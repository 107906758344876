import search from "../../../assets/search.png"
import backArrow from "../../../assets/back.PNG"
import clock from "../../../assets/clock.png"
import CreateBasketCard from "../../CreateBasketCard"


function SelectItemComponent() {
    return (<div className='mt-16 px-4 sm:px-8 md:px-16' >

        <div className="flex items-center  my-4 ">

            <div className="">

                <img className="h-10 text-black " src={backArrow} />
            </div>


            <div className='handPickText ml-2 '>     Selected Item</div>
        </div>

        <div className="relative rounded-md">
            <div className="inset-y-0 pl-3">
                <img className="absolute mt-5 h-7 w-7 text-gray-600" src={search} />
            </div>
            <input
                type="text"
                placeholder="Search"
                id="search"
                className=" h-[60px] block w-full mt-1 rounded-md border-gray-400 font-semibold text-xl text-gray-600 bg-gray-100 pl-14 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
            />
        </div>

        <section className="grid my-10 grid-cols-4 gap-2">
            <div className="bg-[#8DAA6A] text-center p-2 rounded showwingResuiltText text-white">
                Showing
            </div>
            <div className="text-[#8DAA6A] text-center p-2 rounded showwingResuiltText bg-[#F2F2F2]">
                Showing
            </div>
            <div className="text-[#8DAA6A] text-center p-2 rounded showwingResuiltText bg-[#F2F2F2]">
                Showing
            </div>
            <div className="text-[#8DAA6A] text-center p-2 rounded showwingResuiltText bg-[#F2F2F2]">
                Showing
            </div>
        </section>

        <section className="mt-4">
            <div className='grid grid-cols-1 gap-x-2  '>

                <div className="col-span-1 " >

                    <div className="flex">
                        <div className='handPickText  '>Showing 12 Results</div>


                    </div>
                </div>

                <div className="flex col-span-1 col-start-2  items-center lg:hidden ">
                    <div className=" seeAllText "  >
                        Clear Filter
                        <hr style={{ backgroundColor: "#73964C" }} className="bg-red-900 font-bold" />
                    </div>





                </div>

                {/* <div className="flex col-span-1 hidden  col-start-2  items-center lg:block ">
                        <div className=" seeAllText "  >
                            See All 24 items
                            <hr style={{ backgroundColor: "#73964C" }} className="bg-red-900 font-bold" />
                        </div>

                    </div> */}
            </div>

            <div>
                <CreateBasketCard></CreateBasketCard>
            </div>

        </section>

    </div>);
}

export default SelectItemComponent;