import React from "react";
import { IoStar } from "react-icons/io5";
import GrocedyCorporateDashbordBanner from "../../assets/grocedyCorporateDashboardBanner.png";
import { FaPlus } from "react-icons/fa";

const CorporateView = () => {
  return (
    <div className="bg-[#14181F] py-24">
      <div className="mx-auto flex max-w-[1500px] flex-col items-center justify-center px-4 text-white sm:px-8 md:px-14">
        <div className="flex w-fit flex-row items-center space-x-2 rounded-full border-[1px] border-[#45444B] bg-[#272A31] px-10 py-4 ">
          <IoStar className="h-5 w-5" />
          <span className="text-base font-medium">
            Introducing Grocedy Corporate
          </span>
        </div>
        <div className="mx-auto mt-8 max-w-4xl text-center">
          <h2 className="text-5xl font-bold leading-tight">
            Grocedy Corporate: Your Partner in Promoting Healthier, Happier
            Workplaces.
          </h2>
          <p className="mt-4 text-lg font-medium">
            At Grocedy, we believe that a thriving business starts with happy,
            healthy employees. That's why we offer a tailored corporate grocery
            solution designed to meet the specific needs of your company.
          </p>
          <p className="mt-1 text-lg font-medium">
            {" "}
            Grocedy Corporate is your trusted partner in boosting employee
            satisfaction, promoting healthier living, and driving sustainable
            practices within your organization.
          </p>
        </div>
        <div className="mt-16 flex flex-row flex-wrap items-center justify-center">
          <div className="mr-1.5 mt-6 flex w-fit flex-row items-center space-x-2 rounded-full border-[2px] border-[#45444B] bg-transparent px-6 py-3.5 ">
            <FaPlus className="h-4 w-4 text-olivine-500" />
            <span className="text-sm font-medium">Employee Well-being</span>
          </div>
          <div className="mr-1.5 mt-6 flex w-fit flex-row items-center space-x-2 rounded-full border-[2px] border-[#45444B] bg-transparent px-6 py-3.5 ">
            <FaPlus className="h-4 w-4 text-olivine-500" />
            <span className="text-sm font-medium">
              Simple and Secure Access
            </span>
          </div>
          <div className="mr-1.5 mt-6 flex w-fit flex-row items-center space-x-2 rounded-full border-[2px] border-[#45444B] bg-transparent px-6 py-3.5 ">
            <FaPlus className="h-4 w-4 text-olivine-500" />
            <span className="text-sm font-medium">
              Quality Products Guaranteed
            </span>
          </div>
          <div className="mr-1.5 mt-6 flex w-fit flex-row items-center space-x-2 rounded-full border-[2px] border-[#45444B] bg-transparent px-6 py-3.5 ">
            <FaPlus className="h-4 w-4 text-olivine-500" />
            <span className="text-sm font-medium">
              Convenience and Efficiency
            </span>
          </div>
          <div className="mr-1.5 mt-6 flex w-fit flex-row items-center space-x-2 rounded-full border-[2px] border-[#45444B] bg-transparent px-6 py-3.5 ">
            <FaPlus className="h-4 w-4 text-olivine-500" />
            <span className="text-sm font-medium">
              Fast and Reliable Delivery
            </span>
          </div>
          <div className="mr-1.5 mt-6 flex w-fit flex-row items-center space-x-2 rounded-full border-[2px] border-[#45444B] bg-transparent px-6 py-3.5 ">
            <FaPlus className="h-4 w-4 text-olivine-500" />
            <span className="text-sm font-medium">
              Exclusive Discounts for Employees
            </span>
          </div>
          <div className="mr-1.5 mt-6 flex w-fit flex-row items-center space-x-2 rounded-full border-[2px] border-[#45444B] bg-transparent px-6 py-3.5 ">
            <FaPlus className="h-4 w-4 text-olivine-500" />
            <span className="text-sm font-medium">Cost Savings and Value</span>
          </div>
        </div>
        <img
          src={GrocedyCorporateDashbordBanner}
          alt="banner"
          className="mt-0 aspect-auto h-[700px] w-auto rounded-2xl object-contain shadow-2xl shadow-gray-600 sm:mt-16"
        />
        <div className="mx-auto mt-8 flex max-w-2xl flex-col items-center justify-center sm:mt-20">
          <h2 className="text-center text-2xl font-semibold leading-tight">
            Enhance employee satisfaction, promote healthier living, and support
            sustainable practices
          </h2>
          <div className="mt-16">
            <a href="https://corporate.grocedy.com" target="blank">
              <button className="w-fit transform cursor-pointer rounded-md bg-olivine-500 px-10 py-4 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 xsm:text-sm">
                Get Started with Corporate
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateView;
