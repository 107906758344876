import React, { useEffect, useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import {
  addDelivery,
  fetchCountries,
  getDelivery,
  todoListStatus,
} from "../../store/actions";
import toast from "react-hot-toast";
import { addressDescriptionValidator, init_data } from "../../core/helpers";
import { authService } from "../../services/authService";

const DeliveryAddress = ({ onClose }) => {
  const { fetchCountriesData, getDeliveryItems } = useSelector(
    (state) => state.masterData
  );

  const [fetchedCountries, setFetchedCountries] = useState();
  const [countries, setCountries] = useState();
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedAreaId, setSelectedAreaId] = useState(
    (getDeliveryItems &&
      getDeliveryItems?.customer_info?.length &&
      getDeliveryItems?.customer_info[0]?.shipping_area_id?.toString()) ||
      ""
  );
  const [addr1, setAddr1] = useState(
    (getDeliveryItems &&
      getDeliveryItems?.customer_info?.length &&
      getDeliveryItems?.customer_info[0]?.shipping_address1) ||
      ""
  );
  const [addr2, setAddr2] = useState(
    (getDeliveryItems &&
      getDeliveryItems?.customer_info?.length &&
      getDeliveryItems?.customer_info[0]?.shipping_address2) ||
      ""
  );
  const [landmark, setLandmark] = useState(
    (getDeliveryItems &&
      getDeliveryItems?.customer_info?.length &&
      getDeliveryItems?.customer_info[0]?.land_mark) ||
      ""
  );
  const [zipcode, setZipcode] = useState(
    (getDeliveryItems &&
      getDeliveryItems?.customer_info?.length &&
      getDeliveryItems?.customer_info[0]?.shipping_zip_code) ||
      ""
  );
  const [phone, setPhone] = useState(
    (getDeliveryItems &&
      getDeliveryItems?.customer_info?.length &&
      getDeliveryItems?.customer_info[0]?.shipping_phone_number) ||
      ""
  );

  const [errorMsg, setErrorMsg] = useState({
    selectedAreaId: "",
    addr1: "",
    phone: "",
  });

  const dispatch = useDispatch();

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();
  const token = authService.getToken();

  // Address 1
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "ng" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  const handleChangeEvent = (e) => {
    setPhone(e.target.value.replace(/[^0-9]/g, ""));
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      setAddr1(place?.name);
    });
  }, []);

  useEffect(() => {
    if (!fetchCountriesData) {
      const data = {
        sql_ref_no: "12",
        user_id: userId?.toString(),
        c_code: verification_code,
        option: "19",
      };

      dispatch(fetchCountries(data));
    }

    if (fetchCountriesData)
      setFetchedCountries(fetchCountriesData.location_info);
  }, [fetchCountriesData, userId, verification_code, dispatch]);

  useEffect(() => {
    if (fetchedCountries) {
      const resArr = [];
      fetchedCountries.filter((item) => {
        let i = resArr.findIndex((x) => x === item.country_name);
        if (i <= -1) {
          resArr.push(item.country_name);
        }
        return null;
      });

      setCountries(resArr);
    }
  }, [fetchedCountries]);

  // Get the exist
  useEffect(() => {
    if (!getDeliveryItems && token) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "6",
      };

      dispatch(getDelivery(data));
    }
  }, [getDeliveryItems, dispatch, userId, customerId, token]);

  useEffect(() => {
    if (
      getDeliveryItems &&
      getDeliveryItems?.customer_info?.length &&
      getDeliveryItems?.customer_info[0].shipping_city_name
    ) {
      const areas = [];
      fetchedCountries?.filter((item) => {
        if (
          item.city_name ===
          getDeliveryItems?.customer_info[0].shipping_city_name
        ) {
          areas.push(item);
        }
        return null;
      });

      setAreas(areas);
    }
  }, [getDeliveryItems, fetchedCountries]);

  const getCities = (count) => {
    const cities = [];
    fetchedCountries.filter((item) => {
      if (item.country_name === count) {
        cities.push(item);
      }
      return null;
    });

    const resArr = [];
    cities.filter((item) => {
      let i = resArr.findIndex((x) => x === item.city_name);
      if (i <= -1) {
        resArr.push(item.city_name);
      }
      return null;
    });

    return resArr;
  };

  const getAreas = (cit) => {
    const areas = [];
    fetchedCountries.filter((item) => {
      if (item.city_name === cit) {
        areas.push(item);
      }
      return null;
    });

    return areas;
  };

  const handleChange = (event) => {
    const selectedCities = getCities(event.target.value);
    setCities(selectedCities);
  };

  const handleChangeArea = (event) => {
    const selectedAreas = getAreas(event.target.value);

    setAreas(selectedAreas);
  };

  const handleChangeAreaID = (event) => {
    setSelectedAreaId(event.target.value);
  };

  const payloadValuesError = addressDescriptionValidator(
    addr1,
    phone,
    selectedAreaId
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      if (Object.keys(payloadValuesError).length) {
        return setErrorMsg({
          ...errorMsg,
          addr1: payloadValuesError.addr1,
          phone: payloadValuesError.phone,
          selectedAreaId: payloadValuesError.areaId,
        });
      }

      const toastId = toast.loading("Submitting your Delivery details...");

      const data = {
        sql_ref_no: "10",
        user_id: userId?.toString(),
        verification_code,
        customer_id: customerId?.toString(),
        shipping_area_id: selectedAreaId,
        shipping_address1: addr1.replace(/#/g, "%23"),
        shipping_address2: addr2.replace(/#/g, "%23"),
        land_mark: landmark,
        shipping_zip_code: zipcode,
        shipping_phone_number: phone,
        option: "12",
      };

      dispatch(addDelivery(data))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            return toast.error("Delivery details could not be submitted!", {
              id: toastId,
            });
          }
          setAddr1("");
          setAddr2("");
          setLandmark("");
          setPhone("");
          setSelectedAreaId("");
          dispatch(todoListStatus(init_data));

          const data = {
            sql_ref_no: "11",
            user_id: userId?.toString(),
            customer_id: customerId?.toString(),
            option: "6",
          };

          dispatch(getDelivery(data));
          window.location.reload();

          toast.success("Delivery Details Submitted", {
            id: toastId,
          });
          onClose();
        })
        .catch((err) => {
          setAddr1("");
          setAddr2("");
          setLandmark("");
          setPhone("");
          setSelectedAreaId("");
          toast.error("Delivery details could not be submitted!", {
            id: toastId,
          });

          return err;
        });
    } catch (err) {
      return err;
    }
  };

  const handleOnClose = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className="h-full px-2 pb-16 pt-4 xsm:px-3">
      <div className="mx-auto w-full max-w-[420px] rounded-2xl bg-white px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <div className="font-semibold text-gray-700 xsm:text-lg sm:text-xl">
            Add Your Delivery Address
          </div>
          <button
            className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
            onClick={handleOnClose}
          >
            <CgClose className="h-5 w-5 text-gray-600" />
          </button>
        </div>
        <div className="mt-1 text-xs">
          Please enter your delivery address to start using Grocedy
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="mt-1">
              <label
                htmlFor="country/region"
                className="ml-3 text-xs text-gray-500"
              >
                Country/Region
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <select
                    onChange={handleChange}
                    type="text"
                    className="block w-full cursor-pointer rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm focus:border-olivine-500 focus:ring-olivine-500"
                  >
                    {getDeliveryItems &&
                    getDeliveryItems?.customer_info?.length &&
                    getDeliveryItems?.customer_info[0]
                      ?.shipping_country_name ? (
                      <option>
                        {
                          getDeliveryItems?.customer_info[0]
                            ?.shipping_country_name
                        }
                      </option>
                    ) : (
                      <option>select</option>
                    )}
                    {countries &&
                      countries.map((country, idx) => (
                        <option key={idx} value={country}>
                          {country}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-2 flex items-start space-x-2 sm:space-x-3">
              <div className="flex-1">
                <label
                  htmlFor="selectState"
                  className="ml-3 text-xs text-gray-500"
                >
                  Select State
                </label>
                <div className="relative flex items-center justify-end rounded-md">
                  <div className="flex-grow">
                    <select
                      onChange={handleChangeArea}
                      type="text"
                      className="block w-full cursor-pointer rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm focus:border-olivine-500 focus:ring-olivine-500"
                    >
                      {getDeliveryItems &&
                      getDeliveryItems?.customer_info?.length &&
                      getDeliveryItems?.customer_info[0]?.shipping_city_name ? (
                        <option>
                          {
                            getDeliveryItems?.customer_info[0]
                              ?.shipping_city_name
                          }
                        </option>
                      ) : (
                        <option>select</option>
                      )}
                      {cities &&
                        cities.map((city, idx) => (
                          <option key={idx} value={city}>
                            {city}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <label htmlFor="area" className="ml-3 text-xs text-gray-500">
                  Area <span className="text-rose-600"> * </span>
                </label>
                <div className="relative flex items-center justify-end rounded-md">
                  <div className="flex-grow" id="myDropdown">
                    <select
                      onChange={handleChangeAreaID}
                      id="myInput"
                      type="text"
                      className="block w-full cursor-pointer rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm focus:border-olivine-500 focus:ring-olivine-500"
                    >
                      {getDeliveryItems &&
                      getDeliveryItems?.customer_info?.length &&
                      getDeliveryItems?.customer_info[0]?.shipping_area_name ? (
                        <option>
                          {
                            getDeliveryItems?.customer_info[0]
                              ?.shipping_area_name
                          }
                        </option>
                      ) : (
                        <option>select</option>
                      )}
                      {areas &&
                        areas.map((area, idx) => (
                          <option key={idx} value={area.area_id}>
                            {area.area_name}
                          </option>
                        ))}
                    </select>
                    {errorMsg?.selectedAreaId ? (
                      <span className="ml-3 text-xs text-red-500">
                        {errorMsg?.selectedAreaId}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <label
                htmlFor="addressLine1"
                className="ml-3 text-xs text-gray-500"
              >
                Address Line 1 <span className="text-rose-600"> * </span>
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    onChange={(e) => {
                      const limit = 50;

                      // 👇️ only take first N characters
                      setAddr1(e.target.value.slice(0, limit));
                    }}
                    value={addr1}
                    ref={inputRef}
                    type="text"
                    placeholder="House/Flat No, Street/Road name"
                    className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                  {errorMsg?.addr1 ? (
                    <span className="ml-3 text-xs text-red-500">
                      {errorMsg?.addr1}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mt-2">
              <label
                htmlFor="addressLine2"
                className="ml-3 text-xs text-gray-500"
              >
                Address Line 2
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    onChange={(e) => {
                      const limit = 50;

                      // 👇️ only take first N characters
                      setAddr2(e.target.value.slice(0, limit));
                    }}
                    value={addr2}
                    type="text"
                    placeholder=""
                    className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                </div>
              </div>
            </div>
            <div className="mt-2 flex items-center space-x-2 sm:space-x-3">
              <div className="flex-1">
                <label
                  htmlFor="landmark"
                  className="ml-3 text-xs text-gray-500"
                >
                  Landmark
                </label>
                <div className="relative flex items-center justify-end rounded-md">
                  <div className="flex-grow">
                    <input
                      onChange={(e) => {
                        const limit = 50;

                        // 👇️ only take first N characters
                        setLandmark(e.target.value.slice(0, limit));
                      }}
                      value={landmark}
                      type="text"
                      placeholder="Landmark"
                      className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    />
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <label htmlFor="zipCode" className="ml-3 text-xs text-gray-500">
                  Zip Code
                </label>
                <div className="relative flex items-center justify-end rounded-md">
                  <div className="flex-grow">
                    <input
                      onChange={(e) => {
                        const limit = 10;

                        // 👇️ only take first N characters
                        setZipcode(e.target.value.slice(0, limit));
                      }}
                      value={zipcode}
                      type="text"
                      placeholder="Enter Zip Code"
                      className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <label
                htmlFor="phoneNumber"
                className="ml-3 text-xs text-gray-500"
              >
                Phone Number <span className="text-rose-600"> * </span>
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    onChange={handleChangeEvent}
                    value={phone}
                    type="text"
                    placeholder="Enter Phone Number"
                    className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                  {errorMsg?.phone ? (
                    <span className="ml-3 text-xs text-red-500">
                      {errorMsg?.phone}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
          <div className="mt-5 flex justify-between text-center tracking-widest">
            <button
              onClick={(e) => handleOnClose(e)}
              className="rounded-md border-[1px] border-[#CE8172]  px-6 py-2 text-sm text-[#CE8172] transition duration-300 hover:bg-[#f5f0ef] active:ring-1 active:ring-[#CE8172] sm:px-8"
            >
              CANCEL
            </button>
            <button
              onClick={handleSubmit}
              className="rounded-md bg-olivine-500 px-8 py-2 text-sm text-white transition duration-300 hover:bg-olivine-600 sm:px-10"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryAddress;
