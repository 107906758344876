import React from "react";
import Navbar from "../components/Navbar.js";
import Footer from "./Footer.js";
import SignedInNavbarImproved from "./SignedInNavbarImproved.js";
import MainAreaTwo from "./MainAreaTwo.js";

function HomeTwo() {
  return (
    <div className="">
      <SignedInNavbarImproved />
      <MainAreaTwo />
      <Footer />
    </div>
  );
}

export default HomeTwo;
