import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { AuthProvider } from './context/AuthProvider';
import { Provider } from "react-redux";
import { store } from "./store";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Toaster } from "react-hot-toast";
import { RxjsStoreContextProvider } from "./context/RxjsStoreContext";
import { LogicContextProvider } from "./context/LogicContext";
import { RxjsPlanContextProvider } from "./context/RxjsPlanContext";
import { ProfileContextProvider } from "./context/ProfileContext";
// import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      > */}
      <RxjsPlanContextProvider>
        <RxjsStoreContextProvider>
          <LogicContextProvider>
            <ProfileContextProvider>
              <ReactNotifications />
              <Toaster
                position="top-right"
                toastOptions={{
                  // Define default options
                  style: {
                    // background: '#363636',
                    color: "#fff",
                    zIndex: 1,
                  },
                  duration: 5000,
                  // Default options for specific types
                  success: {
                    duration: 3000,
                    style: {
                      background: "#DCF8E7",
                      border: "0.8px solid #279058",
                      borderRadius: "8px",
                      color: "#279058",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontSize: "14px",
                    },
                  },
                  error: {
                    duration: 3000,
                    style: {
                      background: "#FFE4E4",
                      border: "0.8px solid #B44445",
                      borderRadius: "8px",
                      color: "#B44445",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontSize: "14px",
                    },
                  },
                  loading: {
                    duration: 9000,
                    style: {
                      background: "#FAFAFA",
                      border: "0.8px solid #BDBDBD",
                      borderRadius: "8px",
                      color: "#828282",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontSize: "14px",
                    },
                  },
                }}
              />
         
              <App />
              {/* </GoogleOAuthProvider> */}
            </ProfileContextProvider>
          </LogicContextProvider>
        </RxjsStoreContextProvider>
      </RxjsPlanContextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
