import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { authService } from "../../../../../services/authService";
import { toast } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function EmailMethodChangePin(props) {
  const [email, setEmail] = useState(authService.getCurrentUserEmail());

  function submitEmail() {
    if (email.match(emailRegex)) {
      props?.submitEmail(email);
    } else {
      toast.error("Please enter a valid email");
    }
  }

  return (
    <div className="p-4">
      <div className="flex justify-end">
        <div className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
          <IoClose onClick={() => props?.closeModal()} className="h-5 w-5" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center space-x-2">
        <h2 className="mt-4 text-xl font-semibold text-gray-700">Change PIN</h2>
        <p className="mt-1 text-center text-sm text-gray-600">
          Input Registered Email Address
        </p>
        <p className="mt-3 max-w-sm text-center text-sm text-gray-600">
          Please enter your registered email address to reset your new
          transaction pin
        </p>
      </div>
      <div className="mx-auto mt-4 max-w-sm">
        <label htmlFor="emailAddress" className="ml-2 text-sm text-gray-500">
          Email Address
        </label>
        <div className="w-full">
          <input
            type="text"
            placeholder="Enter your email address"
            id="emailAddress"
            autoComplete="off"
            readOnly={true}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            required
            className="mt-1 block w-full flex-1 rounded-md border-0 bg-gray-100 text-base text-gray-600  placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
          />
        </div>
      </div>
      <div className="mx-auto mt-6 w-full max-w-[250px] pb-2">
        <button
          disabled={props?.isLoading}
          onClick={() => submitEmail()}
          className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-white p-8 py-3 text-sm text-olivine-500  transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100"
        >
          {props?.isLoading === true ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            "Send Otp "
          )}{" "}
        </button>
      </div>
    </div>
  );
}

export default EmailMethodChangePin;
