import React, { useState } from "react";
import RatingPopupImage from "../../assets/ratingPopupImage.svg";
import StarRatings from "react-star-ratings";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { activePlans, rateShoppingSubscription } from "../../store/actions";
import { authService } from "../../services/authService";
import { useNavigate } from "react-router-dom";

function FeedBackSubRatingVasPopup({ subRef, setOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");

  const customerId = authService.getCurrentUserCustomerId();

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleSubmission = (event) => {
    event.preventDefault();
    try {
      const toastId = toast.loading("Rating...");
      const id = customerId?.toString();

      const data = {
        ref_no: subRef,
        rating_value: rating?.toString(),
        rating_remark: message,
      };

      dispatch(rateShoppingSubscription(data))
        .unwrap()
        .then((res) => {
          if (res.status === 403) {
            return toast.error(res.response.error, {
              id: toastId,
            });
          }

          toast.success("Thank you for your feedback...", {
            id: toastId,
          });
          dispatch(activePlans(id));
          setOpen(false);
          navigate("/home");
        });
    } catch (err) {
      return err;
    }
  };
  return (
    <div className="max-w-lg bg-white p-4">
      <div className="flex flex-col items-center justify-center text-center">
        <div>
          <img
            src={RatingPopupImage}
            alt="voucher-success"
            className="h-32 w-auto"
          />
        </div>
        <h2 className=" max-w-xs text-center text-sm font-bold text-gray-600">
          Thank you for your purchase
        </h2>
        <p className="mt-1 max-w-xs text-center text-xs text-gray-400">
          Please share your shopping experience with us. Help us serve you
          better
        </p>
        <div className="mt-4 flex flex-row space-x-2">
          <StarRatings
            rating={rating}
            starRatedColor="#ffce31"
            changeRating={handleRatingChange}
            numberOfStars={5}
            name="rating"
          />
        </div>
      </div>
      <div className="mx-auto mt-2">
        <label className="ml-2 text-xs">Add a Review (Optional)</label>
        <textarea
          placeholder="Write your message..."
          rows="2"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="w-full rounded-md border-gray-200 bg-[#F2F2F2] text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
        ></textarea>
      </div>
      <div className="mx-auto mt-6 max-w-sm">
        <button
          onClick={(e) => handleSubmission(e)}
          className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-16 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
        >
          Rate Experience
        </button>
        <div className="mt-2 text-center">
          <button
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
            className="text-sm font-medium text-gray-400 hover:underline hover:underline-offset-2"
          >
            Maybe Later
          </button>
        </div>
      </div>
    </div>
  );
}

export default FeedBackSubRatingVasPopup;
