import React from "react";
import { IoClose } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";

function FundYourWalletPopover({ onClose, handleShowing, handleAddCard }) {
  const handleNext = (e) => {
    e.preventDefault();
    handleShowing();
  };

  const handleCard = () => {
    handleAddCard();
  };

  return (
    <div className="p-8 bg-white w-[400px] sm:w-[460px] text-gray-500 shadow-lg rounded-2xl">
      <div className="flex justify-between items-center">
        <div className="font-bold text-lg text-gravel-500">
          Fund Your Wallet
        </div>
        <button className="p-1 rounded-full hover:bg-gray-200">
          <IoClose
            onClick={() => onClose()}
            className="h-5 w-5 text-gravel-500"
          />
        </button>
      </div>
      <div className="mt-1 flex justify-start font-semibold text-sm text-gravel-500">
        Choose your payment method
      </div>
      <div className="mt-4 flex justify-start font-semibold text-sm text-gravel-400">
        Use this card
      </div>
      <div className="">
        <div className="mt-2 h-12 px-4 cursor-pointer bg-[#E9F8CC] flex items-center justify-between hover:ring-1 hover:ring-gray-300 rounded-lg filter drop-shadow-md">
          <div className="font-semibold text-sm text-gray-500">
            XXXX XXXX XXXX 1234
          </div>
          <div>
            <IoIosArrowForward className="h-4 w-4 text-gray-500 cursor-pointer" />
          </div>
        </div>
        <hr className="my-6" />
        <div
          onClick={() => handleCard()}
          className="h-12 px-4 cursor-pointer bg-[#E9F8CC] flex items-center justify-between hover:ring-1 hover:ring-gray-300 rounded-lg filter drop-shadow-md"
        >
          <div className="font-semibold text-sm text-gray-500">
            Add a New Card
          </div>
          <div>
            <IoIosArrowForward className="h-4 w-4 text-gray-500 cursor-pointer" />
          </div>
        </div>
        <div className="mt-6 h-12 px-4 cursor-pointer bg-[#E9F8CC] flex items-center justify-between hover:ring-1 hover:ring-gray-300 rounded-lg filter drop-shadow-md">
          <div className="font-semibold text-sm text-gray-500">
            Make a Bank Transfer
          </div>
          <div>
            <IoIosArrowForward className="h-4 w-4 text-gray-500 cursor-pointer" />
          </div>
        </div>
        <div className="mt-10">
          <div className="flex justify-between text-center tracking-widest transition-all duration-300">
            <button
              onClick={() => onClose()}
              className="px-8 py-3 text-sm text-olivine-500 border-2 border-olivine-400 rounded-md hover:border-olivine-600 transition duration-300"
            >
              BACK
            </button>
            <button
              onClick={(e) => handleNext(e)}
              className="px-8 py-3 text-sm text-white bg-olivine-500 rounded-md hover:bg-olivine-600 transition duration-300"
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FundYourWalletPopover;
