import React, { useState, useEffect } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { userAdvertsGet } from "../../store/actions";
import { useNavigate } from "react-router-dom";


var counter = 0;


function HomeBannerSlider({
  children: slides,
  autoSlide = false,
  autoSlideInterval = 3000,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(0);
  const [fetchedAdvertLinks, setFetchedAdvertLinks] = useState();

  const { adverts } = useSelector((state) => state.user);
  
  useEffect(()=> {
   
    var interVal = setInterval(function () {
      if(counter >= fetchedAdvertLinks?.length){
        scrollRight(0);
        counter = 0;
      }else{
        scrollRight(400);
        counter++;

      }
    
    }, 4000);

    return ()=> {
      clearInterval(interVal);
    }
  },[fetchedAdvertLinks?.length]);

  useEffect(() => {
    if (!adverts?.length) {
      advertsFetcher();
    }

    if (adverts?.length) {
      setFetchedAdvertLinks(adverts);
    }
  }, [adverts]);

  function advertsFetcher() {
    try {
      dispatch(userAdvertsGet());
    } catch (err) {
      return err;
    }
  }

  const scrollLeft = (size = 400) => {
    document.getElementById("content").scrollLeft -= size;
  };
  const scrollRight = (size = 400) => {
    if (size === 0) {
      document.getElementById("content").scrollLeft = size;
    } else {
      document.getElementById("content").scrollLeft += size;
    }
  };



  return (
    <div className="max-w-[1400px]">
      <h2 className="mt-6 text-xl font-bold text-gray-600">Featured</h2>
      {fetchedAdvertLinks && fetchedAdvertLinks?.length ? (
        <div className="relative">
          <div
            id="content"
            className="carousel flex min-w-full flex-shrink-0 items-center justify-start overflow-x-hidden scroll-smooth whitespace-nowrap px-16 py-4 transition-all duration-500 ease-in  scrollbar-hide"
          >
            {fetchedAdvertLinks?.map((banner, index) => (
              <div
                className="mr-6 block flex-shrink-0 cursor-pointer transition-all duration-500 ease-in"
                key={index}
              >
                <img
                  className="z-10 h-80"
                  src={banner.feature_image_url}
                  alt="sample image"
                  onClick={() => navigate(banner?.feature_action_url_web)}
                />
              </div>
            ))}
          </div>
          <div className="absolute left-0 top-1/2 z-20 flex items-center justify-between">
            <button
              onClick={() => scrollLeft(400)}
              className="m-2 rounded-full bg-gray-200 p-2"
            >
              <FiChevronLeft className="h-6 w-6" />
            </button>
          </div>
          <div className="absolute right-0 top-1/2 z-20 flex items-center justify-between">
            <button
              onClick={() => scrollRight(400)}
              className="m-2 rounded-full bg-gray-200 p-2"
            >
              <FiChevronRight className="h-6 w-6" />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default HomeBannerSlider;
