import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isForgotPassword: false,
  isAddNewPhonenumber: false,
  amountToPay: "",
  selectBiller: "AIRTEL_VTU",
  isFunding: false,
  isGifting: false,
  giftProfileDetails: {},
  giftDelivery: {},
  totalBasketAmount: 0,
  comments: "",
  selectedSalesOrder: {},
  selectedProductName: {},
  selectedBasketSalesOrder: {},
  selectedProductId: {},
  unreadNotify: 0,
  updateWallet: false,
  paystackRecurringPlan: 1,
  isActivePlan: false,
  existingAmount: "",
  isEditing: false,
  transaction: {},
  isPastLogin: false,
  isSubmiting: false,
  userNextAction: "",
  selectedBulkVoucher: {},
  selectedReview: {},
  isCorporate: false,
  isFromLogin: false,
};

const ModalSlice = createSlice({
  name: "Modal",
  initialState,
  reducers: {
    resetModal(state) {
      state.isForgotPassword = null;
      state.isPastLogin = null;
      state.transaction = null;
      state.isEditing = null;
      state.existingAmount = null;
      state.isActivePlan = null;
      state.paystackRecurringPlan = null;
      state.updateWallet = null;
      state.unreadNotify = null;
      state.isAddNewPhonenumber = null;
      state.amountToPay = null;
      state.selectBiller = null;
      state.isGifting = null;
      state.giftProfileDetails = null;
      state.giftDelivery = null;
      state.totalBasketAmount = null;
      state.comments = null;
      state.selectedSalesOrder = null;
      state.selectedProductName = null;
      state.selectedBasketSalesOrder = null;
      state.selectedProductId = null;
      state.isSubmiting = null;
      state.userNextAction = null;
      state.selectedBulkVoucher = null;
      state.selectedReview = null;
      state.isCorporate = null;
      state.isFromLogin = null;
    },

    setForgotPassword(state, { payload }) {
      state.isForgotPassword = payload;
    },

    setUnreadNotify(state, { payload }) {
      state.unreadNotify = payload;
    },

    setAddNewPhonenumber(state, { payload }) {
      state.isAddNewPhonenumber = payload;
    },

    setAmountToPay(state, { payload }) {
      state.amountToPay = payload;
    },

    setTotalBasketAmount(state, { payload }) {
      state.totalBasketAmount = payload;
    },

    setSelectBiller(state, { payload }) {
      state.selectBiller = payload;
    },

    setIsFunding(state, { payload }) {
      state.isFunding = payload;
    },

    setIsGifting(state, { payload }) {
      state.isGifting = payload;
    },

    setGiftProfileDetails(state, { payload }) {
      state.giftProfileDetails = payload;
    },

    setGiftDelivery(state, { payload }) {
      state.giftDelivery = payload;
    },

    setSubscriptionComment(state, { payload }) {
      state.comments = payload;
    },

    setSalesOrder(state, { payload }) {
      state.selectedSalesOrder = payload;
    },

    setBasketSalesOrder(state, { payload }) {
      state.selectedBasketSalesOrder = payload;
    },

    setSelectedProductName(state, { payload }) {
      state.selectedProductName = payload;
    },

    setSelectedProductId(state, { payload }) {
      state.selectedProductId = payload;
    },

    setWalletUpdate(state, { payload }) {
      state.updateWallet = payload;
    },

    setRecurringPlan(state, { payload }) {
      state.paystackRecurringPlan = payload;
    },

    setIsActivePlan(state, { payload }) {
      state.isActivePlan = payload;
    },

    setExistingAmount(state, { payload }) {
      state.existingAmount = payload;
    },

    setIsEditing(state, { payload }) {
      state.isEditing = payload;
    },

    setTransaction(state, { payload }) {
      state.transaction = payload;
    },

    setIsPastLogin(state, { payload }) {
      state.isPastLogin = payload;
    },

    setIsSubmiting(state, { payload }) {
      state.isSubmiting = payload;
    },

    setVerifiedCoupon(state, { payload }) {
      state.verifiedCoupon = payload;
    },

    setUserNextAction(state, { payload }) {
      state.userNextAction = payload;
    },

    setSelectedBulkVoucher(state, { payload }) {
      state.selectedBulkVoucher = payload;
    },

    setSelectedReview(state, { payload }) {
      state.selectedReview = payload;
    },

    setIsCorporate(state, { payload }) {
      state.isCorporate = payload;
    },
    setIsFromLogin(state, { payload }) {
      state.isFromLogin = payload;
    },
  },
});

const { reducer, actions } = ModalSlice;

export const {
  setForgotPassword,
  setAddNewPhonenumber,
  setAmountToPay,
  setSelectBiller,
  setIsFunding,
  setGiftProfileDetails,
  setGiftDelivery,
  setTotalBasketAmount,
  setSubscriptionComment,
  setIsGifting,
  setSalesOrder,
  setSelectedProductName,
  setBasketSalesOrder,
  setSelectedProductId,
  setUnreadNotify,
  setWalletUpdate,
  setRecurringPlan,
  setIsActivePlan,
  setExistingAmount,
  setIsEditing,
  setTransaction,
  setIsPastLogin,
  resetModal,
  setIsSubmiting,
  setUserNextAction,
  setSelectedBulkVoucher,
  setSelectedReview,
  setIsCorporate,
  setIsFromLogin,
} = actions;

export default reducer;
