import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import PlansListbox from "./PlansListbox";

function EditOrderPopover({ selectedItem, plans, closer, dataPull }) {
  const [count5, setCount5] = useState(
    +selectedItem?.JSON_String?.plan?.plan_quantity || 1
  );
  const [count6, setCount6] = useState(
    +selectedItem?.JSON_String?.plan?.interval || 1
  );
  const [selectedPlan, setSelectedPlan] = useState(
    selectedItem?.JSON_String?.plan?.plan_name
  );
  const [monthlyRecurring, setMonthlyRecurring] = useState(
    selectedItem?.JSON_String?.plan?.isRecurring
  );

  if (count5 < 1) {
    setCount5(1);
  }
  if (count6 < 1) {
    setCount6(1);
  }

  const getSelectedPlan = (data) => {
    setSelectedPlan(data);
  };

  const handleMonthlyRecurring = (event) => {
    setMonthlyRecurring(event.target.value);
  };

  const handlePull = (e) => {
    e.preventDefault();

    const updatedPlan = {
      plan_name: selectedPlan,
      plan_units: count5,
      isRecurring: monthlyRecurring,
      months_recurring: monthlyRecurring === "yes" ? count6 : 0,
      itemId: selectedItem?.itemId,
    };

    dataPull(updatedPlan);
  };

  return (
    <div>
      <div className="rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
        <div className="flex items-center justify-between">
          <div className="block text-lg font-bold text-gray-600">Edit Item</div>
          <div className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
            <IoClose
              onClick={async () => {
                closer();
              }}
              className="h-6 w-6 cursor-pointer text-gray-600"
            />
          </div>
        </div>
        <div className="relative mt-6">
          <div className="block text-xs font-semibold text-gray-600 xsm:text-sm">
            Plan name
          </div>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="relative mb-10 mt-1 block flex-grow">
              <PlansListbox
                plansList={plans}
                fetchPlan={getSelectedPlan}
                selectedItem={selectedItem}
              />
            </div>
          </div>
          {/* <AddonListboxOne/> */}
        </div>
        <div className="mt-4">
          <div className="mt-4 block text-xs font-semibold text-gray-600 xsm:text-sm">
            How many <span className="text-[#1525F0]">({selectedPlan}s)</span>{" "}
            do you want?
          </div>
          <div className="mt-1 flex justify-start">
            <div className="flex items-center space-x-8 rounded-md border-[0.5px] border-gray-400 bg-gray-50 px-3 py-1 font-semibold text-gray-500 shadow-sm">
              <div
                onClick={() => setCount5((prev) => prev - 1)}
                className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
              >
                <AiOutlineMinus className="h-5 w-5" />
              </div>
              <div className="w-8 text-center font-bold text-gray-700">
                {count5}
              </div>
              <div
                onClick={() => setCount5((prev) => prev + 1)}
                className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
              >
                <AiOutlinePlus className="h-5 w-5" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label
            htmlFor="monthlyRecuring"
            className="text-xs font-semibold text-gray-600 xsm:text-sm"
          >
            Do you want your plan to recur monthly?
          </label>
          <div className="relative mt-1 flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <select
                onChange={handleMonthlyRecurring}
                type="text"
                className="block w-full cursor-pointer rounded-md border-gray-300 bg-gray-50 pl-4 pr-10 text-gray-500 shadow-sm focus:border-olivine-500 focus:ring-olivine-500"
              >
                <option key={3} value="no">
                  No
                </option>
                <option key={2} value="yes">
                  Yes
                </option>
              </select>
            </div>
          </div>
        </div>
        {monthlyRecurring === "yes" && (
          <div className="mt-4">
            <div className="mt-6 block text-sm font-semibold text-gray-600">
              Number of months
            </div>
            <div className="mt-1 flex justify-start">
              <div className="flex items-center space-x-8 rounded-md border-[0.5px] border-gray-400 bg-gray-50 px-3 py-1 font-semibold text-gray-500 shadow-sm">
                <div
                  onClick={() => setCount6((prev) => prev - 1)}
                  className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
                >
                  <AiOutlineMinus className="h-5 w-5" />
                </div>
                <div className="w-8 text-center font-bold text-gray-700">
                  {count6}
                </div>
                <div
                  onClick={() => setCount6((prev) => prev + 1)}
                  className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
                >
                  <AiOutlinePlus className="h-5 w-5" />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-8 flex items-center justify-between space-x-4">
          <button
            onClick={async (e) => {
              closer();
            }}
            className="block rounded-lg border-[1px] border-olivine-500 px-8 py-3 font-semibold text-olivine-500 shadow transition duration-300 hover:bg-olivine-100"
          >
            Cancel
          </button>
          <button
            onClick={async (e) => {
              handlePull(e);
              closer();
            }}
            className="block rounded-lg bg-olivine-500 px-6 py-3 font-semibold text-white shadow transition duration-300 hover:bg-olivine-600 xsm:px-8"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditOrderPopover;
