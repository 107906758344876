import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Airtel from "../assets/Airtel.png";
import Glo from "../assets/Glo.jpeg";
import MTN from "../assets/MTN.jpeg";
import Nmobile from "../assets/9mobile.jpeg";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  billerGroup,
  billerGroupEnquiry,
  buyAirtime,
  delgroAirtimePurchase,
} from "../store/actions/vasActions";
// import { Store } from "react-notifications-component";
// import { notification } from "../core/helpers";
import toast from "react-hot-toast";
import { authService } from "../services/authService";
import {
  getTransactions,
  getWalletBalance,
  verifyTransactionPinCode,
} from "../store/actions";
import IsLoadingFour from "./IsloadingFour";

function BuyAirtimePopover({ onClose }) {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [amount, setAmount] = useState("");
  const [fetchedBillerGroups, setFetchedBillergroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [packageSlug, setPackageSlug] = useState("AIRTEL_VTU");
  const { walletBalance } = useSelector((state) => state.user);
  const { billerGrpEnq, biller } = useSelector((state) => state.vas);
  const [otp, setOtp] = useState(new Array(4).fill(""));

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentCustomerId();

  // const selectedBiller = 88;
  useEffect(() => {
    if (!biller) {
      dispatch(billerGroup());
    }
  }, [biller, dispatch]);

  useEffect(() => {
    if (biller) {
      const filteredBiller =
        biller &&
        biller?.length &&
        biller?.filter((bill) => bill.slug === "AIRTIME_AND_DATA");
      setFetchedBillergroups(filteredBiller);
    }
  }, [biller, dispatch]);

  useEffect(() => {
    if (fetchedBillerGroups && fetchedBillerGroups?.length) {
      dispatch(billerGroupEnquiry(fetchedBillerGroups[0]?.id));
    }
  }, [fetchedBillerGroups, dispatch]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value.replace(/[^0-9]/g, ""));
  };

  const handleWalletUpdate = () => {
    const data = {
      sql_ref_no: "31",
      user_id: userId.toString(),
      customer_id: customerId.toString(),
      verification_code,
      option: "1",
    };

    dispatch(getWalletBalance(data))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        const id = customerId?.toString();
        dispatch(getTransactions(id));
      })
      .catch((err) => {
        return err;
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const toastId = toast.loading("Vending Airtime....");

    const amountToBuy = amount;

    if (!billerGrpEnq?.length) {
      return toast.error(
        "This service is temporarily unavailable, please try again later",
        {
          id: toastId,
        }
      );
    }

    if (
      !walletBalance?.available_balance ||
      walletBalance?.available_balance < amountToBuy
    ) {
      return toast.error("You have insufficient funds..", {
        id: toastId,
      });
    }

    setIsLoading(true);

    const serverData = {
      user_id: userId?.toString(),
      customer_id: customerId?.toString(),
      transaction_type_id: "2",
      mobile_number: phone,
      amount: amountToBuy,
      remark: "Buy Airtime",
      verification_code,
      transaction_pin: otp?.join(""),
      packageSlug,
    };

    dispatch(delgroAirtimePurchase(serverData))
      .unwrap()
      .then((res) => {
        if (res && res?.status === 403) {
          setIsLoading(false);
          return toast.error(res.response.error, {
            id: toastId,
          });
        }

        // ${res?.response?.error}
        onClose();
        setIsLoading(false);
        toast.success(res[0]?.Message_Text, {
          id: toastId,
        });
        return handleWalletUpdate();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Vend Airtime failed", {
          id: toastId,
        });
      });
  };

  return (
    <div className="mx-4 w-full max-w-[420px] rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold text-gray-600">Buy Airtime</div>
        <button
          onClick={() => onClose()}
          className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="mt-4 flex justify-start text-sm font-semibold text-gravel-500">
        Select Airtime Provider
      </div>
      <div className="mt-4">
        <Tab.Group>
          <Tab.List className="flex items-center space-x-2 sm:space-x-3">
            <div>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected ? "rounded-md ring-1 ring-olivine-500" : "ring-0"
                    }
                    onClick={() => setPackageSlug("AIRTEL_VTU")}
                  >
                    <div className="h-[68px] w-14 cursor-pointer space-y-1 rounded-md border border-gray-200 pt-5 xsm:h-[75px] xsm:w-[71px] sm:h-[82px] sm:w-20">
                      <div className="flex justify-center">
                        <img
                          src={Airtel}
                          className="aspect-auto h-7 w-7 object-cover xsm:h-8 xsm:w-8 sm:h-9 sm:w-9"
                          alt=""
                        />
                      </div>
                      <div className="flex justify-center text-[8.5px] sm:text-[11px]">
                        Airtel Nigeria
                      </div>
                    </div>
                  </button>
                )}
              </Tab>
            </div>
            <div>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected ? "rounded-md ring-1 ring-olivine-500" : "ring-0"
                    }
                    onClick={() => setPackageSlug("GLO_VTU")}
                  >
                    <div className="h-[68px] w-14 cursor-pointer space-y-1 rounded-md border border-gray-200 pt-5 xsm:h-[75px] xsm:w-[71px] sm:h-[82px] sm:w-20 ">
                      <div className="flex justify-center">
                        <img
                          src={Glo}
                          className="aspect-auto h-7 w-7 object-cover xsm:h-8 xsm:w-8 sm:h-9 sm:w-9"
                          alt=""
                        />
                      </div>
                      <div className="flex justify-center text-[8.5px] sm:text-[11px]">
                        GLO Direct
                      </div>
                    </div>
                  </button>
                )}
              </Tab>
            </div>
            <div>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected ? "rounded-md ring-1 ring-olivine-500" : "ring-0"
                    }
                    onClick={() => setPackageSlug("MTN_VTU")}
                  >
                    <div className="h-[68px] w-14 cursor-pointer space-y-1 rounded-md border border-gray-200 pt-5 xsm:h-[75px] xsm:w-[71px] sm:h-[82px] sm:w-20 ">
                      <div className="flex justify-center">
                        <img
                          src={MTN}
                          className="aspect-auto h-7 w-7 object-cover xsm:h-8 xsm:w-8 sm:h-9 sm:w-9"
                          alt=""
                        />
                      </div>
                      <div className="flex justify-center text-[8.5px] sm:text-[11px]">
                        MTN
                      </div>
                    </div>
                  </button>
                )}
              </Tab>
            </div>
            <div>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected ? "rounded-md ring-1 ring-olivine-500" : "ring-0"
                    }
                    onClick={() => setPackageSlug("9MOBILE_VTU")}
                  >
                    <div className="h-[68px] w-14 cursor-pointer space-y-1 rounded-md border border-gray-200 pt-5 xsm:h-[75px] xsm:w-[71px] sm:h-[82px] sm:w-20">
                      <div className="flex justify-center">
                        <img
                          src={Nmobile}
                          className="aspect-auto h-7 w-7 object-cover xsm:h-8 xsm:w-8 sm:h-9 sm:w-9"
                          alt=""
                        />
                      </div>
                      <div className="flex justify-center text-[8.5px] sm:text-[11px]">
                        9Mobile VTU
                      </div>
                    </div>
                  </button>
                )}
              </Tab>
            </div>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <form>
                <div className="mt-4">
                  <label
                    htmlFor="phoneNumber"
                    className="ml-2 text-xs font-semibold"
                  >
                    Mobile number
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="number"
                        placeholder="08012345678"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="amount"
                    className="ml-2 text-xs font-semibold"
                  >
                    Amount
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        type="text"
                        value={amount}
                        // onChange={(e) => setAmount(e.target.value)}
                        onChange={handleChangeAmount}
                        placeholder="&#8358; 0.00"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
              </form>
              {phone?.length === 11 && amount ? (
                <div>
                  <div className="text-md mt-4 text-center font-semibold text-gray-600">
                    Transaction PIN
                  </div>
                  <div className="relative mt-2 flex items-center justify-center space-x-2 rounded-md">
                    {otp.map((data, index) => {
                      return (
                        <input
                          type="text"
                          maxLength={1}
                          className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                          name="opt"
                          key={index}
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className="mb-2 mt-6">
                <div className="flex justify-between text-center tracking-wider transition-all duration-300">
                  <button
                    className="rounded-md border-[1px] border-olivine-400 px-8 py-3 text-xs text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-600 sm:text-sm"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    CANCEL
                  </button>
                  {!isLoading ? (
                    <button
                      disabled={
                        phone?.length === 11 &&
                        amount &&
                        otp?.join("")?.length === 4
                          ? false
                          : true
                      }
                      onClick={(e) => handleSubmit(e)}
                      className="rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      BUY AIRTIME
                    </button>
                  ) : (
                    <button
                      disabled={true}
                      className="cursor-not-allowed rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      <IsLoadingFour />
                    </button>
                  )}
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <form>
                <div className="mt-4">
                  <label
                    htmlFor="phoneNumber"
                    className="ml-2 text-xs font-semibold"
                  >
                    Mobile number
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        type="number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="08012345678"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="amount"
                    className="ml-2 text-xs font-semibold"
                  >
                    Amount
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        type="text"
                        value={amount}
                        // onChange={(e) => setAmount(e.target.value)}
                        onChange={handleChangeAmount}
                        pattern="[0-9]*"
                        placeholder="&#8358; 0.00"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
              </form>
              {phone?.length === 11 && amount ? (
                <div>
                  <div className="text-md mt-4 text-center font-semibold text-gray-600">
                    Transaction PIN
                  </div>
                  <div className="relative mt-2 flex items-center justify-center space-x-2 rounded-md">
                    {otp.map((data, index) => {
                      return (
                        <input
                          type="text"
                          maxLength={1}
                          className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                          name="opt"
                          key={index}
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className="mb-2 mt-6">
                <div className="flex justify-between text-center tracking-wider transition-all duration-300">
                  <button
                    className="rounded-md border-[1px] border-olivine-400 px-8 py-3 text-xs text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-600 sm:text-sm"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    CANCEL
                  </button>
                  {!isLoading ? (
                    <button
                      disabled={
                        phone?.length === 11 &&
                        amount &&
                        otp?.join("")?.length === 4
                          ? false
                          : true
                      }
                      onClick={(e) => handleSubmit(e)}
                      className="rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      BUY AIRTIME
                    </button>
                  ) : (
                    <button
                      disabled={true}
                      className="cursor-not-allowed rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      <IsLoadingFour />
                    </button>
                  )}
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <form>
                <div className="mt-4">
                  <label
                    htmlFor="phoneNumber"
                    className="ml-2 text-xs font-semibold"
                  >
                    Mobile number
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        type="number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="08012345678"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="amount"
                    className="ml-2 text-xs font-semibold"
                  >
                    Amount
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        type="text"
                        value={amount}
                        onChange={handleChangeAmount}
                        pattern="[0-9]*"
                        placeholder="&#8358; 0.00"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
              </form>
              {phone?.length === 11 && amount ? (
                <div>
                  <div className="text-md mt-4 text-center font-semibold text-gray-600">
                    Transaction PIN
                  </div>
                  <div className="relative mt-2 flex items-center justify-center space-x-2 rounded-md">
                    {otp.map((data, index) => {
                      return (
                        <input
                          type="text"
                          maxLength={1}
                          className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                          name="opt"
                          key={index}
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className="mb-2 mt-6">
                <div className="flex justify-between text-center tracking-wider transition-all duration-300">
                  <button
                    className="rounded-md border-[1px] border-olivine-400 px-8 py-3 text-xs text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-600 sm:text-sm"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    CANCEL
                  </button>
                  {!isLoading ? (
                    <button
                      disabled={
                        phone?.length === 11 &&
                        amount &&
                        otp?.join("")?.length === 4
                          ? false
                          : true
                      }
                      onClick={(e) => handleSubmit(e)}
                      className="rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      BUY AIRTIME
                    </button>
                  ) : (
                    <button
                      disabled={true}
                      className="cursor-not-allowed rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      <IsLoadingFour />
                    </button>
                  )}
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <form>
                <div className="mt-4">
                  <label
                    htmlFor="phoneNumber"
                    className="ml-2 text-xs font-semibold"
                  >
                    Mobile number
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        type="number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="08012345678"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="amount"
                    className="ml-2 text-xs font-semibold"
                  >
                    Amount
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        value={amount}
                        // onChange={(e) => setAmount(e.target.value)}
                        onChange={handleChangeAmount}
                        type="text"
                        pattern="[0-9]*"
                        placeholder="&#8358; 0.00"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
              </form>
              {phone?.length === 11 && amount ? (
                <div>
                  <div className="text-md mt-4 text-center font-semibold text-gray-600">
                    Transaction PIN
                  </div>
                  <div className="relative mt-2 flex items-center justify-center space-x-2 rounded-md">
                    {otp.map((data, index) => {
                      return (
                        <input
                          type="text"
                          maxLength={1}
                          className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                          name="opt"
                          key={index}
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className="mb-2 mt-6">
                <div className="flex justify-between text-center tracking-wider transition-all duration-300">
                  <button
                    onClick={() => {
                      onClose();
                    }}
                    className="rounded-md border-[1px] border-olivine-400 px-8 py-3 text-xs text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-600 sm:text-sm"
                  >
                    CANCEL
                  </button>
                  {!isLoading ? (
                    <button
                      disabled={
                        phone?.length === 11 &&
                        amount &&
                        otp?.join("")?.length === 4
                          ? false
                          : true
                      }
                      onClick={(e) => handleSubmit(e)}
                      className="rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      BUY AIRTIME
                    </button>
                  ) : (
                    <button
                      disabled={true}
                      className="cursor-not-allowed rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      <IsLoadingFour />
                    </button>
                  )}
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

export default BuyAirtimePopover;
