import { createSlice } from "@reduxjs/toolkit";
import {
  bulkVoucherCreate,
  voucherAssign,
  voucherBulkAssignImport,
  voucherBulkGet,
  voucherCreate,
  voucherHistory,
  voucherRedeem,
  voucherShare,
} from "../actions";

const initialState = {
  createVoucher: "",
  createVoucherBulk: "",
  redeemVoucher: "",
  historyVoucher: "",
  shareVoucher: "",
  selectedVoucher: {},
  assignedVoucher: {},
  updatedBulkVouchers: "",
  assignedBulkImports: "",
};

const VoucherSlice = createSlice({
  name: "VoucherData",
  initialState,
  reducers: {
    resetVoucher(state) {
      state.createVoucher = undefined;
      state.redeemVoucher = undefined;
      state.historyVoucher = undefined;
      state.shareVoucher = undefined;
      state.selectedVoucher = undefined;
      state.createVoucherBulk = undefined;
      state.assignedVoucher = undefined;
      state.updatedBulkVouchers = undefined;
      state.assignedBulkImports = undefined;
    },

    setSelectedVoucher(state, { payload }) {
      state.selectedVoucher = payload;
    },

    resetUpdated(state) {
      state.updatedBulkVouchers = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(voucherCreate.fulfilled, (state, { payload }) => {
      state.createVoucher = payload;
    });

    builder.addCase(bulkVoucherCreate.fulfilled, (state, { payload }) => {
      state.createVoucherBulk = payload;
    });

    builder.addCase(voucherRedeem.fulfilled, (state, { payload }) => {
      state.redeemVoucher = payload;
    });

    builder.addCase(voucherHistory.fulfilled, (state, { payload }) => {
      state.historyVoucher = payload;
    });

    builder.addCase(voucherShare.fulfilled, (state, { payload }) => {
      state.shareVoucher = payload;
    });

    builder.addCase(voucherAssign.fulfilled, (state, { payload }) => {
      state.assignedVoucher = payload;
    });

    builder.addCase(voucherBulkGet.fulfilled, (state, { payload }) => {
      state.updatedBulkVouchers = payload;
    });

    builder.addCase(voucherBulkAssignImport.fulfilled, (state, { payload }) => {
      state.assignedBulkImports = payload;
    });
  },
});

const { reducer, actions } = VoucherSlice;

export const { resetVoucher, setSelectedVoucher, resetUpdated } = actions;

export default reducer;
