import React, { Fragment, useEffect, useState } from "react";
import Logo from "../assets/logo.svg";
import { IoCopy } from "react-icons/io5";
import { BiArrowBack } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import PaymentMethodPopoverTwo from "./PaymentMethodPopover_Two";
import Modal from "./Ui";
import { useNavigate } from "react-router-dom";
import {
  getPaymentTransaction,
  getTransactions,
  getUserInfo,
  statusNotification,
  transferStatus,
} from "../store/actions";
import { authService } from "../services/authService";
import { toast } from "react-hot-toast";
import { transactions } from "../core/helpers";
import { BsInfo } from "react-icons/bs";

function TransactionReceipt() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [fectcheUserProfile, setFectcheUserProfile] = useState({});
  const [selectedModal, setSelectedModal] = useState("");
  const [isPaid, setIsPaid] = useState(false);
  const { amountToPay } = useSelector((state) => state.modal);
  const { userInfo } = useSelector((state) => state.masterData);

  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();

  let navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "7",
      };

      dispatch(getUserInfo(data));
    }

    if (userInfo) setFectcheUserProfile(userInfo);
  }, [dispatch, userInfo, customerId, userId]);

  if (!amountToPay) return navigate("/home/wallet");

  const handleRedirect = (location) => {
    setShowModal(true);

    switch (location) {
      case "flutter":
        setSelectedModal("flutter");
        break;
      default:
        return;
    }
  };

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedModal("");
  };

  const handleCheck = () => {
    const toastId = toast.loading("Confirming Payment");

    const payload = {
      amount: +amountToPay,
    };

    dispatch(getPaymentTransaction(payload))
      .unwrap()
      .then((res) => {
        if (res?.message_id !== 1) {
          return toast.error(res.message_text, { id: toastId });
        }

        return toast.success(res.message_text, { id: toastId });
      })
      .catch((err) => err);

    // dispatch(getTransactions())
    //   .unwrap()
    //   .then((res) => {
    //     const payload = {
    //       settlementId: res?.data?.transactions[0]?.transaction_id,
    //     };

    //     // const payload = {
    //     //   settlementId: "203221212007150900002",
    //     // };
    //     dispatch(statusNotification(payload))
    //       .unwrap()
    //       .then((res) => {
    //         if (
    //           res?.settlementId === payload?.settlementId &&
    //           res?.transactionAmount === +amountToPay
    //         ) {
    //           setIsPaid(true);
    //           return toast.success("Payment confirmed", {
    //             id: toastId,
    //           });
    //         }
    //         setIsPaid(false);
    //         return toast.error("Payment not confirmed...", {
    //           id: toastId,
    //         });
    //       })
    //       .catch((err) => {
    //         return err;
    //       });
    //   })
    //   .catch((err) => err);
  };

  return (
    <div className="px-8 pb-20 pt-8 md:px-16">
      <div className="mb-4 md:mb-0">
        <img
          src={Logo}
          className="aspect-auto h-11 w-12 cursor-pointer object-cover"
          alt=""
        />
      </div>
      <div className="py-12 sm:px-8 md:px-16 lg:px-48">
        <div className="flex justify-center font-semibold text-gray-500">
          Transfer{" "}
          {amountToPay ? `₦ ${Number(amountToPay).toLocaleString()}` : "₦ 0.00"}{" "}
          to fund your wallet
        </div>
        <div className="mt-9 flex flex-col justify-center space-y-4 border-[0.5px] border-gray-200 bg-[#F3FBE4] p-4">
          <div className="flex justify-center text-sm text-gravel-500">
            Pay into your Account
          </div>
          <div className="">
            <div className="flex justify-center text-sm text-gravel-500">
              Providus Bank
            </div>
            <div className="flex items-center justify-center space-x-2 text-sm text-gravel-500">
              <div className="text-lg font-bold tracking-wider">
                {fectcheUserProfile &&
                fectcheUserProfile?.customer_info?.length &&
                fectcheUserProfile?.customer_info[0]
                  ?.providus_bank_account_number
                  ? fectcheUserProfile?.customer_info[0]
                      ?.providus_bank_account_number
                  : null}
              </div>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(
                    fectcheUserProfile?.customer_info[0]
                      ?.providus_bank_account_number
                  );
                  toast.success("Copied to clipboard!");
                }}
              >
                <IoCopy className="h-3.5 w-3.5 text-olivine-500 transition duration-300 hover:text-olivine-600" />
              </button>
            </div>
          </div>
          {/* <div className="flex justify-center text-sm text-gravel-500">OR</div>
          <div className="">
            <div className="flex justify-center text-sm text-gravel-500">
              Providus Bank
            </div>
            <div className="flex items-center justify-center space-x-2 text-sm text-gravel-500">
              <div className="text-lg font-bold tracking-wider">
                {fectcheUserProfile &&
                fectcheUserProfile?.customer_info?.length &&
                fectcheUserProfile?.customer_info[0]
                  ?.providus_bank_account_number
                  ? fectcheUserProfile?.customer_info[0]
                      ?.providus_bank_account_number
                  : null}
              </div>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(
                    fectcheUserProfile?.customer_info[0]
                      ?.providus_bank_account_number
                  );
                  toast.success("Copied to clipboard!");
                }}
              >
                <IoCopy className="h-3.5 w-3.5 text-olivine-500 transition duration-300 hover:text-olivine-600" />
              </button>
            </div>
          </div> */}
        </div>
        <div
          onClick={() => handleCheck()}
          className="mt-8 flex cursor-pointer justify-center rounded border-[1px] border-olivine-500 px-8 py-3 text-sm text-olivine-500 transition duration-300 ease-in-out hover:ring-[1px] hover:ring-olivine-500"
        >
          {isPaid ? "Payment Confirmed" : "I have sent the money"}
        </div>
        <div className="mt-4 flex flex-row items-start space-x-2 rounded-md bg-[#F5F5F5] p-3">
          <div className="rounded-full bg-gray-600 text-white">
            <BsInfo className="h-4 w-4" />
          </div>
          <p className="text-xs text-gray-500">
            Per Central Bank rules for bank transfers, payments of ₦10,000 and
            above incur a ₦50 fee. This fee will be deducted from your wallet
            Balance.
          </p>
        </div>
        <div className="mt-6 space-y-6 font-semibold md:flex md:items-center md:justify-between md:space-y-0">
          <div className="flex cursor-pointer items-center justify-center space-x-2 rounded border-[1px] border-[#A5A5A5] bg-[#F1F1F1] px-8 py-4 text-sm text-[#A5A5A5] transition duration-300 ease-in-out hover:ring-[1px] hover:ring-[#A5A5A5]">
            <div>
              <BiArrowBack className="h-5 w-5" />
            </div>
            {/* <div className="">Change payment method</div> */}
            <div>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`
                      ${open ? "" : "text-opacity-90"}
                      w-full focus:text-olivine-700 focus:outline-none lg:w-auto`}
                    >
                      <div className="">
                        <div className="">
                          <div>Change payment method</div>
                        </div>
                      </div>
                    </Popover.Button>
                    <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute left-24 z-10 -mt-[400px] w-48 max-w-sm -translate-x-1/2 px-4 sm:left-40 sm:px-0 md:left-52 lg:left-64 lg:w-72">
                        {({ close }) => (
                          <div>
                            <PaymentMethodPopoverTwo
                              changeMethod
                              handleRedirect={handleRedirect}
                              closer={close}
                            />
                          </div>
                        )}
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </div>
          <div
            onClick={() => navigate("/home/wallet")}
            className="flex cursor-pointer items-center justify-center space-x-2 rounded border-[1px] border-[#A5A5A5] bg-[#F1F1F1] px-8 py-4 text-sm text-[#A5A5A5] transition duration-300 ease-in-out hover:ring-[1px] hover:ring-[#A5A5A5]"
          >
            <div>
              <IoClose className="h-5 w-5" />
            </div>
            <div className="">Return to Wallet</div>
          </div>
        </div>
        {/* <div className="mt-16 flex justify-center font-semibold text-gravel-600 md:mt-24">
          Powered by
        </div> */}
      </div>
      <Modal
        visible={showModal}
        onClose={handleOnClose}
        selected={selectedModal}
      />
    </div>
  );
}

export default TransactionReceipt;
