import CurrencyFormat from "react-currency-format";
import { BsInfoLg } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import {
  Modal as MUIModal,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import MinimumBasketImage from "../../assets/minimumBasketImage.svg";
import InfoIcon from "../../assets/infoIcon.svg";
import { CgClose } from "react-icons/cg";

const OrderDetails = ({
  fetchedCart,
  customBasket,
  calculateTotalBasket,
  updatedLocalCart,
  handleCouponVerify,
  setCouponCode,
  couponCode,
  setOpen,
  subTotalOfflinePlanPrice,
  subTotalOfflineAddonPrice,
  fetchedCouponCode,
  coupon,
  priceAlert,
  showPriceAlert,
  handleGeneralCheckout,
  open,
  subTotalPlanPrice,
  subTotalAddonPrice,
  deliveryFees,
}) => {
  return (
    <div className="bg-[#F2FAD3] px-4 py-6 sm:px-8 lg:col-span-4">
      <div className="align-top text-xl font-bold leading-10 text-gray-700">
        Order Summary
      </div>
      {fetchedCart && fetchedCart?.items?.length
        ? fetchedCart?.items?.map((item, idx) => (
            <>
              <div key={idx} className="mt-6 flex justify-between">
                <div>
                  <div className="font-bold text-gray-600">{`${
                    item?.JSON_String?.plan?.plan_name?.split(" ")[0]
                  } Basket`}</div>
                  <div className="text-xs text-gray-500">
                    {item?.JSON_String?.plan?.isRecurring === "yes"
                      ? `${item?.JSON_String?.plan?.number_of_months} months subscription`
                      : `${item?.JSON_String?.plan?.number_of_months} month subscription`}
                  </div>
                </div>
                <div className="font-bold text-gray-500">{`₦${Number(
                  +item?.JSON_String?.plan?.plan_price *
                    item?.JSON_String?.plan?.plan_quantity
                ).toLocaleString()}`}</div>
              </div>

              {item?.JSON_String?.addons?.length &&
                item?.JSON_String?.addons?.map((addOn, idx) => (
                  <div key={idx} className="ml-4 mt-4 flex justify-between">
                    <div>
                      <div className="-mt-2 text-xs font-semibold italic text-gray-600">
                        {addOn?.name}
                      </div>
                      <div className="text-xs text-gray-500">
                        {addOn?.price_brackets?.isRecurring === "yes"
                          ? `${addOn?.price_brackets?.number_of_months} months subscription`
                          : `${addOn?.price_brackets?.number_of_months} month subscription`}
                      </div>
                      <div className="text-xs text-gray-500">
                        {`Number of Addon Units: ${addOn?.price_brackets?.quantity}`}
                      </div>
                    </div>
                    <div className="text-xs font-bold text-gray-500">
                      {`₦${Number(
                        +addOn?.price_brackets?.quantity *
                          addOn?.price_brackets?.price
                      ).toLocaleString()}`}
                    </div>
                  </div>
                ))}
            </>
          ))
        : null}

      {/* custom basket 2  */}

      <>
        {customBasket?.line_items?.length ? (
          <div className="mt-6 flex justify-between">
            <div>
              <div className="font-bold text-gray-600">
                {customBasket?.plan_name}
              </div>
              <div className="text-xs text-gray-500"></div>
            </div>
            <div className="font-bold text-gray-500">
              <CurrencyFormat
                value={Number.parseFloat(
                  calculateTotalBasket(customBasket)
                )?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />
            </div>
          </div>
        ) : null}
      </>

      {/*  end custom basket */}

      {updatedLocalCart?.length &&
      !fetchedCart?.length &&
      updatedLocalCart?.length
        ? updatedLocalCart?.map((item, idx) => (
            <>
              <div key={idx} className="mt-6 flex justify-between">
                <div>
                  <div className="font-bold text-gray-600">{`${
                    item?.JSON_String?.plan?.plan_name?.split(" ")[0]
                  } Basket`}</div>
                  <div className="text-xs text-gray-500">
                    {item?.JSON_String?.plan?.isRecurring === "yes"
                      ? `${item?.JSON_String?.plan?.number_of_months} months subscription`
                      : `${item?.JSON_String?.plan?.number_of_months} month subscription`}
                  </div>
                </div>
                <div className="font-bold text-gray-500">{`₦${Number(
                  +item?.JSON_String?.plan?.plan_price *
                    item?.JSON_String?.plan?.plan_quantity
                ).toLocaleString()}`}</div>
              </div>

              {item?.JSON_String?.addons?.length &&
                item?.JSON_String?.addons?.map((addOn, idx) => (
                  <div key={idx} className="ml-4 mt-4 flex justify-between">
                    <div>
                      <div className="-mt-2 text-xs font-semibold italic text-gray-600">
                        {addOn?.name}
                      </div>
                      <div className="text-xs text-gray-500">
                        {addOn?.price_brackets?.isRecurring === "yes"
                          ? `${addOn?.price_brackets?.number_of_months} months subscription`
                          : `${addOn?.price_brackets?.number_of_months} month subscription`}
                      </div>
                      <div className="text-xs text-gray-500">
                        {`Number of Addon Units: ${addOn?.price_brackets?.quantity}`}
                      </div>
                    </div>
                    <div className="text-xs font-bold text-gray-500">
                      {`₦${Number(
                        +addOn?.price_brackets?.quantity *
                          addOn?.price_brackets?.price
                      ).toLocaleString()}`}
                    </div>
                  </div>
                ))}
            </>
          ))
        : null}
      <div className="mt-14">
        <form onSubmit={handleCouponVerify}>
          <label className="ml-2 flex items-center space-x-1 text-sm text-gray-500 opacity-75">
            <p>Coupon </p>
            <div></div>
          </label>
          <div className="mt-1 flex justify-between space-x-2">
            <input
              onChange={(e) => setCouponCode(e.target.value)}
              value={couponCode}
              type="text"
              placeholder="Enter coupon code"
              className="border-1 flex-1 rounded-md border-olivine-300 text-xs placeholder:text-sm placeholder:text-gray-300 focus:border-none focus:ring-olivine-500 xsm:text-sm"
            />
            <button className="rounded-md border-[1px] border-olivine-500 bg-white px-2 py-3 text-xs font-medium text-olivine-500 hover:bg-olivine-100 xsm:px-4 xsm:text-sm sm:px-6">
              APPLY
            </button>
          </div>
        </form>
      </div>
      {!updatedLocalCart?.length ? (
        <div className="mt-16 flex items-center justify-between">
          <div className="text-medium space-y-2 text-gray-500 opacity-80">
            <div className="text-sm">Items Total</div>
            <div className="flex items-center space-x-1">
              <div className="text-sm">Delivery Fees</div>
              <button
                onClick={() => {
                  setOpen(true);
                }}
              >
                <div className="rounded-full bg-olivine-500 p-1">
                  <BsInfoLg className="h-2 w-2 text-white" />
                </div>
              </button>
              <MUIModal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className="mt-28 px-2 xsm:px-4">
                  <Box className="max-h-sm mx-auto max-w-sm rounded-xl bg-white ">
                    <div className="p-4">
                      <div className="flex justify-end">
                        <div
                          onClick={() => setOpen(false)}
                          className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
                        >
                          <IoClose className="h-5 w-5" />
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center space-x-2">
                        <img src={InfoIcon} alt="icon" className="h-6 w-6" />
                        <h2 className="text-xl font-semibold text-gray-700">
                          Please Note!!!
                        </h2>
                      </div>
                      <p className="mt-2 text-center text-sm text-gray-600">
                        Please be informed that If you are unreachable, Delivery
                        will be rescheduled at additional costs. Please be
                        available.
                      </p>
                      <div className="mx-auto mt-6 max-w-xs pb-2">
                        <button
                          onClick={() => setOpen(false)}
                          className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F6FBEE] p-8 py-2.5 text-sm font-semibold text-olivine-500  transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100"
                        >
                          Got it
                        </button>
                      </div>
                    </div>
                  </Box>
                </div>
              </MUIModal>
            </div>
            <div className="text-sm">Coupon</div>
            <div className="text-lg">Total</div>
          </div>
          <div className="space-y-2 text-right font-medium text-gray-600">
            <div className="text-sm">
              {`₦${Number(
                subTotalPlanPrice +
                  subTotalAddonPrice +
                  calculateTotalBasket(customBasket)
              ).toLocaleString()}`}
            </div>
            <div className="text-sm">
              {`₦${Number(deliveryFees).toLocaleString()}`}
            </div>
            <div className="text-sm text-rose-600">
              -
              <CurrencyFormat
                className={"text-danger font-bold "}
                value={Number.parseFloat(fetchedCouponCode)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />
            </div>
            <div className="text-lg">
              {fetchedCouponCode
                ? `₦${Number(
                    subTotalPlanPrice +
                      subTotalAddonPrice +
                      deliveryFees -
                      fetchedCouponCode +
                      calculateTotalBasket(customBasket)
                  ).toLocaleString()}`
                : `₦${Number(
                    subTotalPlanPrice +
                      subTotalAddonPrice +
                      deliveryFees +
                      calculateTotalBasket(customBasket)
                  ).toLocaleString()}`}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-16 flex items-center justify-between">
          <div className="text-medium space-y-2 text-gray-500 opacity-80">
            <div className="text-sm">Items Total</div>
            <div className="flex items-center space-x-1">
              <div className="text-sm">Delivery Fees</div>
              <button
                onClick={() => {
                  setOpen(true);
                }}
              >
                <div className="rounded-full bg-olivine-500 p-1">
                  <BsInfoLg className="h-2 w-2 text-white" />
                </div>
              </button>
              <MUIModal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className="mt-28 px-2 xsm:px-4">
                  <Box className="max-h-sm mx-auto max-w-sm rounded-xl bg-white ">
                    <div className="p-4">
                      <div className="flex justify-end">
                        <div
                          onClick={() => setOpen(false)}
                          className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
                        >
                          <IoClose className="h-5 w-5" />
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center space-x-2">
                        <img src={InfoIcon} alt="icon" className="h-6 w-6" />
                        <h2 className="text-xl font-semibold text-gray-700">
                          Please Note!!!
                        </h2>
                      </div>
                      <p className="mt-2 text-center text-sm text-gray-600">
                        Please be informed that If you are unreachable, Delivery
                        will be rescheduled at additional costs. Please be
                        available.
                      </p>
                      <div className="mx-auto mt-6 max-w-xs pb-2">
                        <button
                          onClick={() => setOpen(false)}
                          className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F6FBEE] p-8 py-2.5 text-sm font-semibold text-olivine-500  transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100"
                        >
                          Got it
                        </button>
                      </div>
                    </div>
                  </Box>
                </div>
              </MUIModal>
            </div>
            <div className="text-sm">Coupon basket</div>
            <div className="text-lg">Total</div>
          </div>
          <div className="space-y-2 text-right font-medium text-gray-600">
            <div className="text-sm">
              {`₦${Number(
                subTotalOfflinePlanPrice +
                  subTotalOfflineAddonPrice +
                  calculateTotalBasket(customBasket)
              ).toLocaleString()}`}
            </div>
            <div className="text-sm">
              {`₦${Number(deliveryFees).toLocaleString()}`}
            </div>
            <div className="text-sm text-olivine-500">
              -
              <CurrencyFormat
                className={"text-danger font-bold "}
                value={Number.parseFloat(fetchedCouponCode)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />
            </div>
            <div className="text-lg">
              {coupon
                ? `₦${Number(
                    subTotalOfflinePlanPrice +
                      subTotalOfflineAddonPrice +
                      deliveryFees -
                      fetchedCouponCode +
                      calculateTotalBasket(customBasket)
                  ).toLocaleString()}`
                : `₦${Number(
                    subTotalOfflinePlanPrice +
                      subTotalOfflineAddonPrice +
                      deliveryFees +
                      calculateTotalBasket(customBasket)
                  ).toLocaleString()}`}
            </div>
          </div>
        </div>
      )}

      {/*  Price Alert */}
      {priceAlert && (
        <div>
          <Dialog
            className="h-full"
            open={true}
            onClose={() => showPriceAlert(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className="h-hug w-full rounded-md border-gray-300 bg-gray-100 shadow shadow-gray-400">
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div className="flex items-center justify-end">
                    <button
                      onClick={() => showPriceAlert(false)}
                      className="rounded-full p-2 active:bg-gray-200"
                    >
                      <CgClose className="h-5 w-5 text-gravel-500" />
                    </button>
                  </div>
                  <div className="mt-2 flex justify-start text-sm">
                    <img
                      src={MinimumBasketImage}
                      className="h-32 w-full"
                      alt=""
                    />
                  </div>
                  <div className="cursor mt-4 flex justify-center text-base font-semibold xsm:text-lg ">
                    <div className=" font-bold text-gray-800">Note</div>
                  </div>
                  <div className="mt-2 max-w-md text-center text-xs">
                    <div className="text-gray-500">
                      "Please note that our minimum purchase amount is N15,000.
                      Unfortunately, your current basket value does not meet
                      this requirement. To proceed with your purchase, kindly
                      add more items to your basket."
                    </div>
                  </div>
                  <div className="mt-5 pb-4 text-center tracking-wider xsm:px-6 sm:px-16">
                    <div
                      onClick={() => showPriceAlert(false)}
                      className="!cursor-pointer rounded-md border-[1px] border-olivine-500 px-6 py-2 text-sm font-semibold text-olivine-500 transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 active:ring-1 active:ring-olivine-500"
                    >
                      Continue shopping
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
            </div>
          </Dialog>
        </div>
      )}

      <div className="mt-32 pb-20 text-center">
        <button
          // onClick={() => handleCheckout()}
          onClick={() => handleGeneralCheckout()}
          className="w-full rounded-md bg-olivine-500 py-3 font-medium  tracking-wider text-white transition duration-300 hover:bg-olivine-600 focus:text-olivine-700 focus:outline-none"
        >
          CHECKOUT
        </button>
      </div>
    </div>
  );
};

export default OrderDetails;
