import React, { useState } from 'react';
import { HiHome } from "react-icons/hi";
import {IoIosArrowForward, IoIosArrowBack} from "react-icons/io";
import { BiPlus, BiMinus } from "react-icons/bi"
import Silver from "../../../assets/silver.png";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import DeliveryAddressPopover from './DeliveryAddressPopover';
import RecipientDetailsPopover from './RecipientDetailsPopover';
import OrderSummaryPopover from './OrderSummaryPopover';



function MyBasket() {
    const [count, setCount] = useState(3)

    if (count < 0){
        setCount(0)
    }


  return (
    <div className=' pt-8 px-3 xsm:px-4 sm:px-8 md:px-10 lg:px-16 pb-3'>
        <div className='flex items-center space-x-3 text-xs text-gray-500 font-semibold'>
            <div className='flex items-center space-x-1'>
                <div><HiHome className='h-4 w-4'/></div>
                <div><IoIosArrowForward className='h-[11px] w-[11px]'/></div>
            </div>
            <div className='flex items-center space-x-1'>
                <div>Level 1</div>
                <div><IoIosArrowForward className='h-[11px] w-[11px]'/></div>
            </div>
            <div className='flex items-center space-x-1'>
                <div>Level 2</div>
                <div><IoIosArrowForward className='h-[11px] w-[11px]'/></div>
            </div>
            <div className='flex items-center space-x-1 text-olivine-500'>
                <div>Level 3</div>
                <div><IoIosArrowForward className='h-[11px] w-[11px]'/></div>
            </div>
        </div>
        <div>
            <div className='pt-4 flex items-center space-x-2 text-sm font-semibold cursor-pointer text-olivine-500'>
                <div><IoIosArrowBack className='h-4 w-4'/></div>
                <div>Continue Shopping</div>
            </div>
        </div>
        <div className='flex items-center justify-center'>
            <div className='w-full max-w-2xl pb-16'>
                <div className="mt-6 text-xl font-bold tracking-wider text-gray-600">
                    My Basket
                </div>
                <div className='text-sm mt-4'>
                    <div className='hidden sm:grid grid-cols-4 text-gray-500 font-bold'>
                        <div className='col-span-2 grid justify-start'>PRODUCT</div>
                        <div className='col-span-1 grid justify-center'>QUANTITY</div>
                        <div className='col-span-1 grid justify-end'>TOTAL</div>
                    </div>
                    <hr className='mt-1 bg-gray-400 h-[2px]'/>
                    <div className='mt-2 flex justify-between sm:grid sm:grid-cols-4 sm:items-center font-semibold'>
                        <div className='mt-2 sm:col-span-2 grid justify-start'>
                            <div className='flex items-center space-x-2'>
                                <div><img src={Silver} className="h-9 w-9 aspect-auto object-cover rounded-md" alt=""/></div>
                                <div className='text-[11px] xsm:text-[12px] sm:text-xs font-semibold -space-y-1 sm:space-y-0 text-gray-500'>
                                    <div>MARI JADE KNIT DRESS</div>
                                    <div>₦160,000.00</div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-2 xsm:flex xsm:items-center xsm:space-x-2 sm:col-span-1 sm:grid sm:justify-center'>
                            <div className='h-6 w-20 sm:w-24 grid grid-cols-3 border-[0.5px] border-gray-400 rounded'>
                                <div className='col-span-1 grid justify-center items-center'><BiMinus onClick={()=>setCount(prev=>prev-1)} className='h-4 w-4 rounded-full text-gray-500 hover:bg-gray-100 active:bg-gray-200 cursor-pointer'/></div>
                                <div className='col-span-1 grid justify-center items-center text-gray-500 font-semibold'>{count}</div>
                                <div className='col-span-1 grid justify-center items-center'><BiPlus onClick={()=>setCount(prev=>prev+1)} className='h-4 w-4 rounded-full text-gray-500 hover:bg-gray-100 active:bg-gray-200 cursor-pointer'/></div>
                            </div>
                            <div className='mt-[1px] xsm:mt-0 sm:mt-[1px] text-[10px] grid justify-center text-red-400 hover:underline cursor-pointer'>REMOVE</div>
                        </div>
                        <div className='mt-2 hidden sm:col-span-1 sm:grid sm:justify-end'>
                            <div className='text-xs text-gray-500 font-bold'>₦160,000.00</div>
                        </div>
                    </div>
                    <div className='mt-8'>
                        <div className='text-sm font-bold tracking-wider text-gray-600'>Grocedy Savings <span>🎉</span></div>
                        <div className='mt-[2px] text-xs font-semibold tracking-wider text-gray-600'>You have saved ₦20,000 shopping with us.</div>
                    </div>
                    <div className="mt-8">
                        <label className="text-xs font-semibold text-gray-600">Additional Comments</label>
                        <textarea
                        placeholder="Write your message..."
                        rows="4"
                        className="mt-1 w-full rounded-md placeholder:text-sm text-gray-500 font-semibold text-sm bg-[#F0F2E8] placeholder:text-gray-400 border-gray-300 focus:border-olivine-500 focus:ring-olivine-500"
                        ></textarea>
                    </div>
                    <div>
                        <div className="mt-4 grid justify-end">
                            <form className=''>
                                <label className="text-sm text-gray-600 opacity-75">
                                    Coupon
                                </label>
                                <div className="mt-1 flex justify-between space-x-2">
                                    <input
                                    type="text"
                                    placeholder="Enter coupon code"
                                    className="border-1 flex-1 rounded-md text-xs xsm:text-sm text-gray-500 font-semibold border-olivine-300 placeholder:text-sm placeholder:text-gray-300 focus:border-white focus:ring-olivine-500"
                                    />
                                    <button className="rounded-md border-[1px] border-olivine-500 text-xs xsm:text-sm bg-white px-2 xsm:px-4 sm:px-6 py-2 font-medium text-olivine-500 hover:bg-olivine-100">
                                        APPLY
                                    </button>
                                </div>
                                <div className='flex flex-col text-right'>
                                    <div className='mt-6 text-xs font-semibold tracking-wider text-gray-600'>Subtotal: ₦798,000.00</div>
                                    <div className='mt-2 text-xs font-semibold tracking-wider text-gray-600'>Coupon: <span className='text-red-500'>-₦46,000.00</span></div>
                                    <div className='mt-2 text-base font-bold tracking-wider text-gray-600'>TOTAL: ₦752,000.00</div>
                                    <div className='mt-4 text-[9px] font-semibold tracking-wider text-gray-500'>*Shipping and to be calculated at checkout</div>
                                    <div className="">
                                        <Popover className="relative">
                                        {({ open }) => (
                                            <>
                                            <Popover.Button
                                                className={`
                                                                ${open ? "" : "text-opacity-90"}
                                                                w-full focus:text-olivine-700 focus:outline-none lg:w-auto`}
                                            >
                                                <div className="mt-[1px] px-10 py-3 rounded-md font-semibold bg-olivine-500 text-sm text-white tracking-wider hover:bg-olivine-600 transition duration-300">
                                                    <div>PROCEED TO CHECKOUT</div>
                                                </div>
                                            </Popover.Button>
                                            <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1"
                                            >
                                                <Popover.Panel className="absolute z-50 -mt-[530px] left-20 xsm:left-5 sm:left-auto sm:right-44 lg:right-80 w-48 md:w-72 max-w-sm -translate-x-1/2">
                                                {({ close }) => (
                                                    <div>
                                                    <RecipientDetailsPopover/>
                                                    </div>
                                                )}
                                                </Popover.Panel>
                                            </Transition>
                                            </>
                                        )}
                                        </Popover>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MyBasket