import React, { useContext } from "react";
import CurrencyFormat from "react-currency-format";
import { useLocation, useNavigate } from "react-router-dom";
import RxjsStoreContext from "../../context/RxjsStoreContext";
import toast from "react-hot-toast";

import { FaPlus } from "react-icons/fa";
import { HiOutlineHeart, HiHeart } from "react-icons/hi";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AccountImage from "../../assets/LogIntoYourAccount.svg";
import { CgClose } from "react-icons/cg";
import { authService } from "../../services/authService";
import LogicContext from "../../context/LogicContext";

function CreateBasketCardButchersHandPick(props) {
  let navigate = useNavigate();
  const rxjsStore = useContext(RxjsStoreContext);
  const [open, setOpen] = React.useState(false);
  const logicContext = useContext(LogicContext);
  const location = useLocation();
  const { product } = props;

  const viewItemDetails = (itemId) => {
    navigate(`/butchers/create-basket/selected-basket/${itemId}`);
  };

  const addToBasket = (product, quantity) => {
    if (product.item_stock_out === true) {
      return toast.error("Item is out of stock!");
    }
    rxjsStore.setUserSelectedBasket({ ...product, type: "BUTCHER" }, quantity);
    toast.success(
      `${quantity} qty of ${product.name} has been added to basket!`
    );
  };

  const performFavorite = (productId, option) => {
    if (authService.isLogin()) {
      if (option === 0) {
        rxjsStore.performFavoriteButchers(productId, 1);
      } else {
        rxjsStore.performFavoriteButchers(productId, 0);
      }
    } else {
      setOpen(true);
    }
  };

  const continueToLogin = () => {
    logicContext.setPreviousUrl(location.pathname);
    navigate("/login");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section>
      <div>
        <Dialog
          className="h-full pb-16"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="h-hug w-full rounded-md border-gray-300 bg-gray-100 shadow shadow-gray-400">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="flex items-center justify-end">
                  <button
                    onClick={handleClose}
                    className="rounded-full p-2 active:bg-gray-200"
                  >
                    <CgClose className="h-5 w-5 text-gravel-500" />
                  </button>
                </div>
                <div className="mt-2 flex justify-start text-sm">
                  <img src={AccountImage} className="h-40 w-full" alt="" />
                </div>
                <div className="cursor mt-4 flex justify-center text-base font-semibold xsm:text-lg ">
                  <div className=" font-bold text-gray-800">
                    Log into your account{" "}
                  </div>
                </div>
                <div className="mt-2 text-center text-xs">
                  <div className="text-gray-500">
                    You are currently not logged into your Grocedy account.
                    Please login or create a new account to proceed to check
                    out.
                  </div>
                </div>
                <div
                  onClick={continueToLogin}
                  className="mt-5 pb-4 text-center tracking-wider xsm:px-6 sm:px-16"
                >
                  <div className="rounded-md border-[1px] border-olivine-500 py-2 text-sm font-semibold text-olivine-500 transition duration-300 active:ring-1 active:ring-olivine-500">
                    Log into Account
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
      </div>
      {/* end alert */}
      <div className="mr-6 w-[250px] overflow-hidden rounded-md border-[0.5px] border-gray-200 bg-white shadow-sm shadow-gray-300 transition-all duration-300 ease-in">
        <div className="flex">
          <div className="relative">
            <div
              onClick={() => viewItemDetails(product?.item_id)}
              className="w-[100px] bg-white"
            >
              <img
                src={product?.item_image_url}
                className="cursor aspect-auto h-[90px] w-[160px] object-contain"
                alt=""
              />
            </div>
            <div className="group">
              <div
                onClick={() => viewItemDetails(product?.item_id)}
                className="cursor absolute right-0 top-0 h-full w-full rounded-md border-[1px] border-gray-200 transition duration-200"
              ></div>
              <div className="group absolute right-1 top-1 cursor-pointer rounded-full bg-olivine-500  p-1 transition duration-200 hover:scale-125">
                <FaPlus
                  onClick={() => addToBasket(product, 1)}
                  className="h-4 w-4 text-white"
                />
              </div>
              {product?.item_stock_out === true && (
                <p className="absolute left-0 top-2 rounded-r bg-[#E53448] px-1 py-[2px] text-[9px] font-semibold text-white">
                  Out of Stock
                </p>
              )}
            </div>
          </div>
          <div className="mt-2 w-full">
            <div className="flex items-center justify-between space-x-2 px-2">
              <h1
                title={product?.name}
                onClick={() => viewItemDetails(product?.item_id)}
                className="mt-2 line-clamp-1 cursor-pointer text-xs font-bold text-gray-600"
              >
                {product?.name}
              </h1>
              {authService.isLogin() && (
                <div
                  onClick={() =>
                    performFavorite(product?.item_id, product?.favourite_status)
                  }
                  className=""
                >
                  {product?.favourite_status === 1 ? (
                    <HiHeart className="aspect-auto h-5 w-5  cursor-pointer rounded-md object-cover text-red-400" />
                  ) : (
                    <HiOutlineHeart className="aspect-auto h-5 w-5  cursor-pointer rounded-md object-cover text-gray-500" />
                  )}
                </div>
              )}
            </div>
            <div
              title={`₦ ${Number.parseFloat(product?.item_rate_zoho)?.toFixed(
                2
              )} / ${product?.sku}`}
              onClick={() => viewItemDetails(product?.item_id)}
              className="mt-1 line-clamp-1 cursor-pointer px-2 text-xs font-semibold"
            >
              <div className="text-gray-600">
                {" "}
                <CurrencyFormat
                  className={"font-bold"}
                  value={Number.parseFloat(product?.item_rate_zoho)?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
                / {product?.sku}{" "}
              </div>
            </div>
            <div className="px-2 pb-2 ">
              <h4 className="cursor mt-1 text-[10px] font-semibold text-red-400">
                Market Price:{" "}
                <strike className="text-[10px]">
                  <CurrencyFormat
                    className={"font-semibold "}
                    value={Number.parseFloat(
                      product?.item_rate_market
                    )?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </strike>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateBasketCardButchersHandPick;
