import search from "../../../assets/search.png"
import backArrow from "../../../assets/back.PNG"
import clock from "../../../assets/clock.png"
import GrainBasketComponet from "./GrainBasketComponet";



function GrocerySearchContent() {
    return (<div className='mt-16 px-4 sm:px-8 md:px-16' >


        <div className="my-4">

            <img className="h-10 text-black " src={backArrow} />
        </div>

        <div className="relative rounded-md">
            <div className="inset-y-0 pl-3">
                <img className="absolute mt-5 h-7 w-7 text-gray-600" src={search} />
            </div>
            <input
                type="text"
                placeholder="Search"
                id="search"
                className=" h-[60px] block w-full mt-1 rounded-md border-gray-400 font-semibold text-xl text-gray-600 bg-gray-100 pl-14 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
            />
        </div>

        <div>
            <GrainBasketComponet />
        </div>
        <section className="pb-2">
            <div className='text-xl font-bold text-gravel-500'>Trending Searches</div>

            <section className="flex mt-3">
                <div>
                    <img className="text-[#4F4F4F] mt-2 " src={clock} />
                </div>
                <div className='searchText ml-2'>
                    Trending Searches which may exceed
                    one line in some cases.
                </div>
            </section>
            <section className="flex mt-3">
                <div>
                    <img className="text-[#4F4F4F] mt-2 " src={clock} />
                </div>
                <div className='searchText ml-2'>
                    Trending Searches which may exceed
                    one line in some cases.
                </div>
            </section>
            <section className="flex mt-3">
                <div>
                    <img className="text-[#4F4F4F] mt-2 " src={clock} />
                </div>
                <div className='searchText ml-2'>
                    Trending Searches which may exceed
                    one line in some cases.
                </div>
            </section>

        </section>
    </div>);
}

export default GrocerySearchContent;