import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "../../services/authService";
import CartService from "../../services/cartService";
import { startLoad, stopLoad } from "../slices/appSlice";
import { handleError } from "../slices/errorSlice";
import { getWalletBalance } from "./userActions";

const user_email = authService.getCurrentUserEmail();
const verification_code = authService.getCurrentUserVerificationCode();
const userId = authService.getCurrentUserID();
const customerId = authService.getCurrentUserCustomerId();

export const productPurchaseSet = createAsyncThunk(
  "post/planProductPurchaseSet",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.productPurchase(data);

      const refreshData = {
        user_email,
      };

      dispatch(productPurchaseGet(refreshData));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const productPurchaseGet = createAsyncThunk(
  "get/planProductPurchaseGet",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.getCart(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const cartItemDelete = createAsyncThunk(
  "delete/cartItemDelete",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.deleteCartItem(data);

      const payload = {
        user_email,
      };

      dispatch(productPurchaseGet(payload));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const cartItemsDelete = createAsyncThunk(
  "delete/cartItemsDelete",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.deleteAllCartItems(data);

      const payload = {
        user_email,
      };

      dispatch(productPurchaseGet(payload));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const cartItemUpdate = createAsyncThunk(
  "update/cartItemUpdate",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const { itemId, user_email, data } = payload;
      const res = await CartService.updateCartItem(itemId, user_email, data);

      const refreshData = {
        user_email,
      };

      dispatch(productPurchaseGet(refreshData));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const checkout = createAsyncThunk(
  "set/checkout",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.checkout(data);

      const payload = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };

      dispatch(getWalletBalance(payload));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const deductDelivery = createAsyncThunk(
  "set/checkout/delivery",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.deductDeliveryCharge(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const checkoutBasket = createAsyncThunk(
  "set/checkoutbasket",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.checkoutBasket(data);

      const payload = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };

      dispatch(getWalletBalance(payload));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const giftCheckoutBasket = createAsyncThunk(
  "set/checkoutGiftbasket",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.checkoutGiftBasket(data);

      const payload = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };

      dispatch(getWalletBalance(payload));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const verifyCoupon = createAsyncThunk(
  "check/coupon",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.verifyCoupon(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const createSubscriptionNote = createAsyncThunk(
  "create/subscription/note",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.createSubscriptionNote(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getPaymentTransaction = createAsyncThunk(
  "get/payment/transactions",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.paymentTransactions(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getPaymentNotification = createAsyncThunk(
  "get/payment/notification",
  async (_, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.paymentNotifications();

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getAbandonCart = createAsyncThunk(
  "post/abandon/cart",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.abandonCart(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getSingleSalesorder = createAsyncThunk(
  "get/single/salesorder",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.singleSalesorderGet(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getSingleSubscription = createAsyncThunk(
  "get/single/subscription",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.singleSubscriptionGet(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const stockCheck = createAsyncThunk(
  "post/stock/check",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await CartService.checkStock(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
