import { createAsyncThunk } from "@reduxjs/toolkit";
import { payload } from "../../core/helpers";
import { authService } from "../../services/authService";
import VasService from "../../services/vasService";
import { startLoad, stopLoad } from "../slices/appSlice";
import { handleError } from "../slices/errorSlice";
import { getTransactions } from "./paymentActions";
import { getWalletBalance } from "./userActions";

export const buyAirtime = createAsyncThunk(
  "post/buyAirtime",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VasService.buyAirtime(data);

      const customerId = authService.getCurrentUserCustomerId();

      dispatch(getTransactions(customerId?.toString()));
      dispatch(getWalletBalance(payload));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const buyData = createAsyncThunk(
  "post/buyData",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VasService.buyData(data);

      const customerId = authService.getCurrentUserCustomerId();

      dispatch(getTransactions(customerId?.toString()));
      // dispatch(getWalletBalance(payload));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const payBills = createAsyncThunk(
  "post/payBills",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VasService.payBills(data);
      const customerId = authService.getCurrentUserCustomerId();

      dispatch(getTransactions(customerId?.toString()));
      dispatch(getWalletBalance(payload));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const customerEnquire = createAsyncThunk(
  "post/customerEnquire",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VasService.customerEnquiry(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const billerGroup = createAsyncThunk(
  "post/biller-group",
  async (_, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VasService.billerGroups();

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const billerGroupEnquiry = createAsyncThunk(
  "post/biller-group-enquiry",
  async (id, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VasService.billerGroupEnquiry(id);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const billerEnquiry = createAsyncThunk(
  "get/biller-enquiry",
  async (id, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VasService.getBillerPlans(id);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const convenienceFees = createAsyncThunk(
  "get/convenience-fees",
  async (id, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VasService.getConvenienceFees(id);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const delgroAirtimePurchase = createAsyncThunk(
  "post/delgro_airtime_purchase",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VasService.purchaseDelgroAirtime(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const delgroDataPurchase = createAsyncThunk(
  "post/delgro_data_purchase",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VasService.purchaseDelgroData(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const delgroPaybillsPurchase = createAsyncThunk(
  "post/delgro_paybills_purchase",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VasService.purchaseDelgroPaybills(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
