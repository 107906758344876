import React, { useState, useContext, useEffect } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import GiftABasketImage from "../../assets/gifts-2.jpg";
import { FaPlus } from "react-icons/fa";
import { HiHeart, HiOutlineHeart } from "react-icons/hi";
import HomePageButchersComponent from "./HomePageButchersComponent";
import RxjsStoreContext from "../../context/RxjsStoreContext";
import BasketService from "../../services/basketService";
import { useNavigate } from "react-router-dom";

function HomePageButchers() {
  const [butchersList, setButchersList] = useState([]);
  const rxjsStore = useContext(RxjsStoreContext);
  const navigate = useNavigate();

  useEffect(() => {
    rxjsStore.getAllButcherProductList().subscribe((allProduct) => {
      if (allProduct?.length < 1) {
        return callApi();
      }

      let lenn = allProduct.length - 1;
      let randNum = getRandomInt(2, 4);
      let start = getRandomInt(9, 15);
      if (randNum - start < 0) {
        start = 0;
      }
      const selections = allProduct?.slice(start, randNum);
      setButchersList(selections);
    });
  }, []);

  function getRandomInt(min, max) {
    return Math.random() * (max - min) + min;
  }

  function callApi() {
    BasketService.getButcherList()
      .then((data, failed) => {
        if (failed) {
          return failed;
        }

        const { item_group, items, delivery_charge } = data;
        if (item_group?.length) {
          rxjsStore.setButcherGroupList(item_group);
          rxjsStore.setAllButchersProductList(items);
          let charge = {
            item_id: delivery_charge[0]?.item_id,
            item_total: delivery_charge[0]?.item_rate,
            rate: delivery_charge[0]?.item_rate,
            quantity: 1,
            name: "Delivery charge",
          };
          rxjsStore.setDeliveryChargeButcher(charge);
        }
      })
      .catch((err) => {});
  }

  const createButcher = () => {
    navigate("/butchers/create-basket");
  };

  return (
    <div className="mx-auto mt-20 max-w-[1600px] bg-[#EBEDE6] py-24">
      <div className="mx-auto max-w-[2520px] px-3 xsm:px-4 sm:px-8 md:px-10 lg:px-16">
        <div className="text-2xl font-bold text-gravel-500">The Butchers</div>

        <div className="mt-6 grid gap-6 grid-auto-fit-sm ">
          {butchersList?.map((product, idx) => {
            return (
              <>
                <HomePageButchersComponent product={product} />
              </>
            );
          })}

          <div className="h-full w-full overflow-hidden rounded-md border-[0.5px] border-gray-300 bg-olivine-500 text-white shadow-sm shadow-gray-300 transition-all duration-300 ease-in @container">
            <div className="flex flex-col p-4">
              <div className="flex flex-col items-start">
                <div className=" rounded-full bg-white p-2">
                  <FaPlus className="h-6 w-6 text-olivine-500 @xs:h-5 @xs:w-5" />
                </div>
                <h2 className="mt-6 text-xl font-bold @xs:mt-3">
                  The Butchers
                </h2>
                <p className="@mt-2 mt-4 text-xs">
                  Shop the best cuts of meat and Poultry items
                </p>
              </div>
              <button
                onClick={() => createButcher()}
                className="mt-28 w-full transform cursor-pointer rounded-md border-[1px] border-white py-[15px] text-xs font-semibold text-white transition-all duration-200 hover:-translate-y-0.5 hover:bg-olivine-400 focus:outline-none active:bg-olivine-500 @xs:mt-4 xsm:text-sm sm:w-auto sm:px-8 sm:py-[11px]"
              >
                Get Started
              </button>
            </div>
          </div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default HomePageButchers;
