import React, { useState, useEffect, useContext } from "react";
import Logo from "../assets/logo.svg";
import { MdEmail } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { authService } from "../services/authService";
import LogicContext from "../context/LogicContext";
import CircularProgress from "@mui/material/CircularProgress";
import { FaPhone } from "react-icons/fa";
import PasswordChecklist from "react-password-checklist";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch } from "@headlessui/react";

let tempUserProps = {};

function ForgotPassword() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const logicContext = useContext(LogicContext);
  const [isLoading, setIsLoading] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [allowPasswordChange, setAllowPasswordChange] = useState(false);
  const [otpInput, setOtpInput] = useState(new Array(6).fill(""));
  const [usePhone, setUsePhone] = useState(false);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {}, []);

  const submitEmailToGetToken = async () => {
    const formData = getValues();
    if (!formData?.user_email_address) return toast.error("Email is required!");

    setIsLoading(true);
    try {
      const payload = {
        sql_ref_no: "6",
        user_email_address: formData?.user_email_address,
        option: "2",
      };
      authService
        .forgotPassword(payload)
        .then((apiResponse) => {
          setIsLoading(false);
          const filteredData = logicContext.httpResponseManager(
            apiResponse?.data?.result_status[0]
          );

          if (filteredData?.message_id === 1) {
            tempUserProps = apiResponse.data?.user_information[0];
            setAllowPasswordChange(true);
            toast.success(`Verification Code has been sent to your Email.`);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } catch (err) {
      return err;
    }
  };

  function onErrors(error) {
    toast.error("Email is required");
  }

  const submitPhoneToGetToken = async () => {
    const formData = getValues();
    if (!formData?.user_phone) return toast.error("Phone is required!");

    setIsLoading(true);
    try {
      authService
        .requestMobileOTPtoken({ user_name: formData?.user_phone })
        .then((apiResponse) => {
          setIsLoading(false);
          const filteredData = logicContext.httpResponseManager(
            apiResponse?.data?.result_status[0]
          );

          if (filteredData?.message_id === 1) {
            tempUserProps = apiResponse.data?.user_information[0];
            setAllowPasswordChange(true);
            toast.success(`Verification Code has been sent to your Phone!.`);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } catch (err) {
      return err;
    }
  };

  function onErrors(error) {
    toast.error("Phone is required");
  }

  const handleOtpInputChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtpInput([
      ...otpInput.map((d, idx) => (idx === index ? element.value : d)),
    ]);

    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handlePaste = (event) => {
    const pasted = event.clipboardData.getData("text/plain");
    setOtpInput(pasted.split("").slice(0, otpInput.length));
  };

  const confirmOtpManager = async () => {
    let payload = {
      sql_ref_no: "7",
      user_id: tempUserProps?.user_id,
      verification_code: otpInput.join(""),
      option: "1",
    };
    setIsLoading(true);
    const response = await authService.emailVerification(payload);
    setIsLoading(false);
    //  if()
    const filteredData = logicContext.httpResponseManager(response?.data);
    if (filteredData?.message_id === 1) {
      authService.setToken(filteredData?.access_token);
      navigate("/newPassword");
    }
  };

  return (
    <div className="h-screen w-screen px-2 py-4 xsm:px-4 sm:px-8">
      <div className="">
        <Link to="/">
          <img
            src={Logo}
            className="aspect-auto h-7 w-8 cursor-pointer object-cover transition duration-300 hover:opacity-80 sm:h-11 sm:w-12"
            alt=""
          />
        </Link>
      </div>
      <div className="mt-8 flex items-center justify-center">
        <div className="w-full max-w-md rounded-xl bg-[#F6F5F5] px-3 py-6 ring-[1px] ring-gray-200 xsm:px-6 sm:px-8">
          <div>
            <div className="text-xl font-bold text-gray-600">
              Forgot Password
            </div>
            <div className="mb-4">
              <div className="mt-4 flex items-center space-x-2">
                <Switch
                  checked={usePhone}
                  onChange={() => setUsePhone(!usePhone)}
                  className={`${usePhone ? "bg-olivine-500" : "bg-olivine-500"}
                    relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${usePhone ? "translate-x-5" : "translate-x-0"}
                      pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
                {usePhone ? (
                  <p className="text-sm font-medium text-gray-600">Use Email</p>
                ) : (
                  <p className="text-sm font-medium text-gray-600">
                    Use Phone Number
                  </p>
                )}
              </div>
            </div>
            <div className="mt-3 text-xs text-gray-500 sm:text-sm">
              Send a verification code to your registered email/phone.
            </div>
          </div>
          {!usePhone && (
            <div className="mt-6">
              {/* Submit email form */}
              {allowPasswordChange === false ? (
                <form onSubmit={handleSubmit(submitEmailToGetToken, onErrors)}>
                  <div>
                    <label
                      htmlFor="email"
                      className="ml-3 text-xs text-gray-500"
                    >
                      Email Address
                    </label>
                    <div className="relative rounded-md">
                      <div className="inset-y-0 pl-3">
                        <MdEmail className="absolute mt-3 h-5 w-5 text-gray-600" />
                      </div>
                      <input
                        type="email"
                        placeholder="Enter your email address"
                        id="email"
                        {...register("user_email_address", {
                          required: "Email is required!",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email address",
                          },
                        })}
                        className="block w-full rounded-md border-gray-400 bg-white pl-10 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      <small className="text-red-600">
                        {errors.user_email_address?.message}
                      </small>
                    </div>
                  </div>
                  <button
                    disabled={isLoading}
                    className="mt-8 w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600"
                  >
                    {isLoading === true ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "SEND CODE "
                    )}
                  </button>
                </form>
              ) : (
                // Reset Password Form
                <>
                  <form onSubmit={handleSubmit}>
                    <div className="relative mt-8 flex justify-center space-x-2 rounded-md">
                      {otpInput &&
                        otpInput.length &&
                        otpInput?.map((data, index) => {
                          return (
                            <input
                              type="text"
                              maxLength={1}
                              className="block h-8 w-8 items-center justify-center rounded-md bg-white text-center text-[12px] focus:border-olivine-500 focus:ring-olivine-500 sm:h-10 sm:w-10 sm:text-lg"
                              name="opt"
                              key={index}
                              value={data}
                              onChange={(e) =>
                                handleOtpInputChange(e.target, index)
                              }
                              onPaste={handlePaste}
                              onFocus={(e) => e.target.select()}
                            />
                          );
                        })}
                    </div>
                    {!allowPasswordChange && (
                      <button className="mt-8 w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600">
                        {isLoading === true ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "VERIFY EMAIL ADDRESS "
                        )}
                      </button>
                    )}
                  </form>

                  {allowPasswordChange && (
                    <button
                      onClick={() => confirmOtpManager()}
                      className="mt-8 w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600"
                    >
                      {isLoading === true ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Confirm OTP "
                      )}
                    </button>
                  )}
                </>
              )}
            </div>
          )}

          {/* Phone number */}
          {usePhone && (
            <div className="mt-6">
              {/* Submit email form */}
              {allowPasswordChange === false ? (
                <form onSubmit={handleSubmit(submitPhoneToGetToken, onErrors)}>
                  <div>
                    <label
                      htmlFor="email"
                      className="ml-3 text-xs text-gray-500"
                    >
                      Phone Number
                    </label>
                    <div className="relative rounded-md">
                      <div className="inset-y-0 pl-3">
                        <FaPhone className="absolute mt-3.5 h-4 w-4 rotate-180 text-gray-600" />
                      </div>
                      <input
                        type="phone"
                        placeholder="Enter phone number"
                        id="phone"
                        {...register("user_phone", {
                          required: "Phone is required!",
                          pattern: {
                            value: /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/,
                            message: "Invalid phone number",
                          },
                        })}
                        className="block w-full rounded-md border-[1px] border-gray-400 bg-white py-2.5 pl-10 text-sm text-gray-600 placeholder:text-gray-400 focus:border-olivine-500 focus:outline-none focus:ring-1 focus:ring-olivine-500"
                      />
                      <small className="text-red-600">
                        {errors.user_phone?.message}
                      </small>
                    </div>
                  </div>
                  <button
                    disabled={isLoading}
                    className="mt-8 w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600"
                  >
                    {isLoading === true ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "SEND CODE "
                    )}
                  </button>
                </form>
              ) : (
                // Reset Password Form
                <>
                  <form onSubmit={handleSubmit}>
                    <div className="relative mt-8 flex justify-center space-x-2 rounded-md">
                      {otpInput &&
                        otpInput.length &&
                        otpInput?.map((data, index) => {
                          return (
                            <input
                              type="text"
                              maxLength={1}
                              className="block h-8 w-8 items-center justify-center rounded-md bg-white text-center text-[12px] focus:border-olivine-500 focus:ring-olivine-500 sm:h-10 sm:w-10 sm:text-lg"
                              name="opt"
                              key={index}
                              value={data}
                              onChange={(e) =>
                                handleOtpInputChange(e.target, index)
                              }
                              onPaste={handlePaste}
                              onFocus={(e) => e.target.select()}
                            />
                          );
                        })}
                    </div>
                    {!allowPasswordChange && (
                      <button className="mt-8 w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600">
                        {isLoading === true ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "VERIFY EMAIL ADDRESS "
                        )}
                      </button>
                    )}
                  </form>

                  {allowPasswordChange && (
                    <button
                      onClick={() => confirmOtpManager()}
                      className="mt-8 w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600"
                    >
                      {isLoading === true ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Confirm OTP "
                      )}
                    </button>
                  )}
                </>
              )}
            </div>
          )}

          <div className="mt-6 text-sm">
            <div className=" text-gray-500">
              Didn’t receive a code?
              <span
                onClick={() => submitEmailToGetToken()}
                className="cursor-pointer px-3 font-semibold text-olivine-500 transition-all duration-300 hover:text-olivine-600"
              >
                RESEND CODE
              </span>
            </div>
            <div className="mt-4 font-semibold text-olivine-500 transition-all duration-300 hover:text-olivine-600">
              <Link to="/login">LOG IN</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
