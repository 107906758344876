import React, { Fragment, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Dstv from "../assets/DSTV.png";
import Gotv from "../assets/Gotv.png";
import Ekedc from "../assets/EKEDC.png";
import Ikdc from "../assets/IKDC.png";
import {
  billerEnquiry,
  billerGroupEnquiry,
  customerEnquire,
  // getBillerInfos,
  getUserInfo,
} from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-hot-toast";
import { authService } from "../services/authService";
import { Popover, Transition } from "@headlessui/react";
import PaymentConfirmationPopover from "./PaymentConfirmationPopover";
import { toast } from "react-hot-toast";

function PayBillsPopover_Two({ closer }) {
  const dispatch = useDispatch();
  const { biller, billerGrpEnq, billerEq } = useSelector((state) => state.vas);
  // const { billerInfos } = useSelector((state) => state.masterData);
  const workingEnviron = process.env.REACT_APP_ENVIRONMENT;
  // const { walletBalance } = useSelector((state) => state.user);

  const [fetchedBillerGroups, setFetchedBillergroups] = useState([]);
  const [fetchedBillerEnquiry, setFetchedBillerEnquiry] = useState([]);
  const [fetchedBillerPackages, setFetchedBillerPackages] = useState([]);
  const [selectedBillerGroup, setSelectedBillerGroup] = useState();
  const [selectedBillerGroupEnq, setSelectedBillerGroupEnq] = useState();
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedPackageId, setSelectedPackageId] = useState("");
  const [billerName, setBillerName] = useState("");
  const [billerProduct, setBillerProduct] = useState("");
  const [selectedPackagePrice, setSelectedPackagePrice] = useState(0);
  const [billerSlug, setBillerSlug] = useState("");
  const [price, setPrice] = useState();
  const [referenceNumber, setReferenceNumber] = useState("");
  const [topBillerCategory, setTopBillerCategory] = useState("");
  const [topBiller, setTopBiller] = useState("");
  const [customerInfo, setCustomerInfo] = useState("");
  // const [coralPayInfo, setCoralPayInfo] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});

  // const [fetchedProductInfo, setFetchedProductInfo] = useState("");
  const { userInfo } = useSelector((state) => state.masterData);

  const phone =
    userInfo &&
    userInfo?.customer_info?.length &&
    userInfo?.customer_info[0]?.customer_mobile_no;
  const verification_code = authService.getCurrentUserVerificationCode();
  const customerId = authService.getCurrentUserCustomerId();
  const userId = authService.getCurrentUserID();

  useEffect(() => {
    if (!userInfo) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "7",
      };

      dispatch(getUserInfo(data));
    }
  }, [dispatch, userInfo, customerId, userId, verification_code]);

  const handleChange = (e) => {
    setPrice(e.target.value.replace(/[^0-9]/g, ""));
  };

  // Get the different biller groups (PAID TV, DISCO, BETS etc...)
  useEffect(() => {
    if (biller) {
      const filteredBiller =
        biller &&
        biller?.length &&
        biller?.filter((bill) => bill.name !== "Airtime and Data");
      setFetchedBillergroups(filteredBiller);
    }
  }, [biller, dispatch]);

  // useEffect(() => {
  //   if (!billerInfos) {
  //     dispatch(getBillerInfos());
  //   }

  //   if (billerInfos) setFetchedProductInfo(billerInfos?.product_info);
  // }, [billerInfos, dispatch]);

  // Get the different billers based on the biller group selected..
  useEffect(() => {
    if (selectedBillerGroup) {
      dispatch(billerGroupEnquiry(selectedBillerGroup));
    }
  }, [selectedBillerGroup, dispatch]);

  // Get the different packages from the biller selected
  useEffect(() => {
    if (selectedBillerGroupEnq) {
      dispatch(billerEnquiry(selectedBillerGroupEnq));
    }
  }, [selectedBillerGroupEnq, dispatch]);

  useEffect(() => {
    if (billerGrpEnq) {
      setFetchedBillerEnquiry(billerGrpEnq);
    }
  }, [billerGrpEnq, dispatch]);

  useEffect(() => {
    if (billerEq) {
      setFetchedBillerPackages(billerEq);
    }
  }, [billerEq, dispatch]);

  // Filter for the price of the selected product
  useEffect(() => {
    if (selectedPackage) {
      const billPackage =
        fetchedBillerPackages &&
        fetchedBillerPackages?.length &&
        fetchedBillerPackages?.filter(
          (billerPackage) => billerPackage?.slug === selectedPackage
        );

      setSelectedPackagePrice(billPackage[0]?.amount);
    }
  }, [selectedPackage, fetchedBillerPackages]);

  const handlebillerGroup = (event) => {
    setSelectedBillerGroup(event.target.value);
    const selectedBillerGroup = fetchedBillerGroups
      ?.filter(
        (billerGroup) => billerGroup?.id?.toString() === event.target.value
      )
      .pop();

    setSelectedGroup(selectedBillerGroup);
  };

  const handlebillerGroupEnquiry = (event) => {
    const billerSlug = event.target.value.split(".")[1];
    const billerId = event.target.value.split(".")[0];
    const billername = event.target.value.split(".")[2];

    setSelectedBillerGroupEnq(billerId);
    setBillerSlug(billerSlug);
    setBillerName(billername);

    const filteredCategory = fetchedBillerEnquiry
      ?.filter((category) => category?.id?.toString() === billerId)
      .pop();

    setSelectedCategory(filteredCategory);
  };

  const handlePackageInfo = (event) => {
    // setSelectedPackage(event.target.value);
    const productSlug = event.target.value.split(".")[1];
    const productId = event.target.value.split(".")[0];
    const productname = event.target.value.split(".")[2];

    setSelectedPackageId(productId);
    setBillerProduct(productname);
    setSelectedPackage(productSlug);

    // const modifyProductName = productname?.split("_").join(" ");

    // const selectedPackageDetails = fetchedProductInfo?.filter(
    //   (prdInfo) =>
    //     prdInfo?.biller_product_name?.toLowerCase() ===
    //     modifyProductName?.toLowerCase()
    // );

    // setCoralPayInfo(selectedPackageDetails);

    const filteredProduct = fetchedBillerPackages
      ?.filter((product) => product?.id?.toString() === productId)
      .pop();

    setSelectedProduct(filteredProduct);
  };

  const handleTopBiller = (slug, id, category, biller, billerGroup) => {
    const billerSlug = slug;
    const billerId = id;

    setSelectedBillerGroup(billerGroup);

    setSelectedBillerGroupEnq(billerId);
    setBillerSlug(billerSlug);
    setTopBillerCategory(category);
    setTopBiller(biller);
  };

  const handleCustomerEnquiry = (e) => {
    if (e.target.value?.length < 1) {
      return toast.error("  Please enter a reference number!");
    }

    e.preventDefault();
    try {
      const toastId = toast.loading("Fetching Customer Info...");
      const data = {
        packageSlug: selectedPackage,
        referenceNumber,
        billerSlug,
      };
      dispatch(customerEnquire(data))
        .unwrap()
        .then((res) => {
          if (res && res?.status !== "success") {
            return toast.error(res.message, {
              id: toastId,
            });
          }
          setCustomerInfo(res?.responseData?.customer?.customerName);
          return toast.success(res.message, {
            id: toastId,
          });
        })
        .catch((err) => {
          return toast.error("Customer info could not be fetched...", {
            id: toastId,
          });
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold text-gray-600">Bill Payment</div>
        <button
          onClick={() => closer()}
          className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="mt-4 flex justify-start text-sm font-semibold text-gravel-500">
        Top Billers
      </div>
      <div className="mt-2">
        <div className="flex items-center space-x-2 sm:space-x-3">
          <div
            onClick={
              workingEnviron === "development"
                ? () => handleTopBiller("DSTV", "2", "PaidTV", "Dstv", "2")
                : () => handleTopBiller("DSTV", "3", "PAY TV", "DSTV", "3")
            }
            className="h-20 w-20 cursor-pointer space-y-1 rounded-md border border-gray-200 bg-white pt-5 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex justify-center">
              <img
                src={Dstv}
                className="aspect-auto w-9 bg-[#F9F9F9] object-cover xsm:w-14"
                alt=""
              />
            </div>
            <div className="flex justify-center text-[10px] xsm:text-[11px]">
              DSTV
            </div>
          </div>
          <div
            onClick={
              workingEnviron === "development"
                ? () => handleTopBiller("GOTV", "3", "PaidTV", "GOtv", "2")
                : () => handleTopBiller("GOTV", "3", "PAY TV", "GOTV", "3")
            }
            className="h-20 w-20 cursor-pointer space-y-1 rounded-md border border-gray-200 bg-white pt-5 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex justify-center">
              <img
                src={Gotv}
                className="aspect-auto w-9 bg-[#F9F9F9] object-cover xsm:w-14"
                alt=""
              />
            </div>
            <div className="flex justify-center text-[10px] xsm:text-[11px]">
              GOTV
            </div>
          </div>
          <div
            onClick={() =>
              workingEnviron === "development"
                ? handleTopBiller("EKO_DISCO", "1", "Disco", "Eko Disco", "1")
                : handleTopBiller("EKEDC", "4", "ELECTRIC DISCO", "EKEDC", "2")
            }
            className="h-20 w-20 cursor-pointer space-y-1 rounded-md border border-gray-200 bg-white pt-5 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex justify-center">
              <img
                src={Ekedc}
                className="aspect-auto w-9 bg-[#F9F9F9] object-cover xsm:w-14"
                alt=""
              />
            </div>
            <div className="flex justify-center text-[10px] xsm:text-[11px]">
              EKEDC
            </div>
          </div>
          <div
            onClick={() =>
              workingEnviron === "development"
                ? handleTopBiller("IKEDC", "77", "Disco", "IKEDC", "1")
                : handleTopBiller("IKEDC", "2324", "Disco", "IKEDC", "2")
            }
            className="h-20 w-20 cursor-pointer space-y-1 rounded-md border border-gray-200 bg-white pt-5 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex justify-center">
              <img
                src={Ikdc}
                className="aspect-auto w-5 bg-[#F9F9F9] object-cover xsm:w-8"
                alt=""
              />
            </div>
            <div className="flex justify-center text-[10px] xsm:text-[11px]">
              IKEDC
            </div>
          </div>
        </div>
        <form>
          <div className="mt-3">
            <label
              htmlFor="title"
              className="ml-2 text-[10px] font-semibold xsm:text-xs"
            >
              Biller Category
            </label>
            <div className="relative mt-[1px] flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <select
                  onChange={handlebillerGroup}
                  type="text"
                  className="block w-full cursor-pointer rounded-md border-gray-300 bg-white pl-4 pr-10 text-gray-500 placeholder:text-sm optional:text-sm optional:font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                >
                  <optgroup className="rounded-lg bg-olivine-100">
                    {topBillerCategory ? (
                      <option>{topBillerCategory}</option>
                    ) : (
                      <option>Category</option>
                    )}
                    {fetchedBillerGroups &&
                      fetchedBillerGroups?.length &&
                      fetchedBillerGroups?.map((billerGrps, idx) => (
                        <option key={idx} value={billerGrps.id}>
                          {billerGrps?.name}
                        </option>
                      ))}
                  </optgroup>
                </select>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <label
              htmlFor="title"
              className="ml-2 text-[10px] font-semibold xsm:text-xs"
            >
              Biller
            </label>
            <div className="relative mt-[1px] flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <select
                  onChange={handlebillerGroupEnquiry}
                  type="text"
                  className="block w-full cursor-pointer rounded-md border-gray-300 bg-white pl-4 pr-10 text-gray-500 placeholder:text-sm optional:text-sm optional:font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                >
                  <optgroup className="rounded-lg bg-olivine-100">
                    {topBiller ? (
                      <option>{topBiller}</option>
                    ) : (
                      <option>Biller</option>
                    )}
                    {fetchedBillerEnquiry &&
                      fetchedBillerEnquiry?.length &&
                      fetchedBillerEnquiry?.map((billerGrpsEq, idx) => (
                        <option
                          key={idx}
                          value={`${billerGrpsEq.id}.${billerGrpsEq.slug}.${billerGrpsEq.name}`}
                        >
                          {billerGrpsEq?.name}
                        </option>
                      ))}
                  </optgroup>
                </select>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <label
              htmlFor="title"
              className="ml-2 text-[10px] font-semibold xsm:text-xs"
            >
              Choose product
            </label>
            <div className="relative mt-[1px] flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <select
                  onChange={handlePackageInfo}
                  type="text"
                  className="block w-full cursor-pointer rounded-md border-gray-300 bg-white pl-4 pr-10 text-gray-500 placeholder:text-sm optional:text-sm optional:font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                >
                  <optgroup className="rounded-lg bg-olivine-100">
                    <option>Product</option>
                    {fetchedBillerPackages &&
                      fetchedBillerPackages?.length &&
                      fetchedBillerPackages?.map((billerPackage, idx) => (
                        <option
                          key={idx}
                          value={`${billerPackage.id}.${billerPackage.slug}.${billerPackage?.name}`}
                        >
                          {billerPackage?.name}
                        </option>
                      ))}
                  </optgroup>
                </select>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <label
              htmlFor="phoneNumber"
              className="ml-2 text-[10px] font-semibold xsm:text-xs"
            >
              Reference number( e.g smart card/meter number)
            </label>
            <div className="relative mt-[1px] flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <input
                  type="text"
                  maxLength={20}
                  onBlur={(eve) => handleCustomerEnquiry(eve)}
                  onChange={(e) => {
                    // 👇️ only take first N characters
                    setReferenceNumber(e.target.value);
                  }}
                  value={referenceNumber}
                  placeholder="Reference number( e.g smart card/meter number)"
                  className="block w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <label
              htmlFor="amount"
              className="ml-2 text-[10px] font-semibold xsm:text-xs"
            >
              Amount
            </label>
            <div className="relative mt-[1px] flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <input
                  readOnly={selectedPackagePrice ? true : false}
                  type="text"
                  placeholder={selectedPackagePrice ? null : "Input Amount"}
                  onChange={selectedPackagePrice ? null : handleChange}
                  value={
                    selectedPackagePrice
                      ? "₦" + selectedPackagePrice?.toFixed(2)
                      : price
                  }
                  className="block w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="flex justify-between text-center text-sm font-semibold tracking-widest transition-all duration-300">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  closer();
                }}
                className="rounded-md border-[1px] border-olivine-400 px-8 py-3 text-sm text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-600"
              >
                CANCEL
              </button>
              {/* <button
                disabled={
                  selectedPackage && price && referenceNumber ? false : true
                }
                onClick={(e) => handlePaybill(e)}
                className="rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
              >
                Pay Bill
              </button> */}
              <div className="">
                <div>
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          disabled={
                            selectedPackage &&
                            (price || selectedPackagePrice) &&
                            referenceNumber &&
                            customerInfo
                              ? false
                              : true
                          }
                          className={`
                          ${open ? "" : "text-opacity-90"}
                          w-full rounded-md bg-olivine-500 transition-all  duration-200 focus:text-olivine-700 focus:outline-none hover:enabled:bg-olivine-600 disabled:opacity-50 lg:w-auto`}
                        >
                          <div
                            // onClick={(e) => handlePaybill(e)}
                            className="flex w-full items-center justify-center space-x-2 text-white shadow-md"
                          >
                            <div className="px-8 py-3 text-sm font-semibold text-white">
                              Pay Bill
                            </div>
                          </div>
                        </Popover.Button>
                        <Popover.Overlay className="fixed inset-0 w-screen bg-black opacity-30" />
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute -left-[12px] -mt-[400px] -translate-x-1/2 xsm:-left-[50px]">
                            {({ close, open }) => {
                              let payload;
                              const amount = selectedPackagePrice
                                ? selectedPackagePrice
                                : price;

                              if (open) {
                                payload = {
                                  data: {
                                    phoneNumber: phone,
                                    amount: amount?.toString(),
                                    packageSlug: selectedPackage,
                                    productId: selectedPackageId,
                                    customerId: customerId?.toString(),
                                    userId: userId?.toString(),
                                    verification_code,
                                    referenceNumber,
                                    billerSlug,
                                    category_id: selectedGroup?.id?.toString(),
                                    category_name: selectedGroup?.name,
                                    biller_id: selectedCategory?.id?.toString(),
                                    biller_name: selectedCategory?.name,
                                    bill_product_id:
                                      selectedProduct?.id?.toString(),
                                    bill_product_name: selectedProduct?.name,
                                  },
                                  moreInfo: {
                                    billerName: billerName,
                                    billerProduct: billerProduct,
                                    customerInfo,
                                    selectedProduct: selectedProduct?.id,
                                  },
                                };
                              }
                              return (
                                <div>
                                  <PaymentConfirmationPopover
                                    closer={close}
                                    closer2={closer}
                                    payload={payload}
                                  />
                                </div>
                              );
                            }}
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PayBillsPopover_Two;
