import baseAxios from "../core/api/axios/baseAxios";



export const profileService  = {
    getUserProfile,
     getProfileMasterData,
     updatePersonalDetails,
     verifyPinNumber,
     getCurrentUserPin,
     updateTransferPin,
     getDeliveryDetails,
     getCountryCityArea,
     updateDeliveryAddress,
     getSalutationsList

}




  function getUserProfile() {
    return baseAxios.get("profile/get-user-profile");
  }



  function getProfileMasterData() {
    return baseAxios.get("profile/get-profile-master-data");
  }

  function getSalutationsList() {
    return baseAxios.get("profile/get-salutation");
  }


  function updatePersonalDetails(userData) {
    return baseAxios.put("profile/update-personal-details", userData);
  }

  function getCurrentUserPin() {
    return baseAxios.get("profile/get-current-user-pin");
  }


  function verifyPinNumber(userData) {
    return baseAxios.post("profile/verify-pin-number", userData);
  }


  function updateTransferPin(userData) {
    return baseAxios.put("profile/update-transfer-pin", userData);
  }


  function getDeliveryDetails() {
    return baseAxios.get("profile/get-delivery-details");
  }


  function getCountryCityArea() {
    return baseAxios.get("profile/get-country-city-area");
  }

    
  function updateDeliveryAddress(userData) {
    return baseAxios.put("profile/update-delivery-address", userData);
  }




