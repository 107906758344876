import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import { GoCheck } from "react-icons/go";

function AddonListboxOne({ addonList, selectedAddon, existingAddon }) {
  const [selected, setSelected] = useState(existingAddon || addonList[0]?.name);

  useEffect(() => {
    selectedAddon(selected);
  }, [selected, selectedAddon]);

  return (
    <div className="absolute top-0 z-30 w-full rounded-md ring-1 ring-gray-300 focus:ring-olivine-500">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className="relative h-auto w-full cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left shadow focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-olivine-500 focus-visible:ring-offset-2 sm:text-sm">
            <span className="block truncate text-sm font-semibold">
              {selected}
            </span>
            <span className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2">
              <FiChevronDown
                className="h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute left-0 mt-1 max-h-60 w-full overflow-auto rounded-2xl border-[0.5px] border-gray-400 bg-gray-100 py-1 text-sm text-gray-600 shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base md:left-auto md:right-0">
              {addonList &&
                addonList?.length &&
                addonList?.map((ad, idx) => (
                  <Listbox.Option
                    key={idx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-8 pr-2 ${
                        active
                          ? "cursor-pointer bg-olivine-200 text-amber-900"
                          : "text-gray-600"
                      }`
                    }
                    value={ad?.name}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-semibold" : "font-normal"
                          }`}
                        >
                          {ad?.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-olivine-500">
                            <GoCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default AddonListboxOne;
