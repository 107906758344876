import React from "react";
import Footer from "./Footer";
import GiftABasketMain from "./GiftABasketMain";
import Navbar from "./Navbar";

function GiftABasket() {
  return (
    <div>
      <Navbar />
      <GiftABasketMain />
      <Footer />
    </div>
  );
}

export default GiftABasket;
