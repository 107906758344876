import { Route, Routes } from "react-router-dom";
import ErrorPage from "../components/ErrorPage";
import AllItemPage from "../components/pages/create_basket_page/AllItemPage";
import MyBasketList from "../components/pages/create_basket_page/MyBasketList";
import OrderHistory from "../components/pages/create_basket_page/OrderHistory";
import OrderTransactionDetails from "../components/pages/create_basket_page/OrderTransactionDetails";
import PrivateRoutes from "../core/utils/PrivateRoutes";
import PrivateCooperateRoutes from "../core/utils/PrivateCooperateRoute";
import CreateButcherPage from "../components/butcher/CreateButcherPage";
import SelectedBasketPageButchers from "../components/butcher/SelectedBasketPageButchers";

function ButcherRoutes() {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/create-basket"
          element={<CreateButcherPage />}
        ></Route>

        <Route
          path="/create-basket/selected-basket/:product_id"
          element={<SelectedBasketPageButchers />}
        />

        <Route path="/create-basket/section" element={<AllItemPage />}></Route>

        <Route exact path="/my-basket" element={<MyBasketList />} />

        <Route
          exact
          path="/order-history"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <OrderHistory />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route
          exact
          path="/order-history-details"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <OrderTransactionDetails />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default ButcherRoutes;
