import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { Modal, Box } from "@mui/material";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import AssignVoucher from "../bulkVoucher/AssignVoucher";
import { useDispatch, useSelector } from "react-redux";
import { voucherBulkGet } from "../../../store/actions";
import { authService } from "../../../services/authService";
import { useNavigate } from "react-router-dom";
import { GoSearch } from "react-icons/go";
import { setSelectedVoucher } from "../../../store/slices/voucherSlice";
import { MdEdit } from "react-icons/md";

function VoucherDetailsInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedVoucher } = useSelector((state) => state.voucher);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const customerId = authService.getCurrentUserCustomerId();
  const [fetchedBulk, setFetchedBulk] = useState();
  const [fetchedHistory, setFetchedHistory] = useState([]);
  const [isReassign, setReAssign] = useState(false);
  const [selectedBulkVoucher, setSelectedBulkVoucher] = useState([]);
  const [assignedVouchers, setAssignedVouchers] = useState(0);
  const [unAssignedVouchers, setUnAssignedVouchers] = useState(0);
  const [searchInputValue, setSearchInputValue] = useState("");
  const searchRef = React.createRef();

  useEffect(() => {
    if (!fetchedBulk) {
      handleBulkFetch();
    }

    if (fetchedBulk?.voucher_info?.length) {
      const filteredAssigned = fetchedBulk?.voucher_info?.filter(
        (voucher) => voucher?.voucher_status_id === 1
      );
      setAssignedVouchers(filteredAssigned?.length);

      const filteredUnAssigned = fetchedBulk?.voucher_info?.filter(
        (voucher) => voucher?.voucher_status_id === 3
      );
      setUnAssignedVouchers(filteredUnAssigned?.length);
    }
  }, [fetchedBulk]);

  useEffect(() => {
    if (searchInputValue?.length > 0) {
      const filterMember = fetchedBulk?.voucher_info?.filter((item) => {
        return (
          item?.voucher_number
            ?.toLowerCase()
            .includes(searchInputValue?.toLowerCase()) ||
          item?.reciepient_name
            ?.toLowerCase()
            .includes(searchInputValue?.toLowerCase()) ||
          item?.reciepient_mobile_number
            ?.toLowerCase()
            .includes(searchInputValue?.toLowerCase()) ||
          item?.reciepient_email_address
            ?.toLowerCase()
            .includes(searchInputValue?.toLowerCase())
        );
      });

      setFetchedHistory(filterMember);
    }

    if (searchInputValue?.length <= 0) {
      createVoucherList(selectedIndex, fetchedBulk);
    }
  }, [searchInputValue]);

  useEffect(() => {
    try {
      if (fetchedBulk && fetchedBulk?.voucher_info?.length) {
        createVoucherList(selectedIndex, fetchedBulk);
      }
    } catch (err) {
      return err;
    }
  }, [selectedIndex, fetchedBulk]);

  function createVoucherList(selectedIndex, fetchedBulk) {
    switch (selectedIndex) {
      case 0:
        setFetchedHistory(fetchedBulk?.voucher_info);
        break;
      case 1:
        const filteredAssigned = fetchedBulk?.voucher_info?.filter(
          (voucher) => voucher?.voucher_status_id === 1
        );
        setFetchedHistory(filteredAssigned);
        break;
      case 2:
        const filteredUnAssigned = fetchedBulk?.voucher_info?.filter(
          (voucher) => voucher?.voucher_status_id === 3
        );
        setFetchedHistory(filteredUnAssigned);
        break;
      case 3:
        const filteredUnRedeemed = fetchedBulk?.voucher_info?.filter(
          (voucher) => voucher?.voucher_status_id != 2
        );

        setFetchedHistory(filteredUnRedeemed);
        break;
      case 4:
        const filteredRedeemed = fetchedBulk?.voucher_info?.filter(
          (voucher) => voucher?.voucher_status_id === 2
        );

        setFetchedHistory(filteredRedeemed);
        break;
      default:
        setFetchedHistory(fetchedBulk?.voucher_info);
    }
  }

  const handleBulkFetch = async () => {
    try {
      const payload = {
        customer_id: customerId.toString(),
      };

      const data = {
        ref: selectedVoucher?.corporate_ref_no,
        payload,
      };

      await dispatch(voucherBulkGet(data))
        .unwrap()
        .then((res) => {
          setFetchedBulk(res);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  return (
    <div>
      <SignedInNavbarImproved />
      <div className="mx-auto mt-10 max-w-[1600px] bg-[#FEFEFE] px-3 pb-40 xsm:px-6 sm:px-8 md:px-12 lg:px-14">
        <div className="mt-4 flex items-center space-x-2 sm:space-x-4">
          <div className="rounded-full p-1 hover:bg-gray-200">
            <BiArrowBack
              onClick={() => navigate(-1)}
              className="h-6 w-6 cursor-pointer text-gray-600"
            />
          </div>
          <h1 className="text-lg font-bold text-gray-700 sm:text-2xl">
            Voucher (Bulk)
          </h1>
          <div>
            <div className="mt-2 max-w-[600px] flex-1 xsm:mt-0">
              <div
                className={`flex items-center rounded-lg border  border-gray-400 shadow-sm focus:border-olivine-500 ${
                  searchInputValue ? "border-opacity-100" : "border-opacity-60"
                }
                      ${
                        searchInputValue
                          ? "ring-[1px] ring-olivine-500"
                          : "ring-0"
                      }
                    `}
              >
                <input
                  ref={searchRef}
                  onChange={(ev) => {
                    setSearchInputValue(ev.target.value);
                  }}
                  type="text"
                  placeholder="Quick search for anything"
                  className="flex-grow border-0 bg-transparent pl-5 text-base text-gray-600 outline-none ring-0 placeholder:text-sm placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0"
                />
                <div className="flex h-10 w-10 items-center justify-center rounded-r-lg bg-gray-100">
                  <GoSearch className="h-[18px] w-[18px] cursor-pointer rounded-full text-olivine-500" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="flex flex-wrap items-center space-x-2 text-sm xsm:space-x-6 xsm:text-base">
              <div className="items-centern flex space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer px-1 font-semibold">
                        All Vouchers
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
              <div className="items-centern flex space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer px-1 font-semibold">
                        Assigned
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
              <div className="items-centern flex space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer px-1 font-semibold">
                        Unassigned
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
              <div className="items-centern flex space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer px-1 font-semibold">
                        Unredeemed
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
              <div className="items-centern flex space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer px-1 font-semibold">
                        Redeemed
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="space-y-4">
                <div className="mt-6">
                  <p className="text-sm font-medium text-gray-500">
                    Total Voucher:
                    <span className="ml-2 text-base text-olivine-600">
                      {fetchedHistory && fetchedHistory?.length}
                    </span>
                  </p>
                </div>
                {fetchedHistory && fetchedHistory?.length
                  ? fetchedHistory.map((voucher, idx) => (
                      <div
                        key={idx}
                        className="rounded-md border-[0.5px] border-gray-100 bg-white px-4 py-3 shadow sm:px-6"
                      >
                        <div className="flex items-center justify-between">
                          <h3 className="p-2 text-xs font-medium text-gray-500">
                            {`S.N - ${idx + 1}`}
                          </h3>
                          <p className="text-sm font-bold text-[#EFA92B]">
                            {`NGN ${Number(
                              voucher?.voucher_value
                            ).toLocaleString()}`}
                          </p>
                        </div>
                        <p className="text-sm font-normal text-gray-500">
                          Voucher No :{" "}
                          <span className="font-bold text-gray-700">
                            {voucher.voucher_number}
                          </span>
                        </p>
                        <div className="flex flex-row items-center justify-between">
                          {voucher?.voucher_status_id === 3 && (
                            <div className="flex w-1/4 flex-row justify-between">
                              <p
                                onClick={() => {
                                  setOpen(true);
                                  setReAssign(false);
                                  setSelectedBulkVoucher(voucher);
                                }}
                                className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                              >
                                Assign and share
                              </p>

                              <p
                                onClick={() => {
                                  navigate("/voucher/voucher-details");
                                  dispatch(
                                    dispatch(setSelectedVoucher(voucher))
                                  );
                                }}
                                className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                              >
                                View
                              </p>
                            </div>
                          )}

                          {voucher?.voucher_status_id === 2 && (
                            <div className="flex w-1/4 flex-row justify-between">
                              <p className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4">
                                Redeemed
                              </p>
                              <p
                                onClick={() => {
                                  navigate("/voucher/voucher-details");
                                  dispatch(
                                    dispatch(setSelectedVoucher(voucher))
                                  );
                                }}
                                className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                              >
                                View
                              </p>
                            </div>
                          )}

                          {voucher?.voucher_status_id === 1 && (
                            <div className="flex w-1/4 flex-row justify-between">
                              <p
                                onClick={() =>
                                  navigate("/voucher/createVoucherSuccess")
                                }
                                className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                              >
                                Share
                              </p>
                              <p
                                onClick={() => {
                                  setOpen(true);
                                  setReAssign(true);
                                  setSelectedBulkVoucher(voucher);
                                }}
                                className="mt-2 flex cursor-pointer flex-row items-center space-x-1 border-b-[1px] border-olivine-600 border-opacity-0 px-[2px] text-sm font-normal text-olivine-600 hover:border-opacity-100"
                              >
                                <MdEdit className="h-4 w-4" />
                                Edit
                              </p>
                              <p
                                onClick={() => {
                                  navigate("/voucher/voucher-details");
                                  dispatch(
                                    dispatch(setSelectedVoucher(voucher))
                                  );
                                }}
                                className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                              >
                                View
                              </p>
                            </div>
                          )}

                          {voucher?.voucher_status_id === 1 && (
                            <p className="cursor-pointer rounded bg-[#C7E6FF] px-4 py-1 text-sm font-medium text-[#0A6BB7] shadow-sm">
                              Assigned
                            </p>
                          )}
                          {voucher?.voucher_status_id === 3 && (
                            <p className="cursor-pointer rounded bg-[#FFBCC3] px-4 py-1 text-sm font-medium text-[#EB001B] shadow-sm">
                              Open
                            </p>
                          )}
                          {voucher?.voucher_status_id === 2 && (
                            <p className="cursor-pointer rounded bg-[#CBD9BB] px-4 py-1 text-sm font-medium text-[#558223] shadow-sm">
                              Redeemed
                            </p>
                          )}
                        </div>
                      </div>
                    ))
                  : null}
              </Tab.Panel>
              <Tab.Panel className="space-y-4">
                <div className="mt-6">
                  <p className="text-sm font-medium text-gray-500">
                    Total Voucher:
                    <span className="ml-2 text-base text-olivine-600">
                      {fetchedHistory && fetchedHistory?.length}
                    </span>
                  </p>
                </div>
                {fetchedHistory && fetchedHistory?.length
                  ? fetchedHistory?.map((voucher, idx) => (
                      <div
                        key={idx}
                        className="rounded-md border-[0.5px] border-gray-100 bg-white px-4 py-3 shadow sm:px-6"
                      >
                        <div className="flex items-center justify-between">
                          <h3 className="p-2 text-xs font-medium text-gray-500">
                            {`S.N - ${idx + 1}`}
                          </h3>
                          <p className="text-sm font-bold text-[#EFA92B]">{`NGN ${Number(
                            voucher?.voucher_value
                          ).toLocaleString()}`}</p>
                        </div>
                        <p className="text-sm font-normal text-gray-500">
                          Voucher No :{" "}
                          <span className="font-bold text-gray-700">
                            {voucher.voucher_number}
                          </span>
                        </p>
                        <div className="flex flex-row items-center justify-between">
                          {voucher?.voucher_status_id === 1 && (
                            <div className="flex w-1/4 flex-row justify-between">
                              <p
                                onClick={() =>
                                  navigate("/voucher/createVoucherSuccess")
                                }
                                className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                              >
                                Share
                              </p>
                              <p
                                onClick={() => {
                                  setOpen(true);
                                  setReAssign(true);
                                  setSelectedBulkVoucher(voucher);
                                }}
                                className="mt-2 flex cursor-pointer flex-row items-center space-x-1 border-b-[1px] border-olivine-600 border-opacity-0 px-[2px] text-sm font-normal text-olivine-600 hover:border-opacity-100"
                              >
                                <MdEdit className="h-4 w-4" />
                                Edit
                              </p>
                              <p
                                onClick={() => {
                                  navigate("/voucher/voucher-details");
                                  dispatch(
                                    dispatch(setSelectedVoucher(voucher))
                                  );
                                }}
                                className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                              >
                                View
                              </p>
                            </div>
                          )}
                          <p className="cursor-pointer rounded bg-[#C7E6FF] px-4 py-1 text-sm font-medium text-[#0A6BB7] shadow-sm">
                            Assigned
                          </p>
                        </div>
                      </div>
                    ))
                  : null}
              </Tab.Panel>
              <Tab.Panel className="space-y-4">
                <div className="mt-6">
                  <p className="text-sm font-medium text-gray-500">
                    Total Voucher:
                    <span className="ml-2 text-base text-olivine-600">
                      {fetchedHistory && fetchedHistory?.length}
                    </span>
                  </p>
                </div>
                {fetchedHistory && fetchedHistory?.length
                  ? fetchedHistory?.map((voucher, idx) => (
                      <div
                        key={idx}
                        className="rounded-md border-[0.5px] border-gray-100 bg-white px-4 py-3 shadow sm:px-6"
                      >
                        <div className="flex items-center justify-between">
                          <h3 className="p-2 text-xs font-medium text-gray-500">
                            {`S.N - ${idx + 1}`}
                          </h3>
                          <p className="text-sm font-bold text-[#EFA92B]">{`NGN ${Number(
                            voucher?.voucher_value
                          ).toLocaleString()}`}</p>
                        </div>
                        <p className="text-sm font-normal text-gray-500">
                          Voucher No :{" "}
                          <span className="font-bold text-gray-700">
                            {voucher.voucher_number}
                          </span>
                        </p>
                        <div className="flex flex-row items-center justify-between">
                          <p
                            onClick={() => {
                              setOpen(true);
                              setReAssign(false);
                              setSelectedBulkVoucher(voucher);
                            }}
                            className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                          >
                            Assign and share
                          </p>
                          <p className="cursor-pointer rounded bg-[#FFBCC3] px-4 py-1 text-sm font-medium text-[#EB001B] shadow-sm">
                            Open
                          </p>
                          <p
                            onClick={() => {
                              navigate("/voucher/voucher-details");
                              dispatch(dispatch(setSelectedVoucher(voucher)));
                            }}
                            className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                          >
                            View
                          </p>
                        </div>
                      </div>
                    ))
                  : null}
              </Tab.Panel>
              <Tab.Panel className="space-y-4">
                {" "}
                <div className="mt-6">
                  <p className="text-sm font-medium text-gray-500">
                    Total Voucher:
                    <span className="ml-2 text-base text-olivine-600">
                      {fetchedHistory && fetchedHistory?.length}
                    </span>
                  </p>
                </div>
                {fetchedHistory && fetchedHistory?.length
                  ? fetchedHistory?.map((voucher, idx) => (
                      <div
                        key={idx}
                        onClick={() => {
                          navigate("/voucher/voucher-details");
                          dispatch(dispatch(setSelectedVoucher(voucher)));
                        }}
                        className="rounded-md border-[0.5px] border-gray-100 bg-white px-4 py-3 shadow sm:px-6"
                      >
                        <div className="flex items-center justify-between">
                          <h3 className="p-2 text-xs font-medium text-gray-500">
                            {`S.N - ${idx + 1}`}
                          </h3>
                          <p className="text-sm font-bold text-[#EFA92B]">{`NGN ${Number(
                            voucher?.voucher_value
                          ).toLocaleString()}`}</p>
                        </div>
                        <p className="text-sm font-normal text-gray-500">
                          Voucher No :{" "}
                          <span className="font-bold text-gray-700">
                            {voucher.voucher_number}
                          </span>
                        </p>
                        <div className="flex flex-row items-center justify-end">
                          <p className="cursor-pointer rounded bg-[#E3D1EA] px-4 py-1 text-sm font-medium text-[#753B8B] shadow-sm">
                            Unredeemed
                          </p>
                        </div>
                      </div>
                    ))
                  : null}
              </Tab.Panel>
              <Tab.Panel className="space-y-4">
                {" "}
                <div className="mt-6">
                  <p className="text-sm font-medium text-gray-500">
                    Total Voucher:
                    <span className="ml-2 text-base text-olivine-600">
                      {fetchedHistory && fetchedHistory?.length}
                    </span>
                  </p>
                </div>
                {fetchedHistory && fetchedHistory?.length
                  ? fetchedHistory?.map((voucher, idx) => (
                      <div
                        key={idx}
                        onClick={() => {
                          navigate("/voucher/voucher-details");
                          dispatch(dispatch(setSelectedVoucher(voucher)));
                        }}
                        className="rounded-md border-[0.5px] border-gray-100 bg-white px-4 py-3 shadow sm:px-6"
                      >
                        <div className="flex items-center justify-between">
                          <h3 className="p-2 text-xs font-medium text-gray-500">
                            {`S.N - ${idx + 1}`}
                          </h3>
                          <p className="text-sm font-bold text-[#EFA92B]">{`NGN ${Number(
                            voucher?.voucher_value
                          ).toLocaleString()}`}</p>
                        </div>
                        <p className="text-sm font-normal text-gray-500">
                          Voucher No :{" "}
                          <span className="font-bold text-gray-700">
                            {voucher.voucher_number}
                          </span>
                        </p>
                        <div className="flex flex-row items-center justify-end">
                          <p className="cursor-pointer rounded bg-[#CBD9BB] px-4 py-1 text-sm font-medium text-[#558223] shadow-sm">
                            Redeemed
                          </p>
                        </div>
                      </div>
                    ))
                  : null}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-4 overflow-hidden px-2 xsm:px-4">
          <Box className=" mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <AssignVoucher
              selectedBulkVoucher={selectedBulkVoucher}
              setOpen={setOpen}
              setReAssign={setReAssign}
              isReassign={isReassign}
              unAssignedVouchers={unAssignedVouchers}
              assignedVouchers={assignedVouchers}
            />
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default VoucherDetailsInfo;
