import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import RewardCenterBg from "../../../../assets/rewardCenterBg.png";
import { CgClose } from "react-icons/cg";
import AdsContents from "./AdsContents";

const RewardCenterAdsPopup = ({ adsInfo }) => {
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-4xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                  <div
                    style={{ backgroundImage: `url(${RewardCenterBg})` }}
                    className="h-full bg-cover px-6 py-6"
                  >
                    <div className="flex flex-row items-center justify-end ">
                      <div
                        onClick={closeModal}
                        className="w-fit cursor-pointer rounded-full bg-black p-2 hover:bg-black/80"
                      >
                        <CgClose className=" h-4 w-4 text-white" />
                      </div>
                    </div>
                    <div>
                      <AdsContents adsInfo={adsInfo} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default RewardCenterAdsPopup;
