import { Navigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { authService } from "../../services/authService";

const PrivateCooperateRoutes = ({ children }) => {
  if (authService.getApprovedStatus() === "Pending") {
    toast.error("You account is under review, You will be notified shortly!");
  }
  return authService.getApprovedStatus() === "Pending" ||
    authService.getApprovedStatus() === "canceled" ? (
    <Navigate to="/home/wallet" />
  ) : (
    children
  );

};

export default PrivateCooperateRoutes;
