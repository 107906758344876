import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  convenienceFees,
  delgroPaybillsPurchase,
  getTransactions,
  getWalletBalance,
  payBills,
  verifyTransactionPinCode,
} from "../store/actions";
import { authService } from "../services/authService";
import { CircularProgress } from "@mui/material";

function PaymentConfirmationPopover({ closer, payload, closer2 }) {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const [convFees, setConvFees] = useState("0");
  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentCustomerId();
  const { billerGrpEnq } = useSelector((state) => state.vas);

  const dispatch = useDispatch();
  const { walletBalance } = useSelector((state) => state.user);

  useEffect(() => {
    if (payload?.moreInfo?.selectedProduct) {
      dispatch(convenienceFees(payload?.moreInfo?.selectedProduct))
        .unwrap()
        .then((res) => {
          if (res && res?.length) {
            setConvFees(res[0]?.convience_fees_amount?.toString());
          }
        })
        .catch((err) => err);
    }
  }, [dispatch, payload?.moreInfo?.selectedProduct]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleWalletUpdate = () => {
    const data = {
      sql_ref_no: "31",
      user_id: userId.toString(),
      customer_id: customerId.toString(),
      verification_code,
      option: "1",
    };

    dispatch(getWalletBalance(data))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        const id = customerId?.toString();
        dispatch(getTransactions(id));
      })
      .catch((err) => {
        return err;
      });
  };

  const handlePaybill = (e) => {
    e.preventDefault();

    if (!billerGrpEnq?.length) {
      return toast.error(
        "This service is temporarily unavailable, please try again later"
      );
    }

    if (walletBalance?.available_balance < payload?.data?.amount) {
      return toast.error("You have insufficient funds..");
    }

    const toastId = toast.loading("Paying Bill...");

    const data = {
      phoneNumber: payload?.data?.phoneNumber,
      amount: payload?.data?.amount,
      packageSlug: payload?.data?.packageSlug,
      productId: payload?.data?.productId,
      customerId: payload?.data?.customerId,
      userId: payload?.data?.userId,
      verification_code: payload?.data?.verification_code,
      referenceNumber: payload?.data?.referenceNumber,
      billerSlug: payload?.data?.billerSlug,
      pin_number: otp.join(""),
      category_id: payload.data.category_id,
      category_name: payload.data.category_name,
      biller_id: payload.data.biller_id,
      biller_name: payload.data.biller_name,
      bill_product_id: payload.data.bill_product_id,
      bill_product_name: payload.data.bill_product_name,
      convience_fees_amount: convFees,
    };

    dispatch(delgroPaybillsPurchase(data))
      .unwrap()
      .then((res) => {
        if (res && res?.status === 403) {
          setIsLoading(false);
          return toast.error(res.response.error, {
            id: toastId,
          });
        }

        closer();
        closer2();

        setIsLoading(false);
        toast.success(res[0]?.Message_Text, {
          id: toastId,
        });

        return handleWalletUpdate();
      })
      .catch((err) => err);
  };

  return (
    <div className="w-[275px] rounded-2xl bg-[#F5F5F5] px-4 py-6 text-gray-500 shadow-md xsm:w-[360px] xsm:px-6 sm:w-[420px] sm:px-8">
      <div className="flex items-center justify-between">
        <div className="font-bold text-gray-600 sm:text-lg">
          Payment Confirmation
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            closer();
            // closer2();
          }}
          className="rounded-full p-1 hover:bg-gray-200"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="mt-1 text-left text-[12px] text-gray-500 sm:text-xs">
        Please confirm the details of your transaction
      </div>
      <div className="mt-6 divide-y-[1px] divide-gray-200 border-[1px] border-gray-100 bg-white text-xs font-semibold sm:text-sm">
        <div className="flex justify-between px-2 py-3 sm:px-3">
          <div>For</div>
          <div>
            {payload && payload?.moreInfo
              ? `${payload?.moreInfo?.billerName} - ${payload?.moreInfo?.billerProduct}`
              : ""}
          </div>
        </div>
        <div className="flex justify-between px-2 py-3 sm:px-3">
          <div>Customer</div>
          <div>
            {payload && payload?.moreInfo
              ? payload?.moreInfo?.customerInfo
              : ""}
          </div>
        </div>
        <div className="flex justify-between px-2 py-3 sm:px-3">
          <div>Amount</div>
          <div>
            {payload && payload?.data
              ? `₦ ${Number(payload?.data?.amount).toLocaleString()}`
              : ""}
          </div>
        </div>
        <div className="flex justify-between px-2 py-3 sm:px-3">
          <div>Convenience fee</div>
          <div>{convFees ? `₦ ${Number(convFees).toLocaleString()}` : ""}</div>
        </div>
        <div className="flex justify-between px-2 py-3 font-bold text-gray-600 sm:px-3">
          <div>Total</div>
          <div>
            {/* {payload && payload?.data
              ? `₦${Number(
                  +payload?.data?.amount + +convFees
                ).toLocaleString()}
              : ""} */}

            {convFees
              ? payload &&
                payload?.data &&
                `₦${Number(
                  +payload?.data?.amount + +convFees
                ).toLocaleString()}`
              : payload &&
                payload?.data &&
                `₦${Number(+payload?.data?.amount).toLocaleString()}`}
          </div>
        </div>
      </div>
      <div>
        <div className="text-md mt-4 text-center font-semibold text-gray-600">
          Transaction PIN
        </div>
        <div className="relative mt-2 flex items-center justify-center space-x-2 rounded-md">
          {otp.map((data, index) => {
            return (
              <input
                type="text"
                maxLength={1}
                className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                name="opt"
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
              />
            );
          })}
        </div>
      </div>
      <div className="mt-6 flex items-center justify-between text-sm font-semibold">
        <button
          onClick={(e) => {
            e.preventDefault();
            closer();
            closer2();
          }}
          className="rounded-md border-[1px] border-olivine-500 px-6 py-3 text-olivine-500 transition-all duration-300 hover:ring-[1px] hover:ring-olivine-500 sm:px-8"
        >
          Close
        </button>

        {!isLoading ? (
          <button
            disabled={otp?.length === 4 ? false : true}
            onClick={(e) => handlePaybill(e)}
            className="rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
          >
            Proceed to pay
          </button>
        ) : (
          <button
            disabled={true}
            className="cursor-not-allowed rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
          >
            <CircularProgress size={20} color="inherit" />
          </button>
        )}
      </div>
    </div>
  );
}

export default PaymentConfirmationPopover;
