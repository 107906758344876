import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "../../services/authService";
import PaymentService from "../../services/paymentService";
import { startLoad, stopLoad } from "../slices/appSlice";
import { handleError } from "../slices/errorSlice";
import { getWalletBalance } from "./userActions";

const verification_code = authService.getCurrentUserVerificationCode();
const userId = authService.getCurrentUserID();
const customerId = authService.getCurrentUserCustomerId();

export const oneTimePayment = createAsyncThunk(
  "post/oneTimePayment",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaymentService.oneTimePayment(data);
      const payload = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };

      dispatch(getWalletBalance(payload));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const recurringPayment = createAsyncThunk(
  "post/recurringPayment",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaymentService.recurringPayment(data);

      const payload = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };

      dispatch(getTransactions(customerId));
      dispatch(getWalletBalance(payload));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const cancelPaymentPlan = createAsyncThunk(
  "put/cancelPayment",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const { data, planId } = payload;

      const res = await PaymentService.cancelPaymentPlan(data, planId);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getPaymentPlan = createAsyncThunk(
  "get/getPayment",
  async (email, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaymentService.getPaymentPlan(email);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getAutoFund = createAsyncThunk(
  "get/autoFund",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaymentService.fetchAutoFund(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getCancelledPaymentPlan = createAsyncThunk(
  "get/getCancelledPayment",
  async (email, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaymentService.getCancelledPaymentPlan(email);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getTransactions = createAsyncThunk(
  "get/getTransactions",
  async (id, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaymentService.getUserTransactions(id);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const makeWalletUpdate = createAsyncThunk(
  "post/walletUpdate",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaymentService.updateRecurringPayment(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const flutterwaveCallback = createAsyncThunk(
  "get/flutterwave",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaymentService.flutterwaveCallback(payload);

      const data = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };

      dispatch(getTransactions());
      dispatch(getWalletBalance(data));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
