import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import EditOrderPopover from "../EditOrderPopover";
import EditDeliveryDetailsPopover from "../EditDeliveryDetailsPopover";
import CurrencyFormat from "react-currency-format";
import RemoveItemConfirmationPopover from "../RemoveItemConfirmationPopover";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/authService";

const CartDetails = ({
  fetchedCart,
  handleCartUpdate,
  existingDeliveryInfo,
  handleDelete,
  calculateTotalBasket,
  handleContinue,
  handleLocalDelete,
  fetchedPlans,
  fetchedCountries,
  countries,
  customBasket,
  updatedLocalCart,
  handleLocalCartUpdate,
  removeCustomBasket,
}) => {
  const navigate = useNavigate();
  const token = authService.getToken();

  return (
    <div className="pb-8 lg:col-span-6">
      <div className="mt-14 text-4xl font-bold leading-10 text-gravel-500">
        Cart
      </div>
      <div className="flex flex-col justify-between">
        <div className="mt-8 flex flex-col">
          {fetchedCart &&
          fetchedCart?.items?.length &&
          fetchedCart?.items?.length
            ? fetchedCart?.items?.map((item, idx) => (
                <div className="my-4" key={idx}>
                  <div className="text-sm text-gray-500 opacity-80">
                    {`Item ${idx + 1}`}
                  </div>
                  <div className="text-base font-semibold opacity-70">
                    {`${
                      item?.JSON_String?.plan?.plan_name?.split(" ")[0]
                    } Basket`}
                  </div>
                  <div className="text-xs text-gray-600 opacity-90 xsm:text-sm">
                    {`${
                      item?.JSON_String?.plan?.number_of_months
                    } month subscription (at ₦${Number(
                      item?.JSON_String?.plan?.plan_price
                    ).toLocaleString()}/month)`}
                  </div>
                  <div className="text-sm text-gray-600 opacity-90">
                    {`Number of Units: ${item?.JSON_String?.plan?.plan_quantity} `}
                  </div>
                  <div className="opacty-90 text-sm text-olivine-500">
                    <span className="text-gray-600">Addons:</span>{" "}
                    {item?.JSON_String?.addons &&
                      item?.JSON_String?.addons?.length &&
                      item?.JSON_String?.addons?.map((addOn, idx) => (
                        <span key={idx}>{addOn?.name}, </span>
                      ))}
                  </div>
                  <div className="mt-0.5 text-lg font-bold text-gray-600">
                    Total:{" "}
                    {`₦${Number(
                      +item?.JSON_String?.plan?.plan_price *
                        item?.JSON_String?.plan?.plan_quantity
                    ).toLocaleString()}`}
                  </div>

                  <div className="mt-2 flex items-center space-x-4 text-xs xsm:space-x-8 xsm:text-sm sm:mt-1 sm:space-x-12 sm:text-base">
                    <div>
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={`
                                                ${open ? "" : "text-opacity-90"}
                                                focus:text-olivine-700 focus:outline-none`}
                            >
                              <div className="text-olivine-500 hover:text-olivine-700">
                                <div>Edit Order</div>
                              </div>
                            </Popover.Button>
                            <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute inset-x-0 top-40 z-30 mx-auto max-w-md px-2 xsm:px-4">
                                {({ close }) => (
                                  <div>
                                    <EditOrderPopover
                                      selectedItem={item}
                                      plans={fetchedPlans}
                                      closer={close}
                                      dataPull={handleCartUpdate}
                                    />
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </div>
                    <div>
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={`
                                                ${open ? "" : "text-opacity-90"}
                                                focus:text-olivine-700 focus:outline-none`}
                            >
                              <div className="font-semibold text-gray-500 hover:text-gray-600">
                                <div>Edit Delivery Address</div>
                              </div>
                            </Popover.Button>
                            <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute inset-x-0 top-20 z-30 mx-auto max-w-md px-2 pb-20 xsm:px-4">
                                {({ close }) => (
                                  <div>
                                    <EditDeliveryDetailsPopover
                                      closer={close}
                                      fetchedCountries={fetchedCountries}
                                      countries={countries}
                                      existingData={existingDeliveryInfo}
                                    />
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </div>
                    <div>
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={`
                                                ${open ? "" : "text-opacity-90"}
                                                focus:text-olivine-700 focus:outline-none`}
                            >
                              <div className="z-40 text-[#F7B9A9] hover:text-[#f78f75]">
                                <div>Remove</div>
                              </div>
                            </Popover.Button>
                            <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute inset-x-0 top-40 z-30 mx-auto max-w-md px-2 xsm:px-4">
                                {({ close }) => (
                                  <div>
                                    <RemoveItemConfirmationPopover
                                      closer={close}
                                      deleter={handleDelete}
                                      selectedId={item?.itemId}
                                    />
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>

        {/* Custom basket */}
        {customBasket?.line_items?.length ? (
          <div className="mt-8 flex flex-col">
            <div className="my-4">
              <div className="text-sm text-gray-500 opacity-80"></div>
              <div className="text-base font-semibold opacity-70">
                {customBasket?.plan_name}
              </div>
              <div className="text-xs text-gray-600 opacity-90 xsm:text-sm"></div>
              <div className="text-sm text-gray-600 opacity-90">
                {`Number of Units: ${customBasket?.line_items?.length} `}
              </div>
              <div className="opacty-90 text-sm text-olivine-500">
                <span className="text-gray-600">Items:</span>{" "}
                {customBasket?.line_items?.length &&
                  customBasket?.line_items?.map((product, idx) => (
                    <>
                      <span key={idx}>{product?.name}, </span>
                      <div className="mt-0.5   text-gray-600">
                        Quantity : {product?.quantity}
                      </div>
                      <div className="mt-0.5   text-gray-600">
                        Rate :
                        <CurrencyFormat
                          value={Number.parseFloat(product?.rate)?.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      </div>
                      <div className="mt-0.5   text-gray-600">
                        Total :{" "}
                        <CurrencyFormat
                          // className={"font-bold "}
                          value={Number.parseFloat(
                            product?.quantity * product?.rate
                          )?.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      </div>
                    </>
                  ))}
              </div>
              {/* here */}
              <div className="mt-2 flex items-center space-x-4 text-xs xsm:space-x-8 xsm:text-sm sm:mt-1 sm:space-x-12 sm:text-base">
                <div></div>
                <div>
                  <Popover>
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={`
                                                ${open ? "" : "text-opacity-90"}
                                                focus:text-olivine-700 focus:outline-none`}
                        ></Popover.Button>
                        <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute inset-x-0 top-40 z-30 mx-auto max-w-md px-2 xsm:px-4">
                            {({ close }) => (
                              <div>
                                <EditDeliveryDetailsPopover
                                  closer={close}
                                  fetchedCountries={fetchedCountries}
                                  countries={countries}
                                  existingData={existingDeliveryInfo}
                                />
                              </div>
                            )}
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
                <div className="mt-2 flex items-center space-x-4 text-xs xsm:space-x-8 xsm:text-sm sm:mt-1 sm:space-x-12 sm:text-base">
                  <div
                    className="cursor-pointer"
                    onClick={() => navigate("/basket/my-basket")}
                  >
                    Edit
                  </div>
                  <Popover>
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={`
                                                ${open ? "" : "text-opacity-90"}
                                                focus:text-olivine-700 focus:outline-none`}
                        >
                          <div className="z-40 text-[#F7B9A9] hover:text-[#f78f75]">
                            <div>Remove</div>
                          </div>
                        </Popover.Button>
                        <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute inset-x-0 top-52 z-30 mx-auto max-w-md px-2 xsm:px-4">
                            {({ close }) => (
                              <div>
                                <RemoveItemConfirmationPopover
                                  closer={close}
                                  deleter={() => removeCustomBasket()}
                                  // selectedId={product.item_id}
                                />
                              </div>
                            )}
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              </div>
              <div className="mt-0.5 text-lg font-bold text-gray-600">
                Total:{" "}
                <CurrencyFormat
                  value={Number.parseFloat(
                    calculateTotalBasket(customBasket)
                  )?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
              </div>
              {/* here  end */}
            </div>
          </div>
        ) : null}
        {/* End Custom basket */}

        <div className="mt-8 flex flex-col">
          {updatedLocalCart?.length &&
          !fetchedCart?.length &&
          updatedLocalCart?.length
            ? updatedLocalCart?.map((item, idx) => (
                <div className="my-4" key={idx}>
                  <div className="text-sm text-gray-500 opacity-80">
                    {`Item ${idx + 1}`}
                  </div>
                  <div className="text-base font-semibold opacity-70">
                    {`${
                      item?.JSON_String?.plan?.plan_name?.split(" ")[0]
                    } Basket`}
                  </div>
                  <div className="text-sm text-gray-600 opacity-90">
                    {`${
                      item?.JSON_String?.plan?.number_of_months
                    } month subscription (at ₦${Number(
                      item?.JSON_String?.plan?.plan_price
                    ).toLocaleString()}/month)`}
                  </div>
                  <div className="text-sm text-gray-600 opacity-90">
                    {`Number of Units: ${item?.JSON_String?.plan?.plan_quantity} `}
                  </div>
                  <div className="opacty-90 text-sm text-olivine-500">
                    <span className="text-gray-600">Addons:</span>{" "}
                    {item?.JSON_String?.addons &&
                      item?.JSON_String?.addons?.length &&
                      item?.JSON_String?.addons?.map((addOn, idx) => (
                        <span key={idx}>{addOn?.name}, </span>
                      ))}
                  </div>
                  <div className="mt-0.5 text-lg font-bold text-gray-600">
                    Total:{" "}
                    {`₦${Number(
                      +item?.JSON_String?.plan?.plan_price *
                        item?.JSON_String?.plan?.plan_quantity
                    ).toLocaleString()}`}
                  </div>
                  <div className="mt-1 flex items-center space-x-12 text-base">
                    <div>
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={`
                                                ${open ? "" : "text-opacity-90"}
                                                focus:text-olivine-700 focus:outline-none`}
                            >
                              <div className="text-olivine-500 hover:text-olivine-700">
                                <div>Edit Order</div>
                              </div>
                            </Popover.Button>
                            <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute inset-x-0 top-40 z-30 mx-auto max-w-md px-2 xsm:px-4">
                                {({ close }) => (
                                  <div>
                                    <EditOrderPopover
                                      selectedItem={item}
                                      plans={fetchedPlans}
                                      closer={close}
                                      dataPull={handleLocalCartUpdate}
                                    />
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </div>
                    {token && (
                      <div>
                        <Popover>
                          {({ open }) => (
                            <>
                              <Popover.Button
                                className={`
                                                ${open ? "" : "text-opacity-90"}
                                                focus:text-olivine-700 focus:outline-none`}
                              >
                                <div className="font-semibold text-gray-500 hover:text-gray-600">
                                  <div>Edit Delivery Address</div>
                                </div>
                              </Popover.Button>
                              <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                              >
                                <Popover.Panel className="absolute inset-x-0 top-20 z-30 mx-auto max-w-md px-2 pb-20 xsm:px-4">
                                  {({ close }) => (
                                    <div>
                                      <EditDeliveryDetailsPopover
                                        closer={close}
                                        fetchedCountries={fetchedCountries}
                                        countries={countries}
                                        existingData={existingDeliveryInfo}
                                      />
                                    </div>
                                  )}
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      </div>
                    )}
                    <div>
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={`
                                                ${open ? "" : "text-opacity-90"}
                                                focus:text-olivine-700 focus:outline-none`}
                            >
                              <div className="z-40 text-[#F7B9A9] hover:text-[#f78f75]">
                                <div>Remove</div>
                              </div>
                            </Popover.Button>
                            <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute inset-x-0 top-40 z-30 mx-auto max-w-md px-2 xsm:px-4">
                                {({ close }) => (
                                  <div>
                                    <RemoveItemConfirmationPopover
                                      closer={close}
                                      deleter={handleLocalDelete}
                                      selectedId={item?.itemId}
                                    />
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
        <div className="mt-8">
          <button
            onClick={() => handleContinue()}
            className="transform cursor-pointer rounded-md bg-olivine-500 px-8 py-3 text-sm font-medium tracking-wide text-white transition-all duration-200 hover:-translate-y-0.5 hover:bg-olivine-400 focus:outline-none focus:ring focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-2 active:bg-olivine-600"
          >
            CONTINUE SHOPPING
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartDetails;
