import { Route, Routes } from "react-router-dom";
import ErrorPage from "../components/ErrorPage";
import AllItemPage from "../components/pages/create_basket_page/AllItemPage";
import CreateBasketPage from "../components/pages/create_basket_page/CreateBasketPage";
import GiftMyBasketList from "../components/pages/create_basket_page/GiftMyBasketList";
import MyBasketList from "../components/pages/create_basket_page/MyBasketList";
import OrderHistory from "../components/pages/create_basket_page/OrderHistory";
import OrderTransactionDetails from "../components/pages/create_basket_page/OrderTransactionDetails";
import SelectedBasketPage from "../components/pages/create_basket_page/SelectedBasketPage";
import PlansGiftComplete from "../components/pages/gift_a_basket/[id]";
import PrivateRoutes from "../core/utils/PrivateRoutes";
import GiftCreateBasketPage from "../components/pages/create_basket_page/GiftCreateBasketPage";
import CustomGiftMyBasketList from "../components/pages/create_basket_page/CustomGiftMyBasketList";
import PrivateCooperateRoutes from "../core/utils/PrivateCooperateRoute";
import CreateButchersPage from "../components/pages/create_basket_page/CreateButchersPage";

function BasketRoutes() {
  return (
    <>
      <Routes>
        <Route exact path="/create-basket" element={<CreateBasketPage />} />

        <Route exact path="/butchers" element={<CreateButchersPage />} />

        <Route
          exact
          path="/gift-create-basket"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <GiftCreateBasketPage />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route
          path="/create-basket/selected-basket/:product_id"
          element={<SelectedBasketPage />}
        />

        <Route path="/create-basket/section" element={<AllItemPage />}></Route>

        <Route
          exact
          path="/my-basket"
          element={
            // <PrivateCooperateRoutes>
            // <PrivateRoutes>
            // </PrivateRoutes>
            // </PrivateCooperateRoutes>
            <MyBasketList />
          }
        />
        <Route
          exact
          path="/gift-my-basket"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <GiftMyBasketList />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route
          exact
          path="/custom-gift-my-basket"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <CustomGiftMyBasketList />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />
        <Route
          exact
          path="/my-basket/:id"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <PlansGiftComplete />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route
          exact
          path="/order-history"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <OrderHistory />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route
          exact
          path="/order-history-details"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <OrderTransactionDetails />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default BasketRoutes;
