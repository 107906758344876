import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { profileService } from "../../../services/profileService";
import ProfileContext from "../../../context/ProfileContext";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import LogicContext from "../../../context/LogicContext";
import DeliveryDetailsSelect from "./DeliveryDetailsCountrySelect";
import DeliveryDetailsCity from "./DeliveryDetailsCity";
import DeliveryDetailArea from "./DeliveryDetailsArea";
import CircularProgress from "@mui/material/CircularProgress";

function DeliveryDetails() {
  const logicContext = useContext(LogicContext);
  const profileContext = useContext(ProfileContext);
  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const countryCityArea = profileContext.getCountryCityArea();

    if (countryCityArea?.country) {
      setCountryList(countryCityArea.country);
      setCityList(countryCityArea.city);
      setAreaList(countryCityArea.area);
    } else {
      profileService.getCountryCityArea().then((res) => {
        if (res?.data?.country) {
          profileContext.setCountryCityArea(res?.data);
          setCountryList(res.data.country);
          setCityList(res.data.city);
          setAreaList(res.data.area);
        }
      });
    }
  }, []);

  useEffect(() => {
    // const formData = getValues();
    const savedData = profileContext.getDeliveryData();
    if (savedData?.length) {
      processRecords(savedData[0]);
    } else {
      getDeliveryDetails();
    }
  }, []);

  function getDeliveryDetails() {
    setIsLoading(true);
    profileService
      .getDeliveryDetails()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.customer_info[0]) {
          processRecords(res?.data?.customer_info[0]);
          profileContext.setDeliveryData(res?.data?.customer_info);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  const processRecords = (data) => {
    setValue("land_mark", data?.land_mark);
    setValue("shipping_address1", data?.shipping_address1);
    setValue("shipping_address2", data?.shipping_address2);
    setValue("shipping_country_id", data?.shipping_country_id);
    setValue("shipping_city_id", data?.shipping_city_id);
    setValue("shipping_area_id", data?.shipping_area_id);
    setValue("shipping_phone_number", data?.shipping_phone_number);
    setValue("shipping_zip_code", data?.shipping_zip_code);
  };

  const countrySelectAction = (selection) => {
    setValue("shipping_country_id", selection, { shouldValidate: true });
  };

  const citySelectAction = (selection) => {
    setValue("shipping_city_id", selection, { shouldValidate: true });
  };

  const areaSelectAction = (selection) => {
    setValue("shipping_area_id", selection, { shouldValidate: true });
  };

  const submitRecords = () => {
    const formData = getValues();

    if (!getValues("shipping_city_id")) {
      return toast.error("Please select a city!");
    }
    if (!getValues("shipping_area_id")) {
      return toast.error("Please select an area!");
    }
    delete formData?.shipping_country_id;
    delete formData?.shipping_city_id;
    setIsLoading((prev) => !prev);
    profileService
      .updateDeliveryAddress(formData)
      .then((res) => {
        setIsLoading((prev) => !prev);
        const filteredData = logicContext.httpResponseManager(
          res?.data?.result_status[0]
        );
        if (filteredData?.message_id == 1) {
          getDeliveryDetails();
        }
      })
      .catch((rr) => setIsLoading((prev) => !prev));
  };

  function onErrors(error) {
    toast.error("One or more value(s) is required");
  }

  return (
    <div className="mt-8 pb-16">
      <div className="hidden w-full md:inline md:w-auto">
        <div className="flex items-center justify-between rounded-md bg-[#4F4F4F] px-4 py-3 md:bg-white md:px-0">
          <div className=" text-xl font-semibold text-white md:text-gray-700">
            Delivery Details
          </div>
          <Link to="/home/wallet">
            <button className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-white p-8 px-6 py-2.5 text-sm font-semibold text-olivine-500 transition duration-300 ease-in-out hover:ring-[0.5px] hover:ring-olivine-500 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100">
              <div className="text-sm font-semibold text-olivine-500">
                Fund your Account
              </div>
            </button>
          </Link>
        </div>
      </div>

      {/* onSubmit={handleSubmit(submitDeliveryRecord, onErrors)} */}
      <form
        className="max-w-lg"
        onSubmit={handleSubmit(submitRecords, onErrors)}
      >
        <div className="mt-4">
          <label
            htmlFor="country/Region"
            className="ml-3 text-xs text-gray-500"
          >
            Country / Region <span className="text-red-600">*</span>
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <DeliveryDetailsSelect
                default={getValues("shipping_country_id")}
                mainList={countryList}
                activeSelect={countrySelectAction}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="state" className="ml-3 text-xs text-gray-500">
            State <span className="text-red-600">*</span>
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <DeliveryDetailsCity
                default={getValues("shipping_city_id")}
                mainList={cityList}
                activeSelect={citySelectAction}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="area" className="ml-3 text-xs text-gray-500">
            Area <span className="text-red-600">*</span>
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <DeliveryDetailArea
                default={getValues("shipping_area_id")}
                mainList={areaList}
                activeSelect={areaSelectAction}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="addressLine1" className="ml-3 text-xs text-gray-500">
            Address Line 1 <span className="text-red-600">*</span>
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <input
                type="text"
                placeholder="House/Flat No, Street/Road name"
                className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm font-semibold text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                {...register("shipping_address1", {
                  required: "Address Line 1 is required!",
                })}
              />
              <small className="text-red-600">
                {errors.shipping_address1?.message}
              </small>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="addressLine2" className="ml-3 text-xs text-gray-500">
            Address Line 2
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <input
                type="text"
                placeholder=""
                className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm font-semibold text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                {...register("shipping_address2")}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="Landmark" className="ml-3 text-xs text-gray-500">
            Landmark
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <input
                type="text"
                placeholder="Landmark"
                className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm font-semibold text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                {...register("land_mark")}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="zipCode" className="ml-3 text-xs text-gray-500">
            Zip Code <span className="text-red-600">*</span>
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <input
                type="text"
                placeholder="Enter Zip Code"
                className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm font-semibold text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                {...register("shipping_zip_code", {
                  required: " Zip Code is required!",
                })}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="phoneNumber" className="ml-3 text-xs text-gray-500">
            Phone Number <span className="text-red-600">*</span>
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <input
                type="text"
                placeholder="Enter Your Phone Number"
                className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm font-semibold text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                {...register("shipping_phone_number", {
                  required: " Phone Number is required!",
                })}
              />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            disabled={isLoading}
            className="w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600 sm:w-auto sm:px-8"
          >
            {isLoading === true ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "SAVE DELIVERY ADDRESS "
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default DeliveryDetails;
