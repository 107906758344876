import React, { useState } from "react";
import { toast } from "react-hot-toast";
// import { BiArrowBack } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/authService";
import { addPhonenumber, todoListStatus } from "../../store/actions";
import { setAddNewPhonenumber } from "../../store/slices/modalSlice";
import { init_data } from "../../core/helpers";

const Phone = ({ onClose }) => {
  const { userInfo } = useSelector((state) => state.masterData);
  let navigate = useNavigate();
  const [input, setInput] = useState(
    (userInfo &&
      userInfo?.customer_info?.length &&
      userInfo?.customer_info[0]?.customer_mobile_no) ||
      ""
  );

  const dispatch = useDispatch();
  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();

  const reworkPhonenumber = async (number) => {
    const updatedP = number.substring(1).split("");
    updatedP.unshift("234");

    return updatedP.join("");
  };

  const numberChecker = (str) => {
    if (str.charAt(0) === "0") {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const isCode = numberChecker(input);

      if (isCode && input?.length !== 11) {
        return toast.error("Phone number should be 11");
      }

      if (!isCode && input?.length !== 13) {
        return toast.error("Phone number should be 15");
      }

      const toastId = toast.loading("Submitting your mobile number...");

      const data = {
        sql_ref_no: "9",
        user_id: userId?.toString(),
        verification_code,
        mobile_number: isCode ? await reworkPhonenumber(input) : input,
        country_code: "234",
        option: "1",
      };

      dispatch(addPhonenumber(data))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            return toast.error("Phone Number could not be verified!", {
              id: toastId,
            });
          }

          setInput("");
          dispatch(setAddNewPhonenumber(true));
          toast.success("Verification code sent", {
            id: toastId,
          });
          dispatch(todoListStatus(init_data));
          navigate("/password/code");
        })
        .catch((err) => {
          setInput("");
          toast.error("Mobile Number could not be submitted!", {
            id: toastId,
          });

          return err;
        });
    } catch (err) {
      return err;
    }
  };

  const handleOnClose = (e) => {
    onClose();
  };

  return (
    <div className="mx-auto h-full w-full max-w-[420px] px-2 pb-16 pt-40 xsm:px-3">
      <div className="rounded-2xl bg-white px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <div className="font-semibold text-gray-700 xsm:text-lg sm:text-xl">
            Add Your Phone Number
          </div>
          <button
            className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
            onClick={handleOnClose}
          >
            <CgClose className="h-5 w-5 text-gravel-500" />
          </button>
        </div>
        <div className="mt-2 text-xs">
          Please enter your phone number to start using Grocedy
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="mt-2">
              <label
                htmlFor="phoneNumber"
                className="ml-3 text-xs text-gray-500"
              >
                Phone Number
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    value={input}
                    onChange={(e) => {
                      const limit = 15;

                      // 👇️ only take first N characters
                      setInput(e.target.value.slice(0, limit));
                    }}
                    // disabled={
                    //   userInfo?.customer_info[0]?.customer_mobile_no
                    //     ? true
                    //     : false
                    // }
                    type="number"
                    placeholder="Enter Your Phone Number"
                    className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="mt-6 flex justify-between text-center tracking-widest">
            <button
              onClick={handleOnClose}
              className="rounded-md border-[1px] border-[#CE8172]  px-6 py-2 text-sm text-[#CE8172] transition duration-300 hover:bg-[#f5f0ef] active:ring-1 active:ring-[#CE8172] sm:px-8"
            >
              CANCEL
            </button>
            <button
              onClick={handleSubmit}
              disabled={!input ? true : false}
              // disabled={!input}
              className="rounded-md bg-olivine-500 px-8 py-2 text-sm text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Phone;
