import React from "react";
import { IoClose } from "react-icons/io5";
import { authService } from "../../../services/authService";

function VoucherSummary({
  setOpenVoucherSummary,
  voucherSummaryItems,
  setOpenPin,
}) {
  const userFullName = authService.getUserFullName();
  const userEmail = authService.getCurrentUserEmail();

  const handleProceed = () => {
    setOpenVoucherSummary(false);
    setOpenPin(true);
  };
  return (
    <div className="max-w-lg p-4">
      <div className="flex flex-row items-center justify-between bg-[#FEFEFD]">
        <h2 className="text-lg font-semibold text-gray-700">Voucher Summary</h2>
        <div className="cursor-pointer rounded-full bg-olivine-500 p-1 text-white active:bg-olivine-400">
          <IoClose
            onClick={() => setOpenVoucherSummary(false)}
            className="h-5 w-5"
          />
        </div>
      </div>
      <div className="mt-6 rounded-lg border-[0.5px] border-gray-200 bg-white px-4 py-6 shadow-md">
        <ol className="space-y-3 text-xs text-gray-600 sm:space-y-4">
          <li className="sm:flex sm:items-center">
            <p className="font-medium sm:w-48">Sender’s Email Address</p>
            <p className="text-gray-400">{userEmail}</p>
          </li>
          <li className="sm:flex sm:items-center">
            <p className="font-medium sm:w-48">Sender’s Name</p>
            <p className="text-gray-400">{userFullName}</p>
          </li>
          <li className="sm:flex sm:items-center">
            <p className="font-medium sm:w-48">Amount</p>
            <p className="text-gray-400">{`₦${Number(
              voucherSummaryItems?.totalAmount
            ).toLocaleString()}`}</p>
          </li>
          <li className="sm:flex sm:items-center">
            <p className="font-medium sm:w-48">Message</p>
            <p className="text-xs text-gray-400">
              {voucherSummaryItems?.remark}
            </p>
          </li>
        </ol>
      </div>
      <div className="mt-10 sm:mt-16">
        <button
          onClick={() => handleProceed()}
          className="w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 sm:px-10"
        >
          Proceed to Payment
        </button>
      </div>
    </div>
  );
}

export default VoucherSummary;
