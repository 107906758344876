import React from "react";
import Banner from "../assets/grocedyBannerThree.svg";
import GooglePlayImage from "../assets/googlePlayImage.svg";
import AppStoreImage from "../assets/appstoreImage.svg";
import { motion } from "framer-motion";
import WalletImageIcon from "../assets/walletImageIconGreen.svg";
import BasketImageIcon from "../assets/basketImageIconGreen.svg";
import { Link } from "react-router-dom";

function WelcomeToGrocedyThree() {
  return (
    <div>
      <div className="mx-auto grid max-w-[1600px] bg-[#73964C] px-3 xsm:px-4 sm:px-8 md:grid-cols-2 md:items-center lg:px-16">
        <div className="mt-8 md:mt-16">
          <h1 className="text-left align-top text-4xl font-bold leading-snug tracking-normal text-white xsm:text-5xl">
            Where Grocery Shopping & Massive Savings Meet
          </h1>
          <div className="mt-6 max-w-lg text-sm font-normal text-white">
            <p>
              Say goodbye to the hassle of waiting in long queues at the grocery
              store and hello to effortless grocery shopping with Grocedy! Plus,
              save towards your food purchases, pay bills, top-up your mobile
              phones and earn rewards along the way.
            </p>
          </div>
          <div className="mt-8 flex items-center space-x-2 whitespace-nowrap sm:space-x-6">
            <a href="https://download.grocedy.com/u/12811202 ">
              <div className="cursor-pointer">
                <img
                  src={GooglePlayImage}
                  alt="image"
                  className="h-12 w-auto"
                />
              </div>
            </a>
            <a href="https://download.grocedy.com/u/12811202 ">
              <div className="cursor-pointer">
                <img src={AppStoreImage} alt="image" className="h-12 w-auto" />
              </div>
            </a>
            <Link to="/register">
              <div className="">
                <button className="w-full transform cursor-pointer rounded-md border-[1px] border-white px-3 py-3 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-500 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 xsm:px-4 xsm:py-[13px] xsm:text-sm sm:px-8">
                  Sign Up
                </button>
              </div>
            </Link>
          </div>
        </div>
        <div>
          <motion.img
            src={Banner}
            className="h-[550px] pt-12"
            alt="baner"
            animate={{ scale: 1 }}
            initial={{ scale: 0.8 }}
            transition={{ stiffness: 200, type: "spring" }}
          />
        </div>
      </div>
      <div className="mx-auto mt-24 grid max-w-[1600px] place-items-center px-3 xsm:px-4 sm:px-8 lg:px-16">
        <div className="font-sans text-2xl font-bold text-gray-600">
          Easy as 1, 2, 3!
        </div>
        <div className="mt-8 grid grid-cols-1 gap-14 sm:gap-14 md:grid-cols-2 md:gap-10 lg:grid-cols-3 lg:gap-8">
          <div className="h-full w-full rounded-lg bg-white px-4 pb-4 pt-8 shadow-md sm:px-10">
            <img src={WalletImageIcon} className="h-16 w-auto" alt="" />
            <div className="mt-8 align-top text-xl font-bold text-gray-700">
              Load Your Wallet
            </div>
            <div className="mt-4 align-top text-xs font-semibold leading-4 text-gray-700">
              You can fund your wallet manually or set up auto fund to save
              daily, weekly or monthly for your foodstuff
            </div>
          </div>
          <div className="h-full w-full rounded-lg bg-white px-4 pb-4 pt-8 shadow-md sm:px-8">
            <img src={BasketImageIcon} className="h-16 w-auto" alt="" />
            <div className="mt-8 align-top text-xl font-bold text-gray-700">
              Select Your Basket
            </div>
            <div className="mt-4 align-top text-xs font-semibold leading-4 text-gray-700">
              You can pick from our pre-curated baskets or create your own
              basket with food items you love.
            </div>
          </div>
          <div className="h-full w-full rounded-lg bg-white px-4 pb-4 pt-8 shadow-md sm:px-10">
            <img src={WalletImageIcon} className="h-16 w-auto" alt="" />
            <div className="mt-8 align-top text-xl font-bold text-gray-700">
              Get Your Monthly Food Basket
            </div>
            <div className="mb-8 mt-4 align-top text-xs font-semibold leading-4 text-gray-700">
              Sit back and we’ll have your food basket delivered to your
              doorstep each month.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeToGrocedyThree;
