import { createSlice } from "@reduxjs/toolkit";
import {
  cancelRecurringPlan,
  planCreate,
  transactionInitialize,
  verifyPayment,
  verifyPaymentRecurr,
} from "../actions";

const initialState = {
  paymentVerifyPaystack: "",
  paystackRecurrPlan: "",
  paymentVerifyRecurrPaystack: "",
  transactionInit: "",
  planCancel: "",
};

const PaystackSlice = createSlice({
  name: "PaystackData",
  initialState,
  reducers: {
    resetPaymentPaystack(state) {
      state.verifyPayment = null;
      state.paystackRecurrPlan = null;
      state.paymentVerifyRecurrPaystack = null;
      state.transactionInit = null;
      state.planCancel = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(verifyPayment.fulfilled, (state, { payload }) => {
      state.paymentVerify = payload;
    });

    builder.addCase(planCreate.fulfilled, (state, { payload }) => {
      state.paystackRecurrPlan = payload;
    });

    builder.addCase(verifyPaymentRecurr.fulfilled, (state, { payload }) => {
      state.paymentVerifyRecurrPaystack = payload;
    });

    builder.addCase(transactionInitialize.fulfilled, (state, { payload }) => {
      state.transactionInit = payload;
    });

    builder.addCase(cancelRecurringPlan.fulfilled, (state, { payload }) => {
      state.planCancel = payload;
    });
  },
});

const { reducer, actions } = PaystackSlice;

export const { resetPaymentPaystack } = actions;

export default reducer;
