import React, { useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { rxjsStoreProvider } from "../../../context/OrderHistoryRxjsProvider";
import moment from "moment";

function OrderHistoryPlan({ subscriptionList }) {
  const [page, setPage] = React.useState(1); // pagination
  const [history, setHistory] = React.useState(subscriptionList.slice(0, 5)); // pagination
  const navigate = useNavigate();

  useEffect(() => {
    setHistory(subscriptionList.slice(0, 5));
  }, [subscriptionList]);

  const handlePaginationChange = (event, pageValue) => {
    let end = 5 * pageValue;
    let start = end - 5;
    setPage(pageValue);

    let currentList = subscriptionList.slice(start, end);
    setHistory(currentList);
  };

  function viewProps(props) {
    navigate("/basket/order-history-details");
    rxjsStoreProvider.setSingleOrderHistory(props);
  }

  return (
    <>
      <div className="mt-8">
        {history?.map((plan, i) => {
          return (
            <div
              onClick={() => viewProps(plan)}
              key={i}
              className="cursor-pointer"
            >
              <div className="flex items-center justify-between  border-t-[1px] border-gray-300 py-3 text-xs">
                <div className="space-y-1">
                  <div className="font-semibold text-olivine-500">
                    {plan.name}
                  </div>
                  <div className="flex items-center space-x-2 text-[10px]">
                    <span>
                      {moment(new Date(plan?.created_at)).format("DD-MM-YYYY")}
                    </span>
                    <div className="h-3 w-[1px] bg-gray-500"></div>
                    {/* <span>22:40</span> */}
                  </div>
                  <div className="flex items-center space-x-2 text-xs">
                    <span className="text-gray-500">Status :</span>

                    <span className="text-olivine-500">{plan?.status}</span>
                  </div>
                </div>
                <div className="flex flex-col items-end space-y-2">
                  <div className="font-semibold">
                    <CurrencyFormat
                      className={"font-semibold"}
                      value={Number.parseFloat(plan?.amount)?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={plan.currency_code}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Stack spacing={2} className="mb-4 ml-2">
        <Typography>Page: {page}</Typography>
        <Pagination
          count={Math.floor(subscriptionList?.length / 5)}
          page={page}
          onChange={handlePaginationChange}
        />
      </Stack>
    </>
  );
}

export default OrderHistoryPlan;
