import SelectItemComponent from "./SelectItemComponent";



function SelectItem () {
    return ( <div>
      <SelectItemComponent></SelectItemComponent>
    </div> );
}

export default SelectItem;