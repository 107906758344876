import React, { useEffect, useState, useContext } from "react";
import FirstBanner from "../../../assets/firstBanner.png";
import { Link, useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { authService } from "../../../services/authService";
import { toggleSettingsAdsInfo } from "../../../store/actions";

const AdvertsWeb = ({ adsInfo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isToggle, setIsToggle] = useState(
    adsInfo?.ad_visibility?.length &&
      adsInfo?.ad_visibility[0]?.ad_visibility === 1
      ? true
      : false
  );
  const [isClicked, setIsClicked] = useState("not_clicked");

  const customerId = authService.getCurrentCustomerId();

  useEffect(() => {
    if (isClicked === "clicked") {
      if (isToggle) {
        return handleVisibility(1);
      }

      if (!isToggle) {
        return handleVisibility(2);
      }
    }
  }, [isToggle, isClicked]);

  const handleVisibility = (visibilityId) => {
    try {
      const payload = {
        customer_id: customerId,
        visibility_id: visibilityId,
      };

      dispatch(toggleSettingsAdsInfo(payload));
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="mt-8 pb-16">
      <div className="hidden w-full md:inline md:w-auto">
        <div className="flex items-center justify-between rounded-md bg-[#4F4F4F] px-4 py-3 md:bg-white md:px-0">
          <div className="text-lg font-semibold text-white  md:text-xl md:text-gray-700">
            Ads and Promotions Settings
          </div>
          <Link to="/home/wallet">
            <button className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-white p-8 px-6 py-2.5 text-sm font-semibold text-olivine-500 transition duration-300 ease-in-out hover:ring-[0.5px] hover:ring-olivine-500 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100">
              <div className="text-sm font-semibold text-olivine-500">
                Fund your Account
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div className="mb-5 flex items-center space-x-2">
        <p className="text-sm font-medium text-gray-600">
          Show promotional content on login
        </p>
        <Switch
          onChange={() => {
            setIsToggle(!isToggle);
            setIsClicked("clicked");
          }}
          className={`relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent ${
            isToggle ? "bg-olivine-500" : "bg-gray-400"
          }  transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isToggle ? "translate-x-5" : "translate-x-0"}
          pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <div className="grid max-w-3xl gap-6 grid-auto-fit-md">
        {adsInfo && adsInfo?.ad_info?.length && adsInfo?.ad_visibility?.length
          ? adsInfo?.ad_info?.map((ads, idx) => (
              <div
                onClick={() => navigate(ads?.web_action_page)}
                key={idx}
                className=" cursor-pointer bg-[#F3E2C5] p-6"
              >
                <h3 className="text-xl font-bold text-gray-700">
                  {ads?.ad_title}
                </h3>
                <p className="mt-2 text-sm font-medium text-gray-600">
                  {ads?.ad_description}
                </p>
                <div className="mt-6">
                  <img
                    src={ads?.ad_image_url}
                    alt="banner"
                    className="aspect-auto h-60 w-auto object-contain"
                  />
                </div>
              </div>
            ))
          : null}

        {/* <div className=" bg-[#DEEBC7] p-6">
          <h3 className="text-xl font-bold text-gray-700">Ad Title</h3>
          <p className="mt-2 text-sm font-medium text-gray-600">
            Earn Cowries and redeem them for cash value. By subscribing early,
            referring friends, or simply saving extra in your wallet, Cowries
            are your ticket to great rewards.
          </p>
          <div className="mt-6">
            <img
              src={FirstBanner}
              alt="banner"
              className="aspect-auto h-60 w-auto object-contain"
            />
          </div> */}
        {/* </div>
        <div className=" bg-[#D7D3EF] p-6">
          <h3 className="text-xl font-bold text-gray-700">Ad Title</h3>
          <p className="mt-2 text-sm font-medium text-gray-600">
            Earn Cowries and redeem them for cash value. By subscribing early,
            referring friends, or simply saving extra in your wallet, Cowries
            are your ticket to great rewards.
          </p>
          <div className="mt-6">
            <img
              src={FirstBanner}
              alt="banner"
              className="aspect-auto h-60 w-auto object-contain"
            />
          </div>
        </div>
        <div className=" bg-[#E9D9D6] p-6">
          <h3 className="text-xl font-bold text-gray-700">Ad Title</h3>
          <p className="mt-2 text-sm font-medium text-gray-600">
            Earn Cowries and redeem them for cash value. By subscribing early,
            referring friends, or simply saving extra in your wallet, Cowries
            are your ticket to great rewards.
          </p>
          <div className="mt-6">
            <img
              src={FirstBanner}
              alt="banner"
              className="aspect-auto h-60 w-auto object-contain"
            />
          </div>
        </div>
        <div className=" bg-[#F3E2C5] p-6">
          <h3 className="text-xl font-bold text-gray-700">Ad Title</h3>
          <p className="mt-2 text-sm font-medium text-gray-600">
            Earn Cowries and redeem them for cash value. By subscribing early,
            referring friends, or simply saving extra in your wallet, Cowries
            are your ticket to great rewards.
          </p>
          <div className="mt-6">
            <img
              src={FirstBanner}
              alt="banner"
              className="aspect-auto h-60 w-auto object-contain"
            />
          </div>
        </div>
        <div className=" bg-[#DEEBC7] p-6">
          <h3 className="text-xl font-bold text-gray-700">Ad Title</h3>
          <p className="mt-2 text-sm font-medium text-gray-600">
            Earn Cowries and redeem them for cash value. By subscribing early,
            referring friends, or simply saving extra in your wallet, Cowries
            are your ticket to great rewards.
          </p>
          <div className="mt-6">
            <img
              src={FirstBanner}
              alt="banner"
              className="aspect-auto h-60 w-auto object-contain"
            />
          </div>
        </div>
        <div className=" bg-[#D7D3EF] p-6">
          <h3 className="text-xl font-bold text-gray-700">Ad Title</h3>
          <p className="mt-2 text-sm font-medium text-gray-600">
            Earn Cowries and redeem them for cash value. By subscribing early,
            referring friends, or simply saving extra in your wallet, Cowries
            are your ticket to great rewards.
          </p>
          <div className="mt-6">
            <img
              src={FirstBanner}
              alt="banner"
              className="aspect-auto h-60 w-auto object-contain"
            />
          </div>
        </div>
        <div className=" bg-[#E9D9D6] p-6">
          <h3 className="text-xl font-bold text-gray-700">Ad Title</h3>
          <p className="mt-2 text-sm font-medium text-gray-600">
            Earn Cowries and redeem them for cash value. By subscribing early,
            referring friends, or simply saving extra in your wallet, Cowries
            are your ticket to great rewards.
          </p>
          <div className="mt-6">
            <img
              src={FirstBanner}
              alt="banner"
              className="aspect-auto h-60 w-auto object-contain"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AdvertsWeb;
