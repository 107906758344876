import { createAsyncThunk } from "@reduxjs/toolkit";
import { startLoad, stopLoad } from "../slices/appSlice";
import { handleError } from "../slices/errorSlice";
import RewardBankService from "../../services/rewardBank";

export const fetchRewardBankInfo = createAsyncThunk(
  "get/general info",
  async (customerId, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await RewardBankService.handleRewardBankInfo(customerId);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchRewardHistory = createAsyncThunk(
  "get/history",
  async (customerId, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await RewardBankService.handleRewardHistory(customerId);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchRewardHistoryDetail = createAsyncThunk(
  "get/history detail",
  async (refNo, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await RewardBankService.handleRewardHistoryDetail(refNo);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchRedeemRewardPoints = createAsyncThunk(
  "post/redeemRewardPoints",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await RewardBankService.handleRedeemRewardPoints(
        payload.customerId,
        payload?.data
      );

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchEarnedCowriesToday = createAsyncThunk(
  "get/fetchEarnedCowriesToday",
  async (customerId, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await RewardBankService.handleEarnedCowriesToday(customerId);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchEarnedCowriesLastWeek = createAsyncThunk(
  "get/fetchEarnedCowriesLastWeek",
  async (customerId, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await RewardBankService.handleEarnedCowriesLastWeek(
        customerId
      );

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchEarnedCowriesLastMonth = createAsyncThunk(
  "get/fetchEarnedCowriesLastMonth",
  async (customerId, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await RewardBankService.handleEarnedCowriesLastMonth(
        customerId
      );

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchEarnedCowriesCustom = createAsyncThunk(
  "get/fetchEarnedCowriesCustom",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await RewardBankService.handleEarnedCowriesCustom(
        payload.customerId,
        payload.data
      );

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
