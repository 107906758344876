import baseAxios from "../core/api/axios/baseAxios";

class BasketService {
  static async getBasketList() {
    const res = await baseAxios.get(`basket/basket-list`);
    return res?.data || res;
  }

  static async getButcherList() {
    const res = await baseAxios.get(`basket/butcher-list`);
    return res?.data || res;
  }

  static async getBasketListAuth() {
    const res = await baseAxios.get(`basket/basket-list-auth`);
    return res?.data || res;
  }

  static async getButcherListAuth() {
    const res = await baseAxios.get(`basket/butcher-list-auth`);
    return res?.data || res;
  }

  static async getSingleBasketItem(productId) {
    const res = await baseAxios.get(
      `basket/get-basket-single-item/${productId}`
    );
    return res?.data || res;
  }

  static async getSingleBasketButcherItem(productId) {
    const res = await baseAxios.get(
      `basket/get-basket-single-butcher-item/${productId}`
    );
    return res?.data || res;
  }

  static async performFavorite(productId, option) {
    const res = await baseAxios.get(
      `basket/perform-favorite/${productId}/${option}`
    );
    return res?.data || res;
  }

  static async getUserSearchHistory() {
    const res = await baseAxios.get(`/basket/get-user-search-history`);
    return res?.data || res;
  }

  static async saveSearchRecord(searchText) {
    const res = await baseAxios.get(`basket/save-search-record/${searchText}`);
    return res?.data || res;
  }

  static async saveBasketTemporarily(basketList) {
    const res = await baseAxios.post(
      `basket/save-basket-temporarily`,
      basketList
    );
    return res?.data || res;
  }

  static async deleteTempBasket(key) {
    const res = await baseAxios.delete(`basket/delete-temp-basket/${key}`);
    return res?.data || res;
  }

  static async getTempBasketData(key) {
    const res = await baseAxios.get(`basket/get-basket-temporarily/${key}`);
    return res?.data || res;
  }

  static async validatePromoCode(promoCode) {
    const res = await baseAxios.get(`basket/validate-promo-code/${promoCode}`);
    return res?.data || res;
  }

  static async submitCustomBasket(customBasket) {
    const res = await baseAxios.post(`basket/save-custom-basket`, customBasket);
    return res?.data || res;
  }

  static async submitSubscriptionBasket(subscriptionBasket) {
    const res = await baseAxios.post(
      `basket/save-subscription-basket`,
      subscriptionBasket
    );
    return res?.data || res;
  }

  static async getOrderHistory() {
    const res = await baseAxios.get(`basket/order-history`);
    return res?.data || res;
  }
}

export default BasketService;
