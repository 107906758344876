import baseAxios from "../core/api/axios/baseAxios";

class PaymentService {
  static async oneTimePayment(data) {
    const res = await baseAxios.post("payment/one-time", data);

    return res?.data || res;
  }

  static async recurringPayment(data) {
    const res = await baseAxios.post("payment/recuring", data);

    return res?.data || res;
  }

  static async cancelPaymentPlan(data, planId) {
    const res = await baseAxios.put(`payment/${planId}/cancel`, data);

    return res?.data || res;
  }

  static async fetchAutoFund(data) {
    const res = await baseAxios.post("payment/auto-fund", data);

    return res?.data || res;
  }

  static async getPaymentPlan(email) {
    const res = await baseAxios.get(`payment/${email}`);

    return res?.data || res;
  }

  static async getCancelledPaymentPlan(email) {
    const res = await baseAxios.get(`payment/${email}/cancelled`);

    return res?.data || res;
  }

  static async updateRecurringPayment(data) {
    const res = await baseAxios.post("payment/recurr/update", data);

    return res?.data || res;
  }

  static async getUserTransactions(id) {
    const res = await baseAxios.get(`payment/user/transactions/${id}`);

    return res?.data || res;
  }

  static async flutterwaveCallback(payload) {
    const res = await baseAxios.get(
      `payment/flutter/payment-callback?status=${payload?.status}&tx_ref=${payload?.tx_ref}&transaction_id=${payload?.transaction_id}`
    );

    return res?.data || res;
  }
}

export default PaymentService;
