import React, { useEffect, useState } from "react";
import { BiArrowBack, BiSearch } from "react-icons/bi";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { useDispatch, useSelector } from "react-redux";
import { voucherHistory } from "../../../store/actions";
import { authService } from "../../../services/authService";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { setSelectedVoucher } from "../../../store/slices/voucherSlice";
import { GoSearch } from "react-icons/go";

function VoucherHistory() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchInputValue, setSearchInputValue] = useState("");
  const searchRef = React.createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { historyVoucher } = useSelector((state) => state.voucher);
  const [fetchedHistory, setFetchedHistory] = useState([]);
  const customerId = authService.getCurrentUserCustomerId();
  const corporateId = authService.getCustomerCorporateId();
  const corporateAdminStatusId =
    authService.getCustomerCorporateAdminStatusId();

  useEffect(() => {
    if (!historyVoucher) {
      const data = {
        customer_id: customerId?.toString(),
      };

      dispatch(voucherHistory(data));
    }

    if (historyVoucher?.voucher_info?.length) {
      setFetchedHistory([...historyVoucher?.voucher_info]);
    }
  }, [historyVoucher, dispatch, customerId]);

  useEffect(() => {
    if (searchInputValue?.length > 0) {
      const filterMember = fetchedHistory.filter((item) => {
        return (
          item?.voucher_number
            ?.toLowerCase()
            .includes(searchInputValue?.toLowerCase()) ||
          item?.reciepient_name
            ?.toLowerCase()
            .includes(searchInputValue?.toLowerCase()) ||
          item?.reciepient_mobile_number
            ?.toLowerCase()
            .includes(searchInputValue?.toLowerCase()) ||
          item?.reciepient_email_address
            ?.toLowerCase()
            .includes(searchInputValue?.toLowerCase())
        );
      });

      setFetchedHistory(filterMember);
    }

    if (
      searchInputValue?.length <= 0 &&
      corporateId !== 1 &&
      corporateAdminStatusId === 1
    ) {
      createVoucherListBulk(selectedIndex, historyVoucher);
    }

    if (
      searchInputValue?.length <= 0 &&
      corporateId === 1 &&
      corporateAdminStatusId !== 1
    ) {
      createVoucherList(selectedIndex, historyVoucher);
    }
  }, [searchInputValue]);

  useEffect(() => {
    try {
      if (historyVoucher && corporateId !== 1 && corporateAdminStatusId === 1) {
        createVoucherListBulk(selectedIndex, historyVoucher);
      } else {
        createVoucherList(selectedIndex, historyVoucher);
      }
    } catch (err) {
      return err;
    }
  }, [selectedIndex, historyVoucher]);

  function createVoucherList(selectedIndex, historyVoucher) {
    switch (selectedIndex) {
      case 0:
        let vouchers = [];
        if (historyVoucher && historyVoucher?.voucher_info?.length) {
          vouchers = historyVoucher?.voucher_info;
        }

        if (!historyVoucher && !historyVoucher?.voucher_info?.length) {
          vouchers = [];
        }

        setFetchedHistory(vouchers);

        break;
      case 1:
        if (historyVoucher && historyVoucher?.voucher_info?.length) {
          setFetchedHistory([...historyVoucher?.voucher_info]);
        } else {
          setFetchedHistory([]);
        }

        break;
      case 2:
        if (historyVoucher && historyVoucher?.voucher_info?.length) {
          const redeemed = historyVoucher?.voucher_info?.filter(
            (actiPlans) => actiPlans?.voucher_type_id === 2
          );

          setFetchedHistory(redeemed);
        } else {
          setFetchedHistory([]);
        }

        break;
      default:
        setFetchedHistory([]);
    }
  }

  function createVoucherListBulk(selectedIndex, historyVoucher) {
    switch (selectedIndex) {
      case 0:
        let vouchers = [];
        if (
          historyVoucher &&
          historyVoucher?.voucher_info?.length &&
          !historyVoucher?.voucher_bulk_info?.length
        ) {
          vouchers = historyVoucher?.voucher_info;
        }

        if (
          historyVoucher &&
          !historyVoucher?.voucher_info?.length &&
          historyVoucher?.voucher_bulk_info?.length
        ) {
          vouchers = historyVoucher?.voucher_bulk_info;
        }

        if (
          historyVoucher &&
          historyVoucher?.voucher_info?.length &&
          historyVoucher?.voucher_bulk_info?.length
        ) {
          vouchers = [
            ...historyVoucher?.voucher_info,
            ...historyVoucher?.voucher_bulk_info,
          ];
        }

        if (
          !historyVoucher &&
          !historyVoucher?.voucher_info?.length &&
          !historyVoucher?.voucher_bulk_info?.length
        ) {
          vouchers = [];
        }

        setFetchedHistory(vouchers);

        break;
      case 1:
        if (historyVoucher && historyVoucher?.voucher_info?.length) {
          setFetchedHistory([...historyVoucher?.voucher_info]);
        } else {
          setFetchedHistory([]);
        }

        break;
      case 2:
        if (historyVoucher && historyVoucher?.voucher_bulk_info?.length) {
          setFetchedHistory([...historyVoucher?.voucher_bulk_info]);
        } else {
          setFetchedHistory([]);
        }

        break;
      case 3:
        if (historyVoucher && historyVoucher?.voucher_info?.length) {
          const redeemed = historyVoucher?.voucher_info?.filter(
            (actiPlans) => actiPlans?.voucher_type_id === 2
          );

          setFetchedHistory(redeemed);
        } else {
          setFetchedHistory([]);
        }

        break;
      default:
        setFetchedHistory([]);
    }
  }

  return (
    <div>
      <SignedInNavbarImproved />
      <div className="mx-auto mt-10 max-w-[1600px] px-3 pb-40 xsm:px-6 sm:px-8 md:px-12 lg:px-14">
        <div className="mt-4 flex items-center space-x-2 sm:space-x-4">
          <div
            onClick={() => navigate(-1)}
            className="rounded-full p-1 hover:bg-gray-200"
          >
            <BiArrowBack className="h-6 w-6 cursor-pointer text-gray-600" />
          </div>
          <h1 className="text-lg font-bold text-gray-700 sm:text-2xl">
            Voucher History
          </h1>
        </div>
        <div className="">
          {corporateId !== 1 && corporateAdminStatusId === 1
            ? historyVoucher?.voucher_info?.length &&
              selectedIndex !== 2 && (
                <div className=" mx-auto mt-8 flex w-full max-w-2xl flex-row space-x-2">
                  <div className="w-full">
                    <input
                      ref={searchRef}
                      onChange={(ev) => {
                        setSearchInputValue(ev.target.value);
                      }}
                      type="text"
                      placeholder="Search with voucher code, date etc"
                      className="w-full flex-1 rounded-lg border-gray-300 bg-transparent pl-12 text-gray-600 outline-none ring-0 placeholder:font-light placeholder:text-gray-400 focus:border-olivine-600 focus:outline-none focus:ring-1 focus:ring-olivine-600 sm:pl-14"
                    />
                    <div className="-mt-8 pl-4">
                      <BiSearch className="h-6 w-6 text-gray-400" />
                    </div>
                  </div>
                  <button className="rounded-lg bg-olivine-600 px-4 py-[11px] text-sm font-medium text-white transition duration-200 ease-in-out hover:bg-olivine-500 active:bg-olivine-600 xsm:px-6 sm:px-8">
                    Search
                  </button>
                </div>
              )
            : historyVoucher?.voucher_info?.length && (
                <div className=" mx-auto mt-8 flex w-full max-w-2xl flex-row space-x-2">
                  <div className="w-full">
                    <input
                      ref={searchRef}
                      onChange={(ev) => {
                        setSearchInputValue(ev.target.value);
                      }}
                      type="text"
                      placeholder="Search with voucher code, date etc"
                      className="w-full flex-1 rounded-lg border-gray-300 bg-transparent pl-12 text-gray-600 outline-none ring-0 placeholder:font-light placeholder:text-gray-400 focus:border-olivine-600 focus:outline-none focus:ring-1 focus:ring-olivine-600 sm:pl-14"
                    />
                    <div className="-mt-8 pl-4">
                      <BiSearch className="h-6 w-6 text-gray-400" />
                    </div>
                  </div>
                  <button className="rounded-lg bg-olivine-600 px-4 py-[11px] text-sm font-medium text-white transition duration-200 ease-in-out hover:bg-olivine-500 active:bg-olivine-600 xsm:px-6 sm:px-8">
                    Search
                  </button>
                </div>
              )}
        </div>
        <div className="mt-10">
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="flex items-center space-x-4 text-sm xsm:space-x-6 xsm:text-base">
              <div className="items-centern flex space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer">
                        <div className="px-1 font-semibold">
                          All <span className="hidden sm:inline">Vouchers</span>
                        </div>
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
              <div className="items-centern flex space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer">
                        <div className="px-1 font-semibold">
                          Single
                          <span className="hidden sm:inline"> Voucher</span>
                        </div>
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
              {corporateId !== 1 && corporateAdminStatusId === 1 ? (
                <div className="items-centern flex space-x-6 xsm:space-x-8">
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                            : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                        }
                      >
                        <div className="cursor-pointer">
                          <div className="px-1 font-semibold">
                            Bulk
                            <span className="hidden sm:inline"> Voucher</span>
                          </div>
                        </div>
                      </button>
                    )}
                  </Tab>
                </div>
              ) : null}

              <div className="items-centern flex space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer">
                        <div className="px-1 font-semibold">
                          Vouchers
                          <span className="hidden sm:inline"> Redeemed</span>
                        </div>
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="mt-6 cursor-pointer space-y-3">
                  {fetchedHistory && fetchedHistory?.length
                    ? fetchedHistory?.map((history, idx) =>
                        history?.voucher_number ? (
                          <div
                            key={idx}
                            className="max-w-4xl text-xs font-medium text-gray-400"
                            onClick={() => {
                              navigate("/voucher/voucher-details");
                              dispatch(dispatch(setSelectedVoucher(history)));
                            }}
                          >
                            <h3 className="p-2 text-[11px]">
                              {moment(history?.transaction_date).format("LL")}
                            </h3>
                            <div className="rounded-md border-[0.5px] border-gray-100 px-6 py-2 shadow-sm">
                              <div className="flex items-center justify-between">
                                <h2 className="text-gray-600">
                                  {`Voucher: ${history?.voucher_number}`}
                                </h2>
                                <p>{` ₦ ${Number(
                                  history?.voucher_amount
                                ).toLocaleString()}`}</p>
                              </div>
                              {history?.reciepient_name ? (
                                <div className="flex items-center justify-between">
                                  <h2 className="text-gray-600">
                                    Recipient Name:{" "}
                                    {`${history?.reciepient_name}`}
                                  </h2>
                                </div>
                              ) : null}
                              {history?.purchase_zoho_send_remark ? (
                                <div className="flex items-center justify-between">
                                  <h2 className="text-gray-600">
                                    Remark:{" "}
                                    {`${history?.purchase_zoho_send_remark}`}
                                  </h2>
                                </div>
                              ) : null}

                              {history?.voucher_status_name === "Open" ? (
                                <p className="mt-2 text-[11px] font-normal text-red-500">
                                  Open
                                </p>
                              ) : (
                                <p className="mt-2 text-[11px] font-normal text-gray-400">
                                  {`Redeemed- ${history?.redeem_date}`}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div
                            key={idx}
                            className="max-w-4xl text-xs font-medium text-gray-400"
                            onClick={() => {
                              navigate("/voucher/voucher-details-main");
                              dispatch(dispatch(setSelectedVoucher(history)));
                            }}
                          >
                            <h3 className="p-2 text-[11px]">
                              {moment(history?.voucher_date).format("LL")}
                            </h3>
                            <div className="rounded-md border-[0.5px] border-gray-100 px-6 py-2 shadow-sm">
                              <div className="flex items-center justify-between">
                                <h2 className="text-gray-600">
                                  Voucher (Bulk)
                                </h2>
                                <p>{` ₦ ${Number(
                                  history?.voucher_value
                                ).toLocaleString()}`}</p>
                              </div>
                              {history?.voucher_quantity ? (
                                <div className="flex items-center justify-between">
                                  <h2 className="text-gray-600">
                                    Quantity: {`${history?.voucher_quantity}`}
                                  </h2>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )
                      )
                    : null}
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="mt-6 cursor-pointer space-y-3">
                  {fetchedHistory && fetchedHistory?.length
                    ? fetchedHistory?.map((history, idx) => (
                        <div
                          key={idx}
                          className="max-w-4xl text-xs font-medium text-gray-400"
                          onClick={() => {
                            navigate("/voucher/voucher-details");
                            dispatch(dispatch(setSelectedVoucher(history)));
                          }}
                        >
                          <h3 className="p-2 text-[11px]">
                            {moment(history?.transaction_date).format("LL")}
                          </h3>
                          <div className="rounded-md border-[0.5px] border-gray-100 px-6 py-2 shadow-sm">
                            <div className="flex items-center justify-between">
                              <h2 className="text-gray-600">
                                {`Voucher: ${history?.voucher_number}`}
                              </h2>
                              <p>{` ₦ ${Number(
                                history?.voucher_amount
                              ).toLocaleString()}`}</p>
                            </div>
                            {history?.reciepient_name ? (
                              <div className="flex items-center justify-between">
                                <h2 className="text-gray-600">
                                  Recipient Name:{" "}
                                  {`${history?.reciepient_name}`}
                                </h2>
                              </div>
                            ) : null}
                            {history?.purchase_zoho_send_remark ? (
                              <div className="flex items-center justify-between">
                                <h2 className="text-gray-600">
                                  Remark:{" "}
                                  {`${history?.purchase_zoho_send_remark}`}
                                </h2>
                              </div>
                            ) : null}
                            {history?.voucher_status_name === "Open" ? (
                              <p className="mt-2 text-[11px] font-normal text-red-500">
                                Open
                              </p>
                            ) : (
                              <p className="mt-2 text-[11px] font-normal text-gray-400">
                                {`Redeemed- ${history?.redeem_date}`}
                              </p>
                            )}
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </Tab.Panel>
              {corporateId !== 1 && corporateAdminStatusId === 1 ? (
                <Tab.Panel>
                  <div className="mt-6 cursor-pointer space-y-3">
                    {fetchedHistory && fetchedHistory?.length
                      ? fetchedHistory?.map((history, idx) => (
                          <div
                            key={idx}
                            className="max-w-4xl text-xs font-medium text-gray-400"
                            onClick={() => {
                              navigate("/voucher/voucher-details-main");
                              dispatch(dispatch(setSelectedVoucher(history)));
                            }}
                          >
                            <h3 className="p-2 text-[11px]">
                              {moment(history?.voucher_date).format("LL")}
                            </h3>
                            <div className="rounded-md border-[0.5px] border-gray-100 px-6 py-2 shadow-sm">
                              <div className="flex items-center justify-between">
                                <h2 className="text-gray-600">
                                  Voucher (Bulk)
                                </h2>
                                <p>{` ₦ ${Number(
                                  history?.voucher_value
                                ).toLocaleString()}`}</p>
                              </div>
                              {history?.voucher_quantity ? (
                                <div className="flex items-center justify-between">
                                  <h2 className="text-gray-600">
                                    Quantity: {`${history?.voucher_quantity}`}
                                  </h2>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </Tab.Panel>
              ) : null}
              <Tab.Panel>
                <div className="mt-6 cursor-pointer space-y-3">
                  {fetchedHistory && fetchedHistory?.length
                    ? fetchedHistory?.map((history, idx) => (
                        <div
                          key={idx}
                          className="max-w-4xl text-xs font-medium text-gray-400"
                          onClick={() => {
                            navigate("/voucher/voucher-details");
                            dispatch(dispatch(setSelectedVoucher(history)));
                          }}
                        >
                          <h3 className="p-2 text-[11px]">
                            {moment(history?.transaction_date).format("LL")}
                          </h3>
                          <div className="rounded-md border-[0.5px] border-gray-100 px-6 py-2 shadow-sm">
                            <div className="flex items-center justify-between">
                              <h2 className="text-gray-600">
                                {`Voucher: ${history?.voucher_number}`}
                              </h2>
                              <p>{` ₦ ${Number(
                                history?.voucher_amount
                              ).toLocaleString()}`}</p>
                            </div>
                            {history?.reciepient_name ? (
                              <div className="flex items-center justify-between">
                                <h2 className="text-gray-600">
                                  Recipient Name:{" "}
                                  {`${history?.reciepient_name}`}
                                </h2>
                              </div>
                            ) : null}
                            {history?.purchase_zoho_send_remark ? (
                              <div className="flex items-center justify-between">
                                <h2 className="text-gray-600">
                                  Remark:{" "}
                                  {`${history?.purchase_zoho_send_remark}`}
                                </h2>
                              </div>
                            ) : null}
                            {history?.voucher_status_name === "Open" ? (
                              <p className="mt-2 text-[11px] font-normal text-red-500">
                                Open
                              </p>
                            ) : (
                              <p className="mt-2 text-[11px] font-normal text-gray-400">
                                {`Redeemed- ${history?.redeem_date}`}
                              </p>
                            )}
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}

export default VoucherHistory;
