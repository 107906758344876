import React, { useState } from "react";
// import { Store } from "react-notifications-component";
import Logo from "../../assets/logo.svg";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createTransactionPin, todoListStatus } from "../../store/actions";
import { toast } from "react-hot-toast";
import { authService } from "../../services/authService";
import { init_data, refreshTodo } from "../../core/helpers";

const Pin = ({ onClose }) => {
  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();

  const [otp, setOtp] = useState(new Array(4).fill(""));
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const toastId = toast.loading("Submitting your pin...");

      const payload = {
        sql_ref_no: "10",
        user_id: userId?.toString(),
        verification_code,
        pin_number: otp.join(""),
        option: "11",
      };

      dispatch(createTransactionPin(payload))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            return toast.error("Pin could not be submitted!", {
              id: toastId,
            });
          }
          setOtp([...otp.map((v) => "")]);

          toast.success("Transaction Pin set", {
            id: toastId,
          });

          dispatch(todoListStatus(init_data));

          window.location.reload();
          onClose();
          return navigate("/home/todo");
        })
        .catch((err) => {
          setOtp("");

          toast.error("Transaction Pin could not be set!", {
            id: toastId,
          });

          navigate("/login");
          return err;
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="mx-auto h-full w-full max-w-[420px] px-2 pb-16 pt-40 xsm:px-3">
      <div className="rounded-2xl bg-white px-4 py-4 sm:px-6">
        <div>
          <div className="font-semibold text-gray-700 xsm:text-lg sm:text-xl">
            Confirm PIN
          </div>
          <div className="mt-4 text-center text-sm text-gray-600">
            Confirm your 4-digit Transaction PIN
          </div>
        </div>
        <div className="mt-4">
          <form onSubmit={handleSubmit}>
            <div className="relative flex items-center justify-center space-x-2 rounded-md">
              {otp.map((data, index) => {
                return (
                  <input
                    type="text"
                    maxLength={1}
                    className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                    name="opt"
                    key={index}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
            </div>
            <div className="mt-8 flex justify-center pb-2">
              <button className="rounded-md bg-olivine-500 px-16 py-3 text-xs font-semibold tracking-wider text-white transition duration-300 hover:bg-olivine-600 sm:px-24">
                CONFIRM PIN
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Pin;
