import React, { useContext } from "react";
import toast from "react-hot-toast";
import BasketImage from "../../assets/basket-background-image.jpg";
import RxjsStoreContext from "../../context/RxjsStoreContext";
import CurrencyFormat from "react-currency-format";

const RecommendationsComponents = ({ item, key }) => {
  const rxjsStore = useContext(RxjsStoreContext);

  const addToBasket = (product) => {
    const quantity = 1;
    if (product.item_stock_out === true) {
      return toast.error("Item is out of stock!");
    }
    rxjsStore.setUserSelectedBasket(
      { ...product, type: product?.item_type_id === 1 ? "BASKET" : "BUTCHER" },
      quantity
    );
    toast.success(
      `${quantity} qty of ${product?.name} has been added to basket!`
    );
  };

  return (
    <section key={key}>
      {/* end alert */}
      <div className="mt-6 w-auto bg-white pb-3 @container">
        <div className="">
          <div className="max-w-48 flex h-40 w-full items-center justify-center overflow-hidden rounded-lg border-[1px] border-gray-200 bg-white">
            <img
              src={item?.item_image_url ? item?.item_image_url : BasketImage}
              className="cursor aspect-auto h-40 w-auto object-contain"
              alt=""
            />
          </div>
          <div className="mb-1 ml-1 w-full @xs:mt-12">
            <div className="flex items-center justify-between space-x-4 px-2 @xs:space-x-2">
              <h1
                title=""
                className="mt-1 line-clamp-1 cursor-pointer text-base font-bold text-gray-600 @xs:text-sm"
              >
                {item?.sku ? item?.sku : null}
              </h1>
            </div>
            <div
              title=""
              className="mt-1 line-clamp-1 cursor-pointer px-2 text-sm font-semibold"
            >
              <div className="text-gray-600">
                <CurrencyFormat
                  className={"font-bold"}
                  value={Number.parseFloat(item?.item_rate_zoho)?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
              </div>
            </div>
            <div className="mt-2">
              <button
                onClick={() => addToBasket(item)}
                className="w-full max-w-[150px] transform cursor-pointer rounded-md bg-olivine-500 px-3 py-2.5 text-sm font-medium text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecommendationsComponents;
