import React from "react";
import { IoClose } from "react-icons/io5";
import { MdOutlineFileDownload } from "react-icons/md";
import { downloadFile } from "../../../core/helpers";

function ImportantNotice({ setOpenTwo }) {
  return (
    <div>
      <div className="flex max-w-lg flex-row justify-end bg-[#FEFEFD]">
        <div
          onClick={() => setOpenTwo(false)}
          className="cursor-pointer rounded-full bg-olivine-500 p-1 text-white active:bg-olivine-400"
        >
          <IoClose className="h-5 w-5" />
        </div>
      </div>
      <h1 className="mt-4 text-center text-xl font-bold text-gray-700">
        Important Notice!
      </h1>
      <div className="mt-2 text-center text-sm text-gray-500">
        <p>
          To make your Excel or CSV sheet, you can organize your data with these
          column names:
        </p>
        <div className="mt-2 space-y-2 text-xs">
          <p>1. Name: This is where you put the recipient's name.</p>
          <p>
            2. Mobile: This is where you put the recipient's mobile or phone
            number.
          </p>
          <p>3. Email: This is where you put the recipient's email address.</p>
        </div>
      </div>
      <div className="mt-8 w-full pb-2">
        <button className="mx-auto flex w-full max-w-sm transform cursor-pointer items-center justify-center rounded-md border-[1px] border-olivine-500 py-[15px] text-xs font-semibold text-olivine-500 transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 xsm:text-sm sm:px-8 sm:py-[11px]">
          <div
            onClick={() => downloadFile()}
            className="flex w-auto cursor-pointer items-center space-x-2"
          >
            <MdOutlineFileDownload className="h-5 w-5 text-olivine-500" />
            Download Sample File
          </div>
        </button>
      </div>
    </div>
  );
}

export default ImportantNotice;
