import React, { useState, useEffect } from "react";
import { BiArrowBack, BiRefresh } from "react-icons/bi";
import { FaWallet } from "react-icons/fa";
import IsLoadingFour from "../../IsloadingFour";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import BarChart from "../../BarChart";
import { toast } from "react-hot-toast";
import {
  getTransactions,
  getWalletBalance,
  userStatsGet,
} from "../../../store/actions";
import { authService } from "../../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setWalletUpdate } from "../../../store/slices/modalSlice";
import Chart from "chart.js/auto";

const monthsOrder = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

function StatisticsPage() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [enabled, setEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedStats, setFetchedStats] = useState([]);
  const [sortedArray, setSortedArray] = useState([]);

  const [fetchedUserBalance, setFetchedUserBalance] = useState();

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();

  const { walletBalance, stats } = useSelector((state) => state.user);

  useEffect(() => {
    if (!walletBalance) {
      const data = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };
      dispatch(getWalletBalance(data))
        .unwrap()
        .catch((err) => {
          if (err) {
            authService.logOut();
            return navigate("/login");
          }
        });
    }

    if (walletBalance) setFetchedUserBalance(walletBalance);
  }, [
    dispatch,
    userId,
    customerId,
    verification_code,
    walletBalance,
    navigate,
  ]);

  useEffect(() => {
    if (!stats) {
      const data = {
        customer_id: customerId?.toString(),
        verification_code,
      };

      dispatch(userStatsGet(data));
    }

    if (stats?.length) {
      setFetchedStats(stats);
    }
  }, [stats, dispatch, customerId, verification_code]);

  useEffect(() => {
    if (fetchedStats?.length) {
      const sortedData = sortByMonth(fetchedStats);
      setSortedArray(sortedData);
    }
  }, [fetchedStats]);

  function sortByMonth(array) {
    const arrayToSort = [...array];
    return arrayToSort?.sort((a, b) => {
      const monthA = monthsOrder[a?.transaction_month];
      const monthB = monthsOrder[b?.transaction_month];
      return monthA - monthB;
    });
  }

  const handleWalletUpdate = () => {
    const toastId = toast.loading("Updating Wallet Balance..");
    setIsLoading(true);
    dispatch(setWalletUpdate(false));

    const data = {
      sql_ref_no: "31",
      user_id: userId.toString(),
      customer_id: customerId.toString(),
      verification_code,
      option: "1",
    };

    dispatch(getWalletBalance(data))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        const id = customerId?.toString();
        dispatch(getTransactions(id));
        return toast.success("Wallet Balance updated!", {
          id: toastId,
        });
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Wallet Balance failed", {
          id: toastId,
        });
      });
  };

  return (
    <div>
      <SignedInNavbarImproved />
      <div className="mx-auto max-w-[1600px] px-2 pb-96 pt-8 xsm:px-4 sm:px-8 md:px-10 lg:px-16">
        <div className="mt-4 flex items-center space-x-2 sm:space-x-4">
          <div
            onClick={() => navigate("/home/wallet")}
            className="rounded-full p-1 hover:bg-gray-200"
          >
            <BiArrowBack className="h-6 w-6 cursor-pointer text-gray-600" />
          </div>
          <div className="text-lg font-bold text-gravel-600 sm:text-2xl">
            Statistics
          </div>
        </div>
        <div className="mt-6 rounded-md bg-[#EAF6DC] px-2 pb-4 pt-3 ring-[0.5px] ring-gray-300 xsm:px-4 sm:px-8 sm:pb-8 sm:pt-8">
          <div className="mt-4">
            <div className="flex items-center justify-center space-x-2 sm:justify-start">
              <div>
                <FaWallet className="h-5 w-5" />
              </div>
              <div className="">Total Balance</div>
              <div
                onClick={() => handleWalletUpdate()}
                className="text-olivine-500 transition duration-500 hover:text-olivine-600 active:rotate-45"
              >
                <BiRefresh className="h-6 w-6 cursor-pointer" />
              </div>
            </div>
            <div className="mt-1 flex items-center justify-center space-x-1 xsm:mt-0 sm:justify-start">
              {isLoading ? (
                <IsLoadingFour />
              ) : enabled ? (
                <div className="h-10 text-3xl font-bold text-gravel-600">
                  {fetchedUserBalance && fetchedUserBalance?.available_balance
                    ? `₦ ${Number(
                        fetchedUserBalance?.available_balance
                      ).toLocaleString()}`
                    : "₦ 0.00"}
                </div>
              ) : (
                <div className="grid h-10 pt-1 text-3xl font-bold text-gravel-600">
                  *************
                </div>
              )}

              <div className="flex items-center justify-center">
                <div className="text-[#828282] xsm:flex xsm:items-center">
                  {enabled ? (
                    <div
                      onClick={() => setEnabled(false)}
                      className="flex h-10 w-6 items-center justify-center pb-1 xsm:pb-0"
                    >
                      <AiFillEyeInvisible className="h-6 w-6 cursor-pointer text-olivine-500 active:text-olivine-700" />
                    </div>
                  ) : (
                    <div
                      onClick={() => setEnabled(true)}
                      className="flex h-10 w-6 items-center justify-center pb-1 xsm:pb-0"
                    >
                      <AiFillEye className="h-6 w-6 cursor-pointer text-olivine-500 active:text-olivine-700" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 h-[500px] w-full sm:h-[450px]">
          <BarChart stats={sortedArray} />
        </div>
      </div>
    </div>
  );
}

export default StatisticsPage;
