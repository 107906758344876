import React from "react";
import { IoClose } from "react-icons/io5";
import { BsFillCreditCard2FrontFill } from "react-icons/bs";
import { MdDateRange } from "react-icons/md";
import { IoCard, IoLocationSharp } from "react-icons/io5";
import { FaIdCard } from "react-icons/fa";
import { HiHome } from "react-icons/hi";


function AddCardPopover({ handleAddCard, onClose }) {
  return (
    <div className="p-8 bg-white w-[400px] sm:w-[460px] text-gray-500 shadow-lg rounded-2xl">
      <div className="flex justify-between items-center">
        <div className="font-bold text-lg text-gravel-500">Transaction PIN</div>
        <button
          onClick={() => {
            onClose();
            handleAddCard();
          }}
          className="p-1 rounded-full hover:bg-gray-200"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div>
        <form>
          <div className="mt-6">
            <label
              htmlFor="cardNumber"
              className="ml-3 mb-[1px] flex justify-start text-xs text-gray-500"
            >
              Card Number
            </label>
            <div className="relative rounded-md">
              <div className="inset-y-0 pl-3">
                <BsFillCreditCard2FrontFill className="absolute mt-3 h-5 w-5 text-gray-600" />
              </div>
              <input
                type="text"
                placeholder="1234 5678 9012 3456"
                className="bg-white block w-full pl-10 placeholder:text-sm placeholder:text-gray-400 border-gray-500 rounded-md focus:ring-olivine-500 focus:border-olivine-500"
              />
            </div>
          </div>
          <div className="flex items-center space-x-2 mt-4">
            <div>
              <label
                htmlFor="expiryDate"
                className="ml-3 mb-[2px] flex justify-start text-xs text-gray-500"
              >
                Expiry Date
              </label>
              <div className="relative rounded-md">
                <div className="inset-y-0 pl-3">
                  <MdDateRange className="absolute mt-3 h-5 w-5 text-gray-600" />
                </div>
                <input
                  type="text"
                  placeholder="04"
                  className="bg-white block w-full pl-10 placeholder:text-sm placeholder:text-gray-400 border-gray-500 rounded-md focus:ring-olivine-500 focus:border-olivine-500"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="cVVNumber"
                className="ml-3 mb-[2px] flex justify-start text-xs text-gray-500"
              >
                CVV Number
              </label>
              <div className="relative rounded-md">
                <div className="inset-y-0 pl-3">
                  <IoCard className="absolute mt-3 h-5 w-5 text-gray-600" />
                </div>
                <input
                  type="text"
                  placeholder="222"
                  className="bg-white block w-full pl-10 placeholder:text-sm placeholder:text-gray-400 border-gray-500 rounded-md focus:ring-olivine-500 focus:border-olivine-500"
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <label
              htmlFor="cardholder’sName"
              className="ml-3 mb-[1px] flex justify-start text-xs text-gray-500"
            >
              Cardholder’s Name
            </label>
            <div className="relative rounded-md">
              <div className="inset-y-0 pl-3">
                <FaIdCard className="absolute mt-3 h-5 w-5 text-gray-600" />
              </div>
              <input
                type="text"
                placeholder=""
                className="bg-white block w-full pl-10 placeholder:text-sm placeholder:text-gray-400 border-gray-500 rounded-md focus:ring-olivine-500 focus:border-olivine-500"
              />
            </div>
          </div>
          <div className="mt-4">
            <label
              htmlFor="zipCode"
              className="ml-3 mb-[2px] flex justify-start text-xs text-gray-500"
            >
              Zip Code
            </label>
            <div className="relative rounded-md">
              <div className="inset-y-0 pl-3">
                <IoLocationSharp className="absolute mt-3 h-5 w-5 text-gray-600" />
              </div>
              <input
                type="text"
                placeholder="111111"
                className="bg-white block w-full pl-10 placeholder:text-sm placeholder:text-gray-400 border-gray-500 rounded-md focus:ring-olivine-500 focus:border-olivine-500"
              />
            </div>
          </div>
          <div className="mt-4">
            <label
              htmlFor="address"
              className="ml-3 flex mb-[1px] justify-start text-xs text-gray-500"
            >
              Address
            </label>
            <div className="relative rounded-md">
              <div className="inset-y-0 pl-3">
                <HiHome className="absolute mt-3 h-5 w-5 text-gray-600" />
              </div>
              <input
                type="text"
                placeholder="12 Church Street"
                className="bg-white block w-full pl-10 placeholder:text-sm placeholder:text-gray-400 border-gray-500 rounded-md focus:ring-olivine-500 focus:border-olivine-500"
              />
            </div>
          </div>
          <div className="flex justify-between text-center tracking-widest mt-8">
            <button
              onClick={() => {
                onClose();
                handleAddCard();
              }}
              className="px-8 py-3 text-olivine-500 border-2 border-olivine-500 text-sm rounded-md hover:border-olivine-600 transition-all duration-300 tracking-widest"
            >
              BACK
            </button>
            <button className="px-6 py-3 text-white bg-olivine-500 rounded-md text-sm hover:bg-olivine-600 transition-all duration-300 tracking-widest">
              ADD ₦9,000.00
            </button>
          </div>
        </form>
      </div>
      <div></div>
    </div>
  );
}

export default AddCardPopover;
