import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { HiHome } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";
import CircularProgress from "@mui/material/CircularProgress";
import { rxjsStoreProvider } from "../../../context/OrderHistoryRxjsProvider";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CartImage from "../../../assets/CartImage.svg";
import { CgClose } from "react-icons/cg";
import BasketService from "../../../services/basketService";
import { authService } from "../../../services/authService";
import { toast } from "react-hot-toast";
import moment from "moment";
import { BsQuestionLg } from "react-icons/bs";
import OrderRating from "../../Rating/OrderRating";

function OrderTransactionDetails() {
  const [historyData, setHistoryData] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [toolTipOpen, setToolTipOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const subscription$ = rxjsStoreProvider
      .getSingleOrderHistory()
      .subscribe((data) => {
        if (!data?.subscription_id && !data?.salesorder_id) {
          navigate("/home");
        }
        setHistoryData(data);
      });

    return () => {
      if (historyData?.subscription_id || historyData?.salesorder_id) {
        subscription$.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function confirmRepeatOrder() {
    setOpen(false);
    if (authService.isLogin()) {
      if (historyData?.salesorder_id) {
        // custom basket
        let tempCustomBasket = {
          plan_name: "Custom Basket",
          notes: "",
          discount: historyData?.discount,
          customer_id: authService.getCurrentUserZohoCustomerId(),
          shipping_charge: historyData?.shipping_charge,
          adjustment: 0,
          adjustment_description: "custom basket item",
          exchange_rate: 1,
          line_items: [],
        };

        for (let product of historyData?._item_list) {
          const tempItems = {
            item_id: product.item_id,
            name: product.name,
            rate: product.rate,
            quantity: product.quantity,
            unit: product?.unit,
            item_total: Number(product.quantity) * Number(product.rate),
          };
          tempCustomBasket.line_items.push(tempItems);
        }

        setLoading(true);
        BasketService.submitCustomBasket(tempCustomBasket)
          .then((data) => {
            setLoading(false);
            if (data?.Message_Id == 1) {
              toast.success(data?.Message_Text);
              return navigate(-1);
            } else {
              toast.error("Failed!  " + data?.message_text);
            }
            // handleCreate()
            navigate(-1);
            navigate("/home");
          })
          .catch((error) => {
            setLoading(false);
            toast.error("Error saving records");
          });
      }

      if (historyData?.subscription_id) {
        //  Subscription plan
        let subscriptionBasket = {
          customer_id: historyData?.customer_id,
          starts_at: historyData?.created_at,
          auto_collect: historyData?.auto_collect,
          plan: {},
          addons: [],
          coupon_code: "FLAT_20",
        };

        for (let product of historyData?.plan) {
          const tempPlan = {
            //  discount: product?.discount,
            plan_code: product?.plan_code,
            billing_cycles: -1,
            quantity: product?.quantity,
            price: Number(historyData?.amount),
          };
          subscriptionBasket.plan = tempPlan;
        }

        for (let addon of historyData?.plan[0]?.addons) {
          subscriptionBasket.addons.push({
            addon_code: addon.addon_code,
            price: Number(addon.price),
            quantity: Number(addon.quantity),
            billing_cycles: -1,
          });
        }

        setLoading(true);
        BasketService.submitSubscriptionBasket(subscriptionBasket)
          .then((data) => {
            if (data?.Message_Id == 1) {
              toast.success(data?.Message_Text);
              return navigate(-1);
            } else {
              toast.error("Failed " + data?.message_text);
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    } else {
      navigate("/login");
      toast.error("You session has expired, please login!");
    }
  }

  function onPressRepeatOrder(orderData) {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="md:flex md:flex-row">
        <div className="flex-1 bg-primary_color">
          {/* Confirm Alert */}
          <div>
            <Dialog
              className="h-full pb-16"
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="h-hug w-full rounded-md border-gray-300 bg-gray-100 shadow shadow-gray-400">
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <div className="flex items-center justify-end">
                      <button
                        onClick={handleClose}
                        className="rounded-full p-2 active:bg-gray-200"
                      >
                        <CgClose className="h-5 w-5 text-gravel-500" />
                      </button>
                    </div>
                    <div className="flex justify-center">
                      <div className="">
                        <img
                          src={CartImage}
                          className="h-24 w-28 sm:h-32 sm:w-36"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="cursor mt-4 flex justify-center text-base font-semibold xsm:text-lg ">
                      <div className=" font-bold text-gray-800">
                        Confirm Re-order
                      </div>
                    </div>
                    <div className="mt-2 text-center text-xs">
                      <div className="text-gray-500">
                        Please confirm that you would like to re-order the
                        details of your your last order.
                      </div>
                    </div>
                    <div className="mt-5 flex flex-col-reverse pb-4 text-center tracking-wider sm:flex-row sm:items-center sm:justify-between">
                      <div
                        onClick={handleClose}
                        className="mt-4 !cursor-pointer rounded-md border-[1px] border-olivine-500 py-2 text-sm font-semibold text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-500 sm:mt-0 sm:px-8"
                      >
                        No, thank you
                      </div>
                      <div className="">
                        <div
                          onClick={confirmRepeatOrder}
                          className="!cursor-pointer rounded-md border-[1px] border-olivine-500 py-2 text-sm font-semibold text-olivine-500 opacity-100 transition duration-300 hover:opacity-80 hover:ring-1 hover:ring-olivine-500 sm:px-8"
                        >
                          Yes, Re-order
                        </div>
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
              </div>
            </Dialog>
          </div>

          {/*  SUBSCRIPTION ITEMS */}

          <div className="w-full px-3 pb-3 pt-4 xsm:px-4 sm:px-8 md:px-10 lg:px-16">
            <div className="flex items-center space-x-3 text-xs font-semibold text-gray-500">
              <div className="flex  items-center space-x-1">
                <div onClick={() => navigate("/")}>
                  <HiHome className="cursor h-4 w-4" />
                </div>
                <div>
                  <IoIosArrowForward className="h-[11px] w-[11px]" />
                </div>
              </div>
              <div
                onClick={() => navigate("/basket/create-basket")}
                className="flex cursor-pointer items-center space-x-1"
              >
                <div>Custom basket</div>
                <div>
                  <IoIosArrowForward className="h-[11px] w-[11px]" />
                </div>
              </div>
              <div
                onClick={() => navigate("/basket/order-history")}
                className="flex cursor-pointer items-center space-x-1"
              >
                <div>Order History</div>
                <div>
                  <IoIosArrowForward className="h-[11px] w-[11px]" />
                </div>
              </div>
              <div className="flex cursor-pointer items-center space-x-1 text-olivine-500">
                <div>Order details </div>
                <div>
                  <IoIosArrowForward className="h-[11px] w-[11px]" />
                </div>
              </div>
            </div>
            {historyData?.subscription_id && (
              <div className="mt-6 flex items-center justify-center text-xs sm:text-sm">
                <div className="w-full max-w-xl space-y-4 pb-16">
                  <div className="mb-12 mt-8 flex items-center space-x-4">
                    <div className="rounded-full  p-1 hover:bg-gray-200">
                      <BiArrowBack
                        onClick={() => navigate(-1)}
                        className="h-6 w-6 cursor-pointer text-gray-600"
                      />
                    </div>
                    <div className="text-lg font-bold text-gravel-600 sm:text-2xl">
                      Order Details
                    </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="flex w-[130px] items-center space-x-1 whitespace-nowrap font-semibold md:w-48">
                      <div>Transaction Date:</div>
                      <div
                        onClick={() => setToolTipOpen(true)}
                        className="rounded-full bg-olivine-500 p-[3px] text-white"
                      >
                        <BsQuestionLg className="h-2 w-2 cursor-pointer" />
                      </div>
                    </div>
                    <div>
                      {historyData?.created_at
                        ? moment(new Date(historyData?.created_at)).format(
                            "DD-MM-YYYY"
                          )
                        : moment(new Date(historyData?.activated_at)).format(
                            "DD-MM-YYYY"
                          )}
                    </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="flex w-[130px] items-center space-x-1 font-semibold md:w-48">
                      <div>Order ID:</div>
                      <div
                        onClick={() => setToolTipOpen(true)}
                        className="rounded-full bg-olivine-500 p-[3px] text-white"
                      >
                        <BsQuestionLg className="h-2 w-2 cursor-pointer" />
                      </div>
                    </div>
                    <div className="">{historyData?.subscription_id} </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Product Name:
                    </div>
                    <div>{historyData?.product_name} </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Plan:
                    </div>
                    <div>
                      {historyData?.plan && historyData?.plan?.length
                        ? historyData?.plan[0]?.name
                        : historyData?.name}
                    </div>
                  </div>

                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Activated Date:
                    </div>
                    <div>
                      {moment(new Date(historyData?.activated_at)).format(
                        "DD-MM-YYYY"
                      )}
                    </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Start Date:
                    </div>
                    <div>
                      {historyData?.current_term_starts_at
                        ? moment(
                            new Date(historyData?.current_term_starts_at)
                          ).format("DD-MM-YYYY")
                        : ""}
                    </div>
                  </div>

                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      End Date:
                    </div>
                    <div>
                      {historyData?.current_term_ends_at
                        ? moment(
                            new Date(historyData?.current_term_ends_at)
                          ).format("DD-MM-YYYY")
                        : ""}
                    </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Next Billing:
                    </div>
                    <div>
                      {historyData?.next_billing_at
                        ? moment(new Date(historyData?.next_billing_at)).format(
                            "DD-MM-YYYY"
                          )
                        : ""}
                    </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Interval:
                    </div>
                    <div>{historyData?.interval_unit} </div>
                  </div>
                  <div className="space-y-1 xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Order Details
                    </div>

                    {/*  start  */}
                    {historyData?.plan?.map((plan) => {
                      return (
                        <div className="flex text-[10px] text-gray-600 xsm:text-[11px] sm:text-[12px]">
                          <div className="whitespace-nowrap' w-[130px] space-x-1 pl-2 md:w-48">
                            <span className="">{plan?.name} </span>
                            <span className="">x</span>
                            <span className="">{plan?.quantity}</span>
                          </div>
                          <div className="">
                            <span className=" font-semibold">
                              <CurrencyFormat
                                value={Number.parseFloat(plan?.total)?.toFixed(
                                  2
                                )}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={historyData?.currency_symbol}
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })}

                    {historyData?.plan[0]?.addons.map((addon, index) => {
                      return (
                        <div className="flex text-[10px] text-gray-600 xsm:text-[11px] sm:text-[12px]">
                          <div className="whitespace-nowrap' w-[130px] space-x-1 pl-2 md:w-48">
                            <span className="">
                              {addon?.name ? addon?.name : "Delivery Charge"}
                            </span>
                            <span className="">x</span>
                            <span className="">{addon?.quantity}</span>
                          </div>
                          <div className="">
                            <span className="font-semibold">
                              <CurrencyFormat
                                value={Number.parseFloat(addon?.total)?.toFixed(
                                  2
                                )}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={historyData?.currency_symbol}
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="flex text-lg xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Total:
                    </div>
                    <div>
                      <CurrencyFormat
                        className={"font-bold "}
                        value={Number.parseFloat(historyData?.amount)?.toFixed(
                          2
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={historyData?.currency_symbol}
                      />
                    </div>
                  </div>

                  <div className="">
                    <button
                      disabled={loading}
                      onClick={() => onPressRepeatOrder(historyData)}
                      className="mt-4 w-full rounded-md border-[1px] border-olivine-500 bg-[#F0F7E1] py-2 text-base opacity-75 transition duration-300 hover:opacity-100 hover:ring-[1px] hover:ring-olivine-500"
                    >
                      <div className="space-x-2 text-center font-semibold text-olivine-500">
                        {loading ? (
                          <CircularProgress color="success" size="1.16rem" />
                        ) : (
                          <span>Repeat Order</span>
                        )}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* SALES ORDER ITEM */}
            {historyData?.salesorder_id && (
              <div className="col-span-3 mt-6 flex items-center justify-center text-xs sm:text-sm">
                <div className="w-full max-w-xl space-y-4 pb-16">
                  <div className="mb-12 mt-8 flex items-center space-x-4">
                    <div className="rounded-full  p-1 hover:bg-gray-200">
                      <BiArrowBack
                        onClick={() => navigate(-1)}
                        className="h-6 w-6 cursor-pointer text-gray-600"
                      />
                    </div>
                    <div className="text-lg font-bold text-gravel-600 sm:text-2xl">
                      Order Details
                    </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="flex w-[130px] items-center space-x-1 whitespace-nowrap font-semibold md:w-48">
                      <div>Transaction Date:</div>
                      <div
                        onClick={() => setToolTipOpen(true)}
                        className="rounded-full bg-olivine-500 p-[3px] text-white"
                      >
                        <BsQuestionLg className="h-2 w-2 cursor-pointer" />
                      </div>
                    </div>
                    <div>
                      {moment(new Date(historyData?.created_time)).format(
                        "DD-MM-YYYY"
                      )}
                    </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="flex w-[130px] items-center space-x-1 font-semibold md:w-48">
                      <div>Order ID:</div>
                      <div
                        onClick={() => setToolTipOpen(true)}
                        className="rounded-full bg-olivine-500 p-[3px] text-white"
                      >
                        <BsQuestionLg className="h-2 w-2 cursor-pointer" />
                      </div>
                    </div>
                    <div className="">{historyData?.salesorder_id} </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Product Name:
                    </div>
                    <div>{historyData?.salesorder_number} </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Plan:
                    </div>
                    <div> Custom Basket </div>
                  </div>

                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Activated Date:
                    </div>
                    <div>
                      {moment(new Date(historyData?.activated_at)).format(
                        "DD-MM-YYYY"
                      )}
                    </div>
                  </div>
                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Start Date:
                    </div>
                    <div>
                      {moment(new Date(historyData?.start_date)).format(
                        "DD-MM-YYYY"
                      )}
                    </div>
                  </div>

                  <div className="flex xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Shipping:
                    </div>
                    <div>{historyData?.shipping_charge} </div>
                  </div>
                  <div className="space-y-1 xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Order Details
                    </div>

                    {/* start */}
                    {historyData?._item_list?.map((plan, index) => {
                      return (
                        <div
                          key={index}
                          className="flex text-[10px] text-gray-600 xsm:text-[11px] sm:text-[12px]"
                        >
                          <div className="whitespace-nowrap' w-[130px] space-x-1 pl-2 md:w-48">
                            <span className="">{plan?.name} </span>
                            <span className="">x</span>
                            <span className="">{plan?.quantity}</span>
                          </div>
                          <div className="">
                            <span className="font-semibold">
                              <CurrencyFormat
                                value={Number.parseFloat(plan?.rate)?.toFixed(
                                  2
                                )}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={historyData?.currency_symbol}
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/* end */}

                  <div className="flex text-lg xsm:px-2 sm:px-6">
                    <div className="w-[130px] whitespace-nowrap font-semibold md:w-48">
                      Total:
                    </div>
                    <div>
                      <CurrencyFormat
                        className={"font-bold "}
                        value={Number.parseFloat(
                          historyData?.total_amount
                        )?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={historyData?.currency_symbol}
                      />
                    </div>
                  </div>
                  <div className="">
                    <button
                      onClick={() => onPressRepeatOrder(historyData)}
                      className="mt-4 w-full rounded-md border-[1px] border-olivine-500 bg-[#F0F7E1] py-2 text-base opacity-75 transition duration-300 hover:opacity-100 hover:ring-[1px] hover:ring-olivine-500"
                    >
                      <div className="space-x-2 text-center font-semibold text-olivine-500">
                        {loading ? (
                          <CircularProgress color="success" size="1.16rem" />
                        ) : (
                          <span>Repeat Order</span>
                        )}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full md:max-w-[400px]">
          <OrderRating historyData={historyData} />
        </div>
      </div>
    </>
  );
}

export default OrderTransactionDetails;
