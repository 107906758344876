import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import AddonListboxOne from "./AddonListboxOne";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";

function AddonPopover({ dataPull, addons, closer, edit, existingData }) {
  const [addonQuantity, setAddonQuantity] = useState(
    existingData?.quantity || 1
  );
  const [addonMonths, setAddonMonths] = useState(existingData?.noMonths || 1);
  const [selectedAddonPlan, setSelectedAddonPlan] = useState("");
  const [monthlyRecurring, setMonthlyRecurring] = useState("no");

  const handleMonthlyRecurring = (event) => {
    setMonthlyRecurring(event.target.value);
  };

  if (addonQuantity < 1) {
    setAddonQuantity(1);
  }
  if (addonMonths < 1) {
    setAddonMonths(1);
  }

  const handlePull = (e) => {
    e.preventDefault();

    const selectedPlan = addons?.filter(
      (adP) => adP.name === selectedAddonPlan
    );

    const addonList = {
      selectedAddonPlan: selectedPlan,
      addonMonths,
      addonQuantity,
      monthlyRecurring,
      id: existingData ? existingData?.selectedId : "",
      addonId: uuidv4(),
    };

    dataPull(addonList);
  };

  const getSelectedAddon = (data) => {
    setSelectedAddonPlan(data);
  };

  return (
    <div>
      <div className="w-full max-w-[420px] rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
        <div className="flex items-center justify-end">
          <div className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
            <IoClose
              onClick={async () => {
                closer();
              }}
              className="h-6 w-6 cursor-pointer text-gray-600"
            />
          </div>
        </div>
        <div className="">
          <div className="block text-lg font-bold text-gray-600">
            {!edit ? "Select Add-ons" : "Edit Add-ons"}
          </div>
        </div>
        <div className="relative mt-6">
          <AddonListboxOne
            addonList={addons}
            selectedAddon={getSelectedAddon}
            existingAddon={existingData?.addonName}
            existingRecurring={existingData?.isRecurring}
          />
        </div>
        <div className="mt-4">
          <div className="mt-24 block text-xs font-semibold text-gray-600 xsm:text-sm">
            How many units of{" "}
            <span className="text-[#1525F0]">{selectedAddonPlan}</span> do you
            want?
          </div>
          <div className="mt-1 flex justify-start">
            <div className="flex items-center space-x-8 rounded-md border-[0.5px] border-gray-400 bg-gray-50 px-3 py-1 font-semibold text-gray-500 shadow-sm">
              <div
                onClick={() => setAddonQuantity((prev) => prev - 1)}
                className="cursor-pointer rounded-full p-1 hover:bg-gray-200"
              >
                <AiOutlineMinus className="h-5 w-5" />
              </div>
              <div className="w-8 text-center font-bold text-gray-700">
                {addonQuantity}
              </div>
              <div
                onClick={() => setAddonQuantity((prev) => prev + 1)}
                className="cursor-pointer rounded-full p-1 hover:bg-gray-200"
              >
                <AiOutlinePlus className="h-5 w-5" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label
            htmlFor="monthlyRecuring"
            className="text-xs font-semibold text-gray-600 xsm:text-sm"
          >
            Do you want your add-on to recur monthly?
          </label>
          <div className="relative mt-1 flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <select
                onChange={handleMonthlyRecurring}
                type="text"
                className="block w-full cursor-pointer rounded-md border-gray-300 bg-gray-50 pl-4 pr-10 text-gray-500 shadow-sm focus:border-olivine-500 focus:ring-olivine-500"
              >
                <option key={1} value="">
                  select
                </option>
                <option key={2} value="yes">
                  Yes
                </option>
                <option key={3} value="no">
                  No
                </option>
              </select>
            </div>
          </div>
        </div>
        {monthlyRecurring === "yes" ? (
          <div className="mt-4">
            <div className="mt-6 block text-sm font-semibold text-gray-600">
              Number of months
            </div>
            <div className="mt-1 flex justify-start">
              <div className="flex items-center space-x-8 rounded-md border-[0.5px] border-gray-400 bg-gray-50 px-3 py-1 font-semibold text-gray-500 shadow-sm">
                <div
                  onClick={() => setAddonMonths((prev) => prev - 1)}
                  className="cursor-pointer rounded-full p-1 hover:bg-gray-200"
                >
                  <AiOutlineMinus className="h-5 w-5" />
                </div>
                <div className="w-8 text-center font-bold text-gray-700">
                  {addonMonths}
                </div>
                <div
                  onClick={() => setAddonMonths((prev) => prev + 1)}
                  className="cursor-pointer rounded-full p-1 hover:bg-gray-200"
                >
                  <AiOutlinePlus className="h-5 w-5" />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="mt-6 pb-4">
          <button
            onClick={async (e) => {
              handlePull(e);
              closer();
            }}
            className="block w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-olivine-500 p-8 px-8 py-2.5 text-sm font-semibold text-white shadow transition duration-300 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
          >
            {!edit ? "Add add-ons" : "Update add-ons"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddonPopover;
