import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import TotalVoucherIcon from "../../../assets/totalVoucherIcon.svg";
import UnassignedVoucherIcon from "../../../assets/unassignedVouchersIcon.svg";
import AssignedVoucherIcon from "../../../assets/assignedVouchersIcon.svg";
import RedeemedVoucherIcon from "../../../assets/redeemedVouchersIcon.svg";
import UnredeemedVoucherIcon from "../../../assets/unredeemedVouchersIcon.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import moment from "moment";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../../services/authService";
import { voucherBulkGet } from "../../../store/actions";
import { useNavigate } from "react-router-dom";

function VoucherDetailsMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedVoucher } = useSelector((state) => state.voucher);
  const customerId = authService.getCurrentUserCustomerId();

  const [fetchedBulk, setFetchedBulk] = useState();
  const [unassignedVouchers, setUnassignedVouchers] = useState(0);
  const [assignedVouchers, setAssignedVouchers] = useState(0);
  const [redeemedVouchers, setRedeemedVouchers] = useState(0);
  const [unRedeemedVouchers, setUnRedeemedVouchers] = useState(0);

  useEffect(() => {
    if (!fetchedBulk) {
      handleBulkFetch();
    }

    if (fetchedBulk?.voucher_info?.length) {
      const filteredUnassigned = fetchedBulk?.voucher_info.filter(
        (voucher) => voucher?.voucher_status_id === 3
      );
      setUnassignedVouchers(filteredUnassigned?.length);

      const filteredAssigned = fetchedBulk?.voucher_info.filter(
        (voucher) => voucher?.voucher_status_id === 1
      );
      setAssignedVouchers(filteredAssigned?.length);

      const filteredRedeemed = fetchedBulk?.voucher_info.filter(
        (voucher) => voucher?.voucher_status_id === 2
      );
      setRedeemedVouchers(filteredRedeemed?.length);

      const filteredUnRedeemed = fetchedBulk?.voucher_info.filter(
        (voucher) => voucher?.voucher_status_id != 2
      );
      setUnRedeemedVouchers(filteredUnRedeemed?.length);
    }
  }, [fetchedBulk]);

  const handleBulkFetch = async () => {
    try {
      const payload = {
        customer_id: customerId.toString(),
      };

      const data = {
        ref: selectedVoucher?.corporate_ref_no,
        payload,
      };

      await dispatch(voucherBulkGet(data))
        .unwrap()
        .then((res) => {
          setFetchedBulk(res);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="w-full bg-white pb-40 text-xs xsm:text-sm md:text-base">
      <div className="mx-auto max-w-4xl space-y-4 rounded-lg px-4 shadow-lg shadow-gray-200 ">
        <div className="mt-10 flex items-center space-x-2">
          <div className="sm:w-36 md:w-64">
            <div className="h-fit w-fit rounded-full  p-1 hover:bg-gray-200">
              <BiArrowBack
                onClick={() => navigate(-1)}
                className="h-6 w-6 cursor-pointer text-gray-600"
              />
            </div>
          </div>
          <h1 className="text-lg font-bold text-gravel-600 sm:text-2xl">
            Voucher Details
          </h1>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Voucher Type:
          </p>
          <p>Bulk Purchase</p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Issue Date:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.voucher_date
              ? moment(selectedVoucher?.voucher_date).format("LLL")
              : null}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Sender Name:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.customer_full_name
              ? selectedVoucher?.customer_full_name
              : null}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Voucher No:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.voucher_quantity
              ? selectedVoucher?.voucher_quantity
              : null}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Amount:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.voucher_value
              ? `₦ ${Number(selectedVoucher?.voucher_value).toLocaleString()}`
              : null}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Total Vouchers Created
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.total_voucher_amount
              ? `₦ ${Number(
                  selectedVoucher?.total_voucher_amount
                ).toLocaleString()}`
              : null}
          </p>
        </div>
        <div>
          <div
            onClick={() => navigate("/voucher/voucher-details-info")}
            className="cursor-pointer border-b-[1px] border-b-gray-200 hover:bg-gray-50"
          >
            <div className="flex max-w-3xl flex-row items-center justify-between py-5">
              <div className="flex flex-row items-center space-x-2">
                <img src={TotalVoucherIcon} alt="icon" className="h-7 w-7" />
                <div className="text-xs ">
                  <p className="font-semibold text-gray-600">
                    Total Voucher Created
                  </p>
                  <p className="text-gray-600">
                    {fetchedBulk && fetchedBulk?.voucher_info
                      ? fetchedBulk?.voucher_info?.length
                      : null}
                  </p>
                </div>
              </div>
              <MdKeyboardArrowRight className="h-6 w-6 text-gray-700" />
            </div>
          </div>
          <div
            onClick={() => navigate("/voucher/voucher-details-info")}
            className="cursor-pointer border-b-[1px] border-b-gray-200 hover:bg-gray-50"
          >
            <div className="flex max-w-3xl flex-row items-center justify-between py-5">
              <div className="flex flex-row items-center space-x-2">
                <img
                  src={UnassignedVoucherIcon}
                  alt="icon"
                  className="h-7 w-7"
                />
                <div className="text-xs ">
                  <p className="font-semibold text-gray-600">
                    Unassigned Vouchers
                  </p>
                  <p className="text-gray-600">{unassignedVouchers}</p>
                </div>
              </div>
              <MdKeyboardArrowRight className="h-6 w-6 text-gray-700" />
            </div>
          </div>
          <div
            onClick={() => navigate("/voucher/voucher-details-info")}
            className="cursor-pointer border-b-[1px] border-b-gray-200 hover:bg-gray-50"
          >
            <div className="flex max-w-3xl flex-row items-center justify-between py-5">
              <div className="flex flex-row items-center space-x-2">
                <img src={AssignedVoucherIcon} alt="icon" className="h-7 w-7" />
                <div className="text-xs ">
                  <p className="font-semibold text-gray-600">
                    Assigned Vouchers
                  </p>
                  <p className="text-gray-600">{assignedVouchers}</p>
                </div>
              </div>
              <MdKeyboardArrowRight className="h-6 w-6 text-gray-700" />
            </div>
          </div>
          <div
            onClick={() => navigate("/voucher/voucher-details-info")}
            className="cursor-pointer border-b-[1px] border-b-gray-200 hover:bg-gray-50"
          >
            <div className="flex max-w-3xl flex-row items-center justify-between py-5">
              <div className="flex flex-row items-center space-x-2">
                <img src={RedeemedVoucherIcon} alt="icon" className="h-7 w-7" />
                <div className="text-xs ">
                  <p className="font-semibold text-gray-600">
                    Redeemed Vouchers
                  </p>
                  <p className="text-gray-600">{redeemedVouchers}</p>
                </div>
              </div>
              <MdKeyboardArrowRight className="h-6 w-6 text-gray-700" />
            </div>
          </div>
          <div
            onClick={() => navigate("/voucher/voucher-details-info")}
            className="cursor-pointer border-b-[1px] border-b-gray-200 hover:bg-gray-50"
          >
            <div className="flex max-w-3xl flex-row items-center justify-between py-5">
              <div className="flex flex-row items-center space-x-2">
                <img
                  src={UnredeemedVoucherIcon}
                  alt="icon"
                  className="h-7 w-7"
                />
                <div className="text-xs ">
                  <p className="font-semibold text-gray-600">
                    Open/Unredeemed Vouchers
                  </p>
                  <p className="text-gray-600">{unRedeemedVouchers}</p>
                </div>
              </div>
              <MdKeyboardArrowRight className="h-6 w-6 text-gray-700" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoucherDetailsMain;
