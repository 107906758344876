
import grain from "../../../assets/grain.svg"
import './creatBasket.css'


function GrainBasketComponet() {
    return ( <div>
        
        <section>
                <section className="grid gap-2 my-10 grid-cols-4 lg:grid-cols-9 ">
                    <div className=" mt-2 ">
                        <div className="flex box py-5 px-10  justify-center" >
                            <div className="pt-2 ">
                                <img src={grain} className="object-fit ml-3" />
                                <span className="grainText">Grains</span>
                            </div>
                        </div>


                    </div>
                    <div className=" mt-2 ">
                        <div className="flex box py-5 px-10  justify-center" >
                            <div className="pt-2 ">
                                <img src={grain} className="object-fit ml-3" />
                                <span className="grainText">Grains</span>
                            </div>
                        </div>


                    </div>
                    <div className=" mt-2 ">
                        <div className="flex box py-5 px-10  justify-center" >
                            <div className="pt-2 ">
                                <img src={grain} className="object-fit ml-3" />
                                <span className="grainText">Grains</span>
                            </div>
                        </div>


                    </div>
                    <div className=" mt-2 ">
                        <div className="flex box py-5 px-10  justify-center" >
                            <div className="pt-2 ">
                                <img src={grain} className="object-fit ml-3" />
                                <span className="grainText">Grains</span>
                            </div>
                        </div>


                    </div>
                    <div className=" mt-2 ">
                        <div className="flex box py-5 px-10  justify-center" >
                            <div className="pt-2 ">
                                <img src={grain} className="object-fit ml-3" />
                                <span className="grainText">Grains</span>
                            </div>
                        </div>


                    </div>
                    <div className=" mt-2 ">
                        <div className="flex box py-5 px-10  justify-center" >
                            <div className="pt-2 ">
                                <img src={grain} className="object-fit ml-3" />
                                <span className="grainText">Grains</span>
                            </div>
                        </div>


                    </div>
                    <div className=" mt-2 ">
                        <div className="flex box py-5 px-10  justify-center" >
                            <div className="pt-2 ">
                                <img src={grain} className="object-fit ml-3" />
                                <span className="grainText">Grains</span>
                            </div>
                        </div>


                    </div>
                    <div className=" mt-2 ">
                        <div className="flex box py-5 px-10  justify-center" >
                            <div className="pt-2 ">
                                <img src={grain} className="object-fit ml-3" />
                                <span className="grainText">Grains</span>
                            </div>
                        </div>


                    </div>
                    <div className=" mt-2 ">
                        <div className="flex box py-5 px-10  justify-center" >
                            <div className="pt-2 ">
                                <img src={grain} className="object-fit ml-3" />
                                <span className="grainText">Grains</span>
                            </div>
                        </div>


                    </div>


                </section>

            </section>
    </div> );
}

export default GrainBasketComponet;

