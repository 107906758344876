import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authService } from "../../../services/authService";
import toast from "react-hot-toast";
import { setSelectedBulkVoucher } from "../../../store/slices/modalSlice";
import { voucherAssign, voucherBulkGet } from "../../../store/actions";

function AssignVoucher({
  selectedBulkVoucher,
  setOpen,
  setReAssign,
  isReassign,
  unAssignedVouchers,
  assignedVouchers,
}) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const customerId = authService.getCurrentUserCustomerId();

  const [formValues, setFormValues] = useState({
    reciepient_name:
      selectedBulkVoucher && selectedBulkVoucher.reciepient_name
        ? selectedBulkVoucher.reciepient_name
        : "",
    reciepient_email:
      selectedBulkVoucher && selectedBulkVoucher.reciepient_email_address
        ? selectedBulkVoucher.reciepient_email_address
        : "",
    reciepient_mobile:
      selectedBulkVoucher && selectedBulkVoucher.reciepient_mobile_number
        ? selectedBulkVoucher.reciepient_mobile_number
        : "",
  });

  const handleChange = (event) => {
    try {
      const name = event.target.name;
      const value = event.target.value;
      setFormValues((values) => ({ ...values, [name]: value }));
    } catch (err) {
      return err;
    }
  };

  const handleAssign = () => {
    try {
      if (!formValues.reciepient_mobile || !formValues.reciepient_name) {
        return toast.error("Please fill required fields");
      }

      const toastId = toast.loading("Assigning voucher");

      const payload = {
        reciepient_name: formValues.reciepient_name,
        reciepient_mobile: formValues.reciepient_mobile,
        reciepient_email: formValues.reciepient_email,
      };

      const data = {
        ref: selectedBulkVoucher?.ref_no,
        payload,
      };

      dispatch(voucherAssign(data))
        .unwrap()
        .then((res) => {
          if (res.status === 403) {
            return toast.error(res.response.error, {
              id: toastId,
            });
          }

          if (res.result_status[0].message_id !== 1) {
            return toast.error(res.result_status[0].message_text, {
              id: toastId,
            });
          }

          toast.success(res.result_status[0].message_text, {
            id: toastId,
          });

          const payload = {
            customer_id: customerId.toString(),
          };

          const data = {
            ref: selectedBulkVoucher?.corporate_ref_no,
            payload,
          };

          dispatch(voucherBulkGet(data))
            .unwrap()
            .then(() => {
              setOpen(false);
              setReAssign(false);
              dispatch(setSelectedBulkVoucher(selectedBulkVoucher));
              navigate("/voucher/assignVoucherBulkSuccess");
            })
            .catch((err) => err);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };
  return (
    <div className="px-2">
      {" "}
      <div className="flex max-w-lg flex-row items-center justify-between bg-[#FEFEFD]">
        <h2 className="text-lg font-semibold text-gray-700">
          {isReassign ? "Reassign Gift Voucher" : "Assign Gift Voucher"}
        </h2>
        <div className="cursor-pointer rounded-full bg-olivine-500 p-1 text-white active:bg-olivine-400">
          <IoClose onClick={() => setOpen(false)} className="h-5 w-5" />
        </div>
      </div>
      <div className="ml-1 mt-8 text-[10px] text-gray-400">
        <p>
          Assigned Vouchers{" "}
          <span className="ml-2 text-sm font-semibold text-olivine-600">
            {assignedVouchers && assignedVouchers}
          </span>
        </p>
        <p>
          Unassigned Vouchers{" "}
          <span className="ml-2 text-sm font-semibold text-olivine-600">
            {unAssignedVouchers && unAssignedVouchers}
          </span>
        </p>
      </div>
      <div className="mt-6 rounded-lg border-[0.5px] border-gray-200 bg-white px-4 py-2 shadow-md">
        <form className="pb-6">
          <div className="">
            <label
              htmlFor="name"
              className="ml-1 text-[11px] font-medium text-gray-600"
            >
              Recipient’s Name*
            </label>
            <div className="relative flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <input
                  type="text"
                  name="reciepient_name"
                  placeholder=""
                  value={formValues.reciepient_name}
                  onChange={handleChange}
                  className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-xs placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>
          <div className="mt-1">
            <label
              htmlFor="number"
              className="ml-1 text-[11px] font-medium text-gray-600"
            >
              Recipient’s Phone number
            </label>
            <div className="relative flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <input
                  type="text"
                  placeholder=""
                  name="reciepient_mobile"
                  value={formValues.reciepient_mobile}
                  onChange={handleChange}
                  className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-xs placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>
          <div className="mt-1">
            <label
              htmlFor="email"
              className="ml-1 text-[11px] font-medium text-gray-600"
            >
              Recipient’s Email Address
            </label>
            <div className="relative flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <input
                  type="email"
                  placeholder=""
                  name="reciepient_email"
                  value={formValues.reciepient_email}
                  onChange={handleChange}
                  className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-xs placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* <div className="mt-8 space-y-4 sm:flex sm:flex-row sm:items-center sm:justify-between sm:space-x-6 sm:space-y-0">
        <div>
          <button onClick={() => handleAssign()} className="w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 sm:px-10">
            Submit
          </button>
        </div>
        <div>
          <button className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 py-[15px] text-xs font-semibold text-olivine-500 transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 xsm:text-sm sm:w-auto sm:px-8 sm:py-[11px]">
            Submit and Assign More
          </button>
        </div>
      </div> */}
      <div className="mt-8">
        <button
          onClick={() => handleAssign()}
          className="w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 sm:px-10"
        >
          Proceed
        </button>
      </div>
    </div>
  );
}

export default AssignVoucher;
