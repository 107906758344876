import React from "react";
import { BiArrowBack } from "react-icons/bi";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import SuccessVoucherCardImage from "../../../assets/successVoucherCardImage.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { voucherShare } from "../../../store/actions";
import toast from "react-hot-toast";

function AssignAndShareBulkSuccess() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { createVoucher } = useSelector((state) => state.voucher);
  const { selectedBulkVoucher } = useSelector((state) => state.modal);

  const handleVoucherShare = () => {
    try {
      if (selectedBulkVoucher) {
        const toastId = toast.loading("sharing voucher");
        const ref = selectedBulkVoucher?.ref_no;
        dispatch(voucherShare(ref))
          .unwrap()
          .then((res) => {
            if (res?.length) {
              navigate(-1);
              return toast.success(
                `Voucher: ${res[0]?.voucher_number} has been shared with ${res[0]?.reciepient_email_address}`,
                { id: toastId }
              );
            }
            return toast.error("something went wrong", { id: toastId });
          })
          .catch((err) => err);
      }
    } catch (err) {
      return err;
    }
  };

  return (
    <div>
      <SignedInNavbarImproved />
      <div className="mx-auto mt-10 max-w-[1600px] px-3 pb-40 xsm:px-6 sm:px-8 md:px-12 lg:px-14">
        <div className="mt-4 flex items-center space-x-2 sm:space-x-4">
          <div
            onClick={() => navigate(-1)}
            className="rounded-full p-1 hover:bg-gray-200"
          >
            <BiArrowBack className="h-6 w-6 cursor-pointer text-gray-600" />
          </div>
          <h1 className="text-lg font-bold text-gray-700 sm:text-2xl">
            Voucher History
          </h1>
        </div>
        <div className="mt-10 flex flex-col items-center justify-center text-center">
          <div className="rounded-md border-[0.5px] border-gray-200 px-20 py-10 shadow-lg">
            <div className="flex justify-center">
              <img
                src={SuccessVoucherCardImage}
                alt="success-img"
                className="h-20 w-auto"
              />
            </div>
            <p className="mt-2 text-center text-sm text-gray-500">
              Gift Voucher Successfully Created
            </p>
            <p className="mt-2 text-center text-sm text-gray-500">
              {createVoucher && createVoucher?.voucher?.length
                ? createVoucher?.voucher[0]?.voucher_number
                : null}
            </p>
          </div>
          <h2 className="mt-6 text-xl font-bold text-gray-700">
            Share Gift Voucher
          </h2>
          <p className="max-w-lg text-sm text-gray-600">
            When you share this voucher to your friend, the voucher will be
            redeemed by that individual
          </p>
          <div className="mt-6">
            <button
              onClick={(e) => {
                e.preventDefault();
                handleVoucherShare();
              }}
              className="transform cursor-pointer rounded-md bg-olivine-500 px-16 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
            >
              Share Gift Voucher
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignAndShareBulkSuccess;
