import React, { useContext, useEffect, useState } from "react";
import "./creatBasket.css";
import CreateBasketCard from "./CreateBasketCard";
import { useNavigate } from "react-router-dom";
import RxjsStoreContext from "../../../context/RxjsStoreContext";

function AllItems(props) {
  const { allProductList, currentSection } = props;
  const rxjsStore = useContext(RxjsStoreContext);
  let navigate = useNavigate();


  const [randomList, setRandomList] = useState([]);

  useEffect(() => {
    let newList = allProductList.slice(0, 8);
    setRandomList(newList);
  }, [allProductList]);

  const seeAllItems = () => {
    rxjsStore.setSectionList(allProductList);
    navigate(`/basket/create-basket/section`);
  };

  const processTopList = () => {
    let newList = allProductList.slice(0, 4);
    setRandomList(newList);
  };

  return (
    <section className="mt-4">
      <div className="flex items-center justify-between">
        <div className="">
          <div className="flex">
            <div className="text-lg font-bold text-gray-600 ">All items</div>
          </div>
        </div>
        <div className="">
          <div
            className="group cursor-pointer font-semibold text-olivine-500"
            onClick={() => seeAllItems()}
          >
            <div> See All {allProductList?.length} items </div>
            <hr
              // style={{ backgroundColor: "#73964C" }}
              className="-mt-1 h-[2px] bg-olivine-500 opacity-0 group-hover:opacity-100"
            />
          </div>
        </div>
      </div>

      <div className="mt-6 grid gap-6 grid-auto-fit-sm">
        {randomList?.map((product, index) => {
          return <CreateBasketCard product={product} key={index} />;
        })}
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  );
}

export default AllItems;
