import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function VoucherDetails() {
  const { selectedVoucher } = useSelector((state) => state.voucher);
  const navigate = useNavigate();

  return (
    <div className="w-full bg-white pb-40 text-xs xsm:text-sm md:text-base">
      <div className="mx-auto max-w-4xl space-y-4 px-4">
        <div className="mt-10 flex items-center space-x-2">
          <div className="sm:w-36 md:w-64">
            <div
              onClick={() => navigate(-1)}
              className="h-fit w-fit rounded-full  p-1 hover:bg-gray-200"
            >
              <BiArrowBack className="h-6 w-6 cursor-pointer text-gray-600" />
            </div>
          </div>
          <h1 className="text-lg font-bold text-gravel-600 sm:text-2xl">
            Voucher Details
          </h1>
        </div>
        <div>
          <div className="mt-8 flex items-center space-x-2 md:mt-16">
            <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
              Voucher Type:
            </p>
            <p>
              {selectedVoucher && selectedVoucher?.voucher_status_name
                ? selectedVoucher?.voucher_status_name
                : null}
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Issue Date:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.transaction_date
              ? moment(selectedVoucher?.transaction_date).format("LLL")
              : null}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Sender Name:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.customer_full_name
              ? selectedVoucher?.customer_full_name
              : null}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Amount:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.voucher_amount
              ? `₦ ${Number(selectedVoucher?.voucher_amount).toLocaleString()}`
              : null}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Recipient Name:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.reciepient_name
              ? selectedVoucher?.reciepient_name
              : null}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Recipient Mobile Number:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.reciepient_mobile_number
              ? selectedVoucher?.reciepient_mobile_number
              : null}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Redeem Date:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.redeem_date
              ? moment(selectedVoucher?.redeem_date).format("LLL")
              : null}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Redeem Status:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.redeem_zoho_send_status
              ? selectedVoucher?.redeem_zoho_send_status
              : null}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
            Recipient Email:
          </p>
          <p>
            {selectedVoucher && selectedVoucher?.reciepient_email_address
              ? selectedVoucher?.reciepient_email_address
              : null}
          </p>
        </div>
        <div className="space-y-6">
          <div className="max-w-lg cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F0F7E1] py-3 text-center transition duration-300 hover:ring-[0.5px] hover:ring-olivine-500">
            <div
              onClick={() => {
                navigator.clipboard.writeText(
                  // fectcheUserProfile?.customer_info[0]
                  //   ?.providus_bank_account_number
                  selectedVoucher?.voucher_number
                );
                toast.success("Copied to clipboard!");
              }}
              className="text-olivine-500"
            >
              <div>Copy Voucher Code</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoucherDetails;
