import React, { useEffect, useRef } from "react";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import backgroundImage from "../../../assets/basket-background-image.jpg";
import { BiArrowBack } from "react-icons/bi";
import { TbBrandTwitter } from "react-icons/tb";
import { RiFacebookBoxLine, RiInstagramLine } from "react-icons/ri";

function RewardBank() {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div>
      <SignedInNavbarImproved />
      <div className="h-[500px] w-full md:grid md:h-screen md:grid-cols-4">
        <div className="relative md:col-span-3 md:h-screen">
          <div className="h-[500px] w-full md:h-screen">
            <img
              src={backgroundImage}
              alt=""
              className="z-10 aspect-auto h-full w-full object-cover"
            />
          </div>
          <div className="absolute inset-0 z-20 h-[500px] w-full bg-black/60 md:h-auto"></div>
          <div className="absolute inset-x-0 inset-y-28 z-30 h-fit w-full text-white md:inset-y-0">
            <div className="flex flex-col md:h-screen md:max-h-screen md:justify-between">
              <div className="px-2 xsm:px-4 sm:px-8 md:mt-16 lg:mt-28">
                <div className="mx-auto max-w-xl">
                  <div className="flex items-center space-x-2">
                    <div className="h-[2px] w-6 bg-olivine-500"></div>
                    <div className="text-base font-semibold tracking-wider text-olivine-500">
                      Coming soon!
                    </div>
                  </div>
                  <div className="mt-6 pl-2 text-3xl font-bold tracking-wide text-[#F6FBEE] sm:text-4xl">
                    Grocedy Reward Bank
                  </div>
                  <div className="mt-6 pl-2 text-xs font-semibold tracking-widest text-[#F6FBEE]">
                    Get Notified when we launch this feature
                  </div>
                  <div className="mt-2 flex w-full max-w-lg text-xs xsm:text-sm sm:text-base">
                    <input
                      ref={inputRef}
                      type="email"
                      placeholder="Input email Address"
                      className="block flex-1 rounded-l border-gray-500 bg-[#F6FBEE] pl-4 text-base  text-gray-600 placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500 sm:rounded"
                    />
                    <button className="rounded-r bg-olivine-500 px-2 py-2 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 xsm:px-4 xsm:text-sm sm:ml-2 sm:w-auto sm:rounded sm:px-8 sm:py-3">
                      <span className="hidden sm:inline">Notify Me</span>
                      <span className="sm:hidden">
                        <BiArrowBack className="h-6 w-6 rotate-180 transform text-[#F6FBEE]" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-16 max-w-[870px] bg-olivine-500 px-4 pb-8 pt-8 text-center text-xs text-[#F6FBEE] xsm:text-sm sm:px-6 md:mt-0 md:px-8 md:pb-20">
                {" "}
                We are excited to announce that our rewards center is coming
                soon! This is no ordinary reward scheme but will be packed with
                so many ways to earn rewards for using Grocedy. The rewards you
                earn from consistently paying into your Grocedy Wallet, bill
                payments, airtime top-up, monthly subscriptions, referrals etc
                can be redeemed for discounts on your food purchases, giving you
                even more savings and value. We will provide more information on
                how to earn and redeem your rewards in your reward bank soon.
                You can track and redeem your rewards from here when it is
                launched. Just another way that Grocedy cares.
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-black/80 text-white md:col-span-1 md:flex md:h-screen md:max-h-screen md:flex-col md:justify-end md:pb-32 lg:pb-28 ">
          <div className="pt-60 text-center font-semibold text-[#F6FBEE] xsm:pt-40">
            Follow us on our socials
          </div>
          <div className="flex items-center justify-center space-x-6 pb-16 pt-3 md:pb-0">
            <button className="">
              <TbBrandTwitter className="h-7 w-7 text-olivine-500 hover:text-olivine-400" />
            </button>
            <button>
              <RiFacebookBoxLine className="h-7 w-7 text-olivine-500 hover:text-olivine-400" />
            </button>
            <button>
              <RiInstagramLine className="h-7 w-7 text-olivine-500 hover:text-olivine-400" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RewardBank;
