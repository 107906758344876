import React from "react";

function UpdatePhoneNumber({ setOpen, handlePhoneVerification }) {
  return (
    <div className="max-w-lg bg-white p-4">
      <div className="flex flex-col items-center justify-center text-center">
        <h2 className=" max-w-xs text-center text-sm font-bold text-gray-600">
          Update Phone number
        </h2>
        <p className="mt-1 max-w-xs text-center text-xs text-gray-400">
          {
            "Please verify your mobile number to purchase by going to Web Application [Profile -> To-Do List] or Mobile App [Settings->To-do list->Tier 1]"
          }
        </p>
      </div>
      <div className="mx-auto mt-6 max-w-sm">
        <button
          onClick={() => {
            handlePhoneVerification();
            setOpen(false);
          }}
          className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-16 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
        >
          Verify Phone Number
        </button>
        <div className="mt-2 text-center">
          <button
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
            className="text-sm font-medium text-gray-400 hover:underline hover:underline-offset-2"
          >
            Maybe Later
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdatePhoneNumber;
