import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addBvn,
  bvnVerification,
  getBvn,
  todoListStatus,
} from "../../store/actions";
import toast from "react-hot-toast";
import { init_data } from "../../core/helpers";
import { authService } from "../../services/authService";

const BVN = ({ onClose }) => {
  const { bvnGet } = useSelector((state) => state.masterData);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [bvnInvalid, setBvnInvalid] = useState(false);
  const [valid, setValid] = useState(false);
  const [input, setInput] = useState(
    bvnGet && bvnGet?.bank_verification_number
      ? bvnGet?.bank_verification_number
      : ""
  );

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const user_phone = authService.getCurrentUserPhone();

  useEffect(() => {
    if (!bvnGet) {
      dispatch(getBvn());
    }
  }, [bvnGet]);

  useEffect(() => {
    if (input && input?.length === 11) {
      const toastId = toast.loading("Verifying your bvn. Please wait..");
      const data = {
        bvn: input,
        phone: user_phone,
      };

      dispatch(bvnVerification(data))
        .unwrap()
        .then((res) => {
          if (!res.requestSuccessful) {
            setBvnInvalid(true);
            setValid(false);
            dispatch(todoListStatus(init_data));
            return toast.error("BVN InValid", {
              id: toastId,
            });
          }
          setValid(true);
          setBvnInvalid(false);
          return toast.success("BVN Valid", {
            id: toastId,
          });
        })
        .catch((err) => {
          if (err) {
            setValid(false);
          }
        });
    }
  }, [input, dispatch, user_phone]);

  const handleOnClose = (e) => {
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!valid) return;

    try {
      const toastId = toast.loading("Submitting your BVN...");

      const data = {
        sql_ref_no: "10",
        user_id: userId?.toString(),
        verification_code,
        bvn_number: input,
        option: "9",
      };

      dispatch(addBvn(data))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            return toast.error("BVN could not be submitted!", {
              id: toastId,
            });
          }
          setInput("");
          dispatch(getBvn());
          toast.success(res?.message_text, {
            id: toastId,
          });
          dispatch(todoListStatus(init_data));
          window.location.reload();
          onClose();
        })
        .catch((err) => {
          setInput("");
          toast.error("BVN could not be submitted!", {
            id: toastId,
          });

          navigate("/login");

          return err;
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="mx-auto h-full w-full max-w-[420px] px-2 pb-16 pt-64 xsm:px-3">
      <div className="rounded-2xl bg-white px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <div className="font-semibold text-gray-700 xsm:text-lg sm:text-xl">
            Add Your BVN
          </div>
          <button
            className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
            onClick={handleOnClose}
          >
            <CgClose className="h-5 w-5 text-gravel-500" />
          </button>
        </div>
        <div className="mt-2 text-xs">
          Please enter your Bank Verification Number to start using Grocedy
        </div>
        <div>
          <form>
            <div className="mt-2">
              <label
                htmlFor="bankVerificationNumber"
                className="ml-3 text-xs text-gray-500"
              >
                Bank Verification Number
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    value={input}
                    onChange={(e) => {
                      const limit = 11;

                      // 👇️ only take first N characters
                      setInput(e.target.value.slice(0, limit));
                    }}
                    type="number"
                    maxLength="11"
                    placeholder="Enter your BVN"
                    className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                </div>
              </div>
              {bvnInvalid ? (
                <div className="mt-3 text-xs text-red-400">
                  Your BVN may not be linked to your registered Phone Number...
                </div>
              ) : null}
            </div>
          </form>
          <div className="mt-6 flex justify-between text-center tracking-widest">
            <button
              onClick={handleOnClose}
              className="rounded-md border-[1px] border-[#CE8172]  px-6 py-2 text-sm text-[#CE8172] transition duration-300 hover:bg-[#f5f0ef] active:ring-1 active:ring-[#CE8172] sm:px-8"
            >
              CANCEL
            </button>
            <button
              onClick={handleSubmit}
              disabled={!valid}
              className="rounded-md bg-olivine-500 px-8 py-2 text-sm text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BVN;
