import React, { useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlineInfo } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";
import SignedInNavbarImproved from "./SignedInNavbarImproved";
import Modal from "./Ui";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {
  createAccountNumber,
  documentUploadGet,
  getDelivery,
  getNin,
  getUserInfo,
  getWalletBalance,
  todoListStatus,
} from "../store/actions";

function HomeTodo() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [fetchedStatuses, setfetchedStatuses] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const [statusPercent, setStatusPercent] = useState("");
  const [fetchedUserBalance, setFetchedUserBalance] = useState();
  const [fectcheUserProfile, setFectcheUserProfile] = useState({});
  // const [isComplete, setIsComplete] = useState(false);
  const {
    todoListStatusData,
    userInfo,
    getDeliveryItems,
    nin,
    uploadedDocuments,
  } = useSelector((state) => state.masterData);

  const { isPastLogin, isCorporate } = useSelector((state) => state.modal);
  const { walletBalance } = useSelector((state) => state.user);

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();
  const token = authService.getCurrentAuthToken();
  const corporateId = authService.getCustomerCorporateId();

  const akant =
    fectcheUserProfile &&
    fectcheUserProfile?.customer_info?.length &&
    fectcheUserProfile?.customer_info[0]?.providus_bank_account_number;

  useEffect(() => {
    if (!akant && fectcheUserProfile?.customer_info?.length) {
      handleCreate();
    }
  }, [akant, fectcheUserProfile?.customer_info?.length]);

  useEffect(() => {
    if (isCorporate) {
      handleRedirect("addId");
    }
  }, [isCorporate]);

  useEffect(() => {
    if (
      fetchedStatuses?.user_profile === "True" &&
      fetchedStatuses?.phone_number === "True" &&
      fetchedStatuses?.transaction_pin === "True" &&
      fetchedStatuses?.delivery_address === "True" &&
      !isPastLogin
    ) {
      navigate("/home");
    }
  }, [fetchedStatuses, navigate]);

  useEffect(() => {
    if (!walletBalance) {
      const data = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };
      dispatch(getWalletBalance(data))
        .unwrap()
        .catch((err) => {
          if (err) {
            authService.logOut();
            return navigate("/login");
          }
        });
    }

    if (walletBalance) setFetchedUserBalance(walletBalance);
  }, [
    dispatch,
    userId,
    customerId,
    verification_code,
    walletBalance,
    navigate,
  ]);

  useEffect(() => {
    if (!todoListStatusData) {
      const data = {
        sql_ref_no: "20",
        user_id: userId?.toString(),
        verification_code: verification_code?.toString(),
        option: "1",
      };

      dispatch(todoListStatus(data));
    }

    if (todoListStatusData) setfetchedStatuses(todoListStatusData);
  }, [dispatch, todoListStatusData, verification_code, userId]);

  useEffect(() => {
    if (!uploadedDocuments) {
      const data = {
        sql_ref_no: "16",
        user_id: userId?.toString(),
        verification_code,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(documentUploadGet(data));
    }
  }, [dispatch, uploadedDocuments, verification_code, userId, customerId]);

  useEffect(() => {
    if (!nin) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "8",
      };

      dispatch(getNin(data));
    }
  }, [dispatch, customerId, userId, nin]);

  useEffect(() => {
    if (fetchedStatuses) {
      const results = [];
      for (let key in fetchedStatuses) {
        results.push(fetchedStatuses[key]);
      }

      // Return the number of times an array element appears in the array
      const getOccurrence = (array, value) => {
        return array?.filter((v) => v === value)?.length;
      };

      const getPercentage = (array, occ) => {
        return Math.round((occ / array?.length) * 100);
      };

      const yesOcc = getOccurrence(results, "True");

      const yesPercent = getPercentage(results, yesOcc);

      setStatusPercent(yesPercent);
    }
  }, [fetchedStatuses]);

  useEffect(() => {
    if (!userInfo) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "7",
      };

      dispatch(getUserInfo(data));
    }

    if (userInfo) setFectcheUserProfile(userInfo);
  }, [dispatch, userInfo, customerId, userId, verification_code]);

  useEffect(() => {
    if (!getDeliveryItems && token) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "6",
      };

      dispatch(getDelivery(data));
    }
  }, [getDeliveryItems, dispatch, userId, customerId, token]);

  function handleCreate() {
    const data = {
      sql_ref_no: "28",
      user_id: userId?.toString(),
      customer_id: customerId?.toString(),
      verification_code,
      account_name: `${fectcheUserProfile?.customer_info[0]?.customer_first_name} ${fectcheUserProfile?.customer_info[0]?.customer_last_name}`,
      // bvn: `${fetchedUser?.bank_verification_number}`,
      option: "1",
    };

    dispatch(createAccountNumber(data))
      .unwrap()
      .then((res) => {
        if (res.requestSuccessful === false) {
          return res.responseMessage;
        }

        if (res.message_id !== 1) {
          return res.message_text;
        }

        const data = {
          sql_ref_no: "11",
          user_id: userId?.toString(),
          customer_id: customerId?.toString(),
          option: "7",
        };

        dispatch(getUserInfo(data));
      })
      .catch((err) => {
        return err;
      });
  }

  function handleRedirect(location) {
    setShowModal(true);

    switch (location) {
      case "profile":
        setSelectedModal("profile");
        break;
      // return navigate("/login/addPhoneNumber");
      case "addDescription":
        setSelectedModal("addDescription");
        break;
      case "addBvn":
        setSelectedModal("addBvn");
        break;
      case "addNin":
        setSelectedModal("addNin");
        break;
      case "phone":
        setSelectedModal("phone");
        break;
      case "pin":
        setSelectedModal("pin");
        break;
      case "addId":
        setSelectedModal("addId");
        break;
      case "wallet":
        setSelectedModal("wallet");
        break;
      case "paymentOptions":
        setSelectedModal("paymentOptions");
        break;
      default:
        return;
    }
  }

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedModal("");
  };

  return (
    <div className="relative">
      {/* <div className="filter blur-sm bg-slate-200"> */}
      <SignedInNavbarImproved />
      <div className="px-2 py-16 tracking-wider xsm:px-3 sm:px-8 md:px-16 lg:px-28">
        <div
          onClick={() => navigate("/home")}
          className="flex items-center justify-end text-base font-medium text-olivine-600 hover:underline hover:underline-offset-4"
        >
          <button>Skip</button>
        </div>
        <div className="pl-2 text-lg font-semibold text-gravel-500 sm:text-2xl">
          To-Do List
        </div>
        <div className="mt-2 pl-2 text-xs font-normal text-gray-500 sm:text-sm">
          Complete your to-do to start using Grocedy
        </div>
        <div className="mx-auto mt-8 max-w-5xl rounded-md border-[1px] border-gray-400 px-2 pt-8 sm:px-4 md:px-8">
          <div className="text-base font-bold text-gray-600 sm:text-2xl">
            Tier 1 Complete
          </div>
          <div className="mt-4 space-y-2 pr-2 xsm:grid xsm:grid-cols-2 xsm:gap-6 xsm:space-y-0">
            <div className="flex items-center space-x-1">
              <div className="flex items-center space-x-1">
                <div className="flex h-5 w-5 items-center justify-center rounded-full bg-olivine-600 text-xs text-white">
                  1
                </div>
                <p className="text-sm text-gray-500">Tier 1</p>
              </div>
              <div className="h-[1px] w-full flex-1 bg-olivine-600"></div>
            </div>
            <div className="flex items-center space-x-1">
              <div className="flex items-center space-x-1">
                <div className="flex h-5 w-5 items-center justify-center rounded-full bg-[#d1d3cf] text-xs text-white">
                  2
                </div>
                <p className="text-sm text-gray-500">Tier 2</p>
              </div>
              <div className="h-[1px] w-full flex-1 bg-[#d1d3cf]"></div>
              <div className="text-sm text-gray-500">Completed</div>
            </div>
          </div>
          <div
            onClick={() => handleRedirect("profile")}
            className="mt-8 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div>
                <div>
                  Edit your profile details
                  <span className="ml-1 italic text-[#F5A893] sm:ml-2">
                    *Required
                  </span>
                </div>
              </div>
              {fetchedStatuses.user_profile === "True" ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center space-x-1 rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => handleRedirect("phone")}
            className="mt-4 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div>
                <div>
                  Add your phone number
                  <span className="ml-1 italic text-[#F5A893] sm:ml-2">
                    *Reqired
                  </span>
                </div>
              </div>
              {fetchedStatuses.phone_number === "True" ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center space-x-1 rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => handleRedirect("addDescription")}
            className="mt-4 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div>
                <div>
                  Add your delivery address
                  <span className="ml-1 italic text-[#F5A893] sm:ml-2">
                    *Reqired
                  </span>
                </div>
              </div>
              {fetchedStatuses.delivery_address === "True" ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="mr-1 h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => handleRedirect("pin")}
            className="mt-4 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div>
                <div>
                  Create your Grocedy transaction pin
                  <span className="ml-1 italic text-[#F5A893] sm:ml-2">
                    *Reqired
                  </span>
                </div>
              </div>
              {fetchedStatuses.transaction_pin === "True" ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="mr-1 h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>

          {corporateId === 3 ? (
            <div
              onClick={() => handleRedirect("addId")}
              className="mt-4 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:border-olivine-500"
            >
              <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
                <div className="items-center sm:flex">
                  <div>
                    Upload a Valid Identification Card
                    <span className="ml-1 italic text-[#F5A893] sm:ml-2">
                      *Reqired
                    </span>
                  </div>
                </div>
                {fetchedStatuses?.customer_corporate_id_upload_url_status ===
                "True" ? (
                  <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                    <div>
                      <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                    </div>
                    <div className="text-xs text-olivine-500">
                      <div className="text-xs text-olivine-500">Done</div>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                    <div className="mr-1 h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                    <div className="text-xs text-[#F79E1B]">Pending</div>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          <div
            onClick={() => handleRedirect("wallet")}
            className="mt-4 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:border-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div className="items-center sm:flex">
                <div>Fund your Wallet</div>
              </div>
              {fetchedUserBalance?.available_balance ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="mr-1 h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>

          <div className="my-8 text-center">
            <button
              onClick={() => navigate("/home")}
              className="rounded-md bg-olivine-500 px-8 py-3 text-sm font-semibold text-white transition-all duration-300 hover:-translate-y-0.5 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-2 active:bg-olivine-600 sm:px-16 sm:text-base"
            >
              GO TO YOUR HOMEPAGE
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>
      <Modal
        visible={showModal}
        onClose={handleOnClose}
        selected={selectedModal}
        handleRedirect={handleRedirect}
      />
    </div>
  );
}

export default HomeTodo;
