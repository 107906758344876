import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import SuccessImage from "../../../../assets/successsImage.svg";
import { useNavigate } from "react-router-dom";

const CowriesRedeemSeccessfullPopup = ({ closeModal }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex items-center justify-end">
        <div className="w-fit cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
          <IoCloseSharp
            onClick={() => closeModal(false)}
            className="h-6 w-6 text-gray-600"
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <img src={SuccessImage} alt="img" className="h-16 w-16" />
      </div>
      <div className="mt-4">
        <h2 className="mx-auto max-w-sm text-center text-lg font-bold text-gray-600 sm:text-2xl">
          Cowries Successfully Redeemed
        </h2>
        <p className="mx-auto mt-2 max-w-xs text-center text-sm font-medium text-gray-500">
          You have successfully redeemed your cowries to your wallet.
        </p>
        <div className="mx-auto mt-8 max-w-xs pb-10">
          <button
            onClick={() => navigate("/home/wallet")}
            type="button"
            className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-6 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600"
          >
            Go to Wallet
          </button>
        </div>
      </div>
    </div>
  );
};

export default CowriesRedeemSeccessfullPopup;
