export const googleLogin = async (response, name, surname) => {
  if ("access_token" in response) {
    try {
      const token = response.access_token;
      const result = await fetch(
        `${process.env.REACT_APP_PUBLIC_SERVER_URL}auth/google`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, name, surname }),
        }
      );

      return await result.json();
    } catch (err) {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const facebookLogin = async (response) => {
  if ("accessToken" in response) {
    try {
      const data = {
        ssi_api_code: response.id,
        fname: response.name.split(" ")[0],
        surname: response.name.split(" ")[1],
        email: response.email,
      };

      const result = await fetch(
        `${process.env.REACT_APP_PUBLIC_SERVER_URL}auth/facebook`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      return await result.json();
    } catch (err) {
      return undefined;
    }
  } else {
    return undefined;
  }
};
