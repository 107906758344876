import React, { useEffect, useState } from "react";
import Container from "../../../components/pages/my_container/Container";

import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { fetchRewardHistoryDetail } from "../../../store/actions";
import moment from "moment";

const RewardDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [fetchedDetails, setFetchedDetails] = useState([]);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchRewardHistoryDetail(params.id))
        .unwrap()
        .then((res) => {
          if (res?.reward_info_details?.length) {
            setFetchedDetails(res?.reward_info_details);
          }
        })
        .catch((err) => err);
    }

    if (!params.id) {
      return navigate(-1);
    }
  }, [params.id]);

  return (
    <>
      <SignedInNavbarImproved />
      <div className="bg-white">
        <Container className="">
          <div className="mt-10 flex items-center space-x-2 sm:space-x-4">
            <div className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-100">
              <BiArrowBack
                onClick={() => navigate(-1)}
                className="h-5 w-5 cursor-pointer text-gray-600"
              />
            </div>
            <h2 className="text-lg font-bold text-gray-700 sm:text-2xl">
              Reward Details
            </h2>
          </div>

          <div className="mt-6 max-w-4xl space-y-4 rounded-lg border-[1px] border-gray-200 px-4 pb-16 pt-6 shadow sm:px-6 lg:px-10">
            <div className="flex flex-row items-center space-x-4 text-sm sm:text-base">
              <p className="w-36 font-semibold text-gray-600 sm:w-52">
                Reward Cowries Quantity:
              </p>
              <p className="text-gray-500">
                {fetchedDetails && fetchedDetails?.length
                  ? fetchedDetails[0]?.reward_cowries_quantity
                  : null}
              </p>
            </div>
            <div className="flex flex-row items-center space-x-4 text-sm sm:text-base">
              <p className="w-36 font-semibold text-gray-600 sm:w-52">
                Reward Cowries Equivalent:
              </p>
              <p className="text-gray-500">
                {fetchedDetails && fetchedDetails?.length
                  ? `₦ ${Number(
                      fetchedDetails[0]?.reward_cowries_quantity * 1000
                    ).toLocaleString()}`
                  : null}
              </p>
            </div>
            <div className="flex flex-row items-center space-x-4 text-sm sm:text-base">
              <p className="w-36 font-semibold text-gray-600 sm:w-52">
                Wallet Transaction Amount:
              </p>
              <p className="text-gray-500">
                {fetchedDetails && fetchedDetails?.length
                  ? `₦ ${Number(
                      fetchedDetails[0]?.wallet_transaction_amount
                    ).toLocaleString()}`
                  : null}
              </p>
            </div>
            <div className="flex flex-row items-center space-x-4 text-sm sm:text-base">
              <p className="w-36 font-semibold text-gray-600 sm:w-52">
                Reward Title:
              </p>
              <p className="text-gray-500">
                {fetchedDetails && fetchedDetails?.length
                  ? fetchedDetails[0]?.reward_earn_title
                  : null}
              </p>
            </div>
            <div className="flex flex-row items-center space-x-4 text-sm sm:text-base">
              <p className="w-36 font-semibold text-gray-600 sm:w-52">
                Transaction Date:
              </p>
              <p className="text-gray-500">
                {fetchedDetails && fetchedDetails?.length
                  ? moment(fetchedDetails[0]?.transaction_date).format("ll")
                  : null}
              </p>
            </div>
            <div className="flex flex-row items-center space-x-4 text-sm sm:text-base">
              <p className="w-36 font-semibold text-gray-600 sm:w-52">
                Transaction Group:
              </p>
              <p className="text-gray-500">
                {fetchedDetails && fetchedDetails?.length
                  ? fetchedDetails[0]?.transaction_group
                  : null}
              </p>
            </div>
            <div className="flex flex-row items-center space-x-4 text-sm sm:text-base">
              <p className="w-36 font-semibold text-gray-600 sm:w-52">
                Transaction Name Type:
              </p>
              <p className="text-gray-500">
                {fetchedDetails && fetchedDetails?.length
                  ? fetchedDetails[0]?.transaction_type_name
                  : null}
              </p>
            </div>
            <div className="flex flex-row items-center space-x-4 text-sm sm:text-base">
              <p className="w-36 font-semibold text-gray-600 sm:w-52">
                Wallet Transaction Date:
              </p>
              <p className="text-gray-500">
                {fetchedDetails && fetchedDetails?.length
                  ? moment(fetchedDetails[0]?.wallet_transaction_date).format(
                      "ll"
                    )
                  : null}
              </p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default RewardDetails;
