import React from "react";
import LoadingImageOne from "../assets/loadingImageOne.gif";

function IsLoadingOne({ message }) {
  return (
    <div className="bg-olivine-5 fixed z-50 grid h-screen w-full bg-olivine-50">
      <div className="m-auto rounded-lg">
        <div className="flex flex-col items-center justify-center text-center">
          <div>
            <img src={LoadingImageOne} className="h-16 w-auto sm:h-24" alt="" />
          </div>
          <div className="-sm:mt-4 -mt-3 pl-2 text-xs font-bold text-gravel-500">
            {message}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IsLoadingOne;
