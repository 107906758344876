import React from "react";
import { IoClose } from "react-icons/io5";

function RecurringMontlyPopover({ onClose, handleSwitch }) {
  const handleOnClose = () => {
    onClose();
  };

  const handleMonthly = () => {
    handleSwitch();
    onClose();
  };
  return (
    <div className="absolute h-[1500px] w-full bg-black/30 grid">
      <div className="m-auto">
        <div className="px-8 pb-8 pt-4 bg-white w-[420px] text-gray-500 shadow-lg rounded-2xl">
          <div className="flex items-center justify-end">
            <div className="rounded-full p-1 hover:bg-gray-200">
              <IoClose className="h-6 w-6 cursor-pointer text-gray-600" />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="block text-base font-bold text-gray-600">
              Recurring monthly benefits
            </div>
          </div>
          <div className="mt-2 text-xs">
            <div>
              The recurring monthly service entitles you to products at cheaper
              prices along with other benefits
            </div>
          </div>
          <div className="mt-4 flex items-center justify-between space-x-4">
            <button
              onClick={handleOnClose}
              className="block px-4 py-3 whitespace-nowrap bg-white text-olivine-500 border-[1px] border-olivine-500 text-xs font-semibold rounded-lg hover:text-olivine-600 hover:bg-olivine-100 transition duration-300"
            >
              Continue with one off
            </button>
            <button
              onClick={handleMonthly}
              className="block px-4 py-3 whitespace-nowrap -p-[1px] bg-olivine-500 text-white text-xs font-semibold rounded-lg hover:bg-olivine-600 shadow transition duration-300"
            >
              Switch to monthly recurring
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecurringMontlyPopover;
