import { createContext } from "react";
import { BehaviorSubject } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { authService } from "../services/authService";
import BasketService from "../services/basketService";
import { toast } from "react-hot-toast";

const RxjsStoreContext = createContext({
  setBasketGroupList: (list) => {},
  setButcherGroupList: (list) => {},
  getBasketGroupList: () => {},
  getButcharGroupList: () => {},
  getAllBasketProductList: () => {},
  getAllButcherProductList: () => {},
  setAllBasketProductList: () => {},
  setAllButchersProductList: () => {},
  setUserSelectedBasket: (product, quantity) => {},
  removeItemFromBasket: (id) => {},
  getUserSelectedBasket: () => {},
  getTempBasketId: () => {},
  saveBasketTemporarily: () => {},
  decrementQuantity: (key, qty) => {},
  incrementQuantity: (key, qty) => {},
  setSectionList: (sectionList) => {},
  getSectionList: () => {},
  swapUserBasketId: () => {},
  clearCustomBasket: () => {},
  getSinglePrice: (key) => {},
  getProductByGroup: (groupId) => {},
  getButcherByGroup: (groupId) => {},
  handleSearchProduct: (search) => {},
  handleSearchProductButchers: (search) => {},
  performFavorite: (productId, option) => {},
  performFavoriteButchers: (productId, option) => {},
  setDeliveryChargeBasket: (item) => {},
  getDeliveryChargeBasket: () => {},
  setDeliveryChargeButcher: (item) => {},
  getDeliveryChargeButcher: (item) => {},
});

export function RxjsStoreContextProvider(props) {
  let butcherGroupList = new BehaviorSubject([]);
  let basketGroupList = new BehaviorSubject([]);
  let allBasketProductList = new BehaviorSubject([]);
  let allButcherProductList = new BehaviorSubject([]);
  let userSelectedBasket = new BehaviorSubject(new Map());
  let priceListMap = new BehaviorSubject(new Map());
  let sectionListSubscription = new BehaviorSubject([]);
  let deliveryChargeBasket = {};
  let deliveryChargeButcher = {};

  // use Auth id to get basket or use basket ID
  let basketId = getTempBasketId();
  BasketService.getTempBasketData(basketId).then((productList) => {
    if (productList?.length) {
      productList?.forEach((product) => {
        setUserSelectedBasket(product, product.qty); // temp qty , please remove later
        if (authService.isLogin()) {
          swapUserBasketId();
        }
      });
    }
  });

  callApiButcherList(); // Call API for butcher list
  callApiBasketList(); // Call API for butcher list

  //  swap user Id to loggen in user.
  async function swapUserBasketId() {
    await BasketService.deleteTempBasket(getTempBasketId());
    const oldKey = localStorage.getItem("basket_id");
    localStorage.removeItem("basket_id");
    await saveBasketTemporarily();
    BasketService.deleteTempBasket(oldKey);
  }

  function getSinglePrice(key) {
    let tempPriceMap = new Map(priceListMap.getValue());

    const priceData = tempPriceMap.get(key);

    return priceData;
  }

  // save basket id in local storage
  function setTempBasketId(id) {
    localStorage.setItem("basket_id", id);
  }

  //   get Basket id in local storage
  function getTempBasketId() {
    const basketId =
      localStorage.getItem("basket_id") || authService.getCurrentUserID();
    return basketId;
  }

  const setBasketGroupList = (basketList) => {
    basketGroupList.next(basketList);
  };

  const setButcherGroupList = (groupList) => {
    butcherGroupList.next(groupList);
  };

  const setDeliveryChargeBasket = (item) => {
    deliveryChargeBasket = item;
  };

  const getDeliveryChargeBasket = () => {
    return deliveryChargeBasket;
  };

  const setDeliveryChargeButcher = (item) => {
    deliveryChargeButcher = item;
  };

  const getDeliveryChargeButcher = () => {
    return deliveryChargeButcher;
  };

  // you can subscribe to available basket list
  const getButcharGroupList = () => {
    return butcherGroupList;
  };

  const setSectionList = (sectionList) => {
    sectionListSubscription.next(sectionList);
  };

  const getSectionList = () => {
    return sectionListSubscription;
  };

  //  Add product to uer basket
  const setUserSelectedBasket = (product, qty) => {
    let tempProductMap = new Map(userSelectedBasket.getValue());

    // create an ID if it a new basket
    if (tempProductMap.size === 0 && getTempBasketId() === null) {
      setTempBasketId(uuidv4());
    }
    let productExist = tempProductMap.get(product.sku);

    if (productExist) {
      productExist.qty += qty;
      tempProductMap.set(productExist.sku, productExist);
      userSelectedBasket.next(tempProductMap);
    } else {
      product["qty"] = qty;
      tempProductMap.set(product.sku, product);
      userSelectedBasket.next(tempProductMap);
    }
    // sumCurrentProductInBasket();
  };

  //  Remove product from user selected basket
  const removeItemFromBasket = (key) => {
    let tempProductMap = new Map(userSelectedBasket.getValue());
    tempProductMap.delete(key);
    userSelectedBasket.next(tempProductMap);
    if (tempProductMap.size === 0) {
      //  delete temp basket from server;
      BasketService.deleteTempBasket(getTempBasketId());
    }
  };

  const clearCustomBasket = () => {
    let tempProductMap = new Map(userSelectedBasket.getValue());
    tempProductMap.clear();
    userSelectedBasket.next(tempProductMap);
    BasketService.deleteTempBasket(getTempBasketId());
  };

  //  you can subscribe to this function to get user selected basket
  const getUserSelectedBasket = () => {
    return userSelectedBasket;
  };

  // you can subscribe to available basket list
  const getBasketGroupList = () => {
    return basketGroupList;
  };

  //  Increment the quantity of a product in a basket
  function incrementQuantity(key, qty) {
    let tempMapData = new Map(userSelectedBasket.getValue()); // create new MAP of existing user basket
    let productExist = tempMapData.get(key); // get the product
    productExist.qty = productExist.qty + qty; // increment the quantity
    tempMapData.set(key, productExist); // update the quantity
    userSelectedBasket.next(tempMapData); // send the updated product to all subscribers
  }

  //  decrement the quantity of a product in a basket
  function decrementQuantity(key, qty) {
    let tempMapData = new Map(userSelectedBasket.getValue()); // create new MAP of existing user basket
    let productExist = tempMapData.get(key); // ge
    productExist.qty = productExist.qty - qty; // deduct the quantity from the product
    if (productExist.qty < 1) {
      productExist.qty += qty; // return the removed qty back to the basket
      return toast.error("Minimum quantity must be 1.");
    } else {
      tempMapData.set(key, productExist);
      userSelectedBasket.next(tempMapData);
    }
  }

  //  create a list  of all product and send the list to all subscribers
  const setAllBasketProductList = (dataList) => {
    allBasketProductList.next(dataList);
  };

  //  create a list  of all product and send the list to all subscribers
  const setAllButchersProductList = (dataList) => {
    allButcherProductList.next(dataList);
  };

  const performFavorite = (productId, option) => {
    let found = false;
    let newList = allBasketProductList.getValue().map((product) => {
      if (product?.item_id === productId) {
        found = true;
        return { ...product, favourite_status: option };
      } else {
        return product;
      }
    });
    allBasketProductList.next(newList);
    if (found) {
      BasketService.performFavorite(productId, option);
    }
  };

  const performFavoriteButchers = (productId, option) => {
    let found = false;
    let newList = allButcherProductList.getValue().map((product) => {
      if (product?.item_id === productId) {
        found = true;
        return { ...product, favourite_status: option };
      } else {
        return product;
      }
    });
    allButcherProductList.next(newList);
    if (found) {
      BasketService.performFavorite(productId, option);
    }
  };

  // you can subscribe to all available product list
  const getAllBasketProductList = () => {
    return allBasketProductList;
  };
  // you can subscribe to all available product list
  const getAllButcherProductList = () => {
    return allButcherProductList;
  };

  // get product by selected group
  const getProductByGroup = (groupId) => {
    let allProductList = allBasketProductList.getValue();
    let groupList = [];
    for (let product of allProductList) {
      if (product?.group_id === groupId) {
        groupList.push(product);
      }
    }
    return groupList;
  };

  // get product by selected group for butchers
  const getButcherByGroup = (groupId) => {
    let allProductList = allButcherProductList.getValue();
    let groupList = [];
    for (let product of allProductList) {
      if (product?.group_id === groupId) {
        groupList.push(product);
      }
    }
    return groupList;
  };

  const handleSearchProduct = (search) => {
    let allProductList = allBasketProductList.getValue();
    var tempResult = allProductList?.filter((product) => {
      return (
        product?.name?.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        product?.sku?.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        product?.rate?.toLowerCase().indexOf(search.toLowerCase()) >= 0
      );
    });

    // setTimeout(() => {
    saveSearchRecord(search);
    // }, 9000);
    return tempResult;
  };

  const handleSearchProductButchers = (search) => {
    let allButcherList = allButcherProductList.getValue();
    var tempResult = allButcherList?.filter((product) => {
      return (
        product?.name?.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        product?.sku?.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        product?.rate?.toLowerCase().indexOf(search.toLowerCase()) >= 0
      );
    });

    // setTimeout(() => {
    saveSearchRecord(search);
    // }, 9000);
    return tempResult;
  };

  const saveSearchRecord = (searchText) => {
    if (authService.isLogin()) {
      if (searchText.length > 2) {
        BasketService.saveSearchRecord(searchText).then((success) => {});
      }
    }
  };

  // save basket temporarily in the server
  const saveBasketTemporarily = () => {
    let productSelected = new Map(userSelectedBasket.getValue());
    let tempList = [];
    for (let [key, product] of productSelected) {
      const timestampedProduct = {
        ...product,
        createdAt: new Date(),
      };
      tempList.push(timestampedProduct);
    }
    let newFormData = { key: getTempBasketId(), products: tempList };
    if (newFormData?.products?.length > 0) {
      BasketService.saveBasketTemporarily(newFormData).then((response) => {});
    } else {
      // console.log("Do not update... basket");
    }
  };

  function callApiBasketList() {
    BasketService.getBasketList()
      .then((data, failed) => {
        const { item_group, items, delivery_charge } = data;
        if (item_group?.length) {
          setBasketGroupList(item_group);
          setAllBasketProductList(items);
          let charge = {
            item_id: delivery_charge[0]?.item_id,
            item_total: delivery_charge[0]?.item_rate,
            rate: delivery_charge[0]?.item_rate,
            quantity: 1,
            name: "Delivery charge",
          };
          setDeliveryChargeBasket(charge);
        }
      })
      .catch((err) => {});
  }

  function callApiButcherList() {
    BasketService.getButcherList()
      .then((data, failed) => {
        const { item_group, items, delivery_charge } = data;
        if (item_group?.length) {
          setButcherGroupList(item_group);
          setAllButchersProductList(items);
          let charge = {
            ...delivery_charge[0],
            item_total: delivery_charge[0]?.item_rate,
            quantity: 1,
            name: "delivery charge",
          };
          setDeliveryChargeButcher(charge);
        }
      })
      .catch((err) => {});
  }

  const context = {
    setBasketGroupList: setBasketGroupList,
    setButcherGroupList: setButcherGroupList,
    getBasketGroupList: getBasketGroupList,
    getButcharGroupList: getButcharGroupList,
    setAllBasketProductList: setAllBasketProductList,
    setAllButchersProductList: setAllButchersProductList,
    getAllBasketProductList: getAllBasketProductList,
    getAllButcherProductList: getAllButcherProductList,
    setUserSelectedBasket: setUserSelectedBasket,
    getUserSelectedBasket: getUserSelectedBasket,
    removeItemFromBasket: removeItemFromBasket,
    getTempBasketId: getTempBasketId,
    saveBasketTemporarily: saveBasketTemporarily,
    decrementQuantity: decrementQuantity,
    incrementQuantity: incrementQuantity,
    setSectionList: setSectionList,
    getSectionList: getSectionList,
    swapUserBasketId: swapUserBasketId,
    clearCustomBasket: clearCustomBasket,
    getSinglePrice: getSinglePrice,
    getProductByGroup: getProductByGroup,
    getButcherByGroup: getButcherByGroup,
    handleSearchProduct: handleSearchProduct,
    handleSearchProductButchers: handleSearchProductButchers,
    performFavorite: performFavorite,
    performFavoriteButchers: performFavoriteButchers,
    setDeliveryChargeBasket: setDeliveryChargeBasket,
    getDeliveryChargeBasket: getDeliveryChargeBasket,
    setDeliveryChargeButcher: setDeliveryChargeButcher,
    getDeliveryChargeButcher: getDeliveryChargeButcher,
  };

  return (
    <RxjsStoreContext.Provider value={context}>
      {props.children}
    </RxjsStoreContext.Provider>
  );
}

export default RxjsStoreContext;
