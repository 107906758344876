import { createSlice } from "@reduxjs/toolkit";
import {
  fetchRewardBankInfo,
  fetchRewardHistory,
  fetchRewardHistoryDetail,
  fetchRedeemRewardPoints,
  fetchEarnedCowriesToday,
  fetchEarnedCowriesLastWeek,
  fetchEarnedCowriesLastMonth,
  fetchEarnedCowriesCustom,
} from "../actions";

const initialState = {
  generalInfo: "",
  history: "",
  historyDetail: "",
  redeemPoints: "",
  earnedToday: "",
  earnedLastWeek: "",
  earnedLastMonth: "",
  customDate: "",
};

const RewardBankSlice = createSlice({
  name: "RewardBankData",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchRewardBankInfo.fulfilled, (state, { payload }) => {
      state.generalInfo = payload;
    });

    builder.addCase(fetchRewardHistory.fulfilled, (state, { payload }) => {
      state.history = payload;
    });

    builder.addCase(
      fetchRewardHistoryDetail.fulfilled,
      (state, { payload }) => {
        state.historyDetail = payload;
      }
    );

    builder.addCase(fetchRedeemRewardPoints.fulfilled, (state, { payload }) => {
      state.redeemPoints = payload;
    });

    builder.addCase(fetchEarnedCowriesToday.fulfilled, (state, { payload }) => {
      state.earnedToday = payload;
    });

    builder.addCase(
      fetchEarnedCowriesLastWeek.fulfilled,
      (state, { payload }) => {
        state.earnedLastWeek = payload;
      }
    );

    builder.addCase(
      fetchEarnedCowriesLastMonth.fulfilled,
      (state, { payload }) => {
        state.earnedLastMonth = payload;
      }
    );

    builder.addCase(
      fetchEarnedCowriesCustom.fulfilled,
      (state, { payload }) => {
        state.customDate = payload;
      }
    );
  },
});

const { reducer } = RewardBankSlice;

export default reducer;
