import React, { useEffect, useState } from "react";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { IoPersonCircleSharp } from "react-icons/io5";
import { BiDetail } from "react-icons/bi";
import { HiLockClosed } from "react-icons/hi";
import { IoMdArrowDropdown } from "react-icons/io";
import { Listbox } from "@headlessui/react";
import ProfileSettingsItems from "./ProfileSettingsItems";
import ProfileSettingsWeb from "./ProfilesettingsWeb";
import DeliveryDetails from "./DeliveryDetails";
import ChangeTransactionPin from "./changePin/ChangeTransactionPin";
import ChangePassword from "./password/ChangePassword";
import { authService } from "../../../services/authService";
import { Tab } from "@headlessui/react";
import { Link } from "react-router-dom";
import { FaAd } from "react-icons/fa";
import AdvertsWeb from "./AdvertsWeb";
import { useDispatch } from "react-redux";
import { showSettingsAdsInfo } from "../../../store/actions";

const settingsList = [
  {
    id: 1,
    name: "Personal Details",
    icon: IoPersonCircleSharp,
    unavailable: false,
  },
  {
    id: 2,
    name: "Promotions",
    icon: FaAd,
    unavailable: false,
  },
  { id: 3, name: "Delivery Details", icon: BiDetail, unavailable: false },
  { id: 4, name: "PIN", icon: HiLockClosed, unavailable: false },
  { id: 5, name: "Password", icon: HiLockClosed, unavailable: false },
];

function ProfileSettingsMobile() {
  const dispatch = useDispatch();
  const [selectedSetting, setSelectedSetting] = useState(settingsList[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fetchedSettingsAsInfo, setFetchedSettingsAdsInfo] = useState({});

  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (customerId) {
      dispatch(showSettingsAdsInfo(customerId))
        .unwrap()
        .then((res) => {
          if (res) {
            setFetchedSettingsAdsInfo(res);
          }
        })
        .catch((err) => err);
    }
  }, [customerId]);

  const setCurrentTab = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="bg-white">
      <SignedInNavbarImproved />
      <Tab.Group selectedIndex={currentIndex}>
        <div className="mx-auto max-w-[1600px] md:flex md:h-full">
          {/* mobile */}
          <div className="px-4 xsm:px-6 sm:px-8 md:px-0">
            <Tab.List className=" w-full whitespace-nowrap text-white md:h-full md:w-[350px] md:bg-[#222222] md:shadow-md">
              <div className="hidden py-10 pl-10 md:flex md:flex-col">
                <div className="hidden text-2xl md:inline">Profile</div>
                <div className="mt-6 w-full md:flex md:flex-col">
                  <Tab className="" onClick={() => setCurrentTab(0)}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "w-full rounded-md bg-[#7874744f] text-olivine-500"
                            : "w-full"
                        }
                      >
                        <div className="">
                          <ProfileSettingsItems
                            Icon={IoPersonCircleSharp}
                            title="Personal Details"
                          />
                        </div>
                      </button>
                    )}
                  </Tab>
                  <Tab className="" onClick={() => setCurrentTab(1)}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "w-full rounded-md bg-[#7874744f] text-olivine-500"
                            : "w-full"
                        }
                      >
                        <div className="">
                          <ProfileSettingsItems
                            Icon={FaAd}
                            title="Promotions"
                          />
                        </div>
                      </button>
                    )}
                  </Tab>
                  <Tab onClick={() => setCurrentTab(2)}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "w-full rounded-md bg-[#7874744f] text-olivine-500"
                            : "w-full"
                        }
                      >
                        <div className="">
                          <ProfileSettingsItems
                            Icon={BiDetail}
                            title="Delivery Details"
                          />
                        </div>
                      </button>
                    )}
                  </Tab>
                  <Tab onClick={() => setCurrentTab(3)}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "w-full rounded-md bg-[#7874744f] text-olivine-500"
                            : "w-full"
                        }
                      >
                        <div className="">
                          <ProfileSettingsItems
                            Icon={HiLockClosed}
                            title="PIN"
                          />
                        </div>
                      </button>
                    )}
                  </Tab>
                  <Tab onClick={() => setCurrentTab(4)}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "w-full rounded-md bg-[#7874744f] text-olivine-500"
                            : "w-full"
                        }
                      >
                        <div className="">
                          <ProfileSettingsItems
                            Icon={HiLockClosed}
                            title="Password"
                          />
                        </div>
                      </button>
                    )}
                  </Tab>
                </div>
              </div>
              <div className="w-full md:hidden">
                <div className="w-full md:w-auto">
                  <div className="flex items-center justify-between">
                    <div className="mb-2 text-2xl font-bold text-gravel-500">
                      Profile
                    </div>
                    <Link to="/home/wallet">
                      <button className="mb-4 mt-4 rounded border border-olivine-500 px-6 py-3 transition duration-300 ease-in-out hover:ring-[0.5px] hover:ring-olivine-500">
                        <div className="text-sm font-semibold text-olivine-500">
                          Fund your Account
                        </div>
                      </button>
                    </Link>
                  </div>
                  <div>
                    <div className="relative flex items-center justify-between rounded-md bg-[#4F4F4F] px-4 py-3 md:bg-white md:px-0">
                      <div className="text-lg font-bold leading-10 tracking-wider text-white md:text-2xl md:text-gravel-500">
                        {selectedSetting.name}
                      </div>
                      <Listbox
                        value={selectedSetting}
                        onChange={setSelectedSetting}
                      >
                        <Listbox.Button>
                          <IoMdArrowDropdown className="h-6 w-6 cursor-pointer text-white hover:scale-110" />
                        </Listbox.Button>
                        <Listbox.Options className="absolute right-0 top-16 z-50 mt-2 flex w-64 origin-top-right flex-col divide-y divide-gray-200 rounded-md bg-white font-semibold text-gray-600 shadow-md shadow-gray-400 ring-1 ring-black ring-opacity-5 focus:outline-none sm:w-80">
                          {settingsList.map((settingsList, index) => (
                            <Listbox.Option
                              onClick={() => setCurrentTab(index)}
                              key={settingsList.id}
                              value={settingsList}
                              disabled={settingsList.unavailable}
                            >
                              <Tab className="w-full">
                                <ProfileSettingsItems
                                  Icon={settingsList.icon}
                                  title={settingsList.name}
                                />
                              </Tab>
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Listbox>
                    </div>

                    <div className="mt-4 text-sm font-semibold text-gray-600">
                      Account Type:{" "}
                      <span className="text-olivine-600">
                        {authService?.getAccountType()}
                      </span>
                    </div>

                    <div className="text-sm font-semibold text-gray-600">
                      Status:{" "}
                      <span className="text-olivine-600">
                        {authService?.getApprovedStatus()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.List>
          </div>
          {/*   */}

          <Tab.Panels className="w-full px-4 xsm:px-6 sm:px-8">
            <Tab.Panel className="mt-4 md:mt-0">
              <ProfileSettingsWeb />
            </Tab.Panel>
            <Tab.Panel className="mt-4 md:mt-0">
              <AdvertsWeb adsInfo={fetchedSettingsAsInfo} />
            </Tab.Panel>
            <Tab.Panel className="mt-4 md:mt-0">
              <DeliveryDetails />
            </Tab.Panel>
            <Tab.Panel className="mt-4 md:mt-0">
              <ChangeTransactionPin />
            </Tab.Panel>
            <Tab.Panel className="mt-4 md:mt-0">
              <ChangePassword />
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
}

export default ProfileSettingsMobile;
