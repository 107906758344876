import React from "react";
import Logo2 from "../assets/logo2.svg";
import { Link } from "react-router-dom";
import { RiInstagramFill, RiFacebookFill, RiTwitterFill } from "react-icons/ri";

function Footer() {
  const goToLink = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div className="mx-auto mt-32 max-w-[1600px] bg-mine-shaft-500 px-4 pb-10 pt-16 font-sans text-gray-200 xsm:px-6 sm:px-8 md:px-16">
      <div className="sm:flex sm:flex-row sm:items-start sm:justify-between sm:space-x-8">
        <div className="sm:max-w-xs lg:max-w-sm xl:max-w-md">
          <Link to="#">
            <img src={Logo2} className="h-20 w-20" alt="Logo" />
          </Link>
          <p className="pt-4 text-xs sm:text-sm">
            Our primary goal is to make food as affordable as possible. We do
            this by working with manufacturers and local farmers to get food at
            wholesale prices then we help you save towards them with a monthly
            subscription service.
          </p>
          <div className="mt-6 flex items-center space-x-4">
            <Link onClick={() => goToLink("https://www.twitter.com/grocedy")}>
              <RiTwitterFill className="h-8 w-8 rounded-full bg-white p-1 text-mine-shaft-500" />
            </Link>
            <Link onClick={() => goToLink("https://www.facebook.com/grocedy")}>
              <RiFacebookFill className="ml-4 h-8 w-8 rounded-full bg-white p-1 text-mine-shaft-500" />
            </Link>
            <Link onClick={() => goToLink("https://www.instagram.com/grocedy")}>
              <RiInstagramFill className="ml-4 h-8 w-8 rounded-full bg-white p-1 text-mine-shaft-500" />
            </Link>
          </div>
        </div>
        <div className="lg:flex lg:items-start lg:justify-between lg:space-x-20 xl:max-w-[900px] xl:space-x-32">
          <div className="mt-8 max-w-sm space-y-4 text-xs sm:text-sm lg:mt-0">
            <h2 className="text-xl font-semibold">Services</h2>
            <Link to="/">
              <p className="mt-2">Home Page</p>
            </Link>
            <Link to="/">
              <p className="mt-2">Plans</p>
            </Link>
            <Link to="/about">
              <p className="mt-2">About Grocedy</p>
            </Link>
            <Link to="/gift-a-plan">
              <p className="mt-2">Donate a Food Basket</p>
            </Link>
            <Link to="/gift">
              <p className="mt-2">Gift a Food Basket</p>
            </Link>
            <Link to="/basket/create-basket">
              <p className="mt-2">Create your Basket</p>
            </Link>
          </div>
          <div className="mt-8 max-w-sm space-y-2 text-xs sm:text-sm lg:mt-0">
            <h2 className="text-xl font-semibold">Company</h2>
            <p>Address: Chief Augustine street Lekki Phase 1, Lagos nigeria</p>
            <p>Email: customercare@grocedy.com</p>
            <p>Contact: 0700GROCEDY, 09052436563</p>
            <p>Whatsapp: +234 814 153 0208</p>
          </div>
        </div>
      </div>
      <div className="mt-8 space-y-5 sm:mt-16">
        <div className="text-sm sm:flex sm:items-center sm:justify-center sm:space-x-6">
          <Link to="/terms-and-conditions">
            <div className="hover:text-olivine-500 active:text-olivine-400">
              Terms and Conditions
            </div>
          </Link>
          <Link to="/privacy-policy">
            <div className="mt-1 hover:text-olivine-500 active:text-olivine-400 sm:mt-0">
              Privacy Policy
            </div>
          </Link>
          <Link to="/user-data-records">
            <div className="mt-1 hover:text-olivine-500 active:text-olivine-400 sm:mt-0">
              User Data Deletion
            </div>
          </Link>
        </div>
        <hr className="h-[0.5px] w-full border-0 bg-gray-500" />
        <p className="text-center text-sm">
          Grocedy 2021 | All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
