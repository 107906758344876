import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import OtpInput from "react-otp-input";
import { toast } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";

function NewPin(props) {
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");

  function submitNewPin() {
    if (otp1.length !== 4 && otp2.length !== 4) {
      return toast.error("Please, enter a valid PIN");
    }

    if (otp1.length !== otp2.length) {
      return toast.error("Pin must be 4 length!");
    }
    if (otp1 !== otp2) {
      return toast.error("Pin not matched!");
    }

    props.submitNewPin(otp1);
  }

  return (
    <div className="p-4">
      <div className="flex justify-end">
        <div className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
          <IoClose onClick={() => props?.closeModal()} className="h-5 w-5" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center space-x-2">
        <h2 className="mt-4 text-xl font-semibold text-gray-700">Change PIN</h2>
        <p className="mt-1 text-center text-sm text-gray-600">
          Set new transaction PIN
        </p>
        <div className="mt-2 flex justify-center">
          <div>
            <label className="max-w-sm pl-2 text-start text-xs text-gray-600">
              Input New PIN
            </label>
            <OtpInput
              inputStyle={{
                margin: 3,
                height: 36,
                width: 36,
                borderRadius: 4,
                borderColor: "#BDBDBD",
                outline: "none",
                border: "1px solid #BDBDBD",
              }}
              value={otp1}
              onChange={setOtp1}
              numInputs={4}
              inputType="number"
              renderInput={(props) => <input {...props} />}
            />
          </div>
        </div>
        <div className="mt-2 flex justify-center">
          <div>
            <label className="max-w-sm pl-2 text-start text-xs text-gray-600">
              Input New PIN
            </label>
            <OtpInput
              inputStyle={{
                margin: 3,
                height: 35,
                width: 35,
                borderRadius: 4,
                borderColor: "#BDBDBD",
                outline: "none",
                border: "1px solid #BDBDBD",
              }}
              value={otp2}
              onChange={setOtp2}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
            />
          </div>
        </div>
        <div className="mx-auto mt-6 w-full max-w-[250px] pb-2">
          <button
            disabled={props?.isLoading}
            onClick={() => submitNewPin()}
            className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F6FBEE] p-8 py-3 text-sm font-semibold text-olivine-500  transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100"
          >
            {props?.isLoading === true ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Save new pin "
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewPin;
