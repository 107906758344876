import React from "react";
import { IoClose } from "react-icons/io5";
import CancelPlanIcon from "../../../assets/CancelPlanIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIsActivePlan } from "../../../store/slices/modalSlice";

function CancelAutofundPopover({ onClose, cancelFund }) {
  const dispatch = useDispatch();

  const { isActivePlan } = useSelector((state) => state.modal);

  const handleCancelAutoFund = () => {
    cancelFund();
    dispatch(setIsActivePlan(false));
  };
  return (
    <div className="w-full max-w-xs rounded-2xl bg-white">
      <div
        onClick={() => onClose()}
        className="flex justify-end px-4 py-4 xsm:px-6"
      >
        <button className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-300">
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="px-4">
        <div className="flex items-center justify-center space-x-2 pt-3">
          <img src={CancelPlanIcon} alt="icon" className="h-4 w-4" />
          <h3 className="font-semibold text-gray-600">Cancel Auto Fund</h3>
        </div>
        <p className="mt-1 pb-4 text-center text-sm text-gray-500">
          {isActivePlan
            ? "You already have an existing auto-fund. Continuing with this transaction will cancel your existing auto-fund and create a new one."
            : "Are you sure you want to cancel your auto fund?"}
        </p>
      </div>
      <div className="flex flex-col space-y-4 px-4 pb-6">
        <button
          onClick={() => handleCancelAutoFund()}
          className="w-full rounded-md bg-olivine-500 py-3 font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
        >
          Cancel Auto Fund
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          className="w-full rounded-md border-[1px] border-olivine-500 bg-white py-3 font-semibold capitalize text-olivine-500 transition-all duration-200 first:uppercase hover:bg-olivine-50 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100"
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default CancelAutofundPopover;
