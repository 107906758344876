import React, { useState } from "react";
import { Modal, Box } from "@mui/material";
import RedeemVoucherWalletIcon from "../../../assets/redeeemVoucherIcon.svg";
import RedeemAVoucher from "./RedeemAVoucher";
import RedeemAvoucherSuccess from "./RedeemAvoucherSuccess";
import RedeeemVoucherIcon from "../../../assets/redeeemVoucherIcon.svg";

function RedeemVoucherWalletButton() {
  const [openRedeem, setOpenRedeem] = useState(false);
  const [openRedeemSuccess, setOpenRedeemSuccess] = useState(false);
  return (
    <div className="">
      <button
        onClick={() => setOpenRedeem(true)}
        className={`text-opacity-90" h-36 w-full rounded-lg border-[1px] border-gray-200 bg-[#F9F9F9] p-6 hover:ring-[1px] hover:ring-[#558223]/60 focus:outline-none`}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="flex h-14 w-14 items-center justify-center rounded-full bg-[#558223]">
            <img src={RedeeemVoucherIcon} alt="icon" className="h-6 w-6" />
          </div>
          <p className="mt-1 text-base font-semibold text-[#558223]">
            Redeem Voucher
          </p>
        </div>
      </button>
      <Modal
        open={openRedeem}
        onClose={() => setOpenRedeem(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-16 overflow-hidden px-2 xsm:px-4">
          <Box className="mx-auto h-full max-h-[500px] max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <RedeemAVoucher
              setOpenRedeem={setOpenRedeem}
              setOpenRedeemSuccess={setOpenRedeemSuccess}
            />
          </Box>
        </div>
      </Modal>
      <Modal
        open={openRedeemSuccess}
        onClose={() => setOpenRedeemSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-16 overflow-hidden px-2 xsm:px-4">
          <Box className="mx-auto h-full max-h-[500px] max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <RedeemAvoucherSuccess
              setOpenRedeemSuccess={setOpenRedeemSuccess}
            />
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default RedeemVoucherWalletButton;
