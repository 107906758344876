import React from "react";
import Navbar from "../../Navbar";

function PrivacyPolice() {
  return (
    <div className="mx-auto w-full max-w-[2520px] bg-white">
      <Navbar />
      <div className="text-sm">
        <div className="bg-gray-200 py-20">
          <p className="text-center text-4xl font-bold">Privacy Policy</p>
        </div>
        <div className="px-4 pb-20 xsm:px-6 sm:px-10 md:px-16">
          <div className="mt-4 text-center">
            <p className="mx-auto max-w-4xl pb-8 text-xs font-medium italic">
              This Privacy Policy describes how Grocedy collects, uses, and
              protects the personal information of users who visit our website.
              At Grocedy, we are committed to ensuring the privacy and security
              of your personal information. By using our website, you agree to
              the terms outlined in this Privacy Policy.
            </p>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4">
            <div className="sm:col-span-2">
              <ol className="list-inside list-decimal space-y-4 text-base font-bold text-gray-700">
                <li>
                  Information We Collect:
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - Personal Information: When you visit our website, we may
                    collect personal information such as your name, email
                    address, contact number, and location. This information is
                    collected when you voluntarily provide it to us through
                    forms or other means of communication on our website.
                  </p>
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - Log Data: Our servers may automatically collect certain
                    information when you visit our website. This may include
                    your IP address, browser type, the pages you visit, the time
                    spent on those pages, and other statistics.
                  </p>
                </li>
                <li>
                  How We Use Your Information:
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - Personalization: We may use the information collected to
                    personalize your experience on our website, including
                    providing tailored content and recommendations.
                  </p>
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - Communication: We may use your contact information to
                    respond to your inquiries, provide important updates or
                    notifications, and send you marketing communications if you
                    have opted-in to receive them.
                  </p>
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - Analytics: We may use the information collected to analyze
                    website usage, monitor and improve our services, understand
                    user preferences, and enhance the overall user experience.
                  </p>
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - Legal Compliance: We may use your information as required
                    by applicable laws, regulations, or legal processes.
                  </p>
                </li>
                <li>
                  Data Protection and Security:
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - We implement reasonable security measures to protect
                    against unauthorized access, alteration, disclosure, or
                    destruction of your personal information.
                  </p>
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - We restrict access to your personal information to
                    authorized personnel only, who are required to keep it
                    confidential.
                  </p>
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - While we strive to protect your personal information, no
                    method of transmission or storage is 100% secure. Therefore,
                    we cannot guarantee its absolute security.
                  </p>
                </li>
                <li>
                  Third-Party Disclosure:
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - We may disclose your personal information if required by
                    law, to protect our rights, or in response to legal
                    requests.
                  </p>
                </li>
                <li>
                  Cookies and Tracking Technology:
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - We use cookies and similar tracking technologies to
                    enhance your browsing experience, remember your preferences,
                    and collect information about how you use our website.
                  </p>
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - You can modify your browser settings to manage and control
                    cookies. However, disabling cookies may limit certain
                    features of our website.
                  </p>
                </li>
                <li>
                  Third-Party Links:
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - Our website may contain links to third-party websites that
                    are not owned or controlled by Grocedy. We are not
                    responsible for the privacy practices or content of these
                    websites. We recommend reviewing their privacy policies
                    before providing any personal information.
                  </p>
                </li>
                <li>
                  Children’s Privacy:
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - Our website is not intended for children under the age of
                    13. We do not knowingly collect personal information from
                    children. If we become aware of any personal information
                    collected from children, we will take immediate steps to
                    delete it.
                  </p>
                </li>
                <li>
                  Changes to this Privacy Policy:
                  <p className="mt-1 pl-4 text-[13px] font-normal leading-5">
                    {" "}
                    - We reserve the right to modify or update this Privacy
                    Policy at any time. Any changes will be effective
                    immediately upon posting. It is your responsibility to
                    review this Privacy Policy periodically.
                  </p>
                </li>
              </ol>
            </div>
            <div className="mt-4 sm:col-span-1 sm:mt-0">
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at{" "}
                <span className="cursor-pointer text-blue-600 underline underline-offset-2">
                  hello@grocedy.com.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolice;
