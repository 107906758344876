import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { Disclosure } from "@headlessui/react";

function DisclosureVoucherComponent({
  question,
  answer1,
  answer2,
  answer3,
  answer4,
  answer5,
  answer6,
  answer7,
  answer8,
  answer9,
  answer10,
}) {
  return (
    <div className="">
      <div className="mx-auto w-full rounded-md border-[1px] border-gray-300 bg-white">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open ? "" : "text-gray-600"
                } focus-visible:ring-Primary_color flex w-full justify-between px-4 py-5 text-left text-sm  font-medium focus:outline-none focus-visible:ring focus-visible:ring-opacity-75`}
              >
                <span>{question}</span>
                {open ? (
                  <div className="rounded-full border-[1px] border-olivine-500 text-olivine-500">
                    <AiOutlinePlus className="h-4 w-4" />
                  </div>
                ) : (
                  <div className="rounded-full border-[1px] border-olivine-500 text-olivine-500">
                    <AiOutlineMinus className="h-4 w-4" />
                  </div>
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="-mt-2 rounded-md bg-white pb-2 text-sm font-normal text-gray-600">
                <div className="space-y-[2px] px-4 pb-2 sm:px-6">
                  <p className="">{answer1}</p>
                  <p className="">{answer2}</p>
                  <p className="">{answer3}</p>
                  <p className="">{answer4}</p>
                  <p className="">{answer5}</p>
                  <p className="">{answer6}</p>
                  <p className="">{answer7}</p>
                  <p className="">{answer8}</p>
                  <p className="">{answer9}</p>
                  <p className="">{answer10}</p>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}

export default DisclosureVoucherComponent;
