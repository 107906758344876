import baseAxios from "../core/api/axios/baseAxios";

class UserService {
  static async createAccountNumber(data) {
    const res = await baseAxios.post("user/account", data);

    return res?.data || res;
  }

  static async getSettlementStatus(data) {
    const res = await baseAxios.post("user/settlement_notif/status", data);

    return res?.data || res;
  }

  static async getWalletBalance(data) {
    const res = await baseAxios.post("user/balance", data);

    return res?.data || res;
  }

  static async getWalletBalanceUpdate(data) {
    const res = await baseAxios.post("user/balance/update", data);

    return res?.data || res;
  }

  static async getTransferStatus(data) {
    const res = await baseAxios.post("user/settlement/status", data);

    return res?.data || res;
  }

  static async getUserStats(data) {
    const res = await baseAxios.post("user/stats", data);

    return res?.data || res;
  }

  static async getUserAdverts() {
    const res = await baseAxios.get("user/adverts");

    return res?.data || res;
  }
}

export default UserService;
