import React, { useEffect } from "react";

import Footer from "../../Footer";

import CreateBasketContent from "./CreateBasketContent";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";

function CreateBasketPage() {
  return (
    <div className="">
      <SignedInNavbarImproved />
      <CreateBasketContent />

      <Footer />
    </div>
  );
}

export default CreateBasketPage;
