import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { authService } from "../../services/authService";

const PrivateRoutes = ({ children }) => {
  const location = useLocation();

  if (!authService.isLogin() && location.pathname === "/home/wallet") {
    localStorage.setItem("temp_url", location.pathname + "?tab=1");
  }
  return authService.isLogin() ? children : <Navigate to="/login" />;
};

export default PrivateRoutes;
