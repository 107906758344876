import { createContext } from "react";
import { BehaviorSubject } from "rxjs";

const RxjsPlanContext = createContext({
  setAllProductPlanList: (list) => {},
  getAllProductPlan: () => {},
});

export function RxjsPlanContextProvider(props) {
  let AllProductList = new BehaviorSubject({});

  const setAllProductPlanList = (basketList) => {
    AllProductList.next(basketList);
  };

  const getAllProductPlan = () => {
    return AllProductList;
  };

  const context = {
    setAllProductPlanList: setAllProductPlanList,
    getAllProductPlan: getAllProductPlan,
  };

  return (
    <RxjsPlanContext.Provider value={context}>
      {props.children}
    </RxjsPlanContext.Provider>
  );
}

export default RxjsPlanContext;
