import React, { useEffect, useState } from "react";
import Logo from "../assets/logo.svg";
import { IoMdCart } from "react-icons/io";
import HeaderItems from "./HeaderItems";
import { BiMenuAltRight } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import MobileNavigation from "./MobileNavigation";
import { Link, useNavigate } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../store/actions";
import { logout } from "../store/slices/authSlice";
import { authService } from "../services/authService";

function Navbar() {
  let navigate = useNavigate();
  const copyCartObj = JSON.parse(localStorage.getItem("cart"));
  const [fetchedProducts, setFectchedProducts] = useState([]);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { products } = useSelector((state) => state.masterData);
  const token = authService.getCurrentUserVerificationCode();
  const corporateId = authService.getCustomerCorporateId();
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (token) {
      dispatch(logout());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (!products) {
      const data = {
        sql_ref_no: "46",
        corporateId: corporateId ? +corporateId : 1,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(getProducts(data))
        .unwrap()
        .catch((err) => err);
    }

    if (products) setFectchedProducts(products?.product_info);
  }, [dispatch, products]);

  const handleRedirect = (index, product) => {
    switch (index) {
      case "Food Baskets":
        navigate(`/home/plans/${product?.product_id}`);
        break;
      case "cart":
        navigate("/cart");
        break;
      case "GroChill":
        navigate("/butchers/create-basket");
        break;

      case "wallet":
        navigate("/home/wallet");
        break;
      case "Pick Your Basket":
        navigate("/basket/create-basket");
        break;
      case "Create Your Own Basket":
        navigate("/basket/create-basket");
        break;
      default:
        return;
    }
  };

  return (
    <header className="sticky top-0 z-40 mx-auto flex h-12 max-w-[1500px] items-center justify-between bg-white px-4 text-xs shadow-sm sm:h-16 sm:px-8 md:px-14">
      <div className="flex-shrink-0">
        <Link to="/">
          <img
            src={Logo}
            className="aspect-auto h-7 w-8 flex-shrink-0 cursor-pointer object-cover transition duration-300 hover:opacity-80 sm:h-11 sm:w-12"
            alt="logo"
          />
        </Link>
      </div>
      <div className="flex items-center space-x-2 whitespace-nowrap">
        <div className="hidden items-center space-x-1 whitespace-nowrap lg:flex xl:mr-4 ">
          <div class="relative inline-block text-left">
            <Menu>
              <div>
                <Menu.Button class="inline-flex w-full justify-center rounded-md bg-white px-5 py-2 text-base text-gray-600 hover:bg-[#F0F4E7] hover:text-olivine-500 hover:ring-[1px] hover:ring-gray-200 focus:outline-none">
                  Products
                </Menu.Button>
              </div>
              <Menu.Items class="absolute left-0 mt-1 flex w-56 origin-top-right flex-col divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-slate-500/10 focus:outline-none">
                {fetchedProducts &&
                  fetchedProducts?.map((product, index) => (
                    <div key={index} className="p-1">
                      <div className="cursor-pointer rounded px-4 py-3.5 hover:bg-gray-100">
                        <span
                          onClick={() => handleRedirect(product?.name, product)}
                          key={index}
                          class="block text-sm font-semibold text-gray-500 hover:text-gray-600 sm:text-base"
                        >
                          {product?.name}
                        </span>
                      </div>
                    </div>
                  ))}
              </Menu.Items>
            </Menu>
          </div>
          <Link to="/gift">
            <HeaderItems title="Gift a Basket" />
          </Link>
          <a href={process.env.REACT_APP_CORPORATE_LINK} target="blank">
            <HeaderItems title="Corporate" />
          </a>
          <Link to="/about">
            <div className="">
              <HeaderItems title="About" />
            </div>
          </Link>
          <Link to="/contact">
            <HeaderItems title="Contact Us" />
          </Link>
        </div>
        <div
          title="Cart"
          className="group hidden cursor-pointer rounded-full p-2 hover:bg-[#F0F4E7] hover:ring-[1px] hover:ring-gray-200 lg:inline"
        >
          <Link to="/cart">
            <span className="">
              <IoMdCart className="h-6 w-6 text-gray-600" />
            </span>
            <div className="relative">
              {copyCartObj && copyCartObj?.length > 0 ? (
                <div className="absolute -top-8 ml-4 grid h-4 w-5 rounded-full bg-red-500 text-white">
                  <div className="m-auto text-[9px] font-semibold">
                    {copyCartObj &&
                      copyCartObj?.length > 0 &&
                      copyCartObj?.length}
                  </div>
                </div>
              ) : null}
            </div>
          </Link>
        </div>
        <div className="hidden text-sm lg:flex lg:items-center">
          <Link to="/register">
            <button className="rounded-md border-[1px] border-olivine-500 px-6 py-2 text-base text-olivine-500 transition-all duration-200 hover:border-olivine-400 hover:ring-[1px] hover:ring-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-2 active:bg-olivine-100">
              Create Account
            </button>
          </Link>
          <Link to="/login">
            <button
              title="LOGIN"
              className="ml-2 rounded-md px-4 py-2 text-base text-olivine-500 transition-all duration-300 hover:bg-[#F0F4E7] hover:text-olivine-600 hover:ring-[1px] hover:ring-gray-200"
            >
              Log In
            </button>
          </Link>
        </div>
        <div onClick={() => setOpen(!open)} className="lg:hidden">
          {open ? (
            <div className="rounded-full bg-[#E8F3D4] p-1 ring-[0.5px] ring-gray-300 sm:p-2">
              <CgClose className=" h-4 w-4 cursor-pointer text-gravel-400 sm:h-5 sm:w-5" />
            </div>
          ) : (
            <BiMenuAltRight className=" h-7 w-7 cursor-pointer text-gravel-400 sm:h-8 sm:w-8" />
          )}
        </div>
      </div>
      {open && <MobileNavigation />}
    </header>
  );
}

export default Navbar;
