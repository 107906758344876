import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineCheck, AiTwotoneCheckCircle } from "react-icons/ai";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function TrackPageSidebar({ selectedOrders, clicker }) {
  const [fetchedSalesOrders, setFetchedSalesOrder] = useState([]);
  const [current, setCurrent] = useState(0);
  const [ongoingLength, setOngoingLength] = useState(0);
  const [completedLength, setCompletedLength] = useState(0);
  const [active, setActive] = useState(0);
  const navigate = useNavigate();
  const { selectedProductName } = useSelector((state) => state.modal);

  useEffect(() => {
    if (selectedOrders?.length) {
      setFetchedSalesOrder(selectedOrders);
    }
  }, [selectedOrders]);

  useEffect(() => {
    if (selectedOrders?.length) {
      const ongoing = selectedOrders?.filter(
        (so) => so?.order_info?.pickup?.job_status_id !== "2"
      );
      setOngoingLength(ongoing?.length);

      const delivered = selectedOrders?.filter(
        (so) => so?.order_info?.delivery?.job_status_id === "2"
      );
      setCompletedLength(delivered?.length);
    }
  }, [selectedOrders]);

  useEffect(() => {
    if (selectedOrders?.length) {
      switch (current) {
        case 0:
          setFetchedSalesOrder(selectedOrders);
          break;
        case 1:
          const ongoing = selectedOrders?.filter(
            (so) => so?.order_info?.pickup?.job_status_id !== "2"
          );
          setFetchedSalesOrder(ongoing);
          break;
        case 2:
          const delivered = selectedOrders?.filter(
            (so) => so?.order_info?.delivery?.job_status_id === "2"
          );
          setFetchedSalesOrder(delivered);
          break;
        default:
          break;
      }
    }
  }, [selectedOrders, current]);

  const handleActive = (idx) => {
    setActive(idx);
  };

  return (
    <div className="mt-4 h-full border-r-[2px] border-gray-200 lg:mt-0">
      <div
        onClick={() => navigate("/home")}
        className="flex cursor-pointer items-center space-x-1 px-3 xsm:px-4 sm:space-x-4 lg:-mr-5 lg:bg-olivine-500 lg:py-[7px] lg:text-white"
      >
        <div className="cursor-pointer rounded-full p-1 transition duration-300 hover:bg-gray-100 active:bg-gray-200 lg:mt-[3px] lg:hover:bg-olivine-400 lg:active:bg-olivine-600">
          <BiArrowBack className="h-5 w-5 text-gravel-500 lg:text-white" />
        </div>
        <Tippy
          content={
            <div
              className="max-w-sm space-y-1 rounded-md bg-gravel-500 p-4 text-xs text-white"
              interactive={true}
              interactiveBorder={20}
              delay={100}
            >
              <div>{selectedProductName?.planName}</div>
              <div>{`Price: ${selectedProductName?.symbol}${Number(
                selectedProductName?.planPrice
              ).toLocaleString()}`}</div>
              <div>{`Duration: (${selectedProductName?.planDuration})`}</div>
            </div>
          }
        >
          <button className="text-sm font-semibold text-gravel-500 hover:text-opacity-90 xsm:text-base sm:text-lg lg:text-xl lg:text-white">
            {selectedProductName?.planName}
          </button>
        </Tippy>
      </div>
      <div className="mt-4 lg:mt-0">
        <Tab.Group selectedIndex={current} onChange={setCurrent}>
          <Tab.List className="text-sm sm:text-base">
            <div className="grid grid-cols-3 bg-gray-100">
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected
                        ? "border-b-[3px] border-olivine-500 border-opacity-100 py-4  text-olivine-500 outline-none"
                        : "border-b-[3px] border-olivine-500 border-opacity-0 py-4 text-gray-600"
                    }
                  >
                    <div className="cursor-pointer">
                      <div className="grid justify-center font-semibold">
                        All
                      </div>
                    </div>
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected
                        ? "border-b-[3px] border-olivine-500 border-opacity-100 py-4  text-olivine-500 outline-none"
                        : "border-b-[3px] border-olivine-500 border-opacity-0 py-4 text-gray-600"
                    }
                  >
                    <div className="cursor-pointer">
                      <div className="grid justify-center font-semibold">
                        {ongoingLength && ongoingLength > 0
                          ? `${ongoingLength} On-going`
                          : "0 On-going"}
                      </div>
                    </div>
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected
                        ? "border-b-[3px] border-olivine-500 border-opacity-100 py-4  text-olivine-500 outline-none"
                        : "border-b-[3px] border-olivine-500 border-opacity-0 py-4 text-gray-600"
                    }
                  >
                    <div className="cursor-pointer">
                      <div className="grid justify-center font-semibold">
                        {completedLength && completedLength > 0
                          ? `${completedLength} Completed`
                          : "0 Completed"}
                      </div>
                    </div>
                  </button>
                )}
              </Tab>
            </div>
          </Tab.List>
          <Tab.Panels className="">
            <Tab.Panel>
              {fetchedSalesOrders && fetchedSalesOrders?.length
                ? fetchedSalesOrders?.map((salesOrder, idx) => (
                    <div key={idx} className="">
                      <div
                        onClick={() => {
                          clicker(salesOrder);
                          handleActive(idx);
                        }}
                        className={
                          active === idx
                            ? "cursor-pointer bg-[#F0F2EB] p-4"
                            : "cursor-pointer bg-white p-4"
                        }
                        // className="cursor-pointer bg-white p-4"
                        // when active the background color is bg-[#F0F2EB]
                      >
                        <div className="flex flex-col">
                          <div className="flex items-center space-x-2">
                            <div>
                              <AiOutlineCheck className="h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                              <AiTwotoneCheckCircle className="hidden h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                            </div>
                            <div className="text-sm font-semibold text-gray-700">
                              {
                                salesOrder?.order_info?.pickup
                                  ?.job_pickup_address
                              }
                            </div>
                          </div>
                          <div className="flex items-center space-x-5">
                            <div className="ml-[11px] h-14 w-[3px] bg-olivine-500"></div>
                            <div className="flex flex-col text-xs text-gray-500">
                              <div className="">
                                {
                                  salesOrder?.order_info?.pickup
                                    ?.job_pickup_address
                                }
                              </div>
                              <div>
                                {moment(
                                  salesOrder?.order_info?.pickup
                                    ?.job_pickup_datetime
                                ).format("LLL")}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center space-x-2">
                            <div>
                              {salesOrder?.order_info?.pickup?.job_status_id ===
                              "2" ? (
                                <AiOutlineCheck className="h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                              ) : (
                                <AiTwotoneCheckCircle className="h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                              )}
                            </div>
                            <div className="text-sm font-semibold text-gray-700">
                              {salesOrder?.shipping_address}
                            </div>
                          </div>
                          <div className="flex items-center space-x-5">
                            <div className="ml-[11px] mt-[2px] h-14 w-[3px]"></div>
                            <div className="flex flex-col text-xs text-gray-500">
                              <div className="">
                                {salesOrder?.shipping_address}
                              </div>
                              <div>
                                {moment(
                                  salesOrder?.order_info?.pickup
                                    ?.job_delivery_datetime
                                ).format("LLL")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="mt-6 h-[2px] w-full bg-gray-300" />
                      </div>
                    </div>
                  ))
                : null}
            </Tab.Panel>
            <Tab.Panel>
              {fetchedSalesOrders && fetchedSalesOrders?.length
                ? fetchedSalesOrders?.map((fso, idx) => (
                    <div
                      key={idx}
                      // className="cursor-pointer bg-white p-4"
                      // when active the background color is bg-[#F0F2EB]
                      // onClick={() => clicker(fso)}
                      onClick={() => {
                        clicker(fso);
                        handleActive(idx);
                      }}
                      className={
                        active === idx
                          ? "cursor-pointer bg-[#F0F2EB] p-4"
                          : "cursor-pointer bg-white p-4"
                      }
                    >
                      <div className="">
                        <div className="flex flex-col">
                          <div className="flex items-center space-x-2">
                            <div>
                              <AiOutlineCheck className="h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                              <AiTwotoneCheckCircle className="hidden h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                            </div>
                            <div className="text-sm font-semibold text-gray-700">
                              {fso?.order_info?.pickup?.job_pickup_address}
                            </div>
                          </div>
                          <div className="flex items-center space-x-5">
                            <div className="ml-[11px] h-14 w-[3px] bg-olivine-500"></div>
                            <div className="flex flex-col text-xs text-gray-500">
                              <div className="">
                                {fso?.order_info?.pickup?.job_pickup_address}
                              </div>
                              <div>
                                {moment(
                                  fso?.order_info?.pickup?.job_pickup_datetime
                                ).format("LLL")}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center space-x-2">
                            <div>
                              <AiOutlineCheck className="hidden h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                              <AiTwotoneCheckCircle className="mt-[1px] h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                            </div>
                            <div className="text-sm font-semibold text-gray-700">
                              {fso?.shipping_address}
                            </div>
                          </div>
                          <div className="flex items-center space-x-5">
                            <div className="ml-[11px] mt-[2px] h-14 w-[3px]"></div>
                            <div className="flex flex-col text-xs text-gray-500">
                              <div className="">
                                Address may be quite long and at times even
                                exceed one line
                              </div>
                              <div>
                                {moment(
                                  fso?.order_info?.pickup?.job_delivery_datetime
                                ).format("LLL")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="mt-6 h-[2px] w-full bg-gray-300" />
                      </div>
                    </div>
                  ))
                : null}
            </Tab.Panel>
            <Tab.Panel>
              {fetchedSalesOrders && fetchedSalesOrders?.length
                ? fetchedSalesOrders?.map((fso, idx) => (
                    <div
                      // onClick={() => clicker(fso)}
                      key={idx}
                      // className="cursor-pointer bg-white p-4"
                      // when active the background color is bg-[#F0F2EB]
                      onClick={() => {
                        clicker(fso);
                        handleActive(idx);
                      }}
                      className={
                        active === idx
                          ? "cursor-pointer bg-[#F0F2EB] p-4"
                          : "cursor-pointer bg-white p-4"
                      }
                    >
                      <div className="">
                        <div className="col-span-4 flex flex-col">
                          <div className="flex items-center space-x-2">
                            <div>
                              <AiOutlineCheck className="h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                              <AiTwotoneCheckCircle className="hidden h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                            </div>
                            <div className="text-sm font-semibold text-gray-700">
                              {fso?.order_info?.pickup?.job_pickup_address}
                            </div>
                          </div>
                          <div className="flex items-center space-x-5">
                            <div className="ml-[11px] h-14 w-[3px] bg-olivine-500"></div>
                            <div className="flex flex-col text-xs text-gray-500">
                              <div className="">
                                {fso?.order_info?.pickup?.job_pickup_address}
                              </div>
                              <div>
                                {moment(
                                  fso?.order_info?.pickup?.job_pickup_datetime
                                ).format("LLL")}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center space-x-2">
                            <div>
                              <AiOutlineCheck className="h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                              <AiTwotoneCheckCircle className="mt-[1px] hidden h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                            </div>
                            <div className="text-sm font-semibold text-gray-700">
                              {fso?.order_info?.shipping_address}
                            </div>
                          </div>
                          <div className="flex items-center space-x-5">
                            <div className="ml-[11px] mt-[2px] h-14 w-[3px]"></div>
                            <div className="flex flex-col text-xs text-gray-500">
                              <div className="">
                                Address may be quite long and at times even
                                exceed one line
                              </div>
                              <div>
                                {moment(
                                  fso?.order_info?.pickup?.job_delivery_datetime
                                ).format("LLL")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="mt-6 h-[2px] w-full bg-gray-300" />
                      </div>
                    </div>
                  ))
                : null}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

export default TrackPageSidebar;
