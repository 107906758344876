import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  setAmountToPay,
  setIsActivePlan,
  setIsEditing,
} from "../store/slices/modalSlice";
// import { toast } from "react-hot-toast";

function FundYourWalletPopover_Two({
  closer,
  handleRedirect,
  cart,
  handleInsufficientOption,
}) {
  const dispatch = useDispatch();
  const [input, setInput] = useState("");

  const handleChange = (event) => {
    const result = event.target.value.replace(/[^0-9|,]/gi, "");

    setInput(result);
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (!input) return;

    dispatch(setAmountToPay(input.replace(/,/g, "")));
    dispatch(setIsActivePlan(false));
    dispatch(setIsEditing(false));
    closer();
    handleRedirect("paymentOptions");
  };

  const handleNextCart = (e) => {
    e.preventDefault();
    if (!input) return;

    dispatch(setAmountToPay(input.replace(/,/g, "")));
    handleInsufficientOption();
  };

  return (
    <div className="rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold text-gray-600">Fund Your Wallet</div>
        <button
          onClick={() => closer()}
          className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="mt-4 flex justify-start text-sm font-semibold text-gravel-400">
        Amount
      </div>
      <div>
        {/* <form onSubmit={(e) => handleFundWallet(e)}> */}
        <form>
          <div className="mt-1">
            <div className="relative flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <input
                  value={input}
                  onChange={handleChange}
                  type="text"
                  placeholder="Example 9,000"
                  className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 font-semibold text-gray-700 placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>
          <div className="mt-4 font-semibold text-gravel-500">Quick Top-up</div>
          <div className="mt-2 grid grid-cols-3 gap-2 text-sm font-semibold text-gravel-500 sm:grid-cols-4 sm:gap-3">
            <div
              value={input}
              onClick={() => setInput("100000")}
              className="col-span-1 cursor-pointer rounded-full bg-[#E9F8CC] px-1 py-1 text-center text-gravel-400 ring-[1px] ring-gray-300 drop-shadow filter hover:right-1 hover:ring-olivine-500"
            >
              ₦100,000
            </div>
            <div
              value={input}
              onClick={() => setInput("50000")}
              className="col-span-1 cursor-pointer rounded-full bg-[#E9F8CC] px-1 py-1 text-center text-gravel-400 ring-[1px] ring-gray-300 drop-shadow filter hover:right-1 hover:ring-olivine-500"
            >
              ₦50,000
            </div>
            <div
              value={input}
              onClick={() => setInput("20000")}
              className="col-span-1 cursor-pointer rounded-full bg-[#E9F8CC] px-1 py-1 text-center text-gravel-400 ring-[1px] ring-gray-300 drop-shadow filter hover:right-1 hover:ring-olivine-500"
            >
              ₦20,000
            </div>
            <div
              value={input}
              onClick={() => setInput("10000")}
              className="col-span-1 cursor-pointer rounded-full bg-[#E9F8CC] px-1 py-1 text-center text-gravel-400 ring-[1px] ring-gray-300 drop-shadow filter hover:right-1 hover:ring-olivine-500"
            >
              ₦10,000
            </div>
            <div
              value={input}
              onClick={() => setInput("5000")}
              className="col-span-1 cursor-pointer rounded-full bg-[#E9F8CC] px-1 py-1 text-center text-gravel-400 ring-[1px] ring-gray-300 drop-shadow filter hover:right-1 hover:ring-olivine-500"
            >
              ₦5,000
            </div>
            <div
              value={input}
              onClick={() => setInput("1000")}
              className="col-span-1 cursor-pointer rounded-full bg-[#E9F8CC] px-1 py-1 text-center text-gravel-400 ring-[1px] ring-gray-300 drop-shadow filter hover:right-1 hover:ring-olivine-500"
            >
              ₦1,000
            </div>
          </div>
          <div className="mt-6 flex justify-end text-center tracking-widest transition-all duration-300">
            {cart ? (
              <button
                onClick={(e) => handleNextCart(e)}
                className="rounded-md bg-olivine-500 px-8 py-3 text-white hover:bg-olivine-600"
              >
                NEXT
              </button>
            ) : (
              <button
                onClick={(e) => handleNext(e)}
                className="rounded-md bg-olivine-500 px-8 py-3 text-white hover:bg-olivine-600"
              >
                NEXT
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default FundYourWalletPopover_Two;
