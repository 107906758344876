import React from "react";
import Truks from "../assets/grocedy-truks.png";
import Gift1 from "../assets/gifts-1.jpg";
import Gift2 from "../assets/gifts-2.jpg";
import Gift3 from "../assets/gifts-3.jpg";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function GiftABasketMain() {
  const navigate = useNavigate();
  const handleClick = () => {
    toast.error("Please Login to Gift a Basket");

    return navigate("/login");
  };
  return (
    <div className="mx-auto mt-10 max-w-[1600px] bg-white px-4 sm:px-8 md:px-16">
      <div className="text-2xl font-bold text-gravel-500">Gift a Basket</div>
      <div className="mt-12 grid gap-4 grid-auto-fit-xs sm:grid-auto-fit-sm lg:grid-auto-fit-md">
        <div>
          <img
            src={Gift1}
            className="h-[360px] w-auto rounded-md object-cover shadow-sm shadow-gray-400 lg:col-span-1"
            alt=""
          />
        </div>
        <div>
          <img
            src={Gift2}
            className="h-[360px] w-auto rounded-md object-cover shadow-sm shadow-gray-400 lg:col-span-1"
            alt=""
          />
        </div>
        <div>
          <img
            src={Gift3}
            className="h-[360px] w-auto rounded-md object-cover shadow-sm shadow-gray-400 lg:col-span-2"
            alt=""
          />
        </div>
      </div>
      <div className="mt-8 rounded-md bg-[#F2FAD3] p-6">
        <div className="text-lg font-bold text-gravel-500">Help a family</div>
        <div className="mt-2">
          <p className="text-xs font-normal text-gravel-500">
            Individuals, Corporate Bodies, Religious Organizations and NGOs can
            ensure that their food gifts to the hungry and needy reach the
            intended recipients by using our “Gift a Basket” subscription
            service. You can choose the Basket Category, Recipient and
            Subscription tenor. Leave everything else to us and we will ensure
            the basket gets to its intended recipient every month. It’s really
            that simple.
          </p>
          <p className="mt-2 text-sm  text-gravel-500">
            For larger gift volumes, please use the contact form and someone
            from our team will reach out to you immediately!
          </p>
        </div>
        <button
          onClick={() => handleClick()}
          className="mt-6 rounded-md bg-olivine-500 px-8 py-3 text-sm font-semibold text-white transition duration-300 hover:bg-olivine-600"
        >
          GIFT A BASKET
        </button>
      </div>
      <div className="mt-8 grid justify-center">
        <div className="grid justify-items-center">
          <div className="text-2xl font-semibold text-gravel-500">
            Contact Us
          </div>
          <div className="mt-4 max-w-xl text-center text-sm font-semibold text-gray-600">
            Do you have any questions for us, send us a message and we'll get
            back to you.
          </div>
        </div>
        <form className="mt-6">
          <div className="max-w-xl">
            <div className="">
              <label className="ml-3 text-xs">Full Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                className="w-full rounded-md border-gray-400 text-base placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              />
            </div>
            <div className="mt-6">
              <label className="ml-3 text-xs">Email Address</label>
              <input
                type="email"
                placeholder="Enter your email address"
                className="w-full rounded-md border-gray-400 text-base placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              />
            </div>
            <div className="mt-6">
              <label className="ml-3 text-xs">Subject</label>
              <input
                type="text"
                placeholder="Enter the title of your message"
                className="w-full rounded-md border-gray-400 text-base placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              />
            </div>
            <div className="mt-6">
              <label className="ml-3 text-xs">Message</label>
              <textarea
                placeholder="Write your message..."
                rows="4"
                className="w-full rounded-md border-gray-400 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              ></textarea>
            </div>
            <button className="mt-8 w-full max-w-lg rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600">
              SUBMIT MESSAGE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default GiftABasketMain;
