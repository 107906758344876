import React, { useEffect, useState } from "react";
import Banner from "../assets/Banner.png";
import { Link, useNavigate } from "react-router-dom";
import { getProducts } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import GetStarted from "./GetStarted";

import { authService } from "../services/authService";

function WelcomeToGrocedy() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fetchedProducts, setFectchedProducts] = useState([]);
  const { products } = useSelector((state) => state.masterData);
  const corporateId = authService.getCustomerCorporateId();
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (!products) {
      const data = {
        sql_ref_no: "46",
        corporateId: corporateId ? +corporateId : 1,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(getProducts(data))
        .unwrap()
        .catch((err) => err);
    }

    if (products) {
      const filterFoodBasket = products?.product_info
        ?.filter((prod) => prod?.name === "Food Baskets")
        .pop();
      setFectchedProducts(filterFoodBasket);
    }
  }, [dispatch, products]);
  return (
    <div>
      <div className="mx-auto grid max-w-[1600px] px-3 xsm:px-4 sm:px-8 md:grid-cols-2 md:items-center lg:px-16">
        <div className="mt-6 md:mt-0">
          <h1 className="text-left align-top text-2xl font-bold tracking-normal text-gravel-500 xsm:text-3xl">
            Welcome to Grocedy
          </h1>
          <div className="mt-2 text-sm font-normal text-gray-700">
            <p>
              Welcome to Grocedy - the ultimate platform for your food-shopping
              requirements. Our mission is to simplify your grocery shopping
              experience by providing you with hassle-free and convenient
              services. Unlike other grocery stores, we do not sell everything,
              which allows us to concentrate on providing our customers with
              great prices for the things we do sell.
            </p>
            <p>
              We pride ourselves on offering a bulk-buying process that merges
              funds from our users, allowing us to purchase directly from
              farmers, aggregators, and manufacturers to bring you the best
              prices possible. You can also choose from a variety of
              subscription plans that cater to your unique requirements and
              preferences, making it easy to customize your shopping basket.
            </p>
            <p>
              Our platform is designed to make your life simple and convenient.
              In addition to grocery shopping, you can also save towards your
              food purchases, pay bills, top-up your mobile phones and earn
              rewards along the way.
            </p>
            <p>
              Our curated selection of products makes it easy for you to
              stock-up on what you need with a few clicks. Whether you're
              looking for a one-time purchase or a recurring subscription,
              Grocedy's easy-to-use interface lets you shop quickly and easily.
            </p>
            <p>
              So say goodbye to the hassle of waiting in long queues at the
              grocery store and hello to effortless grocery
              shopping with Grocedy!
            </p>
          </div>
          <div className="mt-8 space-y-4 sm:mt-10 sm:flex sm:space-x-4 sm:space-y-0">
            <Link to="/register">
              <div className="">
                <button className="w-full transform cursor-pointer rounded-md bg-olivine-500 py-4 text-xs font-semibold text-white transition-all duration-200 hover:-translate-y-0.5 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 xsm:text-sm sm:w-auto sm:px-8 sm:py-3">
                  Create Account
                </button>
              </div>
            </Link>
            <div className="">
              <button
                onClick={() =>
                  navigate(`/home/plans/${fetchedProducts?.product_id}`)
                }
                className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 py-[15px] text-xs font-semibold text-olivine-500 transition-all duration-200 hover:-translate-y-0.5 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 xsm:text-sm sm:w-auto sm:px-8 sm:py-[11px]"
              >
                View Plans
              </button>
            </div>
          </div>
        </div>
        <div>
          <motion.img
            src={Banner}
            className="pt-12"
            alt="baner"
            animate={{ scale: 1 }}
            initial={{ scale: 0.8 }}
            transition={{ stiffness: 200, type: "spring" }}
          />
        </div>
      </div>
      <GetStarted />
    </div>
  );
}

export default WelcomeToGrocedy;
