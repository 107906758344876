import React, { useEffect, useState } from "react";
import CreateBasketCard from "./CreateBasketCard";
import PlansCard from "./PlansCard";
import { getProducts } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { setRate } from "../store/slices/generalSlice";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";

function Plans() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const corporateId = authService.getCustomerCorporateId();
  const customerId = authService.getCurrentUserCustomerId();

  const { products } = useSelector((state) => state.masterData);

  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [fetchedImages, setFetchedImages] = useState([]);
  const [fetchedPlansUsd, setFetchedPlansUsd] = useState([]);
  const [usdPlan, setUsdPlan] = useState({});
  const [fetchedProducts, setFectchedProducts] = useState({});
  const [selected_plan, setSelected_plan] = useState("");
  const [isShowingNGR, setIsShowingNGR] = useState(true);
  const [isShowingUSD, setIsShowingUSD] = useState(false);

  useEffect(() => {
    if (!products) {
      const data = {
        sql_ref_no: "46",
        corporateId: corporateId ? +corporateId : 1,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(getProducts(data))
        .unwrap()
        .catch((err) => {
          return err;
        });
    }
  }, [dispatch, products, navigate]);

  useEffect(() => {
    if (products) {
      const filterProductId = products?.product_info
        ?.filter((pp) => pp?.name === "Food Baskets")
        .pop();
      localStorage.setItem("selected_plan", filterProductId?.product_id);
      setFectchedProducts(products);
    }
  }, [products]);

  useEffect(() => {
    if (fetchedProducts) {
      const selectedPlan =
        fetchedProducts?.product_info?.length &&
        fetchedProducts?.product_info?.filter(
          (sp) => sp.name === "Food Baskets"
        );

      setSelected_plan(selectedPlan?.length ? selectedPlan[0]?.product_id : "");
    }
  }, [fetchedProducts]);

  useEffect(() => {
    if (
      fetchedProducts?.plan_info?.length &&
      fetchedProducts?.Plan_Image?.length
    ) {
      const filteredPlans =
        fetchedProducts?.plan_info?.length &&
        fetchedProducts?.plan_info?.filter(
          (plan) => plan?.product_id === selected_plan
        );

      setFetchedPlans(filteredPlans);

      const filteredImages =
        fetchedProducts?.Plan_Image?.length &&
        fetchedProducts?.Plan_Image?.filter(
          (image) => image?.product_id === selected_plan
        );

      setFetchedImages(filteredImages);

      // localStorage.setItem("selected_plan", selected_plan);
    }
  }, [dispatch, selected_plan, fetchedProducts]);

  useEffect(() => {
    if (fetchedProducts && fetchedProducts?.currency_info?.length)
      setFetchedPlansUsd(fetchedProducts?.currency_info);
  }, [fetchedProducts]);

  useEffect(() => {
    if (fetchedPlansUsd?.length) {
      const usdConvert = fetchedPlansUsd?.filter(
        (fpu) => fpu.currency_code === "USD"
      );
      setUsdPlan(usdConvert);
    }
  }, [fetchedPlansUsd]);

  useEffect(() => {
    if (usdPlan?.length && isShowingUSD) {
      dispatch(setRate(usdPlan));
    }

    if (isShowingNGR) dispatch(setRate(""));
  }, [usdPlan, isShowingNGR, dispatch, isShowingUSD]);

  const handleSwitch = (currency) => {
    switch (currency) {
      case "NGR":
        setIsShowingUSD(false);
        setIsShowingNGR(true);
        break;
      case "USD":
        setIsShowingNGR(false);
        setIsShowingUSD(true);
        break;
      default:
        return;
    }
  };

  const handleView = (location) => {
    switch (location) {
      case "GROBP":
        return navigate(`/home/plan/${location}`);
      case "GROSP":
        return navigate(`/home/plan/${location}`);
      case "GROGP":
        return navigate(`/home/plan/${location}`);
      case "GRODP":
        return navigate(`/home/plan/${location}`);
      case "GROPP":
        return navigate(`/home/plan/${location}`);
      case "home":
        return navigate("/home");
      default:
        return;
    }
  };

  const handleCart = (id) => {
    navigate(`/home/cart/${id}`);
  };

  return (
    <div className="mx-auto mt-16 max-w-[1600px] px-3 xsm:px-4 sm:px-8 md:px-10 lg:px-16">
      <div className="text-2xl font-bold text-gravel-500">Plans</div>
      {/* <div className="mt-2 flex cursor-pointer items-center font-medium">
        <div
          onClick={() => handleSwitch("NGR")}
          className={
            isShowingNGR
              ? "rounded-l-md bg-olivine-500 px-5 py-3 text-white"
              : "rounded-l-md bg-caper-500 px-5 py-3 text-gravel-500"
          }
        >
          NGN
        </div>
        <div
          onClick={() => handleSwitch("USD")}
          className={
            !isShowingUSD
              ? "rounded-r-md bg-caper-500 px-5 py-3 text-gravel-500"
              : "rounded-r-md bg-olivine-500 px-5 py-3 text-white"
          }
        >
          USD
        </div>
      </div> */}
      <div className="mt-8 grid gap-6 grid-auto-fit-md xsm:grid-auto-fit-lg sm:gap-8">
        {fetchedPlans &&
          isShowingNGR &&
          !isShowingUSD &&
          fetchedPlans?.length &&
          fetchedPlans?.map((pb, idx) => (
            <PlansCard
              key={idx}
              product={pb?.name}
              price={pb?.recurring_price}
              quantity={pb?.description}
              plan_id={pb?.plan_id}
              images={fetchedImages}
              clicked={() => handleView(pb.plan_code)}
              goToCart={() => handleCart(pb.plan_code)}
            />
          ))}

        {fetchedPlans &&
          fetchedPlans?.length &&
          !isShowingNGR &&
          isShowingUSD &&
          fetchedPlans?.map((pb, idx) => (
            <PlansCard
              key={idx}
              product={pb?.name}
              price={pb?.recurring_price}
              quantity={pb?.description}
              plan_id={pb?.plan_id}
              images={fetchedImages}
              rate={usdPlan[0]?.exchange_rate}
              clicked={() => handleView(pb.plan_code)}
              goToCart={() => handleCart(pb.plan_code)}
            />
          ))}
        <CreateBasketCard />
      </div>
    </div>
  );
}

export default Plans;
