import { createSlice } from "@reduxjs/toolkit";
import {
  activePlans,
  addBvn,
  addDelivery,
  addNin,
  addPhonenumber,
  bvnVerification,
  cancelPlan,
  cartItemDelete,
  createTransactionPin,
  deleteCorporateId,
  documentUploadGet,
  downgradePlan,
  fetchCountries,
  fetchRecommendations,
  getBillerInfos,
  getBvn,
  getCartProductPlan,
  getDelivery,
  getDeliveryData,
  getDocumentFileId,
  getDocumentUpload,
  getNin,
  getPlan,
  getPlans,
  getProductPlans,
  getProducts,
  getUserInfo,
  getUserProfile,
  getUserProfileSet,
  productPurchaseGet,
  productPurchaseSet,
  rateShipping,
  rateShopping,
  rateShoppingSubscription,
  rateVasPaybillsShopping,
  rateVasShopping,
  reactivatePlan,
  todoListStatus,
  updateTransactionPin,
  upgradePlan,
  uploadCorporateId,
  verifyPhonenumber,
  verifyTransactionPinCode,
  showAdsInfo,
  hideAdsInfo,
  showSettingsAdsInfo,
  toggleSettingsAdsInfo,
} from "../actions";

const initialState = {
  phoneNumber: "",
  verifyPhonenumber: "",
  fetchCountriesData: "",
  addDeliverySet: "",
  addBvnSet: "",
  addNinSet: "",
  nin: "",
  todoListStatusData: "",
  userProfile: "",
  userProfileSet: "",
  transactionPin: "",
  transactionPinVerify: "",
  documentFileId: "",
  documentUpload: "",
  uploadedDocuments: "",
  userInfo: "",
  plans: "",
  plan: "",
  products: "",
  productPlans: "",
  cartProductPlan: "",
  productPurchase: "",
  cart: "",
  itemDelete: "",
  getDeliveryItems: "",
  billerInfos: "",
  activePlansCustomer: "",
  deliverySalesData: {},
  cancelPlanCustomer: "",
  reactivatePlanCustomer: "",
  upgradePlanCustomer: "",
  downgradePlanCustomer: "",
  newUpdatePin: "",
  bvnGet: "",
  bvnVerify: "",
  shoppingRate: "",
  shoppingRateSubscription: "",
  shippingRate: "",
  shoppingVasPaybillsRate: "",
  shoppingVasRate: "",
  uploadedCorporateId: "",
  deletedCorporateId: "",
  recommendations: "",
  showAds: "",
  hideAds: "",
  showSettingsAds: "",
  toggleAds: "",
};

const MasterDataSlice = createSlice({
  name: "MasterData",
  initialState,
  reducers: {
    resetMasterdata(state) {
      state.phoneNumber = undefined;
      state.verifyPhonenumber = undefined;
      state.fetchCountriesData = undefined;
      state.addDeliverySet = undefined;
      state.addBvnSet = undefined;
      state.addNinSet = undefined;
      state.nin = undefined;
      state.uploadedDocuments = undefined;
      state.billerInfos = undefined;
      state.activePlansCustomer = undefined;
      state.cancelPlanCustomer = undefined;
      state.reactivatePlanCustomer = undefined;
      state.upgradePlanCustomer = undefined;
      state.downgradePlanCustomer = undefined;
      state.newUpdatePin = undefined;
      state.bvnVerify = null;

      state.todoListStatusData = undefined;
      state.userProfile = undefined;
      state.userProfileSet = undefined;
      state.transactionPin = undefined;
      state.transactionPinVerify = undefined;
      state.documentFileId = undefined;
      state.documentUpload = undefined;
      state.deliverySalesData = undefined;

      state.userInfo = undefined;
      state.plans = undefined;
      state.plan = undefined;
      state.products = undefined;
      state.productPlans = undefined;
      state.cartProductPlan = undefined;

      state.productPurchase = undefined;
      state.cart = undefined;
      state.itemDelete = undefined;
      state.getDeliveryItems = undefined;

      state.bvnGet = null;
      state.shippingRate = null;
      state.shoppingRate = null;
      state.shoppingVasPaybillsRate = null;
      state.shoppingVasRate = null;

      state.shoppingRateSubscription = null;
      state.uploadedCorporateId = null;
      state.deletedCorporateId = null;

      state.showAds = null;
      state.hideAds = null;
      state.showSettingsAds = null;
      state.toggleAds = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addPhonenumber.fulfilled, (state, { payload }) => {
      state.phoneNumber = payload;
    });

    builder.addCase(verifyPhonenumber.fulfilled, (state, { payload }) => {
      state.verifyPhonenumber = payload;
    });

    builder.addCase(fetchCountries.fulfilled, (state, { payload }) => {
      state.fetchCountriesData = payload;
    });

    builder.addCase(addDelivery.fulfilled, (state, { payload }) => {
      state.addDeliverySet = payload;
    });

    builder.addCase(getDelivery.fulfilled, (state, { payload }) => {
      state.getDeliveryItems = payload;
    });

    builder.addCase(documentUploadGet.fulfilled, (state, { payload }) => {
      state.uploadedDocuments = payload;
    });

    builder.addCase(activePlans.fulfilled, (state, { payload }) => {
      state.activePlansCustomer = payload;
    });

    builder.addCase(addBvn.fulfilled, (state, { payload }) => {
      state.addBvnSet = payload;
    });

    builder.addCase(addNin.fulfilled, (state, { payload }) => {
      state.addNinSet = payload;
    });

    builder.addCase(getNin.fulfilled, (state, { payload }) => {
      state.nin = payload;
    });

    builder.addCase(todoListStatus.fulfilled, (state, { payload }) => {
      state.todoListStatusData = payload;
    });

    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      state.userProfile = payload;
    });

    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      state.userInfo = payload;
    });

    builder.addCase(getUserProfileSet.fulfilled, (state, { payload }) => {
      state.userProfileSet = payload;
    });

    builder.addCase(updateTransactionPin.fulfilled, (state, { payload }) => {
      state.newUpdatePin = payload;
    });

    builder.addCase(createTransactionPin.fulfilled, (state, { payload }) => {
      state.transactionPin = payload;
    });

    builder.addCase(
      verifyTransactionPinCode.fulfilled,
      (state, { payload }) => {
        state.transactionPinVerify = payload;
      }
    );

    builder.addCase(getDocumentFileId.fulfilled, (state, { payload }) => {
      state.documentFileId = payload;
    });

    builder.addCase(getDocumentUpload.fulfilled, (state, { payload }) => {
      state.documentUpload = payload;
    });

    builder.addCase(getPlans.fulfilled, (state, { payload }) => {
      state.plans = payload;
    });

    builder.addCase(getPlan.fulfilled, (state, { payload }) => {
      state.plan = payload;
    });

    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      state.products = payload;
    });

    builder.addCase(getProductPlans.fulfilled, (state, { payload }) => {
      state.productPlans = payload;
    });

    builder.addCase(getCartProductPlan.fulfilled, (state, { payload }) => {
      state.cartProductPlan = payload;
    });

    builder.addCase(productPurchaseSet.fulfilled, (state, { payload }) => {
      state.productPurchase = payload;
    });

    builder.addCase(productPurchaseGet.fulfilled, (state, { payload }) => {
      state.cart = payload;
    });

    builder.addCase(cartItemDelete.fulfilled, (state, { payload }) => {
      state.itemDelete = payload;
    });

    builder.addCase(getBillerInfos.fulfilled, (state, { payload }) => {
      state.billerInfos = payload;
    });

    builder.addCase(getDeliveryData.fulfilled, (state, { payload }) => {
      state.deliverySalesData = payload;
    });

    builder.addCase(cancelPlan.fulfilled, (state, { payload }) => {
      state.cancelPlanCustomer = payload;
    });

    builder.addCase(reactivatePlan.fulfilled, (state, { payload }) => {
      state.reactivatePlanCustomer = payload;
    });

    builder.addCase(upgradePlan.fulfilled, (state, { payload }) => {
      state.upgradePlanCustomer = payload;
    });

    builder.addCase(downgradePlan.fulfilled, (state, { payload }) => {
      state.downgradePlanCustomer = payload;
    });

    builder.addCase(getBvn.fulfilled, (state, { payload }) => {
      state.bvnGet = payload;
    });

    builder.addCase(bvnVerification.fulfilled, (state, { payload }) => {
      state.bvnVerify = payload;
    });

    builder.addCase(rateShipping.fulfilled, (state, { payload }) => {
      state.shippingRate = payload;
    });

    builder.addCase(rateShopping.fulfilled, (state, { payload }) => {
      state.shoppingRate = payload;
    });

    builder.addCase(rateVasPaybillsShopping.fulfilled, (state, { payload }) => {
      state.shoppingVasPaybillsRate = payload;
    });

    builder.addCase(rateVasShopping.fulfilled, (state, { payload }) => {
      state.shoppingVasRate = payload;
    });

    builder.addCase(uploadCorporateId.fulfilled, (state, { payload }) => {
      state.uploadedCorporateId = payload;
    });

    builder.addCase(deleteCorporateId.fulfilled, (state, { payload }) => {
      state.deletedCorporateId = payload;
    });

    builder.addCase(
      rateShoppingSubscription.fulfilled,
      (state, { payload }) => {
        state.shoppingRateSubscription = payload;
      }
    );

    builder.addCase(fetchRecommendations.fulfilled, (state, { payload }) => {
      state.recommendations = payload;
    });

    builder.addCase(hideAdsInfo.fulfilled, (state, { payload }) => {
      state.hideAds = payload;
    });

    builder.addCase(showAdsInfo.fulfilled, (state, { payload }) => {
      state.showAds = payload;
    });

    builder.addCase(showSettingsAdsInfo.fulfilled, (state, { payload }) => {
      state.showSettingsAds = payload;
    });

    builder.addCase(toggleSettingsAdsInfo.fulfilled, (state, { payload }) => {
      state.toggleAds = payload;
    });
  },
});

const { reducer, actions } = MasterDataSlice;

export const { resetMasterdata } = actions;

export default reducer;
