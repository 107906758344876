import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineDownload, AiOutlineSave } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import RateYourOrderIcon from "../assets/rateYourOrderIcon.svg";
import { IoChevronForwardSharp } from "react-icons/io5";
import { Box, Modal } from "@mui/material";
import FeedBackRatingVasPopup from "./Rating/FeedBackRatingVasPopup";
import StarRatings from "react-star-ratings";
import { setSelectedReview } from "../store/slices/modalSlice";

function TransactionDetailsDownloads() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { transaction } = useSelector((state) => state.modal);

  const rating =
    transaction && transaction?.rating_value
      ? Number(transaction?.rating_value)
      : 0;

  return (
    <div className="bg-primary_color">
      <div className="mx-auto w-full max-w-3xl pb-40 pt-10 text-xs xsm:text-sm md:text-base">
        <div className="flex items-center space-x-4 sm:space-x-8">
          <div
            onClick={() => navigate(-1)}
            className="h-fit w-fit rounded-full  p-1 hover:bg-gray-200"
          >
            <BiArrowBack className="h-6 w-6 cursor-pointer text-gray-600" />
          </div>
          <h1 className="text-lg font-bold text-gravel-600 sm:text-2xl">
            Transaction Details
          </h1>
        </div>
        <div className="mt-10 rounded-xl bg-white pb-16 pt-8 ">
          <div className="space-y-8 px-4 sm:px-6 md:px-8">
            <div className="flex items-center space-x-2">
              <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
                Transaction Date:
              </p>
              <p>
                {transaction &&
                  transaction?.transaction_date &&
                  moment(transaction?.transaction_date).format("MMM Do YYYY")}
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
                Transaction Type:
              </p>
              <p>
                {transaction &&
                  transaction?.transaction_type_name &&
                  transaction?.transaction_type_name}
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
                Amount:
              </p>
              <p>
                {transaction &&
                  ` ₦ ${Number(transaction?.amount).toLocaleString()}`}
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
                Convenience Fee:
              </p>
              <p>
                {transaction
                  ? `₦${Number(
                      transaction?.convience_fees_amount
                    ).toLocaleString()}`
                  : null}
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
                Narration:
              </p>
              <p>{transaction && transaction?.narration}</p>
            </div>
            <div className="flex items-center space-x-2">
              <p className="w-28 whitespace-nowrap font-semibold sm:w-36 md:w-64">
                <span className="sm:hidden">Reference No:</span>
                <span className="hidden sm:inline">Reference Number:</span>
              </p>
              <p className="whitespace-pre-wrap">
                {transaction && transaction?.ref_no}
              </p>
            </div>
          </div>
          {transaction?.narration === "Pay Bills" ||
          transaction?.narration === "Buy Data" ||
          transaction?.narration === "Buy Airtime" ? (
            <div
              onClick={() => setOpen(true)}
              className="mt-8 flex cursor-pointer items-center justify-between border-y-[1px] border-gray-200 px-4 py-5 hover:bg-gray-100 sm:px-6 md:px-8"
            >
              <div className="flex flex-row items-center space-x-2">
                <div>
                  <img src={RateYourOrderIcon} alt="icon" className="h-7 w-7" />
                </div>
                <div className="">
                  <h3 className="text-sm font-semibold text-gray-700">
                    Rate your Purchase
                  </h3>
                  {transaction?.rating_value && (
                    <div className="mt-1 flex items-center space-x-2">
                      <p className="text-sm font-medium text-gray-400">
                        You rated your Experience
                      </p>
                      <div className="flex flex-row items-center space-x-1">
                        <StarRatings
                          rating={rating}
                          starRatedColor="#ffce31"
                          numberOfStars={5}
                          name="rating"
                          starDimension="10px"
                          starSpacing="2px"
                        />
                      </div>
                    </div>
                  )}
                  {transaction?.rating_value && (
                    <p
                      onClick={() => {
                        navigate("/rating-comments");
                        dispatch(setSelectedReview(transaction));
                      }}
                      className="text-sm font-medium text-olivine-600 hover:underline hover:underline-offset-2"
                    >
                      View Comment
                    </p>
                  )}
                </div>
              </div>
              <IoChevronForwardSharp className="h-6 w-6 text-gray-500" />
            </div>
          ) : null}

          <div className="mt-8 space-y-6 px-4 sm:px-6 md:px-8">
            <div
              onClick={() => {
                navigate("/home/transaction/pdf");
              }}
              className="cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F0F7E1] py-2 transition duration-300 hover:ring-[0.5px] hover:ring-olivine-500"
            >
              <div className="flex items-center justify-center space-x-2 font-semibold text-olivine-500">
                <div>
                  <AiOutlineDownload className="h-5 w-5" />
                </div>
                <div>Download as pdf</div>
              </div>
            </div>
            {/* </Link> */}
            <div className="cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F0F7E1] py-2 transition duration-300 hover:ring-[0.5px] hover:ring-olivine-500">
              <div className="flex items-center justify-center space-x-2 font-semibold text-olivine-500">
                <div>
                  <AiOutlineSave className="h-5 w-5" />
                </div>
                <div>Save as image</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-6 overflow-hidden px-2 xsm:px-4">
          <Box className="mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <FeedBackRatingVasPopup setOpen={setOpen} detail={transaction} />
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default TransactionDetailsDownloads;
