import React from "react";
import TestimonialComponent from "./TestimonialComponent";
import Marquee from "react-fast-marquee";

const datas = [
  {
    name: "Mrs. Oyin Famakinwa",
    message:
      "I have two growing children, and I used to spend a lot on food. Budget ke? Something that you will use to go to the market and they will tell you things don't cost. Since I subscribed to Grocedy Premium, all that wahala has stopped. If I say it's 50k, I want to be spending on food, it doesn't change. Let markets be doing the one that concerns them.",
  },
  {
    name: "Papa Emeka",
    message:
      "I'm always in the shop, and I don't normally have time to go around to buy things. But Grocedy agents bring my food to my shop. My wife doesn't have to stress herself, and I'm always sure there will be food on the table. Life is sweet with Grocedy. You people are doing well.",
  },
  {
    name: "Cynthia Umezuru",
    message:
      "Ever since I started using Grocedy, Lagos market stress has stopped being my portion. I just sit at home, and my foodstuff comes to me. I have never regretted my",
  },
  {
    name: "Chi chi",
    message:
      "I used to go to the market after work until i discovered grocedy, it's easier for me now to get everything i want in bulk delivered to my house.",
  },
  {
    name: "Emeka",
    message:
      "At Least grocedy made it possible for me to pay as i earned, this is what excites me about grocedy and the fact that i buy cheaper than the market price.",
  },
  {
    name: "Shorey",
    message:
      "The most interesting thing about using this grocedy is that I pay as I get money so it has really helped me alot  .",
  },
];

function Testimonial() {
  return (
    <div className="mt-32 bg-primary_color">
      <h1 className="text-center text-xl font-bold text-gravel-600 xsm:text-2xl sm:text-3xl">
        What <span className="text-olivine-600">Customers</span> say about us
      </h1>
      <p className="mt-2 text-center text-base font-bold text-gravel-400">
        What our customers say about Grocedy
      </p>
      <div className="mt-6">
        <Marquee speed={60} gradient={false} pauseOnHover>
          {datas.map((data, index) => {
            return <TestimonialComponent {...data} />;
          })}
        </Marquee>
      </div>
    </div>
  );
}

export default Testimonial;
