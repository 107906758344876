import React from "react";
import { motion } from "framer-motion";

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const loadingCircleVariantsOne = {
  start: {
    x: "0%",
  },
  end: {
    x: "100%",
  },
};

const loadingCircleVariantsTwo = {
  start: {
    x: "100%",
  },
  end: {
    x: "0%",
  },
};

const loadingCircleVariantsThree = {
  start: {
    x: "200%",
  },
  end: {
    x: "300%",
  },
};

const loadingCircleVariantsFour = {
  start: {
    x: "300%",
  },
  end: {
    x: "200%",
  },
};

const loadingCircleVariantsFive = {
  start: {
    x: "500%",
  },
  end: {
    x: "400%",
  },
};

const loadingCircleVariantsSix = {
  start: {
    x: "400%",
  },
  end: {
    x: "500%",
  },
};
const loadingCircleTransition = {
  duration: 0.2,
  yoyo: Infinity,
  ease: "easeInOut",
};

function IsLoadingFour() {
  return (
    <div className="z-40">
      <motion.div
        className="flex h-4 w-[77px] flex-col rounded border border-olivine-200 pl-[1px] pt-[1px]"
        variants={loadingContainerVariants}
        initial="start"
        animate="end"
      >
        <motion.span
          className="h-3 w-3 rounded-sm bg-olivine-500 opacity-40"
          variants={loadingCircleVariantsOne}
          transition={loadingCircleTransition}
        />
        <motion.span
          className="-mt-3 h-3 w-3 rounded-sm bg-olivine-500 opacity-80"
          variants={loadingCircleVariantsTwo}
          transition={loadingCircleTransition}
        />
        <motion.span
          className="-mt-3 h-3 w-3 rounded-sm bg-olivine-500 opacity-50"
          variants={loadingCircleVariantsThree}
          transition={loadingCircleTransition}
        />
        <motion.span
          className="-mt-3 h-3 w-3 rounded-sm bg-olivine-500 opacity-80"
          variants={loadingCircleVariantsFour}
          transition={loadingCircleTransition}
        />
        <motion.span
          className="-mt-3 h-3 w-3 rounded-sm bg-olivine-500 opacity-40"
          variants={loadingCircleVariantsFive}
          transition={loadingCircleTransition}
        />
        <motion.span
          className="-mt-3 h-3 w-3 rounded-sm bg-olivine-500 opacity-80"
          variants={loadingCircleVariantsSix}
          transition={loadingCircleTransition}
        />
      </motion.div>
    </div>
  );
}

export default IsLoadingFour;
