import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default function Sort({onSortChange}) {
  const [currentSort, setCurrentSort] = useState('SORT');



  function onSortSelect(name, code){
    setCurrentSort(name);
    onSortChange(code);
  }


  return (
    <div className="  ">
      <Menu as="div"  className="relative inline-block z-50 text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md  px-4  text-sm font-medium text-[#45444B] hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            {currentSort}
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-olivine-500 font-bold hover:text-olivine-600"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 h-80 w-56 overflow-y-scroll origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md shadow-gray-400 ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className=" py-1   ">
              <Menu.Item>
                {({ active }) => (
                  <button
                  onClick={()=> onSortSelect('BEST SELLING', 1)}
                    className={`${
                      active ? 'bg-[#F1F1F1] w-full text-gray-600' : 'text-gray-600'
                    } group w-full items-center pr-4 py-3 flex justify-end text-sm`}
                  >
                   
                   BEST SELLING
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                  onClick={()=> onSortSelect('FEATURED', 2)}
                    className={`${
                      active ? 'bg-[#F1F1F1] w-full text-gray-600' : 'text-gray-600'
                    } group w-full items-center pr-4 py-3 flex justify-end text-sm`}
                  >
                   
                   FEATURED
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                  onClick={()=> onSortSelect('LOWEST PRICE', 3)}
                    className={`${
                      active ? 'bg-[#F1F1F1] w-full text-gray-600' : 'text-gray-600'
                    } group w-full items-center pr-4 py-3 flex justify-end text-sm`}
                  >
                   
                   LOWEST PRICE
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                  onClick={()=> onSortSelect('HIGHEST PRICE', 4)}
                    className={`${
                      active ? 'bg-[#F1F1F1] w-full text-gray-600' : 'text-gray-600'
                    } group w-full items-center pr-4 py-3 flex justify-end text-sm`}
                  >
                   
                   HIGHEST PRICE
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                  onClick={()=> onSortSelect('ALPHABETICALLY, A-Z', 5)}
                    className={`${
                      active ? 'bg-[#F1F1F1] w-full text-gray-600' : 'text-g00'
                    } group w-full items-center pr-4 py-3 flex justify-end text-sm`}
                  >
                   
                   ALPHABETICALLY, A-Z
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                  onClick={()=> onSortSelect('ALPHABETICALLY, Z-A', 6)}
                    className={`${
                      active ? 'bg-[#F1F1F1] w-full text-gray-600' : 'text-g00'
                    } group w-full items-center pr-4 py-3 flex justify-end text-sm`}
                  >
                   
                   ALPHABETICALLY, Z-A
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                  onClick={()=> onSortSelect('NEW PRODUCTS', 7)}
                    className={`${
                      active ? 'bg-[#F1F1F1] w-full text-gray-600' : 'text-gray-600'
                    } group w-full items-center pr-4 py-3 flex justify-end text-sm`}
                  >
                   
                   NEW PRODUCTS
                  </button>
                )}
              </Menu.Item>
          
              <Menu.Item>
                {({ active }) => (
                  <button
                  onClick={()=> onSortSelect('SORT', 8)}
                    className={`${
                      active ? 'bg-[#F1F1F1] w-full text-gray-600' : 'text-gray-600'
                    } group w-full items-center pr-4 py-3 flex justify-end text-sm`}
                  >
                   
                   CLEAR FILTER
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
