

import baseAxios from "../core/api/axios/baseAxios";


  function getProductList(data) {
    return baseAxios.post("master-data/products", data);

  }


  export const productService  = {
    getProductList,
    
    }
