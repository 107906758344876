import React, { useState, useEffect } from "react";
import { FaWallet, FaMoneyBill, FaPlus } from "react-icons/fa";
import { RiRefundLine } from "react-icons/ri";
import { IoCopy } from "react-icons/io5";
import { GiSmartphone } from "react-icons/gi";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import SignedInNavbarImproved from "./SignedInNavbarImproved";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import FundYourWalletPopoverTwo from "./FundYourWalletPopover_Two";
import PayBillsPopoverTwo from "./PayBillsPopover_Two";
import BuyAirtimeAndDataPopover from "./BuyAirtimeAndDataPopover";
import SavingsSettingsPopover from "./SavingsSettingsPopover";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import IsLoadingFour from "./IsloadingFour";
import {
  billerGroup,
  cancelRecurringPlan,
  createAccountNumber,
  getAutoFund,
  getTransactions,
  getUserInfo,
  getWalletBalance,
  verifyPaymentRecurr,
} from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Modal from "./Ui";
import { toast } from "react-hot-toast";
import { authService } from "../services/authService";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  setIsActivePlan,
  setIsEditing,
  setTransaction,
  setWalletUpdate,
} from "../store/slices/modalSlice";
import { CircularProgress } from "@mui/material";
import { BsInfo } from "react-icons/bs";
import RedeemVoucherWalletButton from "./voucher/redeemVoucher/RedeemVoucherWalletButton";
import FundWalletIcon from "../assets/fundWalletIcon.svg";
import BillPaymentIcon from "../assets/billsPaymentIcon.svg";
import AirtimeAndDataIcon from "../assets/airtimeAndDataIcon.svg";
import RewardBankIcon from "../assets/rewardBankIcon.svg";
import CreditScoreIcon from "../assets/creditScoreIcon.svg";
import { Dialog } from "@headlessui/react";

function Wallet() {
  let navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [enabled, setEnabled] = useState(false);
  const [fetchedUserBalance, setFetchedUserBalance] = useState();
  const [fetchedTransactions, setFetchedTransactions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTransact, setIsLoadingTransact] = useState(false);
  const [fectcheUserProfile, setFectcheUserProfile] = useState({});
  const [fetchedActiveRecuringFund, setFetchedActiveRecuringFund] = useState(
    {}
  );

  const [fetchedCancelledRecuringFund, setFetchedCancelledRecuringFund] =
    useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const [recurringNextDate, setRecurringNextDate] = useState("");
  const [prvAccountNo, setPrvAccountNo] = useState("");
  const [wemaAccountNo, setWemaAccountNo] = useState("");
  const [sterlingAccNo, setSterlingAccNo] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [fetchedAutoFund, setFetchedAutoFund] = useState({});

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();

  const { search } = useLocation();

  const PARAMS_TAB = Number(search.split("=")[1]);
  const [currentTab, setCurrentTab] = useState(PARAMS_TAB);

  const dispatch = useDispatch();
  const { walletBalance } = useSelector((state) => state.user);
  const { userInfo } = useSelector((state) => state.masterData);
  const { biller } = useSelector((state) => state.vas);
  const { transactions, autoFund } = useSelector((state) => state.payment);
  const { updateWallet } = useSelector((state) => state.modal);

  const akant =
    fectcheUserProfile &&
    fectcheUserProfile?.customer_info?.length &&
    fectcheUserProfile?.customer_info[0]?.providus_bank_account_number;

  const trxref = queryParams.get("trxref");
  const reference = queryParams.get("reference");
  const recurr_type = localStorage.getItem("recurr_type");

  useEffect(() => {
    if ((trxref || reference) && recurr_type) {
      const data = {
        type: recurr_type,
        ref: trxref ? trxref : reference,
      };

      dispatch(verifyPaymentRecurr(data))
        .unwrap()
        .then(() => {
          getAutoFunds();
          return navigate("/home/wallet");
        })
        .catch((err) => {
          return err;
        });
    }
  }, [trxref, reference]);

  useEffect(() => {
    if (!userInfo) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "7",
      };

      dispatch(getUserInfo(data));
    }

    if (userInfo) setFectcheUserProfile(userInfo);
  }, [dispatch, userInfo, customerId, userId]);

  useEffect(() => {
    if (!autoFund) {
      getAutoFunds();
    }

    if (autoFund) {
      setFetchedAutoFund(autoFund?.wallet_info);
    }
  }, [autoFund, userId, customerId, dispatch, verification_code]);

  useEffect(() => {
    if (fetchedAutoFund?.length) {
      const cancelledAutoFund = fetchedAutoFund?.filter(
        (cancelled) => cancelled?.status_name === "Cancelled"
      );

      const filteredCanceled = cancelledAutoFund?.sort((a, b) =>
        a.inactive_date < b.inactive_date
          ? 1
          : a.inactive_date > b.inactive_date
          ? -1
          : 0
      )[0];

      setFetchedCancelledRecuringFund(filteredCanceled);
    }

    if (fetchedAutoFund?.length) {
      const activeAutoFund = fetchedAutoFund
        ?.filter((active) => active?.status_name === "Active")
        .pop();

      setFetchedActiveRecuringFund(activeAutoFund);
    }
  }, [fetchedAutoFund]);

  useEffect(() => {
    if (updateWallet) {
      const timer = setTimeout(() => {
        handleWalletUpdate();
      }, 9000);
      return () => clearTimeout(timer);
    }
  }, [updateWallet]);

  useEffect(() => {
    if (!akant && fectcheUserProfile?.customer_info?.length) {
      handleCreate();
    }
  }, [akant, fectcheUserProfile?.customer_info?.length]);

  useEffect(() => {
    if (akant) {
      setPrvAccountNo(
        fectcheUserProfile?.customer_info[0]?.providus_bank_account_number
      );
    }
  }, [akant, fectcheUserProfile?.customer_info]);

  useEffect(() => {
    if (
      fectcheUserProfile?.customer_info?.length &&
      fectcheUserProfile?.customer_info[0]?.wema_bank_account_number
    ) {
      setWemaAccountNo(
        fectcheUserProfile?.customer_info[0]?.wema_bank_account_number
      );
    }
  }, [fectcheUserProfile?.customer_info]);

  useEffect(() => {
    if (
      fectcheUserProfile?.customer_info?.length &&
      fectcheUserProfile?.customer_info[0]?.starling_bank_account_number
    ) {
      setSterlingAccNo(
        fectcheUserProfile?.customer_info[0]?.starling_bank_account_number
      );
    }
  }, [fectcheUserProfile?.customer_info]);

  useEffect(() => {
    if (prvAccountNo) {
      setAccountNo(prvAccountNo);
    }
  }, [prvAccountNo]);

  // Get the different biller groups (PAID TV, DISCO, BETS etc.)
  useEffect(() => {
    if (!biller) {
      dispatch(billerGroup());
    }
  }, [biller, dispatch]);

  useEffect(() => {
    if (!walletBalance) {
      const data = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };
      dispatch(getWalletBalance(data))
        .unwrap()
        .catch((err) => {
          if (err) {
            authService.logOut();
            return navigate("/login");
          }
        });
    }

    if (walletBalance) setFetchedUserBalance(walletBalance);
  }, [
    dispatch,
    userId,
    customerId,
    verification_code,
    walletBalance,
    navigate,
  ]);

  useEffect(() => {
    if (fetchedActiveRecuringFund) {
      const nextDate = calcNextDate(
        fetchedActiveRecuringFund?.transaction_date,
        fetchedActiveRecuringFund?.recuring_type_name
      );

      setRecurringNextDate(nextDate);
    }
  }, [fetchedActiveRecuringFund]);

  useEffect(() => {
    if (recurringNextDate) {
      const today = new Date();

      if (recurringNextDate <= today) {
        const nextDate = calcNextDate(
          recurringNextDate,
          fetchedActiveRecuringFund?.recuring_type_name
        );

        setRecurringNextDate(nextDate);
      }
    }
  }, [recurringNextDate, fetchedActiveRecuringFund]);

  useEffect(() => {
    if (!transactions) {
      setIsLoadingTransact(true);
      const id = customerId?.toString();
      dispatch(getTransactions(id))
        .unwrap()
        .then(() => setIsLoadingTransact(false))
        .catch(() => setIsLoadingTransact(false));
    }

    if (transactions) {
      setFetchedTransactions(transactions?.transation_history?.slice(0, 5));
    }
  }, [transactions, dispatch, customerId]);

  const calcNextDate = (currDate, interval) => {
    let formatedNextDate = "";

    switch (interval) {
      case "Daily":
        formatedNextDate = moment(currDate).add(1, "days");
        break;
      case "Weekly":
        formatedNextDate = moment(currDate).add(7, "days");
        break;
      case "Monthly":
        formatedNextDate = moment(currDate).add(30, "days");
        break;
      default:
        return;
    }

    return formatedNextDate?._d;
  };

  const handleRedirect = (location) => {
    setShowModal(true);
    switch (location) {
      case "transactPin":
        setSelectedModal("transactPin");
        break;
      case "fundUrWallet":
        setSelectedModal("fundUrWallet");
        break;
      case "paymentOptions":
        setSelectedModal("paymentOptions");
        break;
      case "airtime":
        setSelectedModal("airtime");
        break;
      case "data":
        setSelectedModal("data");
        break;
      case "cancel-autofund":
        setSelectedModal("cancel-autofund");
        break;
      case "flutter":
        dispatch(setIsActivePlan(true));
        dispatch(setIsEditing(true));
        setSelectedModal("flutter");
        break;
      default:
        return;
    }
  };

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedModal("");
  };

  const handlePlanCancel = async () => {
    try {
      const toastId = toast.loading("Cancelling Recurring Plan...");

      await dispatch(
        cancelRecurringPlan(fetchedActiveRecuringFund?.payment_ref_no)
      )
        .unwrap()
        .then((res) => {
          if (!res || (res && res.status === 400)) {
            return toast.error("Recurring Plan cancel failed!", {
              id: toastId,
            });
          }
          toast.success("Recurring Plan cancelled!", {
            id: toastId,
          });
          const data = {
            userId: userId.toString(),
            customerId: customerId?.toString(),
            verification_code,
          };

          dispatch(getAutoFund(data))
            .unwrap()
            .then(() => {
              window.location.reload();
            })
            .catch((err) => err);
        })
        .catch((err) => {
          toast.error("Recurring Plan cancel failed!", {
            id: toastId,
          });

          return err;
        });
    } catch (err) {
      return err;
    }
  };

  const handleWalletUpdate = () => {
    const toastId = toast.loading("Updating Wallet Balance..");
    setIsLoading(true);
    dispatch(setWalletUpdate(false));

    const data = {
      sql_ref_no: "31",
      user_id: userId.toString(),
      customer_id: customerId.toString(),
      verification_code,
      option: "1",
    };

    dispatch(getWalletBalance(data))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        const id = customerId?.toString();
        dispatch(getTransactions(id));
        return toast.success("Wallet Balance updated!", {
          id: toastId,
        });
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Wallet Balance failed", {
          id: toastId,
        });
      });
  };

  function handleCreate() {
    const data = {
      sql_ref_no: "28",
      user_id: userId.toString(),
      customer_id: customerId.toString(),
      verification_code,
      account_name: `${fectcheUserProfile?.customer_info[0]?.customer_first_name} ${fectcheUserProfile?.customer_info[0]?.customer_last_name}`,
      // bvn: `${fetchedUser?.bank_verification_number}`,
      option: "1",
    };

    dispatch(createAccountNumber(data))
      .unwrap()
      .then((res) => {
        if (res.requestSuccessful === false) {
          return res.responseMessage;
        }

        if (res.message_id !== 1) {
          return res.message_text;
        }

        const data = {
          sql_ref_no: "11",
          user_id: userId?.toString(),
          customer_id: customerId?.toString(),
          option: "7",
        };

        dispatch(getUserInfo(data));
      })
      .catch((err) => {
        return err;
      });
  }

  function getAutoFunds() {
    const data = {
      userId: userId.toString(),
      customerId: customerId?.toString(),
      verification_code,
    };

    dispatch(getAutoFund(data));
  }

  return (
    <div>
      <SignedInNavbarImproved />
      <div className="mx-auto max-w-[1600px] px-2 pb-96 pt-8 xsm:px-4 sm:px-8 md:px-10 lg:px-16">
        <div className="space-y-2 xsm:flex xsm:items-center xsm:justify-between xsm:space-y-0">
          <div className="text-3xl font-bold text-gravel-600">Wallet</div>
          <Link to="/home/wallet/statistics">
            <button className="mt-4 flex transform cursor-pointer items-center space-x-2 rounded-md border border-olivine-500 px-10 py-3 text-olivine-500 transition-all duration-200 ease-in-out hover:-translate-y-0.5 hover:ring-[0.5px] hover:ring-olivine-500 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-2 active:bg-olivine-100 xsm:mt-0">
              <div className="text-sm font-medium text-olivine-500">
                Statistics
              </div>
            </button>
          </Link>
        </div>
        <div className="mt-6 flex flex-row items-start space-x-2 rounded-md bg-[#F5F5F5] p-3">
          <div className="rounded-full bg-gray-600 text-white">
            <BsInfo className="h-4 w-4" />
          </div>
          <p className="text-xs text-gray-500">
            Per Central Bank rules for bank transfers, payments of ₦10,000 and
            above incur a ₦50 fee. This fee will be deducted from your wallet
            Balance.
          </p>
        </div>
        <div className="mt-4 rounded-md bg-[#EAF6DC] px-2 pb-4 pt-3 ring-[0.5px] ring-gray-300 xsm:px-4 sm:px-8 sm:pb-8 sm:pt-8">
          <Tab.Group defaultIndex={currentTab}>
            <Tab.List className="flex flex-col space-y-3 xsm:flex-row xsm:items-center xsm:space-x-2 xsm:space-y-0 xsm:text-sm sm:space-x-4 sm:text-base">
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected
                        ? "cursor-pointer rounded bg-olivine-500 text-white outline-none"
                        : "cursor-pointer rounded bg-[#DBE3CA] text-olivine-500 transition duration-100 ease-out hover:opacity-80"
                    }
                  >
                    <div className="px-4 py-2 xsm:px-5 sm:px-8 md:px-10">
                      Wallet
                    </div>
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected
                        ? "cursor-pointer rounded bg-olivine-500 text-white outline-none"
                        : "cursor-pointer rounded bg-[#DBE3CA] text-olivine-500 transition duration-100 ease-out hover:opacity-80"
                    }
                  >
                    <div className="px-4 py-2 xsm:px-5 sm:px-8 md:px-10">
                      Auto fund
                    </div>
                  </button>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div>
                  <div className="mt-12">
                    <div className="flex items-center justify-center space-x-2 sm:justify-start">
                      <div>
                        <FaWallet className="h-5 w-5" />
                      </div>
                      <div className="">Total Balance</div>
                      <div
                        onClick={() => handleWalletUpdate()}
                        className="text-olivine-500 transition duration-500 hover:text-olivine-600 active:rotate-45"
                      >
                        <BiRefresh className="h-6 w-6 cursor-pointer" />
                      </div>
                    </div>
                    <div className="mt-1 flex items-center justify-center space-x-1 xsm:mt-0 sm:justify-start">
                      {isLoading ? (
                        <IsLoadingFour />
                      ) : enabled ? (
                        <div className="h-10 text-3xl font-bold text-gravel-600">
                          {fetchedUserBalance &&
                          fetchedUserBalance?.available_balance
                            ? `₦ ${Number(
                                fetchedUserBalance?.available_balance
                              ).toLocaleString()}`
                            : "₦ 0.00"}
                        </div>
                      ) : (
                        <div className="grid h-10 pt-1 text-3xl font-bold text-gravel-600">
                          *************
                        </div>
                      )}

                      <div className="flex items-center justify-center">
                        <div className="text-[#828282] xsm:flex xsm:items-center">
                          {enabled ? (
                            <div
                              onClick={() => setEnabled(false)}
                              className="flex h-10 w-6 items-center justify-center pb-1 xsm:pb-0"
                            >
                              <AiFillEyeInvisible className="h-6 w-6 cursor-pointer text-olivine-500 active:text-olivine-700" />
                            </div>
                          ) : (
                            <div
                              onClick={() => setEnabled(true)}
                              className="flex h-10 w-6 items-center justify-center pb-1 xsm:pb-0"
                            >
                              <AiFillEye className="h-6 w-6 cursor-pointer text-olivine-500 active:text-olivine-700" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mt-4 text-xs text-gray-600">
                    Send money to the account number below and your grocedy
                    wallet will be funded immediately
                  </p>
                  <div className="sm:flex sm:space-x-20">
                    <div className="mt-2 flex flex-col justify-between lg:flex-row lg:items-center">
                      <div className="space-y-1 lg:flex lg:items-center lg:space-x-16">
                        {accountNo ? (
                          <div className="flex items-center justify-between text-xs font-semibold text-gravel-500 xsm:text-sm sm:justify-start sm:space-x-3">
                            <div className="">
                              <p className="w-28 lg:w-auto">Providus Bank</p>
                            </div>
                            <div className="flex space-x-2">
                              <p>
                                {
                                  // fectcheUserProfile?.customer_info[0]
                                  //   ?.providus_bank_account_number
                                  accountNo
                                }
                              </p>
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    // fectcheUserProfile?.customer_info[0]
                                    //   ?.providus_bank_account_number
                                    accountNo
                                  );
                                  toast.success("Copied to clipboard!");
                                }}
                              >
                                <IoCopy className="h-3.5 w-3.5 text-olivine-500 transition duration-300 hover:text-olivine-600" />
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-2 flex flex-col justify-between lg:flex-row lg:items-center">
                      <div className="space-y-1 lg:flex lg:items-center lg:space-x-16">
                        {wemaAccountNo ? (
                          <div className="flex items-center justify-between text-xs font-semibold text-gravel-500 xsm:text-sm sm:justify-start sm:space-x-3">
                            <div className="">
                              <p className="w-28 lg:w-auto">Wema Bank</p>
                            </div>
                            <div className="flex space-x-2">
                              <p>{wemaAccountNo}</p>
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    // fectcheUserProfile?.customer_info[0]
                                    //   ?.providus_bank_account_number
                                    wemaAccountNo
                                  );
                                  toast.success("Copied to clipboard!");
                                }}
                              >
                                <IoCopy className="h-3.5 w-3.5 text-olivine-500 transition duration-300 hover:text-olivine-600" />
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="mt-2 flex flex-col justify-between lg:flex-row lg:items-center">
                      <div className="space-y-1 lg:flex lg:items-center lg:space-x-16">
                        {sterlingAccNo ? (
                          <div className="flex items-center justify-between text-xs font-semibold text-gravel-500 xsm:text-sm sm:justify-start sm:space-x-3">
                            <div className="">
                              <p className="w-28 lg:w-auto">Sterling Bank</p>
                            </div>
                            <div className="flex space-x-2">
                              <p>{sterlingAccNo}</p>
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(sterlingAccNo);
                                  toast.success("Copied to clipboard!");
                                }}
                              >
                                <IoCopy className="h-3.5 w-3.5 text-olivine-500 transition duration-300 hover:text-olivine-600" />
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="h-[400px] md:h-44">
                  {fetchedActiveRecuringFund &&
                  fetchedActiveRecuringFund?.deposit_amount ? (
                    <div className="">
                      <div className="mt-8 grid h-[400px] w-full grid-cols-6 divide-y divide-gray-300 rounded-lg bg-white px-4 py-4 text-sm sm:px-8 md:h-24 md:divide-x md:divide-y-0 md:px-0 md:text-xs lg:text-sm">
                        <div className="col-span-6 flex flex-col justify-center pb-2 text-center md:col-span-1 md:pb-0">
                          <div className="font-semibold text-gray-600">
                            Amount
                          </div>
                          <div className="text-gray-400">
                            {fetchedActiveRecuringFund &&
                            fetchedActiveRecuringFund?.deposit_amount
                              ? `₦ ${Number(
                                  fetchedActiveRecuringFund?.deposit_amount
                                ).toLocaleString()}`
                              : "₦ 0.00"}
                          </div>
                        </div>
                        <div className="col-span-6 flex flex-col justify-center py-2 text-center md:col-span-1 md:py-0">
                          <div className="font-semibold text-gray-600">
                            Deducted
                          </div>
                          <div className="text-gray-400">
                            {fetchedActiveRecuringFund &&
                            fetchedActiveRecuringFund?.recuring_type_name
                              ? fetchedActiveRecuringFund?.recuring_type_name
                              : ""}
                          </div>
                        </div>
                        <div className="col-span-6 flex flex-col justify-center py-2 text-center md:col-span-1 md:py-0">
                          <div className="font-semibold text-gray-600">
                            Activated date
                          </div>
                          <div className="text-gray-400">
                            {fetchedActiveRecuringFund &&
                            fetchedActiveRecuringFund?.transaction_date
                              ? moment(
                                  fetchedActiveRecuringFund?.transaction_date
                                ).format("MMM Do YYYY")
                              : ""}
                          </div>
                        </div>
                        <div className="col-span-6 flex flex-col justify-center py-2 text-center md:col-span-1 md:py-0">
                          <div className="font-semibold text-gray-600">
                            Next deduction
                          </div>
                          <div className="text-gray-400">
                            {fetchedActiveRecuringFund && recurringNextDate
                              ? moment(recurringNextDate).format("MMM Do YYYY")
                              : ""}
                          </div>
                        </div>

                        <div className="col-span-6 flex flex-col justify-center py-2 text-center md:col-span-1 md:py-0">
                          <div className="font-semibold text-gray-600">
                            Status
                          </div>
                          <div className="text-gray-400">Active</div>
                        </div>
                        <div className="col-span-6 flex flex-row justify-center text-center md:col-span-1">
                          <button
                            // onClick={() => handlePlanCancel()}
                            onClick={() => handleRedirect("flutter")}
                            className="mr-6 grid"
                          >
                            <div className="m-auto">
                              <div className="border-b-[1.5px] border-olivine-500 border-opacity-0 pt-2 font-semibold text-olivine-500 transition duration-300 hover:border-opacity-100 hover:text-olivine-600 md:py-0">
                                Edit
                              </div>
                            </div>
                          </button>
                          <button
                            // onClick={() => handlePlanCancel()}
                            onClick={() => handleRedirect("cancel-autofund")}
                            className="grid"
                          >
                            <div className="m-auto">
                              <div className="border-b-[1.5px] border-opacity-0 pt-2 font-semibold text-red-500 transition duration-300 hover:border-opacity-100 hover:text-olivine-600 md:py-0">
                                Cancel
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {fetchedCancelledRecuringFund?.deposit_amount &&
                  !fetchedActiveRecuringFund?.deposit_amount ? (
                    <div className="">
                      <div className="mt-8 grid h-[400px] w-full grid-cols-6 divide-y divide-gray-300 rounded-lg bg-white px-4 py-4 text-sm sm:px-8 md:h-24 md:divide-x md:divide-y-0 md:px-0 md:text-xs lg:text-sm">
                        <div className="col-span-6 flex flex-col justify-center pb-2 text-center md:col-span-1 md:pb-0">
                          <div className="font-semibold text-gray-600">
                            Amount
                          </div>
                          <div className="text-gray-400">
                            {fetchedCancelledRecuringFund &&
                            fetchedCancelledRecuringFund?.deposit_amount
                              ? `₦ ${Number(
                                  fetchedCancelledRecuringFund?.deposit_amount
                                ).toLocaleString()}`
                              : "₦ 0.00"}
                          </div>
                        </div>
                        <div className="col-span-6 flex flex-col justify-center py-2 text-center md:col-span-1 md:py-0">
                          <div className="font-semibold text-gray-600">
                            Deducted
                          </div>
                          <div className="text-gray-400">
                            {fetchedCancelledRecuringFund &&
                            fetchedCancelledRecuringFund?.recuring_type_name
                              ? fetchedCancelledRecuringFund?.recuring_type_name
                              : ""}
                          </div>
                        </div>
                        <div className="col-span-6 flex flex-col justify-center py-2 text-center md:col-span-1 md:py-0">
                          <div className="font-semibold text-gray-600">
                            Activation Date
                          </div>
                          <div className="text-gray-400">
                            {fetchedCancelledRecuringFund &&
                            fetchedCancelledRecuringFund?.transaction_date
                              ? moment(
                                  fetchedCancelledRecuringFund?.transaction_date
                                ).format("MMM Do YYYY")
                              : ""}
                          </div>
                        </div>
                        <div className="col-span-6 flex flex-col justify-center py-2 text-center md:col-span-1 md:py-0">
                          <div className="font-semibold text-gray-600">
                            Status
                          </div>
                          <div className="text-gray-400">Cancelled</div>
                        </div>
                        <div className="col-span-6 flex flex-col justify-center py-2 text-center md:col-span-1 md:py-0">
                          <div className="font-semibold text-gray-600">
                            Deactivated Date
                          </div>
                          <div className="text-gray-400">
                            {fetchedCancelledRecuringFund &&
                            fetchedCancelledRecuringFund?.inactive_date
                              ? moment(
                                  fetchedCancelledRecuringFund?.inactive_date
                                ).format("MMM Do YYYY")
                              : ""}
                          </div>
                        </div>
                        <div className="col-span-7 flex flex-col justify-center py-2 text-center md:col-span-1 md:py-0">
                          <div className="grid">
                            <div className="m-auto">
                              <Popover>
                                {({ open }) => (
                                  <>
                                    <Popover.Button
                                      className={`
                                                                        ${
                                                                          open
                                                                            ? ""
                                                                            : "text-opacity-90"
                                                                        }
                                                                        focus:text-olivine-700 focus:outline-none`}
                                    >
                                      <div className="border-b-[1.5px] border-olivine-500 border-opacity-0 font-semibold text-olivine-500 transition duration-300 hover:border-opacity-100 hover:text-olivine-600">
                                        Create
                                      </div>
                                    </Popover.Button>
                                    <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-200"
                                      enterFrom="opacity-0 translate-y-1"
                                      enterTo="opacity-100 translate-y-0"
                                      leave="transition ease-in duration-150"
                                      leaveFrom="opacity-100 translate-y-0"
                                      leaveTo="opacity-0 translate-y-1"
                                    >
                                      <Popover.Panel className="absolute inset-x-0 top-40 z-30 mx-auto max-w-md px-2 xsm:px-4">
                                        {({ close }) => (
                                          <div>
                                            <SavingsSettingsPopover
                                              closer={close}
                                            />
                                          </div>
                                        )}
                                      </Popover.Panel>
                                    </Transition>
                                  </>
                                )}
                              </Popover>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {!fetchedActiveRecuringFund?.deposit_amount &&
                  !fetchedCancelledRecuringFund?.deposit_amount ? (
                    <div className="">
                      <div className="mt-16 w-full rounded-lg bg-white p-4 sm:mt-8">
                        <div className="">
                          <h3 className="text-center text-lg font-medium leading-6 text-gray-600">
                            You currently do not have any auto funds.
                          </h3>
                          <p className="mx-auto mt-1 max-w-4xl text-center text-xs text-gray-500">
                            Did you know that you could set up your auto-funds
                            to facilitate regular contributions to your grocedy
                            wallet. By automating the process you can ensure
                            that funds are consistently contributed to your
                            wallet without having to manually transfer funds
                            each time.
                          </p>
                        </div>
                        <div className="mt-4 flex justify-center">
                          <Popover>
                            {({ open }) => (
                              <>
                                <Popover.Button
                                  className={`
                                    ${open ? "" : "text-opacity-90"}
                                    focus:text-olivine-700 focus:outline-none`}
                                >
                                  <div className="flex items-center justify-center rounded-md border-[1px] border-olivine-500 px-8 py-2 text-sm font-semibold text-olivine-500 transition duration-300 ease-in-out hover:-translate-y-0.5 hover:ring-[0.5px] hover:ring-olivine-500 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-2 active:bg-olivine-100">
                                    <FaPlus className="mr-2 h-3 w-3" />
                                    Create Auto Fund
                                  </div>
                                </Popover.Button>
                                <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel className="absolute inset-x-0 top-40 z-30 mx-auto max-w-md px-2 xsm:px-4">
                                    {({ close }) => (
                                      <div>
                                        <SavingsSettingsPopover
                                          closer={close}
                                        />
                                      </div>
                                    )}
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>

        <div className="mt-8 grid gap-4 grid-auto-fit-[150px]">
          <Popover className="h-36">
            {({ open }) => (
              <>
                <Popover.Button
                  className={`h-36 w-full rounded-lg border-[1px] border-gray-200 bg-[#F9F9F9] p-6 text-opacity-90 hover:ring-[1px] hover:ring-[#558223]/60 focus:outline-none`}
                >
                  <div className="flex flex-col items-center justify-center">
                    <div className="flex h-14 w-14 items-center justify-center rounded-full bg-[#558223]">
                      <img
                        src={FundWalletIcon}
                        alt="icon"
                        className="h-6 w-6"
                      />
                    </div>
                    <p className="mt-1 text-base font-semibold text-[#558223]">
                      Fund Wallet
                    </p>
                  </div>
                </Popover.Button>
                <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute inset-x-0 top-32 z-30 mx-auto max-w-md px-2 xsm:px-4">
                    {({ close }) => (
                      <div>
                        <FundYourWalletPopoverTwo
                          closer={close}
                          handleRedirect={handleRedirect}
                        />
                      </div>
                    )}
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <Popover className="h-36">
            {({ open }) => (
              <>
                <Popover.Button
                  className={`h-36 w-full rounded-lg border-[1px] border-gray-200 bg-[#F9F9F9] p-6 text-opacity-90 hover:ring-[1px] hover:ring-[#558223]/60 focus:outline-none`}
                >
                  <div className="flex flex-col items-center justify-center">
                    <div className="flex h-14 w-14 items-center justify-center rounded-full bg-[#558223]">
                      <img
                        src={BillPaymentIcon}
                        alt="icon"
                        className="h-6 w-6"
                      />
                    </div>
                    <p className="mt-1 text-base font-semibold text-[#558223]">
                      Bill Payment
                    </p>
                  </div>
                </Popover.Button>
                <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute inset-x-0 top-32 z-30 mx-auto max-w-md px-2 xsm:px-4">
                    {({ close }) => (
                      <div>
                        <PayBillsPopoverTwo closer={close} />
                      </div>
                    )}
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <Popover className="h-36">
            {({ open }) => (
              <>
                <Popover.Button
                  className={`h-36 w-full rounded-lg border-[1px] border-gray-200 bg-[#F9F9F9] p-6 text-opacity-90 hover:ring-[1px] hover:ring-[#558223]/60 focus:outline-none`}
                >
                  <div className="flex flex-col items-center justify-center">
                    <div className="flex h-14 w-14 items-center justify-center rounded-full bg-[#558223]">
                      <img
                        src={AirtimeAndDataIcon}
                        alt="icon"
                        className="h-6 w-6"
                      />
                    </div>
                    <p className="mt-1 text-base font-semibold text-[#558223]">
                      Airtime and Data
                    </p>
                  </div>
                </Popover.Button>
                <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute inset-x-0 top-40 z-30 mx-auto max-w-md px-2 xsm:px-4">
                    {({ close }) => (
                      <div>
                        <BuyAirtimeAndDataPopover
                          closer={close}
                          handleRedirect={handleRedirect}
                        />
                      </div>
                    )}
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <RedeemVoucherWalletButton />

          <button
            onClick={() => navigate("/reward-bank")}
            className=" h-36 w-full cursor-pointer rounded-lg border-[1px] border-gray-200 bg-[#F9F9F9] p-6 text-opacity-90 hover:ring-[1px] hover:ring-[#558223]/60 focus:outline-none"
          >
            <div className="flex flex-col items-center justify-center">
              <div className="flex h-14 w-14 items-center justify-center rounded-full bg-[#558223]">
                <img src={RewardBankIcon} alt="icon" className="h-6 w-6" />
              </div>
              <p className="mt-1 text-base font-semibold text-[#558223]">
                Reward Bank
              </p>
            </div>
          </button>

          <button className="h-36 w-full cursor-not-allowed rounded-lg border-[1px] border-gray-200 bg-[#F9F9F9] p-6 text-opacity-90 hover:ring-[1px] hover:ring-[#558223]/60 focus:outline-none">
            <div className="flex flex-col items-center justify-center">
              <div className="flex h-14 w-14 items-center justify-center rounded-full bg-[#558223]">
                <img src={CreditScoreIcon} alt="icon" className="h-6 w-6" />
              </div>
              <p className="mt-1 text-center text-base font-semibold text-[#558223]">
                Credit Score
              </p>
              <p className="mt-1 rounded-md bg-[#F8F1D8] px-2 py-[2px] text-center text-sm font-semibold text-[#C19C16]">
                Coming soon!
              </p>
            </div>
          </button>
        </div>
        <div className="mt-16">
          <div className="flex items-center justify-between font-bold text-gravel-600">
            <div>Transaction History</div>
            <div className="group cursor-pointer">
              <div
                onClick={() => navigate("/home/transaction-history")}
                className="font-semibold text-olivine-500"
              >
                See all
              </div>
              <hr className="-mt-[1px] border-[1px] border-olivine-500 opacity-0 transition-all duration-500 group-hover:opacity-100" />
            </div>
          </div>
          <div className="mt-4">
            {!isLoadingTransact ? (
              fetchedTransactions && fetchedTransactions?.length ? (
                fetchedTransactions?.map((transaction, idx) => (
                  <div
                    onClick={() => {
                      dispatch(setTransaction(transaction));
                      navigate("/home/transaction/detail");
                    }}
                    key={idx}
                    className="flex cursor-pointer items-center justify-between border-t-[1px] border-gray-300 py-3 text-xs"
                  >
                    <div className="space-y-2">
                      {transaction?.payment_ref_no &&
                      transaction?.transaction_service_name ? (
                        <div>{`${transaction?.transaction_type_name} - ${transaction?.transaction_service_name} - ${transaction?.payment_ref_no}`}</div>
                      ) : transaction?.transaction_type_name &&
                        transaction?.narration ? (
                        <div>
                          {`${transaction?.transaction_type_name} - ${transaction?.narration}`}
                        </div>
                      ) : (
                        <div>{`${transaction?.transaction_type_name}`}</div>
                      )}

                      <div className="font-bold">
                        {transaction?.transaction_token
                          ? `Token: ${transaction?.transaction_token}`
                          : null}
                      </div>
                      <div className="font-bold">
                        {!transaction?.transaction_token &&
                        transaction?.transaction_id
                          ? transaction?.transaction_id
                          : null}
                      </div>
                      <div className="flex items-center space-x-2">
                        <span>
                          {moment(transaction?.transaction_date).format(
                            "MMM Do YYYY"
                          )}
                        </span>
                        <div className="h-4 w-[1px] bg-gray-500"></div>
                        <span>
                          {moment(transaction?.transaction_date).format("LT")}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col items-end space-y-2">
                      <div className="font-semibold">
                        {transaction?.transaction_group !== "Debit" ? (
                          <span className="text-lg text-olivine-500">+</span>
                        ) : (
                          <span className="text-lg text-red-600">-</span>
                        )}
                        {` ₦ ${Number(transaction?.amount).toLocaleString()}`}
                      </div>
                      <div>
                        {transaction
                          ? `Processing fee: ₦${Number(
                              transaction?.convience_fees_amount
                            ).toLocaleString()}`
                          : null}
                      </div>
                    </div>
                  </div>
                ))
              ) : null
            ) : (
              <CircularProgress size={20} color="inherit" />
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={showModal}
        onClose={handleOnClose}
        selected={selectedModal}
        cancelFund={handlePlanCancel}
      />
    </div>
  );
}

export default Wallet;

// gaxico5317@laymro.com
