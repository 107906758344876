import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";
import moment from "moment";

function RatingComment() {
  const navigate = useNavigate();
  const { selectedReview } = useSelector((state) => state.modal);
  const rating =
    selectedReview && selectedReview?.rating_value
      ? Number(selectedReview?.rating_value)
      : 0;

  const cusRating =
    selectedReview && selectedReview?.shopping_rating_value
      ? Number(selectedReview?.shopping_rating_value)
      : 0;

  return (
    <div className=" h-[1000px] bg-primary_color px-4">
      <div className="mx-auto w-full max-w-5xl pb-40 pt-16 text-xs xsm:text-sm md:text-base">
        <div className="flex items-center space-x-4 sm:space-x-8">
          <div
            onClick={() => navigate(-1)}
            className="h-fit w-fit rounded-full  p-1 hover:bg-gray-200"
          >
            <BiArrowBack className="h-6 w-6 cursor-pointer text-gray-600" />
          </div>
          <h1 className="text-lg font-bold text-gravel-600 sm:text-2xl">
            Back
          </h1>
        </div>
        {selectedReview?.salesorder_id ? (
          <div className="mt-10 rounded-xl bg-white px-4 pb-16 pt-8 sm:px-6 md:px-8 ">
            <div className="flex items-center justify-between">
              <h2 className="text-sm font-medium text-gray-700">
                Your Comment
              </h2>
              <p className="text-sm font-normal text-gray-500">
                {selectedReview?.created_time
                  ? moment(selectedReview?.created_time).format("LLL")
                  : null}
              </p>
            </div>
            <div className="mt-2 flex flex-row items-center space-x-1">
              <StarRatings
                rating={cusRating}
                starRatedColor="#ffce31"
                numberOfStars={5}
                name="rating"
                starDimension="10px"
                starSpacing="2px"
              />
            </div>
            <p className="mt-4 text-sm text-gray-700">
              {selectedReview?.shopping_rating_remark
                ? selectedReview?.shopping_rating_remark
                : null}
            </p>
          </div>
        ) : (
          <div className="mt-10 rounded-xl bg-white px-4 pb-16 pt-8 sm:px-6 md:px-8 ">
            <div className="flex items-center justify-between">
              <h2 className="text-sm font-medium text-gray-700">
                Your Comment
              </h2>
              <p className="text-sm font-normal text-gray-500">
                {selectedReview?.transaction_date
                  ? moment(selectedReview?.transaction_date).format("LLL")
                  : null}
              </p>
            </div>
            <div className="mt-2 flex flex-row items-center space-x-1">
              <StarRatings
                rating={rating}
                starRatedColor="#ffce31"
                numberOfStars={5}
                name="rating"
                starDimension="10px"
                starSpacing="2px"
              />
            </div>
            <p className="mt-4 text-sm text-gray-700">
              {selectedReview?.rating_remark
                ? selectedReview?.rating_remark
                : null}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default RatingComment;
