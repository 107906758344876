import React, { useEffect, useState } from "react";
import { FaWallet } from "react-icons/fa";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import WarningIcon from "../../../assets/warningIcon.svg";
import { getWalletBalance } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../../services/authService";
import Modal from "../../Ui";

function InsufficientBallancePopover({
  cartAmount,
  handleInsufficient,
  onClose,
}) {
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(false);
  const [fetchedUserBalance, setFetchedUserBalance] = useState();
  const [selectedModal, setSelectedModal] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { walletBalance } = useSelector((state) => state.user);
  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (!walletBalance) {
      const data = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };
      dispatch(getWalletBalance(data))
        .unwrap()
        .catch((err) => {
          return err;
        });
    }

    if (walletBalance) setFetchedUserBalance(walletBalance);
  }, [dispatch, userId, customerId, verification_code, walletBalance]);

  const handleRedirect = () => {
    // setShowModal(true);
    // setSelectedModal("paymentOptions");
    handleInsufficient();
  };

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedModal("");
  };

  return (
    <div className="mx-auto w-full max-w-md rounded-2xl bg-[#F9F9F9] px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
      <div className="flex items-center space-x-2 pl-1">
        <div>
          <img src={WarningIcon} alt="icon" className="h-4 w-4" />
        </div>
        <div className="text-base font-semibold text-gray-600">
          Insufficient Wallet Balance
        </div>
      </div>
      <div className="pb-4">
        <div className="mt-2 flex items-center justify-between rounded-lg border-[0.5px] border-gray-300 bg-[#E9F8CC] p-3 sm:p-4">
          <div className="flex w-full items-center justify-between">
            <div>
              <div className="flex items-center space-x-2 text-gray-600">
                <div>
                  <FaWallet className="h-5 w-5" />
                </div>
                <div className="">Total Balance</div>
              </div>
              <div>
                {enabled ? (
                  <h2 className="pt-1 text-xl font-bold text-gravel-600 sm:text-2xl">
                    {fetchedUserBalance && fetchedUserBalance?.available_balance
                      ? `₦ ${Number(
                          fetchedUserBalance?.available_balance
                        ).toLocaleString()}`
                      : "₦ 0.00"}
                  </h2>
                ) : (
                  <h2 className="pt-1 text-xl font-bold text-gravel-600 sm:text-2xl">
                    ********
                  </h2>
                )}
              </div>
            </div>
            <div>
              <div>
                {enabled ? (
                  <div onClick={() => setEnabled(false)}>
                    <AiFillEyeInvisible className="h-6 w-6 cursor-pointer text-olivine-500 active:text-olivine-700" />
                  </div>
                ) : (
                  <div onClick={() => setEnabled(true)}>
                    <AiFillEye className="h-6 w-6 cursor-pointer text-olivine-500 active:text-olivine-700" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 pl-1 text-xs font-medium">
          <p>
            You do not have enough funds in your wallet for this transaction.
          </p>
          <p>
            Please fund your wallet to complete this{" "}
            <span className="text-olivine-500">
              transaction of{" "}
              {cartAmount
                ? `₦ ${Number(cartAmount).toLocaleString()}`
                : "₦ 0.00"}
            </span>
          </p>
        </div>
        <div className="mt-6 rounded-md bg-[#F1F1F1] px-3 py-2 sm:px-4">
          <h3 className="text-xs">You are paying for:</h3>
          <h2 className="text-xl font-bold text-gray-600 sm:text-lg">
            {cartAmount ? `₦ ${Number(cartAmount).toLocaleString()}` : "₦ 0.00"}
          </h2>
          {/* <p className="text-xs">Plan name and add-ons</p> */}
        </div>
        <div className="mt-8 flex items-center justify-between">
          <button
            onClick={() => onClose()}
            className="transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-white px-6 py-2.5 text-sm font-semibold text-olivine-500 transition-all  duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 xsm:px-8"
          >
            Back
          </button>
          <button
            onClick={handleRedirect}
            className="transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-olivine-500 px-6 py-2.5 text-sm font-semibold text-white transition-all  duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 xsm:px-8"
          >
            Top Up Wallet
          </button>
        </div>
      </div>
      <Modal
        visible={showModal}
        onClose={handleOnClose}
        selected={selectedModal}
      />
    </div>
  );
}

export default InsufficientBallancePopover;
