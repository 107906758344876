import React from "react";

function RemoveItemConfirmationPopover({ closer, deleter, selectedId }) {
  // Close the popover
  const handleCloser = () => {
    closer();
  };

  // Delete the item in the cart
  const handleDelete = (id) => {
    deleter(id);
    closer();
  };

  return (
    <div className="rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
      <div className="flex items-center justify-center">
        <div className="block text-lg font-bold text-gray-600">
          Remove Item?
        </div>
      </div>
      <div className="mt-2 text-sm">
        <div>
          The recurring monthly service entitles you to products at cheaper
          prices along with other benefits
        </div>
      </div>
      <div className="mt-4 flex items-center justify-between space-x-4">
        <button
          onClick={() => handleDelete(selectedId)}
          className="block whitespace-nowrap rounded-lg border-[1px] border-red-300 bg-white px-4 py-3 text-sm font-semibold text-red-400 transition duration-300 hover:bg-red-50 hover:text-red-600"
        >
          Remove Item
        </button>
        <button
          onClick={() => handleCloser()}
          className="-p-[1px] block whitespace-nowrap rounded-lg bg-olivine-500 px-8 py-3 text-sm font-semibold text-white shadow transition duration-300 hover:border-olivine-600 hover:bg-olivine-600 sm:px-10"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default RemoveItemConfirmationPopover;
