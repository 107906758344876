import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import { authService } from "../../../services/authService";
import { createTransactionPin, todoListStatus } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { init_data } from "../../../core/helpers";

function CreateYourTransactionPin({ onClose, handleShowingPinCreate }) {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [confirmOtp, setConfirmOtp] = useState(new Array(4).fill(""));
  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleChangeConfirm = (element, index) => {
    if (isNaN(element.value)) return false;

    setConfirmOtp([
      ...confirmOtp.map((d, idx) => (idx === index ? element.value : d)),
    ]);

    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const toastId = toast.loading("Submitting your pin...");

      if (otp.join("") !== confirmOtp.join("")) {
        return toast.error("Please confirm Pin");
      }

      const payload = {
        sql_ref_no: "10",
        user_id: userId?.toString(),
        verification_code,
        pin_number: otp.join(""),
        option: "11",
      };

      dispatch(createTransactionPin(payload))
        .unwrap()
        .then(() => {
          setOtp([...otp.map((v) => "")]);

          toast.success("Transaction Pin set", {
            id: toastId,
          });

          dispatch(todoListStatus(init_data));

          onClose();
          handleShowingPinCreate();
        })
        .catch((err) => {
          setOtp("");

          toast.error("Transaction Pin could not be set!", {
            id: toastId,
          });
          return err;
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="mx-auto w-full max-w-md rounded-2xl bg-[#F9F9F9] px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold text-gray-600">
          Create Your Transaction PIN
        </div>
        <button
          onClick={() => {
            onClose();
            handleShowingPinCreate();
          }}
          className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="pb-4">
        <p className="mt-2 text-xs">
          Please create a PIN to be used for transactions on Grocedy
        </p>
        <div className="mt-2">
          <label className="pl-1 text-start text-xs font-semibold text-gray-600">
            Input Your Transaction PIN
          </label>
          <div className="justify-left relative flex items-center space-x-2 rounded-md">
            {otp.map((data, index) => {
              return (
                <input
                  type="text"
                  maxLength={1}
                  className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                  name="opt"
                  key={index}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              );
            })}
          </div>
        </div>
        <div className="mt-2">
          <label className="pl-1 text-start text-xs font-semibold text-gray-600">
            Confirm Your Transaction PIN
          </label>
          <div className="justify-left relative flex items-center space-x-2 rounded-md">
            {confirmOtp.map((data, index) => {
              return (
                <input
                  type="text"
                  maxLength={1}
                  className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                  name="opt"
                  key={index}
                  value={data}
                  onChange={(e) => handleChangeConfirm(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              );
            })}
          </div>
        </div>
        <div className="mt-6 flex items-center justify-between">
          <button
            onClick={() => {
              onClose();
              handleShowingPinCreate();
            }}
            className="transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-white px-6 py-2.5 text-sm font-semibold text-olivine-500 transition-all  duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 xsm:px-8"
          >
            Cancel
          </button>
          <button
            onClick={(e) => {
              handleSubmit(e);
            }}
            className="rounded-md bg-olivine-500 px-6 py-2.5 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
          >
            Save Transaction pin
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateYourTransactionPin;
