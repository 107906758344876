import React, { useState, useContext, useEffect } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { FaPlus } from "react-icons/fa";
import { HiHeart, HiOutlineHeart } from "react-icons/hi";
import toast from "react-hot-toast";
import LogicContext from "../../context/LogicContext";
import RxjsStoreContext from "../../context/RxjsStoreContext";

function HomePageButchersComponent(props) {
  const [selected, setSelected] = useState(false);
  const rxjsStore = useContext(RxjsStoreContext);

  const addToBasket = (product, quantity) => {
    if (product.item_stock_out === true) {
      return toast.error("Item is out of stock!");
    }
    rxjsStore.setUserSelectedBasket({ ...product, type: "BUTCHER" }, quantity);
    toast.success(
      `${quantity} qty of ${product.name} has been added to basket!`
    );
  };

  return (
    <div>
      <div className="w-full overflow-hidden rounded-md border-[0.5px] border-gray-200 bg-white shadow-sm shadow-gray-300 transition-all duration-300 ease-in @container">
        <div className="@xs:flex">
          <div className="relative">
            <div className="bg-white @xs:w-40">
              <img
                src={props?.product?.item_image_url}
                className="cursor aspect-auto h-[240px] w-full object-contain @xs:h-48"
                alt=""
              />
            </div>
            <div className="group">
              <div className="cursor absolute right-0 top-0 h-full w-full rounded-md border-[1px] border-gray-200 transition duration-200"></div>
              <div className="group absolute right-2 top-2 cursor-pointer rounded-full bg-olivine-500  p-1 transition duration-200 hover:scale-125">
                <FaPlus
                  className="h-6 w-6 text-white @xs:h-5 @xs:w-5"
                  onClick={() => addToBasket(props.product, 1)}
                />
              </div>
              {props?.product?.item_stock_out === true && (
                <p className="absolute left-0 top-2 rounded-r bg-[#E53448] px-3 py-1 text-xs font-semibold text-white">
                  Out of Stock
                </p>
              )}
            </div>
          </div>
          <div className="w-full @xs:mt-12">
            <div className="flex items-center justify-between space-x-4 px-2 @xs:space-x-2">
              <h1 className="mt-2 line-clamp-1 cursor-pointer text-base font-bold text-gray-600 @xs:text-sm">
                {props?.itemName}
              </h1>
              <div className="">
                {selected ? (
                  <button onClick={() => setSelected(!selected)}>
                    <HiHeart className="aspect-auto h-6 w-6  cursor-pointer rounded-md object-cover text-red-400" />
                  </button>
                ) : (
                  <Tippy
                    content={
                      <div
                        className="max-w-sm space-y-1 rounded-md bg-gravel-500 p-4 text-xs text-white"
                        interactive={true}
                        interactiveBorder={20}
                        delay={100}
                      >
                        <div>Add to favorites</div>
                      </div>
                    }
                  >
                    <button onClick={() => setSelected(!selected)}>
                      {" "}
                      <HiOutlineHeart className="aspect-auto h-6 w-6  cursor-pointer rounded-md object-cover text-gray-500" />
                    </button>
                  </Tippy>
                )}
              </div>
            </div>
            <div className="mt-1 line-clamp-1 cursor-pointer px-2 text-sm font-semibold">
              <div className=" text-gray-600">
                {props?.product?.name} Price: ₦{props?.product?.item_rate_zoho}
              </div>
            </div>
            <div className="px-2 pb-4">
              <h4 className="cursor mt-1 text-xs font-semibold text-red-400 ">
                Market Price:{" "}
                <strike className="text-xs">
                  ₦{props?.product?.item_rate_market}
                </strike>
              </h4>
              <button
                onClick={() => addToBasket(props.product, 1)}
                className="mt-4 w-full max-w-xs rounded-md bg-olivine-500 py-2 text-sm text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
              >
                Add to Basket
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePageButchersComponent;
