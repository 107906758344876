import React from "react";
import { IoClose } from "react-icons/io5";
import TransactionPinConfirmationImage from "../../../../assets/transactionPinConfirmationImage.svg";

function TransactionPinConfirmation(props) {
  return (
    <div className="p-4">
      <div className="flex justify-end">
        <div className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
          <IoClose onClick={() => props?.closeModal()} className="h-5 w-5" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center space-x-2">
        <div>
          <img
            src={TransactionPinConfirmationImage}
            alt="image"
            className="h-32 w-auto"
          />
        </div>
        <h2 className="mt-4 text-xl font-semibold text-gray-700">
          Transaction pin changed
        </h2>
        <p className="mt-1 text-center text-sm text-gray-600">
          Your transaction pin has been changed successfully.
        </p>
        <div className="mx-auto mt-6 w-full max-w-[250px] space-y-4 pb-2">
          <button
            onClick={() => props?.closeModal()}
            className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-olivine-500 p-8 py-2.5 text-sm font-semibold text-white  transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
          >
            Continue
          </button>
          <button
            onClick={() => props?.closeModal()}
            className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-white p-8 py-2.5 text-sm font-semibold text-olivine-500  transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default TransactionPinConfirmation;
