import { authService } from "../../services/authService";

const customerId = authService.getCurrentUserCustomerId();
const userEmail = authService.getCurrentUserEmail();

const stockCheckHandler = async (cart, custom, dispatch, stockCheck) => {
  try {
    const plans =
      cart && cart?.items?.length
        ? cart?.items?.map((item) => {
            return {
              plan_code: item.JSON_String.plan.plan_code,
              quantity: item.JSON_String.plan.plan_quantity,
              price: item.JSON_String.plan.plan_price,
            };
          })
        : [];

    const items =
      custom && custom?.length
        ? custom?.map((item) => {
            return {
              item_id: item.item_id,
              quantity: item.quantity,
              price: item.rate,
            };
          })
        : [];

    const payload = {
      customer_id: +customerId,
      plans,
      items,
    };

    return dispatch(stockCheck(payload))
      .unwrap()
      .then((res) => {
        return res;
      })
      .catch((err) => err);
  } catch (err) {
    return err;
  }
};

const updateCart = (
  dispatch,
  productPurchaseGet,
  calculateTotalPlansQty,
  setTotalPlansQty
) => {
  try {
    const data = {
      user_email: userEmail,
    };

    dispatch(productPurchaseGet(data))
      .unwrap()
      .then((res) => {
        const updatedQty = calculateTotalPlansQty(res?.items);
        setTotalPlansQty(updatedQty);
      });
  } catch (err) {
    return err;
  }
};

function wait(ms, setTimeout) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export { stockCheckHandler, updateCart, wait };
