import React from 'react';
import { BiArrowBack } from "react-icons/bi";
import { GoSearch } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import EmptySearchImage from '../../assets/EmptySearceImage.svg';

function SearchPageEmpty(props) {
    const navigate = useNavigate();
    const {searchResult} = props;

  return (
    <>
       {!searchResult &&
        <div className='pt-8 px-3 xsm:px-4 sm:px-8 md:px-10 lg:px-16 pb-3'>

        <div className='mt-16 flex items-center justify-between text-sm'>
            <div className='font-semibold text-gray-600'>
                <span className='sm:hidden'>Showing {searchResult?.length} Results</span>
                <span className='hidden sm:inline'>Showing {searchResult?.length} Results for searched Item name</span></div>
            <div className='font-semibold text-olivine-500 cursor-pointer hover:text-olivine-600 hover:underline'>Clear Filter</div>
        </div>
        <div className='mt-8'>
            <div className="mt-2 flex justify-start text-sm">
                <img src= {EmptySearchImage} className='h-40 w-full' alt=''/>
            </div>
            <div className="mt-4 flex justify-center font-semibold text-base xsm:text-lg ">
            <div className="text-gray-800 font-bold">No Results</div>
            </div>
            <div className="mt-2 text-center text-xs">
            <div className="text-gray-500">Sorry Your search ‘’Input searched word/phrase” did not match any item. Please try another phrase or word</div>
            </div>
            <div className="mt-5 pb-4 text-center tracking-widest">
            <button onClick={()=> navigate('/')}
                className="rounded-md border-[1px] border-olivine-500 px-12 xsm:px-16 sm:px-20 py-2 text-sm font-semibold text-olivine-500 transition duration-300 active:ring-1 active:ring-olivine-500"
            >
                BACK TO HOME PAGE
            </button>
            </div>
        </div>
    </div>
    }
    </>
 
  
  )
}

export default SearchPageEmpty