import React, { useState } from "react";
import Logo from "../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import { notification } from "../core/helpers";
import { createTransactionPin } from "../store/actions";
import { useDispatch } from "react-redux";
import { BarLoader } from "react-spinners";

function CreateNewPin() {
  const verification_code = localStorage.getItem("grocedy_verification_code");
  const user_email = localStorage.getItem("grocedy_user_email");
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const payload = {
        sql_ref_no: "19",
        email_address: user_email,
        verification_code,
        pin_number: otp.join(""),
        sql_option: "1",
      };

      dispatch(createTransactionPin(payload))
        .unwrap()
        .then(() => {
          setOtp([...otp.map((v) => "")]);
          setIsLoading(true);

          Store.addNotification({
            ...notification,
            type: "success",
            title: "Successful",
            message: "Transaction Pin set",
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });

          navigate("/home/todo");
        })
        .catch((err) => {
          setOtp("");
          setIsLoading(false);

          Store.addNotification({
            ...notification,
            type: "danger",
            title: "Something Went Wrong",
            message: "",
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });

          navigate("/login");
          return err;
        });
    } catch (err) {
      setIsLoading(false);
      return err;
    }
  };

  return (
    <>
      {isLoading ? (
        <div>
          <BarLoader />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="h-screen w-screen p-8">
          <div className="mb-4 md:mb-0">
            <Link to="/">
              <img
                src={Logo}
                className="h-20 w-24 flex-shrink-0 object-contain"
                alt=""
              />
            </Link>
          </div>
          <div className="mx-auto h-[420px] w-[430px] rounded-md bg-[#F6F5F5] px-12 py-12 md:h-[440px] md:w-[600px] md:px-16">
            <div>
              <div className="text-2xl font-bold text-gray-600">
                Confirm PIN
              </div>
              <div className="mt-10 text-gray-500">
                Confirm your 4-digit Transaction PIN
              </div>
            </div>
            <div className="mt-12">
              <form onSubmit={handleSubmit}>
                <div className="relative mx-12 mt-8 flex justify-between rounded-md  md:mx-28">
                  {otp.map((data, index) => {
                    return (
                      <input
                        type="text"
                        maxLength={1}
                        className="block h-12 w-12 rounded-md bg-white text-center text-lg focus:border-olivine-500 focus:ring-olivine-500"
                        name="opt"
                        key={index}
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onFocus={(e) => e.target.select()}
                      />
                    );
                  })}
                </div>
                <button
                  onClick={(e) => alert(otp.join(""))}
                  className="mt-12 w-full rounded-md bg-olivine-500 py-3 text-white"
                >
                  CONFIRM PIN
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreateNewPin;
