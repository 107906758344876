import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HiHome } from "react-icons/hi";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { BiPlus, BiMinus } from "react-icons/bi";
import { Popover } from "@headlessui/react";

import { useNavigate } from "react-router-dom";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import CurrencyFormat from "react-currency-format";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { authService } from "../../../services/authService";
import LogicContext from "../../../context/LogicContext";
import { CgClose } from "react-icons/cg";
import AccountImage from "../../../assets/LogIntoYourAccount.svg";
import MinimumBasketImage from "../../../assets/minimumBasketImage.svg";

function MyBasketList() {
  const navigate = useNavigate();
  const location = useLocation();
  const logicContext = useContext(LogicContext);
  const [count, setCount] = useState(3);
  const [discountValue, setDisCountValue] = useState(0);
  const [priceAlert, showPriceAlert] = useState(false);

  const rxjsStore = useContext(RxjsStoreContext);
  const [open, setOpen] = React.useState(false);
  const [basketProductMap, setBasketProductMap] = useState(new Map());

  useEffect(() => {
    rxjsStore.getUserSelectedBasket().subscribe((data) => {
      let productSelected = new Map(data);
      setBasketProductMap(productSelected);
    });

    return () => {
      updateBasketList();
      //   rxjsStore.saveBasketTemporarily();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateBasketList = () => {
    for (let [key, product] of basketProductMap) {
      rxjsStore.setUserSelectedBasket(product, product.qty);
    }
  };

  function removeQuantity(key) {
    basketProductMap.delete(key);
    rxjsStore.removeItemFromBasket(key);
    setCount((prev) => (prev += 1));
  }

  function incrementQuantity(key, qty) {
    rxjsStore.incrementQuantity(key, qty);
    // setCount(prev => prev += 1);
  }

  function decrementProduct(key, qty) {
    rxjsStore.decrementQuantity(key, qty);
  }

  function calculateSaving(basketListMap) {
    let total = 0;
    for (let [key, product] of basketListMap) {
      total += product?.item_rate_market * product.qty;
    }
    return total - grandTotal();
  }

  function displayProduct() {
    const comps = [];
    basketProductMap.forEach((product, index) =>
      comps.push(
        <div
          key={index}
          className="flex justify-between font-semibold sm:grid sm:grid-cols-4 sm:items-center"
        >
          <div className="mt-4 grid justify-start sm:col-span-2">
            <div className="flex items-center space-x-2">
              <div className="rounded-md border-[1px] border-gray-300">
                <img
                  src={product?.item_image_url}
                  className="aspect-auto h-9 w-9 rounded-md object-cover sm:h-12 sm:w-12"
                  alt=""
                />
              </div>
              <div className="-space-y-[6px] text-[9px] font-semibold text-gray-500 sm:-space-y-1 sm:text-[10px]">
                <div>{product?.name} </div>
                <div>
                  <div className="flex items-center space-x-1">
                    <p>Grocedy Price:</p>
                    <CurrencyFormat
                      className={"font-bold "}
                      value={Number.parseFloat(
                        product?.item_rate_zoho
                      )?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </div>
                </div>
                <div className="flex items-center space-x-1 text-red-400">
                  <p>Market Price:</p>
                  <p className="line-through">
                    <CurrencyFormat
                      className={"font-bold "}
                      value={Number.parseFloat(
                        product?.item_rate_market
                      )?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 xsm:mt-4 xsm:flex xsm:items-center xsm:space-x-2 sm:col-span-1 sm:grid sm:justify-center">
            <div className="grid h-6 w-20 grid-cols-3 rounded border-[0.5px] border-gray-400 sm:w-24">
              <div className="col-span-1 grid items-center justify-center">
                <BiMinus
                  o
                  onClick={() => decrementProduct(product?.sku, 1)}
                  className="h-4 w-4 cursor-pointer rounded-full text-gray-600 hover:bg-gray-100 active:bg-gray-200"
                />
              </div>

              <div className="col-span-1 grid items-center justify-center text-gray-600">
                {product?.qty}
              </div>
              <div className="col-span-1 grid items-center justify-center">
                <BiPlus
                  onClick={() => incrementQuantity(product?.sku, 1)}
                  className="h-4 w-4 cursor-pointer rounded-full text-gray-600 hover:bg-gray-100 active:bg-gray-200"
                />
              </div>
            </div>
            <div
              className="mt-[1px] grid cursor-pointer justify-center text-[10px]
                     text-red-400 hover:underline hover:underline-offset-1 xsm:mt-0 sm:mt-[1px]"
              onClick={() => removeQuantity(product?.sku)}
            >
              REMOVE
            </div>
          </div>
          <div className="mt-2 hidden sm:col-span-1 sm:grid sm:justify-end">
            <div className="text-xs font-medium text-gray-600">
              <CurrencyFormat
                className={"font-medium  "}
                value={Number.parseFloat(
                  Number(product?.item_rate_zoho) * product?.qty
                )?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />
            </div>
          </div>
        </div>
      )
    );
    return comps;
  }

  function grandTotal() {
    let total = 0;
    for (let [key, product] of basketProductMap) {
      total += Number(product?.item_rate_zoho) * product?.qty;
    }
    if (discountValue > 0) {
      let discountAmount = (discountValue / 100) * total;

      return total - discountAmount;
    } else {
      return total;
    }
  }

  const proceedToCashOut = () => {
    const grandTotalPrice = grandTotal();
    if (grandTotalPrice < 15000) {
      return showPriceAlert(true);
    }
    if (authService.isLogin()) {
      navigate("/cart"); //go to basket checkout
    } else {
      logicContext.setPreviousUrl(location.pathname);
      setOpen(true);
    }
  };

  const continueToLogin = () => {
    navigate("/login");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/*  Alert */}
      {/* <LogIntoYourAccount /> */}

      <div>
        <Dialog
          className="h-full pb-16"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="h-hug w-full rounded-md border-gray-300 bg-gray-100 shadow shadow-gray-400">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="flex items-center justify-end">
                  <button
                    onClick={handleClose}
                    className="rounded-full p-2 active:bg-gray-200"
                  >
                    <CgClose className="h-5 w-5 text-gravel-500" />
                  </button>
                </div>
                <div className="mt-2 flex justify-start text-sm">
                  <img src={AccountImage} className="h-40 w-full" alt="" />
                </div>
                <div className="cursor mt-4 flex justify-center text-base font-semibold xsm:text-lg ">
                  <div className=" font-bold text-gray-800">
                    Log into your account{" "}
                  </div>
                </div>
                <div className="mt-2 text-center text-xs">
                  <div className="text-gray-500">
                    You are currently not logged into your Grocedy account.
                    Please login or create a new account to proceed to check
                    out.
                  </div>
                </div>
                <div className="mt-5 pb-4 text-center tracking-wider xsm:px-6 sm:px-16">
                  <div
                    onClick={continueToLogin}
                    className="!cursor-pointer rounded-md border-[1px] border-olivine-500 py-2 text-sm font-semibold text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-500"
                  >
                    Log into Account
                  </div>
                  <div className="mt-4">
                    <div
                      onClick={() => navigate("/register")}
                      className="!cursor-pointer py-1 text-sm font-semibold text-olivine-500 opacity-100 hover:underline hover:underline-offset-4 hover:opacity-80"
                    >
                      Create new Account
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
      </div>
      {/* End  Alert */}

      <div className=" px-3 pb-3 pt-8 xsm:px-4 sm:px-8 md:px-10 lg:px-16">
        <div className="flex items-center space-x-3 text-xs font-semibold text-gray-500">
          <div className="flex items-center space-x-1">
            <div>
              <HiHome
                onClick={() => navigate("/")}
                className="cursor h-3.5 w-3.5"
              />
            </div>
            <div>
              <IoIosArrowForward className="h-[11px] w-[11px]" />
            </div>
          </div>

          <div className="cursor flex items-center space-x-1">
            <div onClick={() => navigate("/basket/create-basket")}>
              Select Product(s)
            </div>
            <div>
              <IoIosArrowForward className="cursor h-[11px] w-[11px]" />
            </div>
          </div>
          <div className="cursor  flex items-center space-x-1 text-olivine-500">
            <div>Basket list</div>
            <div>
              <IoIosArrowForward className="h-[11px] w-[11px]" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex cursor-pointer items-center space-x-2 pt-4 text-sm font-semibold text-olivine-500">
            <div>
              <IoIosArrowBack className="h-4 w-4" />
            </div>
            <div
              onClick={() => navigate("/basket/create-basket")}
              className="cursor"
            >
              Continue Shopping
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="w-full max-w-3xl pb-16">
            <div className="mt-6 text-xl font-semibold text-gray-600">
              My Basket
            </div>
            <div className="mt-4 text-sm">
              <div className="hidden grid-cols-4 font-semibold text-gray-500 sm:grid">
                <div className="col-span-2 grid justify-start">PRODUCT</div>
                <div className="col-span-1 grid justify-center">QUANTITY</div>
                <div className="col-span-1 grid justify-end">TOTAL</div>
              </div>
              <hr className="mt-[2px] h-[2px] bg-gray-400" />

              {displayProduct()}
              <div className="mt-8">
                <div className="text-sm font-bold tracking-wider text-gray-600">
                  Grocedy Savings <span>🎉</span>
                </div>
                <div className="mt-[2px] text-xs font-semibold tracking-wider text-gray-600">
                  You have saved
                  <CurrencyFormat
                    className={"m-2  font-bold text-red-500 "}
                    value={Number.parseFloat(
                      Number.parseFloat(calculateSaving(basketProductMap))
                    )?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                  shopping with us.
                </div>
              </div>
              <div className="mt-8">
                <label className="pl-3 text-xs font-semibold text-gray-600">
                  Additional Comments
                </label>
                <textarea
                  placeholder="Write your message..."
                  rows="4"
                  className="w-full rounded-md border-[0.5px] border-gray-400 bg-[#F0F2E8] text-sm font-semibold text-gray-500 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                ></textarea>
              </div>
              <div>
                <div className="mt-4 grid justify-end">
                  <div className="flex flex-col text-right">
                    <div className="mt-4 pr-3 text-sm font-bold tracking-wider text-gray-600">
                      TOTAL:
                      <CurrencyFormat
                        className={"font-bold "}
                        value={Number.parseFloat(grandTotal())?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </div>
                    <div className="mt-1 pr-3 text-[9px] font-semibold tracking-wider text-gray-500">
                      *Shipping and to be calculated at checkout
                    </div>
                    <div className="">
                      <Popover className="relative">
                        <>
                          <Popover.Button
                            onClick={() => proceedToCashOut()}
                            className={` w-full focus:text-olivine-700 focus:outline-none lg:w-auto`}
                          >
                            <div className="mt-[1px] rounded-md bg-olivine-500 px-10 py-3 text-sm font-semibold tracking-wider text-white transition duration-300 hover:bg-olivine-600">
                              <div>PROCEED TO CHECKOUT</div>
                            </div>
                          </Popover.Button>
                        </>
                      </Popover>
                    </div>
                  </div>
                </div>
                {/* end alert */}

                {/*  Price Alert */}
                {priceAlert && (
                  <div>
                    <Dialog
                      className="h-full"
                      open={true}
                      onClose={() => showPriceAlert(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <div className="h-hug w-full rounded-md border-gray-300 bg-gray-100 shadow shadow-gray-400">
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <div className="flex items-center justify-end">
                              <button
                                onClick={() => showPriceAlert(false)}
                                className="rounded-full p-2 active:bg-gray-200"
                              >
                                <CgClose className="h-5 w-5 text-gravel-500" />
                              </button>
                            </div>
                            <div className="mt-2 flex justify-start text-sm">
                              <img
                                src={MinimumBasketImage}
                                className="h-32 w-full"
                                alt=""
                              />
                            </div>
                            <div className="cursor mt-4 flex justify-center text-base font-semibold xsm:text-lg ">
                              <div className=" font-bold text-gray-800">
                                Note
                              </div>
                            </div>
                            <div className="mt-2 max-w-md text-center text-xs">
                              <div className="text-gray-500">
                                "Please note that our minimum purchase amount is
                                N15,000. Unfortunately, your current basket
                                value does not meet this requirement. To proceed
                                with your purchase, kindly add more items to
                                your basket."
                              </div>
                            </div>
                            <div className="mt-5 pb-4 text-center tracking-wider xsm:px-6 sm:px-16">
                              <div
                                onClick={() => showPriceAlert(false)}
                                className="!cursor-pointer rounded-md border-[1px] border-olivine-500 px-6 py-2 text-sm font-semibold text-olivine-500 transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 active:ring-1 active:ring-olivine-500"
                              >
                                Continue shopping
                              </div>
                            </div>
                          </DialogContentText>
                        </DialogContent>
                      </div>
                    </Dialog>
                  </div>
                )}

                {/* end Price alert */}
                <div></div>
                <div></div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyBasketList;
