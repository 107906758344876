import React, { useEffect, useState } from "react";
import BVN from "./bvn";
import DeliveryAddress from "./deliveryAddress";
import NIN from "./nin";
import Phone from "./phone";
import Pin from "./pin";
import ProfileDetailsModal from "./ProfileDetails";
import RecurringMonthlyPopover from "../RecurringMontlyPopover";
import TransactionPinPopover from "../TransactionPinPopover";
import FundYourWalletPopover from "../FundYourWalletPopover";
import PaywithCardPopover from "../PaywithCardPopover";
import AddCardPopover from "../AddCardPopover";
import PaymentMethodPopoverTwo from "../PaymentMethodPopover_Two";
import FundYourWalletPopoverThree from "../FundYourWalletPopover_Three";
import BuyAirtimePopover from "../BuyAirtimePopover";
import BuyDataPopover from "../BuyDataPopover";
import DeliveryAddressPopover from "../pages/gift_a_basket/DeliveryAddressPopover";
import OrderSummaryPopover from "../pages/gift_a_basket/OrderSummaryPopover";
import SufficientBallancePopover from "../pages/cartPage/SufficientBallancePopover";
import InsufficientBallancePopover from "../pages/cartPage/InsufficientBallancePopover";
import FundYourWalletPopover_Two from "../FundYourWalletPopover_Two";
import CancelAutofundPopover from "../pages/upgradePlan/cancelAutofundPopover";
import CreateYourTransactionPin from "../pages/cartPage/CreateYourTransactionPin";
import CoporateDetailsPopUp from "../CoporateDetailsPopUp";
import ThanksForChoosingGrocedy from "./corporatePopups/ThanksForChoosingGrocedy";
// import BasketRecipePopover from "../pages/create_basket_page/BasketRecipePopOver";

const Modal = ({
  visible,
  onClose,
  selected,
  switcher,
  customGiftMyBasket,
  cartAmount,
  paymentProceed,
  cancelFund,
  handleRedirect,
}) => {
  const [showingCardPop, setShowingCardPop] = useState(false);
  const [showingSetupPin, setShowingSetupPin] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [flutterPay, setFlutterPay] = useState(false);
  const [transPin, setTransPin] = useState(false);
  const [orderPop, setOrderPop] = useState(false);
  const [isThanks, setIsThanks] = useState(false);
  // const [detailsPop, setDetailsPop] = useState(false);
  const [insufficientPop, setInsufficientPop] = useState(false);
  const [insufficientPopOption, setInsufficientPopOption] = useState(false);

  let selectedModal;

  const handleOnClose = (e) => {
    if (e.target.id === "container") onClose();
  };

  const handleShowing = () => {
    setShowingCardPop(!showingCardPop);
  };

  const handleShowingPinCreate = () => {
    setShowingSetupPin(!showingSetupPin);
  };

  const handleAddCard = () => {
    setAddCard(!addCard);
  };

  const handleFlutterPay = () => {
    setFlutterPay(!flutterPay);
  };

  const handlePin = () => {
    setTransPin(!transPin);
  };

  const handleOrder = () => {
    setOrderPop(!orderPop);
  };

  const handleThanks = () => {
    setIsThanks(!isThanks);
  };

  const handleInsufficient = () => {
    setInsufficientPop(!insufficientPop);
  };

  const handleInsufficientOption = () => {
    setInsufficientPopOption(!insufficientPopOption);
  };

  if (
    !visible &&
    !showingCardPop &&
    !addCard &&
    !flutterPay &&
    !orderPop &&
    !insufficientPop &&
    !insufficientPopOption &&
    !showingSetupPin &&
    !isThanks
  )
    return;

  switch (selected) {
    case "profile":
      selectedModal = <ProfileDetailsModal onClose={onClose} />;
      break;
    case "addDescription":
      selectedModal = <DeliveryAddress onClose={onClose} />;
      break;
    case "addBvn":
      selectedModal = <BVN onClose={onClose} />;
      break;
    case "addNin":
      selectedModal = <NIN onClose={onClose} />;
      break;
    case "phone":
      selectedModal = <Phone onClose={onClose} />;
      break;
    case "pin":
      selectedModal = <Pin onClose={onClose} />;
      break;
    case "addId":
      // selectedModal = <ValidId onClose={onClose} />;
      selectedModal = (
        <CoporateDetailsPopUp onClose={onClose} handleThanks={handleThanks} />
      );
      break;
    case "recurr":
      selectedModal = (
        <RecurringMonthlyPopover onClose={onClose} handleSwitch={switcher} />
      );
      break;
    case "transactPin":
      selectedModal = <TransactionPinPopover onClose={onClose} />;
      break;
    case "wallet":
      selectedModal = (
        <FundYourWalletPopover_Two
          closer={onClose}
          handleRedirect={handleRedirect}
        />
      );
      break;
    case "sufficient":
      selectedModal = (
        <SufficientBallancePopover
          paymentProceed={paymentProceed}
          cartAmount={cartAmount}
          onClose={onClose}
          handleShowingPinCreate={handleShowingPinCreate}
        />
      );
      break;
    case "insufficient":
      selectedModal = (
        <InsufficientBallancePopover
          cartAmount={cartAmount}
          onClose={onClose}
          handleInsufficient={handleInsufficient}
        />
      );
      break;
    case "fundUrWallet":
      selectedModal = (
        <FundYourWalletPopover
          handleShowing={handleShowing}
          handleAddCard={handleAddCard}
          onClose={onClose}
        />
      );
      break;
    case "paymentOptions":
      selectedModal = (
        <PaymentMethodPopoverTwo
          handleFlutterPay={handleFlutterPay}
          closer={onClose}
        />
      );
      break;

    case "flutter":
      selectedModal = (
        <FundYourWalletPopoverThree
          handleFlutterPay={handleFlutterPay}
          onClose={onClose}
        />
      );
      break;
    case "airtime":
      selectedModal = (
        <BuyAirtimePopover handlePin={handlePin} onClose={onClose} />
      );
      break;
    case "data":
      selectedModal = <BuyDataPopover onClose={onClose} />;
      break;
    case "profileSaved":
      selectedModal = (
        <DeliveryAddressPopover onClose={onClose} handleOrder={handleOrder} />
      );
      break;
    case "cancel-autofund":
      selectedModal = (
        <CancelAutofundPopover onClose={onClose} cancelFund={cancelFund} />
      );
      break;
    default:
      return;
  }

  if (isThanks) {
    selectedModal = (
      <ThanksForChoosingGrocedy onClose={onClose} handleThanks={handleThanks} />
    );
  }

  if (showingCardPop) {
    selectedModal = (
      <PaywithCardPopover handleShowing={handleShowing} onClose={onClose} />
    );
  }

  if (showingSetupPin) {
    selectedModal = (
      <CreateYourTransactionPin
        onClose={onClose}
        handleShowingPinCreate={handleShowingPinCreate}
      />
    );
  }

  if (addCard) {
    selectedModal = (
      <AddCardPopover handleAddCard={handleAddCard} onClose={onClose} />
    );
  }

  if (flutterPay) {
    selectedModal = (
      <FundYourWalletPopoverThree
        handleFlutterPay={handleFlutterPay}
        onClose={onClose}
      />
    );
  }

  if (orderPop) {
    selectedModal = (
      <OrderSummaryPopover
        customGiftMyBasket={customGiftMyBasket}
        onClose={onClose}
        handleOrder={handleOrder}
        // handlePersonalPop={handlePersonalPop}
      />
    );
  }

  if (insufficientPop) {
    selectedModal = (
      <FundYourWalletPopover_Two
        cart
        closer={onClose}
        handleInsufficientOption={handleInsufficientOption}
      />
    );
  }

  if (insufficientPopOption) {
    selectedModal = (
      <PaymentMethodPopoverTwo
        handleFlutterPay={handleFlutterPay}
        handleInsufficientOption={handleInsufficientOption}
        closer={onClose}
      />
    );
  }

  if (flutterPay && insufficientPopOption) {
    selectedModal = (
      <FundYourWalletPopoverThree
        insufficientPopOption
        handleInsufficientOption={handleInsufficientOption}
        handleFlutterPay={handleFlutterPay}
        onClose={onClose}
      />
    );
  }

  return (
    <div
      onClick={handleOnClose}
      className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30 px-2"
      id="container"
    >
      {selectedModal}
    </div>
  );
};

export default Modal;
