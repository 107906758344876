import React, { useEffect, useState } from "react";

function PlansCard({
  images,
  product,
  price,
  quantity,
  rate,
  plan_id,
  clicked,
  goToCart,
}) {
  const [planImage, setPlanImage] = useState([]);

  useEffect(() => {
    if (images?.length && plan_id) {
      const planImages = images?.filter((img) => img?.zoho_plan_id === plan_id);

      setPlanImage(planImages);
    }
  }, [plan_id, images]);

  return (
    <div className="h-full w-full rounded-md bg-caper-500 px-3 py-4 shadow shadow-gray-500 xsm:px-4 sm:max-w-md">
      <img
        src={planImage && planImage?.length && planImage[0].plan_image_url}
        className="aspect-auto h-48 w-full rounded-md object-cover"
        alt=""
      />
      <h1 className=" non-italic mt-4 align-top font-sans text-xl font-extrabold leading-10 text-gravel-500 xsm:text-2xl">
        {product}
      </h1>
      <h2 className="text-font text-sm font-bold text-olivine-500 xsm:text-base">
        {rate
          ? `$${Number(price * +rate).toFixed(2)}/month`
          : `₦${Number(price).toLocaleString()}/month`}
      </h2>
      <div className="text-sm xsm:text-base">
        <span className="text-gravel-500 opacity-70">
          Up to {quantity} items in your basket
        </span>
        <span className="font-bold text-gray-600">
          (Quantity and price are subject to current market rates and may
          change)
        </span>
      </div>
      <div className="mt-4 text-center text-sm xsm:text-base">
        <div>
          <button
            onClick={goToCart ? goToCart : null}
            className="w-full rounded-md bg-olivine-500 py-3 font-medium text-white hover:bg-olivine-600"
          >
            SUBSCRIBE
          </button>
        </div>
        <div onClick={clicked ? clicked : null}>
          <button className="mb-3 mt-4 w-full rounded-md bg-white py-3 font-medium text-olivine-500 hover:bg-olivine-100">
            VIEW PLANS
          </button>
        </div>
      </div>
    </div>
  );
}

export default PlansCard;
