import React, { useEffect, useState } from "react";
import Container from "../../../components/pages/my_container/Container";
import CowriesImage from "../../../assets/cowriesImage.svg";
import RewardIcon from "../../../assets/rewardIcon.svg";
import SingleCowryImage from "../../../assets/singleCowryImage.svg";
import TierImage from "../../../assets/tierImage.svg";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { IoChevronForward } from "react-icons/io5";
import ProgressBar from "@ramonak/react-progress-bar";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import RewardCenterDisclosureComponent from "./RewardCenterDisclosureComponent";
import { Dialog, Transition } from "@headlessui/react";
import RedeemCowriesPopup from "./popups/RedeemCowriesPopup";
import IsLoadingOne from "../../IsLoading_One";
import { useDispatch } from "react-redux";
import { authService } from "../../../services/authService";
import { fetchRewardBankInfo } from "../../../store/actions";
import CowriesRedeemSeccessfullPopup from "./popups/CowriesRedeemSeccessfullPopup";
import moment from "moment";

const RewardCenter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fetchedRewardBankInfo, setFetchedRewardBankInfo] = useState({});
  const [userTier, setUserTier] = useState("");
  const [userCowriesCount, setUserCowriesCount] = useState(0);
  const [totalPossibleCount, setTotalPossibleCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const customerId = authService.getCurrentUserCustomerId();
  const userReferralCode = authService.getCurrentUserReferralCode();

  useEffect(() => {
    if (customerId) {
      setIsLoading(true);
      dispatch(fetchRewardBankInfo(customerId))
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          setFetchedRewardBankInfo(res);
        })
        .catch((err) => err);
    }
  }, [customerId]);

  useEffect(() => {
    if (fetchedRewardBankInfo?.reward_balance?.length) {
      setUserTier(fetchedRewardBankInfo?.reward_balance[0]?.membership_name);
    }

    if (fetchedRewardBankInfo?.reward_balance?.length) {
      setUserCowriesCount(
        fetchedRewardBankInfo?.reward_balance[0]
          ?.during_membership_reward_cowries_quantity
      );
    }

    if (fetchedRewardBankInfo?.membership_info?.length) {
      setTotalPossibleCount(
        fetchedRewardBankInfo?.membership_info[2]?.minimum_cowries_quantity
      );
    }
  }, [fetchedRewardBankInfo]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function strToArray(desc) {
    const convertStr = desc?.split("\n");

    return convertStr.map((item) => <p>{`${item}`}</p>);
  }

  return (
    <>
      {isLoading ? (
        <div>
          <IsLoadingOne message="Loading" />
        </div>
      ) : (
        <>
          <SignedInNavbarImproved />
          <div className="bg-white">
            <Container className="">
              <div className="mt-10 flex flex-row items-center justify-between">
                <div className="flex items-center space-x-2 sm:space-x-4">
                  <div className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-100">
                    <BiArrowBack
                      onClick={() => navigate(-1)}
                      className="h-5 w-5 cursor-pointer text-gray-600"
                    />
                  </div>
                  <h2 className="text-lg font-bold text-gray-700 sm:text-2xl">
                    Reward Bank
                  </h2>
                </div>
                <Link to="/reward-bank/history">
                  <button className="border-b-[1.5px] border-b-white text-lg font-semibold text-[#73964C] hover:border-b-[#73964C]">
                    Reward History
                  </button>
                </Link>
              </div>
              <div className="mt-6  md:grid md:grid-cols-2 md:gap-4">
                <div className="flex w-full flex-col justify-between rounded-lg border-[1px] border-[#DFE5D1] bg-[#F9F9F9] p-4">
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center space-x-2">
                      <div>
                        <img
                          src={CowriesImage}
                          alt="icon"
                          className="h-12 w-12"
                        />
                      </div>
                      <p className="text-lg font-semibold text-gray-600">
                        Cowries
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-xl font-semibold text-gray-600">
                        {fetchedRewardBankInfo &&
                        fetchedRewardBankInfo?.reward_balance?.length
                          ? `${fetchedRewardBankInfo?.reward_balance[0]?.balance_cowries_quantity} Cowries`
                          : null}
                      </p>
                      <p className="mt-[2px] text-xs font-medium text-gray-400">
                        {fetchedRewardBankInfo &&
                        fetchedRewardBankInfo?.reward_balance?.length
                          ? `Your Cowries Equal ₦${Number(
                              fetchedRewardBankInfo?.reward_balance[0]
                                ?.balance_cowries_amount
                            ).toLocaleString()}`
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="mt-6">
                    <button
                      onClick={openModal}
                      className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-4 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 xsm:text-sm"
                    >
                      Redeem to Wallet
                    </button>
                  </div>
                </div>
                <div
                  onClick={() => navigate("/reward-bank/tier-page")}
                  className="mt-6 w-full cursor-pointer rounded-lg border-[1px] border-[#F2C94C] bg-[#FFFDF1] p-4 hover:border-gray-300 md:mt-0"
                >
                  <div className="flex  flex-row items-center space-x-2">
                    <div>
                      <img src={TierImage} alt="icon" className="h-12 w-12" />
                    </div>
                    <div className="flex-1">
                      <div className="flex flex-row items-center justify-between">
                        <p className="text-base font-semibold text-gray-600">
                          {fetchedRewardBankInfo &&
                          fetchedRewardBankInfo?.reward_balance?.length
                            ? fetchedRewardBankInfo?.reward_balance[0]
                                ?.membership_name
                            : null}
                        </p>
                        <div>
                          <IoChevronForward className="h-5 w-5 text-[#F69E18]" />
                        </div>
                      </div>
                      <p className="mt-[2px] text-xs font-medium text-gray-400">
                        {fetchedRewardBankInfo &&
                        fetchedRewardBankInfo?.membership_info?.length
                          ? userCowriesCount <
                            fetchedRewardBankInfo?.membership_info[0]
                              ?.maximum_cowries_quantity
                            ? `Earn ${
                                fetchedRewardBankInfo?.membership_info[1]
                                  ?.minimum_cowries_quantity - userCowriesCount
                              } more Tier cowries to reach ${
                                fetchedRewardBankInfo?.membership_info[1]
                                  ?.membership_name
                              } Tier`
                            : null
                          : null}

                        {fetchedRewardBankInfo &&
                        fetchedRewardBankInfo?.membership_info?.length
                          ? userCowriesCount >
                              fetchedRewardBankInfo?.membership_info[1]
                                ?.minimum_cowries_quantity &&
                            userCowriesCount <
                              fetchedRewardBankInfo?.membership_info[2]
                                ?.minimum_cowries_quantity
                            ? `Earn ${
                                fetchedRewardBankInfo?.membership_info[2]
                                  ?.minimum_cowries_quantity - userCowriesCount
                              } more Tier cowries to reach ${
                                fetchedRewardBankInfo?.membership_info[2]
                                  ?.membership_name
                              } Tier`
                            : null
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4">
                    <ProgressBar
                      height="12px"
                      width="100%"
                      bgColor="#F69E18"
                      maxCompleted={totalPossibleCount}
                      completed={`${userCowriesCount}`}
                      labelSize="10px"
                      labelAlignment="right"
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between text-xs font-medium text-gray-600">
                    {userTier === "Starter" ? (
                      <>
                        <>
                          <div>
                            <p className="text-[#F69E18]">
                              {fetchedRewardBankInfo &&
                                fetchedRewardBankInfo?.membership_info
                                  ?.length &&
                                fetchedRewardBankInfo?.membership_info[0]
                                  ?.membership_name}
                            </p>
                            <p className="text-[#F69E18]">
                              {fetchedRewardBankInfo &&
                                fetchedRewardBankInfo?.membership_info
                                  ?.length &&
                                fetchedRewardBankInfo?.membership_info[0]
                                  ?.minimum_cowries_quantity}
                            </p>
                          </div>

                          <div>
                            <p className="">
                              {fetchedRewardBankInfo &&
                                fetchedRewardBankInfo?.membership_info
                                  ?.length &&
                                fetchedRewardBankInfo?.membership_info[1]
                                  ?.membership_name}
                            </p>
                            <p className="">
                              {fetchedRewardBankInfo &&
                                fetchedRewardBankInfo?.membership_info
                                  ?.length &&
                                fetchedRewardBankInfo?.membership_info[1]
                                  ?.minimum_cowries_quantity}
                            </p>
                          </div>
                        </>
                      </>
                    ) : null}
                    {userTier === "Pro" ? (
                      <>
                        <div>
                          <p className="text-[#F69E18]">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[1]
                                ?.membership_name}
                          </p>
                          <p className="text-[#F69E18]">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[1]
                                ?.minimum_cowries_quantity}
                          </p>
                        </div>

                        <div>
                          <p className="">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[2]
                                ?.membership_name}
                          </p>
                          <p className="">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[2]
                                ?.minimum_cowries_quantity}
                          </p>
                        </div>
                      </>
                    ) : null}
                    {userTier === "Legend" ? (
                      <>
                        <div>
                          <p className="">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[1]
                                ?.membership_name}
                          </p>
                          <p className="">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[1]
                                ?.minimum_cowries_quantity}
                          </p>
                        </div>

                        <div>
                          <p className="text-[#F69E18]">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[2]
                                ?.membership_name}
                          </p>
                          <p className="text-[#F69E18]">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[2]
                                ?.minimum_cowries_quantity}
                          </p>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="mt-6 flex flex-row items-center justify-between text-sm text-gray-500">
                    <p className="text-sm font-semibold text-gray-500">
                      {fetchedRewardBankInfo &&
                      fetchedRewardBankInfo?.reward_balance?.length
                        ? fetchedRewardBankInfo?.reward_balance[0]
                            ?.membership_activation_date &&
                          `Valid from ${moment(
                            fetchedRewardBankInfo?.reward_balance[0]
                              ?.membership_activation_date
                          ).format("ll")}`
                        : null}
                    </p>
                    <p className="text-sm font-semibold text-gray-500">
                      {fetchedRewardBankInfo &&
                      fetchedRewardBankInfo?.reward_balance?.length
                        ? fetchedRewardBankInfo?.reward_balance[0]
                            ?.membership_expiry_date &&
                          `Valid until ${moment(
                            fetchedRewardBankInfo?.reward_balance[0]
                              ?.membership_expiry_date
                          ).format("ll")}`
                        : null}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                <Tab.Group>
                  {/*   Tab section header */}
                  <Tab.List className="flex w-fit flex-row flex-wrap items-center text-sm">
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected
                              ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-1 text-olivine-500 outline-none"
                              : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                          }
                        >
                          <p className="cursor-pointer font-semibold">
                            Redeem Rewards
                          </p>
                        </button>
                      )}
                    </Tab>

                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected
                              ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-1 text-olivine-500 outline-none"
                              : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                          }
                        >
                          <p className="cursor-pointer font-semibold">
                            Earn Rewards
                          </p>
                        </button>
                      )}
                    </Tab>

                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected
                              ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-1  text-olivine-500 outline-none"
                              : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                          }
                        >
                          <p className="cursor-pointer font-semibold">
                            Frequently Asked Questions
                          </p>
                        </button>
                      )}
                    </Tab>
                  </Tab.List>

                  <Tab.Panels>
                    <Tab.Panel>
                      <div className="mt-10">
                        <h2 className="text-3xl font-semibold text-gray-700">
                          About Grocedy Reward Bank
                        </h2>
                        <h3 className="mt-4 text-lg font-semibold text-gray-700">
                          What are Cowries?
                        </h3>
                        <p className="mt-2 max-w-4xl text-sm text-gray-700">
                          Grocedy Cowries are loyalty reward points that you can
                          earn by participating in various activities within the
                          Grocedy platform. These points can be converted into
                          value and used for future purchases.
                        </p>
                        <div className="mt-6 flex w-fit flex-row items-center space-x-2 rounded-lg border-[1px] border-[#F2C94C] bg-[#F6FBEE] p-4 text-sm font-semibold text-gray-500">
                          <div>
                            <img
                              src={SingleCowryImage}
                              alt="icon"
                              className="h-6 w-6"
                            />
                          </div>
                          <span>20 </span>
                          <span> Cowries = </span>
                          <span> N 2,000</span>
                        </div>
                        <p className="mt-6 max-w-4xl text-sm text-gray-700">
                          20 cowries can be converted into ₦2,000. This is the
                          minimum amount that can be redeemed at a time. Cowries
                          are redeemed in multiples of 20.
                        </p>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className="mt-10">
                        <h2 className="text-xl font-semibold text-gray-700">
                          How to Earn Cowries
                        </h2>
                        <div className="mt-3 space-y-8">
                          {fetchedRewardBankInfo &&
                          fetchedRewardBankInfo?.earn_info?.length
                            ? fetchedRewardBankInfo?.earn_info?.map((earn) => (
                                <div className="rounded-xl border-[1px] border-gray-200 p-6">
                                  <h3 className="text-base font-semibold text-[#558223]">
                                    {`${earn?.earn_title}:`}
                                  </h3>

                                  <div className="mt-2 space-y-3 px-2 text-sm font-medium text-gray-500">
                                    {strToArray(earn?.earn_description)}
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <div className="mt-10 space-y-4">
                        {fetchedRewardBankInfo &&
                        fetchedRewardBankInfo?.faq_info?.length
                          ? fetchedRewardBankInfo?.faq_info?.map((faq) => (
                              <RewardCenterDisclosureComponent
                                question={faq?.question}
                                answer1={<p>{strToArray(faq?.answer)}</p>}
                              />
                            ))
                          : null}
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
              <div className="mt-20 pb-40">
                <div className="mt-6  md:grid md:grid-cols-2 md:gap-4">
                  <div
                    onClick={() => navigate("/reward-bank/summary")}
                    className="w-full cursor-pointer rounded-lg border-[1px] border-[#DFE5D1] bg-[#F6FBEE] p-4 hover:ring-1 hover:ring-gray-300"
                  >
                    <div className="flex flex-row items-center justify-between">
                      <div>
                        <img
                          src={RewardIcon}
                          alt="icon"
                          className="h-10 w-10"
                        />
                      </div>
                      <div>
                        <IoChevronForward className="h-5 w-5 text-[#558223]" />
                      </div>
                    </div>
                    <p className="mt-8 text-2xl font-semibold text-gray-700">
                      Reward Earned Summary.
                    </p>
                    <p className="mt-3 text-sm font-medium text-gray-500">
                      Detailed summary of Cowries Earned
                    </p>
                  </div>
                  <div
                    onClick={() => navigate("/reward-bank/referral-bonus")}
                    className="mt-8 w-full cursor-pointer rounded-lg border-[1px] border-[#DFE5D1] bg-[#F6FBEE] p-4 hover:ring-1 hover:ring-gray-300 md:mt-0"
                  >
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-lg font-semibold text-gray-700">
                        Refer Your Friends
                      </p>
                      <div>
                        <IoChevronForward className="h-5 w-5 text-[#558223]" />
                      </div>
                    </div>
                    <div className="mt-1 flex flex-row space-x-1">
                      <div className="mt-1.5 h-1 w-1 rounded-full bg-gray-500" />
                      <p className="text-xs font-medium text-gray-500">
                        Refer a friend and receive 3 Cowries when they complete
                        a payment cycle.
                      </p>
                    </div>
                    <p className="mt-2 text-sm font-semibold text-gray-600">
                      Referral Code
                    </p>
                    <div className="mt-1 flex w-fit flex-row items-center space-x-2 rounded-md border-[1px] border-[#8DAA6A] px-4 py-2 text-sm font-semibold text-gray-500">
                      <p>{userReferralCode}</p>
                      <span className="cursor-pointer">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.9375 18.75H7.1875C6.44158 18.75 5.72621 18.4537 5.19876 17.9262C4.67132 17.3988 4.375 16.6834 4.375 15.9375V7.1875C4.375 6.44158 4.67132 5.72621 5.19876 5.19876C5.72621 4.67132 6.44158 4.375 7.1875 4.375H15.9375C16.6834 4.375 17.3988 4.67132 17.9262 5.19876C18.4537 5.72621 18.75 6.44158 18.75 7.1875V15.9375C18.75 16.6834 18.4537 17.3988 17.9262 17.9262C17.3988 18.4537 16.6834 18.75 15.9375 18.75Z"
                            fill="#8DAA6A"
                          />
                          <path
                            d="M6.25 3.125H15.4641C15.2696 2.57705 14.9104 2.10268 14.4356 1.76699C13.9609 1.43129 13.3939 1.2507 12.8125 1.25H4.0625C3.31658 1.25 2.60121 1.54632 2.07376 2.07376C1.54632 2.60121 1.25 3.31658 1.25 4.0625V12.8125C1.2507 13.3939 1.43129 13.9609 1.76699 14.4356C2.10268 14.9104 2.57705 15.2696 3.125 15.4641V6.25C3.125 5.4212 3.45424 4.62634 4.04029 4.04029C4.62634 3.45424 5.4212 3.125 6.25 3.125Z"
                            fill="#8DAA6A"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mt-6">
                      <button className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-4 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 xsm:text-sm">
                        Copy Referral Code
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <>
                <Transition appear show={isOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-50"
                    onClose={closeModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-xl bg-white px-4 py-6 text-left align-middle shadow-xl transition-all sm:px-8">
                            <RedeemCowriesPopup
                              fetchedRewardBankInfo={fetchedRewardBankInfo}
                              closeModal={closeModal}
                              openSuccess={setIsOpenSuccess}
                            />
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>

                <Transition appear show={isOpenSuccess} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-50"
                    onClose={() => setIsOpenSuccess(false)}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-xl bg-white px-4 py-6 text-left align-middle shadow-xl transition-all sm:px-8">
                            <CowriesRedeemSeccessfullPopup
                              closeModal={setIsOpenSuccess}
                            />
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
              </>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default RewardCenter;
