import { createAsyncThunk } from "@reduxjs/toolkit";
import { startLoad, stopLoad } from "../slices/appSlice";
import VoucherService from "../../services/voucherService";
import { handleError } from "../slices/errorSlice";

export const voucherCreate = createAsyncThunk(
  "post/voucher create",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VoucherService.createVoucher(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const bulkVoucherCreate = createAsyncThunk(
  "post/voucher create bulk",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VoucherService.createVoucherBulk(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const voucherRedeem = createAsyncThunk(
  "post/voucher redeem",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VoucherService.redeemVoucher(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const voucherAssign = createAsyncThunk(
  "post/voucher assign",
  async ({ ref, payload }, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VoucherService.assignVoucherBulk(ref, payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const voucherBulkGet = createAsyncThunk(
  "post/voucher assign fetch",
  async ({ ref, payload }, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VoucherService.getBulk(ref, payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const voucherBulkAssignImport = createAsyncThunk(
  "post/voucher assign import",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VoucherService.assignVoucherBulkImports(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const voucherHistory = createAsyncThunk(
  "post/voucher history",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VoucherService.historyVoucher(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const voucherShare = createAsyncThunk(
  "get/voucher history",
  async (ref, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await VoucherService.shareVoucher(ref);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
