import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rate: "",
};

const ModalSlice = createSlice({
  name: "Modal",
  initialState,
  reducers: {
    setRate(state, action) {
      state.rate = action.payload;
    },
  },
});

const { reducer, actions } = ModalSlice;

export const { setRate } = actions;

export default reducer;
