import React, { useEffect, useState } from "react";
import TrackPageLarge from "../TrackPageLarge";
import TrackPageSidebar from "../TrackPageSidebar";
import TrackPageSmall from "../TrackPageSmall";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activePlans, getDeliveryData } from "../../../../store/actions";
import {
  setBasketSalesOrder,
  setSelectedProductName,
} from "../../../../store/slices/modalSlice";
import moment from "moment";
import { authService } from "../../../../services/authService";

function TrackCreatePlanPageId() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [fetchedTracking, setFetchedTracking] = useState([]);
  const [fetchedActiveSalesOrder, setFetchedActiveSalesOrder] = useState([]);
  const [filteredFetchedTracking, setFilteredFetchedTracking] = useState([]);
  const [defaultFetchedTracking, setDefaultFetchedTracking] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setClose] = useState(true);
  const [deliverySalesData, setDeliverySalesData] = useState(
    fetchedTracking[0]
  );
  const { selectedBasketSalesOrder } = useSelector((state) => state.modal);
  const { activePlansCustomer } = useSelector((state) => state.masterData);
  const customerId = authService.getCurrentUserZohoCustomerId();

  function handleOpen(saleOrder) {
    setDeliverySalesData(saleOrder);
  }

  function toggleView() {
    setClose(!open);
  }

  useEffect(() => {
    try {
      if (!activePlansCustomer) {
        dispatch(activePlans(customerId));
      }
    } catch (err) {
      return err;
    }
  }, [dispatch, customerId, activePlansCustomer]);

  useEffect(() => {
    try {
      if (activePlansCustomer) {
        setFetchedActiveSalesOrder(activePlansCustomer?.salesorder_list);
      }
    } catch (err) {
      return err;
    }
  }, [activePlansCustomer]);

  useEffect(() => {
    if (
      params.id &&
      !selectedBasketSalesOrder?.length &&
      fetchedActiveSalesOrder?.length
    ) {
      const saleOrderTrack = fetchedActiveSalesOrder?.filter(
        (saleOrder) => saleOrder?.salesorder_number === params.id
      );

      if (saleOrderTrack?.length) {
        dispatch(setBasketSalesOrder(saleOrderTrack[0]));
        // dispatch(setSelectedProductName(subscriptionProductName?.name));
        dispatch(
          setSelectedProductName({
            planName: selectedBasketSalesOrder?.adjustment_description,
            planPrice: selectedBasketSalesOrder?.total_amount,
            planDuration: `${moment(
              selectedBasketSalesOrder?.start_date
            ).format("MMMM YYYY")}`,
            symbol: selectedBasketSalesOrder?.currency_symbol,
          })
        );
        navigate(`/track/create/${params.id}`);
      }
    }

    if (selectedBasketSalesOrder) {
      dispatch(
        setSelectedProductName({
          planName: selectedBasketSalesOrder?.adjustment_description,
          planPrice: selectedBasketSalesOrder?.total_amount,
          planDuration: `${moment(selectedBasketSalesOrder?.start_date).format(
            "MMMM YYYY"
          )}`,
          symbol: selectedBasketSalesOrder?.currency_symbol,
        })
      );
    }
  }, [
    params.id,
    selectedBasketSalesOrder,
    fetchedActiveSalesOrder,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (params.id) {
      dispatch(getDeliveryData(params.id))
        .unwrap()
        .then((res) => {
          setFetchedTracking(res);
        })
        .catch((err) => err);
    }
  }, [params.id, dispatch, navigate]);

  useEffect(() => {
    if (fetchedTracking) {
      const filteredItem = [];

      const customBasketOrder = {
        order_info: fetchedTracking,
        shipping_address: selectedBasketSalesOrder?.shipping_address,
      };

      filteredItem.push(customBasketOrder);

      setFilteredFetchedTracking(filteredItem);
      setDefaultFetchedTracking(filteredItem[0]);
    }
  }, [fetchedTracking, selectedBasketSalesOrder]);

  return (
    <>
      {selectedBasketSalesOrder ? (
        <div>
          <div className="lg:hidden">
            <TrackPageSmall
              clicker={handleOpen}
              selectedOrders={filteredFetchedTracking}
              mapData={deliverySalesData}
              open={open}
              defaultTracking={defaultFetchedTracking}
            />
          </div>
          <div className="flex w-full">
            <div className="hidden w-[550px] lg:inline">
              <TrackPageSidebar
                clicker={handleOpen}
                selectedOrders={filteredFetchedTracking}
              />
            </div>
            <div className="hidden w-full lg:inline">
              <TrackPageLarge
                // clicker={handleOpen}
                open={open}
                viewer={toggleView}
                defaultTracking={defaultFetchedTracking}
                loading={loading}
                mapData={deliverySalesData}
              />
            </div>
          </div>
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
}

export default TrackCreatePlanPageId;
