import { useEffect } from "react";

const SalutationSelect = (props) => {

    useEffect(()=> {

    },[props?.default]);


    let salutationModel = props?.mainList.length > 0
    && props?.mainList.map((item, i) => {
        if(props?.default == item.salutation_id){
            return (
                <option selected key={i} value={item.salutation_id}>{item.salutation_name}</option>
            )
        }else{
            return (
                <option  key={i} value={item.salutation_id}>{item.salutation_name}</option>
              )

        }

});

    return (
        <>
          <select   onChange={(e)=> props.activeSelect(e.target.value)}
                type="text"
                className="block w-full cursor-pointer rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm font-semibold text-gray-600 placeholder:text-sm focus:border-olivine-500 focus:ring-olivine-500"
              >
             <option value=""  >
         -- Select salutation --
      </option> 
       {salutationModel}
         </select>
        
        </>
      );
}
 
export default SalutationSelect;