import { createContext } from "react";

const ProfileContext = createContext({
  setProfileData: (newData) => {},
  setProfileMasterData: (newData) => {},
  getProfileData: () => {},
  getProfileMasterData: () => {},
  setDeliveryData: (newData) => {},
  getDeliveryData: () => {},
  setCountryCityArea: (newData) => {},
  getCountryCityArea: () => {},
  setSalutationList: (newData) => {},
  getSalutationList: () => {},
});

export function ProfileContextProvider(props) {
  let profileData = {};
  let profileMasterData = {};
  let deliveryData = [];
  let countryCityArea = {};
  let salutationList = [];

  const setProfileData = (newData) => {
    profileData = newData;
  };

  const getProfileData = () => {
    return profileData;
  };

  const setProfileMasterData = (newData) => {
    profileMasterData = newData;
  };

  const getProfileMasterData = () => {
    return profileMasterData;
  };

  const setDeliveryData = (newData) => {
    deliveryData = newData;
  };

  const getDeliveryData = () => {
    return deliveryData;
  };

  const setCountryCityArea = (newData) => {
    countryCityArea = newData;
  };

  const getCountryCityArea = () => {
    return countryCityArea;
  };

  const setSalutationList = (newData) => {
    salutationList = newData;
  };

  const getSalutationList = () => {
    return salutationList;
  };

  const context = {
    setProfileData: setProfileData,
    getProfileData: getProfileData,
    setProfileMasterData: setProfileMasterData,
    getProfileMasterData: getProfileMasterData,
    setDeliveryData: setDeliveryData,
    getDeliveryData: getDeliveryData,
    setCountryCityArea: setCountryCityArea,
    getCountryCityArea: getCountryCityArea,
    setSalutationList,
    getSalutationList,
  };

  return (
    <ProfileContext.Provider value={context}>
      {props.children}
    </ProfileContext.Provider>
  );
}

export default ProfileContext;
