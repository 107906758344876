import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import moment from "moment";
// Create styles

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "#000000",
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    width: "80%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  header: {
    width: "100%",
    textAlign: "center",
    margin: 10,
    fontWeight: "bold",
  },
  text: {
    fontWeight: "normal",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});

// Create Document Component
function PDFdocument() {
  const { transaction } = useSelector((state) => state.modal);

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <Text style={styles.header}>TRANSACTION DETAIL</Text>
          <View style={styles.section}>
            <Text>Transaction Date:</Text>
            <Text style={styles.text}>
              {moment(transaction?.transaction_date).format("MMM Do YYYY")}
            </Text>
          </View>
          <View style={styles.section}>
            <Text>Transaction Type:</Text>
            <Text>{transaction?.transaction_type_name}</Text>
          </View>
          <View style={styles.section}>
            <Text>Amount:</Text>
            <Text>{transaction?.amount}</Text>
          </View>
          <View style={styles.section}>
            <Text>Convenience Fee:</Text>
            <Text>{transaction?.convience_fees_amount}</Text>
          </View>
          <View style={styles.section}>
            <Text>Narration:</Text>
            <Text>{transaction?.narration}</Text>
          </View>
          <View style={styles.section}>
            <Text>Reference Number:</Text>
            <Text>{transaction?.ref_no}</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default PDFdocument;
