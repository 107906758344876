import React from "react";
import { IoInformation } from "react-icons/io5";

const AddYourBVNNumber = ({ setOpen, handlePhoneVerification }) => {
  return (
    <div className="mx-auto h-full w-full max-w-[420px] rounded-md bg-white px-2 pb-10 xsm:px-3">
      <div className="mt-8 flex items-center justify-center">
        <div className="with-fit rounded-full bg-olivine-500 p-2 text-white">
          <IoInformation className="h-7 w-7" />
        </div>
      </div>
      <div className="mx-auto max-w-xs">
        <h2 className="mt-4 text-center text-lg font-bold text-gray-700">
          Verify your Mobile Number{" "}
        </h2>
        <p className="mt-1 text-center text-sm font-medium text-gray-600">
          Please verify your mobile number to continue with checkout.
        </p>
      </div>
      <div className="mx-auto mt-6 flex max-w-xs flex-col items-center justify-center">
        <button
          onClick={() => {
            handlePhoneVerification();
            setOpen(false);
          }}
          className="w-full rounded-md bg-olivine-500 px-8 py-3 text-sm text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
        >
          Verify Mobile Number
        </button>
        {/* <button
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
          }}
          className="mt-2 text-sm font-semibold text-gray-700 hover:underline hover:underline-offset-4"
        >
          Maybe Later
        </button> */}
      </div>
    </div>
  );
};

export default AddYourBVNNumber;
