import React, { useContext, useEffect, useRef, useState } from "react";
import Sort from "./Sort";
import { HiHeart, HiHome, HiOutlineHeart } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";

import Gift from "../../../assets/gifts-1.png";
import { FaPlus } from "react-icons/fa";

import { BiArrowBack } from "react-icons/bi";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import { GoSearch } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import CreateBasketCard from "./CreateBasketCard";
import BasketFloatingPane from "./BasketFloatingPane";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import { toast } from "react-hot-toast";

let allItemsSubscription$ = null;

function AllItemPage(props) {
  const [backupList, setBackupList] = useState([]);
  const [currentViewList, setCurrentViewList] = useState([]);
  const [page, setPage] = React.useState(1); // pagination

  const { section } = useParams();
  const rxjsStore = useContext(RxjsStoreContext);
  const navigate = useNavigate();
  const [searchTextInput, setSearchTextInput] = useState("");

  const style = {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  };

  useEffect(() => {
    allItemsSubscription$ = rxjsStore.getSectionList().subscribe((list) => {
      if (list?.length) {
        setCurrentViewList(list?.slice(0, 8));
        setBackupList(list);
      } else {
        navigate("/basket/create-basket");
      }
    });
    return () => {
      if (allItemsSubscription$) {
        allItemsSubscription$?.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  function onSearchChange(ev) {
    setSearchTextInput(ev.target.value);
    const search = ev.target.value || searchTextInput;
    if (search.length < 2) {
      setCurrentViewList(backupList.slice(0, 8));
    } else if (search?.length > 2) {
      var tempResult = rxjsStore.handleSearchProduct(search);
      setCurrentViewList(tempResult.slice(0, 8));
    }
  }

  const handlePaginationChange = (event, pageValue) => {
    let end = 8 * pageValue;
    let start = end - 8;
    setPage(pageValue);
    let currentList = backupList.slice(start, end);
    if (currentList?.length) {
      setCurrentViewList(currentList);
    }
  };

  const clearSearchInput = () => {
    setSearchTextInput("");
    setCurrentViewList(backupList.slice(0, 8));
  };

  async function onSortChange(code) {
    if (code === 1) {
      toast.success("currently not available!");
    }

    if (code === 2) {
      toast.success("currently not available!");
    }

    if (code === 3) {
      // low price is selected
      let tempList = backupList?.sort((a, b) => {
        return a.item_rate_zoho - b.item_rate_zoho;
      });
      setBackupList(tempList);
      setCurrentViewList(tempList.slice(0, 8));
    }

    if (code === 4) {
      //highest price is selected
      let tempList = backupList?.sort((a, b) => {
        return a.item_rate_zoho - b.item_rate_zoho;
      });
      let newList = tempList.reverse();
      setBackupList(newList);
      setCurrentViewList(newList.slice(0, 8));
    }

    if (code === 5) {
      //sort alphabetically A- Z
      let tempList = backupList?.sort((a, b) => {
        let name1 = a?.name.toLowerCase();
        let name2 = b?.name.toLowerCase();
        return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
      });

      setBackupList(tempList);
      setCurrentViewList(tempList?.slice(0, 8));
    }

    if (code === 6) {
      //sort alphabetically Z- A
      let tempList = backupList?.sort((a, b) => {
        let name1 = a?.name.toLowerCase();
        let name2 = b?.name.toLowerCase();
        return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
      });
      let newList = tempList.reverse();
      setBackupList(newList);
      setCurrentViewList(newList?.slice(0, 8));
    }

    if (code === 7) {
      //SORT BY Date NOT IMPLEMENTED
      toast.success("currently not available!");
    }

    if (code === 8) {
      //SORT BY Date NOT IMPLEMENTED
      toast.success("currently not available!");
    }

    if (code === 9) {
      // Default Sort selected
      let tempList = backupList?.sort((a, b) => {
        let name1 = a?.name.toLowerCase();
        let name2 = b?.name.toLowerCase();
        return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
      });

      setBackupList(tempList);
      setCurrentViewList(tempList?.slice(0, 8));
    }
  }

  return (
    <div className="z-0 mt-4 px-4 sm:px-8 md:px-16">
      <div className="mb-3 flex items-center space-x-2 text-[11px] font-semibold text-gray-500 xsm:space-x-3 xsm:text-xs">
        <div className="flex items-center space-x-1">
          <div>
            <HiHome className="h-4 w-4" />
          </div>
          <div>
            <IoIosArrowForward className="h-[11px] w-[11px]" />
          </div>
        </div>
        <div
          onClick={() => navigate("/")}
          className="cursor flex items-center space-x-1"
        >
          <div>Home</div>
          <div>
            <IoIosArrowForward className="h-[11px] w-[11px]" />
          </div>
        </div>
        <div
          onClick={() => navigate("/basket/create-basket")}
          className="cursor flex items-center space-x-1"
        >
          <div>Custom Basket</div>
          <div>
            <IoIosArrowForward className="h-[11px] w-[11px]" />
          </div>
        </div>
        <div className="flex items-center space-x-1 text-olivine-500">
          <div>All Items</div>
          <div>
            <IoIosArrowForward className="h-[11px] w-[11px]" />
          </div>
        </div>
      </div>

      <div className="mt-6 xsm:flex xsm:items-center  xsm:space-x-2 sm:space-x-6 md:grid md:grid-cols-4">
        <div className="flex items-center space-x-2  md:col-span-1">
          <div
            onClick={() => navigate(-1)}
            className="cursor rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
          >
            <BiArrowBack className="h-5 w-5 text-gray-600" />
          </div>
          <div className="hidden text-base font-semibold text-gray-600 md:inline lg:text-lg">
            Pick your Basket
          </div>
        </div>

        <div className="mt-2 flex-1 xsm:mt-0  sm:col-span-2">
          {/* why flex-1 */}
          <div className="flex items-center rounded-lg border border-gray-300 shadow-sm focus:border-olivine-500">
            <input
              onChange={(ev) => onSearchChange(ev)}
              value={searchTextInput}
              type="text"
              placeholder="Quick search for anything"
              className="flex-grow border-0 bg-transparent pl-5 text-sm font-semibold text-gray-600 outline-none ring-0 placeholder:text-sm placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0"
            />
            <div className="flex h-9 w-10 items-center justify-center rounded-r-lg bg-gray-100">
              <GoSearch className="h-[18px] w-[18px] cursor-pointer rounded-full text-olivine-500" />
            </div>
          </div>
        </div>

        <div
          onClick={clearSearchInput}
          className="-mt-[71px] flex cursor-pointer  justify-end text-sm font-semibold text-gray-500 hover:text-gray-700 xsm:mt-0 md:col-span-1"
        >
          <span className="cursor md:hidden ">Cancel</span>
          <span className="cursor hidden md:inline">Cancel Search</span>
        </div>
      </div>
      <section className="mt-16">
        <div className="flex items-center justify-between">
          <div className="text-lg font-bold text-gray-600">All Items</div>
          <div className="flex justify-end font-semibold text-gray-600">
            <Sort onSortChange={onSortChange}></Sort>
          </div>
        </div>
        <section>
          <div className="mt-2">
            <span className="text-sm font-semibold text-gray-500">
              Displaying {currentViewList?.length} out of {backupList?.length}{" "}
              items
            </span>
          </div>
        </section>
        <div className="mt-6 grid gap-6 grid-auto-fit-sm">
          {currentViewList?.map((product, index) => {
            return <CreateBasketCard key={index} product={product} />;
          })}
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>
      <section className="flex justify-center bg-red-900">
        <BasketFloatingPane style={style} />
      </section>

      <Stack spacing={2} className="mb-32 space-y-2">
        <Typography>Page: {page}</Typography>
        <Pagination
          count={Math.floor(backupList?.length / 8)}
          page={page}
          onChange={handlePaginationChange}
        />
      </Stack>
    </div>
  );
}

export default AllItemPage;
