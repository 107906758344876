import React from "react";
import RecommendationsComponents from "./RecommendationsComponents";

const RecommendationsPage = ({ items }) => {
  return (
    <div className="mx-auto max-w-[1600px] bg-white px-4 py-10 sm:px-8 md:px-10 lg:px-16">
      <div className="mt-16">
        <div className="flex flex-row items-center justify-between">
          <h2 className="text-2xl font-bold text-gray-600">
            You might also like
          </h2>
        </div>
        <div className="mt-6 grid gap-6 grid-auto-fit-xs ">
          {items && items?.length
            ? items?.map((item, idx) => (
                <RecommendationsComponents item={item} key={idx} />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default RecommendationsPage;
