import React from "react";
import Footer from "./Footer.js";
import SignedInNavbarImproved from "./SignedInNavbarImproved.js";
import MainArea from "./MainArea.js";

function Home() {
  return (
    <div className="">
      <SignedInNavbarImproved />
      <MainArea />
      <Footer />
    </div>
  );
}

export default Home;
