import React, { useEffect, useState } from "react";
import { BsQuestionLg } from "react-icons/bs";
import { AiOutlineCheck, AiTwotoneCheckCircle } from "react-icons/ai";
import RateYourOrderIcon from "../../assets/rateYourOrderIcon.svg";
import RateOrderIconDeliveryIcon from "../../assets/rateOrderDeliveryIcon.svg";
import { Modal, Box } from "@mui/material";
import { IoChevronForwardSharp } from "react-icons/io5";
import FeedBackRatingPopup from "./FeedBackRatingPopup";
import CurrencyFormat from "react-currency-format";
import StarRatings from "react-star-ratings";
import { useDispatch } from "react-redux";
import { getDeliveryData } from "../../store/actions";
import moment from "moment";
import { setSelectedReview } from "../../store/slices/modalSlice";
import { useNavigate } from "react-router-dom";

function OrderRating({ historyData, deeplink }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [fetchedTracking, setFetchedTracking] = useState({});

  useEffect(() => {
    if (historyData?.subscription_id) {
      dispatch(getDeliveryData(historyData?.subscription_id))
        .unwrap()
        .then((res) => {
          setFetchedTracking(res);
        })
        .catch((err) => err);
    }

    if (historyData?.salesorder_id) {
      dispatch(getDeliveryData(historyData?.salesorder_number))
        .unwrap()
        .then((res) => {
          setFetchedTracking(res);
        })
        .catch((err) => err);
    }
  }, [historyData]);

  const rating =
    historyData && historyData?.shopping_rating_value
      ? Number(historyData?.shopping_rating_value)
      : 0;
  const ratingShipping =
    historyData && historyData?.shipping_rating_value
      ? Number(historyData?.shipping_rating_value)
      : 0;

  const subRating =
    historyData && historyData?.rating_value
      ? Number(historyData?.rating_value)
      : 0;

  return (
    <div className=" divide-y-[1px] divide-gray-200 bg-white pb-40">
      {historyData?.subscription_id && (
        <div className="mt-16 space-y-2 px-4 text-sm font-medium text-gray-500">
          {historyData?.plan?.map((plan) => {
            return (
              <div className="flex items-center justify-between">
                <p>Items Total ({plan?.quantity})</p>
                <p>
                  <CurrencyFormat
                    value={Number.parseFloat(plan?.total)?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={historyData?.currency_symbol}
                  />
                </p>
              </div>
            );
          })}

          {historyData?.plan[0]?.addons.map((addon, index) => {
            return (
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-1 font-semibold">
                  <p> {addon?.name ? addon?.name : "Delivery Charge"}</p>
                  {!addon?.name && (
                    <div className="rounded-full bg-olivine-500 p-[3px] text-white">
                      <BsQuestionLg className="h-2 w-2 cursor-pointer" />
                    </div>
                  )}
                </div>
                <div className="">
                  <CurrencyFormat
                    value={Number.parseFloat(addon?.total)?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={historyData?.currency_symbol}
                  />
                </div>
              </div>
            );
          })}
          <div className="flex items-center justify-between">
            <p>Coupon</p>
            {historyData?.coupon?.discount_amount ? (
              <p className="text-red-500">
                -
                <CurrencyFormat
                  value={Number.parseFloat(
                    historyData?.coupon?.discount_amount
                  )?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={historyData?.currency_symbol}
                />
              </p>
            ) : (
              <p className="text-red-500">-N 0</p>
            )}
          </div>
          <div className="flex items-center justify-between text-base">
            <p className="">Total</p>
            <p className="text-lg font-bold text-gray-700">
              <CurrencyFormat
                className={"font-bold "}
                value={Number.parseFloat(historyData?.amount)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={historyData?.currency_symbol}
              />
            </p>
          </div>
        </div>
      )}

      {historyData?.salesorder_id && (
        <div className="mt-16 space-y-2 px-4 text-sm font-medium text-gray-500">
          {historyData?._item_list?.map((plan) => {
            return (
              <div className="flex items-center justify-between">
                <p>Items Total ({plan?.quantity})</p>
                <p>
                  <CurrencyFormat
                    value={Number.parseFloat(
                      plan?.rate * +plan?.quantity
                    )?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={historyData?.currency_symbol}
                  />
                </p>
              </div>
            );
          })}

          <div className="flex items-center justify-between">
            <p>Coupon</p>
            {historyData?.coupon?.discount_amount ? (
              <p className="text-red-500">
                -
                <CurrencyFormat
                  value={Number.parseFloat(
                    historyData?.coupon?.discount_amount
                  )?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={historyData?.currency_symbol}
                />
              </p>
            ) : (
              <p className="text-red-500">-N 0</p>
            )}
          </div>
          <div className="flex items-center justify-between text-base">
            <p className="">Total</p>
            <p className="text-lg font-bold text-gray-700">
              <CurrencyFormat
                className={"font-bold "}
                value={Number.parseFloat(historyData?.total_amount)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={historyData?.currency_symbol}
              />
            </p>
          </div>
        </div>
      )}

      {/* <div className="mt-6">
        <div className="px-4 py-8">
          <div className="flex flex-row items-start space-x-2">
            <div>
              {fetchedTracking?.pickup?.job_pickup_datetime ? (
                <AiOutlineCheck className="h-5 w-5 rounded-full bg-olivine-500 p-1 text-white" />
              ) : (
                <AiTwotoneCheckCircle className="h-5 w-5 rounded-full bg-olivine-500 p-1 text-white" />
              )}
            </div>
            <div className="">
              <h3 className="text-sm font-semibold text-gray-700">Picked up</h3>
              <p className="text-xs font-medium text-gray-500">
                {fetchedTracking?.pickup?.job_pickup_datetime
                  ? moment(fetchedTracking?.pickup?.job_pickup_datetime).format(
                      "LLL"
                    )
                  : "order is being processed..."}
              </p>
            </div>
          </div>
          <div className="-mt-3.5 ml-2 h-12 w-[4px] bg-olivine-500" />
          <div className="mt-[2px] flex flex-row items-start space-x-2">
            <div>
              {fetchedTracking?.delivery?.job_delivery_datetime ? (
                <AiOutlineCheck className="h-5 w-5 rounded-full bg-olivine-500 p-1 text-white" />
              ) : (
                <AiTwotoneCheckCircle className="h-5 w-5 rounded-full bg-olivine-500 p-1 text-white" />
              )}
            </div>
              <div className="">
                <h3 className="text-sm font-semibold text-gray-700">
                  Delivered
                </h3>
                <p className="text-xs font-medium text-gray-500">
                  {fetchedTracking?.delivery?.job_delivery_datetime
                    ? moment(
                        fetchedTracking?.delivery?.job_delivery_datetime
                      ).format("LLL")
                    : "order is being processed..."}
                </p>
              </div>
          </div>
        </div>
      </div> */}
      <div
        onClick={() => {
          setOpen(true);
          setType("shopping");
        }}
        className="flex cursor-pointer items-center justify-between px-4 py-8 hover:bg-gray-100"
      >
        <div className="flex flex-row items-center space-x-2">
          <div>
            <img src={RateYourOrderIcon} alt="icon" className="h-7 w-7" />
          </div>
          <div className="">
            <h3 className="text-sm font-semibold text-gray-700">
              Rate your Experience
            </h3>
            {historyData?.shopping_rating_value && (
              <div className="mt-1 flex items-center space-x-2">
                <p className="text-sm font-medium text-gray-400">
                  You rated your Experience
                </p>
                <div className="flex flex-row items-center space-x-1">
                  <StarRatings
                    rating={rating}
                    starRatedColor="#ffce31"
                    numberOfStars={5}
                    name="rating"
                    starDimension="10px"
                    starSpacing="2px"
                  />
                </div>
              </div>
            )}
            {historyData?.shopping_rating_remark && (
              <p
                onClick={() => {
                  navigate("/rating-comments");
                  dispatch(setSelectedReview(historyData));
                }}
                className="text-sm font-medium text-olivine-600 hover:underline hover:underline-offset-2"
              >
                View Comment
              </p>
            )}
            {historyData?.rating_value && (
              <div className="mt-1 flex items-center space-x-2">
                <p className="text-sm font-medium text-gray-400">
                  You rated your Experience
                </p>
                <div className="flex flex-row items-center space-x-1">
                  <StarRatings
                    rating={subRating}
                    starRatedColor="#ffce31"
                    numberOfStars={5}
                    name="rating"
                    starDimension="10px"
                    starSpacing="2px"
                  />
                </div>
              </div>
            )}
            {historyData?.rating_remark && (
              <p
                onClick={() => {
                  navigate("/rating-comments");
                  dispatch(setSelectedReview(historyData));
                }}
                className="text-sm font-medium text-olivine-600 hover:underline hover:underline-offset-2"
              >
                View Comment
              </p>
            )}
          </div>
        </div>
        <IoChevronForwardSharp className="h-6 w-6 text-gray-500" />
      </div>
      {historyData?.salesorder_id && (
        <div
          onClick={() => {
            setOpen(true);
            setType("shipping");
          }}
          className="flex cursor-pointer items-center justify-between px-4 py-8 hover:bg-gray-100"
        >
          <div className="flex flex-row items-center space-x-2">
            <div>
              <img
                src={RateOrderIconDeliveryIcon}
                alt="icon"
                className="h-7 w-7"
              />
            </div>
            <div className="">
              <h3 className="text-sm font-semibold text-gray-700">
                Rate Order Delivery
              </h3>
              {historyData?.shipping_rating_value && (
                <div className="mt-1 flex items-center space-x-2">
                  <p className="text-sm font-medium text-gray-400">
                    You rated your Experience
                  </p>
                  <div className="flex flex-row items-center space-x-1">
                    <StarRatings
                      rating={ratingShipping}
                      starRatedColor="#ffce31"
                      numberOfStars={5}
                      name="rating"
                      starDimension="10px"
                      starSpacing="2px"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <IoChevronForwardSharp className="h-6 w-6 text-gray-500" />
        </div>
      )}

      <div className="px-6">
        <div className="mt-4 text-center">
          <button className="text-lg font-medium text-[#558223] hover:underline hover:underline-offset-2">
            Contact support
          </button>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-6 overflow-hidden px-2 xsm:px-4">
          <Box className="mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <FeedBackRatingPopup
              setOpen={setOpen}
              type={type}
              salesOrderId={historyData?.salesorder_id}
              subscriptionId={historyData?.subscription_id}
              deeplink={deeplink}
            />
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default OrderRating;
