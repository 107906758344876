import { BehaviorSubject } from "rxjs";

const orderHistoryStore = new BehaviorSubject({});

const setSingleOrderHistory = (newValue) => {
  orderHistoryStore.next(newValue);
};

const getSingleOrderHistory = () => {
  return orderHistoryStore;
};

export const rxjsStoreProvider = {
  getSingleOrderHistory,
  setSingleOrderHistory,
};
