import React, { useEffect } from "react";

import Footer from "../Footer";


import CreateButcherContent from "./CreateButcherContent";
import CreateBasketCard from "../pages/create_basket_page/CreateBasketCard";
import SignedInNavbarImproved from "../SignedInNavbarImproved";

function CreateButcherPage() {
  return (
    <div className="">
      <SignedInNavbarImproved />
      <CreateButcherContent />

      <Footer />
    </div>
  );
}

export default CreateButcherPage;
