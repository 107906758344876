import React, { useEffect, useState } from "react";

function GiftAPlanPageCards({
  title,
  price,
  quantity,
  clicked,
  rate,
  goToBasket,
  images,
  plan_id,
}) {
  const [planImage, setPlanImage] = useState([]);

  useEffect(() => {
    if (images.length && plan_id) {
      const planImages = images?.filter((img) => img?.zoho_plan_id === plan_id);

      setPlanImage(planImages);
    }
  }, [plan_id, images]);

  return (
    <div className="max-w-sm rounded-md border-[1px] border-gray-300 p-[5px]">
      <div className="rounded-md border border-gray-200">
        <img
          src={planImage && planImage?.length && planImage[0].plan_image_url}
          className="aspect-auto h-36 w-full rounded-md object-cover"
          alt=""
        />
      </div>
      <div className="px-2">
        <h1 className="mt-2 text-lg font-bold text-gravel-500">{title}</h1>
        <h2 className="text-font mt-1 text-sm font-bold text-gray-600">
          {rate
            ? `$${Number(price * +rate).toFixed(2)}/month`
            : `₦${Number(price).toLocaleString()}/month`}
        </h2>
        <div className="text-xs text-gravel-500">{quantity}</div>
      </div>
      <div className="mt-3 flex items-center justify-between px-2 pb-2 text-sm font-semibold">
        <div
          onClick={goToBasket ? goToBasket : null}
          className="cursor-pointer text-olivine-500 transition duration-300 hover:text-olivine-600"
        >
          ADD TO BASKET
        </div>
        <div
          onClick={clicked ? clicked : null}
          className="cursor-pointer text-gray-500 hover:underline"
        >
          VIEW
        </div>
      </div>
    </div>
  );
}

export default GiftAPlanPageCards;
