import React, { useContext } from "react";
import CurrencyFormat from "react-currency-format";
import { useLocation, useNavigate } from "react-router-dom";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import toast from "react-hot-toast";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { FaPlus } from "react-icons/fa";
import { HiOutlineHeart, HiHeart } from "react-icons/hi";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AccountImage from "../../../assets/LogIntoYourAccount.svg";
import { CgClose } from "react-icons/cg";
import { authService } from "../../../services/authService";
import LogicContext from "../../../context/LogicContext";

function CreateBasketCard(props) {
  let navigate = useNavigate();
  const rxjsStore = useContext(RxjsStoreContext);
  const [open, setOpen] = React.useState(false);
  const logicContext = useContext(LogicContext);
  const location = useLocation();
  const { product } = props;

  const basket_type = localStorage.getItem("basket_type");

  const viewItemDetails = (itemId) => {
    navigate(`/basket/create-basket/selected-basket/${itemId}`);
  };

  const addToBasket = (product, quantity) => {
    if (product.item_stock_out === true) {
      return toast.error("Item is out of stock!");
    }
    rxjsStore.setUserSelectedBasket(
      { ...product, type: basket_type === "butcher" ? "BUTCHER" : "BASKET" },
      quantity
    );
    toast.success(
      `${quantity} qty of ${product.name} has been added to basket!`
    );
  };

  const performFavorite = (productId, option) => {
    if (authService.isLogin()) {
      if (option === 0) {
        rxjsStore.performFavorite(productId, 1);
      } else {
        rxjsStore.performFavorite(productId, 0);
      }
    } else {
      setOpen(true);
    }
  };

  const continueToLogin = () => {
    logicContext.setPreviousUrl(location.pathname);
    navigate("/login");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section>
      <div>
        <Dialog
          className="h-full pb-16"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="h-hug w-full rounded-md border-gray-300 bg-gray-100 shadow shadow-gray-400">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="flex items-center justify-end">
                  <button
                    onClick={handleClose}
                    className="rounded-full p-2 active:bg-gray-200"
                  >
                    <CgClose className="h-5 w-5 text-gravel-500" />
                  </button>
                </div>
                <div className="mt-2 flex justify-start text-sm">
                  <img src={AccountImage} className="h-40 w-full" alt="" />
                </div>
                <div className="cursor mt-4 flex justify-center text-base font-semibold xsm:text-lg ">
                  <div className=" font-bold text-gray-800">
                    Log into your account{" "}
                  </div>
                </div>
                <div className="mt-2 text-center text-xs">
                  <div className="text-gray-500">
                    You are currently not logged into your Grocedy account.
                    Please login or create a new account to proceed to check
                    out.
                  </div>
                </div>
                <div
                  onClick={continueToLogin}
                  className="mt-5 pb-4 text-center tracking-wider xsm:px-6 sm:px-16"
                >
                  <div className="rounded-md border-[1px] border-olivine-500 py-2 text-sm font-semibold text-olivine-500 transition duration-300 active:ring-1 active:ring-olivine-500">
                    Log into Account
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
      </div>
      {/* end alert */}
      <div className="w-full overflow-hidden rounded-md border-[0.5px] border-gray-200 bg-white shadow-sm shadow-gray-300 transition-all duration-300 ease-in @container hover:scale-105">
        <div className="@xs:flex">
          <div className="relative">
            <div
              onClick={() => viewItemDetails(product?.item_id)}
              className="bg-white @xs:w-40"
            >
              <img
                src={product?.item_image_url}
                className="cursor aspect-auto h-[270px] w-full object-contain @xs:h-48"
                alt=""
              />
            </div>
            <div className="group">
              <div
                onClick={() => viewItemDetails(product?.item_id)}
                className="cursor absolute right-0 top-0 h-full w-full rounded-md border-[1px] border-gray-200 transition duration-200"
              ></div>
              <div className="group absolute right-2 top-2 cursor-pointer rounded-full bg-olivine-500  p-1 transition duration-200 hover:scale-125">
                <FaPlus
                  onClick={() => addToBasket(product, 1)}
                  className="h-6 w-6 text-white @xs:h-5 @xs:w-5"
                />
              </div>
              {product?.item_stock_out === true && (
                <p className="absolute left-0 top-2 rounded-r bg-[#E53448] px-3 py-1 text-xs font-semibold text-white">
                  Out of Stock
                </p>
              )}
            </div>
          </div>
          <div className="w-full @xs:mt-12">
            <div className="flex items-center justify-between space-x-4 px-2 @xs:space-x-2">
              <h1
                title={product?.name}
                onClick={() => viewItemDetails(product?.item_id)}
                className="mt-2 line-clamp-1 cursor-pointer text-base font-bold text-gray-600 @xs:text-sm"
              >
                {product?.name}
              </h1>
              {authService.isLogin() && (
                <div
                  onClick={() =>
                    performFavorite(product?.item_id, product?.favourite_status)
                  }
                  className=""
                >
                  {product?.favourite_status === 1 ? (
                    <button>
                      <HiHeart className="aspect-auto h-6 w-6  cursor-pointer rounded-md object-cover text-red-400" />
                    </button>
                  ) : (
                    <Tippy
                      content={
                        <div
                          className="max-w-sm space-y-1 rounded-md bg-gravel-500 p-4 text-xs text-white"
                          interactive={true}
                          interactiveBorder={20}
                          delay={100}
                        >
                          <div>Add to favorites</div>
                        </div>
                      }
                    >
                      <button>
                        {" "}
                        <HiOutlineHeart className="aspect-auto h-6 w-6  cursor-pointer rounded-md object-cover text-gray-500" />
                      </button>
                    </Tippy>
                  )}
                </div>
              )}
            </div>
            <div
              title={`₦ ${Number.parseFloat(product?.item_rate_zoho)?.toFixed(
                2
              )} / ${product?.sku}`}
              onClick={() => viewItemDetails(product?.item_id)}
              className="mt-1 line-clamp-1 cursor-pointer px-2 text-sm font-semibold"
            >
              <div className="text-gray-600">
                {" "}
                <CurrencyFormat
                  className={"font-bold"}
                  value={Number.parseFloat(product?.item_rate_zoho)?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
                / {product?.sku}{" "}
              </div>
            </div>
            <div className="px-2 pb-6 ">
              <h4 className="cursor mt-1 text-xs font-semibold text-red-400 ">
                Market Price:{" "}
                <strike className="text-xs">
                  <CurrencyFormat
                    className={"font-semibold "}
                    value={Number.parseFloat(
                      product?.item_rate_market
                    )?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </strike>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateBasketCard;
