import React from "react";
import Navbar from "./Navbar";
import OurTeam from "./OurTeam";
import Lanre from "../assets/Lanre.jpg";
import Olusola from "../assets/Olusola.jpg";
import Olorunfemi from "../assets/Olorunfemi.jpg";
import Footer from "./Footer";

function About() {
  return (
    <div>
      <Navbar />
      <div className="mx-auto max-w-[1600px]">
        <div className="px-4 sm:px-8 md:px-16">
          <div className="mt-16 text-2xl font-bold text-gravel-500">
            About Grocedy
          </div>
          <div className="mt-4 text-xs font-semibold text-gray-600">
            Our primary goal is to make food as affordable as possible at
            wholesale prices through a monthly subscription service. We work
            with local farmers, manufacturers and cooperatives to source the
            best quality produce for our subscribers. Your subscription will not
            only help you save money, but it will help another person or family
            fight hunger as well. With every box delivered to a subscriber, a
            food item gets donated to a person in need.
          </div>
          <div className="mt-10 text-center text-xl font-bold text-gravel-500">
            Our Team
          </div>
        </div>
        <div className="grid gap-8 px-4 sm:px-8 md:grid-cols-2 md:px-16 lg:grid-cols-4">
          <OurTeam
            img={Lanre}
            name="Lanre Smith"
            information="CEO (Chief Executive Officer)"
          />
          {/* <OurTeam
            img={Olusola}
            name="Olusola Omotunde"
            information="CPO (Lead Operations and Logistics)"
          />
          <OurTeam
            img={Olorunfemi}
            name="Olorufemi Paul"
            information="FO (Finance officer)"
          /> */}
        </div>
        <div className="mt-20 grid justify-items-center px-4 sm:px-8 md:px-16">
          <div className="text-xl font-bold text-gravel-500">Our Values</div>
        </div>
        <div className="mt-8 grid gap-4 px-4 text-white sm:px-8 md:grid-cols-2 md:px-16 lg:grid-cols-4">
          <div className="col-span-1 h-56 rounded-md bg-olivine-500 px-4 py-8">
            <div className="flex justify-center text-lg font-semibold">
              People{" "}
            </div>
            <div className="text-sm">
              At our core, we are people driven, our internal and external
              stakeholders are at the centre of our thinking.
            </div>
          </div>
          <div className="col-span-1 h-56 rounded-md bg-olivine-500 px-4 py-8">
            <div className="flex justify-center text-lg font-semibold">
              Passion
            </div>
            <div className="text-sm">
              We don't see things from a pessimistic approach. Our approach to
              problems we face daily is another opportunity to find a solution
              to them.
            </div>
          </div>
          <div className="col-span-1 h-56 rounded-md bg-olivine-500 px-4 py-8">
            <div className="flex justify-center text-lg font-semibold">
              Team work
            </div>
            <div className="text-sm">
              Just like how the words are joined together, we acknowledge that
              the organization's goal cannot be achieved alone.
            </div>
          </div>
          <div className="col-span-1 h-56 rounded-md bg-olivine-500 px-4 py-8">
            <div className="flex justify-center text-lg font-semibold">
              Integrity
            </div>
            <div className="text-sm">
              Integrity is to us the uncompromising quality of our honesty and
              principles, knowing fully that we are expected to adhere to these
              principles and completely and will be held accountable accordingly
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
