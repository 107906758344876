import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import SignedInNavbarImproved from "../../../SignedInNavbarImproved";
import { Modal, Box } from "@mui/material";
import DowngradePlanPopover from "../DowngradePlanPopover";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProducts } from "../../../../store/actions";
import { authService } from "../../../../services/authService";

function ViewContentDowngradePage() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [fetchedProducts, setFectchedProducts] = useState({});
  const [fetchedPlan, setFetchedPlan] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedImage, setSelectedImage] = useState([]);
  const [isViewing, setIsViewing] = useState(0);
  const [selectedPlanImages, setSelectedPlanImages] = useState([]);

  const { products } = useSelector((state) => state.masterData);
  const { selectedProductId } = useSelector((state) => state.modal);
  const corporateId = authService.getCustomerCorporateId();
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (!products) {
      const data = {
        sql_ref_no: "46",
        corporateId: corporateId ? +corporateId : 1,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(getProducts(data))
        .unwrap()
        .catch((err) => {
          return err;
        });
    }

    if (products) setFectchedProducts(products);
  }, [dispatch, products, navigate, corporateId]);

  useEffect(() => {
    if (fetchedPlan) {
      setSelectedPlan(fetchedPlan);
    }
  }, [fetchedPlan]);

  useEffect(() => {
    if (selectedPlanImages.length) {
      setSelectedImage(selectedPlanImages);
    }
  }, [selectedPlanImages]);

  useEffect(() => {
    if (
      fetchedProducts &&
      params.id &&
      fetchedProducts?.plan_info?.length &&
      fetchedProducts?.Plan_Image?.length
    ) {
      const filteredPlan =
        fetchedProducts?.plan_info?.length &&
        fetchedProducts?.plan_info?.filter(
          (plan) => plan?.plan_code === params.id
        );

      setFetchedPlan(filteredPlan);

      const filteredImages =
        fetchedProducts?.Plan_Image?.length &&
        fetchedProducts?.Plan_Image?.filter(
          (image) => image?.zoho_plan_id === filteredPlan[0]?.plan_id
        );

      setSelectedPlanImages(filteredImages);
    }
  }, [dispatch, params.id, fetchedProducts]);

  const handleCurrentView = (id) => {
    setIsViewing(id);
  };

  const items =
    selectedPlan &&
    selectedPlan?.length &&
    selectedPlan[0]?.store_markup_description.split("|");

  const payload = {
    plan_code: selectedPlan[0]?.plan_code,
    price: selectedPlan[0]?.recurring_price,
    quantity: selectedProductId?.qty,
  };

  return (
    <>
      <SignedInNavbarImproved />
      <div>
        <div className="px-4 py-16 sm:px-8 md:px-16">
          <div className="flex items-center space-x-2">
            <button className="rounded-full hover:bg-gray-100 active:bg-gray-200">
              <BiArrowBack className="h-8 w-8 text-gravel-500 sm:hidden" />
            </button>
            <div className="text-3xl font-bold text-gray-700">
              {fetchedPlan &&
                selectedPlan &&
                selectedPlan?.length &&
                selectedPlan[0]?.name}
            </div>
          </div>
          <div className="mt-8 grid gap-4 grid-auto-fit-sm">
            <div className="rounded-xl border-[1px] border-gray-400 shadow-md shadow-gray-500">
              {selectedImage && selectedImage.length ? (
                <img
                  src={selectedImage[isViewing]?.plan_image_url}
                  className="aspect-square h-auto w-auto rounded-lg object-cover"
                  alt=""
                />
              ) : null}
            </div>
            <div className="grid gap-2 grid-auto-fit-[6rem] md:grid-auto-fit-[9rem]">
              {selectedImage && selectedImage.length
                ? selectedImage.map((img, idx) => (
                    <img
                      key={idx}
                      src={img.plan_image_url}
                      className="aspect-square h-auto w-auto cursor-pointer rounded-lg object-cover"
                      alt=""
                      onClick={() => handleCurrentView(idx)}
                    />
                  ))
                : null}
            </div>
          </div>
          <div>
            <div className="mt-8 text-2xl font-bold tracking-wider text-gray-700">
              {selectedPlan && selectedPlan?.length
                ? `₦${Number(
                    selectedPlan[0]?.recurring_price
                  ).toLocaleString()}/month`
                : null}
            </div>
            <div className="mt-2 text-sm font-medium text-gray-500">
              {selectedPlan && selectedPlan[0]?.description}
              <span className="font-semibold text-gray-800">
                (Quantity and price are subject to current market rates and may
                change)
              </span>
            </div>
            <div className="w-full max-w-sm">
              <div className="mt-4 text-lg font-bold text-gray-600">
                Basket Content
              </div>
              <div className="mt-1 space-y-2 text-sm font-semibold text-gravel-500">
                {items &&
                  items?.length &&
                  items?.map((item, idx) => (
                    <div
                      key={idx}
                      className="mb-2 w-full rounded-lg border-[1px] border-gray-400 px-2 py-2 sm:w-80 "
                    >
                      {item}
                    </div>
                  ))}
              </div>
            </div>
            <div className="mt-4 w-full">
              <button
                onClick={() => setOpen(true)}
                className="w-full max-w-xs rounded-md bg-olivine-500 py-3 text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
              >
                Downgrade Plan
              </button>
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className="mt-28 px-2 xsm:px-4">
                  <Box className="max-h-md mx-auto max-w-xs rounded-lg bg-white ">
                    <DowngradePlanPopover
                      data={payload}
                      handleClose={() => setOpen(false)}
                    />
                  </Box>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewContentDowngradePage;
