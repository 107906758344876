import React, { useContext, useEffect, useState } from 'react'
import search from "../../../assets/search.png"
import backArrow from "../../../assets/back.PNG"
import clock from "../../../assets/clock.png"

import GrainBasketComponet from "./GrainBasketComponet";
import front from '../../../assets/frontArrow.png'
import BasketService from '../../../services/basketService'
import toast from 'react-hot-toast'
import RxjsStoreContext from '../../../context/RxjsStoreContext'

import Handpick4You from './Handpick4You'
import IsLoadingOne from '../../IsLoading_One'
import BasketFloatingPane from './BasketFloatingPane'
import Carousel from './Carousel';
import {authService} from '../../../services/authService'


import searchgreen from '../../../assets/icongreen.png'
import CreateBasketCard from './CreateBasketCard';

// import Showll from './Showll'




function Showall() {
    

    return (<div className='mt-16 px-4 sm:px-8 md:px-16' >


        {/* <div className="my-4">

            <img className="h-10 text-black " src={backArrow} />
        </div> */}
        {/* 
        <div className="relative rounded-md">
            <div className="inset-y-0 pl-3">
                <img className="absolute my-3.5 h-7 w-7 text-gray-600" src={search} />
            </div>
            <input
                type="text"
                placeholder="Search"
                id="search"
                className=" h-[60px] block w-full mt-1 rounded-md border-gray-400 font-semibold text-xl text-gray-600 bg-gray-100 pl-14 placeholder:text-md placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
            />
        </div> */}

        <div className=" grid  grid-auto-fit-xs gap-4">
            <section className="grid grid-cols-10 gap-2 lg:grid-cols-8 ">
                
            <div className="flex items-center col-span-1  lg:col-span-2  ">
                <div className="">
                    <img className="h-10 text-black " src={backArrow} />
                </div>
                <div className="smallText2 hidden  font-bold lg:block">
                    Pick your Basket
                </div>
                {/* <div>
                  <img
                    style={{ backgroundColor: '#E0E0E0' }}
                    src={search} alt="alt"
                    className="text-gray-600 object-none object-center  w-8 h-7  ml-1  lg:hidden"
                  />
                </div> */}
            </div>

            <div className=" w-full block  col-span-6   flex justify-center lg:hidden">
            <div className="relative rounded-md">
            <div className="inset-y-0 pl-3">
                <img className="absolute my-3 h-5 w-5 text-gray-600" src={search} />
            </div>
            <input
                type="text"
                placeholder="Search"
                id="search"
                className=" h-[35px] block w-full mt-1 rounded-md border-gray-400 font-semibold text-sm text-gray-600 bg-gray-100 pl-10 placeholder:text-md placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500 "
            />
            </div> 
            </div>

            <div className="relative search  hidden rounded-md lg:block col-span-4 ml-[10rem] ">
                 
                 <input
                   type="text"
                   placeholder="Quick search for anything"
                   id="search"
                   autoComplete='off'
                   className=" h-[34px] block w-full pt-1 mt-1 rounded-lg border-gray-400 font-semibold text-xl text-gray-600 bg-gray-100 pl-2 placeholder:text-sm placeholder:pb-10 placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                 />

                  <div className=" icon-border border-2 absolute top-1  right-0 px-2 h-[2.1rem] rounded-tr-lg w-100  rounded-br-lg  ">
                   <img
                     className="  h-4 w-4 mt-2 "
                     src={searchgreen}
                   />
                 </div>
            </div>

        

            <div className="col-span-3 flex  justify-end lg:col-span-2 ">
                <div className="text-gravel-500 text-md ">Cancel Search</div>
               
            </div>
            </section>
        </div>


        {/* <section className="grid my-10 grid-cols-4 gap-2 lg:hidden">
            <div className="bg-[#8DAA6A] text-center p-2 rounded showwingResuiltText text-white">
                Showing
            </div>
            <div className="text-[#8DAA6A] text-center p-2 rounded showwingResuiltText bg-[#F2F2F2]">
                Showing
            </div>
            <div className="text-[#8DAA6A] text-center p-2 rounded showwingResuiltText bg-[#F2F2F2]">
                Showing
            </div>
            <div className="text-[#8DAA6A] text-center p-2 rounded showwingResuiltText bg-[#F2F2F2]">
                Showing
            </div>
        </section> */}

        <section className="mt-4">
            <div className='grid  grid-cols-1 gap-x-2  '>

                <div className="col-span-1 " >

                    <div className="flex">
                        <div className='handPickText  '>Showing 12 Results</div>


                    </div>
                </div>

                <div className="flex col-span-1 col-start-2  items-center lg:hidden ">
                    <div className=" seeAllText "  >
                        Clear Filter
                        <hr style={{ backgroundColor: "#73964C" }} className="bg-red-900 font-bold" />
                    </div>





                </div>

                <div className="flex col-span-1 hidden  col-start-2  items-center lg:block ">

                    <div className=" seeAllText "  >
                        See All 24 items
                        <hr style={{ backgroundColor: "#73964C" }} className="bg-red-900 font-bold" />
                    </div>

                </div>

            </div>

             <div className="grid grid-cols-1 mt-4  gap-4 lg:grid-cols-4 ">
               <CreateBasketCard></CreateBasketCard>
            </div> 

            {/* <section>
            <Showll allProductList={allProductList}></Showll>
            </section> */}

        </section>

    </div>);
}

export default Showall;

