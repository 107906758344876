import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "../../services/authService";
import { startLoad, stopLoad } from "../slices/appSlice";
import { handleError } from "../slices/errorSlice";
import { getWalletBalance } from "./userActions";
import PaystackService from "../../services/paystackService";

const verification_code = authService.getCurrentUserVerificationCode();
const userId = authService.getCurrentUserID();
const customerId = authService.getCurrentUserCustomerId();

export const verifyPayment = createAsyncThunk(
  "get/paymentVerify",
  async (ref, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaystackService.verifyPayment(ref);
      const payload = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };

      dispatch(getWalletBalance(payload));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const verifyPaymentRecurr = createAsyncThunk(
  "get/paymentVerifyRecurr",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaystackService.verifyPaymentRecurr(data);
      const payload = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };

      dispatch(getWalletBalance(payload));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const planCreate = createAsyncThunk(
  "post/createPlan",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaystackService.createPlan(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const transactionInitialize = createAsyncThunk(
  "post/transacInit",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaystackService.initializeTransaction(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const cancelRecurringPlan = createAsyncThunk(
  "post/cancelPlan",
  async (ref, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await PaystackService.cancelPlan(ref);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
