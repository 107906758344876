import baseAxios from "../core/api/axios/baseAxios";

class VasService {
  static async buyAirtime(data) {
    const res = await baseAxios.post("vas/airtime", data);

    return res?.data || res;
  }

  static async buyData(data) {
    const res = await baseAxios.post("vas/data", data);

    return res?.data || res;
  }

  static async payBills(data) {
    const res = await baseAxios.post("vas/bills", data);

    return res?.data || res;
  }

  static async billerGroups() {
    const res = await baseAxios.get("vas/biller-group");

    return res?.data || res;
  }

  static async billerGroupEnquiry(id) {
    const res = await baseAxios.get(`vas/biller-group/${id}`);

    return res?.data || res;
  }

  static async getConvenienceFees(id) {
    const res = await baseAxios.get(`vas/fees/${id}`);

    return res?.data || res;
  }

  static async getBillerPlans(id) {
    const res = await baseAxios.get(`vas/packages/${id}`);

    return res?.data || res;
  }

  static async customerEnquiry(data) {
    const res = await baseAxios.post("vas/customer-enquiry", data);

    return res?.data || res;
  }

  static async purchaseDelgroAirtime(data) {
    const res = await baseAxios.post("vas/delgro/airtime", data);

    return res?.data || res;
  }

  static async purchaseDelgroData(data) {
    const res = await baseAxios.post("vas/delgro/data", data);

    return res?.data || res;
  }

  static async purchaseDelgroPaybills(data) {
    const res = await baseAxios.post("vas/delgro/paybills", data);

    return res?.data || res;
  }
}

export default VasService;
