import { createSlice } from "@reduxjs/toolkit";
import {
  cancelPaymentPlan,
  flutterwaveCallback,
  getAutoFund,
  getCancelledPaymentPlan,
  getPaymentPlan,
  getTransactions,
  makeWalletUpdate,
  oneTimePayment,
  recurringPayment,
} from "../actions";

const initialState = {
  oneTimePay: "",
  recurringPay: "",
  cancelPlan: "",
  getPlan: "",
  getCancelPlan: "",
  transactions: "",
  flutterwaveCall: "",
  autoFund: "",
  updateWallet: "",
};

const PaymentSlice = createSlice({
  name: "PaymentData",
  initialState,
  reducers: {
    resetPayment(state) {
      state.oneTimePay = undefined;
      state.recurringPay = undefined;
      state.cancelPlan = undefined;
      state.getPlan = undefined;
      state.getCancelPlan = undefined;
      state.transactions = undefined;
      state.flutterwaveCall = undefined;
      state.autoFund = null;
      state.updateWallet = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(oneTimePayment.fulfilled, (state, { payload }) => {
      state.oneTimePay = payload;
    });

    builder.addCase(recurringPayment.fulfilled, (state, { payload }) => {
      state.recurringPay = payload;
    });

    builder.addCase(cancelPaymentPlan.fulfilled, (state, { payload }) => {
      state.cancelPlan = payload;
    });

    builder.addCase(getPaymentPlan.fulfilled, (state, { payload }) => {
      state.getPlan = payload;
    });

    builder.addCase(getCancelledPaymentPlan.fulfilled, (state, { payload }) => {
      state.getCancelPlan = payload;
    });

    builder.addCase(getTransactions.fulfilled, (state, { payload }) => {
      state.transactions = payload;
    });

    builder.addCase(flutterwaveCallback.fulfilled, (state, { payload }) => {
      state.flutterwaveCall = payload;
    });

    builder.addCase(getAutoFund.fulfilled, (state, { payload }) => {
      state.autoFund = payload;
    });

    builder.addCase(makeWalletUpdate.fulfilled, (state, { payload }) => {
      state.updateWallet = payload;
    });
  },
});

const { reducer, actions } = PaymentSlice;

export const { resetPayment } = actions;

export default reducer;
