import React from "react";
import { IoClose } from "react-icons/io5";

function PaywithCardPopover({ handleShowing, onClose }) {
  return (
    <div className="p-6 sm:p-8 bg-white w-[290px] sm:w-[460px] text-gray-500 shadow-lg rounded-2xl">
      <div className="flex justify-between items-center">
        <div className="font-bold text-lg text-gravel-500">Pay with card</div>
        <button className="p-1 rounded-full hover:bg-gray-200">
          <IoClose
            onClick={() => {
              onClose();
              handleShowing();
            }}
            className="h-5 w-5 text-gravel-500"
          />
        </button>
      </div>
      <div className="mt-3 flex justify-start font-semibold text-sm text-gravel-500">
        Confirm Transaction
      </div>
      <div>
        <div className="mt-1 p-3 bg-[#E9F8CC] ring-1 ring-gray-300 rounded-lg filter drop-shadow-md">
          <div className="flex justify-start font-bold text-lg text-gravel-500">
            ₦9,000.00
          </div>
          <div className="flex items-center justify-between mt-1">
            <div className="text-sm font-semibold text-gravel-400 ">
              XXXX XXXX XXXX 1234
            </div>
            <div className="flex">
              <div className="h-7 w-7 rounded-full bg-[#EB001B]"></div>
              <div className="h-7 w-7 rounded-full bg-[#F79E1B] -ml-3 opacity-75"></div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex sm:items-center justify-start space-x-2">
          <input
            type="checkbox"
            className="text-olivine-500 mt-1 sm:mt-0 h-4 w-4 rounded-sm focus:ring-0 cursor-pointer"
          />
          <span className="text-sm">
            I agree to be debited the amount above
          </span>
        </div>
        <div className="mt-8">
          <div className="flex justify-between text-center">
            <button
              onClick={() => {
                onClose();
                handleShowing();
              }}
              className="px-6 py-3 text-olivine-500 border-2 border-olivine-500 text-sm rounded-md hover:border-olivine-600 transition-all duration-300 tracking-widest"
            >
              BACK
            </button>
            <button className="px-3 sm:px-6 py-3 text-white bg-olivine-500 rounded-md text-sm hover:bg-olivine-600 tracking-widest">
              ADD ₦9,000.00
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaywithCardPopover;
