import React, { useContext, useEffect, useState } from "react";


import "./creatBasket.css";
import CurrencyFormat from "react-currency-format";
import RxjsStoreContext from "../../context/RxjsStoreContext";
import { useNavigate } from "react-router-dom";
import CreateBasketCardButchers from "./CreateBasketCardButchers";

function YourFavouriteButchers(props) {
  const rxjsStore = useContext(RxjsStoreContext);
  let navigate = useNavigate();
  const { allProductList } = props;
  const [randomList, setRandomList] = useState([]);
  const [favoriteList, setAllFavoriteList] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    let newList = allProductList?.filter(
      (product) => product.favourite_status === 1
    );
    setAllFavoriteList(newList);
  let fistFourItemList = newList?.slice(0, 4);
    setRandomList(fistFourItemList);
  }, [allProductList]);

  const seeAllItems = () => {
    rxjsStore.setSectionList(favoriteList);
    navigate(`/basket/create-basket/section`);
  };

  return (
    <section className="mt-8">
      <div className="flex items-center justify-between">
        <div className="">
          <div className="flex">
            <div className="text-lg text-gray-600 font-bold">Your Favorite</div>
          </div>
        </div>
        <div className="">
          <div className="group text-olivine-500 font-semibold cursor-pointer" onClick={() => seeAllItems()}>
            <div> See All {favoriteList?.length} items </div>
            <hr
              className="-mt-1 h-[2px] bg-olivine-500 opacity-0 group-hover:opacity-100"
            />
          </div>
        </div>
      </div>
      <div className="mt-6 grid gap-6 grid-auto-fit-sm">
        {randomList?.map((product, index) => {
          return <CreateBasketCardButchers product={product} key={index} />;
        })}
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  );
}

export default YourFavouriteButchers;
