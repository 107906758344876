import React, { useContext, useEffect, useState } from "react";
import { debounce } from "lodash";
import "./creatBasket.css";
import toast from "react-hot-toast";
// import YourFavourite from "./YourFavourite";
// import TopDeals from "./TopDeals";
import AllItems from "./AllItems";
import BasketService from "../../../services/basketService";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import Handpick4You from "./Handpick4You";
import IsLoadingOne from "../../IsLoading_One";
import BasketFloatingPane from "./BasketFloatingPane";
import Carousel from "./Carousel";
import { authService } from "../../../services/authService";
import { HiChevronDoubleRight } from "react-icons/hi";
import { GoSearch } from "react-icons/go";
import { BiArrowBack } from "react-icons/bi";
import SearchPageEmpty from "./SearchPageEmpty";
import { useNavigate } from "react-router-dom";

function CreateButchersContent() {
  const rxjsStore = useContext(RxjsStoreContext);
  const [basketGroupList, setBasketGroupList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [allProductList, setAllProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSection, setCurrentSection] = useState("");
  const [searchTextInput, setSearchTextInput] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const searchRef = React.createRef();
  const navigate = useNavigate();

  const style = {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  };

  useEffect(() => {
    if (authService.isLogin()) {
      callApiForAuth();
    }
    rxjsStore.getAllBasketProductList().subscribe((allProduct) => {
      setAllProductList(allProduct);
      setSearchResult(allProduct);
    });

    rxjsStore.getBasketGroupList().subscribe((data) => {
      if (data?.length < 1) {
        callApi();
      } else {
        setBasketGroupList(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function callApiForAuth() {
    setIsLoading(true);
    BasketService.getBasketListAuth()
      .then((data, failed) => {
        if (failed) {
          return toast.error("error getting products");
        }

        setIsLoading(false);
        const { item_group, items, delivery_charge } = data;
        if (item_group?.length) {
          rxjsStore.setBasketGroupList(item_group);
          rxjsStore.setAllBasketProductList(items);
          let charge = {
            item_id: delivery_charge[0]?.item_id,
            item_total: delivery_charge[0]?.item_rate,
            rate: delivery_charge[0]?.item_rate,
            quantity: 1,
            name: "Delivery charge",
          };
          rxjsStore.setDeliveryChargeButcher(charge);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Error getting product");
      });
  }

  function callApi() {
    setIsLoading(true);
    BasketService.getBasketList()
      .then((data, failed) => {
        if (failed) {
          return toast.error("error getting products");
        }

        setIsLoading(false);
        const { item_group, items, delivery_charge } = data;
        if (item_group?.length) {
          rxjsStore.setBasketGroupList(item_group);
          rxjsStore.setAllBasketProductList(items);
          let charge = {
            item_id: delivery_charge[0]?.item_id,
            item_total: delivery_charge[0]?.item_rate,
            rate: delivery_charge[0]?.item_rate,
            quantity: 1,
            name: "Delivery charge",
          };
          rxjsStore.setDeliveryChargeButcher(charge);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Error getting product");
      });
  }

  function viewOrderHistory() {
    navigate("/basket/order-history");
  }

  const resetProductGroup = (groupId) => {
    const newList = rxjsStore.getProductByGroup(groupId);
    // setAllProductList(newList);
    setCurrentSection(groupId);
    setSearchResult(newList);
  };

  const debouncedSearch = React.useRef(
    debounce(async (event) => {
      processSearch(event);
    }, 2000)
  ).current;

  function processSearch(event) {
    const search = event.target.value;
    setSearchTextInput(search);
    setCurrentSection(search);
    if (search.length < 2) {
      setSearchResult([...allProductList]);
    } else if (search.length > 2) {
      var tempResult = rxjsStore.handleSearchProduct(search);
      setSearchResult(tempResult);
    }
  }

  return (
    <>
      <section className="relative">
        <section>
          {isLoading ? (
            <div>
              <IsLoadingOne message="Getting products..." />
            </div>
          ) : (
            <section className="mx-4 mt-10 sm:px-2 md:px-16">
              <div className="mb-5 xsm:flex xsm:items-center xsm:justify-between xsm:space-x-2 sm:space-x-4">
                <div className="flex items-center space-x-2">
                  <div className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
                    <BiArrowBack
                      onClick={() => navigate(-1)}
                      className="cursor h-5 w-5 text-gray-600"
                    />
                  </div>
                  <div className="hidden text-sm font-semibold text-gray-700 sm:text-base md:inline">
                    Pick your Basket
                  </div>
                </div>
                <div className="mt-2 max-w-[600px] flex-1 xsm:mt-0">
                  <div
                    className={`flex items-center rounded-lg border  border-gray-400 shadow-sm focus:border-olivine-500
                      ${
                        searchInputValue
                          ? "ring-[1px] ring-olivine-500"
                          : "ring-0"
                      }
                    `}
                  >
                    <input
                      ref={searchRef}
                      onChange={(ev) => {
                        debouncedSearch(ev);
                        setSearchInputValue(ev.target.value);
                      }}
                      type="text"
                      placeholder="Quick search for anything"
                      className="flex-grow border-0 bg-transparent pl-5 text-base text-gray-600 outline-none ring-0 placeholder:text-sm placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0"
                    />
                    <div className="flex h-10 w-10 items-center justify-center rounded-r-lg bg-gray-100">
                      <GoSearch className="h-[18px] w-[18px] cursor-pointer rounded-full text-olivine-500" />
                    </div>
                  </div>
                </div>
                {/* <div className='-mt-[71px] xsm:mt-0 md:col-span-1 flex justify-end text-sm text-gray-500 font-semibold cursor-pointer hover:text-gray-700'><span className='md:hidden'>Cancel</span><span className='hidden md:inline'>Cancel Search</span></div> */}

                <div
                  onClick={() => viewOrderHistory()}
                  className=" -mt-[75px] flex cursor-pointer items-center justify-end text-olivine-500 hover:text-olivine-600 xsm:mt-0"
                >
                  <div className="hidden text-sm font-semibold sm:text-base md:inline-flex">
                    Order History
                  </div>
                  <div className="ml-1">
                    <HiChevronDoubleRight className="h-5 w-5" />
                  </div>
                </div>
              </div>

              <div>
                {searchResult?.length ? (
                  <AllItems allProductList={searchResult} />
                ) : (
                  <SearchPageEmpty searchResult={searchResult} />
                )}
              </div>
            </section>
          )}
        </section>

        <section className="flex justify-center bg-red-900">
          <BasketFloatingPane style={style} />
        </section>
      </section>
    </>
  );
}

export default CreateButchersContent;
