import React from "react";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import TrackPageSidebar from "./TrackPageSidebar";
import TrackPageLarge from "./TrackPageLarge";

function TrackPageSmall({
  clicker,
  selectedOrders,
  mapData,
  open,
  defaultTracking,
}) {
  return (
    <div className="lg:hidden">
      <Tab.Group>
        <Tab.List className="text-sm">
          <div className="grid grid-cols-2 bg-olivine-500 py-[1px] text-white">
            <Tab as={Fragment}>
              {({ selected }) => (
                /* Use the `selected` state to conditionally style the selected tab. */
                <button
                  className={
                    selected
                      ? "col-span-1 border-b-[4px] border-white border-opacity-100  py-4 outline-none"
                      : "col-span-1 border-b-[4px] border-olivine-500 border-opacity-0 py-4"
                  }
                >
                  <div className="cursor-pointer">
                    <div className="px-8 font-semibold">ORDERS</div>
                  </div>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                /* Use the `selected` state to conditionally style the selected tab. */
                <button
                  className={
                    selected
                      ? "col-span-1 border-b-[4px] border-white border-opacity-100  py-4 outline-none"
                      : "col-span-1 border-b-[4px] border-olivine-500 border-opacity-0 py-4"
                  }
                >
                  <div className="cursor-pointer">
                    <div className="px-8 font-semibold">MAP</div>
                  </div>
                </button>
              )}
            </Tab>
          </div>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div className="">
              <TrackPageSidebar
                clicker={clicker}
                selectedOrders={selectedOrders}
              />
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="">
              <TrackPageLarge
                open={open}
                clicker={clicker}
                mapData={mapData}
                defaultTracking={defaultTracking}
              />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default TrackPageSmall;
