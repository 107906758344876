import React from "react";
import CowriesImage from "../../../assets/cowriesImage.svg";
import ProgressBar from "@ramonak/react-progress-bar";

const EarnedBreakdownComponents = ({
  methodToEarn,
  statusEarned,
  numberEarned,
  transactionAmount,
}) => {
  return (
    <div className="w-full rounded-xl border-[1px] border-gray-100 px-4 py-4 shadow-xl sm:px-6">
      <div className="max-w-2xl">
        <div className="flex flex-row items-center space-x-2">
          <div>
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="2" fill="#E8F3D4" />
              <path
                d="M14.666 11.8335C15.7727 11.8335 16.6593 10.9402 16.6593 9.8335C16.6593 8.72683 15.7727 7.8335 14.666 7.8335C13.5593 7.8335 12.666 8.72683 12.666 9.8335C12.666 10.9402 13.5593 11.8335 14.666 11.8335ZM9.33268 11.8335C10.4393 11.8335 11.326 10.9402 11.326 9.8335C11.326 8.72683 10.4393 7.8335 9.33268 7.8335C8.22602 7.8335 7.33268 8.72683 7.33268 9.8335C7.33268 10.9402 8.22602 11.8335 9.33268 11.8335ZM9.33268 13.1668C7.77935 13.1668 4.66602 13.9468 4.66602 15.5002V17.1668H13.9993V15.5002C13.9993 13.9468 10.886 13.1668 9.33268 13.1668ZM14.666 13.1668C14.4727 13.1668 14.2527 13.1802 14.0193 13.2002C14.7927 13.7602 15.3327 14.5135 15.3327 15.5002V17.1668H19.3327V15.5002C19.3327 13.9468 16.2193 13.1668 14.666 13.1668Z"
                fill="#558223"
              />
            </svg>
          </div>
          <p className="text-sm font-semibold text-[#558223]">{methodToEarn}</p>
        </div>
        <div className="mt-2 flex flex-row items-center space-x-2">
          <div>
            <div>
              <img src={CowriesImage} alt="icon" className="h-6 w-6" />
            </div>
          </div>
          <div className="flex-1">
            <ProgressBar
              height="12px"
              width="100%"
              bgColor="#558223"
              maxCompleted="100"
              completed={statusEarned}
              labelSize="10px"
              labelAlignment="right"
              isLabelVisible={false}
            />
          </div>
          <p className="text-xs font-semibold text-gray-500">
            <span className="text-gray-600">{statusEarned}</span> Cowries
          </p>
        </div>
        <p className="mt-6 text-sm font-semibold text-gray-500">
          You earned {numberEarned} cowries through this method.
        </p>
      </div>
    </div>
  );
};

export default EarnedBreakdownComponents;
