import React, { useContext, useEffect, useState } from "react";
import Avatar from "../../../assets/avatar.svg";
import { CgClose } from "react-icons/cg";
import { MdEdit } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { profileService } from "../../../services/profileService";
import ProfileContext, {
  ProfileContextProvider,
} from "../../../context/ProfileContext";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import LogicContext from "../../../context/LogicContext";
import CircularProgress from "@mui/material/CircularProgress";
import SalutationSelect from "./SalutationSelect";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../../services/authService";
import { todoListStatus } from "../../../store/actions";

function ProfileSettingsWeb() {
  const dispatch = useDispatch();
  const logicContext = useContext(LogicContext);
  const [isLoading, setIsLoading] = useState(false);
  const [salutationList, setSalutationList] = useState([]);
  const [dateOfBirth, setdateOfBirth] = useState("");

  const { todoListStatusData } = useSelector((state) => state.masterData);

  const [statusPercent, setStatusPercent] = useState("");
  const [fetchedStatuses, setfetchedStatuses] = useState("");

  const customerId = authService.getCurrentUserZohoCustomerId();
  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();

  const profileContext = useContext(ProfileContext);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!todoListStatusData) {
      const data = {
        sql_ref_no: "20",
        user_id: userId?.toString(),
        verification_code: verification_code?.toString(),
        option: "1",
      };

      dispatch(todoListStatus(data));
    }

    if (todoListStatusData) setfetchedStatuses(todoListStatusData);
  }, [dispatch, todoListStatusData, verification_code, userId]);

  useEffect(() => {
    if (fetchedStatuses) {
      const results = [];
      for (let key in fetchedStatuses) {
        results.push(fetchedStatuses[key]);
      }

      // Return the number of times an array element appears in the array
      const getOccurrence = (array, value) => {
        return array.filter((v) => v === value).length;
      };

      const getPercentage = (array, occ) => {
        return Math.round((occ / array.length) * 100);
      };

      const yesOcc = getOccurrence(results, "True");

      const yesPercent = getPercentage(results, yesOcc);

      setStatusPercent(yesPercent);
    }
  }, [fetchedStatuses]);

  useEffect(() => {
    const userProfileRecord = profileContext.getProfileData();
    if (userProfileRecord?.customer_id) {
      setProfileProperties(userProfileRecord);
    } else {
      getUserProfileFromApi();
    }
  }, []);

  function getUserProfileFromApi() {
    profileService.getUserProfile().then((res) => {
      if (res.data?.customer_info[0]) {
        setProfileProperties(res.data?.customer_info[0]);
        // profileContext.setProfileData(res.data?.customer_info[0]);
      }
    });
  }

  useEffect(() => {
    const salutationList = profileContext.getSalutationList();
    if (salutationList?.length) {
      setSalutationList(salutationList);
    } else {
      profileService.getSalutationsList().then((res) => {
        if (res.data?.Salutation) {
          setSalutationList(res.data?.Salutation);
          profileContext.setSalutationList(res.data?.Salutation);
        }
      });
    }
  }, []);

  const setProfileProperties = (data) => {
    setValue("customer_email_address", data?.customer_email_address);
    setValue("customer_first_name", data?.customer_first_name);
    setValue("customer_last_name", data?.customer_last_name);
    setValue("date_of_birth", data?.date_of_birth);
    setValue("customer_mobile_no", data?.customer_mobile_no);
    setValue("salutation_id", data?.salutation_id);
    if (data?.date_of_birth) {
      setdateOfBirth(moment(data?.date_of_birth).format("YYYY-MM-DD"));
    }
  };

  const handleSalutationChange = (selection) => {
    setValue("salutation_id", selection);
  };

  function onErrors(error) {
    toast.error("One or more value(s) is required");
  }

  const submitProfileRecords = () => {
    const formData = getValues();
    setIsLoading((prev) => !prev);
    profileService
      .updatePersonalDetails(formData)
      .then((res) => {
        setIsLoading((prev) => !prev);
        const filteredData = logicContext.httpResponseManager(
          res?.data?.result_status[0]
        );
        if (filteredData?.message_id == 1) {
          getUserProfileFromApi();
        }
      })
      .catch((err) => {
        setIsLoading((prev) => !prev);
      });
  };

  function onDateChange(date) {
    setValue("date_of_birth", date);
    setdateOfBirth(date);
  }

  return (
    <div className="mt-8 pb-16">
      <div className="hidden w-full md:inline md:w-auto">
        <div className="flex items-center justify-between rounded-md bg-[#4F4F4F] px-4 py-3 md:bg-white md:px-0">
          <div className="text-xl font-semibold text-white md:text-xl md:text-gray-600">
            Personal Details
          </div>

          <Link to="/home/wallet">
            <button className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-white p-8 px-6 py-2.5 text-sm font-semibold text-olivine-500 transition duration-300 ease-in-out hover:ring-[0.5px] hover:ring-olivine-500 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100">
              <div className="text-sm font-semibold text-olivine-500">
                Fund your Account
              </div>
            </button>
          </Link>
        </div>

        {/* <div className="text-xl font-semibold text-white md:text-xl md:text-gray-600">
            Account Type: {authService?.getAccountType()}
          </div>

          <div className="text-xl font-semibold text-white md:text-xl md:text-gray-600">
            Status: {authService?.getApprovedStatus()}
          </div> */}

        <div className="text-sm font-semibold text-gray-600">
          Status:
          <span className="text-olivine-600">
            {authService?.getApprovedStatus()}
          </span>
        </div>
      </div>
      <div className="h-hug mt-4 w-full rounded-md border-[0.5px] border-gray-300 bg-[#E9F8CC] px-6 py-4">
        <div className="flex flex-row justify-between">
          <h4 className="text-xs font-semibold text-gray-600">
            Your profile progress
          </h4>
          <div className="cursor-pointer rounded-full bg-[#F6FBEE] p-1 active:bg-gray-200">
            <CgClose className="h-3 w-3 text-gray-600" />
          </div>
        </div>
        <div className="mt-3 sm:flex sm:gap-8 lg:mt-1 lg:gap-16 lg:pr-10">
          <div className="flex-1">
            <div className="text-base font-bold text-gravel-500">
              {statusPercent ? `${statusPercent}% Complete` : "0%"}
            </div>
            <div className="relative mt-1">
              <div className="h-2 w-full rounded-full bg-white"></div>
              <div
                style={{ width: statusPercent + "%" }}
                className="absolute top-0 h-2 rounded-full bg-olivine-500"
              ></div>
            </div>
          </div>
          <Link to="/home/todo">
            <button className="mt-6 w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F6FBEE] p-8 py-2.5 text-xs font-semibold text-olivine-500 transition-all duration-200 hover:-translate-y-0.5 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 xsm:text-sm sm:mt-0 sm:w-auto sm:px-6 sm:py-[11px] lg:px-10">
              To-do list
            </button>
          </Link>
        </div>
      </div>
      <div className="mt-6 flex">
        <div className="flex flex-col items-center justify-center">
          <div className="">
            <img
              src={Avatar}
              className="aspect-auto h-14 w-14 rounded-full border-[1px] border-gray-300 bg-gray-100 object-cover text-gray-500"
              alt=""
            />
          </div>
          <div className="mt-1 flex cursor-pointer items-center space-x-1 font-semibold text-olivine-500 transition-all duration-300 hover:text-olivine-600">
            <div>
              <MdEdit className="h-4 w-4" />
            </div>
            <div className="text-sm">Edit Image</div>
          </div>
        </div>
      </div>

      <form
        onSubmit={handleSubmit(submitProfileRecords, onErrors)}
        className="z-30 max-w-lg"
      >
        <div className="mt-4">
          <label htmlFor="title" className="ml-3 text-xs text-gray-500">
            Title
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <SalutationSelect
                default={getValues("salutation_id")}
                mainList={salutationList}
                activeSelect={handleSalutationChange}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="firstName" className="ml-3 text-xs text-gray-500">
            First Name
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <input
                type="text"
                placeholder="Enter Your First Name"
                className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm font-semibold text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                {...register("customer_first_name", {
                  required: "FirstName is required!",
                })}
              />
              <small className="text-red-600">
                {errors.customer_first_name?.message}
              </small>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="lastName" className="ml-3 text-xs text-gray-500">
            Last Name
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <input
                type="text"
                placeholder="Enter Your Last Name"
                className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm font-semibold text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                {...register("customer_last_name", {
                  required: "LastName is required!",
                })}
              />
              <small className="text-red-600">
                {errors.customer_last_name?.message}
              </small>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="emailAddress" className="ml-3 text-xs text-gray-500">
            Email Address
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <input
                type="text"
                placeholder="Enter Your email Address"
                readOnly={getValues("customer_email_address")}
                className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm font-semibold text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                {...register("customer_email_address", {
                  required: "Email is required!",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid email address",
                  },
                })}
              />
              <small className="text-red-600">
                {errors.customer_email_address?.message}
              </small>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="dateOfBirth" className="ml-3 text-xs text-gray-500">
            Date of Birth
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <input
                value={dateOfBirth}
                onChange={(e) => onDateChange(e.target.value)}
                type="date"
                className="block w-full rounded-md border-gray-400 bg-white pl-4 text-sm font-semibold text-gray-600 placeholder:text-sm focus:border-olivine-500 focus:ring-olivine-500"
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="phoneNumber" className="ml-3 text-xs text-gray-500">
            Phone Number
          </label>
          <div className="relative flex items-center justify-end rounded-md">
            <div className="flex-grow">
              <input
                type="text"
                readOnly={getValues("customer_mobile_no")}
                placeholder="Enter Your Phone Number (2348031234567)"
                className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                {...register("customer_mobile_no", {
                  required: "Phone is required!",
                  minLength: 11,
                  message: "Invalid phone number",
                })}
              />
              <small className="text-red-600">
                {errors.customer_mobile_no?.message}
              </small>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            disabled={isLoading}
            className="w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600 sm:w-auto sm:px-16"
          >
            {isLoading === true ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "SAVE DETAILS "
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProfileSettingsWeb;
