import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import toast from "react-hot-toast";

function PurchaseBulkGiftVoucherOne({
  setOpen,
  setOpenPin,
  setVoucherDetails,
  setOpenVoucherSummary,
  handleItemPull,
}) {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [formValues, setFormValues] = useState({
    amount: "",
    reciepient_name: "",
    reciepient_email: "",
    reciepient_mobile: "",
    remark: "",
  });

  const [formValuesBulk, setFormValuesBulk] = useState({
    quantity: "",
    amountPerVoucher: "",
    totalAmount: "",
    remark: "",
  });

  useEffect(() => {
    if (formValuesBulk?.quantity && formValuesBulk.amountPerVoucher) {
      const total = formValuesBulk?.quantity * formValuesBulk.amountPerVoucher;
      setFormValuesBulk((values) => ({ ...values, totalAmount: total }));
    }
  }, [formValuesBulk?.quantity, formValuesBulk.amountPerVoucher]);

  const handleChange = (event) => {
    try {
      const name = event.target.name;
      const value = event.target.value;
      setFormValues((values) => ({ ...values, [name]: value }));
    } catch (err) {
      return err;
    }
  };

  const handleChangeBulk = (event) => {
    try {
      const name = event.target.name;
      const value = event.target.value;
      setFormValuesBulk((values) => ({ ...values, [name]: value }));
    } catch (err) {
      return err;
    }
  };

  const handleSubmit = () => {
    try {
      if (!formValues.amount || !formValues.reciepient_name) {
        return toast.error("Please fill required fields");
      }

      if (formValues.remark && !formValues.reciepient_email) {
        return toast.error("Please fill email field");
      }
      setVoucherDetails(formValues);
      setOpenPin(true);
      setOpen(false);
    } catch (err) {
      return err;
    }
  };

  const handleSubmitBulk = () => {
    try {
      setOpen(false);
      setOpenVoucherSummary(true);
      handleItemPull(formValuesBulk, selectedOption);
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="px-2">
      <div className="flex max-w-lg flex-row items-center justify-between bg-[#FEFEFD]">
        <h2 className="text-lg font-semibold text-gray-700">
          Purchase Bulk Gift Voucher
        </h2>
        <div
          onClick={() => setOpen(false)}
          className="cursor-pointer rounded-full bg-olivine-500 p-1 text-white active:bg-olivine-400"
        >
          <IoClose className="h-5 w-5" />
        </div>
      </div>
      <div className="mt-2">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="space-y-4 text-[11px] md:flex md:items-center md:space-x-6 md:space-y-0">
            <Tab as={Fragment}>
              {({ selected }) => (
                /* Use the `selected` state to conditionally style the selected tab. */
                <button
                  className={
                    selected
                      ? "border-b-[1px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                      : "border-b-[1px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                  }
                >
                  <div className="cursor-pointer">
                    <div className="px-1 font-semibold">Single Voucher</div>
                  </div>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                /* Use the `selected` state to conditionally style the selected tab. */
                <button
                  className={
                    selected
                      ? "border-b-[1px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                      : "border-b-[1px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                  }
                >
                  <div className="cursor-pointer">
                    <div className="px-1 font-semibold">Bulk Voucher</div>
                  </div>
                </button>
              )}
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <div className="mt-2 rounded-lg border-[0.5px] border-gray-200 bg-white px-4 py-2 shadow-md">
                <form className="pb-6">
                  <div className="">
                    <label
                      htmlFor="email"
                      className="ml-3 text-xs font-medium text-gray-600"
                    >
                      Enter Amount*
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <input
                          type="number"
                          name="amount"
                          value={formValues.amount}
                          onChange={handleChange}
                          placeholder=""
                          className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="email"
                      className="ml-3 text-xs font-medium text-gray-600"
                    >
                      Recipient’s Name*
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <input
                          type="text"
                          name="reciepient_name"
                          value={formValues.reciepient_name}
                          onChange={handleChange}
                          placeholder=""
                          className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="email"
                      className="ml-3 text-xs font-medium text-gray-600"
                    >
                      Recipient’s Phone number*
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <input
                          type="text"
                          name="reciepient_mobile"
                          value={formValues.reciepient_mobile}
                          onChange={handleChange}
                          placeholder=""
                          className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="email"
                      className="ml-3 text-xs font-medium text-gray-600"
                    >
                      Recipient’s Email Address
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <input
                          type="text"
                          name="reciepient_email"
                          value={formValues.reciepient_email}
                          onChange={handleChange}
                          placeholder=""
                          className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="email"
                      className="ml-3 text-xs font-medium text-gray-600"
                    >
                      Message (Optional)
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <textarea
                          type="text"
                          name="remark"
                          value={formValues.remark}
                          onChange={handleChange}
                          placeholder=""
                          className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                      </div>
                    </div>
                  </div>
                </form>
                {/* <div className="mt-4">
          <button
            onClick={() => handleSubmit()}
            className="w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 sm:px-10"
          >
            Proceed
          </button>
        </div> */}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="rounded-lg border-[0.5px] border-gray-200 bg-white px-4 py-2 shadow-md">
                <form className="pb-6">
                  <div className="">
                    <label
                      htmlFor="email"
                      className="ml-1 text-[11px] font-medium text-gray-600"
                    >
                      Total Voucher Quantity
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <input
                          type="number"
                          name="quantity"
                          placeholder=""
                          value={formValuesBulk.quantity}
                          onChange={handleChangeBulk}
                          className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-xs placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1">
                    <label
                      htmlFor="email"
                      className="ml-1 text-[11px] font-medium text-gray-600"
                    >
                      Enter Amount per Voucher*
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <input
                          type="text"
                          name="amountPerVoucher"
                          placeholder=""
                          value={formValuesBulk.amountPerVoucher}
                          onChange={handleChangeBulk}
                          className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-xs placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1">
                    <label
                      htmlFor="email"
                      className="ml-1 text-[11px] font-medium text-gray-600"
                    >
                      Total Amount*
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <input
                          type="text"
                          name="totalAmount"
                          placeholder=""
                          value={formValuesBulk.totalAmount}
                          className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-xs placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1">
                    <label
                      htmlFor="email"
                      className="ml-1 text-[11px] font-medium text-gray-600"
                    >
                      Message (Optional)
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <textarea
                          type="text"
                          name="remark"
                          placeholder=""
                          value={formValuesBulk.remark}
                          onChange={handleChangeBulk}
                          className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-xs placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <div className="text-[11px]">
                  <p className="text-gray-600">
                    How would you like to send this Voucher*
                  </p>
                  <div className="mt-2 space-y-2 sm:flex sm:flex-row sm:items-center sm:space-x-2 sm:space-y-0">
                    <p
                      onClick={() => setSelectedOption("Generate")}
                      className={`cursor-pointer rounded-full text-center  ${
                        selectedOption === "Generate"
                          ? "bg-olivine-500 text-white"
                          : "bg-gray-200 text-gray-600"
                      } px-4 py-1`}
                    >
                      Generate and Assign codes
                    </p>
                    <p
                      onClick={() => setSelectedOption("Import")}
                      className={`cursor-pointer rounded-full text-center  ${
                        selectedOption === "Import"
                          ? "bg-olivine-500 text-white"
                          : "bg-gray-200 text-gray-600"
                      } px-4 py-1`}
                    >
                      Import Recipient Details
                    </p>
                  </div>
                  {selectedOption === "Import" ? (
                    <p className="mt-2 cursor-pointer font-medium text-olivine-500 hover:underline hover:underline-offset-2">
                      Import Users List via Excel/ Csv
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="mt-4">
        {selectedIndex === 0 ? (
          <button
            onClick={() => handleSubmit()}
            className="w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 sm:px-10"
          >
            Proceed
          </button>
        ) : (
          <button
            disabled={selectedOption ? false : true}
            onClick={() => handleSubmitBulk()}
            className="w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
            // className="rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
          >
            Proceed
          </button>
        )}
      </div>
    </div>
  );
}

export default PurchaseBulkGiftVoucherOne;
