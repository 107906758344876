import React from "react";

function SignedInHeaderItems({ Icon, title, clicked }) {
  return (
    <div
      onClick={clicked ? clicked : null}
      className="flex items-center group space-x-1.5 text-base cursor-pointer"
    >
      <div className="">{Icon}</div>
      <p className="">
        {title}
      </p>
    </div>
  );
}

export default SignedInHeaderItems;
