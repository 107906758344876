import React, { useContext, useEffect, useState } from "react";

import "./creatBasket.css";
import RxjsStoreContext from "../../context/RxjsStoreContext";
import { useNavigate } from "react-router-dom";
import BasketService from "../../services/basketService";
import CreateBasketCardButchersHandPick from "./CreateBasketCardBuchersHandPick";
import Marquee from "react-fast-marquee";

function HandPicket4You(props) {
  let navigate = useNavigate();
  const rxjsStore = useContext(RxjsStoreContext);
  const { allProductList } = props;
  const [topList, setTopList] = useState([]);

  useEffect(() => {
    let newList = allProductList?.slice(8, 12);
    setTopList(newList);
  }, [allProductList]);

  useEffect(() => {
    BasketService.getUserSearchHistory().then((storeList) => {
      const lastProps = storeList?.searched_items[0];
      if (lastProps?.search_text) {
        var tempResultList = allProductList?.filter((product) => {
          return (
            product?.name
              ?.toLowerCase()
              .indexOf(lastProps?.search_text?.toLowerCase()) >= 0
          );
        });
        if (tempResultList?.length) {
          setTopList(tempResultList.slice(0, 4));
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const seeAllItems = () => {
    rxjsStore.setSectionList(topList);
    navigate(`/butchers/create-basket/section`);
  };

  return (
    <section className="mt-4">
      <div className="flex items-start justify-between xsm:items-center">
        <div className="">
          <div className="text-base font-bold text-gray-600 xsm:text-lg">
            Hand Pick For You
          </div>
        </div>
      </div>

      <div className="mt-2 ">
        <Marquee speed={40} gradient={false} pauseOnHover>
          {topList?.map((product, index) => {
            return (
              <CreateBasketCardButchersHandPick product={product} key={index} />
            );
          })}
        </Marquee>
      </div>
    </section>
  );
}

export default HandPicket4You;
