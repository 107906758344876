import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { AiOutlineCheck } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";

function BarChart({ stats }) {
  const currentYear = new Date().getFullYear();
  const pastYears = 5;
  const plans = Array.from({ length: pastYears + 1 }, (_, index) => {
    const year = currentYear - index;
    return { name: year.toString() };
  });

  const [selectedTab, setSelectedTab] = useState(0);
  const [selected, setSelected] = useState(plans[0]);
  const [userData, setUserData] = useState({
    labels: stats?.map((data) => data.transaction_month),
    datasets: [
      {
        labels: "Amount",
        data: stats?.map((data) => data.amount),
      },
    ],
    datasets: [
      {
        labels: "Amount",
        data: stats?.map((data) => data.amount),
      },
    ],
  });

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        const filterYear = stats?.filter(
          (stat) => stat?.transaction_year === +selected.name
        );

        const newArray = [];

        if (filterYear?.length) {
          for (let i = 0; i < filterYear.length; i++) {
            const found = newArray.some(
              (el) => el?.coveredmonth === filterYear[i]?.transaction_month
            );
            if (!found) {
              const newObj = {
                dataSets: [],
                coveredmonth: filterYear[i]?.transaction_month,
              };
              newObj.dataSets.push(filterYear[i]?.amount);
              newArray.push(newObj);
            }
            if (found)
              newArray.filter((el) => {
                if (el?.coveredmonth === filterYear[i]?.transaction_month) {
                  el?.dataSets?.push(filterYear[i].amount);
                }
              });
          }
        }

        const newArrayUpdate = [];

        if (filterYear?.length) {
          for (let i = 0; i < filterYear.length; i++) {
            const found = newArrayUpdate.some(
              (el) =>
                el?.transaction_name === filterYear[i]?.transaction_type_name
            );
            if (!found) {
              const newObj = {
                dataSets: [],
                coveredmonth: filterYear[i]?.transaction_month,
                transaction_name: filterYear[i]?.transaction_type_name,
              };
              newObj.dataSets.push(filterYear[i]?.amount);
              newArrayUpdate.push(newObj);
            }
            if (found)
              newArrayUpdate.filter((el) => {
                if (
                  el?.transaction_name === filterYear[i]?.transaction_type_name
                ) {
                  el?.dataSets?.push(filterYear[i].amount);
                }
              });
          }
        }

        setUserData({
          labels: newArray?.map((month) => month.coveredmonth),
          datasets: [
            {
              label: "Deposits",
              data: newArrayUpdate[0]?.dataSets,
              backgroundColor: "rgb(255, 99, 132)",
              stack: "Stack 0",
            },
            {
              label: "Airtime Purchase",
              data: newArrayUpdate[1]?.dataSets,
              backgroundColor: "rgb(75, 192, 192)",
              stack: "Stack 1",
            },
            {
              label: "Bill Payment",
              data: newArrayUpdate[2]?.dataSets,
              backgroundColor: "rgb(53, 162, 235)",
              stack: "Stack 2",
            },
            {
              label: "Data Purchase",
              data: newArrayUpdate[3]?.dataSets,
              backgroundColor: "rgb(255, 192, 235)",
              stack: "Stack 3",
            },
            {
              label: "Purchase",
              data: newArrayUpdate[4]?.dataSets,
              backgroundColor: "rgb(155, 102, 135)",
              stack: "Stack 3",
            },
          ],
        });
        break;
      case 1:
        const filterCredits = stats?.filter(
          (stat) =>
            stat?.transaction_type_id === 5 &&
            stat?.transaction_year === +selected.name
        );

        setUserData({
          labels: filterCredits?.map((data) => data.transaction_month),
          datasets: [
            {
              labels: "Amount",
              data: filterCredits?.map((data) => data.amount),
              backgroundColor: [
                "rgb(26,163,232)",
                "rgb(0,129,64)",
                "rgb(122,97,186)",
                "rgb(247,119,29)",
              ],
            },
          ],
          datasets: [
            {
              labels: "Amount",
              data: filterCredits?.map((data) => data.amount),
              backgroundColor: [
                "rgb(26,163,232)",
                "rgb(0,129,64)",
                "rgb(122,97,186)",
                "rgb(247,119,29)",
              ],
            },
          ],
        });
        break;
      case 2:
        const filterDebits = stats?.filter(
          (stat) =>
            stat?.transaction_type_id === 1 &&
            stat?.transaction_year === +selected.name
        );

        setUserData({
          labels: filterDebits?.map((data) => data.transaction_month),
          datasets: [
            {
              labels: "Amount",
              data: filterDebits?.map((data) => data.amount),
              backgroundColor: [
                "rgb(26,163,232)",
                "rgb(0,129,64)",
                "rgb(122,97,186)",
                "rgb(247,119,29)",
              ],
            },
          ],
          datasets: [
            {
              labels: "Amount",
              data: filterDebits?.map((data) => data.amount),
              backgroundColor: [
                "rgb(26,163,232)",
                "rgb(0,129,64)",
                "rgb(122,97,186)",
                "rgb(247,119,29)",
              ],
            },
          ],
        });
        break;
      case 3:
        const filterAirtime = stats?.filter(
          (stat) =>
            stat?.transaction_type_id === 3 &&
            stat?.transaction_year === +selected.name
        );

        setUserData({
          labels: filterAirtime?.map((data) => data.transaction_month),
          datasets: [
            {
              labels: "Amount",
              data: filterAirtime?.map((data) => data.amount),
              backgroundColor: [
                "rgb(26,163,232)",
                "rgb(0,129,64)",
                "rgb(122,97,186)",
                "rgb(247,119,29)",
              ],
            },
          ],
          datasets: [
            {
              labels: "Amount",
              data: filterAirtime?.map((data) => data.amount),
              backgroundColor: [
                "rgb(26,163,232)",
                "rgb(0,129,64)",
                "rgb(122,97,186)",
                "rgb(247,119,29)",
              ],
            },
          ],
        });
        break;
      case 4:
        const filterDeposit = stats?.filter(
          (stat) =>
            stat?.transaction_type_id === 2 &&
            stat?.transaction_year === +selected.name
        );

        setUserData({
          labels: filterDeposit?.map((data) => data.transaction_month),
          datasets: [
            {
              labels: "Amount",
              data: filterDeposit?.map((data) => data.amount),
              backgroundColor: [
                "rgb(26,163,232)",
                "rgb(0,129,64)",
                "rgb(122,97,186)",
                "rgb(247,119,29)",
              ],
            },
          ],
          datasets: [
            {
              labels: "Amount",
              data: filterDeposit?.map((data) => data.amount),
              backgroundColor: [
                "rgb(26,163,232)",
                "rgb(0,129,64)",
                "rgb(122,97,186)",
                "rgb(247,119,29)",
              ],
            },
          ],
        });
        break;
      case 5:
        const filterData = stats?.filter(
          (stat) =>
            stat?.transaction_type_id === 4 &&
            stat?.transaction_year === +selected.name
        );

        setUserData({
          labels: filterData?.map((data) => data.transaction_month),
          datasets: [
            {
              labels: "Amount",
              data: filterData?.map((data) => data.amount),
              backgroundColor: [
                "rgb(26,163,232)",
                "rgb(0,129,64)",
                "rgb(122,97,186)",
                "rgb(247,119,29)",
              ],
            },
          ],
          datasets: [
            {
              labels: "Amount",
              data: filterData?.map((data) => data.amount),
              backgroundColor: [
                "rgb(26,163,232)",
                "rgb(0,129,64)",
                "rgb(122,97,186)",
                "rgb(247,119,29)",
              ],
            },
          ],
        });
        break;
      default:
    }
  }, [selectedTab, selected, stats]);

  return (
    <div className="mt-4">
      <div className="flex justify-end">
        <Listbox value={selected} onChange={setSelected}>
          <div className="relative mt-1">
            <div className="flex w-28 items-center justify-between rounded-md border-[1px] border-olivine-500 px-4 py-2 text-base">
              <div className="block truncate text-gray-600">
                {selected.name}
              </div>
              <Listbox.Button className="relative focus:outline-none">
                <div className="cursor-pointer rounded-full">
                  <FiChevronDown
                    className="h-4 w-4 text-olivine-500"
                    aria-hidden="true"
                  />
                </div>
              </Listbox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-50 mt-1 h-36 w-36 overflow-auto rounded-lg bg-white py-1 text-sm shadow-lg shadow-gray-300 focus:outline-none">
                {plans.map((plan, planIdx) => (
                  <Listbox.Option
                    key={planIdx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-1 pl-7 ${
                        active
                          ? "bg-[#F5F5F5] font-semibold text-neutral-500"
                          : "font-semibold text-gray-500"
                      }`
                    }
                    value={plan}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {plan.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-olivine-500">
                            <AiOutlineCheck
                              className="h-3 w-3"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <div className="mt-4 sm:mt-0">
        <Tab.Group
          onChange={(index) => {
            setSelectedTab(index);
          }}
        >
          <Tab.List className="flex flex-wrap items-center space-x-4 text-sm sm:space-x-6 sm:text-base">
            <Tab as={Fragment}>
              {({ selected }) => (
                /* Use the `selected` state to conditionally style the selected tab. */
                <button
                  className={
                    selected
                      ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                      : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                  }
                >
                  <div className="cursor-pointer">
                    <div className="px-1 font-semibold">All</div>
                  </div>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                /* Use the `selected` state to conditionally style the selected tab. */
                <button
                  className={
                    selected
                      ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                      : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                  }
                >
                  <div className="cursor-pointer">
                    <div className="px-1 font-semibold">Orders</div>
                  </div>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                /* Use the `selected` state to conditionally style the selected tab. */
                <button
                  className={
                    selected
                      ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                      : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                  }
                >
                  <div className="cursor-pointer">
                    <div className="px-1 font-semibold">Deposits</div>
                  </div>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                /* Use the `selected` state to conditionally style the selected tab. */
                <button
                  className={
                    selected
                      ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                      : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                  }
                >
                  <div className="cursor-pointer">
                    <div className="px-1 font-semibold">Bills</div>
                  </div>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                /* Use the `selected` state to conditionally style the selected tab. */
                <button
                  className={
                    selected
                      ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                      : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                  }
                >
                  <div className="cursor-pointer">
                    <div className="px-1 font-semibold">Airtime</div>
                  </div>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                /* Use the `selected` state to conditionally style the selected tab. */
                <button
                  className={
                    selected
                      ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                      : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                  }
                >
                  <div className="cursor-pointer">
                    <div className="px-1 font-semibold">Data</div>
                  </div>
                </button>
              )}
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <div className="mt-6 h-[350px] w-full">
                <Bar
                  data={userData}
                  options={{
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                    },
                    layout: {
                      padding: 5,
                    },
                    title: {
                      display: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                      display: true,
                      text: "Monthly Sales",
                      fontSize: 20,
                      fontColor: "#333",
                    },
                    legend: {
                      position: "top",
                      labels: {
                        fontSize: 14,
                        fontColor: "#444",
                      },
                      display: false,
                    },
                    scales: {
                      x: {
                        ticks: {
                          fontColor: "#666",
                        },
                      },
                      y: {
                        ticks: {
                          fontColor: "#666",
                          beginAtZero: true,
                        },
                      },
                    },
                    tooltips: {
                      mode: "index",
                      intersect: false,
                    },
                    hover: {
                      mode: "nearest",
                      animationDuration: 400,
                    },
                    animation: {
                      duration: 1000,
                      easing: "easeInOutQuart",
                    },
                    plugins: {
                      legend: {
                        display: true,
                      },
                    },
                  }}
                  // options={options}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-6 h-[350px] w-full">
                <Bar
                  data={userData}
                  options={{
                    layout: {
                      padding: 5,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                      display: true,
                      text: "Monthly Sales",
                      fontSize: 20,
                      fontColor: "#333",
                    },
                    legend: {
                      position: "top",
                      labels: {
                        fontSize: 14,
                        fontColor: "#444",
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          fontColor: "#666",
                        },
                      },
                      y: {
                        ticks: {
                          fontColor: "#666",
                          beginAtZero: true,
                        },
                      },
                    },
                    tooltips: {
                      mode: "index",
                      intersect: false,
                    },
                    hover: {
                      mode: "nearest",
                      animationDuration: 400,
                    },
                    animation: {
                      duration: 1000,
                      easing: "easeInOutQuart",
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-6 h-[350px] w-full">
                <Bar
                  data={userData}
                  options={{
                    layout: {
                      padding: 5,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                      display: true,
                      text: "Monthly Sales",
                      fontSize: 20,
                      fontColor: "#333",
                    },
                    legend: {
                      position: "top",
                      labels: {
                        fontSize: 14,
                        fontColor: "#444",
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          fontColor: "#666",
                        },
                      },
                      y: {
                        ticks: {
                          fontColor: "#666",
                          beginAtZero: true,
                        },
                      },
                    },
                    tooltips: {
                      mode: "index",
                      intersect: false,
                    },
                    hover: {
                      mode: "nearest",
                      animationDuration: 400,
                    },
                    animation: {
                      duration: 1000,
                      easing: "easeInOutQuart",
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-6 h-[350px] w-full">
                <Bar
                  data={userData}
                  options={{
                    layout: {
                      padding: 5,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                      display: true,
                      text: "Monthly Sales",
                      fontSize: 20,
                      fontColor: "#333",
                    },
                    legend: {
                      position: "top",
                      labels: {
                        fontSize: 14,
                        fontColor: "#444",
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          fontColor: "#666",
                        },
                      },
                      y: {
                        ticks: {
                          fontColor: "#666",
                          beginAtZero: true,
                        },
                      },
                    },
                    tooltips: {
                      mode: "index",
                      intersect: false,
                    },
                    hover: {
                      mode: "nearest",
                      animationDuration: 400,
                    },
                    animation: {
                      duration: 1000,
                      easing: "easeInOutQuart",
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-6 h-[350px] w-full">
                <Bar
                  data={userData}
                  options={{
                    layout: {
                      padding: 5,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                      display: true,
                      text: "Monthly Sales",
                      fontSize: 20,
                      fontColor: "#333",
                    },
                    legend: {
                      position: "top",
                      labels: {
                        fontSize: 14,
                        fontColor: "#444",
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          fontColor: "#666",
                        },
                      },
                      y: {
                        ticks: {
                          fontColor: "#666",
                          beginAtZero: true,
                        },
                      },
                    },
                    tooltips: {
                      mode: "index",
                      intersect: false,
                    },
                    hover: {
                      mode: "nearest",
                      animationDuration: 400,
                    },
                    animation: {
                      duration: 1000,
                      easing: "easeInOutQuart",
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-6 h-[350px] w-full">
                <Bar
                  data={userData}
                  options={{
                    layout: {
                      padding: 5,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                      display: true,
                      text: "Monthly Sales",
                      fontSize: 20,
                      fontColor: "#333",
                    },
                    legend: {
                      position: "top",
                      labels: {
                        fontSize: 14,
                        fontColor: "#444",
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          fontColor: "#666",
                        },
                      },
                      y: {
                        ticks: {
                          fontColor: "#666",
                          beginAtZero: true,
                        },
                      },
                    },
                    tooltips: {
                      mode: "index",
                      intersect: false,
                    },
                    hover: {
                      mode: "nearest",
                      animationDuration: 400,
                    },
                    animation: {
                      duration: 1000,
                      easing: "easeInOutQuart",
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

export default BarChart;
