import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import CancelPlanIcon from "../../../assets/CancelPlanIcon.svg";
import UpgradePlanIcon from "../../../assets/UpgradePlanIcon.svg";
import DowngradePlanIcon from "../../../assets/DowngradePlanIcon.svg";
import UpgradingModal from "./UpgradingModal";
import { useSelector } from "react-redux";

function CancelledPlan({ handleClose, product_id }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const { selectedProductId } = useSelector((state) => state.modal);

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedModal("");
  };

  const handleRedirect = (location) => {
    setShowModal(true);

    switch (location) {
      case "cancelPlan":
        setSelectedModal("cancelPlan");
        break;
      case "reactivatePlan":
        setSelectedModal("reactivatePlan");
        break;
      case "upgradePlan":
        setSelectedModal("upgradePlan");
        break;
      case "downgradePlan":
        setSelectedModal("downgradePlan");
        break;
      default:
        return;
    }
  };

  return (
    <div>
      <div className="flex justify-end px-4 py-4 xsm:px-6">
        <button
          onClick={handleClose}
          className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-300"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="divide-y-[0.5px] divide-gray-200 pb-4">
        {selectedProductId && selectedProductId?.status === "live" ? (
          <div
            onClick={() => handleRedirect("cancelPlan")}
            className="cursor-pointer px-4 hover:bg-gray-100 xsm:px-5"
          >
            <div className="flex items-center space-x-2 pt-2">
              <img src={CancelPlanIcon} alt="icon" className="h-4 w-4" />
              <h3 className="font-semibold text-gray-700">Cancel Plan</h3>
            </div>
            <p className="pb-4 text-sm text-gray-500">
              Cancel the basket plan you are currently paying for. The
              cancellation will take effect on the next billing date of your
              subscription. If you are already paying for a plan for your
              current billing period, you must complete the current plan, and
              the cancellation will commence at the next billing date. You will
              have to create a new plan to continue.
            </p>
          </div>
        ) : selectedProductId && !selectedProductId?.isRecuring ? (
          <div
            onClick={() => handleRedirect("reactivatePlan")}
            className="cursor-pointer px-4 hover:bg-gray-100 xsm:px-5"
          >
            <div className="flex items-center space-x-2 pt-2">
              <img src={CancelPlanIcon} alt="icon" className="h-4 w-4" />
              <h3 className="font-semibold text-gray-700">Reactivate Plan</h3>
            </div>
            <p className="pb-4 text-sm text-gray-500">
              You can reactivate your the basket plan you are currently paying
              for. You will have to create a new plan to continue.
            </p>
          </div>
        ) : null}

        {selectedProductId?.planCode !== "GROPP" ? (
          <div
            onClick={() => handleRedirect("upgradePlan")}
            className="cursor-pointer px-4 hover:bg-gray-100 xsm:px-5"
          >
            <div className="flex items-center space-x-2 pt-2">
              <img src={UpgradePlanIcon} alt="icon" className="h-4 w-4" />
              <h3 className="font-semibold text-gray-700">Upgrade Plan</h3>
            </div>
            <p className="pb-4 text-sm text-gray-500">
              Upgrade the basket plan you are currently paying for to a higher
              basket plan. The upgraded plan will start on the next billing date
              of your subscription. If you are already paying for a plan for
              your current billing period, you must complete the current plan
              and your new plan will commence at the next billing date.
            </p>
          </div>
        ) : (
          <div className="cursor-not-allowed px-4 hover:bg-gray-100 xsm:px-5">
            <div className="flex items-center space-x-2 pt-2">
              <img src={UpgradePlanIcon} alt="icon" className="h-4 w-4" />
              <h3 className="font-semibold text-gray-300">Upgrade Plan</h3>
            </div>
            <p className="pb-4 text-sm text-gray-300">
              You can cancel your the basket plan you are currently paying for.
              You will have to create a new plan to continue.
            </p>
          </div>
        )}

        {selectedProductId?.planCode !== "GROBP" ? (
          <div
            onClick={() => handleRedirect("downgradePlan")}
            className="cursor-pointer px-4 hover:bg-gray-100 xsm:px-5"
          >
            <div className="flex items-center space-x-2 pt-2">
              <img src={DowngradePlanIcon} alt="icon" className="h-4 w-4" />
              <h3 className="font-semibold text-gray-700">Downgrade Plan</h3>
            </div>
            <p className="pb-4 text-sm text-gray-500">
              Downgrade the basket plan you are currently paying for to a lower
              basket plan. The downgraded plan will start on the next billing
              date of your subscription. If you are already paying for a plan
              for your current billing period, you must complete the current
              plan, and your new plan will commence at the next billing date.
            </p>
          </div>
        ) : (
          <div className="cursor-not-allowed px-4 hover:bg-gray-100 xsm:px-5">
            <div className="flex items-center space-x-2 pt-2">
              <img src={DowngradePlanIcon} alt="icon" className="h-4 w-4" />
              <h3 className="font-semibold text-gray-300">Downgrade Plan</h3>
            </div>
            <p className="pb-4 text-sm text-gray-300">
              You can cancel your the basket plan you are currently paying for.
              You will have to create a new plan to continue.
            </p>
          </div>
        )}
      </div>
      <UpgradingModal
        visible={showModal}
        onClose={handleOnClose}
        selected={selectedModal}
        handleClose={handleClose}
        product_id={product_id}
      />
    </div>
  );
}

export default CancelledPlan;
