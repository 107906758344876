import React, { useState, useEffect, useContext } from "react";
import Logo from "../assets/logo.svg";
import { MdEmail } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { authService } from "../services/authService";
import LogicContext from "../context/LogicContext";
import CircularProgress from "@mui/material/CircularProgress";

let tempUserProps = {};

function VerifyEmail() {
  const logicContext = useContext(LogicContext);
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [allowPasswordChange, setAllowPasswordChange] = useState(false);
  const [otpInput, setOtpInput] = useState(new Array(6).fill(""));

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const submitEmailToGetToken = async () => {
    const formData = getValues();
    if (!formData?.user_email_address) return toast.error("Email is required!");

    setIsLoading(true);
    try {
      const payload = {
        sql_ref_no: "6",
        user_email_address: formData?.user_email_address,
        option: "2",
      };
      authService
        .forgotPassword(payload)
        .then((apiResponse) => {
          setIsLoading(false);

          if (apiResponse?.data?.result_status[0]?.message_id != 1) {
            setAllowPasswordChange(false);
            toast.error(apiResponse.data?.result_status[0]?.message_text);
          }

          if (apiResponse?.data?.result_status[0]?.message_id === 1) {
            tempUserProps = apiResponse.data?.user_information[0];
            setAllowPasswordChange(true);
            toast.success(`Verification Code has been sent to your Email.`);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } catch (err) {
      return err;
    }
  };

  function onErrors(error) {
    toast.error("Email is required");
  }

  const handleOtpInputChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtpInput([
      ...otpInput.map((d, idx) => (idx === index ? element.value : d)),
    ]);

    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handlePaste = (event) => {
    const pasted = event.clipboardData.getData("text/plain");
    setOtpInput(pasted.split("").slice(0, otpInput.length));
  };

  const confirmOtpManager = async () => {
    try {
      let payload = {
        sql_ref_no: "7",
        user_id: tempUserProps?.user_id,
        verification_code: otpInput.join(""),
        option: "1",
      };
      setIsLoading(true);
      const response = await authService.emailVerification(payload);
      setIsLoading(false);
      const filteredData = logicContext.httpResponseManager(response?.data);
      if (filteredData?.message_id === 1) {
        authService.setToken(filteredData?.access_token);
        navigate("/login");
      }
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="h-screen w-screen px-2 py-4 xsm:px-4 sm:px-8">
      <div className="">
        <Link to="/">
          <img
            src={Logo}
            className="aspect-auto h-7 w-8 cursor-pointer object-cover transition duration-300 hover:opacity-80 sm:h-11 sm:w-12"
            alt=""
          />
        </Link>
      </div>
      <div className="h-screen w-screen px-2 py-4 xsm:px-4 sm:px-8">
        <div className="mt-8 flex items-center justify-center">
          <div className="w-full max-w-md rounded-xl bg-[#F6F5F5] px-3 py-6 ring-[1px] ring-gray-200 xsm:px-6 sm:px-8">
            <div>
              <div className="text-xl font-bold text-gray-600">
                Verify Email
              </div>
              <div className="mt-3 text-xs text-gray-500 sm:text-sm">
                Send a verification code to your registered email.
              </div>
            </div>
            <div className="mt-6">
              {/* Submit email form */}
              {allowPasswordChange === false ? (
                <form onSubmit={handleSubmit(submitEmailToGetToken, onErrors)}>
                  <div>
                    <label htmlFor="email" className="text-xs text-gray-500">
                      Email Address
                    </label>
                    <div className="relative rounded-md">
                      <div className="inset-y-0 pl-3">
                        <MdEmail className="absolute mt-3 h-5 w-5 text-gray-600" />
                      </div>
                      <input
                        type="email"
                        placeholder="Enter your email address"
                        id="email"
                        {...register("user_email_address", {
                          required: "Email is required!",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email address",
                          },
                        })}
                        className="mb-4 block w-full rounded-md border-gray-400 bg-white pl-10 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      <small className="text-red-600">
                        {errors?.user_email_address?.message}
                      </small>
                    </div>
                  </div>
                  <button
                    disabled={isLoading}
                    className="mt-8 w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600"
                  >
                    {isLoading === true ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "SEND CODE"
                    )}
                  </button>
                </form>
              ) : (
                // Reset Password Form
                <>
                  <form onSubmit={handleSubmit}>
                    <div className="relative mt-8 flex justify-center space-x-2 rounded-md">
                      {otpInput &&
                        otpInput.length &&
                        otpInput?.map((data, index) => {
                          return (
                            <input
                              type="text"
                              maxLength={1}
                              className="mb-4 block h-8 w-8 items-center justify-center rounded-md bg-white text-center text-[12px] focus:border-olivine-500 focus:ring-olivine-500 sm:h-10 sm:w-10 sm:text-lg"
                              name="opt"
                              key={index}
                              value={data}
                              onChange={(e) =>
                                handleOtpInputChange(e.target, index)
                              }
                              onPaste={handlePaste}
                              onFocus={(e) => e.target.select()}
                            />
                          );
                        })}
                    </div>
                    {!allowPasswordChange && (
                      <button className="mt-8 w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600">
                        {isLoading === true ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "VERIFY EMAIL ADDRESS "
                        )}
                      </button>
                    )}
                  </form>

                  {allowPasswordChange && (
                    <button
                      onClick={() => confirmOtpManager()}
                      className="mt-8 w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600"
                    >
                      {isLoading === true ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Confirm OTP "
                      )}
                    </button>
                  )}
                </>
              )}
            </div>

            <div className="mt-6 text-sm">
              <div className=" text-gray-500">
                Didn’t receive a code?
                <span
                  onClick={() => submitEmailToGetToken()}
                  className="cursor-pointer px-3 font-semibold text-olivine-500 transition-all duration-300 hover:text-olivine-600"
                >
                  RESEND CODE
                </span>
              </div>
              <div className="mt-4 font-semibold text-olivine-500 transition-all duration-300 hover:text-olivine-600">
                <Link to="/login">LOG IN</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
