import baseAxios from "../core/api/axios/baseAxios";

class VoucherService {
  static async createVoucher(payload) {
    const res = await baseAxios.post("voucher", payload);

    return res?.data || res;
  }

  static async createVoucherBulk(payload) {
    const res = await baseAxios.post("voucher/bulk", payload);

    return res?.data || res;
  }

  static async assignVoucherBulk(ref, payload) {
    const res = await baseAxios.post(`voucher/bulk/assign/${ref}`, payload);

    return res?.data || res;
  }

  static async getBulk(ref, payload) {
    const res = await baseAxios.post(`voucher/bulk/${ref}`, payload);

    return res?.data || res;
  }

  static async assignVoucherBulkImports(payload) {
    const res = await baseAxios.post("voucher/assign/bulk/imports", payload);

    return res?.data || res;
  }

  static async redeemVoucher(payload) {
    const res = await baseAxios.post("voucher/redeem", payload);

    return res?.data || res;
  }

  static async historyVoucher(payload) {
    const res = await baseAxios.post("voucher/history", payload);

    return res?.data || res;
  }

  static async shareVoucher(ref) {
    const res = await baseAxios.get(`voucher/share_voucher/${ref}`);

    return res?.data || res;
  }
}

export default VoucherService;
