import React from "react";
import Basket from "../assets/basket-bxs.svg";
import Star from "../assets/star.svg";
import { BsFillPersonFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Testimonial from "./pages/testimonials/Testimonial";
import GooglePlayImage from "../assets/googlePlayImage.svg";
import AppStoreImage from "../assets/appstoreImage.svg";

function CheckOutOtherProduct() {
  const navigate = useNavigate();
  const selected_plan = localStorage.getItem("selected_plan");

  const handleContinue = () => {
    navigate(`/home/plans/${selected_plan}`);
  };

  return (
    <div className="mx-auto mt-28 max-w-[1600px] px-3 xsm:px-4 sm:px-8 md:px-10 lg:px-16">
      <div>
        <div className="text-center text-2xl font-bold text-gravel-500">
          What else are we cooking?
        </div>
      </div>
      <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 ">
        <div className="grid max-w-2xl grid-cols-3 rounded-lg bg-white px-4 py-8 sm:px-8">
          <img src={Basket} className="col-span-3 h-14 w-14 " alt="" />
          <div className="col-span-3 mt-4 text-2xl font-extrabold text-gray-600">
            GroceRe
          </div>
          <p className="col-span-3 mt-3 pb-10 text-sm font-semibold text-gray-600">
            Grecere is a cross border solution from Grocedy that allows
            Nigerians in diaspora buy and send food and cash gift back home{" "}
          </p>
          {/* <button className="col-span-2 mt-8 rounded-md bg-olivine-500 px-6 py-3 text-center font-semibold text-white transition-all duration-300 hover:bg-olivine-600 sm:px-8">
            Coming Soon
          </button> */}
        </div>
        <div className="grid max-w-2xl grid-cols-3 rounded-lg bg-caper-500 px-4 py-8 sm:px-8">
          <img src={Star} className="col-span-3 h-14 w-14" alt="" />
          <div className="col-span-3 mt-4 text-2xl font-extrabold text-gravel-600">
            Grocedy Premium
          </div>
          <p className="col-span-3 mt-3 pb-10 text-sm font-semibold text-gray-600">
            Grocedy Premium is a plan that allows customers to buy food items at
            a particular price for the long term.
          </p>
          {/* <button className="col-span-2 mt-8 rounded-md bg-olivine-500 px-6 py-3 text-center font-semibold text-white transition-all duration-300 hover:bg-olivine-600 sm:px-8">
            Coming Soon
          </button> */}
        </div>
      </div>
      <div className="mt-20 grid justify-items-center">
        <div className="text-xl font-bold text-gravel-600 sm:text-2xl">
          What are you waiting for?
        </div>
        <div className="mt-1 text-center text-sm font-bold text-gravel-400">
          Put your loved ones on a care plan so you don’t have to worry about
          them again.
        </div>
        <button
          onClick={() => handleContinue()}
          className="mt-8 transform cursor-pointer rounded-md bg-olivine-500 px-16 py-3 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 xsm:text-sm"
        >
          Get Started
        </button>
      </div>
      <div>
        <Testimonial />
      </div>
      <div className="mx-auto mt-32 max-w-5xl rounded-xl bg-[#558123] px-4 py-10 sm:px-6">
        <h2 className="mx-auto max-w-2xl text-center text-4xl font-bold text-white">
          Seamless Shopping at Your Fingertips: Install the Grocedy App Now!
        </h2>
        <div className="mt-8 flex items-center justify-center space-x-6">
          <a href="https://download.grocedy.com/u/12811202 ">
            <div className="cursor-pointer">
              <img src={GooglePlayImage} alt="image" className="h-12 w-auto" />
            </div>
          </a>
          <a href="https://download.grocedy.com/u/12811202 ">
            <div className="cursor-pointer">
              <img src={AppStoreImage} alt="image" className="h-12 w-auto" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default CheckOutOtherProduct;
