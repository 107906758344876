import { createSlice } from "@reduxjs/toolkit";
import {
  billerEnquiry,
  billerGroup,
  billerGroupEnquiry,
  buyAirtime,
  buyData,
  convenienceFees,
  customerEnquire,
  delgroAirtimePurchase,
  delgroDataPurchase,
  delgroPaybillsPurchase,
  payBills,
} from "../actions";

const initialState = {
  airtime: "",
  biller: "",
  billerEq: "",
  billerGrpEnq: "",
  data: "",
  billPay: "",
  customerEnquiry: "",
  fees: "",
  delgroAirtime: "",
  delgroData: "",
  delgroPaybills: "",
};

const VasSlice = createSlice({
  name: "VasData",
  initialState,
  reducers: {
    resetVas(state) {
      state.airtime = undefined;
      state.biller = undefined;
      state.billerEq = undefined;
      state.data = undefined;
      state.billerGrpEnq = undefined;
      state.billPay = undefined;
      state.customerEnquiry = undefined;
      state.fees = null;
      state.delgroAirtime = null;
      state.delgroData = null;
      state.delgroPaybills = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(buyAirtime.fulfilled, (state, { payload }) => {
      state.airtime = payload;
    });

    builder.addCase(buyData.fulfilled, (state, { payload }) => {
      state.data = payload;
    });

    builder.addCase(payBills.fulfilled, (state, { payload }) => {
      state.billPay = payload;
    });

    builder.addCase(billerGroup.fulfilled, (state, { payload }) => {
      state.biller = payload;
    });

    builder.addCase(billerGroupEnquiry.fulfilled, (state, { payload }) => {
      state.billerGrpEnq = payload;
    });

    builder.addCase(billerEnquiry.fulfilled, (state, { payload }) => {
      state.billerEq = payload;
    });

    builder.addCase(convenienceFees.fulfilled, (state, { payload }) => {
      state.fees = payload;
    });

    builder.addCase(customerEnquire.fulfilled, (state, { payload }) => {
      state.customerEnquiry = payload;
    });

    builder.addCase(delgroAirtimePurchase.fulfilled, (state, { payload }) => {
      state.delgroAirtime = payload;
    });

    builder.addCase(delgroDataPurchase.fulfilled, (state, { payload }) => {
      state.delgroData = payload;
    });

    builder.addCase(delgroPaybillsPurchase.fulfilled, (state, { payload }) => {
      state.delgroPaybills = payload;
    });
  },
});

const { reducer, actions } = VasSlice;

export const { resetVas } = actions;

export default reducer;
