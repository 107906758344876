import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../../services/authService";
import { getUserProfile } from "../../../store/actions";
import { setGiftProfileDetails } from "../../../store/slices/modalSlice";
import { profileDescriptionValidator } from "../../../core/helpers";
import { toast } from "react-hot-toast";

const BasketRecipePopover = ({ closer, handleRedirect, totalDeliveryFee }) => {
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => state.masterData);
  const { giftProfileDetails } = useSelector((state) => state.modal);

  const [fname, setFname] = useState(
    (giftProfileDetails && giftProfileDetails?.fname) || ""
  );
  const [lname, setLname] = useState(
    (giftProfileDetails && giftProfileDetails?.lname) || ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    (giftProfileDetails && giftProfileDetails?.phoneNumber) || ""
  );
  const [fetchedUserProfile, setFectcheUserProfile] = useState("");
  const [email, setEmail] = useState(
    (giftProfileDetails && giftProfileDetails?.email) || ""
  );
  const [valid, setValid] = useState(false);
  const [title, setTitle] = useState(
    (giftProfileDetails && giftProfileDetails?.title) || ""
  );
  const [titleName, setTitleName] = useState("");

  const [errorMsg, setErrorMsg] = useState({
    fname: "",
    lname: "",
    phoneNumber: "",
    email: "",
  });

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (fname && lname && phoneNumber && email) setValid(true);
  }, [fname, lname, phoneNumber, email]);

  useEffect(() => {
    if (!userProfile) {
      const data = {
        sql_ref_no: "12",
        user_id: userId?.toString(),
        option: "21",
      };

      dispatch(getUserProfile(data));
    }

    if (userProfile) setFectcheUserProfile(userProfile);
  }, [dispatch, userProfile, verification_code, userId, customerId]);

  const handleChangeEvent = (e) => {
    setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""));
  };

  const handleChangeTitle = (event) => {
    setTitle(event.target.value.split("-")[0]);
    setTitleName(event.target.value.split("-")[1]);
  };

  const handleOnClose = (e) => {
    e.preventDefault();
    closer();
  };

  const payloadValuesError = profileDescriptionValidator(
    fname,
    lname,
    phoneNumber,
    email
  );

  const handleSave = (e) => {
    try {
      e.preventDefault();

      if (Object.keys(payloadValuesError).length) {
        toast.error("Please Fill required fields ...");
        return setErrorMsg({
          ...errorMsg,
          fname: payloadValuesError.fname,
          lname: payloadValuesError.lname,
          phoneNumber: payloadValuesError.phoneNumber,
          email: payloadValuesError.email,
        });
      }
      closer();

      const profileData = {
        fname,
        lname,
        email,
        phoneNumber,
        title,
        titleName,
        totalDeliveryFee,
      };

      dispatch(setGiftProfileDetails(profileData));
      handleRedirect("profileSaved");
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="px-[2px]">
      <div className="w-full max-w-md rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
        <div className="flex items-center justify-between">
          <div className="font-semibold text-gray-600 sm:text-xl">
            Recipient’s Details (1/3)
          </div>
          <button className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
            <CgClose
              onClick={handleOnClose}
              className="h-5 w-5 text-gravel-500"
            />
          </button>
        </div>
        <div className="mt-1 flex justify-start text-[11px] xsm:text-xs">
          Please enter your gift recipient’s details
        </div>
        <div>
          <form>
            <div className="mt-3 flex items-start space-x-2">
              <div className="flex-1">
                <label
                  htmlFor="landmark"
                  className="mb-[2px] ml-2 flex justify-start text-xs font-medium text-gray-600"
                >
                  First Name <span className="text-rose-600"> * </span>
                </label>
                <div className="relative flex items-center justify-end rounded-md">
                  <div className="flex-grow">
                    <input
                      onChange={(e) => {
                        const limit = 50;

                        // 👇️ only take first N characters
                        setFname(e.target.value.slice(0, limit));
                      }}
                      value={fname}
                      type="text"
                      placeholder="First name"
                      className="block w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    />
                    {errorMsg?.fname ? (
                      <span className="ml-3 text-xs text-red-500">
                        {errorMsg?.fname}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <label
                  htmlFor="zipCode"
                  className="mb-[2px] ml-2 flex justify-start text-xs font-medium text-gray-600"
                >
                  Last Name <span className="text-rose-600"> * </span>
                </label>
                <div className="relative flex items-center justify-end rounded-md">
                  <div className="flex-grow">
                    <input
                      onChange={(e) => {
                        const limit = 50;

                        // 👇️ only take first N characters
                        setLname(e.target.value.slice(0, limit));
                      }}
                      value={lname}
                      type="text"
                      placeholder="Last name"
                      className="block w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    />
                    {errorMsg?.lname ? (
                      <span className="ml-3 text-xs text-red-500">
                        {errorMsg?.lname}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label
                htmlFor="addressLine1"
                className="mb-[2px] ml-2 flex justify-start text-xs font-medium text-gray-600"
              >
                Title
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <select
                    onChange={handleChangeTitle}
                    type="text"
                    className="block w-full cursor-pointer rounded-md border-gray-300 bg-gray-50 pl-4 pr-10 text-sm text-gray-600 shadow-sm focus:border-olivine-500 focus:ring-olivine-500"
                  >
                    {giftProfileDetails && giftProfileDetails?.titleName ? (
                      <option>{giftProfileDetails?.titleName}</option>
                    ) : (
                      <option>select</option>
                    )}
                    {fetchedUserProfile &&
                      fetchedUserProfile?.Salutation?.slice(0).map((salute) => (
                        <option
                          key={salute.salutation_id}
                          value={`${salute.salutation_id}-${salute.salutation_name}`}
                        >
                          {salute.salutation_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label
                htmlFor="addressLine1"
                className="mb-[2px] ml-2 flex justify-start text-xs font-medium text-gray-600"
              >
                Email Address <span className="text-rose-600"> * </span>
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    onChange={(e) => {
                      const limit = 50;

                      // 👇️ only take first N characters
                      setEmail(e.target.value.slice(0, limit));
                    }}
                    value={email}
                    type="email"
                    placeholder="Enter recipient’s email address"
                    className="block w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                  {errorMsg?.email ? (
                    <span className="ml-3 text-xs text-red-500">
                      {errorMsg?.email}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label
                htmlFor="phoneNumber"
                className="mb-[2px] ml-2 flex justify-start text-xs font-medium text-gray-600"
              >
                Phone Number <span className="text-rose-600"> * </span>
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    // onChange={(e) => {
                    //   const limit = 15;

                    //   // 👇️ only take first N characters
                    //   setPhoneNumber(e.target.value.slice(0, limit));
                    // }}

                    onChange={handleChangeEvent}
                    value={phoneNumber}
                    type="text"
                    placeholder="Enter Phone Number"
                    className="block w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-sm  text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                  {errorMsg?.phoneNumber ? (
                    <span className="ml-3 text-xs text-red-500">
                      {errorMsg?.phoneNumber}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
          <div className="mt-5 flex justify-end pb-4 text-center tracking-widest">
            <button
              onClick={handleSave}
              // disabled={!valid}
              className="rounded-md bg-olivine-500 px-8 py-2.5 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-400 active:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasketRecipePopover;
