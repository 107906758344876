import React from "react";

function PriceUpdate() {
  return (
    <div className="w-full bg-gray-200 px-4 pt-10 pb-40">
      <div className="mx-auto w-full max-w-[700px] space-y-4 bg-white px-6 py-10 text-sm font-medium xsm:px-10 xsm:py-14 sm:px-16 sm:py-20">
        <p>
          Dear valued customers, we want to express our sincere gratitude for
          your loyalty and support that has contributed to making Grocedy a
          go-to platform for affordable food products at wholesale prices.
        </p>
        <p>
          As we all know, there has been a significant increase in food prices
          (over 25% in the last 12 months) and we understand the impact this has
          on your pockets. However, since September 2022, we have absorbed this
          cost to ease the burden on our customers and we appreciate your
          understanding during this time.
        </p>
        <p>
          Unfortunately, sustaining this subsidy has become increasingly
          difficult for us with the rising cost of production. Therefore, we
          have no choice but to review our prices. However, we want to assure
          you that we have set new prices that still give you value for your
          money and cost savings.
        </p>
        <p>
          We kindly request your continued understanding and patronage as we
          launch our new prices on the 22nd of June 2023. We remain committed to
          providing affordable food products with the quality you
          deserve. Thank you.
        </p>
        <div>
          <h3>New Price list:</h3>
          <ol className="mt-3 list-inside list-disc space-y-2">
            <li>Bronze Basket - ₦12,000.00</li>
            <li>Silver Basket - ₦22,500.00</li>
            <li>Gold Basket - ₦33,000.00</li>
            <li>Diamond Basket - ₦56,000.00</li>
            <li>Platinum Basket - ₦82,000.00</li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default PriceUpdate;
