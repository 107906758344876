import React, { useContext, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { BsExclamationLg } from "react-icons/bs";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import "../create_basket_page/SelectedCard.css";
import { useNavigate, useParams } from "react-router-dom";
import BasketService from "../../../services/basketService";
import CurrencyFormat from "react-currency-format";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import toast from "react-hot-toast";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import Footer from "../../Footer";
import { RadioGroup } from "@headlessui/react";
import RecommendationsPage from "../../recommendations/RecommendationsPage";
import { useDispatch } from "react-redux";
import { authService } from "../../../services/authService";
import { fetchRecommendations } from "../../../store/actions";

function SelectedBasketPage() {
  const { product_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rxjsStore = useContext(RxjsStoreContext);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [fetchedRecommendations, setFetchedRecommendations] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [savingType, setSavingsType] = useState("percent");
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (selectedProduct) {
      handleRecommendations(selectedProduct);
    }
  }, [selectedProduct]);

  useEffect(() => {
    BasketService.getSingleBasketItem(product_id).then((data, failure) => {
      if (!data?.item_id) {
        return navigate(-1);
      }
      setSelectedProduct(data);
    });

    return () => {
      rxjsStore.saveBasketTemporarily();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id]);

  const addToBasket = (product) => {
    if (product.item_stock_out === true) {
      return toast.error("Item is out of stock!");
    }

    if (quantity > 0) {
      rxjsStore.setUserSelectedBasket({ ...product, type: "BASKET" }, quantity);
      toast.success(
        `${quantity} qty of ${product?.name} has been added to basket!`
      );
    } else {
      toast.error(
        `${quantity} qty of ${product?.name} cannot be added to basket!`
      );
    }
  };

  const incrementQty = (quantity) => {
    setQuantity((prev) => {
      return (prev += quantity);
    });
  };

  const decrementQty = (quantity) => {
    setQuantity((prev) => {
      if (prev > 1) {
        return (prev -= quantity);
      } else {
        return 0;
      }
    });
  };

  const yourSavings = (grocedyPrice, marketPrice) => {
    if (savingType === "percent") {
      let diff = marketPrice - grocedyPrice;
      let saving = (diff / marketPrice) * 100;
      return Math.ceil(saving);
    } else if (savingType === "naira") {
      let saving = marketPrice - grocedyPrice;
      return Math.ceil(saving);
    }
  };

  const removeSpacesAndNewlines = (str) => {
    const cleanedXmlString = str.replace(/\n/g, "").replace(/\s/g, "");

    return cleanedXmlString;
  };

  const handleRecommendations = (item) => {
    try {
      if (item) {
        const xmlStructure = `<root><a><item_id>${item?.item_id}</item_id></a></root>`;
        const removedString = removeSpacesAndNewlines(xmlStructure);

        const data = {
          xml_string_item: removedString,
          item_type_id: "1",
        };

        const payload = {
          data,
          customer_id: customerId,
        };

        dispatch(fetchRecommendations(payload))
          .unwrap()
          .then((res) => {
            if (res?.items?.length) {
              setFetchedRecommendations(res?.items);
            }
          })
          .catch((err) => err);
      }
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      <SignedInNavbarImproved />
      <div className="mt-6 px-4 sm:px-8 md:px-16">
        <div
          onClick={() => navigate(-1)}
          className="flex items-center space-x-3"
        >
          <div className="cursor-pointer rounded-full p-1 hover:bg-gray-200">
            <BiArrowBack className="h-6 w-6 text-gray-600" />
          </div>
          <div className="font-bold text-gray-600">Selected Item</div>
        </div>
        <section className="mt-4 ">
          <div className="h-auto max-w-xs overflow-hidden md:max-w-md">
            <div className="w-full">
              <img
                src={selectedProduct?.item_image_url}
                className="aspect-auto h-auto w-auto object-cover"
                alt="product"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="mt-6 text-sm font-bold text-gray-600 sm:text-base">
            {selectedProduct?.name} / sku:{selectedProduct?.sku}
          </div>
          <p className="mt-4 text-xs font-semibold text-gray-500">
            Quantity and price are subject to current market rates and may
            change
          </p>
          <div className=" ">
            <div className="mt-3 flex items-center space-x-4">
              <div className="rounded bg-olivine-600 px-6 py-2 text-sm font-semibold text-white">
                KG
              </div>
              <div className="rounded bg-[#E0E0E0] px-4 py-2 text-sm font-semibold text-gray-500">
                BUCKET
              </div>
            </div>
            <div className="">
              <RadioGroup
                value={savingType}
                onChange={setSavingsType}
                className=""
              >
                <div className="mt-8 flex items-center text-sm font-semibold">
                  <RadioGroup.Option value="naira" className="">
                    {({ checked }) => (
                      <div
                        className={
                          checked
                            ? "cursor-pointer rounded-l-lg bg-olivine-500 px-4 py-2 text-white"
                            : "cursor-pointer rounded-l-lg bg-[#CDE0A9] px-4 py-2 text-gray-500"
                        }
                      >
                        NGN
                      </div>
                    )}
                  </RadioGroup.Option>
                  <RadioGroup.Option value="percent" className="">
                    {({ checked }) => (
                      <div
                        className={
                          checked
                            ? "cursor-pointer rounded-r-lg bg-olivine-500 px-6 py-2 text-white"
                            : "cursor-pointer rounded-r-lg bg-[#CDE0A9] px-6 py-2 text-gray-500"
                        }
                      >
                        %
                      </div>
                    )}
                  </RadioGroup.Option>
                </div>
              </RadioGroup>
            </div>
            <div className="mt-6">
              <div className="text-sm font-semibold text-gray-600">
                Grocedy Price:{" "}
                <span className="">
                  <CurrencyFormat
                    className={"font-bold "}
                    value={selectedProduct?.item_rate_zoho}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </span>
              </div>
            </div>
            <div className="mt-2">
              <div className="mt-1 text-sm font-semibold text-gray-600">
                Market Price :{" "}
                <span className="">
                  <CurrencyFormat
                    className={"font-semibold "}
                    value={selectedProduct?.item_rate_market}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </span>
              </div>
              <div className="mt-2 flex items-center space-x-2 text-sm font-semibold text-olivine-600">
                <div>Save:</div>
                {savingType === "percent" ? (
                  <div>
                    {" "}
                    {yourSavings(
                      selectedProduct?.item_rate_zoho,
                      selectedProduct?.item_rate_market
                    )}{" "}
                    %
                  </div>
                ) : (
                  ""
                )}
                {savingType === "naira" ? (
                  <div>
                    <CurrencyFormat
                      className={""}
                      value={yourSavings(
                        selectedProduct?.item_rate_zoho,
                        selectedProduct?.item_rate_market
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mt-4 flex items-center space-x-2">
              <div>
                <BsExclamationLg className="h-3.5 w-3.5 rounded-full bg-olivine-500 p-[2.5px] text-white" />
              </div>
              <div className="text-xs font-semibold text-gray-500">
                Learn more on how arrived at this index
              </div>
            </div>
            <div className="mt-3">
              <div className=" text-sm font-semibold text-gray-500 sm:text-base">
                Select Quantity
              </div>
            </div>
            <div className="mt-1 flex justify-start">
              <div className="flex items-center space-x-8 rounded-md border-[0.5px] border-gray-400 bg-gray-50 px-2 py-1 font-semibold text-gray-500 shadow-sm">
                <div className="cursor-pointer rounded-full p-1 hover:bg-gray-200">
                  <AiOutlineMinus
                    onClick={() => decrementQty(1)}
                    className="h-5 w-5"
                  />
                </div>
                <div className="w-8 text-center font-bold text-gray-700">
                  {quantity}
                </div>
                <div className="cursor-pointer rounded-full p-1 hover:bg-gray-200">
                  <AiOutlinePlus
                    onClick={() => incrementQty(1)}
                    className="h-5 w-5"
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 sm:w-64">
              <button
                className="w-full rounded-md border-[1px] border-olivine-500 py-3 text-sm font-semibold text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-500"
                onClick={() => addToBasket(selectedProduct)}
              >
                ADD TO BASKET
              </button>
            </div>
          </div>
        </section>
      </div>
      <div className="mt-6">
        {fetchedRecommendations && fetchedRecommendations?.length && (
          <RecommendationsPage items={fetchedRecommendations} />
        )}
      </div>
      <Footer />
    </>
  );
}

export default SelectedBasketPage;
