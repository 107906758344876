import React, { useEffect, useState } from "react";
import GoogleMap from "./GoogleMap";
import Tracking from "./Tracking";

function TrackPageLarge({ mapData, defaultTracking }) {
  const [orderData, setOrderData] = useState(defaultTracking?.order_info);
  const [viewTimeline, setViewTimeline] = useState(true);

  useEffect(() => {
    if (!mapData && defaultTracking) {
      setOrderData(defaultTracking?.order_info);
    }
  }, [mapData, defaultTracking]);

  useEffect(() => {
    if (mapData) {
      setOrderData(mapData?.order_info);
    }
  }, [mapData]);

  return (
    <div className="overflow-hidden">
      <div className="z-50 h-[45px] w-full bg-white lg:bg-olivine-500">
        <div
          className={
            "absolute right-4 top-[70px] z-50 cursor-pointer text-sm font-semibold text-gray-500 transition duration-300 hover:underline hover:underline-offset-2 lg:top-3 lg:text-white " +
            (viewTimeline ? "hidden" : "inline")
          }
          onClick={() => setViewTimeline(!viewTimeline)}
        >
          Show Timeline
        </div>
      </div>
      <div className="absolute top-0 z-50 h-[20px] bg-white lg:bg-olivine-500" />
      <div className="relative">
        <div>
          <div className="relative">
            <GoogleMap link={orderData?.pickup?.tracking_link} />
            <div className="absolute left-0 right-0 top-0 h-[45px] bg-white" />
          </div>
        </div>
        <div className="absolute inset-0 right-0 z-10 h-20 px-4 pt-20 sm:px-6">
          <Tracking
            track={orderData}
            viewTimeline={viewTimeline}
            setViewTimeline={setViewTimeline}
          />
        </div>
      </div>
    </div>
  );
}

export default TrackPageLarge;
