import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { MdDateRange } from "react-icons/md";
import SignedInNavbarImproved from "./SignedInNavbarImproved";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getTransactions } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../services/authService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { setTransaction } from "../store/slices/modalSlice";
import { CircularProgress } from "@mui/material";

function TransactionHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [fetchedTransactions, setFetchedTransactions] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoadingTransact, setIsLoadingTransact] = useState(false);

  const { transactions } = useSelector((state) => state.payment);
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (!transactions) {
      setIsLoadingTransact(true);
      const id = customerId?.toString();
      dispatch(getTransactions(id))
        .unwrap()
        .then(() => setIsLoadingTransact(false))
        .catch(() => setIsLoadingTransact(false));
    }
  }, [transactions, dispatch, customerId]);

  useEffect(() => {
    if (transactions?.transation_history?.length) {
      setFetchedTransactions(transactions?.transation_history);
    }
  }, [transactions]);

  useEffect(() => {
    try {
      switch (selectedIndex) {
        case 0:
          setFetchedTransactions(transactions?.transation_history);
          break;
        case 1:
          const walletDeposit = transactions?.transation_history?.filter(
            (actiPlans) => actiPlans?.transaction_type_id === 1
          );

          setFetchedTransactions(walletDeposit);
          break;
        case 2:
          const debit = transactions?.transation_history?.filter(
            (actiPlans) => actiPlans?.transaction_group === "Debit"
          );

          setFetchedTransactions(debit);
          break;
        case 3:
          const subscriptions = transactions?.transation_history?.filter(
            (actiPlans) => actiPlans?.transaction_type_id === 5
          );

          setFetchedTransactions(subscriptions);
          break;
        case 4:
          const bills = transactions?.transation_history?.filter(
            (actiPlans) => actiPlans?.transaction_type_id === 3
          );

          setFetchedTransactions(bills);
          break;
        case 5:
          const airtime = transactions?.transation_history?.filter(
            (actiPlans) => actiPlans?.transaction_type_id === 2
          );

          setFetchedTransactions(airtime);
          break;
        default:
          return setFetchedTransactions(transactions?.transation_history);
      }
    } catch (err) {
      return err;
    }
  }, [selectedIndex, transactions]);

  function searchByDate() {
    let transaList = [];
    transactions?.transation_history?.forEach((ft) => {
      if (
        new Date(ft?.transaction_date) >= startDate &&
        new Date(ft?.transaction_date) <= endDate
      ) {
        transaList.push(ft);
      }
    });

    setFetchedTransactions(transaList);
  }

  // function searchByDate() {
  //   let transaList = [];
  //   transactions?.transation_history?.forEach((ft) => {

  //     if (
  //       moment(ft?.transaction_date).format("ddd DD-MM") >=
  //         moment(startDate).format("ddd DD-MM") &&
  //       moment(ft?.transaction_date).format("ddd DD-MM") <=
  //         moment(endDate).format("ddd DD-MM")
  //     ) {

  //       transaList.push(ft);
  //     }
  //   });

  //   setFetchedTransactions(transaList);
  // }

  return (
    <div>
      <SignedInNavbarImproved />
      <div className="px-4 pb-96 pt-8 sm:px-8 md:px-10 lg:px-16">
        <div className="mt-4 flex items-center space-x-2 sm:space-x-4">
          <div className="rounded-full p-1 hover:bg-gray-200">
            <BiArrowBack
              onClick={() => navigate(-1)}
              className="h-6 w-6 cursor-pointer text-gray-600"
            />
          </div>
          <div className="text-lg font-bold text-gravel-600 sm:text-2xl">
            Transaction History
          </div>
        </div>
        <div className="mt-16 flex items-center space-x-4 xsm:justify-start xsm:space-x-6 xsm:px-0">
          <div className="relative rounded-md">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Start Date"
              className="h-10 w-28 rounded-md border-gray-400 bg-white text-sm text-gray-600 focus:border-olivine-500 focus:ring-olivine-500 xsm:w-36"
            />
            <MdDateRange className="absolute right-1 top-2 h-6 w-6 text-olivine-500" />
          </div>
          <div className="relative rounded-md">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="End Date"
              className="h-10 w-28 rounded-md border-gray-400 bg-white text-sm text-gray-600 focus:border-olivine-500 focus:ring-olivine-500 xsm:w-36"
            />
            <MdDateRange className="absolute right-1 top-2 h-6 w-6 text-olivine-500" />
          </div>
          <div className="rounded-md ">
            <button
              onClick={searchByDate}
              className="cursor-pointer rounded-lg border-[1px] border-olivine-500 bg-olivine-500 px-8 py-[10px] text-xs font-semibold text-white transition-all hover:bg-olivine-600 xsm:px-8 xsm:text-sm md:py-[9px]"
            >
              SEARCH
            </button>
          </div>
        </div>
        <div className="mt-10">
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="space-y-4 text-sm xsm:text-base md:flex md:items-center md:space-x-6 md:space-y-0">
              <div className="items-centern flex space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer">
                        <div className="px-1 font-semibold">All</div>
                      </div>
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer">
                        <div className="px-1 font-semibold">Wallet deposit</div>
                      </div>
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer">
                        <div className="px-1 font-semibold">Debit</div>
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
              <div className="flex items-center space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer">
                        <div className="px-1 font-semibold">Subcriptions</div>
                      </div>
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer">
                        <div className="px-1 font-semibold">Bills</div>
                      </div>
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer">
                        <div className="px-1 font-semibold">Airtime</div>
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="mt-16">
                  <div className="mt-16">
                    {!isLoadingTransact ? (
                      fetchedTransactions && fetchedTransactions?.length ? (
                        fetchedTransactions?.map((fTransact, idx) => (
                          <div
                            onClick={() => {
                              dispatch(setTransaction(fTransact));
                              navigate("/home/transaction/detail");
                            }}
                            key={idx}
                            className="flex cursor-pointer items-center justify-between border-t-[1px] border-gray-300 py-3 text-xs"
                          >
                            <div className="space-y-2">
                              {fTransact?.payment_ref_no &&
                              fTransact?.transaction_service_name ? (
                                <div>{`${fTransact?.transaction_type_name} - ${fTransact?.transaction_service_name} - ${fTransact?.payment_ref_no}`}</div>
                              ) : fTransact?.transaction_type_name &&
                                fTransact?.narration ? (
                                <div>
                                  {`${fTransact?.transaction_type_name} - ${fTransact?.narration}`}
                                </div>
                              ) : (
                                <div>{`${fTransact?.transaction_type_name}`}</div>
                              )}

                              <div className="font-bold">
                                {fTransact?.transaction_token
                                  ? `Token: ${fTransact?.transaction_token}`
                                  : null}
                              </div>
                              <div className="font-bold">
                                {!fTransact?.transaction_token &&
                                fTransact?.transaction_id
                                  ? fTransact?.transaction_id
                                  : null}
                              </div>
                              <div className="flex items-center space-x-2">
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "MMM Do YYYY"
                                  )}
                                </span>
                                <div className="h-4 w-[1px] bg-gray-500"></div>
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "LT"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-col items-end space-y-2">
                              <div className="font-semibold">
                                {fTransact?.transaction_group !== "Debit" ? (
                                  <span className="text-lg text-olivine-500">
                                    +
                                  </span>
                                ) : (
                                  <span className="text-lg text-red-600">
                                    -
                                  </span>
                                )}
                                {` ₦ ${Number(
                                  fTransact?.amount
                                ).toLocaleString()}`}
                              </div>
                              <div>
                                {fTransact
                                  ? `Processing fee: ₦${Number(
                                      fTransact?.convience_fees_amount
                                    ).toLocaleString()}`
                                  : null}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : null
                    ) : (
                      <CircularProgress size={20} color="inherit" />
                    )}
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="mt-16">
                  <div className="mt-16">
                    {!isLoadingTransact ? (
                      fetchedTransactions && fetchedTransactions?.length ? (
                        fetchedTransactions?.map((fTransact, idx) => (
                          <div
                            onClick={() => {
                              dispatch(setTransaction(fTransact));
                              navigate("/home/transaction/detail");
                            }}
                            key={idx}
                            className="flex cursor-pointer items-center justify-between border-t-[1px] border-gray-300 py-3 text-xs"
                          >
                            <div className="space-y-2">
                              {fTransact?.payment_ref_no &&
                              fTransact?.transaction_service_name ? (
                                <div>{`${fTransact?.transaction_type_name} - ${fTransact?.transaction_service_name} - ${fTransact?.payment_ref_no}`}</div>
                              ) : (
                                <div>{`${fTransact?.transaction_type_name}`}</div>
                              )}

                              <div className="font-bold">
                                {fTransact?.transaction_token
                                  ? `Token: ${fTransact?.transaction_token}`
                                  : null}
                              </div>
                              <div className="font-bold">
                                {!fTransact?.transaction_token &&
                                fTransact?.transaction_id
                                  ? fTransact?.transaction_id
                                  : null}
                              </div>
                              <div className="flex items-center space-x-2">
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "MMM Do YYYY"
                                  )}
                                </span>
                                <div className="h-4 w-[1px] bg-gray-500"></div>
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "LT"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-col items-end space-y-2">
                              <div className="font-semibold">
                                {fTransact?.transaction_group !== "Debit" ? (
                                  <span className="text-lg text-olivine-500">
                                    +
                                  </span>
                                ) : (
                                  <span className="text-lg text-red-600">
                                    -
                                  </span>
                                )}
                                {` ₦ ${Number(
                                  fTransact?.amount
                                ).toLocaleString()}`}
                              </div>
                              <div>
                                {fTransact
                                  ? `Processing fee: ₦${Number(
                                      fTransact?.convience_fees_amount
                                    ).toLocaleString()}`
                                  : null}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : null
                    ) : (
                      <CircularProgress size={20} color="inherit" />
                    )}
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="mt-16">
                  <div className="mt-16">
                    {!isLoadingTransact ? (
                      fetchedTransactions && fetchedTransactions?.length ? (
                        fetchedTransactions?.map((fTransact, idx) => (
                          <div
                            onClick={() => {
                              dispatch(setTransaction(fTransact));
                              navigate("/home/transaction/detail");
                            }}
                            key={idx}
                            className="flex cursor-pointer items-center justify-between border-t-[1px] border-gray-300 py-3 text-xs"
                          >
                            <div className="space-y-2">
                              {fTransact?.payment_ref_no &&
                              fTransact?.transaction_service_name ? (
                                <div>{`${fTransact?.transaction_type_name} - ${fTransact?.transaction_service_name} - ${fTransact?.payment_ref_no}`}</div>
                              ) : (
                                <div>{`${fTransact?.transaction_type_name}`}</div>
                              )}

                              <div className="font-bold">
                                {fTransact?.transaction_token
                                  ? `Token: ${fTransact?.transaction_token}`
                                  : null}
                              </div>
                              <div className="font-bold">
                                {!fTransact?.transaction_token &&
                                fTransact?.transaction_id
                                  ? fTransact?.transaction_id
                                  : null}
                              </div>
                              <div className="flex items-center space-x-2">
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "MMM Do YYYY"
                                  )}
                                </span>
                                <div className="h-4 w-[1px] bg-gray-500"></div>
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "LT"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-col items-end space-y-2">
                              <div className="font-semibold">
                                {fTransact?.transaction_group !== "Debit" ? (
                                  <span className="text-lg text-olivine-500">
                                    +
                                  </span>
                                ) : (
                                  <span className="text-lg text-red-600">
                                    -
                                  </span>
                                )}
                                {` ₦ ${Number(
                                  fTransact?.amount
                                ).toLocaleString()}`}
                              </div>
                              <div>
                                {fTransact
                                  ? `Processing fee: ₦${Number(
                                      fTransact?.convience_fees_amount
                                    ).toLocaleString()}`
                                  : null}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : null
                    ) : (
                      <CircularProgress size={20} color="inherit" />
                    )}
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="mt-16">
                  <div className="mt-16">
                    {!isLoadingTransact ? (
                      fetchedTransactions && fetchedTransactions?.length ? (
                        fetchedTransactions?.map((fTransact, idx) => (
                          <div
                            onClick={() => {
                              dispatch(setTransaction(fTransact));
                              navigate("/home/transaction/detail");
                            }}
                            key={idx}
                            className="flex cursor-pointer items-center justify-between border-t-[1px] border-gray-300 py-3 text-xs"
                          >
                            <div className="space-y-2">
                              {fTransact?.payment_ref_no &&
                              fTransact?.transaction_service_name ? (
                                <div>{`${fTransact?.transaction_type_name} - ${fTransact?.transaction_service_name} - ${fTransact?.payment_ref_no}`}</div>
                              ) : (
                                <div>{`${fTransact?.transaction_type_name}`}</div>
                              )}

                              <div className="font-bold">
                                {fTransact?.transaction_token
                                  ? `Token: ${fTransact?.transaction_token}`
                                  : null}
                              </div>
                              <div className="font-bold">
                                {!fTransact?.transaction_token &&
                                fTransact?.transaction_id
                                  ? fTransact?.transaction_id
                                  : null}
                              </div>
                              <div className="flex items-center space-x-2">
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "MMM Do YYYY"
                                  )}
                                </span>
                                <div className="h-4 w-[1px] bg-gray-500"></div>
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "LT"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-col items-end space-y-2">
                              <div className="font-semibold">
                                {fTransact?.transaction_group !== "Debit" ? (
                                  <span className="text-lg text-olivine-500">
                                    +
                                  </span>
                                ) : (
                                  <span className="text-lg text-red-600">
                                    -
                                  </span>
                                )}
                                {` ₦ ${Number(
                                  fTransact?.amount
                                ).toLocaleString()}`}
                              </div>
                              <div>
                                {fTransact
                                  ? `Processing fee: ₦${Number(
                                      fTransact?.convience_fees_amount
                                    ).toLocaleString()}`
                                  : null}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : null
                    ) : (
                      <CircularProgress size={20} color="inherit" />
                    )}
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="mt-16">
                  <div className="mt-16">
                    {!isLoadingTransact ? (
                      fetchedTransactions && fetchedTransactions?.length ? (
                        fetchedTransactions?.map((fTransact, idx) => (
                          <div
                            onClick={() => {
                              dispatch(setTransaction(fTransact));
                              navigate("/home/transaction/detail");
                            }}
                            key={idx}
                            className="flex cursor-pointer items-center justify-between border-t-[1px] border-gray-300 py-3 text-xs"
                          >
                            <div className="space-y-2">
                              {fTransact?.payment_ref_no &&
                              fTransact?.transaction_service_name ? (
                                <div>{`${fTransact?.transaction_type_name} - ${fTransact?.transaction_service_name} - ${fTransact?.payment_ref_no}`}</div>
                              ) : (
                                <div>{`${fTransact?.transaction_type_name}`}</div>
                              )}

                              <div className="font-bold">
                                {fTransact?.transaction_token
                                  ? `Token: ${fTransact?.transaction_token}`
                                  : null}
                              </div>
                              <div className="font-bold">
                                {!fTransact?.transaction_token &&
                                fTransact?.transaction_id
                                  ? fTransact?.transaction_id
                                  : null}
                              </div>
                              <div className="flex items-center space-x-2">
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "MMM Do YYYY"
                                  )}
                                </span>
                                <div className="h-4 w-[1px] bg-gray-500"></div>
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "LT"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-col items-end space-y-2">
                              <div className="font-semibold">
                                {fTransact?.transaction_group !== "Debit" ? (
                                  <span className="text-lg text-olivine-500">
                                    +
                                  </span>
                                ) : (
                                  <span className="text-lg text-red-600">
                                    -
                                  </span>
                                )}
                                {` ₦ ${Number(
                                  fTransact?.amount
                                ).toLocaleString()}`}
                              </div>
                              <div>
                                {fTransact
                                  ? `Processing fee: ₦${Number(
                                      fTransact?.convience_fees_amount
                                    ).toLocaleString()}`
                                  : null}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : null
                    ) : (
                      <CircularProgress size={20} color="inherit" />
                    )}
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="mt-16">
                  <div className="mt-16">
                    {!isLoadingTransact ? (
                      fetchedTransactions && fetchedTransactions?.length ? (
                        fetchedTransactions?.map((fTransact, idx) => (
                          <div
                            onClick={() => {
                              dispatch(setTransaction(fTransact));
                              navigate("/home/transaction/detail");
                            }}
                            key={idx}
                            className="flex cursor-pointer items-center justify-between border-t-[1px] border-gray-300 py-3 text-xs"
                          >
                            <div className="space-y-2">
                              {fTransact?.payment_ref_no &&
                              fTransact?.transaction_service_name ? (
                                <div>{`${fTransact?.transaction_type_name} - ${fTransact?.transaction_service_name} - ${fTransact?.payment_ref_no}`}</div>
                              ) : (
                                <div>{`${fTransact?.transaction_type_name}`}</div>
                              )}

                              <div className="font-bold">
                                {fTransact?.transaction_token
                                  ? `Token: ${fTransact?.transaction_token}`
                                  : null}
                              </div>
                              <div className="font-bold">
                                {!fTransact?.transaction_token &&
                                fTransact?.transaction_id
                                  ? fTransact?.transaction_id
                                  : null}
                              </div>
                              <div className="flex items-center space-x-2">
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "MMM Do YYYY"
                                  )}
                                </span>
                                <div className="h-4 w-[1px] bg-gray-500"></div>
                                <span>
                                  {moment(fTransact?.transaction_date).format(
                                    "LT"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-col items-end space-y-2">
                              <div className="font-semibold">
                                {fTransact?.transaction_group !== "Debit" ? (
                                  <span className="text-lg text-olivine-500">
                                    +
                                  </span>
                                ) : (
                                  <span className="text-lg text-red-600">
                                    -
                                  </span>
                                )}
                                {` ₦ ${Number(
                                  fTransact?.amount
                                ).toLocaleString()}`}
                              </div>
                              <div>
                                {fTransact
                                  ? `Processing fee: ₦${Number(
                                      fTransact?.convience_fees_amount
                                    ).toLocaleString()}`
                                  : null}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : null
                    ) : (
                      <CircularProgress size={20} color="inherit" />
                    )}
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}

export default TransactionHistory;
