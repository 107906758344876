import React from "react";
import SignedInNavbar from "./SignedInNavbar";
// import SignedInPlansPageCards from "./SignedInPlansPageCards";
// import Bronze from "../assets/bronze.png";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { getPlans } from "../store/actions";

function SignedInPlansPage(props) {
  // const dispatch = useDispatch();
  // let navigate = useNavigate();
  // const [fetchedPlans, setFetchedPlans] = useState([]);

  // const { plans } = useSelector((state) => state.masterData);

  // useEffect(() => {
  //   if (!plans) {
  //     const data = {
  //       sql_ref_no: "22",
  //       sql_option: "1",
  //     };

  //     dispatch(getPlans(data));
  //   }

  //   if (plans) setFetchedPlans(plans);
  // }, [dispatch, plans]);

  // const handleView = (location) => {
  //   switch (location) {
  //     case "GROBP":
  //       navigate("/login/home/plans/bronze");
  //       break;
  //     case "GROSP":
  //       navigate("/login/home/plans/silver");
  //       break;
  //     case "GROGP":
  //       navigate("/login/home/plans/gold");
  //       break;
  //     case "GRODP":
  //       navigate("/login/home/plans/diamond");
  //       break;
  //     case "GROPP":
  //       navigate("/login/home/plans/platinum");
  //       break;
  //     case "home":
  //       navigate("/login/home");
  //       break;
  //     default:
  //       return;
  //   }
  // };

  return (
    <div>
      <SignedInNavbar />
      <div className="px-4 py-16 sm:px-8 md:px-16">
        <div className="non-italic align-top font-sans text-5xl font-bold leading-10 text-gravel-500">
          Plans
        </div>
        <div className="mt-9 flex font-sans font-medium ">
          <div className="rounded-l-md bg-olivine-500 px-5 py-4 text-white">
            NGN
          </div>
          <div className="rounded-r-md bg-caper-500 px-5 py-4 text-gravel-500">
            USD
          </div>
        </div>
        <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {/* {fetchedPlans &&
            fetchedPlans?.map((plan) => (
              <SignedInPlansPageCards
                key={plan.id}
                clicked={() => handleView(plan.plan_code)}
                title={plan.name}
                image={Bronze}
                price={plan.recurring_price}
                quantity={plan.description}
              />
            ))} */}
        </div>
      </div>
    </div>
  );
}

export default SignedInPlansPage;
