import React, { useEffect, useState } from "react";
import SignedInNavbarImproved from "../../../SignedInNavbarImproved";
import DowngradePlanPageComponent from "../DowngradePlanPageComponent";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProducts } from "../../../../store/actions";
import { authService } from "../../../../services/authService";

function DowngradePlansPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const [fetchedProducts, setFectchedProducts] = useState({});
  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [fetchedImages, setFetchedImages] = useState([]);
  const { products } = useSelector((state) => state.masterData);
  const { selectedProductId } = useSelector((state) => state.modal);
  const corporateId = authService.getCustomerCorporateId();
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (!products) {
      const data = {
        sql_ref_no: "46",
        corporateId: corporateId ? +corporateId : 1,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(getProducts(data))
        .unwrap()
        .catch((err) => {
          return err;
        });
    }

    if (products) setFectchedProducts(products);
  }, [dispatch, products]);

  useEffect(() => {
    if (
      params.id &&
      fetchedProducts?.plan_info?.length &&
      fetchedProducts?.Plan_Image?.length
    ) {
      const filteredPlans =
        fetchedProducts?.plan_info?.length &&
        fetchedProducts?.plan_info?.filter(
          (plan) => plan?.product_id === params.id
        );

      const upgradeIndex = [];

      filteredPlans?.forEach((plan, idx) => {
        if (plan?.plan_code === selectedProductId?.planCode) {
          return upgradeIndex?.push(idx);
        }
      });

      const plansToUpgradeTo = filteredPlans?.filter(
        (_, idx) => idx < upgradeIndex[0]
      );

      setFetchedPlans(plansToUpgradeTo);

      const filteredImages =
        fetchedProducts?.Plan_Image?.length &&
        fetchedProducts?.Plan_Image?.filter(
          (image) => image?.product_id === params.id
        );

      setFetchedImages(filteredImages);
    }
  }, [dispatch, params.id, fetchedProducts, selectedProductId?.planCode]);

  const getPlanImage = (id) => {
    const selectedImages =
      fetchedImages && fetchedImages?.filter((img) => img?.zoho_plan_id === id);

    return selectedImages[0]?.plan_image_url;
  };

  return (
    <div>
      <SignedInNavbarImproved />
      <div className="relative px-3 pb-8 pt-16 xsm:px-4 sm:px-8 md:px-10 lg:px-16">
        <div className="text-2xl font-bold text-gray-600">Downgrade Plans</div>
        <div className="mt-8 grid gap-6 pb-64 grid-auto-fit-sm">
          {fetchedPlans && fetchedPlans?.length
            ? fetchedPlans?.map((plan, idx) => (
                <DowngradePlanPageComponent
                  id={idx}
                  Name={plan?.name}
                  Image={getPlanImage(plan?.plan_id)}
                  Price={`$${Number(plan?.recurring_price).toFixed(2)}/month`}
                  amount={plan?.recurring_price}
                  planCode={plan?.plan_code}
                  Quantity={selectedProductId?.qty}
                />
              ))
            : null}
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default DowngradePlansPage;
