import { createContext } from "react";
import { BehaviorSubject } from "rxjs";
import toast from "react-hot-toast";

const LogicContext = createContext({
  setPreviousUrl: (url) => {},
  getPreviousUrl: () => {},
  httpResponseManager: (responseData) => {},
});

export function LogicContextProvider(props) {
  let previousUrlSubject = new BehaviorSubject("");

  const setPreviousUrl = (url) => {
    previousUrlSubject.next(url);
  };

  const getPreviousUrl = () => {
    return previousUrlSubject;
  };

  const httpResponseManager = (apiData) => {
    if (apiData) {
      const { message_id, message_text } = apiData;
      switch (message_id) {
        case 1:
        case "1":
          if (message_text !== "User Found") {
            toast.success(message_text);
          }
          return apiData;
        case 2:
        case "2":
          toast.error(message_text);
          return apiData;
        case 3:
        case "3":
          toast.error(message_text);
          return apiData;
        case 4:
        case "4":
          toast.error(message_text);
          return apiData;

        default:
          toast.error(message_text || "connection error!");
      }
    } else {
      toast.error("Network error!");
    }
  };

  const context = {
    setPreviousUrl: setPreviousUrl,
    getPreviousUrl: getPreviousUrl,
    httpResponseManager: httpResponseManager,
  };

  return (
    <LogicContext.Provider value={context}>
      {props.children}
    </LogicContext.Provider>
  );
}

export default LogicContext;
