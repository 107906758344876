import React from "react";
import { CgClose } from "react-icons/cg";

const RecipientDetailsPopover = () => {
  return (
    <div className="h-full pb-16">
      <div className="h-hug w-[275px] rounded-2xl border-[1px] border-gray-300 bg-gray-100 px-3 py-3 shadow shadow-gray-400 xsm:w-[350px] xsm:px-4 sm:w-[420px] sm:px-6">
        <div className="flex items-center justify-between">
          <div className="font-bold text-gravel-500 sm:text-xl">
            Recipient’s Details (1/3)
          </div>
          <button className="rounded-full p-2 active:bg-gray-200">
            <CgClose className="h-5 w-5 text-gravel-500" />
          </button>
        </div>
        <div className="mt-1 flex justify-start text-[11px] xsm:text-xs">
          Please enter your gift recipient’s details
        </div>
        <div>
          <form>
            <div className="mt-3 flex items-center space-x-2">
              <div className="flex-1">
                <label
                  htmlFor="landmark"
                  className="ml-3 flex justify-start text-xs text-gray-600"
                >
                  First Name
                </label>
                <div className="relative flex items-center justify-end rounded-md">
                  <div className="flex-grow">
                    <input
                      type="text"
                      placeholder="First name"
                      className="block w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-sm font-semibold placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    />
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <label
                  htmlFor="zipCode"
                  className="ml-3 flex justify-start text-xs text-gray-600"
                >
                  Last Name
                </label>
                <div className="relative flex items-center justify-end rounded-md">
                  <div className="flex-grow">
                    <input
                      type="text"
                      placeholder="Last name"
                      className="block w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-sm font-semibold placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label
                htmlFor="addressLine1"
                className="ml-3 flex justify-start text-xs text-gray-600"
              >
                Email Address
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    type="email"
                    placeholder="Enter recipient’s email address"
                    className="block w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-sm font-semibold placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label
                htmlFor="phoneNumber"
                className="ml-3 flex justify-start text-xs text-gray-600"
              >
                Phone Number
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    className="block w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-sm font-semibold placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label
                htmlFor="country/region"
                className="ml-3 flex justify-start text-xs text-gray-600"
              >
                Select Subscription Duration
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <select
                    type="text"
                    className="block w-full cursor-pointer rounded-md border-gray-300 bg-white pl-4 pr-10 text-sm font-semibold text-gray-600 placeholder:text-sm focus:border-olivine-500 focus:ring-olivine-500"
                  >
                    <option>select</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
          <div className="mt-5 flex justify-end pb-4 text-center tracking-widest">
            <button className="rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 sm:px-10">
              NEXT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipientDetailsPopover;
