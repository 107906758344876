import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";

function CreateBasketCard() {
  const navigate = useNavigate();

  const createBasket = () => {
    navigate("/basket/create-basket");
  };

  return (
    <div className="flex h-full w-full flex-col justify-between rounded-md bg-olivine-500 px-3 py-4 shadow shadow-gray-500 xsm:px-4 sm:max-w-md">
      <div>
        <AiOutlinePlus
          onClick={() => createBasket()}
          className="mt-14 h-12 w-12 rounded-full bg-white text-olivine-500"
        />
        <div className="mt-6 text-2xl font-bold leading-normal text-white">
          Create your own
          <br /> basket
        </div>
        <div className="mt-4 text-sm text-white">
          You can now create your own basket from available food items.
        </div>
        <div className="mt-2 text-base font-bold text-white">
          From ₦15,000/month
        </div>
      </div>
      <div className="mb-3 mt-6 px-4 text-center">
        <button
          onClick={() => createBasket()}
          className=" w-full rounded-md border-[1px] border-white py-3 font-medium text-white transition duration-300 hover:bg-olivine-600"
        >
          START NOW
        </button>
      </div>
    </div>
  );
}

export default CreateBasketCard;
