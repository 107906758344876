import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import OtpInput from "react-otp-input";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";

function CurrentMethodChangePin(props) {
  const [otp, setOtp] = useState("");

  function submitPin() {
    if (otp.length !== 4) {
      return toast.error("Please, enter a valid PIN");
    }
    props.submitOldPin(otp);
  }

  return (
    <div className="p-4">
      <div className="flex justify-end">
        <div className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
          <IoClose onClick={() => props?.closeModal()} className="h-5 w-5" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center space-x-2">
        <h2 className="mt-4 text-xl font-semibold text-gray-700">Change PIN</h2>
        <p className="mt-1 text-center text-sm text-gray-600">
          Input previous pin
        </p>
        <p className="mt-3 max-w-sm text-center text-sm text-gray-600">
          Please enter your previous transaction PIN to proceed and reset your
          new pin
        </p>
        <div className="mt-2 flex justify-center">
          <OtpInput
            inputStyle={{
              margin: 3,
              height: 35,
              width: 35,
              borderRadius: 4,
              borderColor: "#BDBDBD",
              outline: "none",
              border: "1px solid #BDBDBD",
            }}
            value={otp}
            onChange={setOtp}
            inputType="number"
            numInputs={4}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <div className="mx-auto mt-6 w-full max-w-[250px] pb-2">
          <button
            disabled={props?.isLoading}
            onClick={() => submitPin()}
            className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F6FBEE] p-8 py-3 text-sm font-semibold text-olivine-500  transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100"
          >
            {props?.isLoading === true ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Proceed "
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CurrentMethodChangePin;
