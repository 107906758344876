import React from "react";
import { IoClose } from "react-icons/io5";

function TransactionPinPopover() {
  return (
    <div className="p-8 bg-white w-[400px] sm:w-[460px] text-gray-500 shadow-lg rounded-2xl">
      <div className="flex justify-between items-center">
        <div className="font-bold text-lg text-gravel-500">Transaction PIN</div>
        <button className="p-1 rounded-full hover:bg-gray-200">
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="mt-6 px-4 py-3 bg-[#E9F8CC] space-y-1 rounded-lg ring-[0.5px] ring-olivine-400 filter drop-shadow">
        <div className="flex justify-start font-semibold text-sm text-gravel-400">
          You are paying for:
        </div>
        <div className="flex justify-start font-bold text-xl text-gravel-500 tracking-wider">
          ₦44,000.00
        </div>
        <div className="flex justify-start font-semibold text-sm text-gravel-400">
          Plan name and add-ons
        </div>
        <div className="flex justify-start text-base font-light text-gravel-600 ">
          08012345678
        </div>
      </div>
      <div className="mt-4 ">
        <form>
          <div className="flex justify-start font-bold text-sm text-gray-500">
            Please enter your transaction PIN
          </div>
          <div className="mt-2 rounded-md flex space-x-2">
            <input
              type="text"
              className="h-8 w-10 text-base text-center bg-white block rounded focus:ring-olivine-500 focus:ring-1 focus:border-2 focus:border-olivine-500"
            />
            <input
              type="text"
              className="h-8 w-10 text-base text-center bg-white block rounded focus:ring-olivine-500 focus:ring-1 focus:border-2 focus:border-olivine-500"
            />
            <input
              type="text"
              className="h-8 w-10 text-base text-center bg-white block rounded focus:ring-olivine-500 focus:ring-1 focus:border-2 focus:border-olivine-500"
            />
            <input
              type="text"
              className="h-8 w-10 text-base text-center bg-white block rounded focus:ring-olivine-500 focus:ring-1 focus:border-2 focus:border-olivine-500"
            />
          </div>
          <div className="mt-8">
            <div className="flex justify-between text-center mt-8">
              <button className="flex px-8 py-3 text-olivine-500 text-sm border-2 border-olivine-400 rounded-md hover:border-olivine-600 transition-all duration-300 tracking-widest">
                BACK
              </button>
              <button className="px-12 py-3 text-white text-sm bg-olivine-500 rounded-md hover:bg-olivine-600 transition duration-300 tracking-widest">
                PAY
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TransactionPinPopover;
