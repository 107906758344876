import React from 'react';
import SignedInNavbar from './SignedInNavbar';
import { BiArrowBack } from 'react-icons/bi';
import group4 from '../assets/Group-4.svg';
import SignedInNavbarImproved from './SignedInNavbarImproved';



function RewardCentre() {
  return (
    <div>
        <SignedInNavbarImproved/>
        <div className='px-4 sm:px-8 md:px-10 lg:px-16 pt-16 pb-40'>
            <div className='flex items-center space-x-6'>
                <div className='p-1 rounded-full hover:bg-gray-200'>
                    <BiArrowBack className='h-6 w-6 text-gray-600 cursor-pointer'/>
                </div>
                <div className='font-bold text-2xl text-gravel-500'>Reward Centre</div>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 sm:px-8 md:px-16 lg:px-0 mt-9'>
                <div className='cols-span-1 bg-[#F6FBEE] border-[1px] border-gray-200 rounded-md p-4 sm:p-8 shadow shadow-gray-400'>
                    <div className='sm:grid sm:grid-cols-2 flex flex-col-reverse'>
                        <div className='cols-span-1 mt-4 sm:mt-0'>
                            <div className='text-olivine-500 font-bold text-lg'>Reward Bank</div>
                            <div className='mt-2 font-medium text-sm text-gray-600'>Keep using Grocedy by funding your account to get cash <br/>rewards.</div>
                            <div className='mt-4 font-normal text-sm text-gray-500'>Your Reward:</div>
                            <div className='font-bold text-base text-gray-600'>₦ 1,000</div>
                            <button className='mt-8 font-semibold text-olivine-500 hover:text-olivine-600'>WITHDRAW TO WALLET</button>
                        </div>
                        <div className='cols-span-1 flex justify-center'>
                            <img src= {group4} className='mt-1' alt=''/>
                        </div>
                    </div>
                </div>
                <div className='col-span-1 bg-[#F6FBEE] border-[1px] border-gray-200 rounded-md p-4 sm:p-8 shadow shadow-gray-400'>
                    <div>
                        <div className='text-olivine-500 font-bold text-lg'>Refer and Earn</div>
                        <div className='mt-2 font-medium text-sm text-gray-600'>Refer and earn NGN 500 for each successful sign up. Your code is <br/><span className='font-bold text-gravel-500'>RE52456</span></div>
                    </div>
                    <div className='mt-7 sm:grid sm:grid-cols-3'>
                        <div className='grid-col-1 mt-4 sm:mt-0'>
                            <div className='font-normal text-sm text-gray-500'>Referral Balance:</div>
                            <div className='font-bold text-base text-gray-600'>₦ 1,000</div>
                        </div>
                        <div className='grid-col-1 mt-4 sm:mt-0'>
                            <div className='font-normal text-sm text-gray-500'>No. of Sign-ups</div>
                            <div className='font-bold text-base text-gray-600'>2</div>
                        </div>
                        <div className='grid-col-1 mt-4 sm:mt-0'>
                            <div className='font-normal text-sm text-gray-500'>No. of Referrals:</div>
                            <div className='font-bold text-base text-gray-600'>2</div>
                        </div>
                    </div>
                    <button className='mt-10 font-semibold text-olivine-500 hover:text-olivine-600'>WITHDRAW TO WALLET</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RewardCentre