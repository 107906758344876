import React from "react";

function ProfileSettingsItems({ Icon, title }) {
  return (
    <div className="group flex cursor-pointer items-center space-x-2 rounded-md px-4 py-5 transition duration-300 hover:bg-[#4F4F4F4F]">
      <div>
        <Icon className="h-6 w-6" />
      </div>
      <p className="">{title}</p>
    </div>
  );
}

export default ProfileSettingsItems;
