import React from "react";
import RewardBankHistoryEmptyImage from "../../../assets/rewardBankHistoryEmptyImage.svg";

const RewardBankHistoryEmpty = () => {
  return (
    <div className="mt-2">
      <div className="flex items-center justify-center">
        <img
          src={RewardBankHistoryEmptyImage}
          alt="img"
          className="h-60 w-60"
        />
      </div>
      <div className="mx-auto max-w-xl">
        <h2 className="text-center text-lg font-bold text-gray-700 sm:text-2xl">
          No Rewards Yet
        </h2>
        <p className="mt-2 text-center text-sm font-medium text-gray-500">
          "Oops, it seems like your reward history is empty. Start Earn Cowries
          today by exploring Grocedy's fantastic offers and loyalty programs.
          Shop, refer friends, and subscribe to baskets to watch your rewards
          grow!"
        </p>
      </div>
    </div>
  );
};

export default RewardBankHistoryEmpty;
