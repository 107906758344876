import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  getTransactions,
  getWalletBalance,
  planCreate,
  transactionInitialize,
  verifyPayment,
  getAutoFund,
  cancelRecurringPlan,
} from "../store/actions";
import { authService } from "../services/authService";
import {
  setExistingAmount,
  setIsActivePlan,
  setIsEditing,
  setWalletUpdate,
} from "../store/slices/modalSlice";
import { usePaystackPayment } from "react-paystack";
import Modal from "./Ui";

function FundYourWalletPopover_Three({
  onClose,
  handleFlutterPay,
  insufficientPopOption,
  handleInsufficientOption,
}) {
  const [selected, setSelected] = useState("recurring");
  const [showModal, setShowModal] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState("");
  const [fetchedActiveRecuringFund, setFetchedActiveRecuringFund] = useState(
    {}
  );
  const [fetchedAutoFund, setFetchedAutoFund] = useState({});
  const [selectedModal, setSelectedModal] = useState("");

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();
  const user_full_name = authService.getUserFullName();
  const user_phone = authService.getCurrentUserPhone();
  const dispatch = useDispatch();
  const { amountToPay, existingAmount, isEditing } = useSelector(
    (state) => state.modal
  );
  const { autoFund } = useSelector((state) => state.payment);
  const [input, setInput] = useState((existingAmount && existingAmount) || "");

  const handleChange = (event) => {
    const result = event.target.value.replace(/[^0-9|,]/gi, "");

    setInput(result);
  };

  useEffect(() => {
    if (fetchedActiveRecuringFund?.deposit_amount && isEditing) {
      dispatch(
        setExistingAmount(fetchedActiveRecuringFund?.deposit_amount?.toString())
      );
    }
  }, [fetchedActiveRecuringFund?.deposit_amount]);

  useEffect(() => {
    if (existingAmount) {
      setInput(existingAmount);
    }
  }, [existingAmount]);

  useEffect(() => {
    if (!autoFund) {
      const data = {
        userId: userId.toString(),
        customerId: customerId?.toString(),
        verification_code,
      };

      dispatch(getAutoFund(data));
    }

    if (autoFund) {
      setFetchedAutoFund(autoFund?.wallet_info);
    }
  }, [autoFund, userId, customerId, dispatch, verification_code]);

  useEffect(() => {
    if (fetchedAutoFund?.length) {
      const activeAutoFund = fetchedAutoFund
        ?.filter((active) => active?.status_name === "Active")
        .pop();

      setFetchedActiveRecuringFund(activeAutoFund);
    }
  }, [fetchedAutoFund]);

  const updateWalletBalance = () => {
    try {
      const data = {
        sql_ref_no: "31",
        user_id: userId.toString(),
        customer_id: customerId.toString(),
        verification_code,
        option: "1",
      };

      dispatch(getWalletBalance(data))
        .unwrap()
        .then(() => {
          const id = customerId?.toString();
          dispatch(getTransactions(id));

          return dispatch(setWalletUpdate(true));
        })
        .catch((err) => {
          return err;
        });
    } catch (err) {
      return err;
    }
  };

  const config = {
    email: authService.getCurrentUserEmail(),
    amount: +amountToPay * 100,
    metadata: {
      name: user_full_name,
      phone: user_phone,
    },
    channels: ["card"],
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    text: "Pay Now",
  };

  // function to verify the one time payment
  const onSuccess = (reference) => {
    const toastId = toast.loading("Verifying payment...");
    dispatch(verifyPayment(reference?.reference))
      .unwrap()
      .then((res) => {
        if (res && res.status === 403) {
          return toast.error(res.response.error, { id: toastId });
        }

        if (res && res.status === 400) {
          return toast.error("request failed!", { id: toastId });
        }

        handleFlutterPay();
        if (insufficientPopOption) {
          handleInsufficientOption();
        }
        onClose();
        updateWalletBalance();
        return toast.success(res.message_text, { id: toastId });
      })
      .catch((err) => {
        return err;
      });
  };

  // you can call this function anything
  const onCloseDialog = () => {
    return toast.error("request failed!");
  };

  const initializePayment = usePaystackPayment(config);

  const initializeTransaction = (planCode) => {
    try {
      const data = {
        email: authService.getCurrentUserEmail(),
        amount: input ? input.replace(/,/g, "") : amountToPay.replace(/,/g, ""),
        plan: planCode,
      };

      dispatch(transactionInitialize(data))
        .unwrap()
        .then((res) => {
          dispatch(setExistingAmount(""));
          dispatch(setIsEditing(false));
          window.location.replace(res?.data?.authorization_url);
        })
        .catch((err) => {
          return err;
        });
    } catch (err) {
      return err;
    }
  };

  const createPaystackPlan = () => {
    try {
      if (!selectedDuration) {
        return toast.error("Please select a duration!");
      }

      const toastId = toast.loading("Funding your wallet.. Please wait!!");

      const data = {
        amount: input ? input.replace(/,/g, "") : amountToPay.replace(/,/g, ""),
        plan_name: `Fund Wallet (Recurring) ${user_full_name}`,
        interval: selectedDuration,
      };

      let recuring_type = 1;

      switch (selectedDuration) {
        case "daily":
          recuring_type = 1;
          break;
        case "weekly":
          recuring_type = 2;
          break;
        case "monthly":
          recuring_type = 3;
          break;
        default:
          recuring_type = 1;
      }

      localStorage.setItem("recurr_type", recuring_type);

      return dispatch(planCreate(data))
        .unwrap()
        .then((res) => {
          if (!res.plan_code) {
            return toast.error("error", { id: toastId });
          }

          initializeTransaction(res.plan_code);
          // setPaymentPlan(res.plan_code);

          toast.success("successful", { id: toastId });
        })
        .catch((err) => {
          return err;
        });
    } catch (err) {
      return err;
    }
  };

  const handlePlanCancel = () => {
    try {
      const toastId = toast.loading("Cancelling Recurring Plan...");

      dispatch(cancelRecurringPlan(fetchedActiveRecuringFund?.payment_ref_no))
        .unwrap()
        .then((res) => {
          if (res && res.status === 400) {
            return toast.error("Recurring Plan cancel failed!", {
              id: toastId,
            });
          }
          toast.success("Recurring Plan cancelled!", {
            id: toastId,
          });
          handleOnClose();
          setFetchedActiveRecuringFund();
        })
        .catch((err) => {
          toast.error("Recurring Plan cancel failed!", {
            id: toastId,
          });

          return err;
        });
    } catch (err) {
      return err;
    }
  };

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedModal("");
  };

  const handleRedirect = (location) => {
    setShowModal(true);
    setSelectedModal(location);
  };

  return (
    <div className="mx-2 w-full max-w-[420px] rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:mx-4 xsm:px-6 xsm:py-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold text-gray-600">
          {isEditing ? "Update Auto Fund" : "Fund Wallet"}
        </div>
        <button
          onClick={() => {
            onClose();
            handleFlutterPay();
          }}
          className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="mt-4">
        <form>
          <div className="mt-2">
            {isEditing ? (
              <div className="mb-6">
                <div className="ml-2 text-xs font-semibold">Amount</div>
                <div className="mt-1">
                  <div className="relative flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        value={input}
                        onChange={handleChange}
                        type="text"
                        placeholder="Example 9,000"
                        className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 font-semibold text-gray-700 placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <label
              htmlFor="transactionType"
              className="ml-2 text-xs font-semibold"
            >
              Please select the transaction type
            </label>
            <div className="relative mt-2 flex items-center justify-end rounded-md pr-24">
              <div className="flex-grow">
                <select
                  value={selected}
                  onChange={(e) => setSelected(e.target.value)}
                  type="text"
                  className="block h-10 w-full cursor-pointer rounded-md border-gray-300 bg-white pl-4 pr-10 text-gray-500 placeholder:text-sm optional:text-sm optional:font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                >
                  <optgroup className="rounded-lg bg-olivine-100">
                    <option value="one-time">One-time transaction</option>
                    <option value="recurring">Recurring </option>
                  </optgroup>
                </select>
              </div>
            </div>
          </div>
          {selected === "recurring" ? (
            <div className="mt-6">
              <label
                htmlFor="deductionInterval"
                className="ml-2 text-xs font-semibold"
              >
                Select the deduction interval
              </label>
              <div className="relative mt-1 flex items-center justify-end rounded-md pr-24">
                <div className="flex-grow">
                  <select
                    value={selectedDuration}
                    onChange={(e) => setSelectedDuration(e.target.value)}
                    type="text"
                    className="block h-10 w-full cursor-pointer rounded-md border-gray-300 bg-white pl-4 pr-10 text-gray-500 placeholder:text-sm optional:text-sm optional:font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                  >
                    <optgroup className="rounded-lg bg-olivine-100">
                      <option>Please select an option</option>
                      <option value="daily">Daily deductions</option>
                      <option value="weekly">Weekly deductions </option>
                      <option value="monthly">Monthly deductions </option>
                    </optgroup>
                  </select>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </form>

        <div className="mt-10 flex flex-row justify-between">
          <div className="flex w-full flex-row justify-between text-center tracking-widest transition-all duration-300">
            <button
              onClick={(e) => {
                e.preventDefault();
                handleFlutterPay();
                if (insufficientPopOption) {
                  handleInsufficientOption();
                }
                onClose();
              }}
              className="rounded-md border-[1px] border-olivine-400 px-8 py-3 text-sm text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-600"
            >
              BACK
            </button>

            {selected === "recurring" ? (
              fetchedActiveRecuringFund?.status_name === "Active" ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setIsActivePlan(true));
                    handleRedirect("cancel-autofund");
                  }}
                  className="rounded-md bg-olivine-500 px-8 py-3 text-sm text-white transition duration-300 hover:bg-olivine-600"
                >
                  CONTINUE
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    createPaystackPlan();
                  }}
                  className="rounded-md bg-olivine-500 px-8 py-3 text-sm text-white transition duration-300 hover:bg-olivine-600"
                >
                  CONTINUE
                </button>
              )
            ) : (
              <button
                className="rounded-md bg-olivine-500 px-8 py-3 text-sm text-white transition duration-300 hover:bg-olivine-600"
                onClick={() => {
                  initializePayment(onSuccess, onCloseDialog);
                }}
              >
                CONTINUE
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={showModal}
        onClose={handleOnClose}
        selected={selectedModal}
        cancelFund={handlePlanCancel}
      />
    </div>
  );
}

export default FundYourWalletPopover_Three;
