import axios from "axios";
import { reqInterceptor } from "../interceptor/request-interceptor";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_SERVER_URL,
});

axiosInstance.interceptors.request.use(reqInterceptor, (err) =>
  console.log(err)
);

export default axiosInstance;
