import React, { useContext, useEffect, useState } from "react";
import Ospan from "../assets/avatar.svg";
import { IoLogOut } from "react-icons/io5";
import { HiHome } from "react-icons/hi";
import { FaShoppingBasket } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { IoMdWallet, IoMdNotifications, IoMdBasket } from "react-icons/io";
import { IoMdCart } from "react-icons/io";
import { BsPersonCircle } from "react-icons/bs";
import { RiFolderHistoryFill, RiFolderHistoryLine } from "react-icons/ri";
import SignedInHeaderItems from "./SignedInHeaderItems";
import { motion } from "framer-motion";
import { Disclosure } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  getUserInfo,
  getWalletBalance,
  productPurchaseGet,
} from "../store/actions";
import { logout } from "../store/slices/authSlice";
import { resetUser } from "../store/slices/userSlice";
import { resetVas } from "../store/slices/vasSlice";
import { resetPayment } from "../store/slices/paymentSlice";
import { resetMasterdata } from "../store/slices/masterDataSlice";
import { resetCart } from "../store/slices/cartSlice";
import { authService } from "../services/authService";
import RxjsStoreContext from "../context/RxjsStoreContext";
import { resetVoucher } from "../store/slices/voucherSlice";

function SignedInMobileNavigation() {
  let navigate = useNavigate();
  const rxjsStore = useContext(RxjsStoreContext);
  const [fectcheUserProfile, setFectcheUserProfile] = useState({});
  const [fetchedUserBalance, setFetchedUserBalance] = useState();
  const [fetchedProducts, setFectchedProducts] = useState([]);
  const [fetchedCart, setFetchedCart] = useState();
  const [totalCartItems, setTotalCartItems] = useState(0);
  const [customBasket, setCustomBasket] = useState({});
  const dispatch = useDispatch();
  const user_email = authService.getCurrentUserEmail();
  const userId = authService.getCurrentUserEmail();
  const verification_code = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();
  const corporateId = authService.getCustomerCorporateId();

  const { userInfo, products } = useSelector((state) => state.masterData);
  const { walletBalance } = useSelector((state) => state.user);
  const { unreadNotify } = useSelector((state) => state.modal);
  const { cart } = useSelector((state) => state.cart);
  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };

  useEffect(() => {
    if (!userInfo) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "7",
      };

      dispatch(getUserInfo(data));
    }

    if (userInfo) setFectcheUserProfile(userInfo);
  }, [dispatch, userInfo, customerId, userId, verification_code]);

  useEffect(() => {
    let tempCustomBasket = {
      plan_name: "Custom Basket",
      notes: "",
      discount: "00.00%",
      customer_id: authService.getCurrentUserZohoCustomerId(),
      shipping_charge: 0,
      adjustment: 0,
      adjustment_description: "custom basket item",
      exchange_rate: 1,
      line_items: [],
    };
    rxjsStore.getUserSelectedBasket().subscribe((productsListMap) => {
      for (let [key, product] of productsListMap) {
        const tempItems = {
          item_id: product.item_id,
          name: product.name,
          rate: product.item_rate_zoho,
          quantity: product.qty,
          unit: product.unit,
          item_total: product.qty * Number(product.item_rate_zoho),
        };
        tempCustomBasket.line_items.push(tempItems);
      }
      setCustomBasket(tempCustomBasket);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!walletBalance) {
      const data = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };
      dispatch(getWalletBalance(data));
    }

    if (walletBalance) setFetchedUserBalance(walletBalance);
  }, [dispatch, userId, customerId, verification_code, walletBalance]);

  useEffect(() => {
    if (!products) {
      const data = {
        sql_ref_no: "46",
        corporateId: corporateId ? +corporateId : 1,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(getProducts(data))
        .unwrap()
        .catch((err) => {
          // navigate("/login");

          return err;
        });
    }

    if (products) setFectchedProducts(products?.product_info);
  }, [dispatch, products, navigate, corporateId]);

  useEffect(() => {
    if (!cart && user_email) {
      const data = {
        user_email,
      };

      dispatch(productPurchaseGet(data))
        .unwrap()
        .catch((err) => {
          // navigate("/login");

          return err;
        });
    }
  }, [dispatch, cart, user_email, navigate]);

  useEffect(() => {
    if (cart) {
      setFetchedCart(cart);
    }
  }, [cart]);

  useEffect(() => {
    let total = 0;
    if (customBasket?.line_items?.length && !fetchedCart?.items?.length) {
      total = 1;
    }

    if (!customBasket?.line_items?.length && fetchedCart?.items?.length) {
      total = fetchedCart?.items?.length;
    }

    if (customBasket?.line_items?.length && fetchedCart?.items?.length) {
      total = fetchedCart?.items?.length + 1;
    }
    setTotalCartItems(total);
  }, [customBasket?.line_items?.length, fetchedCart?.items?.length]);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetUser());
    dispatch(resetVas());
    dispatch(resetPayment());
    dispatch(resetMasterdata());
    dispatch(resetCart());
    dispatch(resetVoucher());
    navigate("/login");
  };

  const handleRedirect = (index, product) => {
    switch (index) {
      case "Food Baskets":
        navigate(`/home/plans/${product?.product_id}`);
        break;
      case "GroChill":
        navigate("/butchers/create-basket");
        break;
      case "cart":
        navigate("/cart");
        break;
      case "wallet":
        navigate("/home/wallet");
        break;
      case "home":
        navigate("/home");
        break;
      case "Pick Your Basket":
        navigate("/basket/create-basket");
        break;
      case "Create Your Own Basket":
        navigate("/basket/create-basket");
        break;
      default:
        return;
    }
  };

  return (
    <div className="absolute top-12 z-50 h-[1800px] w-full overflow-auto bg-white pb-40 text-xs font-semibold sm:top-20 sm:hidden md:hidden">
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        transition={{ delay: 0.1 }}
      >
        <div className="px-4 py-3 xsm:px-6">
          <div className="flex items-center space-x-2">
            {fectcheUserProfile &&
            fectcheUserProfile?.customer_info?.length &&
            fectcheUserProfile?.customer_info[0]?.customer_image_url ? (
              <img
                src={fectcheUserProfile?.customer_info[0]?.customer_image_url}
                className="aspect-auto h-9 w-9 rounded-full border-[1px] border-gray-400 object-cover"
                alt=""
              />
            ) : (
              <img
                src={Ospan}
                className="aspect-auto h-9 w-9 rounded-full border-[1px] border-gray-400 object-cover"
                alt=""
              />
            )}
            <div className="space-y-[1px]">
              <div className="text-xs font-light text-gray-800">
                {fectcheUserProfile && fectcheUserProfile?.customer_info?.length
                  ? fectcheUserProfile?.customer_info[0]?.customer_full_name
                  : null}
              </div>
              <div className="text-sm font-semibold text-olivine-500">
                {fetchedUserBalance && fetchedUserBalance?.available_balance
                  ? `₦ ${Number(
                      fetchedUserBalance?.available_balance
                    ).toLocaleString()}`
                  : "₦ 0.00"}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        transition={{ delay: 0.1 }}
      >
        <div
          onClick={() => navigate("/home")}
          className="px-4 py-[10px] text-gray-600 transition duration-300 hover:bg-[#F0F4E7] hover:text-olivine-500 xsm:px-6"
        >
          <SignedInHeaderItems
            Icon={<HiHome className="h-4 w-4" />}
            title="Home"
          />
        </div>
      </motion.div>
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        transition={{ delay: 0.2 }}
      >
        <div className="w-full">
          <div className="mx-auto w-full rounded-2xl bg-white">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`${
                      open ? " text-olivine-500" : "text-gray-600"
                    } flex w-full justify-between px-4 py-3 text-left text-sm font-medium hover:bg-[#F0F4E7] hover:text-olivine-500 focus:outline-none focus-visible:ring focus-visible:ring-olivine-500 focus-visible:ring-opacity-75 xsm:px-6`}
                  >
                    <span>
                      <SignedInHeaderItems
                        Icon={<IoMdBasket className="h-4 w-4" />}
                        title="Products"
                      />
                    </span>
                    <FiChevronDown
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-4 w-4`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="mx-4 my-2 rounded-md bg-[#F4F5F1] py-2 text-sm text-gray-600">
                    {fetchedProducts &&
                      fetchedProducts?.map((product, idx) => (
                        <div
                          key={idx}
                          className="cursor-pointer px-4 py-3 text-gray-500 hover:bg-gray-200 hover:text-gray-700 xsm:px-8 sm:px-10"
                        >
                          <span
                            onClick={() =>
                              handleRedirect(product?.name, product)
                            }
                            key={idx}
                            class="block text-sm font-semibold sm:text-base"
                          >
                            {product?.name}
                          </span>
                        </div>
                      ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        transition={{ delay: 0.3 }}
      >
        <div className="w-full">
          <div className="mx-auto w-full rounded-2xl bg-white">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`${
                      open ? " text-olivine-500" : "text-gray-600"
                    } flex w-full justify-between px-4 py-3 text-left text-sm font-medium hover:bg-[#F0F4E7] hover:text-olivine-500 focus:outline-none focus-visible:ring focus-visible:ring-olivine-500 focus-visible:ring-opacity-75 xsm:px-6`}
                  >
                    <span>
                      <SignedInHeaderItems
                        Icon={<FaShoppingBasket className="h-4 w-4" />}
                        title="Gift"
                      />
                    </span>
                    <FiChevronDown
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-4 w-4`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="mx-4 my-2 rounded-md bg-[#F4F5F1] py-2 text-sm text-gray-600">
                    <div className="cursor-pointer rounded-t-md px-4 py-3 hover:bg-gray-200 xsm:px-8">
                      <Link
                        to="/login/gift-a-plan"
                        class="block text-sm font-semibold text-gray-500 hover:text-gray-600"
                      >
                        Gift a Basket
                      </Link>
                    </div>
                    <div className="cursor-pointer rounded-t-md px-4 py-3 hover:bg-gray-200 xsm:px-8">
                      <Link
                        to="/voucher"
                        className="block text-sm font-semibold text-gray-500 hover:text-gray-600"
                      >
                        Gift a Voucher
                      </Link>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        transition={{ delay: 0.4 }}
      >
        <div
          onClick={() => handleRedirect("wallet")}
          className="px-4 py-[10px] text-gray-600 transition duration-300 hover:bg-[#F0F4E7] hover:text-olivine-500 xsm:px-6"
        >
          <SignedInHeaderItems
            Icon={<IoMdWallet className="h-4 w-4" />}
            title="Wallet"
          />
        </div>
      </motion.div>
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        transition={{ delay: 0.5 }}
      >
        <div className="px-4 py-3.5 text-gray-600 transition duration-300 hover:bg-[#F0F4E7] hover:text-olivine-500 xsm:px-6">
          <div className="relative" onClick={() => handleRedirect("cart")}>
            <div className="">
              <SignedInHeaderItems
                Icon={<IoMdCart className="h-4 w-4" />}
                title="My Cart"
              />
            </div>
            {totalCartItems && totalCartItems > 0 ? (
              <div className="absolute -top-[10px] left-[8px] flex h-4 w-5 items-center justify-center rounded-full bg-red-500">
                <div className="text-[10px] font-semibold text-white">
                  {totalCartItems && totalCartItems > 0 && totalCartItems}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        transition={{ delay: 0.6 }}
      >
        <Link to="/home/notifications">
          <div className="py-3.5 text-gray-600 transition duration-300 hover:bg-[#F0F4E7] hover:text-olivine-500">
            <div className="relative">
              <div className="px-4 xsm:px-6">
                <SignedInHeaderItems
                  Icon={<IoMdNotifications className="h-4 w-4" />}
                  title="Notifications"
                />
              </div>

              {unreadNotify && unreadNotify > 0 ? (
                <div className="absolute -top-[9px] left-[22px] flex h-4 w-5 items-center justify-center rounded-full bg-red-500 xsm:left-[30px]">
                  <div className="text-[10px] font-semibold text-white">
                    {unreadNotify}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Link>
      </motion.div>
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        transition={{ delay: 0.7 }}
      >
        <Link to="/home/profile">
          <div className="px-4 py-[10px] text-gray-600 transition duration-300 hover:bg-[#F0F4E7] hover:text-olivine-500 xsm:px-6">
            <SignedInHeaderItems
              Icon={<BsPersonCircle className="h-4 w-4" />}
              title="Profile"
            />
          </div>
        </Link>
      </motion.div>
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        transition={{ delay: 0.8 }}
      >
        <Link to="/home/transaction-history">
          <div className="px-4 py-[10px] text-gray-600 transition duration-300 hover:bg-[#F0F4E7] hover:text-olivine-500 xsm:px-6">
            <SignedInHeaderItems
              Icon={<RiFolderHistoryFill className="h-4 w-4" />}
              title="Transaction History"
            />
          </div>
        </Link>
      </motion.div>
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        transition={{ delay: 0.9 }}
      >
        <Link to="/basket/order-history">
          <div className="px-4 py-[10px] text-gray-600 transition duration-300 hover:bg-[#F0F4E7] hover:text-olivine-500 xsm:px-6">
            <SignedInHeaderItems
              Icon={<RiFolderHistoryLine className="h-4 w-4" />}
              title="Order History"
            />
          </div>
        </Link>
      </motion.div>
      <motion.div
        initial={animateFrom}
        animate={animateTo}
        transition={{ delay: 1 }}
      >
        <div
          onClick={handleLogout}
          className="px-4 py-[10px] !text-red-500 opacity-100 transition duration-300 hover:bg-[#F0F4E7] hover:opacity-70 xsm:px-6"
        >
          <SignedInHeaderItems
            className="!text-red-500"
            Icon={<IoLogOut className="h-5 w-5 text-red-500" />}
            title="Log Out"
          />
        </div>
      </motion.div>
    </div>
  );
}

export default SignedInMobileNavigation;
