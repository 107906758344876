import React from "react";
import { IoClose } from "react-icons/io5";
import { Disclosure, Transition } from "@headlessui/react";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiOutlineCheck, AiTwotoneCheckCircle } from "react-icons/ai";
import moment from "moment";
import { motion } from "framer-motion";

const Tracking = ({ track, viewTimeline, setViewTimeline }) => {
  return (
    <div className={`${viewTimeline ? "inline" : "hidden"}`}>
      <motion.div drag>
        <div className="relative">
          <div className="rounded-xl border-[1px] border-gray-300 bg-white px-4 py-4 text-sm shadow-md shadow-gray-400 xsm:px-6">
            <section>
              <div className="mt-4 md:mt-0">
                <div>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <div className="flex flex-col md:flex-row md:justify-between">
                          <div>
                            <div className="text-sm font-bold text-neutral-500">
                              Your delivery is on it’s way!
                            </div>
                          </div>
                          <div className="mt-4 flex items-start space-x-2 md:mt-0">
                            <div className="font-semibold text-gray-500">
                              View Timeline
                            </div>
                            <Disclosure.Button className="rounded-full hover:bg-gray-100 active:bg-gray-200">
                              <div>
                                <IoMdArrowDropdown
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-6 w-6 text-gray-500 hover:text-olivine-500`}
                                />
                              </div>
                            </Disclosure.Button>
                          </div>
                        </div>
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        />
                        <Disclosure.Panel className="mb-16 mt-4 w-full text-sm text-gray-500 md:mb-0">
                          <div className="sm:pl- flex w-full flex-col justify-between md:flex-row md:pl-40 lg:pl-20 xl:pl-40">
                            <div className="flex w-full flex-row md:flex-col">
                              <div className="flex flex-col md:flex-row md:items-center md:justify-center">
                                <div className="">
                                  {track &&
                                  (track?.pickup?.job_status_id === "1" ||
                                    track?.pickup?.job_status_id === "2") ? (
                                    <AiOutlineCheck className="h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                                  ) : (
                                    <AiTwotoneCheckCircle className="h-6 w-6 rounded-full bg-olivine-500 p-2 text-white" />
                                  )}
                                </div>
                                <div className="flex justify-center md:w-full md:flex-grow">
                                  <hr className="h-10 w-[2px] md:h-[2px] md:w-full" />
                                </div>
                              </div>
                              <div className="-mt-2 ml-4 md:-ml-8 md:mt-0">
                                <div className="text-xs font-bold text-gray-500">
                                  Order Picked Up
                                </div>
                                <div className="text-[11px] text-gray-500 md:-ml-3">
                                  {moment(
                                    track?.pickup?.job_pickup_datetime
                                  ).format("LLL")}
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full flex-row md:flex-col">
                              <div className="flex flex-col md:flex-row md:items-center">
                                <div className="">
                                  {track &&
                                  (track?.pickup?.job_status_id === "1" ||
                                    track?.pickup?.job_status_id === "2") ? (
                                    <AiOutlineCheck className="h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                                  ) : (
                                    <AiTwotoneCheckCircle className="h-6 w-6 rounded-full bg-olivine-500 p-2 text-white" />
                                  )}
                                  {/* <AiOutlineCheck className="h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                                  <AiTwotoneCheckCircle className="hidden h-6 w-6 rounded-full bg-olivine-500 p-2 text-white" /> */}
                                </div>
                                <div className="flex justify-center md:w-full md:flex-grow">
                                  <hr className="h-10 w-[2px] md:h-[2px] md:w-full" />
                                </div>
                              </div>
                              <div className="-mt-2 ml-4 md:-ml-8 md:mt-0">
                                <div className="text-xs font-bold text-gray-500">
                                  Order in Transit
                                </div>
                                <div className="text-[11px] text-gray-500 md:-ml-3">
                                  {moment(
                                    track?.delivery?.job_delivery_datetime
                                  ).format("LLL")}
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full flex-row md:flex-col">
                              <div className="flex flex-col md:flex-row md:items-center">
                                <div className="">
                                  {track?.delivery?.job_status_id === "2" ? (
                                    <AiOutlineCheck className="h-6 w-6 rounded-full bg-olivine-500 p-1 text-white" />
                                  ) : (
                                    <AiTwotoneCheckCircle className="h-6 w-6 rounded-full bg-olivine-500 p-2 text-white" />
                                  )}
                                </div>
                              </div>
                              <div className="-mt-2 ml-4 md:-ml-8 md:mt-0">
                                <div className="text-xs font-bold text-olivine-500">
                                  Order Delivered
                                </div>
                                <div className="text-[11px] text-gray-500 md:-ml-3">
                                  {track?.job_status_id === "2"
                                    ? moment(
                                        track?.job_delivery_datetime
                                      ).format("LLL")
                                    : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            </section>
          </div>
          <div
            className="absolute -right-6 -top-5 cursor-pointer rounded-full bg-neutral-600 p-1 shadow-md shadow-gray-400 hover:opacity-80 active:opacity-100"
            onClick={() => setViewTimeline(!viewTimeline)}
          >
            <IoClose className="h-5 w-5 text-white" />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Tracking;
