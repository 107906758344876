import React, { useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from "react-router-dom";
import { rxjsStoreProvider } from "../../../context/OrderHistoryRxjsProvider";
import moment from "moment";




function OrderHistoryAllPlan({allPlan}) {
  const [page, setPage] = React.useState(1); // pagination
  const [paginationStart, setPaginationStart] = React.useState(0); // pagination
  const [history, setHistory] = React.useState(allPlan.slice(0, 5)); // pagination
  const navigate = useNavigate();


  useEffect(()=> {
    setHistory(allPlan.slice(0, 5));
  },[allPlan]);


  const handlePaginationChange = (event, pageValue) => {
    let end = 5 * pageValue;
    let start = end - 5;
    setPage(pageValue);
    setPaginationStart(start);
    let currentList = allPlan.slice(start, end);
    setHistory(currentList);
};


  function viewProps(plan){
        navigate('/basket/order-history-details');
        rxjsStoreProvider.setSingleOrderHistory(plan);
  }

  return ( 
    <>
    
    <div className="mt-8">
        {history?.map((plan, i)=> {

            return (
                <div onClick={()=> viewProps(plan)} key={i} className="cursor-pointer">
                <div className="flex items-center justify-between  border-t-[1px] border-gray-300 py-3 text-xs">
                  <div className="space-y-1">
                    <div className="flex items-center space-x-1 font-semibold">
                      <div className="px-2 rounded-full items-center justify-center bg-olivine-500">
                        <div className="text-white text-[10px]">{i + paginationStart + 1}</div>
                      </div>
                      <div className="ml-2 text-xs text-olivine-500"> {plan?.name || "Custom Basket"}</div>
                    </div>
                    <div className="flex items-center text-[10px] space-x-2">
                      <span>{
                      moment(new Date(plan?.start_date)).format("DD-MM-YYYY")
                       ||  moment(new Date(plan?.created_at)).format("DD-MM-YYYY")
                       } </span>
                      <div className="h-3 w-[1px] bg-gray-500"></div>
                      {/* <span>22:40</span> */}
                    </div>
                    <div className="flex items-center text-xs space-x-2">
                      <span className="text-gray-500">Status :</span>

                      <span className=" text-olivine-500">
                        {plan?.status}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-end space-y-2">
                    <div className="font-semibold">
                      <CurrencyFormat
                      className={"font-semibold"}
                      value={Number.parseFloat(plan?.total_amount)?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={plan.currency_code}
                    />
                    </div>
                  </div>
                </div>
              </div>
            )
        })}
           
            </div>

            <Stack spacing={2} className="mb-4 mt-2 ">
            <Typography className="text-gray-600">Page: {page}</Typography>
            <Pagination count={Math.floor(allPlan?.length/5)} page={page} onChange={handlePaginationChange} />
        </Stack>
    </>
 );
}

export default OrderHistoryAllPlan;