import { authService } from "../../../services/authService";

export const reqInterceptor = (req) => {
  // const token = localStorage.getItem('token');
  const token = authService.getToken();
  const isApiUrl = req.baseURL?.startsWith(
    process.env.REACT_APP_PUBLIC_SERVER_URL
  );

  if (token && isApiUrl) {
    req.headers.authorization = `Bearer ${token}`;
  }

  return req;
};
