import React from "react";
import { IoClose } from "react-icons/io5";
import { RiArrowLeftRightFill } from "react-icons/ri";
import { BsChevronRight } from "react-icons/bs";
import PaystackLogo from "../assets/PaystackLogo.png";
import Flutterwave from "../assets/Flutterwave.png";
import { useNavigate } from "react-router-dom";

function PaymentMethodPopover_Two({
  closer,
  handleFlutterPay,
  handleRedirect,
  changeMethod,
}) {
  let navigate = useNavigate();

  // handleInsufficientOption();

  return (
    <div className="mx-2 w-full max-w-[420px] rounded-xl bg-white pb-8 text-gray-500 shadow-lg xsm:mx-4">
      <div className="px-6 pt-6">
        <div className="flex items-center justify-between">
          <div className="block text-lg font-bold text-gray-600">
            Payment Method
          </div>
          <button className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
            <div>
              <IoClose onClick={() => closer()} className="h-5 w-5" />
            </div>
          </button>
        </div>
        <div className="mb-2 mt-2 flex justify-start text-sm">
          Choose your preferred payment method for checkout
        </div>
      </div>
      {/* <hr className="h-[0.5px] w-full bg-gray-300" />
      <div
        onClick={() => {
          closer();
        }}
        className="flex h-[75px] cursor-pointer items-center justify-between px-6 hover:bg-gray-100"
      >
        <div className="flex items-center space-x-3">
          <div className="text-center">
            <div className="flex items-center">
              <div className="z-20 h-6 w-6 rounded-full bg-[#EB001B]"></div>
              <div className="z-30 -m-[9px] h-6 w-6 rounded-full bg-[#F79E1B] opacity-75"></div>
            </div>
            <p className="text-[8px] font-bold">mastercard</p>
          </div>
          <div>
            <div className="flex justify-start text-sm font-bold">
              Pay with card
            </div>
            <div className="text-xs">Pay securely with your bank card</div>
          </div>
        </div>
        <div>
          <BsChevronRight className="h-5 w-5" />
        </div>
      </div> */}
      <hr className="h-[0.5px] w-full bg-gray-300" />
      <div
        onClick={() =>
          !changeMethod ? handleFlutterPay() : handleRedirect("flutter")
        }
        className="flex h-[75px] cursor-pointer items-center justify-between px-6 hover:bg-gray-100"
      >
        <div className="flex items-center space-x-4">
          <div className="">
            <img src={PaystackLogo} className="h-9 w-auto" alt="" />
          </div>
          <div>
            <div className="flex justify-start text-sm font-bold">
              Pay with Paystack
            </div>
            <div className="text-xs">Pay securely with Paystack</div>
          </div>
        </div>
        <div>
          <BsChevronRight className="h-5 w-5" />
        </div>
      </div>
      <hr className="h-[0.5px] w-full bg-gray-300" />
      <div
        onClick={() => {
          closer();
          navigate("/home/transactionReciept");
        }}
        className="flex h-[75px] cursor-pointer items-center justify-between px-6 hover:bg-gray-100"
      >
        <div className="flex items-center space-x-3">
          <div className="">
            <RiArrowLeftRightFill className="h-7 w-8 text-gray-600" />
          </div>
          <div>
            <div className="flex justify-start text-sm font-bold">
              Pay with Transfer
            </div>
            <div className="text-xs">Pay securely with transfer</div>
          </div>
        </div>
        <div>
          <BsChevronRight className="h-5 w-5" />
        </div>
      </div>
    </div>
  );
}

export default PaymentMethodPopover_Two;
