import React, { useRef, useState, useEffect, useContext } from "react";
import Logo from "../assets/logo.svg";
import Illustration from "../assets/Illustration.svg";
import { MdEmail } from "react-icons/md";
import { IoMdLock } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
import IsLoadingOne from "./IsLoading_One";
import toast from "react-hot-toast";
import FacebookImage from "../assets/facebookImage.svg";
import GoogleLogoImage from "../assets/googleLogoImage.svg";
import AppleImage from "../assets/appleImage.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { googleLogin } from "../core/lib/googleLogin";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import LogicContext from "../context/LogicContext";
import RxjsStoreContext from "../context/RxjsStoreContext";
import { authService } from "../services/authService";
import { useDispatch } from "react-redux";
import { setIsFromLogin } from "../store/slices/modalSlice";

function LogInPage() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const rxjsContext = useContext(RxjsStoreContext);
  const emailRef = useRef();
  const errRef = useRef();
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const logicContext = useContext(LogicContext);

  const InputType = visibility ? "text" : "password";
  const viewer = () => {
    setVisibility(!visibility);
  };

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isPhoneOrEmail = email;
    if (
      Array.from(isPhoneOrEmail)[0] !== "0" &&
      !isNaN(Array.from(isPhoneOrEmail)[0])
    ) {
      isPhoneOrEmail = "234" + isPhoneOrEmail.slice(0);
    }

    if (Array.from(isPhoneOrEmail)[0] === "0") {
      isPhoneOrEmail = "234" + isPhoneOrEmail.slice(1);
    }

    setIsLoading(true);
    const payload = {
      sql_ref_no: "2",
      user_name: isPhoneOrEmail,
      password: pwd.replace(/#/g, "%23"),
      option: "1",
    };

    authService
      .loginUser(payload)
      .then((apiResponse) => {
        const filteredData = logicContext.httpResponseManager(apiResponse.data);
        setIsLoading(false);

        if (filteredData && filteredData?.message_id === 4) {
          navigate("/verify-email");
        }

        if (filteredData?.access_token && filteredData?.message_id === 1) {
          authService.setToken(filteredData?.access_token);
          let tempUrl = localStorage.getItem("temp_url");
          if (tempUrl) {
            navigate(tempUrl);
            setTimeout(() => {
              localStorage.removeItem("temp_url");
            }, 2000);
          } else {
            navigate("/home/todo");
          }
        }

        if (filteredData?.access_token && filteredData?.message_id) {
          authService.setTokenUnVerified(filteredData?.access_token);
          let tempUrl = localStorage.getItem("temp_url");
          if (tempUrl) {
            navigate(tempUrl);
            setTimeout(() => {
              localStorage.removeItem("temp_url");
            }, 2000);
          } else {
            navigate("/home/todo");
          }
          return;
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const onSuccess = async (res) => {
    setIsLoading(true);
    var accessToken = gapi?.auth?.getToken();

    const tokens = await googleLogin(
      accessToken,
      res.profileObj.givenName,
      res.profileObj.familyName
    );

    if (tokens.access_token) {
      authService.setToken(tokens.access_token);
      localStorage.setItem("token", tokens.access_token);

      toast.success("Welcome to Grocedy");

      navigate("/home/todo");
    } else {
      setIsLoading(false);
      toast.error("Something Went Wrong!");
    }
  };

  const onFailure = (res) => {
    // console.log("Failed", res);
  };

  const facebookResponseHandler = async (response) => {
    if (response?.userID) {
      // setIsLoading(true);
      let nameSplit = response?.name.split(" ");
      const userData = {
        surname: nameSplit[0],
        fname: nameSplit[1],
        email: response?.email,
        ssi_api_code: response?.data_access_expiration_time?.toString(),
      };
      await authService
        .facebookLogin(userData)
        .then((data) => {
          if (data?.access_token) {
            authService.setToken(data?.access_token);
            let tempUrl = localStorage.getItem("temp_url");
            if (tempUrl) {
              navigate(tempUrl);
              setTimeout(() => {
                localStorage.removeItem("temp_url");
              }, 2000);
            } else {
              navigate("/home/todo");
            }
          }
          // setIsLoading(false);
        })
        .catch((err) => {
          // setIsLoading(false);
          alert("Facebook login failed, please try again later.");
        });
    } else {
      toast.error("Facebook login failed!");
    }
  };

  return (
    <>
      {isLoading ? (
        <div>
          <IsLoadingOne message="We are signing you in..." />
        </div>
      ) : (
        <div className="mx-auto w-full max-w-[1600px] md:grid md:grid-cols-2">
          <div className="mt-4 px-4 xsm:px-8 sm:px-8 md:col-span-1 md:px-16">
            <Link to="/">
              <img
                src={Logo}
                className="aspect-auto h-7 w-8 flex-shrink-0 cursor-pointer object-cover transition duration-300 hover:opacity-80 sm:h-11 sm:w-12"
                alt=""
              />
            </Link>
            <div className="mt-6 sm:px-8 md:px-0">
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <div className="text-2xl font-bold text-gray-600">Welcome!</div>
              <div className="mt-2 text-sm font-semibold text-gray-500">
                Enter your details to start using Grocedy
              </div>
            </div>
            <div className="mt-4 sm:px-8 md:px-0">
              <form onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="emailAddress"
                    className="ml-3 text-sm text-gray-500"
                  >
                    Email Address or Phone Number
                  </label>
                  <div className="relative rounded-md">
                    <div className="inset-y-0 pl-3">
                      <MdEmail className="absolute mt-2.5 h-5 w-5 text-gray-600" />
                    </div>
                    <input
                      type="text"
                      placeholder="Enter your email address or Phone Number"
                      id="emailAddress"
                      ref={emailRef}
                      autoComplete="off"
                      onChange={(e) => {
                        const limit = 50;

                        // 👇️ only take first N characters
                        setEmail(e.target.value.slice(0, limit));
                      }}
                      value={email}
                      required
                      className="mt-1 block w-full rounded-md border-gray-400 bg-gray-100 pl-10 text-base text-gray-600  placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="password"
                    className="ml-3 text-sm text-gray-500"
                  >
                    Password
                  </label>
                  <div className="relative flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <div className="absolute inset-y-0 flex justify-between pl-3">
                        <IoMdLock className="mt-2 h-6 w-6 text-gray-600" />
                      </div>
                      <input
                        type={InputType}
                        placeholder="Enter your password"
                        id="password"
                        onChange={(e) => {
                          const limit = 30;

                          // 👇️ only take first N characters
                          setPwd(e.target.value.slice(0, limit));
                        }}
                        value={pwd}
                        required
                        className="block w-full rounded-md border-gray-400 bg-gray-100 pl-10 pr-10 text-base text-gray-600 placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                    <div
                      onClick={viewer}
                      className="absolute right-3 top-[10px] flex-grow cursor-pointer text-sm font-semibold text-gravel-400 hover:text-olivine-500"
                    >
                      {InputType === "text" ? "Hide" : "View"}
                    </div>
                  </div>

                  <div className="mt-2 text-right text-sm font-semibold text-olivine-500 transition duration-200 hover:underline hover:underline-offset-2">
                    <Link to="/password">Forgot Password?</Link>
                  </div>
                </div>

                <button className="mt-4 w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-base font-semibold text-white transition-all duration-300 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-2 active:bg-olivine-600">
                  Login
                </button>
              </form>
            </div>
            <div className="mt-3 sm:px-8 md:px-0">
              <div className="text-center text-sm text-gray-500">
                Don’t have an account?
                <span className="hover:text-bold px-1 text-sm font-semibold text-olivine-500 transition duration-200 hover:underline hover:underline-offset-2">
                  <Link to="/register">Register</Link>
                </span>
              </div>
            </div>
            <div className="mt-1 sm:px-8 md:px-0">
              <div className="text-center text-sm text-gray-500">
                Have a Coporate Code?
                <span
                  onClick={() => dispatch(setIsFromLogin(true))}
                  className="hover:text-bold px-1 text-sm font-semibold text-olivine-500 transition duration-200 hover:underline hover:underline-offset-2"
                >
                  <Link to="/register">Register for Coporate</Link>
                </span>
              </div>
            </div>
            <div className="mt-6 flex flex-row items-center space-x-4 px-2">
              <div className="h-[0.5px] w-full flex-1 bg-gray-400" />
              <div className="text-sm font-bold text-gray-500">OR</div>
              <div className="h-[0.5px] w-full flex-1 bg-gray-400" />
            </div>
            <div className="mt-4 flex flex-col pb-20">
              <FacebookLogin
                appId={`${process.env.REACT_APP_FACEBOOK_CLIENT_ID}`}
                autoLoad={false}
                fields="name,email,picture"
                scope="public_profile,email,user_friends"
                callback={facebookResponseHandler}
                icon="fa-facebook"
                render={(renderProps) => (
                  <div
                    onClick={renderProps.onClick}
                    className="ring-gray- flex cursor-pointer items-center justify-center space-x-4 rounded-lg bg-[#1877F2] p-3 text-olivine-500 ring-1 ring-[#1877F2] transition-all duration-300 hover:bg-opacity-80"
                  >
                    <img src={FacebookImage} alt="icon" className="h-6 w-6" />
                    <p className="text-sm font-medium text-white">
                      Log in with Facebook
                    </p>
                  </div>
                )}
              />
              <GoogleLogin
                clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                buttonText="Login with Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={"single_host_origin"}
                render={(renderProps) => (
                  <div
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="mt-6 flex cursor-pointer items-center justify-center space-x-4 rounded-lg bg-white p-3 text-olivine-500 ring-1 ring-gray-300 transition-all duration-300 hover:bg-gray-100 active:bg-gray-200"
                  >
                    <img src={GoogleLogoImage} alt="icon" className="h-6 w-6" />
                    <p className="text-sm font-medium text-gray-600">
                      Log in with Google
                    </p>
                  </div>
                )}
              />
              <div className="mt-6 flex cursor-pointer items-center justify-center space-x-4 rounded-lg bg-black p-3 text-olivine-500 ring-1 ring-black transition-all duration-300 hover:bg-opacity-80">
                <img src={AppleImage} alt="icon" className="h-6 w-6" />
                <p className="text-sm font-medium text-white">
                  Log in with Apple
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-1 hidden bg-olivine-500 md:grid ">
            <div className="mx-auto grid h-[384px] w-[359px] justify-items-center pt-[130px]">
              <img src={Illustration} className="h-72 w-80" alt="" />
              <div className="text-3xl font-bold  text-white">
                Welcome to Grocedy
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LogInPage;
