import React, { useEffect, useState, useContext } from "react";
import { Modal, Box } from "@mui/material";
import { Link } from "react-router-dom";
import NewPassword from "./NewPassword";
import { toast } from "react-hot-toast";
import EmailOTP from "../changePin/emailMethod/EmailOTP";
import { authService } from "../../../../services/authService";
import LogicContext from "../../../../context/LogicContext";
import CircularProgress from "@mui/material/CircularProgress";
import { Switch } from "@headlessui/react";

const processStepper = { ENTER_OTP: "ENTER_OTP", NEW_PASSWORD: "NEW_PASSWORD" };

function ChangePassword() {
  const [activeStep, setActiveStep] = useState(processStepper.ENTER_OTP);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [usePhone, setUsePhone] = useState(false);
  const logicContext = useContext(LogicContext);

  useEffect(() => {
    setActiveStep(processStepper.ENTER_OTP);
  }, []);

  function closeModal() {
    setOpen(false);
    setActiveStep(processStepper.ENTER_OTP);
  }

  function submitEmail(email) {
    const payload = {
      sql_ref_no: "6",
      user_email_address: email,
      option: "2",
    };
    if (usePhone) {
      //  For phone number
      setIsLoading((prev) => !prev);
      authService
        .requestMobileOTPtoken({ user_name: authService.getCurrentUserName() })
        .then((apiResponse) => {
          setIsLoading((prev) => !prev);
          const filteredData = logicContext.httpResponseManager(
            apiResponse?.data?.result_status[0]
          );

          if (filteredData?.message_id === 1) {
            toast.success(
              `Verification Code has been sent to your Phone Number.`
            );
            setActiveStep(processStepper.ENTER_OTP);
            setOpen(true);
          }
        });
    } else {
      // For email
      setIsLoading((prev) => !prev);
      authService
        .forgotPassword(payload)
        .then((apiResponse) => {
          setIsLoading((prev) => !prev);
          const filteredData = logicContext.httpResponseManager(
            apiResponse?.data?.result_status[0]
          );

          if (filteredData?.message_id === 1) {
            toast.success(`Verification Code has been sent to your Email.`);
            setActiveStep(processStepper.ENTER_OTP);
            setOpen(true);
          }
        })
        .catch((err) => {
          setIsLoading((prev) => !prev);
        });
    }
  }

  function verifyOtp(otp) {
    let payload = {
      sql_ref_no: "7",
      user_id: authService.getCurrentUserID(),
      verification_code: otp,
      option: "1",
    };

    setIsLoading((prev) => !prev);
    authService
      .emailVerification(payload)
      .then((res) => {
        const filteredData = logicContext.httpResponseManager(res?.data);
        setIsLoading((prev) => !prev);
        if (filteredData.message_id === 1) {
          setActiveStep(processStepper.NEW_PASSWORD);
        }
      })
      .catch((err) => {
        setIsLoading((prev) => !prev);
      });
  }

  function submitNewPassword(newPassword) {
    setIsLoading((prev) => !prev);
    const data = {
      sql_ref_no: "8",
      email_address: authService?.getCurrentUserEmail(),
      verification_code: authService.getCurrentUserVerificationCode(),
      new_password: newPassword,
      option: "1",
    };
    authService.newPassword(data).then((res) => {
      setIsLoading((prev) => !prev);
      const filteredData = logicContext.httpResponseManager(res?.data);
      if (filteredData?.message_id === 1) {
        closeModal();
      }
    });
  }

  return (
    <div className="mt-8 pb-16">
      <div className="hidden w-full md:inline md:w-auto">
        <div className="flex items-center justify-between rounded-md bg-[#4F4F4F] px-4 py-3 md:bg-white md:px-0">
          <div className="text-lg font-semibold text-white  md:text-xl md:text-gray-700">
            Change Password
          </div>
          <Link to="/home/wallet">
            <button className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-white p-8 px-6 py-2.5 text-sm font-semibold text-olivine-500 transition duration-300 ease-in-out hover:ring-[0.5px] hover:ring-olivine-500 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100">
              <div className="text-sm font-semibold text-olivine-500">
                Fund your Account
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div className="mb-5 flex items-center space-x-2">
        <Switch
          checked={usePhone}
          onChange={() => setUsePhone(!usePhone)}
          className={`${usePhone ? "bg-olivine-500" : "bg-olivine-500"}
          relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${usePhone ? "translate-x-5" : "translate-x-0"}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
        {usePhone ? (
          <p className="text-sm font-medium text-gray-600">Use Email</p>
        ) : (
          <p className="text-sm font-medium text-gray-600">Use Phone Number</p>
        )}
      </div>
      {/* Start Logic */}

      {!usePhone && (
        <>
          <h4 className="text-sm font-semibold text-gray-500">
            An OTP will be sent to your Email.
          </h4>
          <div className="mt-6 max-w-lg">
            <label htmlFor="yourEmail" className="ml-2 text-sm text-gray-600">
              Email
            </label>
            <div className="flex-grow">
              <input
                type="email"
                placeholder="Input your email address"
                value={authService.getCurrentUserEmail()}
                readOnly={true}
                className="block w-full rounded-md border-gray-300 bg-white pr-8 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              />
            </div>
            <div className="pb-80">
              <div className="mt-8 max-w-sm sm:max-w-xs">
                <button
                  disabled={isLoading}
                  onClick={() => {
                    submitEmail(authService.getCurrentUserEmail());
                  }}
                  className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-olivine-500 py-3 text-sm text-white transition-all  duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
                >
                  {isLoading === true ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Send Verification code "
                  )}
                </button>
              </div>
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className="mt-28 px-2 xsm:px-4">
                  <Box className="mx-auto max-w-lg overflow-hidden rounded-md bg-white ">
                    {activeStep === processStepper.ENTER_OTP && (
                      <EmailOTP
                        closeModal={closeModal}
                        verifyOtp={verifyOtp}
                        isLoading={isLoading}
                        reSendOtp={submitEmail}
                        channel={
                          usePhone
                            ? authService?.getCurrentUserName()
                            : authService?.getCurrentUserEmail()
                        }
                      />
                    )}

                    {activeStep === processStepper.NEW_PASSWORD && (
                      <NewPassword
                        closeModal={closeModal}
                        submitNewPassword={submitNewPassword}
                        isLoading={isLoading}
                      />
                    )}
                  </Box>
                </div>
              </Modal>
            </div>
          </div>
        </>
      )}
      {usePhone && (
        <>
          <h4 className="text-sm font-semibold text-gray-500">
            An OTP will be sent to your Phone number.
          </h4>
          <div className="mt-6 max-w-lg">
            <label htmlFor="yourEmail" className="ml-2 text-sm text-gray-600">
              Phone
            </label>
            <div className="flex-grow">
              <input
                type="email"
                placeholder="Input your email address"
                value={authService.getCurrentUserName()}
                readOnly={true}
                className="block w-full rounded-md border-gray-300 bg-white pr-8 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              />
            </div>
            <div className="pb-80">
              <div className="mt-8 max-w-sm sm:max-w-xs">
                <button
                  disabled={isLoading}
                  onClick={() => {
                    submitEmail(authService.getCurrentUserName());
                  }}
                  className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-olivine-500 py-3 text-sm text-white transition-all  duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
                >
                  {isLoading === true ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Send Verification code "
                  )}
                </button>
              </div>
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className="mt-28 px-2 xsm:px-4">
                  <Box className="mx-auto max-w-lg overflow-hidden rounded-md bg-white ">
                    {activeStep === processStepper.ENTER_OTP && (
                      <EmailOTP
                        closeModal={closeModal}
                        verifyOtp={verifyOtp}
                        isLoading={isLoading}
                        reSendOtp={submitEmail}
                        channel={
                          usePhone
                            ? authService.getCurrentUserName()
                            : authService.getCurrentUserEmail()
                        }
                      />
                    )}

                    {activeStep === processStepper.NEW_PASSWORD && (
                      <NewPassword
                        closeModal={closeModal}
                        submitNewPassword={submitNewPassword}
                        isLoading={isLoading}
                      />
                    )}
                  </Box>
                </div>
              </Modal>
            </div>
          </div>
        </>
      )}
      {/* end logic */}
    </div>
  );
}

export default ChangePassword;
