import baseAxios from "../core/api/axios/baseAxios";
import jwt_decode from "jwt-decode";

export const authService = {
  setToken,
  getToken,
  logOut,
  isLogin,
  getCurrentAuthToken,
  getCurrentCustomerId,
  getCurrentUserReferralCode,
  getCurrentUserCustomerId,
  getCurrentUserEmail,
  getCurrentUserID,
  getCurrentUserPhone,
  getCurrentUserVerificationCode,
  getCurrentUserZohoCustomerId,
  getComputedStyle,
  getSelection,
  registerUser,
  loginUser,
  forgotPassword,
  newPassword,
  emailVerification,
  emailVerificationResend,
  bvnVerify,
  facebookLogin,
  setTokenUnVerified,
  getTokenUnVerified,
  getPayloadUnverified,
  verifyOTPToken,
  getTempUserVerificationCode,
  getCurrentUserName,
  getUserFullName,
  getUnverifiedUserID,
  getUnverifiedUserEmail,
  requestMobileOTPtoken,
  getApprovedStatus,
  getApprovedStatusId,
  getAccountType,
  getCustomerCorporateId,
  getCustomerCorporateAdminStatusId,
  checkCorporateKey,
  getRejectionReason,
  getApprovedBannerStatusId,
};

function setToken(token) {
  localStorage.setItem("token", token);
}

function getToken() {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  } else {
    return null;
  }
}

function setTokenUnVerified(token) {
  localStorage.setItem("token_unVerified", token);
}

function getTokenUnVerified() {
  const token = localStorage.getItem("token_unVerified");
  if (token) {
    return token;
  } else {
    return null;
  }
}

async function getUnverifiedUserID() {
  const token = await getTokenUnVerified();
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.user_id;
  } else {
    return null;
  }
}

async function getUnverifiedUserEmail() {
  const token = await getTokenUnVerified();
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.email;
  } else {
    return null;
  }
}

async function getPayloadUnverified() {
  const token = await getTokenUnVerified();
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode;
  } else {
    return null;
  }
}

function logOut() {
  localStorage.removeItem("token");
  localStorage.removeItem("token_unVerified");
  localStorage.removeItem("recurr_type");
  localStorage.clear();
}

//  checking if the current id has valid token
function isLogin() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    if (tokenDecode?.exp) {
      const isLogin = Date.now() < tokenDecode.exp * 1000;
      return isLogin;
    } else {
      return false;
    }
  }
}

function getCurrentUserZohoCustomerId() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.zoho_customer_id;
  } else {
    return null;
  }
}

function getCustomerCorporateId() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);

    return tokenDecode?.corporate_id;
  } else {
    return null;
  }
}

function getCustomerCorporateAdminStatusId() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);

    return tokenDecode?.customer_corporate_admin_status_id;
  } else {
    return null;
  }
}

function getUserFullName() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.user_full_name;
  } else {
    return null;
  }
}

function getCurrentUserEmail() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.email;
  } else {
    return null;
  }
}

function getApprovedStatus() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);

    if (tokenDecode?.customer_type_name === "Corporate") {
      return tokenDecode?.approve_status_name;
    } else {
      return tokenDecode?.approve_status_name;
    }
  } else {
    return null;
  }
}

function getApprovedStatusId() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);

    if (tokenDecode?.customer_type_name === "Corporate") {
      return tokenDecode?.corporate_status_id;
    } else {
      return tokenDecode?.corporate_status_id;
    }
  } else {
    return null;
  }
}

function getApprovedBannerStatusId() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);

    if (tokenDecode?.customer_type_name === "Corporate") {
      return tokenDecode?.corporate_approve_banner_id;
    } else {
      return tokenDecode?.corporate_approve_banner_id;
    }
  } else {
    return null;
  }
}

function getRejectionReason() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);

    if (tokenDecode?.customer_type_name === "Corporate") {
      return tokenDecode?.corporate_customer_rejected;
    } else {
      return tokenDecode?.corporate_customer_rejected;
    }
  } else {
    return null;
  }
}

function getAccountType() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.customer_type_name;
  } else {
    return null;
  }
}

function getCurrentAuthToken() {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  } else {
    return null;
  }
}

function getCurrentCustomerId() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.customer_id;
  } else {
    return null;
  }
}

function getCurrentUserReferralCode() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.referral_code;
  } else {
    return null;
  }
}

function getCurrentUserPhone() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.user_phone;
  } else {
    return null;
  }
}

function getCurrentUserName() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.user_phone;
  } else {
    return null;
  }
}

function getCurrentUserCustomerId() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.customer_id;
  } else {
    return null;
  }
}

function getCurrentUserID() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.user_id;
  } else {
    return null;
  }
}

function getCurrentUserVerificationCode() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.verification_code;
  } else {
    return null;
  }
}

async function getTempUserVerificationCode() {
  const token = await getTokenUnVerified();
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode?.verification_code;
  } else {
    return null;
  }
}

async function registerUser(data) {
  const response = await baseAxios.post("auth/signup", data);
  return response;
}

function loginUser(data) {
  return baseAxios.post("auth/signin", data);
}

function checkCorporateKey(data) {
  return baseAxios.post("auth/signup-check-corporate-key", data);
}

function emailVerification(data) {
  return baseAxios.post(`auth/emailVerify`, data);
}

function emailVerificationResend(data) {
  return baseAxios.post(`auth/emailVerify/resend`, data);
}

function forgotPassword(data) {
  return baseAxios.post(`auth/forgotPassword`, data);
}

function newPassword(data) {
  return baseAxios.post(`auth/newPassword`, data);
}

function bvnVerify(data) {
  return baseAxios.post(`auth/bvnVerify`, data);
}

function facebookLogin(data) {
  return baseAxios.post(`auth/facebook`, data);
}

function verifyOTPToken(data) {
  return baseAxios.post("master-data/verifyPhonenumber", data);
}

function requestMobileOTPtoken(data) {
  return baseAxios.post("auth/request-mobile-otp", data);
}
