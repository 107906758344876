import React from "react";

import Footer from "../../Footer";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import CreateButchersContent from "./CreateButchersContent";

function CreateButchersPage() {
  return (
    <div className="">
      <SignedInNavbarImproved />
      <CreateButchersContent />

      <Footer />
    </div>
  );
}

export default CreateButchersPage;
