import { createSlice } from "@reduxjs/toolkit";
import {
  cartItemDelete,
  cartItemsDelete,
  cartItemUpdate,
  checkout,
  checkoutBasket,
  createSubscriptionNote,
  deductDelivery,
  getAbandonCart,
  getPaymentNotification,
  getPaymentTransaction,
  getSingleSalesorder,
  getSingleSubscription,
  giftCheckoutBasket,
  productPurchaseGet,
  productPurchaseSet,
  stockCheck,
  verifyCoupon,
} from "../actions";

const initialState = {
  productPurchase: "",
  cart: "",
  itemDelete: "",
  itemsDelete: "",
  itemUpdate: "",
  cartCheckout: "",
  coupon: "",
  basketCheckout: "",
  subscriptionNote: "",
  deliveryDeducter: "",
  giftCustomBasket: "",
  paymentTransact: "",
  paymentNotify: "",
  cartAbandon: "",
  singleSalesorder: "",
  singleSubscription: "",
  stockCheck: "",
};

const CartSlice = createSlice({
  name: "CartData",
  initialState,
  reducers: {
    resetCart(state) {
      state.productPurchase = null;
      state.cart = null;
      state.itemDelete = null;
      state.itemUpdate = null;
      state.cartCheckout = null;
      state.coupon = null;
      state.basketCheckout = null;
      state.subscriptionNote = null;
      state.itemsDelete = null;
      state.deliveryDeducter = null;
      state.giftCustomBasket = null;
      state.paymentTransact = null;
      state.paymentNotify = null;
      state.cartAbandon = null;
      state.singleSalesorder = null;
      state.singleSubscription = null;
      state.stockCheck = null;
    },
    resetCoupon(state) {
      state.coupon = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(productPurchaseSet.fulfilled, (state, { payload }) => {
      state.productPurchase = payload;
    });

    builder.addCase(productPurchaseGet.fulfilled, (state, { payload }) => {
      state.cart = payload;
    });

    builder.addCase(cartItemDelete.fulfilled, (state, { payload }) => {
      state.itemDelete = payload;
    });

    builder.addCase(cartItemsDelete.fulfilled, (state, { payload }) => {
      state.itemsDelete = payload;
    });

    builder.addCase(cartItemUpdate.fulfilled, (state, { payload }) => {
      state.itemUpdate = payload;
    });

    builder.addCase(checkout.fulfilled, (state, { payload }) => {
      state.cartCheckout = payload;
    });

    builder.addCase(deductDelivery.fulfilled, (state, { payload }) => {
      state.deliveryDeducter = payload;
    });

    builder.addCase(createSubscriptionNote.fulfilled, (state, { payload }) => {
      state.subscriptionNote = payload;
    });

    builder.addCase(checkoutBasket.fulfilled, (state, { payload }) => {
      state.basketCheckout = payload;
    });

    builder.addCase(verifyCoupon.fulfilled, (state, { payload }) => {
      state.coupon = payload;
    });

    builder.addCase(giftCheckoutBasket.fulfilled, (state, { payload }) => {
      state.giftCustomBasket = payload;
    });

    builder.addCase(getPaymentTransaction.fulfilled, (state, { payload }) => {
      state.paymentTransact = payload;
    });

    builder.addCase(getPaymentNotification.fulfilled, (state, { payload }) => {
      state.paymentNotify = payload;
    });

    builder.addCase(getAbandonCart.fulfilled, (state, { payload }) => {
      state.cartAbandon = payload;
    });

    builder.addCase(getSingleSalesorder.fulfilled, (state, { payload }) => {
      state.singleSalesorder = payload;
    });

    builder.addCase(getSingleSubscription.fulfilled, (state, { payload }) => {
      state.singleSubscription = payload;
    });

    builder.addCase(stockCheck.fulfilled, (state, { payload }) => {
      state.stockCheck = payload;
    });
  },
});

const { reducer, actions } = CartSlice;

export const { resetCart, resetCoupon } = actions;
export default reducer;
