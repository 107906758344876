import { createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../../services/userService";
import { startLoad, stopLoad } from "../slices/appSlice";
import { handleError } from "../slices/errorSlice";

export const createAccountNumber = createAsyncThunk(
  "post/account",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await UserService.createAccountNumber(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const statusNotification = createAsyncThunk(
  "post/settlementStatus",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await UserService.getSettlementStatus(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const transferStatus = createAsyncThunk(
  "get/getTransferStatus",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await UserService.getTransferStatus(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getWalletBalance = createAsyncThunk(
  "get/balance",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await UserService.getWalletBalance(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getWalletBalanceUpdateApi = createAsyncThunk(
  "get/balance/update",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await UserService.getWalletBalanceUpdate(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const userStatsGet = createAsyncThunk(
  "get/stats",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await UserService.getUserStats(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const userAdvertsGet = createAsyncThunk(
  "get/adverts",
  async (_, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await UserService.getUserAdverts();

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
