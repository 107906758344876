import { useNavigate } from "react-router-dom";
import CancelPlanPopover from "./CancelPlanPopover";
import ReactivatePlanPopover from "./ReactivatePlanPopover";

const UpgradingModal = ({ onClose, selected, handleClose, product_id }) => {
  const navigate = useNavigate();
  let selectedModal;

  const handleOnClose = (e) => {
    if (e.target.id === "container") onClose();
  };

  switch (selected) {
    case "cancelPlan":
      selectedModal = (
        <CancelPlanPopover onClose={onClose} handleClose={handleClose} />
      );
      break;
    case "reactivatePlan":
      selectedModal = (
        <ReactivatePlanPopover onClose={onClose} handleClose={handleClose} />
      );
      break;
    case "upgradePlan":
      // selectedModal = <UpgradePlansPage />;
      navigate(`/home/upgradePlan/${product_id}`);
      break;
    case "downgradePlan":
      navigate(`/home/downgradePlan/${product_id}`);
      break;
    default:
      return;
  }

  return (
    <div
      onClick={handleOnClose}
      className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm"
      id="container"
    >
      {selectedModal}
    </div>
  );
};

export default UpgradingModal;
