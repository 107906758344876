import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { planCreate, transactionInitialize } from "../store/actions";
import { authService } from "../services/authService";
import { CircularProgress } from "@mui/material";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { generateId } from "../core/helpers";
import { toast } from "react-hot-toast";

function SavingsSettingsPopover({ closer }) {
  const dispatch = useDispatch();

  const [plan, setPlan] = useState("daily");
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.masterData);
  const userEmail = authService.getCurrentUserEmail();
  const userFullName = authService.getUserFullName();
  const phone_number =
    userInfo &&
    userInfo?.customer_info?.length &&
    userInfo?.customer_info[0]?.customer_mobile_no;

  const handleChange = (event) => {
    const result = event.target.value.replace(/[^0-9|,]/gi, "");

    setInput(result);
  };

  const initializeTransaction = (planCode) => {
    try {
      const data = {
        email: userEmail,
        amount: input.replace(/,/g, ""),
        plan: planCode,
      };

      dispatch(transactionInitialize(data))
        .unwrap()
        .then((res) => {
          window.location.replace(res?.data?.authorization_url);
        })
        .catch((err) => {
          return err;
        });
    } catch (err) {
      return err;
    }
  };

  const createPaystackPlan = () => {
    try {
      setIsLoading(true);

      if (!plan) {
        return toast.error("Please select a duration!");
      }

      const toastId = toast.loading("Funding your wallet.. Please wait!!");

      const data = {
        amount: input.replace(/,/g, ""),
        plan_name: `Fund Wallet (Recurring) ${userFullName}`,
        interval: plan,
      };

      let recuring_type = 1;

      switch (plan) {
        case "daily":
          recuring_type = 1;
          break;
        case "weekly":
          recuring_type = 2;
          break;
        case "monthly":
          recuring_type = 3;
          break;
        default:
          recuring_type = 1;
      }

      localStorage.setItem("recurr_type", recuring_type);

      return dispatch(planCreate(data))
        .unwrap()
        .then((res) => {
          if (!res.plan_code) {
            return toast.error("error", { id: toastId });
          }

          initializeTransaction(res.plan_code);
          // setPaymentPlan(res.plan_code);

          toast.success("successful", { id: toastId });
        })
        .catch((err) => {
          return err;
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold text-gray-600">
          Active Monthly Settings
        </div>
        <button
          onClick={() => closer()}
          className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="mt-4 flex justify-start text-sm font-semibold text-gravel-500">
        Confirm Transaction
      </div>
      <div>
        <form>
          <div className="mt-4">
            <div className="flex justify-start">
              <label htmlFor="title" className="ml-2 text-xs font-semibold">
                Amount
              </label>
            </div>
            <div className="mt-3">
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    value={input}
                    onChange={handleChange}
                    type="text"
                    placeholder="Example 9,000"
                    className="block w-full rounded-md border-gray-500 bg-white pl-4 pr-10 font-bold text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <RadioGroup value={plan} onChange={setPlan}>
              <div className="flex justify-start">
                <RadioGroup.Label className="text-xs text-gray-500 xsm:text-sm">
                  How often do you want to to be deducted?
                </RadioGroup.Label>
              </div>
              <div className="mt-6 flex items-center space-x-4 text-sm">
                <RadioGroup.Option value="daily">
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? "cursor-pointer rounded-full bg-olivine-500 px-4 py-1 text-white ring-1 ring-gray-300 sm:px-6"
                          : "cursor-pointer rounded-full bg-[#F6FBEE] px-4 py-1 text-gray-500 ring-1 ring-gray-200 sm:px-6"
                      }
                    >
                      Daily
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="weekly">
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? "cursor-pointer rounded-full bg-olivine-500 px-3.5 py-1 text-white ring-1 ring-gray-300 sm:px-6"
                          : "cursor-pointer rounded-full bg-[#F6FBEE] px-3.5 py-1 text-gray-500 ring-1 ring-gray-200 sm:px-6"
                      }
                    >
                      Weekly
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="monthly">
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? "cursor-pointer rounded-full bg-olivine-500 px-3.5 py-1 text-white ring-1 ring-gray-300 sm:px-6"
                          : "cursor-pointer rounded-full bg-[#F6FBEE] px-3.5 py-1 text-gray-500 ring-1 ring-gray-200 sm:px-6"
                      }
                    >
                      Monthly
                    </span>
                  )}
                </RadioGroup.Option>
              </div>
            </RadioGroup>
          </div>
          <div className="mt-10">
            <div className="flex justify-between text-center font-semibold tracking-widest transition-all duration-300">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  closer();
                }}
                className="rounded-md border-[1px] border-olivine-400 px-6 py-3 text-sm text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-600 xsm:px-8"
              >
                BACK
              </button>
              {!isLoading ? (
                <button
                  disabled={false}
                  // onClick={(e) => handleSubmit(e)}
                  onClick={(e) => {
                    createPaystackPlan();
                  }}
                  className="rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                >
                  Proceed to pay
                </button>
              ) : (
                <button
                  disabled={true}
                  className="rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                >
                  <CircularProgress size={20} color="inherit" />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SavingsSettingsPopover;
