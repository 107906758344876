import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";
import { CgClose } from "react-icons/cg";
import { FaCamera } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentFileId,
  getUserProfile,
  getUserProfileSet,
  todoListStatus,
} from "../../store/actions";
import IsLoadingOne from "../IsLoading_One";
import moment from "moment";
import { init_data, profileDescriptionPopValidator } from "../../core/helpers";
import { authService } from "../../services/authService";

const ProfileDetailsModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const startRef = useRef();
  const [fetchedUserProfile, setFectcheUserProfile] = useState("");
  const { userProfile, documentFileId, userInfo } = useSelector(
    (state) => state.masterData
  );
  const [demo, setDemo] = useState();
  const [uploadedFileId, setUploadedFileId] = useState("");
  const [uploadedFileResponse, setUploadedFileResponse] = useState("");
  const [fetchedDocumentFileId, setFectchedDocumentFileId] = useState("");

  const [title, setTitle] = useState(
    (userInfo &&
      userInfo?.customer_info?.length &&
      userInfo?.customer_info[0]?.salutation_id?.toString()) ||
      ""
  );
  const [gender, setGender] = useState(
    (userInfo &&
      userInfo?.customer_info?.length &&
      userInfo?.customer_info[0]?.gender_id?.toString()) ||
      ""
  );
  const [fname, setFname] = useState(
    (userInfo &&
      userInfo?.customer_info?.length &&
      userInfo?.customer_info[0]?.customer_first_name) ||
      ""
  );
  const [lname, setLname] = useState(
    (userInfo &&
      userInfo?.customer_info?.length &&
      userInfo?.customer_info[0]?.customer_last_name) ||
      ""
  );
  const [dob, setDob] = useState(
    (userInfo &&
      userInfo?.customer_info?.length &&
      userInfo?.customer_info[0]?.date_of_birth !== null &&
      moment(userInfo?.customer_info[0]?.date_of_birth).format("LL")) ||
      ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [pNumber, setPnumber] = useState(
    (userInfo &&
      userInfo?.customer_info?.length &&
      userInfo?.customer_info[0]?.customer_mobile_no) ||
      ""
  );
  // const [email, setEmail] = useState(
  //   (userInfo &&
  //     userInfo?.customer_info?.length &&
  //     userInfo?.customer_info[0]?.customer_email_address) ||
  //     ""
  // );

  const [errorMsg, setErrorMsg] = useState({
    fname: "",
    lname: "",
    pNumber: "",
  });

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();
  const token = authService.getToken();
  const user_email = authService.getCurrentUserEmail();

  useEffect(() => {
    if (!userProfile) {
      const data = {
        sql_ref_no: "12",
        user_id: userId?.toString(),
        option: "21",
      };

      dispatch(getUserProfile(data));
    }

    if (userProfile) setFectcheUserProfile(userProfile);
  }, [dispatch, userProfile, verification_code, userId, customerId]);

  useEffect(() => {
    if (!documentFileId) {
      const data = {
        sql_ref_no: "12",
        user_id: userId?.toString(),
        option: "20",
      };

      dispatch(getDocumentFileId(data));
    }

    if (documentFileId) setFectchedDocumentFileId(documentFileId);
  }, [dispatch, documentFileId, verification_code, userId]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    onDrop: (acceptedFiles) => {
      const mappedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setDemo(mappedFiles);
    },
  });

  const uploadFile = useCallback(async () => {
    // setIsLoading(true);
    setUploadedFileId("");

    let fileId = "";

    switch (demo[0].type.split("/").pop()) {
      case "jpeg":
        fileId = fetchedDocumentFileId?.File_Extension[1]?.format_id;
        break;
      case "jpg":
        fileId = fetchedDocumentFileId?.File_Extension[1]?.format_id;
        break;
      case "png":
        fileId = fetchedDocumentFileId?.File_Extension[0]?.format_id;
        break;
      default:
        return;
    }

    const formData = new FormData();

    formData.append("file", demo[0]);

    const requestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_PUBLIC_SERVER_URL}master-data/imageUpload`,
        formData,
        requestConfig
      )
      .then((res) => {
        toast.success("Profile Image Uploaded");
        setUploadedFileId(fileId);
        setUploadedFileResponse(res.data);
        setDemo(undefined);
        // setIsLoading(false);
      })
      .catch(() => {
        setDemo(undefined);
        setUploadedFileId("");
        setUploadedFileResponse("");
        // setIsLoading(false);
        toast.error("Something Went Wrong!");
      });
  }, [demo, token, fetchedDocumentFileId?.File_Extension]);

  useEffect(() => {
    if (demo) {
      uploadFile();
    }
  }, [demo, uploadFile]);

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const payloadValuesError = profileDescriptionPopValidator(
    fname,
    lname,
    pNumber
  );

  const handleSubmit = (e) => {
    try {
      e.preventDefault();

      if (Object.keys(payloadValuesError).length) {
        return setErrorMsg({
          ...errorMsg,
          fname: payloadValuesError.fname,
          lname: payloadValuesError.lname,
          pNumber: payloadValuesError.pNumber,
        });
      }

      const toastId = toast.loading("Submitting your Profile details...");

      const data = {
        sql_ref_no: "10",
        user_id: userId?.toString(),
        verification_code,
        salutation_id: title,
        gender_id: gender,
        customer_first_name: fname,
        customer_last_name: lname,
        customer_email_address: user_email,
        mobile_country_code: "234",
        customer_id: customerId?.toString(),
        customer_mobile_no: pNumber,
        date_Of_birth: dob,
        // email,
        customer_image_id: uploadedFileId ? "1" : "2",
        Customer_Image_Format_Id: uploadedFileId
          ? uploadedFileId.toString()
          : "",
        Customer_Image_URL: uploadedFileResponse.Location,
        option: "13",
      };

      dispatch(getUserProfileSet(data))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            return toast.error("Profile details could not be submitted!", {
              id: toastId,
            });
          }

          setTitle("");
          setGender("");
          setFname("");
          setLname("");
          setDob("");
          setUploadedFileId("");
          setUploadedFileResponse("");
          setPnumber("");
          setIsLoading(true);
          dispatch(todoListStatus(init_data));
          const data = {
            sql_ref_no: "12",
            user_id: userId?.toString(),
            option: "21",
          };

          dispatch(getUserProfile(data));
          window.location.reload();
          toast.success("Profile Details Submitted", { id: toastId });
          onClose();
        })
        .catch((err) => {
          setTitle("");
          setGender("");
          setFname("");
          setLname("");
          setDob("");
          setPnumber("");
          setUploadedFileId("");
          setUploadedFileResponse("");
          setIsLoading(false);
          toast.error("Profile details could not be submitted!", {
            id: toastId,
          });

          return err;
        });
    } catch (err) {
      return err;
    }
  };

  const handleOnClose = (e) => {
    onClose();
  };

  return (
    <>
      {isLoading ? (
        <IsLoadingOne message="loading" />
      ) : (
        <div className="mx-auto h-full w-full max-w-[420px] px-2 pb-8 pt-4 xsm:px-3">
          <div className="w-full rounded-2xl bg-white px-4 py-4 sm:px-6">
            <div className="flex w-full items-center justify-between">
              <div className="font-semibold text-gray-700 xsm:text-lg sm:text-xl">
                Edit Your Profile Details
              </div>
              <button
                className="rounded-full p-1 hover:bg-gray-200 active:bg-gray-200"
                onClick={handleOnClose}
              >
                <CgClose className="h-5 w-5 text-gravel-500" />
              </button>
            </div>
            <div className="text-xs">
              Please ensure that you entered your correct details
            </div>
            <div
              htmlFor="demo"
              {...getRootProps()}
              className="mt-3 grid h-9 w-9 rounded-full bg-gray-200 hover:bg-gray-300"
            >
              <input {...getInputProps()} />
              <button className="rounded-full p-2 active:bg-gray-200">
                {uploadedFileResponse && uploadedFileResponse?.Location ? (
                  <img
                    src={uploadedFileResponse?.Location}
                    alt="uploadedImg"
                    className="h-5 w-5 text-gravel-500"
                  />
                ) : (
                  <FaCamera className="h-5 w-5 text-gravel-500" type="file" />
                )}
              </button>
            </div>
            {uploadedFileResponse && uploadedFileResponse?.key ? (
              <p className="pt-1 text-sm">
                {uploadedFileResponse?.key.split("/")[1]}
              </p>
            ) : (
              ""
            )}
            <div>
              <form>
                <div className="mt-2 flex items-start space-x-2 xsm:space-x-3">
                  <div className="flex-1">
                    <label
                      htmlFor="title"
                      className="ml-3 text-xs text-gray-500"
                    >
                      Title
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <select
                          onChange={handleChangeTitle}
                          type="text"
                          className="block w-full cursor-pointer rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm focus:border-olivine-500 focus:ring-olivine-500"
                        >
                          <option>
                            {userInfo && userInfo?.customer_info?.length
                              ? userInfo?.customer_info[0].salutation_name
                              : "select"}
                          </option>
                          {fetchedUserProfile &&
                            fetchedUserProfile?.Salutation?.slice(1).map(
                              (salute) => (
                                <option
                                  key={salute.salutation_id}
                                  value={salute.salutation_id}
                                >
                                  {salute.salutation_name}
                                </option>
                              )
                            )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="title"
                      className="ml-3 text-xs text-gray-500"
                    >
                      Gender
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <select
                          onChange={handleChangeGender}
                          type="text"
                          className="block w-full cursor-pointer rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm focus:border-olivine-500 focus:ring-olivine-500"
                        >
                          <option>
                            {userInfo && userInfo?.customer_info?.length
                              ? userInfo?.customer_info[0].gender_name
                              : "select"}
                          </option>
                          {fetchedUserProfile &&
                            fetchedUserProfile?.Gender?.map((gend) => (
                              <option
                                key={gend.gender_id}
                                value={gend.gender_id}
                              >
                                {gend.gender_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="firstName"
                    className="ml-3 text-xs text-gray-500"
                  >
                    First Name <span className="text-rose-600"> * </span>
                  </label>
                  <div className="relative flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        onChange={(e) => {
                          const limit = 50;

                          // 👇️ only take first N characters
                          setFname(e.target.value.slice(0, limit));
                        }}
                        value={fname}
                        type="text"
                        placeholder="Enter Your First Name"
                        className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      {errorMsg?.fname ? (
                        <span className="ml-3 text-xs text-red-500">
                          {errorMsg?.fname}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="lastName"
                    className="ml-3 text-xs text-gray-500"
                  >
                    Last Name <span className="text-rose-600"> * </span>
                  </label>
                  <div className="relative flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        onChange={(e) => {
                          const limit = 50;

                          // 👇️ only take first N characters
                          setLname(e.target.value.slice(0, limit));
                        }}
                        value={lname}
                        type="text"
                        placeholder="Enter Your Last Name"
                        className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      {errorMsg?.lname ? (
                        <span className="ml-3 text-xs text-red-500">
                          {errorMsg?.lname}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="dateOfBirth"
                    className="ml-3 text-xs text-gray-500"
                  >
                    Date of Birth
                  </label>
                  <div className="relative flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        onChange={(e) => setDob(e.target.value)}
                        value={dob}
                        type="text"
                        placeholder="Start Date"
                        ref={startRef}
                        onFocus={() => (startRef.current.type = "date")}
                        onBlur={() => (startRef.current.type = "date")}
                        className="block w-full rounded-md border-gray-400 bg-white pl-4 text-sm text-gray-600 placeholder:text-sm focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="mt-2">
                  <label htmlFor="email" className="ml-3 text-xs text-gray-500">
                    Email
                  </label>
                  <div className="relative flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        onChange={(e) => {
                          const limit = 15;

                          // 👇️ only take first N characters
                          setEmail(e.target.value.slice(0, limit));
                        }}
                        value={email}
                        type="text"
                        placeholder="Enter Your Email"
                        className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div> */}
                <div className="mt-2">
                  <label
                    htmlFor="phoneNumber"
                    className="ml-3 text-xs text-gray-500"
                  >
                    Phone Number <span className="text-rose-600"> * </span>
                  </label>
                  <div className="relative flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        onChange={(e) => {
                          const limit = 15;

                          // 👇️ only take first N characters
                          setPnumber(e.target.value.slice(0, limit));
                        }}
                        value={pNumber}
                        type="number"
                        placeholder="Enter Your Phone Number"
                        className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      {errorMsg?.pNumber ? (
                        <span className="ml-3 text-xs text-red-500">
                          {errorMsg?.pNumber}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
              <div className="mt-6 flex justify-between text-center tracking-widest">
                <button
                  onClick={handleOnClose}
                  className="rounded-md border-[1px] border-[#CE8172]  px-6 py-2 text-sm text-[#CE8172] transition duration-300 hover:bg-[#f5f0ef] active:ring-1 active:ring-[#CE8172] sm:px-8"
                >
                  CANCEL
                </button>
                <button
                  onClick={handleSubmit}
                  className="rounded-md bg-olivine-500 px-8 py-2 text-sm text-white transition duration-300 hover:bg-olivine-600 sm:px-10"
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileDetailsModal;
