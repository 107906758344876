import React, { useState } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import { Modal, Button, Box } from "@mui/material";
import UpgradePlanPopover from "./UpgradePlanPopover";
import { useNavigate } from "react-router-dom";

function UpgradePlanPageComponent({
  Name,
  Image,
  Price,
  Quantity,
  amount,
  id,
  planCode,
}) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const payload = {
    plan_code: planCode,
    price: amount,
    quantity: Quantity,
  };

  return (
    <div
      key={id}
      className="max-w-xs rounded-md border-[0.5px] border-gray-300 p-[5px] shadow shadow-gray-400"
    >
      <div className="py-1 pl-2 text-xl font-semibold text-gray-600">
        {Name}
      </div>
      <div className="rounded-md border border-gray-200">
        <img
          src={Image}
          className="aspect-auto h-36 w-full rounded-md object-cover"
          alt=""
        />
      </div>
      <div className="px-2">
        <h1 className="mt-2 text-lg font-bold text-gravel-500">{Price}</h1>
        <div className="text-xs text-gravel-500">Up to {Quantity} items</div>
      </div>
      <div className="mt-3 flex w-full flex-col space-y-3 px-2 pb-4 text-sm font-semibold">
        <button
          onClick={() => navigate(`/home/viewUpgradePlan/${planCode}`)}
          className="flex items-center space-x-1 text-olivine-500 transition duration-300 hover:text-olivine-400 active:text-olivine-600"
        >
          <span>View Content</span>
          <AiFillCaretRight className="h-4 w-4" />
        </button>
        <div className="w-full">
          <Button
            onClick={() => setOpen(true)}
            className="!first:uppercase w-full rounded-md !bg-olivine-500 py-3 !capitalize !text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
          >
            Upgrade plan
          </Button>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="mt-28 px-2 xsm:px-4">
              <Box className="max-h-md mx-auto max-w-xs rounded-lg bg-white ">
                <UpgradePlanPopover
                  data={payload}
                  handleClose={() => setOpen(false)}
                />
              </Box>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UpgradePlanPageComponent;
