import React, { useEffect, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import IsLoadingOne from "../../IsLoading_One";
const libraries = ["places"];

const mapContainerStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};

const center = {
  lat: -33.86882,
  lng: 151.20929,
};

function Map({ link }) {
  const [fetchLink, setFetchLink] = useState("");
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    if (link) {
      setFetchLink(link);
    }
  }, [link]);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";
  return (
    <>
      {!isLoaded ? (
        <IsLoadingOne message="loading..." />
      ) : (
        <div
          className="z-10"
          mapContainerStyle={mapContainerStyle}
          zoom={8}
          center={center}
        >
          <iframe
            src={fetchLink}
            title="Grocedy Tracking"
            width="100%"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="h-screen"
          ></iframe>
        </div>
      )}
    </>
  );
}

export default Map;
