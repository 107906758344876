import React from "react";
import Navbar from "../components/Navbar.js";
import Footer from "./Footer.js";
import SignedInNavbarImproved from "./SignedInNavbarImproved.js";
import MainAreaThree from "./MainAreaThree.js";

function HomeThree() {
  return (
    <div className="">
      <SignedInNavbarImproved />
      <MainAreaThree />
      <Footer />
    </div>
  );
}

export default HomeThree;
