import React from "react";
import CheckOutOtherProduct from "./CheckOutOtherProduct";
import GetStarted from "./GetStarted";
import Plans from "./Plans";
import WelcomeToGrocedy from "./WelcomeToGrocedy";
import HomePageButchers from "./butcher/HomePageButchers";
import BasketFloatingPane from "./pages/create_basket_page/BasketFloatingPane";
import WelcomeToGrocedyTwo from "./WelcomeToGrocedyTwo";
import CorporateView from "./grocedy_corporate/CorporateView";

function MainArea() {
  return (
    <div className="bg-primary_color">
      {/* <WelcomeToGrocedy /> */}
      <WelcomeToGrocedyTwo />
      <Plans />
      <HomePageButchers />
      <CorporateView />
      <CheckOutOtherProduct />
      <BasketFloatingPane />
    </div>
  );
}
export default MainArea;
