import React, { useContext, useEffect, useState } from "react";
// import SignedInNavbar from './SignedInNavbar';
// import { FaWallet, FaMoneyBill } from 'react-icons/fa';
// import { RiRefundLine } from 'react-icons/ri';
// import { IoCopy } from 'react-icons/io5';
import { BiArrowBack } from "react-icons/bi";
import { MdDateRange } from "react-icons/md";
// import SignedInNavbarImproved from './SignedInNavbarImproved';
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HiHome } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import BasketService from "../../../services/basketService";
import {authService} from "../../../services/authService";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import OrderHistoryAllPlan from "./OrderHistoryAllPlan";
import OrderHistoryPlan from "./OrderHistoryPlan";
import OrderHistoryCustomBasket from "./OrderHistoryCustomBasket";
import OrderHistoryReOccurring from "./OrderHistoryReOccuring";
import OrderHistoryGiftList from "./OrderHistoryGiftList";
import { useLocation, useNavigate } from "react-router-dom";
import IsLoadingOne from "../../IsLoading_One";
import LogicContext from "../../../context/LogicContext";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AccountImage from "../../../assets/LogIntoYourAccount.svg";
import { CgClose } from "react-icons/cg";
import { toast } from "react-hot-toast";

function OrderHistory() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [allPlanListBackupList, setAllPlanListBackupList] = useState([]);
  const [allPlanList, setAllPlanList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [salesOrderList, setSalesOrderList] = useState([]);
  const [occurringList, setOccurringList] = useState([]);
  const [giftList, setGiftList] = useState([]);
  const rxjsStore = useContext(RxjsStoreContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [openLoginModal, setOpenNotLoginModal] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const logicContext = useContext(LogicContext);



  useEffect(() => {
    setIsLoading(true);
    BasketService.getOrderHistory().then((dataList, failed) => {
        setIsLoading(false);

        setSubscriptionList(dataList?.subscription_list);
        setSalesOrderList(dataList?.salesorder_list);

        let tempList = dataList?.subscription_list.filter(
          (sub) => sub.expires_at === "");

        let tempListGiftList = dataList?.subscription_list.filter(
          (sub) => sub.setGiftList === "true");

        setOccurringList(tempList);
        setGiftList(tempListGiftList);
        setAllPlanList([...dataList?.salesorder_list,...dataList?.subscription_list]);
        setAllPlanListBackupList([...dataList?.salesorder_list,...dataList?.subscription_list,]);

        // rxjsStore.setOrderHistory(orderHistory);
      })
      .catch((err) => {
        toast.error("Error getting order history!");
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const continueToLogin = () => {
    logicContext.setPreviousUrl(location.pathname);
    navigate("/login");
  };



  function searchByDate() {
    let subTempList = [];
    let planOrderTempList = [];
    allPlanListBackupList.forEach((singlePlan) => {
      if (
        new Date(singlePlan?.start_date) >= startDate &&
        new Date(singlePlan?.start_date) <= endDate
      ) {
        subTempList.push(singlePlan);
      }
      if (
        new Date(singlePlan?.created_at) >= startDate &&
        new Date(singlePlan?.created_at) <= endDate
      ) {
        planOrderTempList.push(singlePlan);
      }
    });

    setAllPlanList([...subTempList, ...planOrderTempList]);
    setSalesOrderList(subTempList);
    setSubscriptionList(planOrderTempList);
    let tempListGiftList = subTempList?.filter(
      (sub) => sub.setGiftList === "true"
    );
    setGiftList(tempListGiftList);

    let tempList = subTempList?.filter((sub) => sub.expires_at === "");
    setOccurringList(tempList);
  }



  const closeNotLoginModal = () => {
    setOpenNotLoginModal(false);
  };


  return (
    <div>
      <SignedInNavbarImproved />
      {isLoading ? (
        <div>
          <IsLoadingOne message="Getting order history..." />
        </div>
      ) : (
        <>
          <div>
            <Dialog
              className="h-full pb-16"
              open={openLoginModal}
              onClose={closeNotLoginModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="h-hug w-full rounded-md border-gray-300 bg-gray-100 shadow shadow-gray-400">
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <div className="flex items-center justify-end">
                      <button
                        onClick={closeNotLoginModal}
                        className="rounded-full p-2 active:bg-gray-200"
                      >
                        <CgClose className="h-5 w-5 text-gravel-500" />
                      </button>
                    </div>
                    <div className="mt-2 flex justify-start text-sm">
                      <img src={AccountImage} className="h-40 w-full" alt="" />
                    </div>
                    <div className="cursor mt-4 flex justify-center text-base font-semibold xsm:text-lg ">
                      <div className=" font-bold text-gray-800">
                        Log into your account{" "}
                      </div>
                    </div>
                    <div className="mt-2 max-w-md text-center text-xs">
                      <div className="text-gray-500">
                        You are currently not logged into your Grocedy account.
                        Please login or create a new account to proceed to check
                        out.
                      </div>
                    </div>
                    <div
                      onClick={continueToLogin}
                      className="mt-5 pb-4 text-center tracking-wider xsm:px-6 sm:px-16"
                    >
                      <div className="rounded-md border-[1px] border-olivine-500 py-2 text-sm font-semibold text-olivine-500 transition duration-300 active:ring-1 active:ring-olivine-500">
                        Log into Account
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
              </div>
            </Dialog>
          </div>
          {/* end alert */}

          <div className="px-4 pt-8 pb-96 sm:px-8 md:px-10 lg:px-16">
            <div className="mt-8 ml-[5px] mb-3 flex items-center space-x-3 text-xs font-semibold text-gray-500">
              <div
                onClick={() => navigate("/")}
                className="cursor flex items-center space-x-1"
              >
                <div>
                  <HiHome className="h-3.5 w-3.5" />
                </div>
                <div>
                  <IoIosArrowForward className="h-[11px] w-[11px]" />
                </div>
              </div>

              <div
                onClick={() => navigate("/basket/create-basket")}
                className="cursor flex items-center space-x-1"
              >
                <div>custom Basket</div>
                <div>
                  <IoIosArrowForward className="h-[11px] w-[11px]" />
                </div>
              </div>
              <div className="cursor flex items-center space-x-1 text-olivine-500">
                <div>Order History</div>
                <div>
                  <IoIosArrowForward className="h-[11px] w-[11px]" />
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center space-x-2 sm:space-x-4">
              <div
                onClick={() => navigate("/basket/create-basket")}
                className="rounded-full p-1 hover:bg-gray-200"
              >
                <BiArrowBack className="h-6 w-6 cursor-pointer text-gray-600" />
              </div>

              <div className="text-lg font-bold text-gravel-600 sm:text-2xl">
                Order History
              </div>
            </div>

            <div className=" space-x-0  md:flex md:space-x-6 ">
              <div className="mt-10 flex items-center space-x-4 xsm:justify-start xsm:space-x-6 xsm:px-0">
                <div className="relative rounded-md">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                    className="h-10 w-28 rounded-md border-gray-400 bg-white text-sm text-gray-600 focus:border-olivine-500 focus:ring-olivine-500 xsm:w-36"
                  />
                  <MdDateRange className="absolute top-2 right-1 h-6 w-6 text-olivine-500" />
                </div>
                <div className="relative rounded-md">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    className="h-10 w-28 rounded-md border-gray-400 bg-white text-sm text-gray-600 focus:border-olivine-500 focus:ring-olivine-500 xsm:w-36"
                  />
                  <MdDateRange className="absolute top-2 right-1 h-6 w-6 text-olivine-500" />
                </div>
              </div>

              <div className="mt-3 rounded-md md:mt-[40px]   ">
                <button
                  onClick={searchByDate}
                  className="cursor-pointer rounded-lg border-[1px] border-olivine-500 bg-olivine-500 px-8 py-[10px] text-xs font-semibold text-white transition-all hover:bg-olivine-600 xsm:px-8 xsm:text-sm md:py-[9px]"
                >
                  SEARCH
                </button>
              </div>
            </div>

            <div className="mt-10">
              <Tab.Group>
                {/*   Tab section header */}
                <Tab.List className="space-y-4 text-xs xsm:text-sm md:flex md:items-center md:space-y-0 md:space-x-6">
                  <div className="items-centern  flex space-x-2 xsm:space-x-4">
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected
                              ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                              : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gray-500"
                          }
                        >
                          <div className="cursor-pointer">
                            <div className=" text-xs font-semibold xsm:text-sm ">
                              All Orders ({allPlanList.length})
                            </div>
                          </div>
                        </button>
                      )}
                    </Tab>

                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected
                              ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                              : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gray-500"
                          }
                        >
                          <div className="cursor-pointer">
                            <div className=" text-xs font-semibold xsm:text-sm ">
                              Plans ({subscriptionList?.length})
                            </div>
                          </div>
                        </button>
                      )}
                    </Tab>

                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected
                              ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                              : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gray-500"
                          }
                        >
                          <div className="cursor-pointer">
                            <div className=" text-xs font-semibold xsm:text-sm">
                              Recurring Orders ({occurringList?.length})
                            </div>
                          </div>
                        </button>
                      )}
                    </Tab>
                  </div>
                  <div className="flex  items-center space-x-2 xsm:space-x-6">
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected
                              ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                              : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gray-500"
                          }
                        >
                          <div className="cursor-pointer">
                            <div className=" text-xs font-semibold xsm:text-sm">
                              Pick your Basket Orders ({salesOrderList?.length})
                            </div>
                          </div>
                        </button>
                      )}
                    </Tab>

                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected
                              ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                              : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gray-500"
                          }
                        >
                          <div className="cursor-pointer">
                            <div className=" text-xs font-semibold xsm:text-sm ">
                              Gift Orders ({giftList?.length})
                            </div>
                          </div>
                        </button>
                      )}
                    </Tab>
                  </div>
                  <div className="flex items-center space-x-2 xsm:space-x-6">
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected
                              ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                              : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gray-500"
                          }
                        >
                          <div className="cursor-pointer">
                            <div className=" text-xs font-semibold xsm:text-sm">
                              {/* Recurring Orders */}
                            </div>
                          </div>
                        </button>
                      )}
                    </Tab>
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected
                              ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                              : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gray-500"
                          }
                        >
                          <div className="cursor-pointer">
                            <div className=" text-xs font-semibold xsm:text-sm ">
                              {/* Gift Orders */}
                            </div>
                          </div>
                        </button>
                      )}
                    </Tab>
                  </div>
                </Tab.List>

                {/*  tab Body */}
                <Tab.Panels>
                  {/* All  Plan */}
                  <Tab.Panel>
                    <OrderHistoryAllPlan allPlan={allPlanList} />
                  </Tab.Panel>

                  {/* plans  */}
                  <Tab.Panel>
                    <OrderHistoryPlan subscriptionList={subscriptionList} />
                  </Tab.Panel>

                  {/* reoccurring */}
                  <Tab.Panel>
                    <OrderHistoryReOccurring occurringList={occurringList} />
                  </Tab.Panel>

                  {/* custom basket */}
                  <Tab.Panel>
                    <OrderHistoryCustomBasket salesOrderList={salesOrderList} />
                  </Tab.Panel>

                  <Tab.Panel>
                    <OrderHistoryGiftList giftList={giftList} />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default OrderHistory;
