import React from "react";
import Navbar from "../../Navbar";

function TermsAndConditions() {
  return (
    <div className="mx-auto w-full max-w-[2520px] bg-gray-200">
      <Navbar />
      <div className="px-4 pb-40 xsm:px-6">
        <div className="mx-auto mt-10 max-w-3xl rounded-3xl border-[0.5px] border-gray-300 bg-[#F2FAD3]">
          <div className="p-8 sm:p-16">
            <h1 className="text-center text-xl font-bold text-gray-700">
              GROCEDY TERMS AND CONDITIONS
            </h1>
            <ol className="mt-8 list-inside list-decimal space-y-1 text-sm font-medium text-gray-600">
              <li>
                You acknowledge and agree that Grocedy has the right to decline
                acceptance into its food subscription service.
              </li>
              <li>
                You understand that Grocedy may extend the delivery date if
                faced with logistics issues beyond its control.
              </li>
              <li>
                You agree not to hold Grocedy or its staff liable for any
                misrepresentation of products or policies, except for the
                information provided in the product brochure and on the website{" "}
                <span className="text-olivine-600 underline">
                  <a href="https://www.grocedy.com/">(www.grocedy.com).</a>
                </span>
              </li>
              <li>
                You are responsible for completing all your subscription
                instalments and charges within 27 days every month before being
                considered for food delivery.
              </li>
              <li>
                You understand that all payments for subscriptions are
                non-refundable. Once Grocedy has received your subscription
                payment or any instalment payment, no refunds will be provided.
              </li>
              <li>
                You can only alter your subscription category after completing
                and closing an existing subscription plan category which has
                been fully paid for at least one month. Upgrade and downgrade of
                subscription plans will commence from the following month.
              </li>
              <li>
                You acknowledge that Grocedy may alter or review the
                subscription fee and charges if necessary, without prior notice,
                due to present market conditions. These changes may affect the
                quantity of items delivered to you.
              </li>
              <li>
                The items delivered to you will be subject to the market rate on
                the day of delivery.
              </li>
              <li>
                All complaints should be forwarded only by email to
                customercare@grocedy.com or through the live chat options.
              </li>
              <li>
                Any delivery not attended to by your registered receiver or
                yourself will be returned to the Grocedy office, wherein pickup
                will be at your own expense and invitation.
              </li>
              <li>
                All subscription payments must be completed within 27 days from
                the start of the subscription date.
              </li>
              <li>
                {" "}
                Delivery will be made to your registered address within 5 days
                of your last payment date that completed payment for your basket
                or up to 3 working days if you paid in full for your basket.
              </li>
            </ol>
            <div className="mt-6">
              <h2 className="text-base font-medium text-gray-700">
                ACKNOWLEDGEMENT
              </h2>
              <p className="text-sm font-medium text-gray-600">
                I hereby acknowledge that I have read, understood, and agreed to
                the above terms and conditions of my subscription to Grocedy for
                using its platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
