import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../store/actions";
import Navbar from "../Navbar";
import { useNavigate, useParams } from "react-router-dom";
import IsLoadingOne from "../IsLoading_One";
import SignedInNavbarImproved from "../SignedInNavbarImproved";
import { authService } from "../../services/authService";
import { CgClose } from "react-icons/cg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

function SignedInProductPlanUsd() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const [fetchedPlan, setFetchedPlan] = useState({});
  const [selectedPlanImages, setSelectedPlanImages] = useState([]);
  const [isViewing, setIsViewing] = useState(0);
  const [fetchedProducts, setFectchedProducts] = useState({});
  const [toolTipOpen, setToolTipOpen] = useState(false);

  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedImage, setSelectedImage] = useState([]);

  const token = authService.getToken();
  const selected_plan = localStorage.getItem("selected_plan");
  const { products } = useSelector((state) => state.masterData);
  const { rate } = useSelector((state) => state.general);
  const { loading } = useSelector((state) => state.error);
  const { isGifting } = useSelector((state) => state.modal);
  const corporateId = authService.getCustomerCorporateId();
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (!products) {
      const data = {
        sql_ref_no: "46",
        corporateId: corporateId ? +corporateId : 1,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(getProducts(data))
        .unwrap()
        .catch((err) => {
          return err;
        });
    }

    if (products) setFectchedProducts(products);
  }, [dispatch, products, navigate, corporateId]);

  useEffect(() => {
    if (
      fetchedProducts &&
      params.id &&
      fetchedProducts?.plan_info?.length &&
      fetchedProducts?.Plan_Image?.length
    ) {
      const filteredPlan =
        fetchedProducts?.plan_info?.length &&
        fetchedProducts?.plan_info?.filter(
          (plan) => plan?.plan_code === selected_plan
        );

      setFetchedPlan(filteredPlan);

      const filteredImages =
        fetchedProducts?.Plan_Image?.length &&
        fetchedProducts?.Plan_Image?.filter(
          (image) => image?.zoho_plan_id === filteredPlan[0]?.plan_id
        );

      setSelectedPlanImages(filteredImages);

      localStorage.setItem("selected_plan", params.id);
    }
  }, [dispatch, params.id, fetchedProducts, selected_plan]);

  useEffect(() => {
    if (fetchedPlan) {
      setSelectedPlan(fetchedPlan);
    }
  }, [fetchedPlan]);

  useEffect(() => {
    if (selectedPlanImages.length) {
      setSelectedImage(selectedPlanImages);
    }
  }, [selectedPlanImages]);

  const items =
    selectedPlan &&
    selectedPlan?.length &&
    selectedPlan[0]?.store_markup_description.split("|");

  const handleCart = (id) => {
    navigate(`/home/cart/${id}`);
  };

  const handleBasket = (id) => {
    navigate(`/basket/my-basket/${id}`);
  };

  const handleCurrentView = (id) => {
    setIsViewing(id);
  };

  return (
    <>
      {loading ? (
        <IsLoadingOne message="loading..." />
      ) : (
        <div>
          {token ? <SignedInNavbarImproved /> : <Navbar />}
          <div className="px-4 py-16 sm:px-8 md:px-16">
            <div className="flex items-center space-x-2">
              <button className="rounded-full hover:bg-gray-100 active:bg-gray-200">
                <BiArrowBack className="h-8 w-8 text-gravel-500 sm:hidden" />
              </button>
              <div className="text-3xl font-bold text-gravel-500 sm:ml-0 sm:text-4xl">
                {fetchedPlan &&
                  selectedPlan &&
                  selectedPlan?.length &&
                  selectedPlan[0]?.name}
              </div>
            </div>

            <div className="mt-8 grid gap-4 grid-auto-fit-sm">
              <div className="rounded-xl border-[1px] border-gray-400 shadow-md shadow-gray-500">
                {selectedImage && selectedImage.length ? (
                  <img
                    src={selectedImage[isViewing]?.plan_image_url}
                    className="aspect-square h-auto w-auto rounded-lg object-fill"
                    alt=""
                  />
                ) : null}
              </div>
              <div className="grid gap-2 grid-auto-fit-[6rem] md:grid-auto-fit-[9rem]">
                {selectedImage && selectedImage.length
                  ? selectedImage.map((img, idx) => (
                      <img
                        key={idx}
                        src={img.plan_image_url}
                        className="aspect-square h-auto w-auto cursor-pointer rounded-lg object-cover"
                        alt=""
                        onClick={() => handleCurrentView(idx)}
                      />
                    ))
                  : null}
              </div>
            </div>
            <div>
              <div className="text-font mt-8 text-2xl font-bold tracking-wider">
                {selectedPlan &&
                  selectedPlan?.plan_bo &&
                  (rate
                    ? `$${Number(
                        +selectedPlan?.plan_bo[0]?.recurring_price *
                          +rate[0]?.exchange_rate
                      ).toFixed(2)}/month`
                    : `₦${Number(
                        selectedPlan?.plan_bo[0]?.recurring_price
                      ).toLocaleString()}/month`)}
              </div>
              <div className="mt-4">
                <span className="font-medium text-gray-500">
                  {/* Up to 7 items in your basket */}
                  {selectedPlan && selectedPlan[0]?.description}
                  <br />
                </span>
                <span className="font-bold">
                  (Quantity and price are subject to current market rates and
                  may change)
                </span>
              </div>
              <div>
                <div className="text-font mt-4 text-lg font-bold">
                  Basket Content
                </div>
                <div className="mt-1 text-sm font-semibold text-gravel-500">
                  {items &&
                    items?.length &&
                    items?.map((item, idx) => (
                      <div
                        key={idx}
                        className="mb-2 w-full rounded-lg border-[1px] border-gray-400 px-2 py-2 sm:w-80 "
                      >
                        {item}
                      </div>
                    ))}
                </div>
              </div>

              {/* end tooltip Alert */}
              <div>
                <Dialog
                  className="h-full pb-16"
                  open={toolTipOpen}
                  onClose={() => setToolTipOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <div className="h-hug w-full rounded-md border-gray-300 bg-gray-100 shadow shadow-gray-400">
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <div className="flex items-center justify-end">
                          <button
                            onClick={() => setToolTipOpen(false)}
                            className="rounded-full p-2 active:bg-gray-200"
                          >
                            <CgClose className="h-5 w-5 text-gravel-500" />
                          </button>
                        </div>
                        <div className="cursor mt-4 flex justify-center text-base font-semibold xsm:text-lg ">
                          <div className=" font-bold text-gray-800">
                            Transaction Date
                          </div>
                        </div>
                        <div className="w-ful mt-2 max-w-sm text-center text-xs">
                          <div className="text-gray-500">
                            transaction date meaning transaction date meaning
                            transaction date meaning transaction date meaning
                          </div>
                        </div>
                        <div className="mt-5 flex items-center justify-center pb-4 text-center tracking-wider">
                          <div
                            onClick={() => setToolTipOpen(false)}
                            className="mt-4 w-full !cursor-pointer rounded-md border-[1px] border-olivine-500 py-2 text-sm font-semibold text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-500 sm:mt-0"
                          >
                            Got it
                          </div>
                        </div>
                      </DialogContentText>
                    </DialogContent>
                  </div>
                </Dialog>
              </div>
              {/* end tooltip Alert */}

              <div className="mt-8 flex flex-col-reverse pb-20  sm:flex-row sm:items-center sm:space-x-4">
                {isGifting ? (
                  <button
                    onClick={() => handleBasket(selectedPlan[0]?.plan_code)}
                    className="mt-8 rounded-md bg-olivine-500 px-8 py-4 font-semibold text-white hover:bg-olivine-600 sm:mt-0 sm:border-[1px] sm:border-olivine-500 sm:py-3"
                  >
                    ADD TO BASKET
                  </button>
                ) : (
                  <button
                    onClick={() => handleCart(selectedPlan[0]?.plan_code)}
                    className="mt-8 rounded-md bg-olivine-500 px-8 py-4 font-semibold text-white hover:bg-olivine-600 sm:mt-0 sm:border-[1px] sm:border-olivine-500 sm:py-3"
                  >
                    ADD TO CART
                  </button>
                )}

                {!isGifting ? (
                  <button
                    onClick={() => handleBasket(selectedPlan[0]?.plan_code)}
                    className="rounded-md border-[1px] border-gray-300 bg-[#ECF9D4] px-12 py-4 font-semibold tracking-widest text-olivine-500 hover:bg-[#e5f6c7] hover:ring-[1px] hover:ring-olivine-500 sm:border-[1px] sm:border-olivine-500 sm:bg-white sm:py-3 sm:hover:bg-olivine-100"
                  >
                    GIFT
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SignedInProductPlanUsd;
