import React, { useEffect } from "react";

import Footer from "../../Footer";

import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import GiftCreateBasketContent from "./GiftCreateBasketContent";

function GiftCreateBasketPage() {
  return (
    <div className="">
      <SignedInNavbarImproved />
      <GiftCreateBasketContent />

      <Footer />
    </div>
  );
}

export default GiftCreateBasketPage;
