import React from "react";
import { IoClose } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";

function BuyAirtimeAndDataPopover({ closer, handleRedirect }) {
  return (
    <div className="rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold text-gray-600">
          Buy Airtime and Data
        </div>
        <button
          onClick={() => closer()}
          className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="pb-4">
        <div
          onClick={() => handleRedirect("airtime")}
          className="mt-6 flex h-12 cursor-pointer items-center justify-between rounded-lg bg-[#E9F8CC] px-4 drop-shadow-md filter hover:ring-1 hover:ring-gray-400"
        >
          <div className="text-sm font-semibold text-gray-500">Buy Airtime</div>
          <div>
            <IoIosArrowForward className="h-4 w-4 cursor-pointer text-gray-500" />
          </div>
        </div>
        <div
          onClick={() => handleRedirect("data")}
          className="mt-6 flex h-12 cursor-pointer items-center justify-between rounded-lg bg-[#E9F8CC] px-4 drop-shadow-md filter hover:ring-1 hover:ring-gray-400"
        >
          <div className="text-sm font-semibold text-gray-500">Buy Data</div>
          <div>
            <IoIosArrowForward className="h-4 w-4 cursor-pointer text-gray-500" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyAirtimeAndDataPopover;
