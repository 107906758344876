import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
// import { ToWords } from "to-words";
import { Modal, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import CancelledPlan from "./pages/upgradePlan/CancelledPlan";
import { setSelectedProductId } from "../store/slices/modalSlice";
import { useNavigate } from "react-router-dom";
import { rxjsStoreProvider } from "../context/OrderHistoryRxjsProvider";

function SignedInHomeCardComponent({
  keyIdx,
  products,
  images,
  tracker,
  customBasket,
}) {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  // const [dateDifference, setDateDifference] = useState(0);
  const [planImage, setPlanImage] = useState([]);
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   if (products?.current_term_ends_at && products?.current_term_starts_at) {
  //     const duration = moment(products?.current_term_ends_at).diff(
  //       moment(products?.current_term_starts_at),
  //       "months"
  //     );

  //     setDateDifference(duration);
  //   }
  // }, [products?.current_term_ends_at, products?.current_term_starts_at]);

  const color = "#D1D5DB";
  // const toWords = new ToWords();

  useEffect(() => {
    if (images.length && products && products?.plan?.length) {
      const planImages = images?.filter(
        (img) => img?.plan_code === products?.plan[0]?.plan_code
      );
      setPlanImage(planImages);
    }
  }, [products, images]);

  function viewProps(props) {
    navigate("/basket/order-history-details");
    rxjsStoreProvider.setSingleOrderHistory(props);
  }

  return (
    <div
      key={keyIdx}
      className=" h-full w-full max-w-md rounded-md border border-gray-200 p-4 @container"
    >
      <div className="flex justify-between">
        <div className="flex items-center space-x-2 whitespace-nowrap">
          <div className="whitespace-nowrap rounded-md border-[1px] border-gray-300">
            <img
              src={
                planImage && planImage?.length
                  ? planImage[0]?.url
                  : "https://s3.eu-west-2.amazonaws.com/delgro-bucket.com/grocedy-live-products/110011.jpeg"
              }
              className="aspect-auto h-10 w-12 rounded-md object-cover"
              alt=""
            />
          </div>
          <div className="">
            <div className="flex space-x-2 text-sm">
              <div className="font-semibold text-gray-500">
                {products && products?.plan?.length
                  ? products?.plan[0]?.name
                  : products?.adjustment_description}
              </div>
              <div
                className="rounded-full px-2 py-0.5 text-xs font-semibold text-olivine-500"
                style={{ background: color }}
              >
                {products?.status}
              </div>
              <div className="font-semibold text-gray-500">
                {products && products?.is_gift === "true" ? "(Gift)" : null}
              </div>
              <div className="font-semibold text-gray-500">
                {products && products?.subscription_name
                  ? products?.subscription_name
                  : ""}
              </div>
            </div>
            <div className="mt-[2px] font-bold text-gray-600">
              {products && products?.amount
                ? `${products?.currency_symbol}${Number(
                    products?.amount
                  ).toLocaleString()}`
                : `${products?.currency_symbol}${Number(
                    products?.total_amount
                  ).toLocaleString()}`}
            </div>
          </div>
        </div>
        <div className="">
          {!customBasket ? (
            <button
              onClick={() => {
                setOpen(true);
                dispatch(
                  setSelectedProductId({
                    id: products?.subscription_id,
                    status: products?.status,
                    planCode: products?.plan[0]?.plan_code,
                    qty: products?.plan[0]?.quantity,
                    nextBilling: products?.next_billing_at,
                    isRecuring: !products?.expires_at ? true : false,
                  })
                );
              }}
            >
              <div className="cursor-pointer rounded-full p-1 text-gray-500 hover:bg-gray-100 active:bg-gray-200">
                <BsThreeDotsVertical className="h-4 w-4" />
              </div>
            </button>
          ) : null}

          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="mt-10 px-2 xsm:px-4">
              <Box className="max-h-sm mx-auto max-w-sm rounded-xl bg-white ">
                <CancelledPlan
                  product_id={products?.product_id}
                  handleClose={() => setOpen(false)}
                />
              </Box>
            </div>
          </Modal>
        </div>
      </div>
      {/* <div className="mt-3 text-sm text-gray-600">
        <span className="font-bold">
          {dateDifference && dateDifference > 0
            ? dateDifference > 1
              ? `${toWords.convert(dateDifference)} months `
              : `${toWords.convert(dateDifference)} month `
            : "Less than a month "}
        </span>
        (
        {products?.current_term_starts_at && products?.current_term_ends_at
          ? `${moment(products?.current_term_starts_at).format(
              "MMMM YYYY"
            )} - ${moment(products?.current_term_ends_at).format("MMMM YYYY")}`
          : `${moment(products?.activated_at).format("MMMM YYYY")}`}
        )
      </div> */}
      <div className="mt-3 text-sm text-gray-600">
        <span className="font-bold">Created on: </span>
        {products?.activated_at
          ? moment(products?.activated_at).format("lll")
          : `${moment(products?.start_date).format("lll")}`}
      </div>

      {/* {products && products?.subscription_name ? null : (
        <div className="mt-3 text-sm text-gray-600">
          <span className="font-bold">Expires on: </span>
          {products?.expires_at && products?.expires_at}
        </div>
      )} */}

      <div className="mt-3 flex items-center justify-between text-xs font-semibold leading-8 text-gray-600 @xs:text-sm">
        <div
          onClick={() => tracker(products)}
          className="cursor-pointer text-olivine-500 hover:text-olivine-600 hover:underline hover:underline-offset-4"
        >
          Track your Order
        </div>
        <div
          onClick={() => viewProps(products)}
          className="cursor-pointer text-[#565656] hover:underline hover:underline-offset-4"
        >
          View Order Details
        </div>
      </div>
    </div>
  );
}

export default SignedInHomeCardComponent;
