import React from "react";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { AiFillEdit } from "react-icons/ai";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import AddonPopover from "./AddonPopover";

function Addons({
  img,
  rate,
  quantity,
  deleter,
  selectedId,
  exchangeRate,
  addonName,
  dataPull,
  addons,
  noMonths,
  isRecurring,
}) {
  const handleDelete = (id) => {
    deleter(id);
  };

  const existingAddon = {
    addonName,
    quantity,
    isRecurring,
    noMonths,
    selectedId,
  };

  return (
    <div className="">
      <div className="grid grid-cols-9 items-center py-2 text-xs font-semibold text-gray-500 sm:grid-cols-12">
        <div className="col-span-3 flex items-center space-x-1 sm:col-span-6 sm:space-x-2">
          <div className="hidden rounded-md border-[1px] border-gray-200 xsm:inline">
            <img
              src={img}
              className="aspect-square h-7 w-7 object-cover sm:h-10 sm:w-10"
              alt=""
            />
          </div>
          <div className="">
            <div className="text-[9px] xsm:text-[10px] sm:text-xs">
              {addonName}
            </div>
            <div className="text-[9px] text-gray-500 xsm:text-[10px] sm:text-xs">
              {!exchangeRate
                ? `₦${Number(rate).toLocaleString()}`
                : `$${Number(rate * exchangeRate).toFixed(2)}`}
            </div>
          </div>
        </div>
        <div className="col-span-2 sm:col-span-2">
          <div className="grid">
            <div className="mx-auto">{quantity}</div>
          </div>
        </div>
        <div className="col-span-2 justify-end sm:col-span-2 sm:flex">
          <div className="grid justify-end">
            <div className="sm:mx-auto">
              {!exchangeRate
                ? `₦${Number(+rate * +quantity).toLocaleString()}`
                : `$${Number(+rate * +quantity * +exchangeRate).toFixed(2)}`}
            </div>
          </div>
        </div>
        <div className="col-span-2 flex justify-end space-x-2 sm:col-span-2 sm:space-x-4">
          <div className="">
            <div className="grid cursor-pointer justify-end text-gray-500 transition duration-300 hover:text-gray-700">
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`${
                        open ? "" : "text-opacity-90"
                      } focus:text-black focus:outline-none`}
                    >
                      <AiFillEdit
                        // onClick={() => handleEdit("here")}
                        className="h-4 w-4"
                      />
                    </Popover.Button>
                    <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute right-[205px] z-10 -mt-[450px] w-full max-w-sm -translate-x-1/2 xsm:right-[290px]">
                        {({ close }) => (
                          <div>
                            <AddonPopover
                              addons={addons}
                              closer={close}
                              dataPull={dataPull}
                              edit
                              existingData={existingAddon}
                            />
                          </div>
                        )}
                        {/* <div>
                            <AddonPopover addons={addons} />
                          </div> */}
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              {/* <AiFillEdit
                  onClick={() => handleEdit()}
                  className="h-4 w-4 cursor-pointer"
                /> */}
            </div>
          </div>
          <div className="">
            <div className="grid cursor-pointer justify-end text-red-500 transition duration-300 hover:text-red-700">
              <RiDeleteBin6Fill
                onClick={() => handleDelete(selectedId)}
                className="h-4 w-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addons;
