import React from "react";
import { IoClose } from "react-icons/io5";
import UpgradePlanIcon from "../../../assets/UpgradePlanIcon.svg";
import { activePlans, upgradePlan } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { authService } from "../../../services/authService";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function UpgradePlanPopover({ data, handleClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const zohoCustomerId = authService.getCurrentUserZohoCustomerId();
  const { selectedProductId } = useSelector((state) => state.modal);

  const handlePlanUpgrade = () => {
    try {
      const toastId = toast.loading("Upgrading Plan... please wait...");

      const payload = {
        id: selectedProductId?.id,
        data,
      };

      dispatch(upgradePlan(payload))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            return toast.error(res.response.Message_Text, {
              id: toastId,
            });
          }
          handleClose();
          dispatch(activePlans(zohoCustomerId));
          navigate("/home");
          return toast.success("Plan Upgraded successfully", {
            id: toastId,
          });
        })
        .catch(() => {
          return toast.error("Plan upgrade failed...", {
            id: toastId,
          });
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="rounded-2xl bg-white">
      <div className="flex justify-end px-4 py-4 xsm:px-6">
        <button
          onClick={handleClose}
          className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-300"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="px-4">
        <div className="flex items-center justify-center space-x-2 pt-3">
          <img src={UpgradePlanIcon} alt="icon" className="h-4 w-4" />
          <h3 className="font-semibold text-gray-700">Upgrade Plan</h3>
        </div>
        <p className="mt-1 pb-4 text-center text-sm text-gray-500">
          Are you sure you want to upgrade from your current plan to this plan?
          <br />
          {`The upgraded plan will start on the ${moment(
            selectedProductId?.nextBilling
          ).format("DD MMMM YYYY")}}. (Anniversary
          of your subscription).`}
        </p>
      </div>
      <div className="flex flex-col space-y-4 px-4 pb-6">
        <button
          onClick={() => handlePlanUpgrade()}
          className="w-full rounded-md bg-olivine-500 py-3 font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600"
        >
          Upgrade plan
        </button>
        <button
          onClick={handleClose}
          className="w-full rounded-md border-[1px] border-olivine-500 bg-white py-3 font-semibold capitalize text-olivine-500 transition-all duration-200 first:uppercase hover:bg-olivine-50 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100"
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default UpgradePlanPopover;
