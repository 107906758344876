import React, { useState } from "react";
import { Link } from "react-router-dom";
import CurrentTransactionMechod from "./currentPinMethod/CurrentTransactionMechod";
import EmailAddressMethod from "./emailMethod/EmailAddressMethod";
import PhoneNumberMethod from "./phoneMethod/PhoneNumberMethod";

function ChangeTransactionPin() {
  // const [otp, setOtp] = useState(new Array(4).fill(""));

  return (
    <div className="mt-8 pb-16">
      <div className="hidden w-full md:inline md:w-auto">
        <div className="flex items-center justify-between rounded-md bg-[#4F4F4F] px-4 py-3 md:bg-white md:px-0">
          <div>
            <h2 className="text-lg font-semibold text-white md:text-xl md:text-gray-700">
              Change Transaction PIN
            </h2>
          </div>
          <Link to="/home/wallet">
            <button className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-white p-8 px-6 py-2.5 text-sm font-semibold text-olivine-500 transition duration-300 ease-in-out hover:ring-[0.5px] hover:ring-olivine-500 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100">
              <div className="text-sm font-semibold text-olivine-500">
                Fund your Account
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div className="pb-20">
        <h4 className="text-sm font-semibold text-gray-500">
          Choose your preferred method
        </h4>
        <div className="mt-4 divide-y-[0.5px] divide-gray-300 overflow-hidden rounded-xl border-[1px] border-gray-200 bg-[#F6F6F6]">
          <CurrentTransactionMechod />
          <EmailAddressMethod />
          <PhoneNumberMethod />
        </div>
      </div>
    </div>
  );
}

export default ChangeTransactionPin;
