import React from "react";
import Shopping_cart from "../assets/shopping_cart.svg";
import Order from "../assets/order.svg";
import Credit_cart from "../assets/credit_card.svg";
import { useNavigate } from "react-router-dom";

function GetStarted() {
  const navigate = useNavigate();
  const selected_plan = localStorage.getItem("selected_plan");

  const handleContinue = () => {
    navigate(`/home/plans/${selected_plan}`);
  };
  return (
    <div className="mx-auto mt-36 grid max-w-[1600px] place-items-center px-3 xsm:px-4 sm:px-8 lg:px-16">
      <div className="font-sans text-2xl font-bold text-gray-600">
        Easy as 1, 2, 3!
      </div>
      <div className="mt-8 grid grid-cols-1 gap-14 sm:gap-14 md:grid-cols-2 md:gap-10 lg:grid-cols-3 lg:gap-8">
        <div className="h-full w-full rounded-md bg-olivine-500 px-4 pb-4 pt-8 sm:px-10">
          <img src={Shopping_cart} className="h-44 w-80" alt="" />
          <div className="mt-8 align-top text-xl font-bold text-white">
            1. Pick a basket
          </div>
          <div className="mt-6 align-top text-xs font-semibold leading-4 text-white">
            Select a basket that suits you.
          </div>
        </div>
        <div className="h-full w-full rounded-md bg-olivine-500 px-4 pb-4 pt-8 sm:px-8">
          <img src={Credit_cart} className="h-44 w-80" alt="" />
          <div className="mt-8 align-top text-xl font-bold text-white">
            2. Subscribe
          </div>
          <div className="mt-6 align-top text-xs font-semibold leading-4 text-white">
            Pay for any of our baskets with <br />
            your Grocedy Wallet or your debit <br />
            card.
          </div>
        </div>
        <div className="h-full w-full rounded-md bg-olivine-500 px-4 pb-4 pt-8 sm:px-10">
          <img src={Order} className="h-44 w-80" alt="" />
          <div className="mt-8 align-top text-xl font-bold text-white">
            3. Get your delivery
          </div>
          <div className="mb-8 mt-6 align-top text-xs font-semibold leading-4 text-white">
            Sit back, we'll have your basket <br />
            items delivered to you , right at <br />
            your doorstep.
          </div>
        </div>
      </div>
      <button
        onClick={() => handleContinue()}
        className="mt-10 transform cursor-pointer rounded-md bg-olivine-500 px-16 py-3 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 xsm:text-sm"
      >
        Get Started
      </button>
    </div>
  );
}

export default GetStarted;
