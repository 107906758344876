import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import modalReducer from "./slices/modalSlice";
import errorReducer from "./slices/appSlice";
import masterDataReducer from "./slices/masterDataSlice";
import generalReducer from "./slices/generalSlice";
import cartReducer from "./slices/cartSlice";
import userReducer from "./slices/userSlice";
import paymentReducer from "./slices/paymentSlice";
import vasReducer from "./slices/vasSlice";
import paystackReducer from "./slices/paystackSlice";
import voucherReducer from "./slices/voucherSlice";
import rewardBankReducer from "./slices/rewardBankSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
    error: errorReducer,
    masterData: masterDataReducer,
    general: generalReducer,
    cart: cartReducer,
    user: userReducer,
    payment: paymentReducer,
    vas: vasReducer,
    paystack: paystackReducer,
    voucher: voucherReducer,
    rewardBank: rewardBankReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  // middleware: middleWares,
});
