import React, { useState } from "react";

import { CgClose } from "react-icons/cg";
import { MdImage, MdOutlineDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../services/authService";
import {
  deleteCorporateId,
  todoListStatus,
  uploadCorporateId,
} from "../store/actions";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { generateId, init_data } from "../core/helpers";
import axios from "axios";
import { setIsCorporate } from "../store/slices/modalSlice";

const CoporateDetailsPopUp = ({ onClose, handleThanks }) => {
  const dispatch = useDispatch();
  const [uploadedFileResponse, setUploadedFileResponse] = useState("");
  const [demo, setDemo] = useState();
  const { todoListStatusData } = useSelector((state) => state.masterData);
  const { isCorporate } = useSelector((state) => state.modal);

  const customerId = authService.getCurrentUserCustomerId();
  const token = authService.getToken();

  const { getRootProps, getInputProps } = useDropzone({
    // accept: {
    //   "image/*": [".jpeg", ".jpg", ".png"],
    // },
    onDrop: (acceptedFiles) => {
      const mappedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setDemo(mappedFiles);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!demo) {
      toast.error("Select a File to upload");

      return;
    }
    // Get the first file from the list of uploaded files
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (demo[0].size > maxSize) {
      setDemo(undefined);
      return toast.error("Selected File is too large"); // Prevent further execution
    }

    if (todoListStatusData?.customer_corporate_id_upload_url) {
      toast.success(
        "Uploading a new file will overwrite your existing file..."
      );
    }

    const toastId = toast.loading("Submitting your document...");

    const data = {
      file: demo[0],
      id: generateId(8),
    };

    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    const requestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    await axios
      .post(process.env.REACT_APP_UPLOAD_API, formData, requestConfig)
      .then((res) => {
        if (res?.status !== 200) {
          return toast.error("ID could not be uploaded", {
            id: toastId,
          });
        }

        setUploadedFileResponse(res.data);
        // upload to main server
        const data = {
          customer_id: customerId?.toString(),
          file_url: res?.data?.Location,
        };

        dispatch(uploadCorporateId(data))
          .unwrap()
          .then((res) => {
            if (res?.status === 403) {
              return toast.error(res?.response?.error, {
                id: toastId,
              });
            }

            toast.success("Valid Id Uploaded", {
              id: toastId,
            });
            dispatch(todoListStatus(init_data));
            setDemo(undefined);

            if (isCorporate) {
              return handleThanks();
            }

            onClose();
            return window.location.reload();
          })
          .catch((err) => err);
      })
      .catch(() => {
        setDemo(undefined);
        onClose();
        setUploadedFileResponse("");
        toast.error("Valid Id Upload failed!", {
          id: toastId,
        });
      });
  };

  const handleOnClose = () => {
    dispatch(setIsCorporate(false));
    onClose();
  };

  const handleDelete = () => {
    const toastId = toast.loading("Deleting your document...");
    dispatch(deleteCorporateId(customerId))
      .unwrap()
      .then((res) => {
        if (res?.status === 403) {
          return toast.error(res?.response?.error, {
            id: toastId,
          });
        }
        dispatch(todoListStatus(init_data));
        window.location.reload();
        toast.success("Valid Id Deleted", {
          id: toastId,
        });
      });
  };

  const handleDownload = (url) => {
    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute to the AWS S3 link
    link.href = url;

    // Set the download attribute to specify the filename
    link.download = url.split("/").pop(); // Set the desired filename

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  return (
    <div className="mx-auto h-full w-full max-w-[420px] px-2 pb-16 pt-[300px] xsm:px-3">
      <div className="rounded-2xl bg-[#F9F9F9] px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <div className="font-bold text-gray-700 xsm:text-lg sm:text-xl">
            Upload a Valid Company ID Card
          </div>
          <button
            onClick={handleOnClose}
            className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
          >
            <CgClose className="h-5 w-5 text-gravel-500" />
          </button>
        </div>
        <div className="mt-2 text-xs text-gray-700">
          Upload a valid Company ID card to Create your Coporate account
        </div>
        <div className="mt-5 text-sm text-gray-700">
          <h3 className="font-semibold text-olivine-600">Please Note</h3>
          <ul className="list-outside list-disc px-2 text-xs font-medium xsm:px-3">
            <li className="">
              Your ID card may take between 24-48 hours to be authenticated
            </li>
            <li>You will need to verify your ID card after 6 months</li>
          </ul>
        </div>
        {todoListStatusData?.customer_corporate_id_upload_url && (
          <div className="mt-5 text-sm text-gray-700">
            <h3 className="font-semibold text-olivine-400">Uploaded File:</h3>
            <p className="flex list-outside list-disc flex-row justify-between px-2 text-xs font-medium xsm:px-3 ">
              <p
                className="cursor-pointer font-semibold text-olivine-600 underline"
                onClick={() =>
                  handleDownload(
                    todoListStatusData?.customer_corporate_id_upload_url
                  )
                }
              >
                Click here to download
              </p>
              <MdOutlineDeleteOutline
                onClick={() => handleDelete()}
                size={15}
                color="red"
                className="cursor-pointer"
              />
            </p>
          </div>
        )}

        <div className="text-gray-700">
          <form>
            <div className="mt-6">
              <label className="pl-2 text-xs">Upload ID Limit (5mb)</label>
              <div
                htmlFor="demo"
                {...getRootProps()}
                className="w-hug mt-1 grid h-24 rounded-xl border-2 border-dotted border-gray-300 bg-white"
              >
                <input {...getInputProps()} />
                <div className="m-auto grid">
                  <div className="m-auto">
                    <MdImage className="h-5 w-5 text-gray-500" />
                  </div>
                  <div className="text-sm">
                    Drop your image here or{" "}
                    <span className="cursor-pointer text-olivine-500 hover:text-olivine-700">
                      browse
                    </span>
                  </div>
                  {demo && demo[0]?.name ? (
                    <p className="text-sm">{demo[0]?.name}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </form>

          <div className="mt-6 flex justify-between text-center tracking-widest">
            <button
              onClick={handleOnClose}
              className="rounded-md border-[1px] border-[#CE8172]  px-6 py-2 text-sm text-[#CE8172] transition duration-300 hover:bg-[#f5f0ef] active:ring-1 active:ring-[#CE8172] sm:px-8"
            >
              CANCEL
            </button>
            <button
              onClick={handleSubmit}
              className="rounded-md bg-olivine-500 px-8 py-2 text-sm text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoporateDetailsPopUp;
