import baseAxios from "../core/api/axios/baseAxios";

class CartService {
  static async productPurchase(data) {
    const res = await baseAxios.put("cart", data);

    return res?.data || res;
  }

  static async getCart(data) {
    const res = await baseAxios.get(`cart/${data.user_email}`, data);

    return res?.data || res;
  }

  static async deleteCartItem(data) {
    const res = await baseAxios.delete(
      `cart/${data.itemId}/${data.user_email}`
    );

    return res?.data || res;
  }

  static async deleteAllCartItems(data) {
    const res = await baseAxios.put(`cart/${data.user_email}/empty`);

    return res?.data || res;
  }

  static async updateCartItem(itemId, user_email, data) {
    const res = await baseAxios.put(`cart/${itemId}/${user_email}`, data);

    return res?.data || res;
  }

  static async checkout(data) {
    const res = await baseAxios.post("cart/checkout", data);

    return res?.data || res;
  }

  static async checkoutBasket(data) {
    const res = await baseAxios.post("cart/checkout/basket", data);

    return res?.data || res;
  }

  static async checkoutGiftBasket(data) {
    const res = await baseAxios.post("cart/checkout/gift-basket", data);

    return res?.data || res;
  }

  static async deductDeliveryCharge(data) {
    const res = await baseAxios.post("cart/checkout/deduct", data);

    return res?.data || res;
  }

  static async verifyCoupon(data) {
    const res = await baseAxios.get(
      `cart/coupon/${data.coupon_code}/${data.product_id}`
    );

    return res?.data || res;
  }

  static async createSubscriptionNote(data) {
    const res = await baseAxios.post("cart/checkout/subscription", data);

    return res?.data || res;
  }

  static async paymentTransactions(payload) {
    const res = await baseAxios.post("cart/checkout/pay-transact", payload);

    return res?.data || res;
  }

  static async paymentNotifications() {
    const res = await baseAxios.get("cart/checkout/notification");

    return res?.data || res;
  }

  static async abandonCart(id) {
    const res = await baseAxios.get(`cart/abandon-cart/${id}`);

    return res?.data || res;
  }

  static async singleSalesorderGet(id) {
    const res = await baseAxios.get(`cart/salesorder/${id}`);

    return res?.data || res;
  }

  static async singleSubscriptionGet(id) {
    const res = await baseAxios.get(`cart/subscription/${id}`);

    return res?.data || res;
  }

  static async checkStock(data) {
    const res = await baseAxios.post(`cart/stock-check`, data);

    return res?.data || res;
  }
}

export default CartService;
