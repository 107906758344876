import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import SingleCowryImage from "../../../../assets/singleCowryImage.svg";
import { useDispatch } from "react-redux";
import { fetchRedeemRewardPoints } from "../../../../store/actions";
import { authService } from "../../../../services/authService";
import toast from "react-hot-toast";

const RedeemCowriesPopup = ({
  fetchedRewardBankInfo,
  closeModal,
  openSuccess,
}) => {
  const dispatch = useDispatch();
  const [totalCowries, setTotalCowries] = useState(0);
  const [redeemCowries, setRedeemCowries] = useState();
  const [textError, setTextError] = useState("");

  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (
      fetchedRewardBankInfo &&
      fetchedRewardBankInfo?.reward_balance?.length
    ) {
      setTotalCowries(
        fetchedRewardBankInfo?.reward_balance[0]?.balance_cowries_quantity
      );
    }
  }, [fetchedRewardBankInfo]);

  const validateText = () => {
    const textPattern = /^[0-9]+$/; // Only allow alphanumeric characters, no whitespace or special characters
    if (redeemCowries && !textPattern.test(redeemCowries)) {
      setTextError("Please enter only numbers");
      setRedeemCowries("");
    } else if (redeemCowries > totalCowries) {
      setTextError("You can only redeem from available cowries");
      setRedeemCowries("");
    } else if (redeemCowries % 20 !== 0) {
      setTextError("You can only redeem cowries in multiples of 20");
    } else {
      setTextError("");
    }
  };

  const handleRedeem = async () => {
    try {
      validateText();
      if (textError || !redeemCowries) {
        return toast.error("Please fill all fields.");
      }

      const toastId = toast.loading("Redeeming Reward Points");

      const data = {
        redeem_quantity: redeemCowries?.toString(),
      };

      const payload = {
        customerId,
        data,
      };

      dispatch(fetchRedeemRewardPoints(payload))
        .unwrap()
        .then((res) => {
          if (res?.message_id === 1) {
            openSuccess(true);
            closeModal();
            return toast.success(res?.message_text, {
              id: toastId,
            });
          }
          return toast.error("Cowries could not be redeemed", {
            id: toastId,
          });
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  return (
    <div>
      <div className="flex items-center justify-end">
        <div className="w-fit cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
          <IoCloseSharp
            onClick={() => closeModal()}
            className="h-6 w-6 text-gray-600"
          />
        </div>
      </div>
      <h3 className="text-2xl font-semibold leading-6 text-gray-900">
        Redeem Cowries
      </h3>
      <div className="mt-2">
        <p className="text-sm text-gray-500">
          Please note that you can only redeem cowries in multiples of 20.
        </p>
        <div className="mt-2 flex w-fit flex-row items-center space-x-2 rounded-lg border-[1px] border-[#8DAA6A] bg-[#F6FBEE] px-4 py-3 text-sm font-semibold text-gray-500 sm:px-8">
          <div>
            <img src={SingleCowryImage} alt="icon" className="h-6 w-6" />
          </div>
          <span>
            {fetchedRewardBankInfo &&
            fetchedRewardBankInfo?.reward_balance?.length
              ? fetchedRewardBankInfo?.reward_balance[0]
                  ?.balance_cowries_quantity
              : null}
          </span>
          <span> Cowries = </span>
          <span>
            {fetchedRewardBankInfo &&
            fetchedRewardBankInfo?.reward_balance?.length
              ? `₦${Number(
                  fetchedRewardBankInfo?.reward_balance[0]
                    ?.balance_cowries_amount
                ).toLocaleString()}`
              : null}
          </span>
        </div>
      </div>
      <div className="mt-3 rounded-lg border-[1px] border-gray-100 px-4 pb-6 pt-2 shadow-sm">
        <form>
          <div>
            <label htmlFor="" className="ml-1 text-sm text-gray-500">
              Total Available Cowries:
            </label>
            <div className="rounded-md">
              <input
                type="text"
                placeholder="Pre-filled"
                value={totalCowries}
                autoComplete="off"
                className="pointer-events-none mt-1 block w-full rounded-md border-[1px] border-gray-200 pl-2 text-sm text-gray-600  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-white focus:outline-none focus:ring-1 focus:ring-gray-400"
              />
            </div>
          </div>
          <div className="mt-3">
            <label htmlFor="" className="ml-1 text-sm text-gray-500">
              Amount of cowries you want to redeem
            </label>
            <div className="rounded-md">
              <input
                type="number"
                placeholder="Cowries to redeem"
                autoComplete="off"
                onBlur={validateText}
                value={redeemCowries}
                onChange={(e) => setRedeemCowries(e.target.value)}
                className="mt-1 block w-full rounded-md border-[1px] border-gray-200 pl-2 text-sm text-gray-600  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-white focus:outline-none focus:ring-1 focus:ring-gray-400"
              />

              {textError && (
                <span
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "normal",
                  }}
                >
                  {textError}
                </span>
              )}
            </div>
          </div>
          <div className="mt-3">
            <label htmlFor="" className="ml-1 text-sm text-gray-500">
              Amount to recieve
            </label>
            <div className="rounded-md">
              <input
                type="text"
                placeholder="Amount equivalent"
                autoComplete="off"
                value={redeemCowries ? +redeemCowries * 1000 : ""}
                className="pointer-events-none  mt-1 block w-full rounded-md border-[1px] border-gray-200 pl-2 text-sm text-gray-600  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-white focus:outline-none focus:ring-1 focus:ring-gray-400"
              />
            </div>
          </div>
        </form>
      </div>
      <div className="mt-6 @container">
        <div className="flex flex-col space-y-4 @xs:flex-row @xs:items-center @xs:justify-between @xs:space-y-0 ">
          <button
            type="button"
            className="w-full rounded-md border-[1px] border-gray-300 px-8 py-[11px] text-sm font-medium text-gray-600 hover:bg-gray-100 active:bg-gray-200 @xs:w-fit"
          >
            Cancel
          </button>
          <button
            onClick={() => handleRedeem()}
            type="button"
            className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-6 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 @xs:w-fit"
          >
            Redeem to Wallet
          </button>
        </div>
      </div>
    </div>
  );
};

export default RedeemCowriesPopup;
