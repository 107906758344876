import { authService } from "../../services/authService";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { startLoad, stopLoad } from "./appSlice";
import { handleError } from "./errorSlice";

export const createUser = createAsyncThunk(
  "/register",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await authService.registerUser(data);
      const { access_token } = res;

      if (access_token) {
        authService.setToken(access_token);
      }

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const loginUser = createAsyncThunk(
  "/login",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await authService.loginUser(data);
      const { access_token } = res;
      if (access_token) {
        authService.setToken(access_token);
      }

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const emailVerification = createAsyncThunk(
  "/emailVerification",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await authService.emailVerification(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const emailVerificationResend = createAsyncThunk(
  "/emailVerificationResend",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await authService.emailVerificationResend(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const bvnVerification = createAsyncThunk(
  "/bvnVerification",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await authService.bvnVerify(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "/forgetPassword",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await authService.forgotPassword(data);
      localStorage.setItem("grocedy_userId", res.user_id);
      localStorage.setItem("grocedy_user_email", res.user_email_address);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const newPassword = createAsyncThunk(
  "/newPassword",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await authService.newPassword(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

const initialState = {
  register: "",
  login: "",
  emailVerify: "",
  emailVerifyResend: "",
  forgotPassword: "",
  token: "",
  verification_code: "",
  user_email: "",
  newPassword: "",
  user_id: "",
  bvnVerify: "",
};

const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    checkAuth(state) {
      const data = localStorage.getItem("grocedy_token");
      const vCode = localStorage.getItem("grocedy_verification_code");
      const userId = localStorage.getItem("grocedy_userId");
      const email = localStorage.getItem("grocedy_user_email");
      if (!data) return;
      state.token = data;
      state.verification_code = vCode;
      state.user_email = email;
      state.user_id = userId;
    },
    logout(state) {
      // localStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("basket_type");

      state.token = null;
      state.verification_code = null;
      state.user_email = null;
      state.user_id = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.register = payload;
    });

    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.login = payload;
    });

    builder.addCase(emailVerification.fulfilled, (state, { payload }) => {
      state.emailVerify = payload;
    });

    builder.addCase(emailVerificationResend.fulfilled, (state, { payload }) => {
      state.emailVerifyResend = payload;
    });

    builder.addCase(bvnVerification.fulfilled, (state, { payload }) => {
      state.bvnVerify = payload;
    });

    builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
      state.forgotPassword = payload;
    });

    builder.addCase(newPassword.fulfilled, (state, { payload }) => {
      state.newPassword = payload;
    });
  },
});

const { reducer, actions } = AuthSlice;

export const { checkAuth, logout } = actions;

export default reducer;
