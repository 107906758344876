import baseAxios from "../core/api/axios/baseAxios";

class PaystackService {
  static async verifyPayment(ref) {
    const res = await baseAxios.get(`paystack/verify/${ref}`);

    return res?.data || res;
  }

  static async createPlan(data) {
    const res = await baseAxios.post("paystack/plan", data);

    return res?.data || res;
  }

  static async verifyPaymentRecurr(data) {
    const res = await baseAxios.get(
      `paystack/verify/recurr/${data.ref}/${data.type}`
    );

    return res?.data || res;
  }

  static async initializeTransaction(data) {
    const res = await baseAxios.post("paystack/transaction/initialize", data);

    return res?.data || res;
  }

  static async cancelPlan(ref) {
    const res = await baseAxios.get(`paystack/cancel-plan/${ref}`);

    return res?.data || res;
  }
}

export default PaystackService;
