import React from "react";

function HeaderItems({ title }) {
  return (
    <div className="group cursor-pointer items-center px-5 py-3 text-base text-gray-600 transition-all duration-300 hover:bg-[#F0F4E7] hover:text-olivine-500 md:py-2 lg:rounded-md lg:hover:bg-[#F0F4E7] lg:hover:ring-[1px] lg:hover:ring-gray-200">
      {title}
    </div>
  );
}

export default HeaderItems;
