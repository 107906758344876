import React, { useEffect, useState } from "react";
import Container from "../../../components/pages/my_container/Container";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { authService } from "../../../services/authService";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { fetchRewardBankInfo } from "../../../store/actions";

const ReferralBonus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fetchedRewardBankInfo, setFetchedRewardBankInfo] = useState({});

  const userReferralCode = authService.getCurrentUserReferralCode();
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (customerId) {
      dispatch(fetchRewardBankInfo(customerId))
        .unwrap()
        .then((res) => {
          setFetchedRewardBankInfo(res);
        })
        .catch((err) => err);
    }
  }, [customerId]);

  return (
    <>
      <SignedInNavbarImproved />
      <div className="bg-white pb-40">
        <Container className="">
          <div className="mt-10 flex items-center space-x-2 sm:space-x-4">
            <div className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-100">
              <BiArrowBack
                onClick={() => navigate(-1)}
                className="h-5 w-5 cursor-pointer text-gray-600"
              />
            </div>
            <h2 className="text-lg font-bold text-gray-700 sm:text-2xl">
              Referral Bonus
            </h2>
          </div>
          <div className="mt-8 max-w-3xl">
            <div className="mt-8 w-full rounded-lg border-[1px] border-gray-200 bg-[#F9F9F9] p-6">
              <h4 className="text-2xl font-semibold text-gray-700">
                Grocedy Referral Bonus
              </h4>

              <p className="mt-4 text-sm font-medium text-gray-500">
                Refer a friend and receive 3 Cowries when they complete a
                payment cycle.
              </p>
              <p className="mt-3 text-sm font-semibold text-gray-600">
                Referral Code
              </p>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(userReferralCode);
                  toast.success("Copied to clipboard!");
                }}
                className="mt-1 flex w-fit flex-row items-center space-x-2 rounded-md border-[1px] border-[#8DAA6A] bg-[#F6FBEE] px-4 py-2 text-sm font-semibold text-gray-500"
              >
                <p>{userReferralCode}</p>
                <span className="cursor-pointer">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.9375 18.75H7.1875C6.44158 18.75 5.72621 18.4537 5.19876 17.9262C4.67132 17.3988 4.375 16.6834 4.375 15.9375V7.1875C4.375 6.44158 4.67132 5.72621 5.19876 5.19876C5.72621 4.67132 6.44158 4.375 7.1875 4.375H15.9375C16.6834 4.375 17.3988 4.67132 17.9262 5.19876C18.4537 5.72621 18.75 6.44158 18.75 7.1875V15.9375C18.75 16.6834 18.4537 17.3988 17.9262 17.9262C17.3988 18.4537 16.6834 18.75 15.9375 18.75Z"
                      fill="#8DAA6A"
                    />
                    <path
                      d="M6.25 3.125H15.4641C15.2696 2.57705 14.9104 2.10268 14.4356 1.76699C13.9609 1.43129 13.3939 1.2507 12.8125 1.25H4.0625C3.31658 1.25 2.60121 1.54632 2.07376 2.07376C1.54632 2.60121 1.25 3.31658 1.25 4.0625V12.8125C1.2507 13.3939 1.43129 13.9609 1.76699 14.4356C2.10268 14.9104 2.57705 15.2696 3.125 15.4641V6.25C3.125 5.4212 3.45424 4.62634 4.04029 4.04029C4.62634 3.45424 5.4212 3.125 6.25 3.125Z"
                      fill="#8DAA6A"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className="mt-6 w-full rounded-lg border-[1px] border-gray-200 bg-[#F9F9F9] p-6">
              <div className="flex flex-row items-center space-x-2">
                <div>
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" rx="2" fill="#E8F3D4" />
                    <path
                      d="M14.666 11.8335C15.7727 11.8335 16.6593 10.9402 16.6593 9.8335C16.6593 8.72683 15.7727 7.8335 14.666 7.8335C13.5593 7.8335 12.666 8.72683 12.666 9.8335C12.666 10.9402 13.5593 11.8335 14.666 11.8335ZM9.33268 11.8335C10.4393 11.8335 11.326 10.9402 11.326 9.8335C11.326 8.72683 10.4393 7.8335 9.33268 7.8335C8.22602 7.8335 7.33268 8.72683 7.33268 9.8335C7.33268 10.9402 8.22602 11.8335 9.33268 11.8335ZM9.33268 13.1668C7.77935 13.1668 4.66602 13.9468 4.66602 15.5002V17.1668H13.9993V15.5002C13.9993 13.9468 10.886 13.1668 9.33268 13.1668ZM14.666 13.1668C14.4727 13.1668 14.2527 13.1802 14.0193 13.2002C14.7927 13.7602 15.3327 14.5135 15.3327 15.5002V17.1668H19.3327V15.5002C19.3327 13.9468 16.2193 13.1668 14.666 13.1668Z"
                      fill="#558223"
                    />
                  </svg>
                </div>
                <div>
                  <p className="text-xs font-medium text-gray-400">
                    Total Earnings
                  </p>
                  <p className="mt-[1px] text-base font-bold text-gray-600 ">
                    {fetchedRewardBankInfo &&
                      fetchedRewardBankInfo?.referral_earning?.length &&
                      `₦ ${Number(
                        fetchedRewardBankInfo?.referral_earning[0]
                          ?.referral_earning_cowries
                      ).toLocaleString()}`}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="mt-8 ">
              <h3 className="text-lg font-normal text-gray-400">
                MY REFERRALS
              </h3>
            </div>
            <div className="mt-2 w-full rounded-lg border-[1px] border-gray-200 bg-[#F9F9F9] p-6">
              <p className="text-lg font-bold text-gray-600 ">
                Firstname Lastname
              </p>
              <p className="mt-1 text-xs font-medium text-gray-400">
                11 Nov 2023
              </p>
            </div> */}
            <div className="mt-20">
              <button
                onClick={() => {
                  navigator.clipboard.writeText(userReferralCode);
                  toast.success("Copied to clipboard!");
                }}
                className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-4 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 xsm:text-sm"
              >
                Copy Referral Code
              </button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ReferralBonus;
