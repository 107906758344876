import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { init_data } from "../../core/helpers";
import { authService } from "../../services/authService";
import { addNin, todoListStatus } from "../../store/actions";

const NIN = ({ onClose }) => {
  const { nin } = useSelector((state) => state.masterData);

  const [input, setInput] = useState(
    nin && nin?.national_id ? nin?.national_id : ""
  );
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const toastId = toast.loading("Submitting your NIN...");

      const data = {
        sql_ref_no: "10",
        user_id: userId?.toString(),
        verification_code,
        nin_number: input,
        option: "10",
      };

      dispatch(addNin(data))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            return toast.error("NIN could not be submitted!", {
              id: toastId,
            });
          }
          setInput("");
          dispatch(todoListStatus(init_data));
          toast.success("NIN Submitted", {
            id: toastId,
          });

          onClose();
          window.location.reload();
        })
        .catch((err) => {
          setInput("");
          toast.error("Nin could not be submitted!", {
            id: toastId,
          });

          navigate("/login");

          return err;
        });
    } catch (err) {
      return err;
    }
  };

  const handleOnClose = (e) => {
    onClose();
  };

  return (
    <div className="mx-auto h-full w-full max-w-[420px] px-2 pb-16 pt-64 xsm:px-3">
      <div className="rounded-2xl bg-white px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <div className="font-semibold text-gray-700 xsm:text-lg sm:text-xl">
            Add Your NIN
          </div>
          <button
            className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
            onClick={handleOnClose}
          >
            <CgClose className="h-5 w-5 text-gravel-500" />
          </button>
        </div>
        <div className="mt-2 text-xs">
          Please enter your National Identification Number to start using
          Grocedy
        </div>
        <div>
          <form>
            <div className="mt-2">
              <label
                htmlFor="nationalIdentificationNumber"
                className="ml-3 text-xs text-gray-500"
              >
                National Identification Number
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    value={input}
                    onChange={(e) => {
                      const limit = 11;

                      // 👇️ only take first N characters
                      setInput(e.target.value.slice(0, limit));
                    }}
                    type="number"
                    placeholder="Enter your NIN"
                    className="block w-full rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="mt-6 flex justify-between text-center tracking-widest">
            <button
              onClick={handleOnClose}
              className="rounded-md border-[1px] border-[#CE8172]  px-6 py-2 text-sm text-[#CE8172] transition duration-300 hover:bg-[#f5f0ef] active:ring-1 active:ring-[#CE8172] sm:px-8"
            >
              CANCEL
            </button>
            <button
              onClick={handleSubmit}
              disabled={!input}
              className="rounded-md bg-olivine-500 px-8 py-2 text-sm text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NIN;
