import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { MdImage } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";
import SignedInNavbar from "./SignedInNavbar";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentFileId } from "../store/actions";
import axios from "axios";
import { Store } from "react-notifications-component";
import { notification } from "../core/helpers";

function UploadValidIdCard() {
  const dispatch = useDispatch();
  const [idType, setIdType] = useState("");
  const [demo, setDemo] = useState();
  const [fetchedDocumentFileId, setFectchedDocumentFileId] = useState("");
  const { documentFileId } = useSelector((state) => state.masterData);

  const verification_code = localStorage.getItem("grocedy_verification_code");
  const userId = localStorage.getItem("grocedy_userId");
  const user_email = localStorage.getItem("grocedy_user_email");
  const token = localStorage.getItem("grocedy_token");

  useEffect(() => {
    if (!documentFileId) {
      const data = {
        sql_ref_no: "12",
        email_address: user_email,
        verification_code,
        sql_option: "4",
      };

      dispatch(getDocumentFileId(data));
    }

    if (documentFileId) setFectchedDocumentFileId(documentFileId);
  }, [dispatch, documentFileId, verification_code, user_email]);

  const handleChange = (event) => {
    setIdType(event.target.value);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    onDrop: (acceptedFiles) => {
      const mappedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setDemo(mappedFiles);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fileId = "";
    let fileTypeName = "";
    let documentTypeId = "";

    switch (demo[0].type.split("/").pop()) {
      case "jpeg":
        fileId = fetchedDocumentFileId?.File_Extension[0]?.Id;

        fileTypeName = "png";
        break;
      case "jpg":
        fileId = fetchedDocumentFileId?.File_Extension[0]?.Id;
        fileTypeName = "jpg";
        break;
      case "png":
        fileId = fetchedDocumentFileId?.File_Extension[1]?.Id;
        fileTypeName = "png";
        break;
      default:
        return;
    }

    switch (idType) {
      case "VotersCard":
        documentTypeId =
          fetchedDocumentFileId.Document_Type[0].Document_Type_Id;
        break;
      case "DriversLiscense":
        documentTypeId =
          fetchedDocumentFileId.Document_Type[1].Document_Type_Id;
        break;
      case "InternationalPassport":
        documentTypeId =
          fetchedDocumentFileId.Document_Type[2].Document_Type_Id;
        break;
      default:
        return;
    }

    const data = {
      sql_ref_no: "15",
      email_address: user_email,
      verification_code,
      document_typeid: documentTypeId.toString(),
      file_typeid: fileId.toString(),
      //   image_byte: "",
      // user_id: userId,
      file: demo[0],
      Image_name: `${userId}_${documentTypeId}.${fileTypeName}`,
      sql_option: "1",
    };

    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    const requestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_PUBLIC_SERVER_URL}master-data/documentUpload`,
        formData,
        requestConfig
      )
      .then(() => {
        Store.addNotification({
          ...notification,
          type: "success",
          title: "Successful Upload",
          message: "Profile Image Uploaded",
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        // setUploadedFileId(fileId);
        setDemo(undefined);
      })
      .catch(() => {
        setDemo(undefined);
        // setUploadedFileId("");
        Store.addNotification({
          ...notification,
          type: "danger",
          title: "Something Went Wrong!",
          message: "",
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });

    // dispatch(getDocumentUpload(formData))
    //   .unwrap()
    //   .then(() => {
    //     setDemo(undefined);
    //     setIsLoading(true);
    //     Store.addNotification({
    //       ...notification,
    //       type: "success",
    //       title: "Successful Upload",
    //       message: "Profile Picture Successfully Set",
    //       dismiss: {
    //         duration: 3000,
    //         onScreen: true,
    //       },
    //     });
    //   })
    //   .catch((err) => {
    //     setDemo(undefined);
    //     setIsLoading(false);
    //     Store.addNotification({
    //       ...notification,
    //       type: "danger",
    //       title: "Something Went Wrong!",
    //       message: "",
    //       dismiss: {
    //         duration: 3000,
    //         onScreen: true,
    //       },
    //     });

    //     return err;
    //   });
  };

  return (
    <div>
      <SignedInNavbar />
      <div className="absolute bottom-0 left-0 right-0 top-0 z-50 h-[1050px] w-full pb-8 sm:grid sm:bg-[#313131]/60 sm:pt-4">
        <div className="h-hug w-hug rounded-2xl bg-white px-8 tracking-wider sm:m-auto sm:mt-12 sm:border-2 sm:border-gray-200 sm:py-8 sm:shadow-md">
          <div className="py-4 sm:hidden">
            <BiArrowBack className="h-6 w-6 cursor-pointer" />
          </div>
          <div className="flex items-center justify-between">
            <div className="text-2xl font-bold text-gravel-500">
              Upload a Valid ID Card
            </div>
            <button className="hidden sm:flex">
              <CgClose className="h-7 w-7 text-gravel-500" />
            </button>
          </div>
          <div className="mt-4 text-lg sm:pr-8">
            Upload a valid ID card to start using Grocedy
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <div className="mt-4 text-sm">
                <label>Select ID Type</label>
                <div className="">
                  <div className="mt-4 flex items-center">
                    <input
                      type="radio"
                      id="IdCard"
                      value="VotersCard"
                      name="idCard"
                      onChange={handleChange}
                      className="h-3 w-3 cursor-pointer text-olivine-500 focus:ring-0"
                    />
                    <label
                      id="IdCard"
                      value="VotersCard"
                      name="idCard"
                      className="ml-4"
                    >
                      Voter’s Card
                    </label>
                  </div>
                  <div className="mt-1 flex items-center">
                    <input
                      type="radio"
                      id="IdCard"
                      value="DriversLiscense"
                      name="idCard"
                      onChange={handleChange}
                      className="h-3 w-3 cursor-pointer text-olivine-500 focus:ring-0"
                    />
                    <label
                      id="IdCard"
                      value="DriversLiscense"
                      name="idCard"
                      className="ml-4"
                    >
                      Driver’s Liscense
                    </label>
                  </div>
                  <div className="mt-1 flex items-center">
                    <input
                      type="radio"
                      id="IdCard"
                      value="InternationalPassport"
                      name="idCard"
                      onChange={handleChange}
                      className="h-3 w-3 cursor-pointer text-olivine-500 focus:ring-0"
                    />
                    <label
                      id="IdCard"
                      value="InternationalPassport"
                      name="idCard"
                      className="ml-4"
                      onChange={handleChange}
                    >
                      International Passport
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label className="pl-2 text-xs">Upload ID</label>
                <div
                  htmlFor="demo"
                  {...getRootProps()}
                  className="w-hug grid h-24 rounded-md border-2 border-dotted border-gray-300 bg-white"
                >
                  <input {...getInputProps()} />
                  <div className="m-auto grid">
                    <div className="m-auto">
                      <MdImage className="h-5 w-5 text-gray-600" />
                    </div>
                    <div className="text-sm">
                      Drop your image here or{" "}
                      <span className="cursor-pointer text-olivine-500 hover:text-olivine-700">
                        browse
                      </span>
                    </div>
                    {demo && demo[0]?.name ? (
                      <p className="text-sm">{demo[0]?.name}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-16 text-center tracking-widest sm:mt-8 sm:flex sm:justify-between">
                <button className="hidden rounded-md border-2 border-[#CE8172] px-8 py-3 text-[#CE8172] hover:bg-[#f5f0ef] sm:flex sm:py-2">
                  CANCEL
                </button>
                <button className="w-full rounded-md  bg-olivine-500 py-3 text-white hover:bg-olivine-600 sm:w-32 sm:py-2">
                  SAVE
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadValidIdCard;
