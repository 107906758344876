import React, { useEffect, useState } from "react";
import TrackPageLarge from "./TrackPageLarge";
import TrackPageSidebar from "./TrackPageSidebar";
import TrackPageSmall from "./TrackPageSmall";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activePlans, getDeliveryData } from "../../../store/actions";
import {
  setSalesOrder,
  setSelectedProductName,
} from "../../../store/slices/modalSlice";
import { authService } from "../../../services/authService";
import moment from "moment";

function TrackPageId() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const customerId = authService.getCurrentUserZohoCustomerId();
  const [fetchedTracking, setFetchedTracking] = useState([]);
  const [filteredFetchedTracking, setFilteredFetchedTracking] = useState([]);
  const [defaultFetchedTracking, setDefaultFetchedTracking] = useState([]);
  const [fetchedActiveSalesOrder, setFetchedActiveSalesOrder] = useState([]);
  const [fetchedActivePlans, setFetchedActivePlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setClose] = useState(true);
  const [deliverySalesData, setDeliverySalesData] = useState(
    fetchedTracking[0]
  );
  const { selectedSalesOrder } = useSelector((state) => state.modal);
  const { activePlansCustomer } = useSelector((state) => state.masterData);

  function handleOpen(saleOrder) {
    setDeliverySalesData(saleOrder);
  }

  function toggleView() {
    setClose(!open);
  }

  useEffect(() => {
    try {
      if (!activePlansCustomer) {
        dispatch(activePlans(customerId));
      }
    } catch (err) {
      return err;
    }
  }, [dispatch, customerId, activePlansCustomer]);

  useEffect(() => {
    try {
      if (activePlansCustomer) {
        setFetchedActivePlans(activePlansCustomer?.subscription_list);
        setFetchedActiveSalesOrder(activePlansCustomer?.salesorder_list);
      }
    } catch (err) {
      return err;
    }
  }, [activePlansCustomer]);

  useEffect(() => {
    if (params.id && !selectedSalesOrder?.length) {
      const saleOrderTrack = fetchedActiveSalesOrder?.filter(
        (saleOrder) => saleOrder?.subscription_id === params.id
      );

      const subscriptionProductName = fetchedActivePlans
        ?.filter((saleOrder) => saleOrder?.subscription_id === params.id)
        .pop();

      if (saleOrderTrack?.length) {
        dispatch(setSalesOrder(saleOrderTrack));
        // dispatch(setSelectedProductName(subscriptionProductName?.name));
        dispatch(
          setSelectedProductName({
            planName: subscriptionProductName?.name,
            planPrice: subscriptionProductName?.amount,
            planDuration: `${moment(
              subscriptionProductName?.current_term_starts_at
            ).format("MMMM YYYY")} - ${moment(
              subscriptionProductName?.current_term_ends_at
            ).format("MMMM YYYY")}`,
            symbol: subscriptionProductName?.currency_symbol,
          })
        );
        navigate(`/track/${params.id}`);
      }
    }
  }, [
    params.id,
    selectedSalesOrder,
    fetchedActiveSalesOrder,
    dispatch,
    navigate,
    fetchedActivePlans,
  ]);

  useEffect(() => {
    if (fetchedTracking && fetchedTracking?.length) {
      const filteredItem = [];

      selectedSalesOrder?.forEach((so) => {
        return fetchedTracking?.filter((ft) => {
          if (ft?.pickup.salesorder_id === so?.salesorder_number) {
            return filteredItem.push({
              order_info: ft,
              shipping_address: so?.shipping_address,
            });
          }
          return null;
        });
      });

      setFilteredFetchedTracking(filteredItem);
      setDefaultFetchedTracking(filteredItem[0]);
    }
  }, [fetchedTracking, selectedSalesOrder]);

  useEffect(() => {
    if (selectedSalesOrder?.length) {
      setLoading(true);
      Promise.all(
        selectedSalesOrder?.forEach((so) => {
          return dispatch(getDeliveryData(so?.salesorder_number))
            .unwrap()
            .then((res) => {
              setFetchedTracking((oldArray) => [...oldArray, res]);
            })
            .catch((err) => err);
        })
      );
    }
  }, [selectedSalesOrder, dispatch, navigate]);

  return (
    <>
      {selectedSalesOrder?.length ? (
        <div>
          <div className="lg:hidden">
            <TrackPageSmall
              clicker={handleOpen}
              selectedOrders={filteredFetchedTracking}
              mapData={deliverySalesData}
              open={open}
              defaultTracking={defaultFetchedTracking}
            />
          </div>
          <div className="flex w-full">
            <div className="hidden w-[550px] lg:inline">
              <TrackPageSidebar
                clicker={handleOpen}
                selectedOrders={filteredFetchedTracking}
              />
            </div>
            <div className="hidden w-full lg:inline">
              <TrackPageLarge
                // clicker={handleOpen}
                open={open}
                viewer={toggleView}
                defaultTracking={defaultFetchedTracking}
                loading={loading}
                mapData={deliverySalesData}
              />
            </div>
          </div>
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
}

export default TrackPageId;
