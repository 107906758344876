import React, { useEffect, useState } from "react";
import { BiArrowBack, BiEditAlt } from "react-icons/bi";
import { IoMdInformation } from "react-icons/io";
import { MdOutlineFileDownload } from "react-icons/md";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { Modal, Box } from "@mui/material";
import PurchaseGiftVoucher from "./PurchaseGiftVoucher";
import ImportantNotice from "./ImportantNotice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { downloadFile } from "../../../core/helpers";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { voucherBulkAssignImport } from "../../../store/actions";
import UpdateImportedDetails from "./UpdateImportedDetails";

function ListOfVouchers() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const { createVoucherBulk, updatedBulkVouchers } = useSelector(
    (state) => state.voucher
  );
  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [fetchedVoucherList, setFetchedVoucherList] = useState([]);
  const [selectedBulkVoucher, setSelectedBulkVoucher] = useState([]);
  const [isReassign, setReAssign] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDetailImport, setSelectedDetailImport] = useState({});
  const [selectedDetailImportIdx, setSelectedDetailImportIdx] = useState(0);
  const [importedCsvFile, setImportedCsvFile] = useState([]);
  const [combinedArray, setCombinedArray] = useState([]);
  const { userNextAction } = useSelector((state) => state.modal);

  const onDrop = async (acceptedFiles) => {
    try {
      const file = acceptedFiles[0]; // Assuming you allow only one file at a time
      handleFileUpload(file);
    } catch (error) {
      console.error(error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (userNextAction === "Import") {
      setSelectedIndex(1);
    }
  }, [userNextAction]);

  useEffect(() => {
    if (
      importedCsvFile?.length &&
      fetchedVoucherList?.length &&
      fetchedVoucherList?.length < importedCsvFile?.length
    ) {
      toast.error("You do not have enough voucher for the imported recipients");
    }

    if (
      importedCsvFile?.length &&
      fetchedVoucherList?.length &&
      (importedCsvFile?.length === fetchedVoucherList?.length ||
        fetchedVoucherList?.length > importedCsvFile?.length)
    ) {
      const mergedItems = mergeArrays(importedCsvFile, fetchedVoucherList);

      setCombinedArray(mergedItems);
    }
  }, [importedCsvFile, fetchedVoucherList]);

  useEffect(() => {
    if (updatedBulkVouchers && createVoucherBulk) {
      setFetchedVoucherList(updatedBulkVouchers?.voucher_info);
    }

    if (createVoucherBulk && !updatedBulkVouchers) {
      setFetchedVoucherList(createVoucherBulk?.voucher_info);
    }

    if (!updatedBulkVouchers && !createVoucherBulk) {
      navigate(-1);
    }
  }, [createVoucherBulk, updatedBulkVouchers, navigate]);

  const handleFileUpload = (file) => {
    if (file?.name?.endsWith(".csv")) {
      handleCsvConversion(file);
    }
    // Check if the file is in Excel format
    else if (file?.name?.endsWith(".xls") || file?.name?.endsWith(".xlsx")) {
      handleExcelConversion(file);
    } else {
      console.error("Unsupported file format");
    }
  };

  const handleCsvConversion = (file) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const contents = e.target.result;
        parseCSV(contents);
      };

      reader.readAsText(file);
    }
  };

  const handleExcelConversion = (file) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const contents = e.target.result;
        parseExcel(contents);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const parseCSV = (contents) => {
    Papa.parse(contents, {
      header: true,
      complete: (result) => {
        const csvData = result.data;
        const updatedCsvData = csvData.slice(0, csvData.length - 1);
        setImportedCsvFile(updatedCsvData);
        // You can now use the 'result.data' array of objects as needed
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const parseExcel = (contents) => {
    try {
      const data2 = new Uint8Array(contents);
      const workbook = XLSX?.read(data2, { type: "array" });
      const sheetName = workbook?.SheetNames[0];
      const sheet = workbook?.Sheets[sheetName];
      const jsonResult = XLSX?.utils?.sheet_to_json(sheet, { header: 1 });

      const transformedArray = jsonResult.slice(1).map((row) => {
        return jsonResult[0].reduce((obj, key, index) => {
          obj[key] = row[index].toString();
          return obj;
        }, {});
      });

      setImportedCsvFile(transformedArray);
    } catch (error) {
      console.error("Error parsing Excel:", error);
    }
  };

  const mergeArrays = (importedCsvFile, fetchedVoucherList) => {
    let merged = [];
    if (fetchedVoucherList?.length > importedCsvFile?.length) {
      const sliced = fetchedVoucherList.slice(0, importedCsvFile.length);
      merged = sliced?.map((voucher, idx) => ({
        ...voucher,
        ...importedCsvFile[idx],
      }));

      return merged;
    }

    merged = fetchedVoucherList?.map((voucher, idx) => ({
      ...voucher,
      ...importedCsvFile[idx],
    }));

    return merged;
  };

  const handleAssignBulk = () => {
    const bulkImports =
      combinedArray &&
      combinedArray?.length &&
      combinedArray.map((voucher) => {
        if (!voucher?.name || !voucher?.mobile) {
          return;
        }

        return `<a>
          <voucher_no>${voucher?.voucher_number}</voucher_no>
          <name>${voucher?.name}</name>
          <mobile>${voucher?.mobile}</mobile>
          <email>${voucher?.email}</email>
        </a>`;
      });

    const xmlStructure = `<root>${bulkImports}</root>`;

    const removedString = removeSpacesAndNewlines(xmlStructure);

    const toastId = toast.loading("Creating vouchers");

    const payload = {
      xml_voucher: removedString,
    };

    dispatch(voucherBulkAssignImport(payload))
      .unwrap()
      .then((res) => {
        if (res.status === 403) {
          return toast.error(res.response.error, {
            id: toastId,
          });
        }

        if (res.result_status[0].message_id !== 1) {
          return toast.error(res.result_status[0].message_text, {
            id: toastId,
          });
        }

        toast.success("Bulk Vouchers Created Successfully", {
          id: toastId,
        });

        return navigate("/voucher-history");
      })
      .catch((err) => err);
  };

  const removeSpacesAndNewlines = (str) => {
    const cleanedXmlString = str.replace(/\n/g, "").replace(/\s/g, "");

    return cleanedXmlString;
  };

  const handleDetailsUpdate = (obj, idx) => {
    const existingDetails = [...combinedArray];

    existingDetails[idx] = obj;

    setCombinedArray(existingDetails);
  };

  return (
    <div>
      <SignedInNavbarImproved />
      <div className="mx-auto mt-10 max-w-[1600px] px-3 pb-40 xsm:px-6 sm:px-8 md:px-12 lg:px-14">
        <div className="mt-4 flex items-center space-x-2 sm:space-x-4">
          <div className="rounded-full p-1 hover:bg-gray-200">
            <BiArrowBack
              onClick={() => navigate(-1)}
              className="h-6 w-6 cursor-pointer text-gray-600"
            />
          </div>
          <h1 className="text-lg font-bold text-gray-700 sm:text-2xl">
            List of Vouchers
          </h1>
        </div>
        <div className="mt-6">
          <p className="text-sm font-medium text-gray-500">
            Total Voucher:
            <span className="ml-2 text-base text-olivine-600">
              {fetchedVoucherList?.length}
            </span>
          </p>
        </div>
        <div className="mt-6">
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="flex items-center space-x-4 text-sm xsm:space-x-6 xsm:text-base">
              <div className="items-centern flex space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer">
                        <div className="px-1 font-semibold">
                          Assign{" "}
                          <span className="hidden sm:inline">Vouchers</span>
                        </div>
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
              <div className="items-centern flex space-x-6 xsm:space-x-8">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "border-b-[2px] border-olivine-500 border-opacity-100 py-1  text-olivine-500 outline-none"
                          : "border-b-[2px] border-olivine-500 border-opacity-0 py-1 text-gravel-500"
                      }
                    >
                      <div className="cursor-pointer">
                        <div className="px-1 font-semibold">
                          Import{" "}
                          <span className="hidden sm:inline">
                            Recipient List
                          </span>
                        </div>
                      </div>
                    </button>
                  )}
                </Tab>
              </div>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="space-y-4">
                {fetchedVoucherList && fetchedVoucherList?.length
                  ? fetchedVoucherList?.map((voucher, idx) => (
                      <div
                        key={idx}
                        className="rounded-md border-[0.5px] border-gray-100 px-6 py-3 shadow"
                      >
                        <div className="flex items-center justify-between">
                          <h3 className="p-2 text-xs font-medium text-gray-500">
                            {`S.N - ${idx + 1}`}
                          </h3>
                          <p className="text-sm font-bold text-[#EFA92B]">{`NGN ${voucher?.voucher_amount}`}</p>
                        </div>
                        <p className="text-sm font-normal text-gray-500">
                          Voucher No :
                          <span className="font-bold text-gray-700">
                            {voucher?.voucher_number}
                          </span>
                        </p>
                        {voucher?.voucher_status_id === 3 && (
                          <p
                            onClick={() => {
                              setOpen(true);
                              setReAssign(false);
                              setSelectedBulkVoucher(voucher);
                            }}
                            className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                          >
                            Assign and share
                          </p>
                        )}

                        {voucher?.voucher_status_id === 2 && (
                          <p className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4">
                            Redeemed
                          </p>
                        )}

                        {voucher?.voucher_status_id === 1 && (
                          <div className="flex w-1/4 flex-row justify-between">
                            <p
                              onClick={() =>
                                navigate("/voucher/createVoucherSuccess")
                              }
                              className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                            >
                              Share
                            </p>
                            <p
                              onClick={() => {
                                setOpen(true);
                                setReAssign(true);
                                setSelectedBulkVoucher(voucher);
                              }}
                              className="mt-2 cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
                            >
                              Edit
                            </p>
                          </div>
                        )}
                      </div>
                    ))
                  : null}
              </Tab.Panel>
              <Tab.Panel className="space-y-4">
                {combinedArray && combinedArray?.length ? (
                  combinedArray?.map((userVoucher, idx) => (
                    <div
                      key={idx}
                      className="space-y-1 rounded-md border-[0.5px] border-gray-100 px-6 py-3 shadow"
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-normal text-gray-500">
                          Voucher No :{" "}
                          <span className="font-bold text-gray-600">
                            {userVoucher?.voucher_number}
                          </span>
                          <BiEditAlt
                            onClick={() => {
                              setIsEditing(true);
                              setSelectedDetailImport(userVoucher);
                              setSelectedDetailImportIdx(idx);
                            }}
                            className="cursor-pointer"
                            size={24}
                          />
                        </p>
                        <p className="text-sm font-bold text-[#EFA92B]">
                          {`NGN ${userVoucher?.voucher_amount}`}
                        </p>
                      </div>
                      <p className="text-xs text-gray-500">
                        Rec. Name :{" "}
                        <span className="font-bold text-gray-600">
                          {userVoucher.name}
                        </span>
                      </p>
                      <div className="space-y-1 sm:flex sm:items-center sm:justify-between sm:space-y-0">
                        <p className="text-xs font-normal text-gray-500">
                          Rec. Mobile Number :{" "}
                          <span className="font-bold text-gray-600">
                            {userVoucher.mobile}
                          </span>
                        </p>
                        <p className="text-xs font-normal text-gray-500">
                          Email Address :{" "}
                          <span className="font-bold text-gray-600">
                            {userVoucher.email}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className=" max-w-3xl rounded-lg border-[0.5px] border-gray-200 bg-white px-2 py-2 shadow-md xsm:px-4 sm:px-6">
                    <div
                      {...getRootProps({})}
                      className="mt-8 flex items-center space-x-2"
                    >
                      <div>
                        <input {...getInputProps()} multiple={false} />
                        <button className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 px-4 py-[15px] text-xs font-semibold text-olivine-500 transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 xsm:text-sm sm:w-auto sm:px-8 sm:py-[11px]">
                          Import Users List via Excel/ Csv
                        </button>
                      </div>
                      <div
                        onClick={() => setOpenTwo(true)}
                        className="flex cursor-pointer rounded-full bg-olivine-600 text-white"
                      >
                        <IoMdInformation className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="mt-8 flex">
                      <div
                        onClick={() => downloadFile()}
                        className="flex w-auto cursor-pointer items-center space-x-2 border-b-[1.5px] border-gray-500 border-opacity-0 px-0 text-sm text-gray-600 hover:border-opacity-100"
                      >
                        <MdOutlineFileDownload className="h-5 w-5 text-gray-500" />
                        Download Sample File
                      </div>
                    </div>
                    {/* <div className="mt-8 max-w-md pb-4">
                      <button className="w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 sm:px-10">
                        Submit
                      </button>
                    </div> */}
                  </div>
                )}
                {importedCsvFile && importedCsvFile?.length ? (
                  <div className="mt-8 max-w-md pb-4">
                    <button
                      onClick={() => handleAssignBulk()}
                      className="w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 sm:px-10"
                    >
                      Submit
                    </button>
                  </div>
                ) : null}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-4 overflow-hidden px-2 xsm:px-4">
          <Box className=" mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <PurchaseGiftVoucher
              selectedBulkVoucher={selectedBulkVoucher}
              setOpen={setOpen}
              setReAssign={setReAssign}
              isReassign={isReassign}
            />
          </Box>
        </div>
      </Modal>
      <Modal
        open={openTwo}
        onClose={() => setOpenTwo(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-16 overflow-hidden px-2 xsm:px-4">
          <Box className=" mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <ImportantNotice setOpenTwo={setOpenTwo} />
          </Box>
        </div>
      </Modal>
      <Modal
        open={isEditing}
        onClose={() => setIsEditing(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-16 overflow-hidden px-2 xsm:px-4">
          <Box className=" mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <UpdateImportedDetails
              setIsEditing={setIsEditing}
              selectedDetailImport={selectedDetailImport}
              selectedDetailImportIdx={selectedDetailImportIdx}
              handleDetailsUpdate={handleDetailsUpdate}
            />
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default ListOfVouchers;
