import React, { useEffect, useState } from "react";
import { MdDateRange } from "react-icons/md";
import Container from "../../../components/pages/my_container/Container";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tab } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import EarnedBreakdownComponents from "./EarnedBreakdownComponents";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch } from "react-redux";
import { authService } from "../../../services/authService";
import {
  fetchEarnedCowriesCustom,
  fetchEarnedCowriesLastWeek,
  fetchEarnedCowriesToday,
} from "../../../store/actions";
import toast from "react-hot-toast";

const people = [{ name: "Days" }, { name: "Weeks" }, { name: "Months" }];

const RewardSummary = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(people[0]);
  const [fetchedEarnedToday, setFetchedEarnedToday] = useState();
  const [fetchedEarnedLastWeek, setFetchedEarnedLastWeek] = useState();
  const [fetchedEarnedLastMonth, setFetchedEarnedLastMonth] = useState();
  const [fetchedEarnedCustom, setFetchedEarnedCustom] = useState();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const customerId = authService.getCurrentCustomerId();

  useEffect(() => {
    if (customerId) {
      dispatch(fetchEarnedCowriesToday(customerId))
        .unwrap()
        .then((res) => {
          setFetchedEarnedToday(res);
        })
        .catch((err) => err);
    }
  }, []);

  console.log("fetchedEarnedToday", fetchedEarnedToday);

  useEffect(() => {
    if (customerId) {
      dispatch(fetchEarnedCowriesLastWeek(customerId))
        .unwrap()
        .then((res) => {
          setFetchedEarnedLastWeek(res);
        })
        .catch((err) => err);
    }
  }, []);

  useEffect(() => {
    if (customerId) {
      dispatch(fetchEarnedCowriesLastWeek(customerId))
        .unwrap()
        .then((res) => {
          setFetchedEarnedLastMonth(res);
        })
        .catch((err) => err);
    }
  }, []);

  const handleCustomSerach = (start, end) => {
    try {
      const toastId = toast.loading("Fetching cowries...");

      const data = {
        from_date: new Intl.DateTimeFormat("en-CA").format(start),
        to_date: new Intl.DateTimeFormat("en-CA").format(end),
      };

      const payload = {
        customerId,
        data,
      };

      dispatch(fetchEarnedCowriesCustom(payload))
        .unwrap()
        .then((res) => {
          toast.success("successful", { id: toastId });
          setFetchedEarnedCustom(res);
        })
        .catch((err) => {
          toast.error("failed", { id: toastId });
          return err;
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      <SignedInNavbarImproved />
      <div className="bg-white @container">
        <Container className="">
          <div className="mt-10 flex items-center space-x-2 sm:space-x-4">
            <div className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-100">
              <BiArrowBack
                onClick={() => navigate(-1)}
                className="h-5 w-5 cursor-pointer text-gray-600"
              />
            </div>
            <h2 className="text-lg font-bold text-gray-700 sm:text-2xl">
              Reward Summary
            </h2>
          </div>

          <div className="mt-6 max-w-4xl">
            <Tab.Group
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
            >
              {/*   Tab section header */}
              <Tab.List className="flex w-full flex-row items-center space-x-1 rounded-lg bg-[#E7E9E5] px-4 py-2.5 text-sm sm:px-6">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "w-full rounded border-opacity-100 bg-white px-4 py-1 text-gray-700 outline-none"
                          : "w-full border-opacity-0 px-4 py-1 text-gray-500 hover:bg-gray-100"
                      }
                    >
                      <p className="cursor-pointer py-2 font-semibold">Today</p>
                    </button>
                  )}
                </Tab>

                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "w-full rounded border-opacity-100 bg-white px-4 py-1 text-gray-700 outline-none"
                          : "w-full border-opacity-0 px-4 py-1 text-gray-500 hover:bg-gray-100"
                      }
                    >
                      <p className="cursor-pointer py-2 font-semibold">
                        Last <span className="hidden xsm:inline">Week</span>
                        <span className="xsm:hidden">Wk</span>
                      </p>
                    </button>
                  )}
                </Tab>

                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "w-full rounded border-opacity-100 bg-white px-4 py-1 text-gray-700 outline-none"
                          : "w-full border-opacity-0 px-4 py-1 text-gray-500 hover:bg-gray-100"
                      }
                    >
                      <p className="cursor-pointer py-2 font-semibold">
                        Last <span className="hidden xsm:inline">Month</span>
                        <span className="xsm:hidden">Mth</span>
                      </p>
                    </button>
                  )}
                </Tab>

                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "w-full rounded border-opacity-100 bg-white px-4 py-1 text-gray-700 outline-none"
                          : "w-full border-opacity-0 px-4 py-1 text-gray-500 hover:bg-gray-100"
                      }
                    >
                      <p className="cursor-pointer py-2 font-semibold">
                        Custom <span className="hidden xsm:inline">Months</span>
                        <span className="xsm:hidden">Mth</span>
                      </p>
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel>
                  <div className="mt-10 pb-40">
                    <div className="rounded-2xl shadow-xl">
                      <div className="flex flex-row items-center justify-between px-4">
                        <p className="text-sm font-semibold text-gray-600">
                          Cowries Earned
                        </p>
                      </div>
                      <div className="mt-10 flex items-center justify-center pb-20">
                        <div
                          className={twMerge(
                            `flex h-[110px] w-[110px] items-center justify-center rounded-full bg-[#558223]/20 `
                          )}
                        >
                          <div
                            style={{
                              background: `conic-gradient(var(--outline, #558223), calc(var(--progress, ${
                                fetchedEarnedToday &&
                                fetchedEarnedToday?.total_reward_info?.length &&
                                fetchedEarnedToday?.total_reward_info[0]
                                  ?.earn_cowries
                              }) * 1%), transparent calc(var(--progress, ${
                                fetchedEarnedToday &&
                                fetchedEarnedToday?.total_reward_info?.length &&
                                fetchedEarnedToday?.total_reward_info[0]
                                  ?.earn_cowries
                              }) * 1%))`,
                            }}
                            className={`flex h-[110px] w-[110px] rounded-full text-2xl`}
                          >
                            <div
                              className={twMerge(
                                `m-auto flex h-[90px] w-[90px] flex-col items-center justify-center -space-y-[9px] rounded-[inherit] bg-white text-gray-400 `
                              )}
                            >
                              <p className="text-sm font-bold text-gray-600">
                                {fetchedEarnedToday &&
                                fetchedEarnedToday?.total_reward_info?.length
                                  ? fetchedEarnedToday?.total_reward_info[0]
                                      ?.earn_cowries
                                  : 0}
                              </p>
                              <p className="text-[10px] font-semibold">
                                Cowries Earned
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {fetchedEarnedToday &&
                    fetchedEarnedToday?.total_reward_info?.length &&
                    fetchedEarnedToday?.total_reward_info[0]?.earn_cowries >
                      0 ? (
                      <div className="mt-16">
                        <h3 className="text-xl font-semibold text-gray-700">
                          Cowries Earned Breakdown
                        </h3>
                        <div className="mt-4 space-y-8">
                          <EarnedBreakdownComponents
                            methodToEarn={
                              fetchedEarnedToday &&
                              fetchedEarnedToday?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedToday?.breakdown_reward_info[0]
                                ?.reward_earn_title
                            }
                            statusEarned={
                              fetchedEarnedToday &&
                              fetchedEarnedToday?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedToday?.breakdown_reward_info[0]
                                ?.reward_cowries_quantity
                            }
                            numberEarned={
                              fetchedEarnedToday &&
                              fetchedEarnedToday?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedToday?.breakdown_reward_info[0]
                                ?.reward_cowries_quantity
                            }
                            transactionAmount={
                              fetchedEarnedToday &&
                              fetchedEarnedToday?.breakdown_reward_info?.length
                                ? `₦${Number(
                                    fetchedEarnedToday?.breakdown_reward_info[0]
                                      ?.reward_cowries_quantity
                                  ).toLocaleString()}`
                                : `₦${Number(0).toLocaleString()}`
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="mt-10 pb-40">
                    <div className="rounded-2xl shadow-xl">
                      <div className="flex flex-row items-center justify-between px-4">
                        <p className="text-sm font-semibold text-gray-600">
                          Cowries Earned
                        </p>
                      </div>
                      <div className="mt-10 flex items-center justify-center pb-20">
                        <div
                          className={twMerge(
                            `flex h-[110px] w-[110px] items-center justify-center rounded-full bg-[#558223]/20 `
                          )}
                        >
                          <div
                            style={{
                              background: `conic-gradient(var(--outline, #558223), calc(var(--progress, ${
                                fetchedEarnedLastWeek &&
                                fetchedEarnedLastWeek?.total_reward_info
                                  ?.length &&
                                fetchedEarnedLastWeek?.total_reward_info[0]
                                  ?.earn_cowries
                              }) * 1%), transparent calc(var(--progress, ${
                                fetchedEarnedLastWeek &&
                                fetchedEarnedLastWeek?.total_reward_info
                                  ?.length &&
                                fetchedEarnedLastWeek?.total_reward_info[0]
                                  ?.earn_cowries
                              }) * 1%))`,
                            }}
                            className={`flex h-[110px] w-[110px] rounded-full text-2xl`}
                          >
                            <div
                              className={twMerge(
                                `m-auto flex h-[90px] w-[90px] flex-col items-center justify-center -space-y-[9px] rounded-[inherit] bg-white text-gray-400 `
                              )}
                            >
                              <p className="text-sm font-bold text-gray-600">
                                {fetchedEarnedLastWeek &&
                                fetchedEarnedLastWeek?.total_reward_info?.length
                                  ? fetchedEarnedLastWeek?.total_reward_info[0]
                                      ?.earn_cowries
                                  : 0}
                              </p>
                              <p className="text-[10px] font-semibold">
                                Cowries Earned
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {fetchedEarnedLastWeek &&
                    fetchedEarnedLastWeek?.total_reward_info?.length &&
                    fetchedEarnedLastWeek?.total_reward_info[0]?.earn_cowries >
                      0 ? (
                      <div className="mt-16">
                        <h3 className="text-xl font-semibold text-gray-700">
                          Cowries Earned Breakdown
                        </h3>
                        <div className="mt-4 space-y-8">
                          <EarnedBreakdownComponents
                            methodToEarn={
                              fetchedEarnedLastWeek &&
                              fetchedEarnedLastWeek?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedLastWeek?.breakdown_reward_info[0]
                                ?.reward_earn_title
                            }
                            statusEarned={
                              fetchedEarnedLastWeek &&
                              fetchedEarnedLastWeek?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedLastWeek?.breakdown_reward_info[0]
                                ?.reward_cowries_quantity
                            }
                            numberEarned={
                              fetchedEarnedLastWeek &&
                              fetchedEarnedLastWeek?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedLastWeek?.breakdown_reward_info[0]
                                ?.reward_cowries_quantity
                            }
                            transactionAmount={
                              fetchedEarnedLastWeek &&
                              fetchedEarnedLastWeek?.breakdown_reward_info
                                ?.length
                                ? `₦${Number(
                                    fetchedEarnedLastWeek
                                      ?.breakdown_reward_info[0]
                                      ?.reward_cowries_quantity
                                  ).toLocaleString()}`
                                : `₦${Number(0).toLocaleString()}`
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="mt-10 pb-40">
                    <div className="rounded-2xl shadow-xl">
                      <div className="flex flex-row items-center justify-between px-4">
                        <p className="text-sm font-semibold text-gray-600">
                          Cowries Earned
                        </p>
                      </div>
                      <div className="mt-10 flex items-center justify-center pb-20">
                        <div
                          className={twMerge(
                            `flex h-[110px] w-[110px] items-center justify-center rounded-full bg-[#558223]/20 `
                          )}
                        >
                          <div
                            style={{
                              background: `conic-gradient(var(--outline, #558223), calc(var(--progress, ${
                                fetchedEarnedLastMonth &&
                                fetchedEarnedLastMonth?.total_reward_info
                                  ?.length &&
                                fetchedEarnedLastMonth?.total_reward_info[0]
                                  ?.earn_cowries
                              }) * 1%), transparent calc(var(--progress, ${
                                fetchedEarnedLastMonth &&
                                fetchedEarnedLastMonth?.total_reward_info
                                  ?.length &&
                                fetchedEarnedLastMonth?.total_reward_info[0]
                                  ?.earn_cowries
                              }) * 1%))`,
                            }}
                            className={`flex h-[110px] w-[110px] rounded-full text-2xl`}
                          >
                            <div
                              className={twMerge(
                                `m-auto flex h-[90px] w-[90px] flex-col items-center justify-center -space-y-[9px] rounded-[inherit] bg-white text-gray-400 `
                              )}
                            >
                              <p className="text-sm font-bold text-gray-600">
                                {fetchedEarnedLastMonth &&
                                fetchedEarnedLastMonth?.total_reward_info
                                  ?.length
                                  ? fetchedEarnedLastMonth?.total_reward_info[0]
                                      ?.earn_cowries
                                  : 0}
                              </p>
                              <p className="text-[10px] font-semibold">
                                Cowries Earned
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {fetchedEarnedLastMonth &&
                    fetchedEarnedLastMonth?.total_reward_info?.length &&
                    fetchedEarnedLastMonth?.total_reward_info[0]?.earn_cowries >
                      0 ? (
                      <div className="mt-16">
                        <h3 className="text-xl font-semibold text-gray-700">
                          Cowries Earned Breakdown
                        </h3>
                        <div className="mt-4 space-y-8">
                          <EarnedBreakdownComponents
                            methodToEarn={
                              fetchedEarnedLastMonth &&
                              fetchedEarnedLastMonth?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedLastMonth?.breakdown_reward_info[0]
                                ?.reward_earn_title
                            }
                            statusEarned={
                              fetchedEarnedLastMonth &&
                              fetchedEarnedLastMonth?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedLastMonth?.breakdown_reward_info[0]
                                ?.reward_cowries_quantity
                            }
                            numberEarned={
                              fetchedEarnedLastMonth &&
                              fetchedEarnedLastMonth?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedLastMonth?.breakdown_reward_info[0]
                                ?.reward_cowries_quantity
                            }
                            transactionAmount={
                              fetchedEarnedLastMonth &&
                              fetchedEarnedLastMonth?.breakdown_reward_info
                                ?.length
                                ? `₦${Number(
                                    fetchedEarnedLastMonth
                                      ?.breakdown_reward_info[0]
                                      ?.reward_cowries_quantity
                                  ).toLocaleString()}`
                                : `₦${Number(0).toLocaleString()}`
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="mt-6 flex flex-col space-y-4 @sm:flex-row @sm:items-center @sm:space-x-2 @sm:space-y-0">
                    <div className="flex flex-row items-center space-x-2">
                      <div className="relative">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Start Date"
                          className="max-w-32 h-[42px] w-full cursor-pointer rounded-md border-gray-300 bg-white text-sm font-medium text-gray-500 focus:border-olivine-500 focus:ring-[1px] focus:ring-olivine-500"
                        />
                        <MdDateRange
                          className={twMerge(
                            "absolute right-1 top-2.5 h-5 w-5 text-olivine-500"
                          )}
                        />
                      </div>
                      <div className="relative">
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="End Date"
                          className="max-w-32 h-[42px] w-full cursor-pointer rounded-md border-gray-300 bg-white text-sm font-medium text-gray-500 focus:border-olivine-500 focus:ring-[1px] focus:ring-olivine-500"
                        />
                        <MdDateRange
                          className={twMerge(
                            "absolute right-1 top-2.5 h-5 w-5 text-olivine-500"
                          )}
                        />
                      </div>
                    </div>

                    <div className="">
                      <button
                        onClick={() => handleCustomSerach(startDate, endDate)}
                        className="w-full cursor-pointer rounded-md border-[1px] border-olivine-500 bg-olivine-500 px-10 py-[10px] text-sm font-medium text-white transition-all hover:bg-olivine-600 @sm:w-auto"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="mt-10 pb-40">
                    <div className="rounded-2xl shadow-xl">
                      <div className="flex flex-row items-center justify-between px-4">
                        <p className="text-sm font-semibold text-gray-600">
                          Cowries Earned
                        </p>
                      </div>
                      <div className="mt-10 flex items-center justify-center pb-20">
                        <div
                          className={twMerge(
                            `flex h-[110px] w-[110px] items-center justify-center rounded-full bg-[#558223]/20 `
                          )}
                        >
                          <div
                            style={{
                              background: `conic-gradient(var(--outline, #558223), calc(var(--progress, ${
                                fetchedEarnedCustom &&
                                fetchedEarnedCustom?.total_reward_info
                                  ?.length &&
                                fetchedEarnedCustom?.total_reward_info[0]
                                  ?.earn_cowries
                              }) * 1%), transparent calc(var(--progress, ${
                                fetchedEarnedCustom &&
                                fetchedEarnedCustom?.total_reward_info
                                  ?.length &&
                                fetchedEarnedCustom?.total_reward_info[0]
                                  ?.earn_cowries
                              }) * 1%))`,
                            }}
                            className={`flex h-[110px] w-[110px] rounded-full text-2xl`}
                          >
                            <div
                              className={twMerge(
                                `m-auto flex h-[90px] w-[90px] flex-col items-center justify-center -space-y-[9px] rounded-[inherit] bg-white text-gray-400 `
                              )}
                            >
                              <p className="text-sm font-bold text-gray-600">
                                {fetchedEarnedCustom &&
                                fetchedEarnedCustom?.total_reward_info?.length
                                  ? fetchedEarnedCustom?.total_reward_info[0]
                                      ?.earn_cowries
                                  : 0}
                              </p>
                              <p className="text-[10px] font-semibold">
                                Cowries Earned
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {fetchedEarnedCustom &&
                    fetchedEarnedCustom?.total_reward_info?.length &&
                    fetchedEarnedCustom?.total_reward_info[0]?.earn_cowries >
                      0 ? (
                      <div className="mt-16">
                        <h3 className="text-xl font-semibold text-gray-700">
                          Cowries Earned Breakdown
                        </h3>
                        <div className="mt-4 space-y-8">
                          <EarnedBreakdownComponents
                            methodToEarn={
                              fetchedEarnedCustom &&
                              fetchedEarnedCustom?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedCustom?.breakdown_reward_info[0]
                                ?.reward_earn_title
                            }
                            statusEarned={
                              fetchedEarnedCustom &&
                              fetchedEarnedCustom?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedCustom?.breakdown_reward_info[0]
                                ?.reward_cowries_quantity
                            }
                            numberEarned={
                              fetchedEarnedCustom &&
                              fetchedEarnedCustom?.breakdown_reward_info
                                ?.length &&
                              fetchedEarnedCustom?.breakdown_reward_info[0]
                                ?.reward_cowries_quantity
                            }
                            transactionAmount={
                              fetchedEarnedCustom &&
                              fetchedEarnedCustom?.breakdown_reward_info?.length
                                ? `₦${Number(
                                    fetchedEarnedCustom
                                      ?.breakdown_reward_info[0]
                                      ?.reward_cowries_quantity
                                  ).toLocaleString()}`
                                : `₦${Number(0).toLocaleString()}`
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </Container>
      </div>
    </>
  );
};

export default RewardSummary;
