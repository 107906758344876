import React from "react";

function TestimonialComponent({ name, message }) {
  return (
    <div className="mb-4 mr-8 mt-4 h-56 w-60 cursor-not-allowed overflow-hidden rounded-lg bg-[#E9ECE3] p-4 shadow-md ring-1 ring-slate-400/20 sm:h-40 sm:w-96">
      <div className="text-center">
        <h2 className="text-center text-sm font-bold text-gray-700">{name}</h2>
        <p className="mt-2 text-[11px] font-medium text-gray-600">{message}</p>
      </div>
    </div>
  );
}

export default TestimonialComponent;
