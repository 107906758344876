import { createSlice } from "@reduxjs/toolkit";
import {
  createAccountNumber,
  getWalletBalance,
  getWalletBalanceUpdateApi,
  statusNotification,
  transferStatus,
  userAdvertsGet,
  userStatsGet,
} from "../actions";

const initialState = {
  account: "",
  walletBalance: "",
  settlementStatus: "",
  walletBalanceUpdate: "",
  transferStatusGet: "",
  stats: "",
  adverts: "",
};

const UserSlice = createSlice({
  name: "UserData",
  initialState,
  reducers: {
    resetUser(state) {
      state.account = null;
      state.walletBalance = null;
      state.settlementStatus = null;
      state.walletBalanceUpdate = null;
      state.transferStatusGet = null;
      state.stats = null;
      state.adverts = null;
    },
    resetWalletBalance(state) {
      state.walletBalance = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createAccountNumber.fulfilled, (state, { payload }) => {
      state.account = payload;
    });

    builder.addCase(statusNotification.fulfilled, (state, { payload }) => {
      state.settlementStatus = payload;
    });

    builder.addCase(getWalletBalance.fulfilled, (state, { payload }) => {
      state.walletBalance = payload;
    });
    builder.addCase(transferStatus.fulfilled, (state, { payload }) => {
      state.transferStatusGet = payload;
    });

    builder.addCase(userStatsGet.fulfilled, (state, { payload }) => {
      state.stats = payload;
    });

    builder.addCase(userAdvertsGet.fulfilled, (state, { payload }) => {
      state.adverts = payload;
    });

    builder.addCase(
      getWalletBalanceUpdateApi.fulfilled,
      (state, { payload }) => {
        state.walletBalanceUpdate = payload;
      }
    );
  },
});

const { reducer, actions } = UserSlice;
export const { resetUser, resetWalletBalance } = actions;

export default reducer;
