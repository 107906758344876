import React, { useEffect, useState } from "react";
import Container from "../../../components/pages/my_container/Container";
import CowriesImage from "../../../assets/cowriesImage.svg";
import RewardIcon from "../../../assets/rewardIcon.svg";
import SingleCowryImage from "../../../assets/singleCowryImage.svg";
import TierImage from "../../../assets/tierImage.svg";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { IoChevronForward } from "react-icons/io5";
import ProgressBar from "@ramonak/react-progress-bar";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import RewardCenterDisclosureComponent from "./RewardCenterDisclosureComponent";
import RewardBankHistoryEmpty from "./RewardBankHistoryEmpty";
import { authService } from "../../../services/authService";
import { useDispatch } from "react-redux";
import { fetchRewardBankInfo } from "../../../store/actions";
import moment from "moment";
import IsLoadingOne from "../../IsLoading_One";

const TierPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fetchedRewardBankInfo, setFetchedRewardBankInfo] = useState({});
  const [totalPossibleCount, setTotalPossibleCount] = useState(0);
  const [userCowriesCount, setUserCowriesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userTier, setUserTier] = useState("");

  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (customerId) {
      setIsLoading(true);
      dispatch(fetchRewardBankInfo(customerId))
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          setFetchedRewardBankInfo(res);
        })
        .catch((err) => err);
    }
  }, [customerId]);

  useEffect(() => {
    if (fetchedRewardBankInfo?.reward_balance?.length) {
      setUserTier(fetchedRewardBankInfo?.reward_balance[0]?.membership_name);
    }

    if (fetchedRewardBankInfo?.reward_balance?.length) {
      setUserCowriesCount(
        fetchedRewardBankInfo?.reward_balance[0]
          ?.during_membership_reward_cowries_quantity
      );
    }

    if (fetchedRewardBankInfo?.membership_info?.length) {
      setTotalPossibleCount(
        fetchedRewardBankInfo?.membership_info[2]?.minimum_cowries_quantity
      );
    }
  }, [fetchedRewardBankInfo]);

  return (
    <>
      {isLoading ? (
        <div>
          <IsLoadingOne message="Loading" />
        </div>
      ) : (
        <>
          <SignedInNavbarImproved />
          <div className="bg-white pb-40">
            <Container className="">
              <div className="mt-10 flex items-center space-x-2 sm:space-x-4">
                <div className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-100">
                  <BiArrowBack
                    onClick={() => navigate(-1)}
                    className="h-5 w-5 cursor-pointer text-gray-600"
                  />
                </div>
                <h2 className="text-lg font-bold text-gray-700 sm:text-2xl">
                  Reward Bank
                </h2>
              </div>
              <div className="mt-8 max-w-4xl">
                <div className="w-full rounded-lg border-[1px] border-[#F2C94C] bg-[#FFFDF1] p-4">
                  <div className="flex  flex-row items-center space-x-2">
                    <div>
                      <img src={TierImage} alt="icon" className="h-12 w-12" />
                    </div>
                    <div className="flex-1">
                      <div className="flex flex-row items-center justify-between">
                        <p className="text-base font-semibold text-gray-600">
                          {fetchedRewardBankInfo &&
                          fetchedRewardBankInfo?.reward_balance?.length
                            ? fetchedRewardBankInfo?.reward_balance[0]
                                ?.membership_name
                            : null}
                        </p>
                        <div>
                          <IoChevronForward className="h-5 w-5 text-[#F69E18]" />
                        </div>
                      </div>
                      <p className="mt-[2px] text-xs font-medium text-gray-400">
                        {fetchedRewardBankInfo &&
                        fetchedRewardBankInfo?.membership_info?.length
                          ? userCowriesCount <
                            fetchedRewardBankInfo?.membership_info[0]
                              ?.maximum_cowries_quantity
                            ? `Earn ${
                                fetchedRewardBankInfo?.membership_info[1]
                                  ?.minimum_cowries_quantity - userCowriesCount
                              } more Tier cowries to reach ${
                                fetchedRewardBankInfo?.membership_info[1]
                                  ?.membership_name
                              } Tier`
                            : null
                          : null}

                        {fetchedRewardBankInfo &&
                        fetchedRewardBankInfo?.membership_info?.length
                          ? userCowriesCount >
                              fetchedRewardBankInfo?.membership_info[1]
                                ?.minimum_cowries_quantity &&
                            userCowriesCount <
                              fetchedRewardBankInfo?.membership_info[2]
                                ?.minimum_cowries_quantity
                            ? `Earn ${
                                fetchedRewardBankInfo?.membership_info[2]
                                  ?.minimum_cowries_quantity - userCowriesCount
                              } more Tier cowries to reach ${
                                fetchedRewardBankInfo?.membership_info[2]
                                  ?.membership_name
                              } Tier`
                            : null
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4">
                    <ProgressBar
                      height="12px"
                      width="100%"
                      bgColor="#F69E18"
                      maxCompleted={totalPossibleCount}
                      completed={userCowriesCount}
                      labelSize="10px"
                      labelAlignment="right"
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between text-xs font-medium text-gray-600">
                    {userTier === "Starter" ? (
                      <>
                        {/* <p className="text-[#F69E18]">Starter</p>
                        <p className="">Pro</p> */}
                        <>
                          <div>
                            <p className="text-[#F69E18]">
                              {fetchedRewardBankInfo &&
                                fetchedRewardBankInfo?.membership_info
                                  ?.length &&
                                fetchedRewardBankInfo?.membership_info[0]
                                  ?.membership_name}
                            </p>
                            <p className="text-[#F69E18]">
                              {fetchedRewardBankInfo &&
                                fetchedRewardBankInfo?.membership_info
                                  ?.length &&
                                fetchedRewardBankInfo?.membership_info[0]
                                  ?.minimum_cowries_quantity}
                            </p>
                          </div>

                          {/* <p className="">Legend</p> */}
                          <div>
                            <p className="">
                              {fetchedRewardBankInfo &&
                                fetchedRewardBankInfo?.membership_info
                                  ?.length &&
                                fetchedRewardBankInfo?.membership_info[2]
                                  ?.membership_name}
                            </p>
                            <p className="">
                              {fetchedRewardBankInfo &&
                                fetchedRewardBankInfo?.membership_info
                                  ?.length &&
                                fetchedRewardBankInfo?.membership_info[2]
                                  ?.minimum_cowries_quantity}
                            </p>
                          </div>
                        </>
                      </>
                    ) : null}
                    {userTier === "Pro" ? (
                      <>
                        <div>
                          <p className="text-[#F69E18]">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[1]
                                ?.membership_name}
                          </p>
                          <p className="text-[#F69E18]">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[1]
                                ?.minimum_cowries_quantity}
                          </p>
                        </div>

                        {/* <p className="">Legend</p> */}
                        <div>
                          <p className="">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[2]
                                ?.membership_name}
                          </p>
                          <p className="">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[2]
                                ?.minimum_cowries_quantity}
                          </p>
                        </div>
                      </>
                    ) : null}
                    {userTier === "Legend" ? (
                      <>
                        {/* <p className="">Pro</p>
                        <p className="text-[#F69E18]">Legend</p> */}
                        <div>
                          <p className="">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[1]
                                ?.membership_name}
                          </p>
                          <p className="">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[1]
                                ?.minimum_cowries_quantity}
                          </p>
                        </div>

                        <div>
                          <p className="text-[#F69E18]">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[2]
                                ?.membership_name}
                          </p>
                          <p className="text-[#F69E18]">
                            {fetchedRewardBankInfo &&
                              fetchedRewardBankInfo?.membership_info?.length &&
                              fetchedRewardBankInfo?.membership_info[2]
                                ?.minimum_cowries_quantity}
                          </p>
                        </div>
                      </>
                    ) : null}
                    {/* <p>Starter</p>
                    <p>Pro</p>
                    <p className="text-[#F69E18]">Legend</p> */}
                  </div>
                  <div className="mt-6 flex flex-row items-center justify-between text-sm text-gray-500">
                    <p className="text-sm font-semibold text-gray-500">
                      {fetchedRewardBankInfo &&
                      fetchedRewardBankInfo?.reward_balance?.length
                        ? fetchedRewardBankInfo?.reward_balance[0]
                            ?.membership_activation_date &&
                          `Valid from ${moment(
                            fetchedRewardBankInfo?.reward_balance[0]
                              ?.membership_activation_date
                          ).format("ll")}`
                        : null}
                    </p>
                    <p className="text-sm font-semibold text-gray-500">
                      {fetchedRewardBankInfo &&
                      fetchedRewardBankInfo?.reward_balance?.length
                        ? fetchedRewardBankInfo?.reward_balance[0]
                            ?.membership_expiry_date &&
                          `Valid until ${moment(
                            fetchedRewardBankInfo?.reward_balance[0]
                              ?.membership_expiry_date
                          ).format("ll")}`
                        : null}
                    </p>
                  </div>
                </div>
                <div className="mt-8 w-full rounded-lg border-[1px] border-[#DEF5D1] px-6 py-6 lg:px-10">
                  <h3 className="text-xl font-semibold text-gray-700">
                    Membership Tiers
                  </h3>
                  <div className="mt-4 flex flex-col space-y-4 sm:flex-row sm:items-center sm:space-x-4 sm:space-y-0">
                    {fetchedRewardBankInfo &&
                    fetchedRewardBankInfo?.membership_info?.length
                      ? fetchedRewardBankInfo?.membership_info?.map(
                          (member, idx) => (
                            <div
                              key={idx}
                              className="w-full rounded-lg border-[1px] border-[#DEF5D1] bg-[#E8F3D4] p-2"
                            >
                              <h4 className="text-base font-semibold text-gray-600">
                                {member && member?.membership_name
                                  ? `${member?.membership_name} Tier:`
                                  : null}
                              </h4>
                              <div className="mt-2 flex flex-row items-center space-x-2">
                                <div>
                                  <img
                                    src={CowriesImage}
                                    alt="icon"
                                    className="h-8 w-8"
                                  />
                                </div>
                                <p className="text-sm font-semibold text-gray-500">
                                  {member
                                    ? `${member?.minimum_cowries_quantity} - ${member?.maximum_cowries_quantity} Cowries`
                                    : null}
                                </p>
                              </div>
                            </div>
                          )
                        )
                      : null}
                  </div>
                </div>
                <div className="mt-8 w-full rounded-lg bg-[#558223] px-6 py-10 lg:px-10">
                  <h3 className="text-2xl font-semibold text-[#EDF2E6]">
                    Membership Tier Benefits
                  </h3>
                  <div className="mt-6 flex flex-col space-y-8">
                    {fetchedRewardBankInfo &&
                    fetchedRewardBankInfo?.membership_info?.length
                      ? fetchedRewardBankInfo?.membership_info?.map(
                          (member, idx) => (
                            <div key={idx}>
                              <p className="text-base font-medium text-[#EDF2E6]">
                                {member && member?.membership_name
                                  ? `${member?.membership_name} level gets every cowry earned multiplied by ${member?.reward_cowries_earn_times}`
                                  : null}
                              </p>
                              <p className="text-base font-medium text-[#EDF2E6]">
                                {member && member?.reward_cowries_earn_times
                                  ? `- ${member?.reward_cowries_earn_times} x Cowry earned`
                                  : null}
                              </p>
                              <div className="mt-2 flex w-full max-w-[330px] flex-row items-center justify-center space-x-2 rounded-lg border-[1px] border-[#DEF5D1] bg-[#F6FBEE] p-2 px-16 py-4">
                                <p className="text-lg font-semibold text-gray-500">
                                  {member && member?.reward_cowries_earn_times
                                    ? `- ${member?.reward_cowries_earn_times} x`
                                    : null}
                                </p>
                                <div>
                                  <img
                                    src={CowriesImage}
                                    alt="icon"
                                    className="h-8 w-8"
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )
                      : null}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default TierPage;
