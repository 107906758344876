import React, { useEffect, useState } from "react";
import Logo from "../assets/logo.svg";
import { IoMdLock } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setForgotPassword } from "../store/slices/modalSlice";
import { useNavigate } from "react-router-dom";
import { newPassword } from "../store/slices/authSlice";
import PasswordChecklist from "react-password-checklist";
import { toast } from "react-hot-toast";
import { authService } from "../services/authService";

function NewPassword() {
  const [visibility, setVisibility] = useState(false);
  const [fetchedVeriCode, setFetchedVeriCode] = useState();
  const [fetchedEmail, setFetchedEmail] = useState();
  const [pwd, setPwd] = useState("");
  const [passwordValid, setPaswordValid] = useState(false);

  const { forgotPassword } = useSelector((state) => state.auth);
  let navigate = useNavigate();

  useEffect(() => {
    if (forgotPassword) setFetchedVeriCode(forgotPassword?.verification_code);
    if (forgotPassword) setFetchedEmail(forgotPassword?.user_email_address);
  }, [forgotPassword]);

  const dispatch = useDispatch();

  const InputType = visibility ? "text" : "password";
  const viewer = () => {
    setVisibility(!visibility);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!passwordValid) return;

    try {
      const toastId = toast.loading("Sending Verification code...");

      const data = {
        sql_ref_no: "8",
        email_address: authService?.getCurrentUserEmail(),
        verification_code:
          fetchedVeriCode || authService.getCurrentUserVerificationCode(),
        new_password: pwd,
        option: "1",
      };

      dispatch(newPassword(data))
        .unwrap()
        .then(() => {
          setPwd("");
          dispatch(setForgotPassword(false));
          localStorage.removeItem("grocedy_user_email");
          localStorage.removeItem("grocedy_userId");
          toast.success("Your Password was changed!", {
            id: toastId,
          });
          navigate("/login");
        })
        .catch((err) => {
          setPwd("");

          toast.error("Your Password was not changed!", {
            id: toastId,
          });

          return err;
        });
    } catch (err) {
      return err;
    }
  };
  return (
    <div className="h-screen w-screen p-8">
      <div className="mb-4 md:mb-0">
        <img
          src={Logo}
          className="h-20 w-24 flex-shrink-0 object-contain"
          alt=""
        />
      </div>
      <div className="mx-auto h-[450px] w-[430px] rounded-md bg-[#F6F5F5] px-16 py-16 md:h-[450px] md:w-[600px]">
        <div>
          <div className="text-2xl font-bold text-gray-600">New Password</div>
        </div>
        <div className="mt-12">
          <form onSubmit={handleSubmit}>
            <div>
              <label className="ml-3 text-xs text-gray-500">Password</label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <div className="inset-y-0 pl-3">
                    <IoMdLock className="absolute mt-3 h-5 w-5 text-gray-600" />
                  </div>
                  <input
                    type={InputType}
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                    placeholder="Enter your new password"
                    className="block w-full rounded-md bg-white pl-10 pr-10 focus:border-olivine-700 focus:ring-black"
                  />
                </div>
                <div
                  onClick={viewer}
                  className="absolute mr-3 flex-grow cursor-pointer text-xs text-gravel-500"
                >
                  view
                </div>
              </div>
            </div>
            <PasswordChecklist
              rules={["minLength", "specialChar", "number", "capital"]}
              minLength={8}
              value={pwd}
              onChange={(isValid) => {
                isValid ? setPaswordValid(true) : setPaswordValid(false);
              }}
              messages={{
                minLength: "The password has more than 8 characters.",
                specialChar: "The password has special characters.",
                number: "The password has a number.",
                capital: "The password has an uppercase letter.",
              }}
            />
            <div>
              <button className="mt-10 w-full rounded-md bg-olivine-500 py-3 text-white transition-all duration-300 hover:bg-olivine-600">
                SAVE PASSWORD
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
