import React, { useEffect, useState } from "react";
import redeemVoucherSuccessImage from "../../../assets/redeemVoucherSuccessImage.svg";
import { useSelector } from "react-redux";

function RedeemAvoucherSuccess({ setOpenRedeemSuccess }) {
  const { redeemVoucher } = useSelector((state) => state.voucher);
  const [fetchedVoucherRedeem, setFetchedVoucherRedeem] = useState(0);

  useEffect(() => {
    if (redeemVoucher && redeemVoucher?.deposit_info?.length) {
      setFetchedVoucherRedeem(redeemVoucher?.deposit_info[0]?.deposit_amount);
    }
  }, [redeemVoucher]);
  return (
    <div className="max-w-lg bg-[#73964C] p-4">
      <div className="flex flex-col items-center justify-center pt-16 text-center">
        <div>
          <img
            src={redeemVoucherSuccessImage}
            alt="voucher-success"
            className="h-32 w-auto"
          />
        </div>
        <h2 className="mt-4 max-w-xs text-center text-xl font-bold text-white">
          You have successfully redeemed your Voucher.
        </h2>
        <p className="max-w-xs text-center text-sm text-white">
          {`You have redeemed your voucher worth ₦ ${Number(
            fetchedVoucherRedeem
          ).toLocaleString()} to your Grocedy wallet`}
        </p>
      </div>
      <div className="mt-6">
        <button
          onClick={(e) => {
            e.preventDefault();
            setOpenRedeemSuccess(false);
          }}
          className="w-full transform cursor-pointer rounded-md bg-white py-3 text-sm font-semibold text-olivine-500 transition-all duration-200 hover:bg-gray-100 focus:outline-none focus:ring-[2px] focus:ring-gray-100 focus:ring-opacity-60 focus:ring-offset-1 active:bg-gray-200 sm:px-10"
        >
          Got it
        </button>
      </div>
    </div>
  );
}

export default RedeemAvoucherSuccess;
