import React, { useState } from "react";
import IndividualImage from "../../../assets/individualImage.svg";
import BusinessImage from "../../../assets/businessImage.svg";
import CoporationImage from "../../../assets/coporationImage.svg";
import CoporationAdminImage from "../../../assets/corporateAdminImage.svg";
import { RadioGroup } from "@headlessui/react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function CreateAccount({ setCorporateCode, setPageNUmber, paramDataList }) {
  const [select, setSelect] = useState("");
  const navigate = useNavigate();

  const setCodeOption = (option) => {
    setCorporateCode(option);
    setSelect(option);
  };

  return (
    <div className="w-full bg-[#FBFBFB]  px-4">
      <div className="mx-auto max-w-3xl">
        <RadioGroup
          value={select}
          onChange={setCodeOption}
          className="mt-10 w-full pb-36"
        >
          <RadioGroup.Label className="">
            <div className="text-xl font-bold text-gray-600 sm:text-3xl">
              Get started with Grocedy
            </div>
            <div className="mt-3 text-xs font-semibold text-gray-500 sm:text-sm">
              Please select a category that describes you
            </div>
            <hr className="mt-8 h-[0.5px] w-full bg-olivine-500" />
          </RadioGroup.Label>
          <div className="">
            <div className="mt-8 w-full space-y-6 md:flex md:items-center md:space-x-5 md:space-y-0">
              <RadioGroup.Option value="INDIVIDUAL" className="sm:w-full">
                {({ checked }) => (
                  <div
                    className={
                      checked
                        ? "cursor-pointer rounded-lg bg-olivine-500 p-3 text-white shadow-md shadow-gray-300 sm:h-[250px] sm:w-full"
                        : "cursor-pointer rounded-lg bg-white p-3 text-gray-500 shadow-md shadow-gray-300 ring-[1px] ring-gray-200 sm:h-[250px] sm:w-full"
                    }
                  >
                    <div className="flex justify-end">
                      <div
                        className={
                          checked
                            ? "h-2 w-2 rounded-full bg-white sm:h-3 sm:w-3"
                            : "h-2 w-2 rounded-full bg-white sm:h-3 sm:w-3"
                        }
                      ></div>
                    </div>
                    <div className="flex items-center sm:flex-col sm:justify-center sm:py-1">
                      <div
                        className={
                          checked
                            ? "rounded-full bg-white p-4"
                            : "rounded-full bg-gray-200 p-4"
                        }
                      >
                        <img
                          src={IndividualImage}
                          alt=""
                          className="h-6 w-6 sm:h-10 sm:w-10"
                        />
                      </div>
                    </div>
                    <div className="mt-2 flex sm:flex-col sm:justify-center sm:text-center">
                      <div className="text-base font-semibold">Individual</div>
                    </div>
                    <div className="mt-[2px] text-[10px] font-semibold sm:text-center">
                      Register as an individual to get great prices on food
                      items, basket plans and you can also create your own
                      basket
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="BUSINESS" className="sm:w-full">
                {({ checked }) => (
                  <div
                    className={
                      checked
                        ? "cursor-pointer rounded-lg bg-olivine-500 p-3 text-white shadow-md shadow-gray-300 sm:h-[250px] sm:w-full"
                        : "cursor-pointer rounded-lg bg-white p-3 text-gray-500 shadow-md shadow-gray-300 ring-[1px] ring-gray-200 sm:h-[250px] sm:w-full"
                    }
                  >
                    <div className="flex justify-end">
                      <div
                        className={
                          checked
                            ? "h-2 w-2 rounded-full bg-white sm:h-3 sm:w-3"
                            : "h-2 w-2 rounded-full bg-white sm:h-3 sm:w-3"
                        }
                      ></div>
                    </div>
                    <div className="flex items-center sm:flex-col sm:justify-center sm:py-1">
                      <div
                        className={
                          checked
                            ? "rounded-full bg-white p-4"
                            : "rounded-full bg-gray-200 p-4"
                        }
                      >
                        <img
                          src={BusinessImage}
                          alt=""
                          className="h-6 w-6 sm:h-10 sm:w-10"
                        />
                      </div>
                    </div>
                    <div className="mt-2 flex sm:flex-col sm:justify-center sm:text-center">
                      <div className="text-base font-semibold">Business</div>
                    </div>
                    <div className="mt-[2px] text-[10px] font-semibold sm:text-center">
                      Register as a business to get additional discounts on bulk
                      purchases. Suitable for retailers, restaurants, hotels
                      etc. Minimum order quantities apply
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="CORPORATION" className="sm:w-full">
                {({ checked }) => (
                  <div
                    className={
                      checked
                        ? "cursor-pointer rounded-lg bg-olivine-500 p-3 text-white shadow-md shadow-gray-300 sm:h-[250px] sm:w-full"
                        : "cursor-pointer rounded-lg bg-white p-3 text-gray-500 shadow-md shadow-gray-300 ring-[1px] ring-gray-200 sm:h-[250px] sm:w-full"
                    }
                  >
                    <div className="flex justify-end">
                      <div
                        className={
                          checked
                            ? "h-2 w-2 rounded-full bg-white sm:h-3 sm:w-3"
                            : "h-2 w-2 rounded-full bg-white sm:h-3 sm:w-3"
                        }
                      ></div>
                    </div>
                    <div className="flex items-center sm:flex-col sm:justify-center sm:py-1">
                      <div
                        className={
                          checked
                            ? "rounded-full bg-white p-4"
                            : "rounded-full bg-gray-200 p-4"
                        }
                      >
                        <img
                          src={CoporationImage}
                          alt=""
                          className="h-6 w-6 sm:h-10 sm:w-10"
                        />
                      </div>
                    </div>
                    <div className="mt-2 flex sm:flex-col sm:justify-center sm:text-center">
                      <div className="text-base font-semibold">Corporate</div>
                    </div>
                    <div className="mt-[2px] text-[10px] font-semibold sm:text-center">
                      Register as a corporate user if your company, cooperative
                      or third-party application has provided you a corporate
                      registration code.
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option
                value="CORPORATION_ADMIN"
                className="sm:w-full"
              >
                {({ checked }) => (
                  <div
                    className={
                      checked
                        ? "cursor-pointer rounded-lg bg-olivine-500 p-3 text-white shadow-md shadow-gray-300 sm:h-[250px] sm:w-full"
                        : "cursor-pointer rounded-lg bg-white p-3 text-gray-500 shadow-md shadow-gray-300 ring-[1px] ring-gray-200 sm:h-[250px] sm:w-full"
                    }
                  >
                    <div className="flex justify-end">
                      <div
                        className={
                          checked
                            ? "h-2 w-2 rounded-full bg-white sm:h-3 sm:w-3"
                            : "h-2 w-2 rounded-full bg-white sm:h-3 sm:w-3"
                        }
                      ></div>
                    </div>
                    <div className="flex items-center sm:flex-col sm:justify-center sm:py-1">
                      <div
                        className={
                          checked
                            ? "rounded-full bg-white p-4"
                            : "rounded-full bg-gray-200 p-4"
                        }
                      >
                        <img
                          src={CoporationAdminImage}
                          alt=""
                          className="h-6 w-6 sm:h-10 sm:w-10"
                        />
                      </div>
                    </div>
                    <div className="mt-2 flex sm:flex-col sm:justify-center sm:text-center">
                      <div className="text-base font-semibold">
                        Corporate Admin
                      </div>
                    </div>
                    <div className="mt-[2px] text-[9px] font-semibold sm:text-center">
                      Register your company with Grocedy Corporate and take the
                      lead in managing your corporate account. Get started today
                      to unlock exclusive features and streamline your corporate
                      experience."
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
            </div>
          </div>

          {paramDataList?.length !== 2 && (
            <div className="mt-14 flex flex-col-reverse sm:flex-row sm:items-center sm:justify-end sm:space-x-4 sm:space-y-0">
              <button
                onClick={() => navigate(-1)}
                className="mt-4 flex cursor-pointer items-center justify-center space-x-1 rounded-md border border-olivine-500 py-4 transition-all duration-300 hover:ring-[1px] hover:ring-olivine-600 sm:mt-0 sm:px-12"
              >
                <div>
                  <BiArrowBack className="h-4 w-4 text-olivine-500" />
                </div>
                <div className="text-sm font-semibold text-olivine-500">
                  Back
                </div>
              </button>
              <button
                onClick={() => setPageNUmber(2)}
                disabled={!select}
                className="cursor-pointer rounded-md bg-olivine-500 py-4 text-center text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600 disabled:opacity-50 sm:px-12"
              >
                Proceed
              </button>
            </div>
          )}
        </RadioGroup>
      </div>
    </div>
  );
}

export default CreateAccount;
