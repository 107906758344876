import React from "react";

function UserDataRecords() {
  return (
    <div className="w-full bg-gray-200 px-4 pb-40 pt-10">
      <div className="mx-auto w-full max-w-[700px] space-y-4 rounded-3xl bg-[#F2FAD3] px-6 py-10 text-sm font-medium text-gray-600 xsm:px-10 xsm:py-14 sm:px-16 sm:py-20">
        <h2>Dear Valued User,</h2>
        <p>
          We at Grocedy value your privacy and understand the importance of
          ensuring that your personal information is secure. Thus, we want to
          inform you about the process for deleting your Facebook sign-in data
          from our platform if you wish to do so.
        </p>
        <p>
          When you signed up using your Facebook account on Grocedy, we asked
          for certain permissions to access your basic profile information,
          including your name, email address, and profile picture.
        </p>
        <p>
          If you would like us to delete this information from our servers,
          please follow these steps:
        </p>
        <ol className="list-inside list-decimal space-y-2 text-xs">
          <li>Open the Grocedy app and go to your account settings</li>
          <li>
            See the “Send us a mail” tab and send a mail to
            customercare@grocedy.com
          </li>
          <li>
            Subject of the email should be “Delete Facebook sign-in data”. The
            body of the mail should contain your email address and name.
          </li>
          <li>
            You will receive a confirmation email asking you to confirm that you
            want to disconnect your Facebook account.
          </li>
          <li>
            Once you confirm the request, by responding to the mail, Grocedy
            will delete all your Facebook sign-in data from our servers,
            including your name, email address, profile picture, and any other
            information we may have collected.
          </li>
        </ol>
        <p>
          Please note that this action is irreversible, and we will not be able
          to retrieve your data once you have disconnected your Facebook
          account.
        </p>
        <p>
          We hope that this information has been helpful to you. If you have any
          further questions or concerns, please do not hesitate to reach out to
          our customer support team.
        </p>
        <p>Thank you for trusting Grocedy with your personal information.</p>
        <p>Sincerely,</p>
        <p>Team Grocedy</p>
      </div>
    </div>
  );
}

export default UserDataRecords;
