import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import grain from "../../../assets/grain.svg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./carousel.css";

// import required modules
import { Pagination, Navigation } from "swiper";

function Carousel(props) {
  const [activePane, setActivePane] = useState("");
  const { basketGroupList } = props;

  const selectCategory = (group_id) => {
    setActivePane(group_id);
    props?.resetProductGroup(group_id);
  };

  return (
    <Swiper
      slidesPerView={4}
      spaceBetween={10}
      loop={true}
      loopFillGroupWithBlank={true}
      navigation={true}
      modules={[Navigation]}
      className="mySwiper"
      style={{
        "--swiper-navigation-color": "#DAA6A",
        "--swiper-navigation-size": "20px",
      }}
    >
      {basketGroupList?.map((group, index) => {
        return (
          <SwiperSlide  key={index}>
            <section
              className={
                "cursor-pointer w-full p-2 transition duration-200 " +
                (activePane === group.group_id
                  ? "cursor-pointer activeClass carouselContent  w-full "
                  : "")
              }
              onClick={() => selectCategory(group.group_id)}
            >
              <div className=" flex justify-center">
                <img src={grain} className="" alt={group?.group_name}/>
              </div>
              <div className=" flex justify-center">
                <span className="grainText">{group?.group_name} </span>
              </div>
            </section>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Carousel;
