import React, { useEffect, useState } from "react";
import Container from "../../../components/pages/my_container/Container";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import RewardBankHistoryEmpty from "./RewardBankHistoryEmpty";
import { authService } from "../../../services/authService";
import { fetchRewardHistory } from "../../../store/actions";
import IsLoadingOne from "../../IsLoading_One";
import { useDispatch } from "react-redux";
import moment from "moment";

const RewardBankHistory = () => {
  const [rewardBankHistory, setRewardBankHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedHistory, setFetchedHistory] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (customerId) {
      setIsLoading(true);
      dispatch(fetchRewardHistory(customerId))
        .unwrap()
        .then((res) => {
          if (res?.reward_info?.length) {
            setIsLoading(false);
            setRewardBankHistory(res?.reward_info);
          } else {
            setIsLoading(false);
            setRewardBankHistory([]);
          }
        })
        .catch((err) => err);
    }
  }, [customerId]);

  useEffect(() => {
    switch (selectedIndex) {
      case 0:
        setFetchedHistory(rewardBankHistory);
        break;
      case 1:
        const earnedCowries = rewardBankHistory?.filter(
          (history) => history?.reward_type_id === 1
        );
        setFetchedHistory(earnedCowries);
        break;
      case 2:
        const redeemedCowries = rewardBankHistory?.filter(
          (history) => history?.reward_type_id === 2
        );
        setFetchedHistory(redeemedCowries);
        break;
      default:
        setFetchedHistory(rewardBankHistory);
    }
  }, [selectedIndex, rewardBankHistory]);

  return (
    <>
      {isLoading ? (
        <div>
          <IsLoadingOne message="Loading" />
        </div>
      ) : (
        <>
          <SignedInNavbarImproved />
          <div className="bg-white">
            <Container className="">
              <div className="mt-10 flex items-center space-x-2 sm:space-x-4">
                <div className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-100">
                  <BiArrowBack
                    onClick={() => navigate(-1)}
                    className="h-5 w-5 cursor-pointer text-gray-600"
                  />
                </div>
                <h2 className="text-lg font-bold text-gray-700 sm:text-2xl">
                  Reward Bank History
                </h2>
              </div>
              {!rewardBankHistory?.length ? (
                <RewardBankHistoryEmpty />
              ) : (
                <div className="mt-6">
                  <Tab.Group
                    selectedIndex={selectedIndex}
                    onChange={setSelectedIndex}
                  >
                    {/*   Tab section header */}
                    <Tab.List className="flex w-fit flex-row flex-wrap items-center text-sm">
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <button
                            className={
                              selected
                                ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-1 text-olivine-500 outline-none"
                                : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                            }
                          >
                            <p className="cursor-pointer font-semibold">
                              All Cowries
                            </p>
                          </button>
                        )}
                      </Tab>

                      <Tab as={Fragment}>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <button
                            className={
                              selected
                                ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-1 text-olivine-500 outline-none"
                                : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                            }
                          >
                            <p className="cursor-pointer font-semibold">
                              <span className="hidden sm:inline">Cowries</span>{" "}
                              Earned
                            </p>
                          </button>
                        )}
                      </Tab>

                      <Tab as={Fragment}>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <button
                            className={
                              selected
                                ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-1  text-olivine-500 outline-none"
                                : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                            }
                          >
                            <p className="cursor-pointer font-semibold">
                              <span className="hidden sm:inline">Cowries</span>{" "}
                              Redeemed
                            </p>
                          </button>
                        )}
                      </Tab>
                    </Tab.List>

                    <Tab.Panels>
                      <Tab.Panel>
                        <div className="mt-10 space-y-6 pb-40 ">
                          {fetchedHistory && fetchedHistory?.length
                            ? fetchedHistory?.map((cowry, idx) => (
                                <div
                                  key={idx}
                                  onClick={() =>
                                    navigate(
                                      `/reward-bank/history/${cowry.ref_no}`
                                    )
                                  }
                                >
                                  <p className="pl-6 text-xs font-normal text-gray-500">
                                    {cowry?.transaction_date &&
                                      moment(cowry?.transaction_date).format(
                                        "ll"
                                      )}
                                  </p>
                                  <div className="mt-1 cursor-pointer rounded border-[1px] border-gray-100 bg-[#F9F9F9] px-6 py-3 shadow-sm">
                                    <p className="text-sm font-bold text-gray-600">
                                      {cowry?.reward_earn_title}
                                    </p>
                                    <div className="mt-1 flex flex-row items-center justify-between text-sm font-semibold">
                                      <p className="text-gray-500">
                                        {cowry?.reward_cowries_quantity &&
                                          `₦ ${Number(
                                            cowry?.reward_cowries_quantity *
                                              1000
                                          ).toLocaleString()}`}
                                      </p>
                                      <p className="text-[#5D890E]">
                                        {cowry?.reward_cowries_quantity &&
                                          `+ ${cowry?.reward_cowries_quantity} Cowries`}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </Tab.Panel>

                      <Tab.Panel>
                        <div className="mt-10 ">
                          {fetchedHistory && fetchedHistory?.length
                            ? fetchedHistory?.map((cowry, idx) => (
                                <div
                                  key={idx}
                                  onClick={() =>
                                    navigate(
                                      `/reward-bank/history/${cowry.ref_no}`
                                    )
                                  }
                                >
                                  <p className="pl-6 text-xs font-normal text-gray-500">
                                    {cowry?.transaction_date &&
                                      moment(cowry?.transaction_date).format(
                                        "ll"
                                      )}
                                  </p>
                                  <div className="mt-1 cursor-pointer rounded border-[1px] border-gray-100 bg-[#F9F9F9] px-6 py-3 shadow-sm">
                                    <p className="text-sm font-bold text-gray-600">
                                      {cowry?.reward_earn_title}
                                    </p>
                                    <div className="mt-1 flex flex-row items-center justify-between text-sm font-semibold">
                                      <p className="text-gray-500">
                                        {cowry?.reward_cowries_quantity &&
                                          `₦ ${Number(
                                            cowry?.reward_cowries_quantity *
                                              1000
                                          ).toLocaleString()}`}
                                      </p>
                                      <p className="text-[#5D890E]">
                                        {cowry?.reward_cowries_quantity &&
                                          `+ ${cowry?.reward_cowries_quantity} Cowries`}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </Tab.Panel>

                      <Tab.Panel>
                        <div className="mt-10 ">
                          {fetchedHistory && fetchedHistory?.length
                            ? fetchedHistory?.map((cowry, idx) => (
                                <div
                                  key={idx}
                                  onClick={() =>
                                    navigate(
                                      `/reward-bank/history/${cowry.ref_no}`
                                    )
                                  }
                                >
                                  <p className="pl-6 text-xs font-normal text-gray-500">
                                    {cowry?.transaction_date &&
                                      moment(cowry?.transaction_date).format(
                                        "ll"
                                      )}
                                  </p>
                                  <div className="mt-1 cursor-pointer rounded border-[1px] border-gray-100 bg-[#F9F9F9] px-6 py-3 shadow-sm">
                                    <p className="text-sm font-bold text-gray-600">
                                      {cowry?.reward_earn_title}
                                    </p>
                                    <div className="mt-1 flex flex-row items-center justify-between text-sm font-semibold">
                                      <p className="text-gray-500">
                                        {cowry?.reward_cowries_quantity &&
                                          `₦ ${Number(
                                            cowry?.reward_cowries_quantity *
                                              1000
                                          ).toLocaleString()}`}
                                      </p>
                                      <p className="text-[#5D890E]">
                                        {cowry?.reward_cowries_quantity &&
                                          `+ ${cowry?.reward_cowries_quantity} Cowries`}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              )}
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default RewardBankHistory;
