import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { authService } from "../../../services/authService";
import { voucherRedeem } from "../../../store/actions";
import toast from "react-hot-toast";

function RedeemAVoucher({ setOpenRedeem, setOpenRedeemSuccess }) {
  const dispatch = useDispatch();
  const [voucherCode, setVoucherCode] = useState("");

  const user_id = authService.getCurrentUserID();
  const customer_id = authService.getCurrentUserCustomerId();
  const mobile_number = authService.getCurrentUserPhone();

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      const toastId = toast.loading("Redeeming voucher");

      const data = {
        user_id: user_id?.toString(),
        customer_id: customer_id?.toString(),
        voucher_number: voucherCode,
        mobile_number,
      };

      dispatch(voucherRedeem(data))
        .unwrap()
        .then((res) => {
          if (res.status === 403) {
            return toast.error(res.response.error, {
              id: toastId,
            });
          }

          toast.success(res.result_status[0].message_text, {
            id: toastId,
          });
          setOpenRedeem(false);

          return setOpenRedeemSuccess(true);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="p-4">
      <div className="flex max-w-lg flex-row items-center justify-between bg-[#FEFEFD]">
        <h2 className="text-lg font-semibold text-gray-700">
          Redeem a Voucher
        </h2>
        <div
          onClick={() => setOpenRedeem(false)}
          className="cursor-pointer rounded-full bg-olivine-500 p-1 text-white active:bg-olivine-400"
        >
          <IoClose className="h-5 w-5" />
        </div>
      </div>
      <div className="mt-6 rounded-lg border-[0.5px] border-gray-200 bg-white p-4 shadow-md">
        <form className="pb-6">
          <div className="mt-2">
            <label
              htmlFor="email"
              className="ml-3 text-xs font-medium text-gray-600"
            >
              Enter Voucher Code
            </label>
            <div className="relative flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <input
                  type="text"
                  placeholder="946-7EB-C99"
                  name="voucher_code"
                  value={voucherCode}
                  onChange={(e) => setVoucherCode(e.target.value)}
                  className="block w-full rounded-md border-gray-200 bg-[#ECEDEB] pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>
        </form>
        <div className="mt-6">
          <button
            onClick={(e) => handleSubmit(e)}
            className="w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 sm:px-10"
          >
            Redeem
          </button>
        </div>
      </div>
    </div>
  );
}

export default RedeemAVoucher;
