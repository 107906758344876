

const DeliveryDetailsCity = (props) => {


    let countriesList = props?.mainList.length > 0
    && props?.mainList.map((item, i) => {
        if(props?.default == item.city_id){
            return (
                <option selected key={i} value={item.city_id}>{item.city_name}</option>
            )
        }else{
            return (
                <option  key={i} value={item.city_id}>{item.city_name}</option>
              )

        }

});

    return (
        <>
          <select   onChange={(e)=> props.activeSelect(e.target.value)}
                type="text"
                className="block w-full cursor-pointer rounded-md border-gray-400 bg-white pl-4 pr-10 text-sm font-semibold text-gray-600 placeholder:text-sm focus:border-olivine-500 focus:ring-olivine-500"
              >
             <option value=""  >
         -- Select country --
      </option> 
       {countriesList}
         </select>
        
        </>
      );
}
 
export default DeliveryDetailsCity;