import React from "react";
import CheckOutOtherProduct from "./CheckOutOtherProduct";
import GetStarted from "./GetStarted";
import Plans from "./Plans";
import WelcomeToGrocedy from "./WelcomeToGrocedy";
import HomePageButchers from "./butcher/HomePageButchers";
import BasketFloatingPane from "./pages/create_basket_page/BasketFloatingPane";
import WelcomeToGrocedyTwo from "./WelcomeToGrocedyTwo";
import WelcomeToGrocedyThree from "./WelcomeToGrocedyThree";

function MainAreaThree() {
  return (
    <div className="bg-primary_color">
      {/* <WelcomeToGrocedy /> */}
      {/* <WelcomeToGrocedyTwo /> */}
      <WelcomeToGrocedyThree />
      <Plans />
      <HomePageButchers />
      <CheckOutOtherProduct />
      <BasketFloatingPane />
    </div>
  );
}
export default MainAreaThree;
