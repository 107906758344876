import React from "react";
import { CgClose } from "react-icons/cg";

const ThanksForChoosingGrocedy = ({ handleThanks, onClose }) => {
  const thanksHandler = () => {
    handleThanks();
    onClose();
    return window.location.reload();
  };

  return (
    <div className="mx-auto h-full w-full max-w-[420px] px-2 pb-16 pt-[300px] xsm:px-3">
      <div className="rounded-2xl bg-[#F9F9F9] px-4 py-6 sm:px-6">
        <div className="flex items-center justify-end">
          <button className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
            <CgClose className="h-5 w-5 text-gravel-500" />
          </button>
        </div>
        <h2 className="mt-4 text-center text-2xl font-bold text-gray-700">
          Thank you for choosing Grocedy!
        </h2>
        <div className="mt-4">
          <p className="text-sm text-gray-500">
            {" "}
            Our team is diligently reviewing the documents you've submitted. we
            will notify you promptly once the approval process is complete.
          </p>
          <p className="mt-1 text-sm text-gray-500">
            Meanwhile, we invite you to explore our platform. Feel free to
            browse our selection of products and services. We're here to ensure
            your experience is exceptional.{" "}
          </p>
        </div>
        <div className="mx-auto mt-6 flex max-w-xs justify-center text-center tracking-widest">
          <button
            onClick={() => thanksHandler()}
            className="w-full rounded-md bg-olivine-500 py-3 text-sm text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
          >
            Noted
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThanksForChoosingGrocedy;
