import baseAxios from "../core/api/axios/baseAxios";

class RewardBankService {
  static async handleRewardBankInfo(customerId) {
    const res = await baseAxios.get(`reward-bank/${customerId}`);

    return res?.data || res;
  }

  static async handleRewardHistory(customerId) {
    const res = await baseAxios.get(`reward-bank/history/${customerId}`);

    return res?.data || res;
  }

  static async handleRewardHistoryDetail(refNo) {
    const res = await baseAxios.get(`reward-bank/detail/${refNo}`);

    return res?.data || res;
  }

  static async handleRedeemRewardPoints(customerId, data) {
    const res = await baseAxios.post(`reward-bank/${customerId}`, data);

    return res?.data || res;
  }

  static async handleEarnedCowriesToday(customerId) {
    const res = await baseAxios.get(`reward-bank/${customerId}/today`);

    return res?.data || res;
  }

  static async handleEarnedCowriesLastWeek(customerId) {
    const res = await baseAxios.get(`reward-bank/${customerId}/last-week`);

    return res?.data || res;
  }

  static async handleEarnedCowriesLastMonth(customerId) {
    const res = await baseAxios.get(`reward-bank/${customerId}/last-month`);

    return res?.data || res;
  }

  static async handleEarnedCowriesCustom(customerId, data) {
    const res = await baseAxios.post(`reward-bank/${customerId}/custom`, data);

    return res?.data || res;
  }
}

export default RewardBankService;
