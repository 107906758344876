import React from "react";

function OurTeam({ img, name, information }) {
  return (
    <div className="col-span-1 mx-auto mt-10 grid justify-items-center text-gray-500">
      <img
        src={img}
        className="aspect-auto h-[150px] w-[150px] rounded-full border-[1px] border-gray-300 object-cover transition duration-500 ease-in-out hover:scale-125"
        alt=""
      />
      <p className="mt-4 text-sm font-bold">{name}</p>
      <p className="text-xs font-semibold">{information}</p>
    </div>
  );
}

export default OurTeam;
